import React from 'react';
import { Helmet } from 'react-helmet';

import LegalEntityPage from 'components/General/LegalEntityPage/LegalEntityPage';

function LegalEntity() {
  return (
    <section className="Mysection">
      <Helmet>
        <title>Юридические лица | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <LegalEntityPage />
    </section>
  );
}

export default LegalEntity;
