import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import ReportPage from 'components/General/ReportPage/ReportPage';

const Report = observer(() => {
  const { user } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    if ((user.user.role.id !== 1 && user.user.role.id !== 0 && user.department !== 3 && user.department !== 2)) {
      navigate(-1);
    }
  }, [user.department]);
  return (
    <section className="Mysection">
      <Helmet>
        <title>Отчет | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <ReportPage />
    </section>
  );
});

export default Report;
