export const CONTACT_FIELDS = [
  { id: 'fullname', label: 'ФИО', delete: true },
  { id: 'email', label: 'Почта' },
  { id: 'phone', label: 'Номер телефона', type: 'phone' },
  { id: 'telegram', label: 'Телеграм' },
  { id: 'post', label: 'Должность' },
  { id: 'birthday', label: 'Дата рождения', date: true },
];

export const CONTACT_BLOGGER_FIELDS = [
  { id: 'address', label: 'Адрес', delete: true },
  { id: 'phone', label: 'Номер телефона', type: 'phone' },
  { id: 'telegram', label: 'Телеграм' },
  { id: 'email', label: 'Почта*' },
  { id: 'password', label: 'Пароль' },
  { id: 'responsible', label: 'Менеджер', autocomplete: true },
  { id: 'note', label: 'Примечание' },
];
