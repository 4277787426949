// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import SquareArrow from 'components/UI/AccordionArrows/AccordionArrow/SquareArrow';
import CancelBtn from 'components/UI/Btns/CancelBtn';
import InputElement from 'components/UI/InputElements/InputElement';
import TextAreaAutoSize from 'components/UI/MyTextArea/TextAreaAutoSize';
import SearchBar from 'components/UI/SearchBar/SearchBar';

import { FORMAT_COMMENT_TEXT_VARIANTS, FORMAT_OPTIONS } from '../consts';

import classes from '../ModalCreateContract.module.css';

const PublicationItem = React.memo((props) => {
  const {
    change, isActive, types, blogger, link, platformCount, publicationNumber, deleteBtn, cancelBtn, left, deleteHandler, publication, index,
  } = props;
  const [activeArrow, setActiveArrow] = useState(false);
  const [status, setStatus] = useState(false);

  const [count, setCount] = useState('');
  const [timing, setTiming] = useState('');
  const [manualType, setManualType] = useState('');
  const [nonRemovalPeriod, setNonRemovalPeriod] = useState('');
  const [nonRemovalPeriodUnits, setNonRemovalPeriodUnits] = useState('');
  const [showCategory, setShowCategory] = useState('');
  const [videoHosting, setVideoHosting] = useState('');
  const [channel, setChannel] = useState('');
  const [additionalCount, setAdditionalCount] = useState(0);
  const [additionalTiming, setAdditionalTiming] = useState(0);
  const [id, setId] = useState('');
  const [contractItem, setContractItem] = useState('');
  const [additionalContractItem, setAdditionalContractItem] = useState('');

  const [format, setFormat] = useState({ id: 0, name: '' });

  function replaceTemplateVars(template, vars) {
    return Object.keys(vars).reduce((currentTemplate, key) => {
      const regex = new RegExp(`\\+\\+\\+${key}\\+\\+\\+`, 'g');
      return currentTemplate.replace(regex, vars[key]);
    }, template);
  }

  function createNumbersInText(type) {
    let ones;
    if (type === 'female') {
      ones = ['', 'Одной', 'Двух', 'Трёх', 'Четырёх', 'Пяти', 'Шести', 'Семи', 'Восьми', 'Девяти'];
    } else if (type === 'male') {
      ones = ['', 'Одного', 'Двух', 'Трёх', 'Четырёх', 'Пяти', 'Шести', 'Семи', 'Восьми', 'Девяти'];
    }
    const teens = ['Десяти', 'Одиннадцати', 'Двенадцати', 'Тринадцати', 'Четырнадцати', 'Пятнадцати', 'Шестнадцати', 'Семнадцати', 'Восемнадцати', 'Девятнадцати'];
    const tens = ['', '', 'Двадцати', 'Тридцати', 'Сорока', 'Пятидесяти', 'Шестидесяти', 'Семидесяти', 'Восьмидесяти', 'Девяноста'];

    const numbersInText = {};

    for (let i = 1; i <= 100; i++) {
      if (i < 10) {
        numbersInText[i] = ones[i];
      } else if (i >= 10 && i < 20) {
        numbersInText[i] = teens[i - 10];
      } else if (i >= 20 && i < 100) {
        const tenPart = tens[Math.floor(i / 10)];
        const onePart = ones[i % 10];
        numbersInText[i] = `${tenPart} ${onePart}`.trim();
      } else if (i === 100) {
        numbersInText[i] = 'Ста';
      }
    }

    return numbersInText;
  }

  useEffect(() => {
    if (format.name && types.length > 0) {
      // Найти соответствующий шаблон
      const template = FORMAT_COMMENT_TEXT_VARIANTS[format.name];
      if (template) {
        const vars = {
          amountNumberFemale: count || 0,
          amountNumberMale: count || 0,
          amountTextFemale: createNumbersInText('female')[count] || 'Ноль',
          amountTextMale: createNumbersInText('male')[count] || 'Ноль',
          timingNumber: timing || 0,
          additionalTimingNumber: additionalTiming || 0,
          timingText: createNumbersInText('female')[timing] || 'Ноль',
          additionalTimingText: createNumbersInText('female')[additionalTiming] || 'Ноль',
          audioVisualAmount: count === '1' ? 'аудиовизуального' : 'аудиовизуальных',
          audioVisualAdditionalAmount: additionalCount === '1' ? 'аудиовизуального' : 'аудиовизуальных',
          integration: count === '1' ? 'интеграции' : 'интеграций',
          additionalIntegration: additionalCount === '1' ? 'интеграции' : 'интеграций',
          additionalAmountNumber: additionalCount || 0,
          additionalAmountText: createNumbersInText('male')[additionalCount] || 'Ноль',
          additionalWorks: additionalTiming === '1' ? 'произведения' : 'произведений',
          second: timing === '1' ? 'секунды' : 'секунд',
          additionalSecond: additionalTiming === '1' ? 'секунды' : 'секунд',
          section: showCategory,
          bloggerName: blogger,
          link,
          videoHosting,
          marketingFemale: count === '1' ? 'рекламной' : 'рекламных',
          marketingMale: count === '1' ? 'рекламного' : 'рекламных',
          post: count === '1' ? 'поста' : 'постов',
          minute: timing === '1' ? 'минуты' : 'минут',
          publicationNumber,
          channelNumber: platformCount,
          presented: timing === '1' ? 'представленной' : 'представленных',
          works: count === '1' ? 'произведения' : 'произведений',
          live: count === '1' ? 'прямого эфира' : 'прямых эфиров',
          channel,
        };

        // Применить шаблон
        const updatedContractItem = replaceTemplateVars(template, vars);
        setContractItem(updatedContractItem);
        setAdditionalContractItem(updatedContractItem);
      }
    } else {
      setContractItem('');
      setAdditionalContractItem('');
      setCount('');
      setTiming('');
      setShowCategory('');
      setVideoHosting('');
      setChannel('');
      setAdditionalCount(0);
      setAdditionalTiming(0);
      setNonRemovalPeriod('');
      setNonRemovalPeriodUnits('');
    }
  }, [format, count, timing, types]);

  useEffect(() => {
    change(
      count,
      additionalCount,
      timing,
      additionalTiming,
      channel,
      manualType,
      nonRemovalPeriod,
      nonRemovalPeriodUnits,
      showCategory,
      videoHosting,
      format.name === 'Другое' ? additionalContractItem : contractItem,
      format,
      index,
    );
  }, [count,
    additionalCount,
    timing,
    additionalTiming,
    channel,
    manualType,
    nonRemovalPeriod,
    nonRemovalPeriodUnits,
    showCategory,
    videoHosting,
    additionalContractItem,
    contractItem,
    format?.name,
    id, index]);

  useEffect(() => {
    setCount(publication.amount);
    setTiming(publication.timing);
    setManualType(publication.manual_type);
    setNonRemovalPeriod(publication.non_removal_period);
    setNonRemovalPeriodUnits(publication.non_removal_period_units);
    setFormat({ id: publication.type?.id, name: publication.type?.name });
    setShowCategory(publication.section);
    setVideoHosting(publication.video_hosting);
    setChannel(publication.channel);
    setAdditionalCount(publication.additional_amount);
    setAdditionalTiming(publication.additional_timing);
    setId(publication.id);
    setContractItem(publication.contract_item);
    setAdditionalContractItem(publication.contract_item);
    setStatus(isActive);
  }, []);

  return (
    <>
      <div className={classes.publication__item}>
        {/* Поле для ручного ввода типа публикации с выпадающим списком */}
        <div className={classes.filter__staff}>
          <SearchBar
            text={format}
            setText={setFormat}
            list={FORMAT_OPTIONS}
            type="formatPublications"
            placeholder="Формат"
          />

          <SquareArrow active={activeArrow} setActive={setActiveArrow} />

          {deleteBtn && (
          <div className={classes.delete} onClick={() => deleteHandler(index)} />
          )}

          {cancelBtn && (
          <CancelBtn
            onClick={() => deleteHandler(index)}
            style={{
              padding: '1px 8px',
              left: left || 412,
              position: 'absolute',
            }}
          />
          )}
        </div>

      </div>

      <CSSTransition
        in={activeArrow}
        timeout={400}
        classNames="application-publication"
        appear
        unmountOnExit
      >
        {format.name !== 'Другое'
          ? (
            <div className={classes.publication__inner}>
              {/* Поле количества, принимающее только числа */}
              <InputElement
                type="number"
                placeholder="Количество"
                value={count}
                setValue={setCount}
                className={classes.input}
                style={{ marginBottom: 0 }}
              />

              {/* Поле хронометража, принимающее только числа */}
              <InputElement
                type="number"
                placeholder="Хрон"
                value={timing}
                setValue={setTiming}
                className={classes.input}
                style={{ marginBottom: 0 }}
              />

              {/* Условно добавляем поле Шоу/Рубрика только для определенных типов публикаций */}
              {['Youtube. Интеграция', 'Youtube. Pre-roll', 'Youtube. Mid-roll', 'ВК. Интеграция', 'ВК. Pre-roll', 'ВК. Mid-roll'].includes(format.name) && (
              <InputElement
                type="text"
                placeholder="Шоу/Рубрика"
                value={showCategory}
                setValue={setShowCategory}
                className={classes.input}
                style={{ marginBottom: 0 }}
              />
              )}

              {/* Условно добавляем поля Видеохостинг и Канал только для Онлайн-вебинаров */}
              {format.name === 'Отдельные виды интеграций. Онлайн-вебинар' && (
              <>
                <InputElement
                  type="text"
                  placeholder="Видеохостинг"
                  value={videoHosting}
                  setValue={setVideoHosting}
                  className={classes.input}
                  style={{ marginBottom: 0 }}
                />
                <InputElement
                  type="text"
                  placeholder="Канал"
                  value={channel}
                  setValue={setChannel}
                  className={classes.input}
                  style={{ marginBottom: 0 }}
                />
              </>
              )}

              {/* Условно добавляем поля Дополнительное количество и Дополнительный хрон только для Twitch. Интеграция видео в стрим */}
              {format.name === 'Twitch. Интеграция видео в стрим' && (
              <>
                <InputElement
                  type="number"
                  placeholder="Дополнительное количество"
                  value={additionalCount}
                  setValue={setAdditionalCount}
                  className={classes.input}
                  style={{ marginBottom: 0 }}
                />
                <InputElement
                  type="number"
                  placeholder="Дополнительный хрон"
                  value={additionalTiming}
                  setValue={setAdditionalTiming}
                  className={classes.input}
                  style={{ marginBottom: 0 }}
                />
              </>
              )}

              <TextAreaAutoSize
                text="Предмет договора"
                placeholder="Выберите формат публикации..."
                value={contractItem}
                setValue={setContractItem}
                style={{
                  minWidth: '100%',
                  left: '-200px',
                  maxHeight: '500px',
                }}
                textStyle={{
                  minWidth: '200px',
                  left: '-61px',
                  position: 'relative',
                }}
                containerStyle={{ left: '-200px' }}
                disabled
              />
              <div className={classes.nonRemovalPeriod}>
                <InputElement
                  text="Период неудаления контента"
                  type="number"
                  placeholder="Количество"
                  value={nonRemovalPeriod}
                  setValue={setNonRemovalPeriod}
                  className={classes.input}
                  style={{ marginBottom: 0, left: '-261px' }}
                  textStyle={{ position: 'relative' }}
                  inputStyle={{ width: 200 }}
                />

                <InputElement
                  onlyWord
                  type="text"
                  placeholder="Единица измерения"
                  value={nonRemovalPeriodUnits}
                  setValue={setNonRemovalPeriodUnits}
                  className={classes.input}
                  style={{ marginBottom: 0, left: '-261px' }}
                  inputStyle={{
                    minWidth: '200px',
                    marginBottom: '0px',
                    left: '-248px',
                  }}
                />
              </div>
            </div>
          ) : (
            <TextAreaAutoSize
              text="Предмет договора"
              placeholder="Услуги по..."
              value={additionalContractItem}
              setValue={setAdditionalContractItem}
              style={{
                minWidth: '100%',
                left: '-200px',
                maxHeight: '500px',
              }}
              textStyle={{
                minWidth: '200px',
                left: '-61px',
                position: 'relative',
              }}
              containerStyle={{ left: '-200px' }}
            />
          )}

      </CSSTransition>
    </>
  );
});

export default PublicationItem;
