// @ts-nocheck
import React from 'react';

import LawyerSection from 'components/UI/Section/LawyerSection';

import OneContactInfo from './components/oneContactInfo';

const ContactInfo = React.memo((props) => {
  const {
    contacts,
    updateContacts,
    save,
    cancel,
    addContact,
    removeContact,
    inModal,
    className,
    sectionClassName,
    contentClassName,
    titleClassName,
    blogger,
    staffList,
    noClose,
  } = props;

  return (
    <LawyerSection
      title="Контактная информация"
      sectionClassName={sectionClassName}
      className={contentClassName}
      titleClassName={titleClassName}
      noClose={noClose}
      defaultActive
    >
      {contacts.map((contact, index) => (
        <OneContactInfo
          key={contact.id}
          contact={contact}
          updateContacts={updateContacts}
          save={save}
          cancel={cancel}
          addContact={addContact}
          removeContact={removeContact}
          inModal={inModal}
          noClose={noClose}
          className={className}
          index={index}
          length={contacts?.length}
          blogger={blogger}
          staffList={staffList}
        />
      ))}
    </LawyerSection>
  );
});

export default ContactInfo;
