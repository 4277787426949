// @ts-nocheck
import React from 'react';

import InputElement from 'components/UI/InputElements/InputElement';
import TextAreaAutoSize from 'components/UI/MyTextArea/TextAreaAutoSize';

import classes from '../../ProjectPage.module.scss';

function PublicationInfoItem(props) {
  const { publication, publicationTypes, textStyle } = props;

  return (
    <div className={classes.platform__wrapper}>
      <InputElement
        text="Формат публикации"
        value={publication?.type}
        textStyle={textStyle}
        dropdown={publicationTypes}
        disabled
      />

      {publication.type?.name === 'Другое' ? (
        <InputElement
          text="Предмет договора"
          value={publication?.contract_item}
          textStyle={{ minWidth: 230 }}
          textarea
          textareaStyle={{ minHeight: '90px' }}
          disabled
        />
      ) : (
        <>
          <InputElement
            text="Количество"
            value={publication?.amount}
            disabled
            textStyle={textStyle}
          />

          <InputElement
            text="Хрон"
            value={publication?.timing}
            disabled
            textStyle={textStyle}
          />

          {publication.type?.name === 'Twitch. Интеграция видео в стрим' && (
          <>
            <InputElement
              text="Дополнительное количество"
              value={publication?.additional_amount}
              disabled
              textStyle={textStyle}
            />

            <InputElement
              text="Дополнительный хрон"
              value={publication?.additional_timing}
              disabled
              textStyle={textStyle}
            />
          </>
          )}

          {['Youtube. Интеграция', 'Youtube. Pre-roll', 'Youtube. Mid-roll', 'ВК. Интеграция', 'ВК. Pre-roll', 'ВК. Mid-roll'].includes(publication.type?.name) && (
          <InputElement
            text="Шоу/Рубрика"
            value={publication?.section}
            disabled
            textStyle={textStyle}
          />
          )}

          {publication.type?.name === 'Отдельные виды интеграций. Онлайн-вебинар' && (
          <>
            <InputElement
              text="Видеохостинг"
              value={publication?.video_hosting}
              disabled
              textStyle={textStyle}
            />

            <InputElement
              text="Канал"
              value={publication?.channel}
              disabled
              textStyle={textStyle}
            />
          </>
          )}

          <InputElement
            text="Предмет договора"
            value={publication?.contract_item}
            textStyle={{ minWidth: 230 }}
            textarea
            textareaStyle={{ minHeight: '90px' }}
            disabled
          />

          <div className={classes.nonRemovalPeriod}>
            <InputElement
              text="Период неудаления контента"
              placeholder="Количество"
              value={publication?.non_removal_period}
              disabled
              textStyle={textStyle}
              style={{ marginBottom: 0 }}
            />

            <InputElement
              value={publication?.non_removal_period_units}
              placeholder="Единица измерения"
              disabled
              textStyle={textStyle}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default PublicationInfoItem;
