import qs from 'qs';

import { $authHost } from 'API';

import { getDate } from 'utils/getDate';
import { getSum } from 'utils/getSums';

export const fetchPaymentStatusInfo = async (
  page,
  limit,
  controller,
  filter,
) => {
  const params = { page, limit };

  if (filter) {
    // Массив для хранения сортировок по возрастанию/убыванию
    const sortArray = [];

    if (filter?.id) {
      params.project_project_id = Number(filter.id);
    }

    if (filter.id_status?.id === 0) {
      sortArray.push('project_id');
    }
    if (filter.id_status?.id === 1) {
      sortArray.push('-project_id');
    }

    let tempArray = [];
    filter?.project.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.project_id = tempArray;
    }

    tempArray = [];
    filter?.client_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.client_legal_entity = tempArray;
    }

    if (filter?.contract_basis) {
      params.number = filter.contract_basis;
    }

    if (filter?.total_sum) {
      params.total_sum = getSum(filter.total_sum);
    }

    tempArray = [];
    filter?.contract_format.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.contract_type = tempArray;
    }

    tempArray = [];
    filter?.our_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.contractor_legal_entity = tempArray;
    }

    if (filter?.margin_status?.id === 0) {
      sortArray.push('margin');
    } else if (filter?.margin_status?.id === 1) {
      sortArray.push('-margin');
    }

    tempArray = [];
    filter?.responsible.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.manager = tempArray;
    }

    tempArray = [];
    filter?.director.forEach((director) => tempArray.push(director.id));
    if (tempArray.length > 0) {
      params.grouphead = tempArray;
    }

    if (filter?.documents_date_start) {
      params.actual_month_after = getDate(filter.documents_date_start);
    }

    if (filter?.documents_date_end) {
      params.actual_month_before = getDate(filter.documents_date_end);
    }

    if (filter?.documents_date_deadline?.id === 0) {
      sortArray.push('collection_date');
    } else if (filter?.documents_date_deadline?.id === 1) {
      sortArray.push('-collection_date');
    }

    if (filter?.accounting_month_start) {
      params.accounting_month_after = getDate(filter.accounting_month_start);
    }

    if (filter?.accounting_month_end) {
      params.accounting_month_before = getDate(filter.accounting_month_end);
    }

    params.o = sortArray;
  }

  const { data } = await $authHost.get('/payment_status/contract/', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};

export const fetchOnePaymentStatus = async (id) => {
  const { data } = await $authHost.get(
    `/payment_status/contract/${id}/`,
  );
  return data;
};

export const fetchOnePaymentStatusIncome = async (id) => {
  const { data } = await $authHost.get(
    `/financier/payment_status/project/${id}/income/`,
  );
  return data;
};

export const fetchOnePaymentStatusExpense = async (id) => {
  const { data } = await $authHost.get(
    `/financier/payment_status/project/${id}/expense/`,
  );
  return data;
};

export const fetchOnePaymentStatusStat = async (id) => {
  const { data } = await $authHost.get(
    `/financier/payment_status/project/${id}/payment_stat/`,
  );
  return data;
};

export const exportGooglePaymentStatus = async (
  page,
  limit,
  controller,
  filter,
  columnsList,
) => {
  const params = { page, limit };

  const tempArray = [];
  columnsList.forEach((item) => tempArray.push(item));
  if (tempArray.length > 0) {
    params.fields = tempArray;
  }

  if (filter) {
    // Массив для хранения сортировок по возрастанию/убыванию
    const sortArray = [];

    if (filter?.id) {
      params.project_project_id = Number(filter.id);
    }

    if (filter.id_status?.id === 0) {
      sortArray.push('project_id');
    }
    if (filter.id_status?.id === 1) {
      sortArray.push('-project_id');
    }

    let tempArray = [];
    filter?.project.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.project_id = tempArray;
    }

    tempArray = [];
    filter?.client_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.client_legal_entity = tempArray;
    }

    if (filter?.contract_basis) {
      params.number = filter.contract_basis;
    }

    if (filter?.total_sum) {
      params.total_sum = getSum(filter.total_sum);
    }

    tempArray = [];
    filter?.contract_format.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.contract_type = tempArray;
    }

    tempArray = [];
    filter?.our_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.contractor_legal_entity = tempArray;
    }

    if (filter?.margin_status?.id === 0) {
      sortArray.push('margin');
    } else if (filter?.margin_status?.id === 1) {
      sortArray.push('-margin');
    }

    tempArray = [];
    filter?.responsible.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.manager = tempArray;
    }

    tempArray = [];
    filter?.director.forEach((director) => tempArray.push(director.id));
    if (tempArray.length > 0) {
      params.director = tempArray;
    }

    if (filter?.documents_date_start) {
      params.collection_date_after = getDate(filter.documents_date_start);
    }

    if (filter?.documents_date_end) {
      params.collection_date_before = getDate(filter.documents_date_end);
    }

    if (filter?.documents_date_deadline?.id === 0) {
      sortArray.push('collection_date');
    } else if (filter?.documents_date_deadline?.id === 1) {
      sortArray.push('-collection_date');
    }

    if (filter?.accounting_month_start) {
      params.accounting_month_after = getDate(filter.accounting_month_start);
    }

    if (filter?.accounting_month_end) {
      params.accounting_month_before = getDate(filter.accounting_month_end);
    }

    params.o = sortArray;
  }

  const { data } = await $authHost.get('/financier/payment_status/export/', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};
