import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import CommentStore from 'store/CommentStore';
import ContractsStore from 'store/ContractStore';
import FilterStore from 'store/FilterStore';
import MainMarketingStore from 'store/MainMarketingStore';
import ModalStore from 'store/ModalStore';
import NotificationStore from 'store/NotificationStore';
import ProjectStore from 'store/ProjectStore';
import RegistryFinanceStore from 'store/RegistryFinanceStore';
import ReportNewsStore from 'store/ReportNewsStore';
import ReportProjectStore from 'store/ReportProjectStore';
import ReportTaskStore from 'store/ReportTaskStore';
import ToastStore from 'store/ToastStore';

import UserStore from './store/UserStore';
import App from './App';

export const Context = createContext(null);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Context.Provider
    value={{
      user: new UserStore(),
      modal: new ModalStore(),
      toast: new ToastStore(),
      mainMarketing: new MainMarketingStore(),
      contracts: new ContractsStore(),
      projectsFinance: new RegistryFinanceStore(),
      projects: new ProjectStore(),
      notifications: new NotificationStore(),
      comments: new CommentStore(),
      reportProjects: new ReportProjectStore(),
      reportTasks: new ReportTaskStore(),
      reportNews: new ReportNewsStore(),
      filters: new FilterStore(),
    }}
  >
    <App />
  </Context.Provider>,
);
