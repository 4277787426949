// @ts-nocheck
import React, { useState } from 'react';

import InputElement from 'components/UI/InputElements/InputElement';

import SuccessBtn from '../../../Btns/SuccessBtn';
import { ADDITIONAL_FIELD } from '../Fields';

import classes from '../ModalCreateBlogger.module.css';

function AdditionalInfo(props) {
  const {
    state, setState, save, cancel, noDelete,
  } = props;

  const [active, setActive] = useState(false);

  const saveHandler = () => {
    setActive(false);
    save();
  };

  const cancelHandler = () => {
    setActive(false);
    cancel('additionalInfo');
  };

  return (
    <>
      {ADDITIONAL_FIELD.map((field) => (
        <InputElement
          key={field.id}
          text={field.label}
          textStyle={{ minWidth: 230 }}
          value={state[field.id]}
          onClick={() => setActive(true)}
          onChange={(event) => setState({ ...state, [`${field.id}`]: event.target.value })}
        />
      ))}

      {save && active && (
        <div className={classes.btnSave__wrapper}>
          <SuccessBtn
            text="Сохранить"
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={() => saveHandler()}
          />

          <SuccessBtn
            text="Отменить"
            type="white"
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={() => cancelHandler()}
          />
        </div>
      )}
    </>
  );
}

export default AdditionalInfo;
