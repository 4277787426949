// @ts-nocheck
import React, { useContext, useState } from 'react';
import { Context } from 'index';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import CheckBox from 'components/UI/MyCheckbox/CheckBox';
import MyModal from 'components/UI/MyModal/MyModal';

import {
  styleForModal,
  stylesForButtonApplyOff,
  stylesForButtonApplyOn,
  stylesForButtonCancelAll,
  stylesForButtonChooseAll,
  stylesForButtonOpenGoogleTable,
} from './consts/consts';

import classes from './ModalExportGoogle.module.scss';

const PAYMENT_DATES_ID = 'payment_dates';
const CLOSING_DOCS_ID = 'closing_docs';
const TOKEN_ID = 'token';
const PAID_SUM_SEPARATE_ID = 'paid_sum_separate';
const MARGIN_ID = 'margin';

function ModalExportGoogle({
  show,
  onHide,
  columnsList,
  setColumnsList,
  url,
  setUrl,
  click,
  props,
}) {
  const {
    headers,
  } = props;

  const { toast } = useContext(Context);

  const [activeButton, setActiveButton] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const close = () => {
    onHide();
  };

  const send = () => {
    if (columnsList.length === 0) {
      toast.setToastInfo('Необходимо выбрать минимум один столбец');
      toast.setShowError(true);
    } else {
      click();
      setIsClicked(true);
      toast.setToastInfo('Данные выгружаются, пожалуйста подождите');
      toast.setShowSuccess(true);
    }
  };

  const clear = () => {
    setUrl('');
    setColumnsList([]);
    setIsClicked(false);
    onHide();
  };

  const handleCheckboxChange = (columnId) => {
    // Определяем ID чекбоксов, которые должны быть удалены при добавлении нового
    const idsToRemove = [PAYMENT_DATES_ID, CLOSING_DOCS_ID, TOKEN_ID, PAID_SUM_SEPARATE_ID, MARGIN_ID];

    if (columnId === PAYMENT_DATES_ID || columnId === CLOSING_DOCS_ID || columnId === TOKEN_ID || columnId === PAID_SUM_SEPARATE_ID || columnId === MARGIN_ID) {
      // Проверяем, выбран ли чекбокс
      const isChecked = columnsList.includes(columnId);

      // Определяем новый список колонок
      const newList = columnsList
        .filter((item) => !idsToRemove.includes(item)) // Удаляем определенные ID, если они есть
        .concat(isChecked ? [] : [columnId]); // Добавляем или не добавляем новый ID в зависимости от состояния чекбокса

      // Устанавливаем новый список колонок
      setColumnsList(newList);
    } else {
      // Для остальных чекбоксов
      setColumnsList(columnsList.includes(columnId)
        ? columnsList.filter((item) => item !== columnId)
        : [...columnsList, columnId]);
    }
  };

  const chooseAllColumns = () => {
    if (!activeButton) {
      setColumnsList([]);
      let isHeader = false;

      headers.forEach((header) => {
        if (header.id === CLOSING_DOCS_ID || header.id === TOKEN_ID || header.id === MARGIN_ID) {
          isHeader = true;
        }
      });

      if (isHeader) {
        setColumnsList(headers
          .filter((column) => column.id !== PAYMENT_DATES_ID)
          .filter((column) => column.id !== CLOSING_DOCS_ID)
          .filter((column) => column.id !== TOKEN_ID)
          .filter((column) => column.id !== PAID_SUM_SEPARATE_ID)
          .filter((column) => column.id !== MARGIN_ID)
          .map((column) => column.id));
      } else {
        setColumnsList(headers.map((column) => column.id));
      }
    }
    setActiveButton(true);
  };

  const cancelAllColumns = () => {
    setColumnsList([]);
    setActiveButton(false);
  };

  return (
    <MyModal buttonBack show={show} onHide={close} style={styleForModal}>
      <h1>Выбор колонок для экспорта</h1>
      <div className={classes.checkbox__container}>
        {headers?.map((column) => (
          <div key={column.id} className={classes.wrapper}>
            <CheckBox
              name={column.id}
              key={column.id}
              value={column.id}
              label={column.label}
              onChange={() => handleCheckboxChange(column.id)}
              checked={columnsList?.includes(column.id)}
              transparentBg
            />
          </div>
        ))}
      </div>
      <div className={classes.btn__container}>
        <SuccessBtn
          text="Выбрать все"
          style={stylesForButtonChooseAll}
          onClick={() => chooseAllColumns()}
        />
        <SuccessBtn
          text="Отменить все"
          style={stylesForButtonCancelAll}
          onClick={() => cancelAllColumns()}
        />
      </div>

      <div className={classes.btn__container}>
        {!url ? (
          <SuccessBtn
            text={!isClicked ? 'Принять' : 'Подождите...'}
            style={!isClicked ? stylesForButtonApplyOn : stylesForButtonApplyOff}
            onClick={() => send()}
          />
        ) : (
          <SuccessBtn
            text="Открыть таблицу"
            style={stylesForButtonOpenGoogleTable}
            onClick={() => clear()}
            target="_blank"
            href={url}
          />
        )}
      </div>
    </MyModal>
  );
}

export default ModalExportGoogle;
