// @ts-nocheck
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { fetchAllProjects, fetchStaff } from 'API/ManagerAPI/AutocompleteAPI';
import {
  exportGooglePaymentStatus,
  fetchPaymentStatusInfo,
} from 'API/PaymentStatusAPI';
import { check } from 'API/UserAPI';
import { autocompleteAllLegalEntities } from '../../../API/LawyersAPI/AutocompleteAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import ModalExportGoogle from 'components/UI/Modals/ModalExportGoogle/ModalExportGoogle';
import PageTitle from 'components/UI/PageTitle/PageTitle';

import PaymentsTable from '../../UI/PaymentsTable/PaymentsTable';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from 'hooks/useLogout';

import { ONE_PAYMENT_STATUS_ROUTE } from 'utils/consts';

import { HEADERS, HEADERS_GOOGLE_EXPORT_PAYMENT_STATUS } from './consts/consts';
import { prepareDataForTable } from './helpers/helpers';

// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import FilterForPaymentStatus from './FilterForPaymentStatus';

const PaymentStatusPage = observer(() => {
  const { contracts, modal } = useContext(Context);
  const navigate = useNavigate();
  const [logout] = useLogout();
  const table = useRef();
  const isFirstRender = useRef(true);
  const controller = new AbortController();

  const [fetching, setFetching] = useState(true);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [url, setUrl] = useState('');

  const [projects, setProjects] = useState([]);
  const [directorList, setDirectorList] = useState([]);
  const [managers, setManagers] = useState([]);
  const [allLegalEntity, setAllLegalEntity] = useState([]);

  const [columnsList, setColumnsList] = useState([]);

  useEffect(() => {
    check()
      .then(() => Promise.all([
        autocompleteAllLegalEntities(),
        fetchStaff({ role: 1 }),
        fetchStaff({ job: 0 }),
        fetchAllProjects(),
      ]))
      .then(([allLegalEntities, directorList, managers, projects]) => {
        setAllLegalEntity(allLegalEntities);
        setDirectorList(directorList);
        setManagers(managers);
        setProjects(projects);
      })
      .catch((error) => logout(error));
  }, []);

  const [fetchContracts, isContractsLoading] = useFetching(
    async (page, limit, signal, filter, update) => {
      await check().catch((error) => logout(error));

      const data = await fetchPaymentStatusInfo(page, limit, signal, filter);
      const formattedData = prepareDataForTable(data?.results);

      contracts.setPaymentStatusContractsTotalCount(data.count);

      if (update || contracts.paymentStatusContractsPage === 1) {
        contracts.setPaymentStatusContractsPage(2);
        contracts.setPaymentStatusContracts(formattedData, 'update');
      } else {
        contracts.setPaymentStatusContractsPage(contracts.paymentStatusContractsPage + 1);
        contracts.setPaymentStatusContracts(formattedData);
      }

      setFetching(false);
    },
  );

  const totalPages = Math.ceil(
    contracts.paymentStatusContractsTotalCount
      / contracts.paymentStatusContractsLimit,
  );

  /* Запрос на сервер при скролле до конца таблицы */

  useEffect(() => {
    if (fetching) {
      if (contracts.paymentStatusContractsFilter) {
        fetchContracts(
          contracts.paymentStatusContractsPage,
          contracts.paymentStatusContractsLimit,
          controller.signal,
          contracts.paymentStatusContractsFilter,
        );
      } else {
        fetchContracts(
          contracts.paymentStatusContractsPage,
          contracts.paymentStatusContractsLimit,
          controller.signal,
        );
      }
    }

    return () => {
      controller.abort();
    };
  }, [fetching]);

  /* Когда доскролили до нижней части таблицы (первое условие)
    Проверяем текущую страницу с общим количеством страниц(второе условие)
    Далее делаем запрос
  */

  const scrollHandler = (event) => {
    if (
      event.target.scrollHeight
        - (event.target.scrollTop + event.target.clientHeight)
        < 600
      && contracts.paymentStatusContractsPage <= totalPages
    ) {
      setFetching(true);
    }
  };

  // Наблюдение за скроллом

  useEffect(() => {
    if (isContractsLoading) return;

    const element = table.current;
    element.addEventListener('scroll', scrollHandler);
    return function () {
      element.removeEventListener('scroll', scrollHandler);
    };
  }, [isContractsLoading]);

  const setLocalStorageValue = (value) => {
    localStorage.setItem('paymentStatusHiddelColumns', JSON.stringify(value));
  };

  const openDetailsHandler = (id) => {
    navigate(`${ONE_PAYMENT_STATUS_ROUTE}/${id}`);
    contracts.setPaymentStatusContractsPage(1);
    contracts.setPaymentStatusContracts([]);
  };

  const click = async () => {
    try {
      const res = await exportGooglePaymentStatus(
        contracts.paymentStatusContractsPage,
        contracts.paymentStatusContractsLimit,
        controller.signal,
        contracts.paymentStatusContractsFilter,
        columnsList,
      );
      setUrl(res.sheet_url);
    } catch (err) {
      console.error(err);
    }
  };

  const exportGoogleHandler = () => {
    modal.setModalExportGoogleProps({
      headers: HEADERS_GOOGLE_EXPORT_PAYMENT_STATUS,
    });
    modal.setModalExportGoogleVisible(true);
  };

  if (isContractsLoading && isFirstRender.current) {
    isFirstRender.current = false;
    return <Spinner animation="grow" />;
  }

  const propsForPaymentsTable = {
    tableRef: table,
    headers: HEADERS,
    data: contracts.paymentStatusContracts,
    setLocalStorageValue,
    initialHiddenColumns: JSON.parse(localStorage.getItem('paymentStatusHiddenColumns')),
    onOpenDetails: openDetailsHandler,
    filterIsOpen,
    setFilterIsOpen,
  };

  const propsForFilterPaymentStatus = {
    isOpen: filterIsOpen,
    setIsOpen: setFilterIsOpen,
    projectList: projects,
    managersList: managers,
    directorList,
    allLegalEntity,
    fetchContracts,
  };

  return (
    <div>
      {/* <PageTitle
        button={(
          <SuccessBtn
            text="Экспорт в Google"
            style={{ padding: '4px 18px', fontSize: 11 }}
            onClick={exportGoogleHandler}
          />
              )}
        style={{ justifyContent: 'space-between' }}
      >
        Статус оплат
      </PageTitle>

      <PaymentsTable
        {...propsForPaymentsTable}
        filterWindow={(<FilterForPaymentStatus {...propsForFilterPaymentStatus} />
        )}
      />

      <ModalExportGoogle
        show={modal.modalExportGoogleVisible}
        onHide={() => modal.setModalExportGoogleVisible(false)}
        props={modal.modalExportGoogleProps}
        columnsList={columnsList}
        setColumnsList={setColumnsList}
        click={click}
        url={url}
        setUrl={setUrl}
        paymentStatusMark
      /> */}
    </div>
  );
});
export default PaymentStatusPage;
