// @ts-nocheck
import { makeAutoObservable } from 'mobx';

export default class ReportTaskStore {
  constructor() {
    this._reportTasks = [];
    this._reportTasksTotalCount = 0;
    this._reportTasksPage = 1;
    this._reportTasksLimit = 15;
    this._reportTasksFilter = undefined;

    makeAutoObservable(this);
  }

  setReportTasks(reportTasks, update) {
    if (update) {
      return (this._reportTasks = reportTasks);
    }

    if (this._reportTasksPage === 1) {
      return (this._reportTasks = reportTasks);
    }

    reportTasks.forEach((reportTask) => this._reportTasks.push(reportTask));
  }

  setReportTasksTotalCount(count) {
    this._reportTasksTotalCount = count;
  }

  setReportTasksPage(page) {
    this._reportTasksPage = page;
  }

  setReportTasksFilter(filter) {
    this._reportTasksFilter = filter;
  }

  get reportTasks() {
    return this._reportTasks;
  }

  get reportTasksTotalCount() {
    return this._reportTasksTotalCount;
  }

  get reportTasksPage() {
    return this._reportTasksPage;
  }

  get reportTasksLimit() {
    return this._reportTasksLimit;
  }

  get reportTasksFilter() {
    return this._reportTasksFilter;
  }
}
