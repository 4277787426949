import Auth from 'pages/Auth';
import BloggerPage from 'pages/BloggerPage';
import Bloggers from 'pages/Bloggers';
import ClientPage from 'pages/ClientPage';
import Clients from 'pages/Clients';
import ContractPage from 'pages/ContractPage';
import Contracts from 'pages/Contracts';
import CostPage from 'pages/FinancePages/CostPage';
import CostsPage from 'pages/FinancePages/CostsPage';
import LegalEntities from 'pages/LegalEntities';
import LegalEntityPage from 'pages/LegalEntityPage';
import Main from 'pages/Main';
import MainFinance from 'pages/MainFinance';
import Salaries from 'pages/ManagerPages/Salaries';
import MyTasks from 'pages/MyTasks';
import NotificationPage from 'pages/NotificationPage';
import PaymentsStatus from 'pages/PaymentsStatus';
import PaymentStatus from 'pages/PaymentStatus';
import ProjectAccountingPage from 'pages/ProjectAccountingPage';
import ProjectPage from 'pages/ProjectPage';
import Report from 'pages/Report';
import Settings from 'pages/Settings';
import StaffCard from 'pages/StaffCard';
import Teams from 'pages/Teams';

import {
  BLOGGERS_ROUTE,
  CLIENTS_ROUTE,
  CONTRACTS_MAIN_ROUTE,
  COST_ROUTE,
  FINANCE_MAIN_ROUTE,
  FORGOT_ROUTE,
  LEGAL_ENTITY_ROUTE,
  LOGIN_ROUTE,
  MAIN_ROUTE,
  NOTIFICATION_ROUTE,
  ONE_BLOGGER_ROUTE,
  ONE_CLIENT_ROUTE,
  ONE_CONTRACT_ROUTE,
  ONE_COST_ROUTE,
  ONE_LEGAL_ENTITY_ROUTE,
  ONE_PAYMENT_STATUS_ROUTE,
  PAYMENT_STATUS_ROUTE,
  PROJECT_ACCOUNTING_ROUTE,
  PROJECT_ROUTE,
  RECOVER_ROUTE,
  REPORT_ROUTE,
  SALARIES_ROUTE,
  SETTING_ROUTE,
  STAFF_CARD_ROUTE,
  TASKS_ROUTE,
  TEAMS_ROUTE,
} from 'utils/consts';

export const publicRoutes = [
  { path: LOGIN_ROUTE, element: Auth },
  { path: FORGOT_ROUTE, element: Auth },
  { path: `${RECOVER_ROUTE}/:uid/:token`, element: Auth },
];

export const privateRoutes = [
  { path: MAIN_ROUTE, element: Main },
  { path: CONTRACTS_MAIN_ROUTE, element: Contracts },
  // { path: FINANCE_MAIN_ROUTE, element: MainFinance },
  { path: PROJECT_ACCOUNTING_ROUTE, element: ProjectAccountingPage },
  { path: TASKS_ROUTE, element: MyTasks },
  { path: CLIENTS_ROUTE, element: Clients },
  { path: `${ONE_CLIENT_ROUTE}/:id`, element: ClientPage },
  { path: TEAMS_ROUTE, element: Teams },
  { path: LEGAL_ENTITY_ROUTE, element: LegalEntities },
  { path: `${ONE_LEGAL_ENTITY_ROUTE}/:id`, element: LegalEntityPage },
  { path: `${PROJECT_ROUTE}/:id`, element: ProjectPage },
  { path: `${ONE_CONTRACT_ROUTE}/:id`, element: ContractPage },
  { path: `${STAFF_CARD_ROUTE}/:id`, element: StaffCard },
  { path: REPORT_ROUTE, element: Report },
  { path: NOTIFICATION_ROUTE, element: NotificationPage },
  // { path: PAYMENT_STATUS_ROUTE, element: PaymentsStatus },
  { path: `${ONE_PAYMENT_STATUS_ROUTE}/:id`, element: PaymentStatus },
  { path: SALARIES_ROUTE, element: Salaries },
  { path: COST_ROUTE, element: CostsPage },
  { path: `${ONE_COST_ROUTE}/:id`, element: CostPage },
  { path: BLOGGERS_ROUTE, element: Bloggers },
  { path: `${ONE_BLOGGER_ROUTE}/:id`, element: BloggerPage },
  { path: SETTING_ROUTE, element: Settings },
];
