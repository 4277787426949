// @ts-nocheck
import React from 'react';

import NotificationContainer from '../NotificationContainer/NotificationContainer';
import Section from '../Section/Section';

import classes from './HistoryСhanges.module.css';

function HistoryСhanges(props) {
  const { history, tableStyle } = props;

  return (
    <div style={tableStyle}>
      <Section
        title="История изменений"
        className={classes.container}
        style={{ marginTop: 10 }}
      >
        {history?.map((historyElement) => (
          <NotificationContainer
            key={historyElement.id}
            notification={historyElement}
            noLink
          />
        ))}
      </Section>
    </div>
  );
}

export default HistoryСhanges;
