// @ts-nocheck
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Dropdown } from 'react-bootstrap';
import { Context } from 'index';

import { patchTaskUser } from 'API/TaskAPI';
import { check } from 'API/UserAPI';

import { useLogout } from 'hooks/useLogout';

import { getStyle } from 'utils/getStyle';

import CancelBtn from '../Btns/CancelBtn';

import classes from './SearchBar.module.css';

const SearchBar = React.memo((props) => {
  const {
    text,
    setText,
    checkResponsible,
    multiple,
    placeholder,
    list,
    type,
    fetchMyTasks,
    fetchMyTasksResponsible,
    updateProject,
    email,
    disabled,
    deleteElement,
    containerClassName,
    className,
    classNameMenu,
    inputStyle,
    id,
    style,
    onKeyPress,
    onClick,
    onClickDropdownItem,
    setResponsibleMargin,
    parent,
  } = props;

  const { toast } = useContext(Context);

  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const inputRef = useRef();
  const rootEl = useRef(null);
  const [logout] = useLogout();

  // Клик вне окошка аккордиона
  useEffect(() => {
    const onClick = (e) => {
      if (rootEl.current) {
        rootEl.current.contains(e.target) || setSuggestions([]);
      }
    };

    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  /* При нажатии делается проверка на существование такого же ответственного
    и далее вставляются данные из выпадающего списка */
  const onSuggestHandler = (changeText, suggestId, suggestion) => {
    if (type === 'staff') {
      if (checkResponsible) {
        if (checkResponsible(suggestId)) {
          toast.setToastInfo('Такой ответственный уже выбран');
          toast.setShowError(true);
          return;
        }
      }
    }

    if (fetchMyTasks || fetchMyTasksResponsible) {
      let data = {};
      if (type !== 'bloggerApplication') {
        data = fetchMyTasks
          ? { project: { id: suggestId } }
          : { responsible: [{ id: suggestId }] };
      } else {
        data = { blogger: { id: suggestId } };
      }

      if (parent && parent?.subtasks?.length > 0) {
        const editedSubtasks = parent.subtasks.map((subtask) => ((subtask.id === id)
          ? {
            ...subtask,
            responsible: [{ id: suggestId }],
          } : subtask
        ));
        check()
          .then(() => {
            patchTaskUser(parent.id, { subtasks: editedSubtasks }).then(() => {
              if (fetchMyTasks) {
                fetchMyTasks();
              } else if (fetchMyTasksResponsible) {
                fetchMyTasksResponsible();
              }
            });
          })
          .catch((error) => logout(error));
      } else {
        check()
          .then(() => {
            patchTaskUser(id, data).then(() => {
              if (fetchMyTasks) {
                fetchMyTasks();
              } else if (fetchMyTasksResponsible) {
                fetchMyTasksResponsible();
              }
            });
          })
          .catch((error) => logout(error));
      }
    }

    if (multiple) {
      if (type === 'staffWithTeams') {
        multiple({ name: changeText, id: suggestId, team: suggestion.team });
      } else {
        multiple({ name: changeText, id: suggestId });
      }
      setText('', text.fieldId);
    } else if (type === 'bloggerApplication') {
      setText(changeText, text.fieldId, suggestId, suggestion);
    } else {
      setText(changeText, text.fieldId, suggestId);
    }
    setSuggestions([]);
  };

  // При измении поля инпута, выпадает список возможных вариантов и обновляется значение инпута
  const onChangeHandler = (changeText) => {
    let matches = [];

    if (fetchMyTasks) {
      if (changeText.length > 0) {
        const str = changeText.replace(/[|\\{()[^$+*?.-]/g, '\\$&');
        matches = list.filter((element) => {
          const regex = new RegExp(`${str}`, 'gi');
          return element?.name.match(regex);
        });
      }

      if (updateProject) {
        updateProject();
      }

      setSuggestions(matches.slice(0, 50));
      setText(changeText, text.fieldId);
    }

    if (type === 'project') {
      if (changeText.length > 0) {
        const str = changeText.replace(/[|\\{()[^$+*?.-]/g, '\\$&');
        matches = list.filter((element) => {
          const regex = new RegExp(`${str}`, 'gi');
          return element.long_name.match(regex);
        });
      }

      if (updateProject) {
        updateProject();
      }

      setSuggestions(matches.slice(0, 50));
      setText(changeText, text.fieldId);
    }

    if (
      type === 'blogger'
      || type === 'bloggerApplication'
      || type === 'client'
    ) {
      if (changeText.length > 0) {
        matches = list.filter((element) => {
          const regex = new RegExp(`${changeText}`, 'gi');
          return element?.name?.match(regex) || element?.fullname?.match(regex);
        });
      }

      setSuggestions(matches.slice(0, 50));
      setText(changeText, text.fieldId);
    }

    if (type === 'staff' || fetchMyTasksResponsible) {
      if (changeText.length > 0) {
        matches = list.filter((staffElement) => {
          const regex = new RegExp(`${changeText}`, 'gi');
          return (
            staffElement?.email?.match(regex)
            || staffElement?.fullname?.match(regex)
            || staffElement?.name?.match(regex)
          );
        });
      }

      setSuggestions(matches.slice(0, 50));
      setText(changeText, text.fieldId);
    }

    if (type === 'staffWithTeams') {
      if (changeText.length > 0) {
        matches = list.filter((staffElement) => {
          const regex = new RegExp(`${changeText}`, 'gi');
          return (
            staffElement?.email?.match(regex)
            || staffElement?.fullname?.match(regex)
            || staffElement?.name?.match(regex)
          );
        });
      }

      setSuggestions(matches.slice(0, 50));
      setText(changeText, text.fieldId);
    }

    if (type === 'legal_details') {
      if (changeText.length > 0) {
        matches = list.filter((element) => {
          const regex = new RegExp(`${changeText}`, 'gi');
          return element.legal_name.match(regex);
        });
      }

      setSuggestions(matches.slice(0, 50));
      setText(changeText, text.fieldId);
    }

    if (type === 'formatPublications') {
      if (changeText.length > 0) {
        const regexSafeSearchString = changeText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(regexSafeSearchString, 'gi');

        matches = list.filter((option) => option.label.match(regex));
      }

      setSuggestions(matches);
      setText({ ...text, name: changeText });
    }
    setIsDropdownActive(true);
  };

  const clickHandler = (event, suggestion) => {
    if (setResponsibleMargin) {
      setResponsibleMargin(suggestion);
    }
    if (email) {
      onSuggestHandler(suggestion.email, suggestion.id);
    } else if (type === 'staff' || fetchMyTasksResponsible) {
      onSuggestHandler(suggestion.fullname, suggestion.id);
    }

    if (type === 'staffWithTeams') {
      onSuggestHandler(suggestion.name, suggestion.id, suggestion);
    }

    if (type === 'legal_details') {
      onSuggestHandler(suggestion.legal_name, suggestion.id);
    }

    if (type === 'bloggerApplication') {
      onSuggestHandler(suggestion.name, suggestion.id, suggestion);
    }

    if (
      type === 'client'
      || fetchMyTasks
      || type === 'blogger'
    ) {
      onSuggestHandler(suggestion.name, suggestion.id);
    }

    if (type === 'project') {
      onSuggestHandler(suggestion.long_name, suggestion.id);
    }

    if (onClickDropdownItem) {
      onClickDropdownItem(event, suggestion.fullname || suggestion.name);
    }

    if (type === 'formatPublications') {
      setText({ ...text, id: suggestion.id, name: suggestion.label });
      setSuggestions([]);
    }

    setIsDropdownActive(false);
  };

  return (
    <div className={[classes.container, containerClassName].join(' ')} style={style}>
      <input
        className={[classes.info__input, className].join(' ')}
        onChange={(event) => onChangeHandler(event.target.value)}
        value={text?.name}
        required={props?.required}
        placeholder={placeholder}
        disabled={disabled}
        onKeyPress={onKeyPress}
        onClick={onClick}
        style={inputStyle}
        ref={inputRef}
      />

      {deleteElement && (
      <CancelBtn
        type="button"
        onClick={() => {
          deleteElement(text.fieldId);
        }}
        style={{ position: 'absolute', right: '-25px' }}
      />
      )}

      <Dropdown.Menu
        className={['my__dropdown__inner', classNameMenu].join(' ')}
        style={getStyle(suggestions)}
        show={suggestions.length > 0}
        ref={rootEl}
      >
        {suggestions.length > 0
            && suggestions.map((suggestion) => (
              <Dropdown.Item
                key={suggestion.id}
                onClick={(event) => clickHandler(event, suggestion)}
                className="my__dropdown__item"
              >
                {type === 'staffWithTeams' && <div>{suggestion?.name}</div>}
                {type === 'formatPublications' && <div>{suggestion?.label}</div>}
                {type === 'staff' && (
                  <div>{suggestion.fullname}</div>
                )}

                {(fetchMyTasks
                  || type === 'client'
                  || type === 'blogger'
                  || type === 'bloggerApplication') && (
                  <span>{suggestion?.name}</span>
                )}

                {type === 'project'
                && <span>{suggestion.long_name}</span>}

                {type === 'legal_details' && (
                  <span>{suggestion.legal_name}</span>
                )}
              </Dropdown.Item>
            ))}
      </Dropdown.Menu>
    </div>
  );
});

export default SearchBar;
