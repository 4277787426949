// @ts-nocheck

import React, { useContext, useEffect, useState } from 'react';
import { Context } from 'index';

import {
  deleteOneContractFiles,
  postOneContractFiles,
} from 'API/LawyersAPI/ContractAPI';
import { check } from 'API/UserAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import Files from 'components/UI/Files/Files';
import InputElement from 'components/UI/InputElements/InputElement';
import MyInputFile from 'components/UI/MyInput/MyInputFile';
import LawyerSection from 'components/UI/Section/LawyerSection';
import UploadFilesContainer from 'components/UI/UploadFilesContainer/UploadFilesContainer';

import ContractElement from '../../../UI/Modals/ModalCreateContract/components/ContractElement';
import SearchBar from '../../../UI/SearchBar/SearchBar';

import { useLogout } from 'hooks/useLogout';
import { useProgress } from 'hooks/useProgress';

import { getDate } from '../../../../utils/getDate';

import classes from '../ProjectPage.module.scss';

function CommonInfo(props) {
  const {
    lawyerList,
    documentList,
    project,
    selectedContractorLegalEntity,
    setSelectedContractorLegalEntity,
    contractorLegalEntityList,
    contractFormatList,
    selectedClientLegalEntity,
    setSelectedClientLegalEntity,
    changeSelectedContractorLegalEntity,
    changeSelectedClientLegalEntity,
    clientLegalEntityList,
    save,
    fetchContract,
    isContract,
  } = props;

  const { modal } = useContext(Context);
  const [logout] = useLogout();

  const [activeBtn, setActiveBtn] = useState(false);

  const [contractFormat, setContractFormat] = useState({ id: null, name: '' });

  const [manager, setManager] = useState([]);
  const [staffContacts, setStaffContacts] = useState([]);
  const [lawyer, setLawyer] = useState([]);
  const [document, setDocument] = useState([]);

  const [contacts, setContacts] = useState([{ id: Date.now(), email: '' }]);

  const [isVisible, setIsVisible] = useState(false);

  const [advObject, setAdvObject] = useState('');
  const [advObjectComment, setAdvObjectComment] = useState('');
  const [dateBid, setDateBid] = useState(null);

  const [dateContract, setDateContract] = useState(new Date());
  const [isContractSend, setIsContractSend] = useState(false);
  const [numContract, setNumContract] = useState('');

  const [branchName, setBranchName] = useState('');
  const [files, setFiles] = useState([]);

  const updateLawyer = (element) => {
    setLawyer([element]);
  };

  const updateDocument = (element) => {
    setDocument([element]);
  };

  const isBloggerFormat = () => contractFormat.id === 2;

  const [progressFiles, addFileFunc] = useProgress(
    setIsVisible,
    fetchContract,
    project.id,
    logout,
    postOneContractFiles,
  );

  const addFileHandler = (event) => {
    const files = [...event.target.files];
    files.forEach((file) => {
      addFileFunc(file);
    });
  };

  const deleteFileHandler = (name, fileId) => {
    modal.setModalConfirmVisible(true);

    modal.setModalConfirmProps({
      text: `Вы хотите удалить файл ${name}?`,
      click: () => {
        check()
          .then(() => {
            deleteOneContractFiles(fileId).then(() => {
              fetchContract('common_files');
            });
          })
          .catch((error) => logout(error));
      },
    });
  };

  const clickHandler = () => {
    setActiveBtn(true);
  };

  const cancelHandler = () => {
    const {
      type,
      client_legal_entity,
      contractor_legal_entity,
      manager,
      staff_contacts,
      lawyer,
      doc_manager,
      blogger_contacts,
      client_contacts,
      adv_object,
      adv_object_comment,
      bid_sent_datetime,
      is_sent,
      sent_datetime,
      number,
      post_branch,
    } = project;

    if (contractor_legal_entity.name) {
      setSelectedContractorLegalEntity([{ ...contractor_legal_entity, name: contractor_legal_entity?.legal_name, fieldId: Date.now() }]);
    }
    if (client_legal_entity.name) {
      setSelectedClientLegalEntity([{ ...client_legal_entity, name: client_legal_entity?.legal_name, fieldId: Date.now() }]);
    }

    setActiveBtn(false);
    setContractFormat(type);
    setManager(manager);
    setStaffContacts(staff_contacts);
    setLawyer(lawyer);
    setDocument(doc_manager);

    if (type.id === 2 && blogger_contacts.length > 0) {
      setContacts(blogger_contacts);
    } else if (client_contacts.length > 0) {
      setContacts(client_contacts);
    }

    setAdvObject(adv_object);
    setAdvObjectComment(adv_object_comment);
    setDateBid(bid_sent_datetime);
    setIsContractSend(is_sent);
    setDateContract(sent_datetime);
    setNumContract(number);
    setBranchName(post_branch);
  };

  useEffect(() => {
    if (project.sent_datetime) {
      setDateContract(project.sent_datetime);
    } else if (isContractSend === true) {
      setDateContract(getDate(Date.now()));
    } else {
      setDateContract('');
    }
  }, [isContractSend]);

  useEffect(() => {
    setContractFormat(project.type);
    setManager(project.manager);
    setStaffContacts(project.staff_contacts);
    setLawyer(project.lawyer);
    setDocument(project.doc_manager);
    setAdvObject(project.adv_object);
    setAdvObjectComment(project.adv_object_comment);
    setDateBid(project.bid_sent_datetime);
    setIsContractSend(project.is_sent);
    setDateContract(project.sent_datetime);
    setNumContract(project.number);
    setBranchName(project.post_branch);
    setFiles(project.files);

    if (project.type.id === 2) {
      setContacts(
        project.blogger_contacts.length > 0
          ? project.blogger_contacts
          : [{ id: Date.now(), email: '' }],
      );
    } else {
      setContacts(
        project.client_contacts.length > 0
          ? project.client_contacts
          : [{ id: Date.now(), email: '' }],
      );
    }
  }, [project]);

  const saveCommonHandler = () => {
    const data = {
      project: {
        id: project.project.id,
      },
      adv_object: advObject,
      adv_object_comment: advObjectComment,
      number: numContract,
      sent_datetime: dateContract ? getDate(dateContract, true) : '',
      post_branch: branchName,
      is_sent: isContractSend,
    };

    const sentManager = [];

    manager.forEach((item) => {
      sentManager.push({ id: item.id });
    });

    data.manager = sentManager;

    if (document[0]?.id) {
      data.doc_manager = [{ id: document[0].id }];
    }

    if (lawyer[0]?.id) {
      data.lawyer = [{ id: lawyer[0].id }];
    }

    if (selectedClientLegalEntity[0]?.id) {
      data.client_legal_entity = { id: selectedClientLegalEntity[0].id };
    }

    if (selectedContractorLegalEntity[0].id) {
      data.contractor_legal_entity = { id: selectedContractorLegalEntity[0].id };
    }

    save(data, 'commonInfo').then(() => {
      setActiveBtn(false);
    });
  };

  return (
    <>
      <UploadFilesContainer
        progressFiles={progressFiles}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
      <LawyerSection
        title="Общая информация"
        contentStyle={{ overflow: 'hidden' }}
        defaultActive
      >
        <div className={classes.wrapper}>
          <InputElement
            text="Формат договора"
            dropdown={contractFormatList}
            value={contractFormat}
            setValue={setContractFormat}
            textStyle={{ minWidth: 230 }}
            onClick={clickHandler}
            disabled
          />

          {contractFormat.id === 4 || contractFormat.id === 7 ? (
            <>
              <ContractElement
                text="ЮЛ заказчика"
                style={{ alignItems: 'center' }}
                textStyle={{ minWidth: 230, width: 230 }}
              >
                <SearchBar
                  text={selectedClientLegalEntity[0]}
                  setText={changeSelectedClientLegalEntity}
                  list={contractFormat.id === 7
                    ? clientLegalEntityList
                    : contractorLegalEntityList}
                  type="legal_details"
                  onClick={clickHandler}
                  containerClassName={classes.searchContainer}
                  placeholder="Неизвестно"
                  disabled={!isContract}
                />
              </ContractElement>

              <ContractElement
                text="ЮЛ исполнителя"
                style={{ alignItems: 'center' }}
                textStyle={{ minWidth: 230, width: 230 }}
              >
                <SearchBar
                  text={selectedContractorLegalEntity[0]}
                  setText={changeSelectedContractorLegalEntity}
                  list={contractFormat.id === 7
                    ? contractorLegalEntityList
                    : clientLegalEntityList}
                  type="legal_details"
                  onClick={clickHandler}
                  containerClassName={classes.searchContainer}
                  placeholder="Неизвестно"
                  disabled={!isContract}
                />
              </ContractElement>
            </>
          ) : (
            <>
              <ContractElement
                text="ЮЛ заказчика"
                style={{ alignItems: 'center' }}
                textStyle={{ minWidth: 230, width: 230 }}
              >
                <SearchBar
                  text={selectedClientLegalEntity[0]}
                  setText={changeSelectedClientLegalEntity}
                  list={isBloggerFormat() ? contractorLegalEntityList : clientLegalEntityList}
                  type="legal_details"
                  onClick={clickHandler}
                  containerClassName={classes.searchContainer}
                  placeholder="Неизвестно"
                  disabled={!isContract}
                />
              </ContractElement>

              <ContractElement
                text="ЮЛ исполнителя"
                style={{ alignItems: 'center' }}
                textStyle={{ minWidth: 230, width: 230 }}
              >
                <SearchBar
                  text={selectedContractorLegalEntity[0]}
                  setText={changeSelectedContractorLegalEntity}
                  list={isBloggerFormat() ? clientLegalEntityList : contractorLegalEntityList}
                  type="legal_details"
                  onClick={clickHandler}
                  containerClassName={classes.searchContainer}
                  placeholder="Неизвестно"
                  disabled={!isContract}
                />
              </ContractElement>
            </>
          )}

          {manager.map((element) => (
            <ContractElement
              key={element.id}
              text="Менеджер"
              textStyle={{ minWidth: 230, width: 230, height: 32 }}
            >
              <div style={{ width: '100%' }}>
                <InputElement value={element.fullname} disabled />

                <InputElement value={element.email} disabled />
              </div>
            </ContractElement>
          ))}

          {lawyer.map((element) => (
            <ContractElement
              key={element.id}
              text="Юрист"
              textStyle={{ minWidth: 230, width: 230, height: 32 }}
            >
              <div style={{ width: '100%' }}>
                <InputElement
                  value={element}
                  setValue={updateLawyer}
                  dropdown={lawyerList}
                  onClick={clickHandler}
                />

                <InputElement value={element.email} disabled />
              </div>
            </ContractElement>
          ))}

          {document.map((element) => (
            <ContractElement
              key={element.id}
              text="ДОК"
              textStyle={{ minWidth: 230, width: 230, height: 32 }}
            >
              <div style={{ width: '100%' }}>
                <InputElement
                  value={element}
                  setValue={updateDocument}
                  dropdown={documentList}
                  onClick={clickHandler}
                />

                <InputElement value={element.email} disabled />
              </div>
            </ContractElement>
          ))}

          {staffContacts.length > 0 && (
            <ContractElement
              text="Дополнительные контактные лица"
              textStyle={{
                minWidth: 230,
                width: 230,
                height: 32,
              }}
            >
              <div style={{ width: '100%' }}>
                {staffContacts.map((element) => (
                  <InputElement
                    key={element.id}
                    value={element.email}
                    disabled
                  />
                ))}
              </div>
            </ContractElement>
          )}

          {contractFormat.id !== 3 && (
            <ContractElement
              text={`Контакты ${
                contractFormat.id === 2 ? 'исполнителя' : 'клиента'
              }`}
              style={{
                alignItems: 'start',
                marginBottom: 10,
              }}
              textStyle={{
                minWidth: 230,
                width: 230,
                height: 32,
              }}
            >
              <div className={classes.contacts__container}>
                {contacts.map((contact) => (
                  <InputElement
                    key={contact.id}
                    value={contact.email}
                    placeholder="Заполните в заявке"
                    disabled
                    onClick={clickHandler}
                  />
                ))}
              </div>
            </ContractElement>
          )}

          <InputElement
            text="Объект рекламирования"
            value={advObject}
            setValue={setAdvObject}
            textStyle={{ minWidth: 230 }}
            textarea
            textareaStyle={{ maxHeight: '90px', height: '90px' }}
            placeholder="Бренд/акция/скидки/алкоголь/фин.услуги..."
            disabled
          />

          <InputElement
            text="Комментарий"
            value={advObjectComment}
            setValue={setAdvObjectComment}
            textStyle={{ minWidth: 230 }}
            placeholder="Комментарий к объекту рекламирования"
            onClick={clickHandler}
            disabled
          />

          <InputElement
            text="Дата отправки заявки"
            type="date"
            value={dateBid}
            setValue={setDateBid}
            textStyle={{ minWidth: 230 }}
            disabled
            date
          />

          <InputElement
            text="№ Договора"
            value={numContract}
            setValue={setNumContract}
            textStyle={{ minWidth: 230 }}
            placeholder="Введите текст"
            onClick={clickHandler}
          />

          <ContractElement
            text="Договор"
            textStyle={{
              minWidth: 230,
              width: 230,
              height: 32,
            }}
          >
            <div className={classes.date__wrapper}>
              <InputElement
                checkboxText="Отправлен"
                value={isContractSend}
                setValue={setIsContractSend}
                checkbox
                onClick={clickHandler}
                style={{ margin: 0 }}
              />

              <InputElement
                value={dateContract}
                type="date"
                setValue={setDateContract}
                date
                onClick={clickHandler}
              />
            </div>
          </ContractElement>

          <InputElement
            text="Название ветки"
            value={branchName}
            setValue={setBranchName}
            textStyle={{ minWidth: 230 }}
            placeholder="Введите текст"
            onClick={clickHandler}
          />

          <div className={classes.files__container}>
            {files.length > 0 && (
              <div className={classes.files}>
                {files.map((file) => (
                  <Files
                    key={file.id}
                    file={file}
                    deleteFileHandler={() => deleteFileHandler(file.name, file.id)}
                  />
                ))}
              </div>
            )}

            <div>
              <MyInputFile
                onChange={(event) => addFileHandler(event)}
                multiple
                accept=".ppt, .pptx, .xls, .xlsx, .doc, .docx, .jpeg, .jpg, .png, .pdf, .key, .heif"
                htmlFor={`contract_page_${project.id}`}
              />
            </div>
          </div>

          {activeBtn && (
            <div className={classes.btn__wrapper}>
              <SuccessBtn
                text="Сохранить"
                style={{
                  padding: '2px 20px',
                  fontSize: 13,
                }}
                onClick={saveCommonHandler}
              />

              <SuccessBtn
                text="Отменить"
                type="white"
                style={{
                  padding: '2px 20px',
                  fontSize: 13,
                }}
                onClick={cancelHandler}
              />
            </div>
          )}
        </div>
      </LawyerSection>
    </>
  );
}

export default CommonInfo;
