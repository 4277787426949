// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import { changePhoto, fetchOneUser } from 'API/StaffAPI';
import { check } from 'API/UserAPI';

import OneCheckboxItem from 'components/UI/CheckboxItem/OneCheckboxItem';
import PageTitle from 'components/UI/PageTitle/PageTitle';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from '../../../hooks/useLogout';

import Avatar from './components/Avatar';
import Info from './components/Info';
import Notifications from './components/Notifications';

import classes from './SettingsPage.module.css';

function SettingsPage() {
  const [logout] = useLogout();

  // // СНЕГ
  // // Состояние для хранения выбранного значения снегопада
  // const [snowfallSetting, setSnowfallSetting] = useState('В режиме ожидания');

  // // Чтение из localStorage при монтировании
  // useEffect(() => {
  //   const savedSetting = localStorage.getItem('snowfallSetting');
  //   setSnowfallSetting(savedSetting || 'В режиме ожидания');
  // }, []);

  // // Обработчик для изменения настроек снегопада
  // const handleSnowfallChange = (newSetting) => {
  //   localStorage.setItem('snowfallSetting', newSetting);
  //   setSnowfallSetting(newSetting);
  // };

  // // СНЕГ

  const [info, setInfo] = useState({});
  const [fetchUser, isUserLoading] = useFetching(async () => {
    const data = await fetchOneUser();
    setInfo(data);
  });

  const isFirstRender = useRef(true);

  useEffect(() => {
    check()
      .then(() => {
        fetchUser();
      })
      .catch((error) => logout(error));
  }, []);

  const updatePhoto = (event) => {
    check()
      .then(() => {
        const formData = new FormData();
        formData.append(event.target.files[0].name, event.target.files[0]);

        changePhoto(info.id, formData).then(() => {
          fetchUser(info.id);
        });
      })
      .catch((error) => logout(error));
  };

  // При первом рендере показываем крутилку
  if (isFirstRender.current) {
    if (isUserLoading) {
      return <Spinner animation="grow" />;
    }
    isFirstRender.current = false;
  }

  return (
    <>
      <PageTitle>Настройки</PageTitle>

      <div className={classes.profile__container}>
        <div className={classes.container_inner}>
          <Avatar updatePhoto={updatePhoto} info={info} />

          <Info info={info} />
        </div>
      </div>

      <Notifications info={info} fetchUser={fetchUser} id={info.id} />

      {/* <div className={classes.profile__container}>Снегопад
        <div className={classes.profile_snow}>
          <OneCheckboxItem style={{ width: '250px' }} text="Всегда" status={snowfallSetting === 'Всегда'} setStatus={() => handleSnowfallChange('Всегда')} />
          <OneCheckboxItem style={{ width: '250px' }} text="В режиме ожидания" status={snowfallSetting === 'В режиме ожидания'} setStatus={() => handleSnowfallChange('В режиме ожидания')} />
          <OneCheckboxItem style={{ width: '250px' }} text="Отключить" status={snowfallSetting === 'Отключить'} setStatus={() => handleSnowfallChange('Отключить')} />
        </div>
      </div> */}
    </>
  );
}

export default SettingsPage;
