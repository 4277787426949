// @ts-nocheck
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import AccordionArrow from 'components/UI/AccordionArrows/AccordionArrow/AccordionArrow';
import InputElement from 'components/UI/InputElements/InputElement';
import ContractElement from 'components/UI/Modals/ModalCreateContract/components/ContractElement';

import { CONTRACT_FIELDS } from '../Fields';

import classes from '../ProjectPage.module.scss';

function ContractItem(props) {
  const { element } = props;
  const [active, setActive] = useState(false);

  return (
    <div className={classes.contract__container}>
      <div
        className={classes.contract__header}
        onClick={() => setActive(!active)}
      >
        <span>
          {`Договор ${element?.type?.name} ${
            element?.type?.id === 2 ? element?.blogger?.name || '' : ''
          }`}
        </span>

        <AccordionArrow
          isActive={active}
          style={{ width: 16, height: 16 }}
          arrowStyle={{ top: 7 }}
        />
      </div>

      <CSSTransition
        in={active}
        timeout={600}
        classNames="application"
        appear
        unmountOnExit
      >
        <div>
          {CONTRACT_FIELDS.map((field) => (
            <div key={field.id}>
              {field.date ? (
                <ContractElement
                  text="Срок оказания услуг"
                  textStyle={{ minWidth: 220, width: 220, height: 32 }}
                >
                  <div className={classes.date__wrapper}>
                    <InputElement
                      value={element?.date_start}
                      disabled
                      date
                      style={{ margin: 0 }}
                    />

                    <InputElement value={element?.date_end} disabled date />
                  </div>
                </ContractElement>
              ) : (
                <InputElement
                  text={field.label}
                  value={
                    (field.staff && element[field.id][0]?.fullname)
                    || (field.status && element[field.id]?.name)
                    || (field.date && element[field.id])
                  }
                  textStyle={{ minWidth: 220 }}
                  style={{ marginBottom: 10 }}
                  disabled
                />
              )}
            </div>
          ))}
        </div>
      </CSSTransition>
    </div>
  );
}

export default ContractItem;
