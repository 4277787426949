// @ts-nocheck
import { makeAutoObservable } from 'mobx';

export default class FilterStore {
  constructor() {
    // Состояние фильтров для главной страницы менеджеров
    this._managersFilter = {
      id: '',
      id_status: {},
      deadline_start: '',
      deadline_end: '',
      deadline_status: {},
      status: [],
    };
    this._managersProjectFilter = [];
    this._managersClientsFilter = [];
    this._managersManagerFilter = [];
    this._managersDirectorFilter = [];
    this._managersResponsibleFilter = [];

    // Состояние фильтров для главной страницы юристов
    this._lawyersFilter = {
      status: [],
      id: '',
      bid_sent_date_start: '',
      bid_sent_date_end: '',
      bid_sent_date_status: {},
      contract_sent_date_start: '',
      contract_sent_date_end: '',
      contract_sent_date_status: {},
      contract_date: '',
      branch_name: '',
      contract_id: '',
      total_sum: '',
      payment_date_start: '',
      payment_date_end: '',
      payment_date_status: {},
      marking_data: {},
      service_start: '',
      service_end: '',
      service_end_start: '',
      service_end_end: '',
      contract_type: [],
      contract_status: [],
      closing_document_status: [],
      receipt_status: [],
      indicator: [],
    };
    this._lawyersProjectFilter = [];
    this._lawyersClientLegalEntityFilter = [];
    this._lawyersContractorLegalEntityFilter = [];
    this._lawyersManagerFilter = [];
    this._lawyersLawyerFilter = [];
    this._lawyersDocFilter = [];

    // Состояние фильтров для учета проектов
    this._projectAccountingFilter = {
      status: [],
      id: '',
      date_start_after: '',
      date_start_before: '',
      date_end_after: '',
      date_end_before: '',
      payment_date_start: '',
      payment_date_end: '',
      is_adesk: {},
      month_date_start: '',
      month_date_end: '',
      indicators: {},
    };
    this._projectAccountingProjectFilter = [];
    this._projectAccountingClientLegalEntityFilter = [];
    this._projectAccountingContractorLegalEntityFilter = [];
    this._projectAccountingDirectorFilter = [];
    this._projectAccountingManagerFilter = [];
    this._projectAccountingResponsibleFilter = [];
    this._projectAccountingContractTypesFilter = [];
    this._projectAccountingQuarterFilter = [];

    // Состояние фильтров для главной страницы финансов
    this._financeFilter = {
      id: '',
      bid_sent_date_start: '',
      bid_sent_date_end: '',
      deadline_status: {},
      is_contract_sent: {},
      contract_sent_date_start: '',
      contract_sent_date_end: '',
      contract_sent_date_deadline: {},
      branch_name: '',
      contract_number: '',
      contract_sum: '',
      ak_sum: '',
      payment_date_start: '',
      payment_date_end: '',
      service_start: '',
      service_end: '',
      service_end_start: '',
      service_end_end: '',
      is_adesk: {},
      is_case: {},
      date_take_documents_start: '',
      date_take_documents_end: '',
      quarter_start: '',
      quarter_end: '',
    };
    this._financeProjectFilter = [];
    this._financeContractFormatFilter = [];
    this._financeClientLegalEntityFilter = [];
    this._financeContractorLegalEntityFilter = [];
    this._financeManagerFilter = [];
    this._financeLawyerFilter = [];
    this._financeDocFilter = [];
    this._financeContractStatusFilter = [];
    this._financeContractClosedStatusFilter = [];
    this._financeStatusScoreFilter = [];
    this._financeProjectStatusFilter = [];
    this._financeQuarterClosingFilter = [];
    this._financeQuarterStartFilter = [];
    this._financeQuarterEndFilter = [];

    // Состояние фильтров для статуса оплат
    this._paymentStatusFilter = {
      id: '',
      contract_basis: '',
      total_sum: '',
      accounting_month_start: '',
      accounting_month_end: '',
      margin_status: {},
      documents_date_start: '',
      documents_date_end: '',
      documents_date_deadline: {},
    };

    this._paymentStatusProjectFilter = [];
    this._paymentStatusContractFormatFilter = [];
    this._paymentStatusClientLegalEntityFilter = [];
    this._paymentStatusContractorLegalEntityFilter = [];
    this._paymentStatusManagerFilter = [];
    this._paymentStatusDirectorFilter = [];

    // Состояние фильтра для страницы отчета

    this._reportFilter = {
      periodStart: '',
      periodEnd: '',
      paymentStart: '',
      paymentEnd: '',
      monthStart: '',
      monthEnd: '',
      deadline_start: '',
      deadline_end: '',
      deadline_date_status: {},
      taskStatus: {},
      news_period_start: '',
      news_period_end: '',
      news_date_status: {},
      selectedDepartment: {},
      selectedJob: {},
    };

    this._reportSelectedDepartmentList = [];
    this._reportSelectedBloggerList = [];
    this._reportSelectedDirectorList = [];
    this._reportselectedResponsibleList = [];
    this._reportClientLegalEntityList = [];
    this._reportContractorLegalEntityList = [];

    makeAutoObservable(this);
  }

  // Фильтры менеджеров
  setManagersFilter(payload) {
    this._managersFilter = {
      ...this._managersFilter,
      ...payload,
    };
  }

  setManagersProjectFilter(payload) {
    this._managersProjectFilter = payload;
  }

  setManagersClientsFilter(payload) {
    this._managersClientsFilter = payload;
  }

  setManagersManagerFilter(payload) {
    this._managersManagerFilter = payload;
  }

  setManagersDirectorFilter(payload) {
    this._managersDirectorFilter = payload;
  }

  setManagersResponsibleFilter(payload) {
    this._managersResponsibleFilter = payload;
  }

  get managersFilter() {
    return this._managersFilter;
  }

  get managersProjectFilter() {
    return this._managersProjectFilter;
  }

  get managersClientsFilter() {
    return this._managersClientsFilter;
  }

  get managersManagerFilter() {
    return this._managersManagerFilter;
  }

  get managersDirectorFilter() {
    return this._managersDirectorFilter;
  }

  get managersResponsibleFilter() {
    return this._managersResponsibleFilter;
  }

  // Фильтры Юристов
  setLawyersFilter(payload) {
    this._lawyersFilter = {
      ...this._lawyersFilter,
      ...payload,
    };
  }

  setLawyersProjectFilter(payload) {
    this._lawyersProjectFilter = payload;
  }

  setLawyersClientLegalEntityFilter(payload) {
    this._lawyersClientLegalEntityFilter = payload;
  }

  setLawyersContractorLegalEntityFilter(payload) {
    this._lawyersContractorLegalEntityFilter = payload;
  }

  setLawyersManagerFilter(payload) {
    this._lawyersManagerFilter = payload;
  }

  setLawyersLawyerFilter(payload) {
    this._lawyersLawyerFilter = payload;
  }

  setLawyersDocFilter(payload) {
    this._lawyersDocFilter = payload;
  }

  get lawyersFilter() {
    return this._lawyersFilter;
  }

  get lawyersProjectFilter() {
    return this._lawyersProjectFilter;
  }

  get lawyersClientLegalEntityFilter() {
    return this._lawyersClientLegalEntityFilter;
  }

  get lawyersContractorLegalEntityFilter() {
    return this._lawyersContractorLegalEntityFilter;
  }

  get lawyersManagerFilter() {
    return this._lawyersManagerFilter;
  }

  get lawyersLawyerFilter() {
    return this._lawyersLawyerFilter;
  }

  get lawyersDocFilter() {
    return this._lawyersDocFilter;
  }

  // Фильтры для учета проектов
  setProjectAccountingFilter(payload) {
    this._projectAccountingFilter = {
      ...this._projectAccountingFilter,
      ...payload,
    };
  }

  setProjectAccountingProjectFilter(payload) {
    this._projectAccountingProjectFilter = payload;
  }

  setProjectAccountingClientLegalEntityFilter(payload) {
    this._projectAccountingClientLegalEntityFilter = payload;
  }

  setProjectAccountingContractorLegalEntityFilter(payload) {
    this._projectAccountingContractorLegalEntityFilter = payload;
  }

  setProjectAccountingDirectorFilter(payload) {
    this._projectAccountingDirectorFilter = payload;
  }

  setProjectAccountingManagerFilter(payload) {
    this._projectAccountingManagerFilter = payload;
  }

  setProjectAccountingResponsibleFilter(payload) {
    this._projectAccountingResponsibleFilter = payload;
  }

  setProjectAccountingContractTypesFilter(payload) {
    this._projectAccountingContractTypesFilter = payload;
  }

  setProjectAccountingQuarterFilter(payload) {
    this._projectAccountingQuarterFilter = payload;
  }

  get projectAccountingFilter() {
    return this._projectAccountingFilter;
  }

  get projectAccountingProjectFilter() {
    return this._projectAccountingProjectFilter;
  }

  get projectAccountingClientLegalEntityFilter() {
    return this._projectAccountingClientLegalEntityFilter;
  }

  get projectAccountingContractorLegalEntityFilter() {
    return this._projectAccountingContractorLegalEntityFilter;
  }

  get projectAccountingDirectorFilter() {
    return this._projectAccountingDirectorFilter;
  }

  get projectAccountingManagerFilter() {
    return this._projectAccountingManagerFilter;
  }

  get projectAccountingResponsibleFilter() {
    return this._projectAccountingResponsibleFilter;
  }

  get projectAccountingContractTypesFilter() {
    return this._projectAccountingContractTypesFilter;
  }

  get projectAccountingQuarterFilter() {
    return this._projectAccountingQuarterFilter;
  }

  // Фильтр статуса оплат
  setPaymentStatusFilter(payload) {
    this._paymentStatusFilter = {
      ...this._paymentStatusFilter,
      ...payload,
    };
  }

  setPaymentStatusProjectFilter(payload) {
    this._paymentStatusProjectFilter = payload;
  }

  setPaymentStatusContractFormatFilter(payload) {
    this._paymentStatusContractFormatFilter = payload;
  }

  setPaymentStatusClientLegalEntityFilter(payload) {
    this._paymentStatusClientLegalEntityFilter = payload;
  }

  setPaymentStatusContractorLegalEntityFilter(payload) {
    this._paymentStatusContractorLegalEntityFilter = payload;
  }

  setPaymentStatusManagerFilter(payload) {
    this._paymentStatusManagerFilter = payload;
  }

  setPaymentStatusDirectorFilter(payload) {
    this._paymentStatusDirectorFilter = payload;
  }

  get paymentStatusFilter() {
    return this._paymentStatusFilter;
  }

  get paymentStatusProjectFilter() {
    return this._paymentStatusProjectFilter;
  }

  get paymentStatusContractFormatFilter() {
    return this._paymentStatusContractFormatFilter;
  }

  get paymentStatusClientLegalEntityFilter() {
    return this._paymentStatusClientLegalEntityFilter;
  }

  get paymentStatusContractorLegalEntityFilter() {
    return this._paymentStatusContractorLegalEntityFilter;
  }

  get paymentStatusManagerFilter() {
    return this._paymentStatusManagerFilter;
  }

  get paymentStatusDirectorFilter() {
    return this._paymentStatusDirectorFilter;
  }

  // Фильтры финансов
  setFinanceFilter(payload) {
    this._financeFilter = {
      ...this._financeFilter,
      ...payload,
    };
  }

  setFinanceProjectFilter(payload) {
    this._financeProjectFilter = payload;
  }

  setFinanceContractFormatFilter(payload) {
    this._financeContractFormatFilter = payload;
  }

  setFinanceClientLegalEntityFilter(payload) {
    this._financeClientLegalEntityFilter = payload;
  }

  setFinanceContractorLegalEntityFilter(payload) {
    this._financeContractorLegalEntityFilter = payload;
  }

  setFinanceManagerFilter(payload) {
    this._financeManagerFilter = payload;
  }

  setFinanceLawyerFilter(payload) {
    this._financeLawyerFilter = payload;
  }

  setFinanceDocFilter(payload) {
    this._financeDocFilter = payload;
  }

  setFinanceContractStatusFilter(payload) {
    this._financeContractStatusFilter = payload;
  }

  setFinanceContractClosedStatusFilter(payload) {
    this._financeContractClosedStatusFilter = payload;
  }

  setFinanceStatusScoreFilter(payload) {
    this._financeStatusScoreFilter = payload;
  }

  setFinanceProjectStatusFilter(payload) {
    this._financeProjectStatusFilter = payload;
  }

  setFinanceQuarterClosingFilter(payload) {
    this._financeQuarterClosingFilter = payload;
  }

  setFinanceQuarterStartFilter(payload) {
    this._financeQuarterStartFilter = payload;
  }

  setFinanceQuarterEndFilter(payload) {
    this._financeQuarterEndFilter = payload;
  }

  get financeFilter() {
    return this._financeFilter;
  }

  get financeProjectFilter() {
    return this._financeProjectFilter;
  }

  get financeContractFormatFilter() {
    return this._financeContractFormatFilter;
  }

  get financeClientLegalEntityFilter() {
    return this._financeClientLegalEntityFilter;
  }

  get financeManagerFilter() {
    return this._financeManagerFilter;
  }

  get financeLawyerFilter() {
    return this._financeLawyerFilter;
  }

  get financeDocFilter() {
    return this._financeDocFilter;
  }

  get financeContractStatusFilter() {
    return this._financeContractStatusFilter;
  }

  get financeContractClosedStatusFilter() {
    return this._financeContractClosedStatusFilter;
  }

  get financeStatusScoreFilter() {
    return this._financeStatusScoreFilter;
  }

  get financeProjectStatusFilter() {
    return this._financeProjectStatusFilter;
  }

  get financeQuarterClosingFilter() {
    return this._financeQuarterClosingFilter;
  }

  get financeQuarterStartFilter() {
    return this._financeQuarterStartFilter;
  }

  get financeQuarterEndFilter() {
    return this._financeQuarterEndFilter;
  }

  // Фильтр для страницы отчета

  setReportFilter(payload) {
    this._reportFilter = {
      ...this._reportFilter,
      ...payload,
    };
  }

  setReportSelectedDepartmentList(payload) {
    this._reportSelectedDepartmentList = payload;
  }

  setReportSelectedBloggerList(payload) {
    this._reportSelectedBloggerList = payload;
  }

  setReportSelectedDirectorList(payload) {
    this._reportSelectedDirectorList = payload;
  }

  setReportSelectedResponsibleList(payload) {
    this._reportselectedResponsibleList = payload;
  }

  setReportClientLegalEntityList(payload) {
    this._reportClientLegalEntityList = payload;
  }

  setReportContractorLegalEntityList(payload) {
    this._reportContractorLegalEntityList = payload;
  }

  get reportFilter() {
    return this._reportFilter;
  }

  get reportSelectedDepartmentList() {
    return this._reportSelectedDepartmentList;
  }

  get reportSelectedBloggerList() {
    return this._reportSelectedBloggerList;
  }

  get reportSelectedDirectorList() {
    return this._reportSelectedDirectorList;
  }

  get reportselectedResponsibleList() {
    return this._reportselectedResponsibleList;
  }

  get reportClientLegalEntityList() {
    return this._reportClientLegalEntityList;
  }

  get reportContractorLegalEntityList() {
    return this._reportContractorLegalEntityList;
  }
}
