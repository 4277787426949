import qs from 'qs';

import { $authHost } from 'API';

import { getDate } from '../../utils/getDate';

export const fetchManagerProjects = async (page, limit, controller, filter) => {
  const params = { page, limit };

  if (filter) {
    if (filter.id) {
      params.project_id = Number(filter.id);
    }

    if (filter.projectName) {
      params.project_name = filter.projectName;
    }

    let tempArray = [];
    filter?.project?.forEach((project) => tempArray.push(project.id));
    if (tempArray.length > 0) {
      params.id = tempArray;
    }

    tempArray = [];
    filter?.client?.forEach((client) => {
      tempArray.push(client.id);
    });
    if (tempArray.length > 0) {
      params.client = tempArray;
    }

    if (filter.deadline_start) {
      params.created_after = getDate(filter.deadline_start);
    }

    if (filter.deadline_end) {
      params.created_before = getDate(filter.deadline_end);
    }

    if (filter.deadline_status?.id === 0) {
      params.o = 'created';
    }
    if (filter.deadline_status?.id === 1) {
      params.o = '-created';
    }

    if (filter.doc_created?.id === 0) {
      params.doc_created = false;
    }
    if (filter.doc_created?.id === 1) {
      params.doc_created = true;
    }

    if (filter.id_status?.id === 0) {
      params.o = 'project_id';
    }
    if (filter.id_status?.id === 1) {
      params.o = '-project_id';
    }

    tempArray = [];
    filter?.manager?.forEach((responsible) => tempArray.push(responsible.id));
    if (tempArray.length > 0) {
      params.manager = tempArray;
    }

    tempArray = [];
    filter?.director?.forEach((director) => tempArray.push(director.id));
    if (tempArray.length > 0) {
      params.director = tempArray;
    }

    tempArray = [];
    filter?.responsible?.forEach((responsible) => tempArray.push(responsible.id));
    if (tempArray.length > 0) {
      params.responsible = tempArray;
    }

    if (filter.status.length > 0) {
      params.status = filter.status;
    }
  }

  const { data } = await $authHost.get('/project_main/project/', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};

export const fetchOneProject = async (id) => {
  const { data } = await $authHost.get(`/project_card/project/${id}/`);
  return data;
};

export const fetchOneProjectTasks = async (id, filter) => {
  const params = {};

  if (filter) {
    if (filter.status >= 0) {
      params.status = filter.status;
    }

    if (filter.deadline === 0) {
      params.o = 'deadline';
    }
    if (filter.deadline === 1) {
      params.o = '-deadline';
    }
    if (filter.responsible_name) {
      params.responsible_name = filter.responsible_name;
    }
  }

  const { data } = await $authHost.get(
    `/project_card/project/${id}/tasks/`,
    {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  );
  return data;
};

export const fetchOneProjectPublications = async (id, filter) => {
  const params = {};

  if (filter) {
    if (filter.status >= 0) {
      params.status = filter.status;
    }

    if (filter.deadline === 0) {
      params.o = 'deadline';
    }
    if (filter.deadline === 1) {
      params.o = '-deadline';
    }
    if (filter.blogger) {
      params.blogger = filter.blogger;
    }
    if (filter.type) {
      params.type = filter.type;
    }
  }

  const { data } = await $authHost.get(
    `/project_card/project/${id}/publications/`,
    {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  );
  return data;
};

export const fetchOneProjectMargin = async (id) => {
  const { data } = await $authHost.get(
    `/project_card/project/${id}/margin_distribution/`,
  );
  return data;
};

export const updateOneProjectMargin = async (id, sendData) => {
  const { data } = await $authHost.post(
    `/project_card/project/${id}/margin_distribution/`,
    sendData,
  );
  return data;
};

export const fetchOneProjectEstimate = async (id) => {
  const { data } = await $authHost.get(
    `/project_card/project/${id}/estimate/`,
  );
  return data;
};

export const updateOneProjectEstimate = async (id, sendData) => {
  const { data } = await $authHost.patch(
    `/project_card/estimate/${id}/`,
    sendData,
  );
  return data;
};

export const postOneProjectPublication = async (data) => {
  await $authHost.post('/manager/project_card/publication/', data);
};

export const patchOneProjectPublication = async (id, data) => {
  await $authHost.patch(`/project_card/publication/${id}/`, data);
};

export const deleteOneProjectPublication = async (id) => {
  await $authHost.delete(`/project_card/publication/${id}/`);
};

export const postOneExpense = async (data) => {
  await $authHost.post('/project_card/expense/', data);
};

export const patchOneExpense = async (id, data) => {
  await $authHost.patch(`/project_card/expense/${id}/`, data);
};

export const deleteOneExpense = async (id) => {
  await $authHost.delete(`/project_card/expense/${id}/`);
};

export const createProject = async (sentData) => {
  const { data } = await $authHost.post(
    '/project_main/project/',
    sentData,
  );
  return data;
};

export const patchOneProject = async (id, data) => {
  await $authHost.patch(`/project_card/project/${id}/`, data);
};

export const uploadProjectFile = async (id, files, options) => {
  const response = await $authHost.post(
    `/project_card/project/${id}/file/`,
    files,
    options,
  );
  return response;
};

export const deleteProjectFile = async (file_id) => {
  await $authHost.delete(`/manager/project_card/project_file/${file_id}/`);
};

export const getHistory = async (id) => {
  const { data } = await $authHost.get(`/project_card/project/${id}/history/`);

  return data;
};

export const deleteProject = async (id) => {
  await $authHost.delete(`/project_main/project/${id}/`);
};

export const getComments = async (page, limit, controller, filter, id) => {
  const params = { page, limit };

  if (filter) {
    if (filter.periodStart) {
      params.date_after = getDate(filter.periodStart);
    }

    if (filter.periodEnd) {
      params.date_before = getDate(filter.periodEnd);
    }

    if (filter.paymentStart) {
      params.payment_dates_after = getDate(filter.paymentStart);
    }

    if (filter.paymentEnd) {
      params.payment_dates_before = getDate(filter.paymentEnd);
    }

    if (filter.monthStart) {
      params.accounting_month_after = getDate(filter.monthStart);
    }

    if (filter.monthEnd) {
      params.accounting_month_before = getDate(filter.monthEnd);
    }

    if (Number.isInteger(filter.selectedDepartment.id)) {
      params.department = filter.selectedDepartment.id;
    }

    let tempArray = [];
    filter?.selectedDirectorList?.forEach((grouphead) => tempArray.push(grouphead.id));
    if (tempArray.length > 0) {
      params.grouphead = tempArray;
    }

    tempArray = [];
    filter?.selectedResponsibleList?.forEach((person) => tempArray.push(person.id));
    if (tempArray.length > 0) {
      params.staff = tempArray;
    }
  }

  const { data } = await $authHost.get(`/project_card/project/${id}/comments/`, {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};

export const postComment = async (id, sentData) => {
  const { data } = await $authHost.post(
    `/project_card/project/${id}/comments/`,
    sentData,
  );
  return data;
};

export const getCommentsCost = async (page, limit, controller, filter, id) => {
  const params = { page, limit };

  if (filter) {
    if (filter.periodStart) {
      params.date_after = getDate(filter.periodStart);
    }

    if (filter.periodEnd) {
      params.date_before = getDate(filter.periodEnd);
    }

    if (filter.paymentStart) {
      params.payment_dates_after = getDate(filter.paymentStart);
    }

    if (filter.paymentEnd) {
      params.payment_dates_before = getDate(filter.paymentEnd);
    }

    if (filter.monthStart) {
      params.accounting_month_after = getDate(filter.monthStart);
    }

    if (filter.monthEnd) {
      params.accounting_month_before = getDate(filter.monthEnd);
    }

    if (Number.isInteger(filter.selectedDepartment.id)) {
      params.department = filter.selectedDepartment.id;
    }

    let tempArray = [];
    filter?.selectedDirectorList?.forEach((grouphead) => tempArray.push(grouphead.id));
    if (tempArray.length > 0) {
      params.grouphead = tempArray;
    }

    tempArray = [];
    filter?.selectedResponsibleList?.forEach((person) => tempArray.push(person.id));
    if (tempArray.length > 0) {
      params.staff = tempArray;
    }
  }

  const { data } = await $authHost.get(`/payment_card/payment/${id}/comments/`, {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};

export const postCommentCost = async (id, sentData) => {
  const { data } = await $authHost.post(
    `/payment_card/payment/${id}/comments/`,
    sentData,
  );
  return data;
};

export const fetchReportProjects = async (page, limit, controller, filter) => {
  const params = { page, limit };

  if (filter) {
    if (filter.periodStart) {
      params.date_after = getDate(filter.periodStart);
    }

    if (filter.periodEnd) {
      params.date_before = getDate(filter.periodEnd);
    }

    if (filter.paymentStart) {
      params.payment_dates_after = getDate(filter.paymentStart);
    }

    if (filter.paymentEnd) {
      params.payment_dates_before = getDate(filter.paymentEnd);
    }

    if (filter.monthStart) {
      params.accounting_month_after = getDate(filter.monthStart);
    }

    if (filter.monthEnd) {
      params.accounting_month_before = getDate(filter.monthEnd);
    }

    if (Number.isInteger(filter.selectedDepartment.id)) {
      params.department = filter.selectedDepartment.id;
    }

    let tempArray = [];
    filter?.selectedDepartmentList?.forEach((department) => tempArray.push(department.id));
    if (tempArray.length > 0) {
      params.department = tempArray;
    }

    tempArray = [];
    filter?.selectedDirectorList?.forEach((grouphead) => tempArray.push(grouphead.id));
    if (tempArray.length > 0) {
      params.grouphead = tempArray;
    }

    tempArray = [];
    filter?.selectedResponsibleList?.forEach((person) => tempArray.push(person.id));
    if (tempArray.length > 0) {
      params.staff = tempArray;
    }

    tempArray = [];
    filter?.selectedProjectStatusList?.forEach((element) => tempArray.push(element.id));
    if (tempArray.length > 0) {
      params.status = tempArray;
    }
  }

  const { data } = await $authHost.get('/report_page/projects/', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};

export const exportGoogleReportProjects = async (filter) => {
  const params = { };

  if (filter) {
    if (filter.periodStart) {
      params.date_after = getDate(filter.periodStart);
    }

    if (filter.periodEnd) {
      params.date_before = getDate(filter.periodEnd);
    }

    if (filter.paymentStart) {
      params.payment_dates_after = getDate(filter.paymentStart);
    }

    if (filter.paymentEnd) {
      params.payment_dates_before = getDate(filter.paymentEnd);
    }

    if (filter.monthStart) {
      params.accounting_month_after = getDate(filter.monthStart);
    }

    if (filter.monthEnd) {
      params.accounting_month_before = getDate(filter.monthEnd);
    }

    if (Number.isInteger(filter.selectedDepartment.id)) {
      params.department = filter.selectedDepartment.id;
    }

    let tempArray = [];

    filter?.selectedDepartmentList?.forEach((department) => tempArray.push(department.id));
    if (tempArray.length > 0) {
      params.department = tempArray;
    }

    tempArray = [];
    filter?.selectedDirectorList?.forEach((grouphead) => tempArray.push(grouphead.id));
    if (tempArray.length > 0) {
      params.grouphead = tempArray;
    }

    tempArray = [];
    filter?.selectedResponsibleList?.forEach((person) => tempArray.push(person.id));
    if (tempArray.length > 0) {
      params.staff = tempArray;
    }

    tempArray = [];
    filter?.selectedProjectStatusList?.forEach((element) => tempArray.push(element.id));
    if (tempArray.length > 0) {
      params.status = tempArray;
    }
  }

  const { data } = await $authHost.get('/report_page/export/', {
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};
