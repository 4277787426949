import React from 'react';

import photo from '../../../../assets/icons/photo.svg';

import addBtnClass from '../../../UI/Btns/Btns.module.scss';
import classes from '../SettingsPage.module.css';

function Avatar({ info, updatePhoto }) {
  return (
    <div className={classes.profile__avatar__container}>
      <img
        className={classes.profile__avatar}
        src={info?.photo?.file ? info?.photo?.file : photo}
        alt=""
      />

      <label
        htmlFor="change"
        className={[
          addBtnClass.btn__success,
          addBtnClass.btn__success__white,
        ].join(' ')}
        style={{
          fontSize: 13,
          padding: '0px 6px',
        }}
      >
        <input
          className={classes.input__file}
          onChange={(event) => updatePhoto(event)}
          type="file"
          accept=".jpg, .jpeg"
          id="change"
        />
        <span>Изменить</span>
      </label>
    </div>
  );
}

export default Avatar;
