import { useState } from 'react';

export const useStateNew = (initualValue) => {
  const [state, setState] = useState(initualValue || []);

  const change = (value, fieldId, id, suggestion) => {
    if (initualValue[0].contacts) {
      setState(
        state.map((element) => (element.fieldId === fieldId
          ? {
            ...element,
            name: value,
            contacts: suggestion ? suggestion.contacts : [],
            id: (id >= 0 || id === 'customId') ? id : null,
          }
          : element)),
      );
    } else if (initualValue[0].id === null) {
      setState(
        state.map((element) => (element.fieldId === fieldId
          ? {
            ...element,
            name: value,
            id: (id >= 0 || id === 'customId') ? id : null,
          }
          : element)),
      );
    }
  };

  const add = () => {
    setState([...state, { fieldId: Date.now(), id: null, name: '' }]);
  };

  const deleteFunc = (id) => {
    if (state.length > 1) {
      setState(state.filter((element) => element.fieldId !== id));
    }
  };

  const check = (id) => {
    let isDuplicate = false;
    state.forEach((element) => {
      if (element.id === id) {
        isDuplicate = true;
      }
    });

    return isDuplicate;
  };

  const deleteFuncNoCheck = (id) => {
    setState(state.filter((element) => element.fieldId !== id));
  };

  return [state, setState, change, add, deleteFunc, check, deleteFuncNoCheck];
};
