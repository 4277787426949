import React from 'react';

import classes from '../Navbar.module.css';

function ExitItem(props) {
  const {
    onClick, icon, title,
  } = props;

  const className = 'new__navbar__item ';
  return (
    <div className={className} onClick={onClick}>
      <img className={classes.new__navbar__image} src={icon} alt="" />
      <span>{title}</span>
    </div>
  );
}

export default ExitItem;
