import React from 'react';

import classes from './NewInput.module.scss';

function MyInputFile(props) {
  const {
    style, onChange, multiple, accept, containerStyle, htmlFor, disabled, text,
  } = props;

  return (
    <div className={classes.addFile__container} style={containerStyle}>
      <label
        htmlFor={htmlFor || 'addFile'}
        className={classes.addFile__custom}
      >
        <input
          className={classes.addFile}
          onChange={onChange}
          type="file"
          style={style}
          multiple={multiple}
          accept={accept}
          id={htmlFor || 'addFile'}
          disabled={disabled}
        />
      </label>
      <button
        type="button"
        id={htmlFor || 'addFile'}
        className={classes.addFile__btn}
      >
        <label
          htmlFor={htmlFor || 'addFile'}
          style={{ cursor: 'pointer' }}
        >
          {text || 'Добавить файл'}
        </label>
      </button>
    </div>
  );
}

export default MyInputFile;
