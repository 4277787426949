import React from 'react';
import { Link } from 'react-router-dom';

import classes from '../styles/TeamsPage.module.css';

function TeamElementItem(props) {
  const {
    element, image, to,
  } = props;

  return (
    <Link className={classes.team__list__element} to={`${to}/${element.id}`}>
      <span className={classes.team__list__element__text}>
        {element.fullname || element.name || element.legal_name}
      </span>

      <img src={image} alt="" />
    </Link>
  );
}

export default TeamElementItem;
