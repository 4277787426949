// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { addSKBIDFile, deleteBIDFile, getSKBIDFile } from 'API/ApplicationAPI';
import { check } from 'API/UserAPI';

import Align from 'components/UI/Align/Align';
import NewAddBtn from 'components/UI/Btns/NewAddBtn';
import Files from 'components/UI/Files/Files';
import InputElement from 'components/UI/InputElements/InputElement';
import ContractElement from 'components/UI/Modals/ModalCreateContract/components/ContractElement';
import PaymentDateNoTime from 'components/UI/Modals/ModalCreateContract/components/PaymentDateNoTime';
import MyInputFile from 'components/UI/MyInput/MyInputFile';
import SearchBar from 'components/UI/SearchBar/SearchBar';

import { useLogout } from 'hooks/useLogout';
import { useStateNew } from 'hooks/useStateNew';
import { useStateNew2 } from 'hooks/useStateNew2';
import { useDate } from '../../../../../../hooks/UseDate';

import { getSum, getSumRuble, numberToSum } from 'utils/getSums';

import AccordionArrow from '../../../../AccordionArrows/AccordionArrow/AccordionArrow';
import SuccessBtn from '../../../../Btns/SuccessBtn';
import CheckboxElement from '../../../../CheckboxItem/CheckboxElement';
import CheckboxItem from '../../../../CheckboxItem/CheckboxItem';

import classes from './Step2.module.scss';

function Step2(props) {
  const {
    ourLegalEntity,
    client_contacts,
    show,
    step2Value,
    setStep2Value,
    element,
    index,
    lawyerList,
    managerList,
    documentList,
    allLegalEntity,
    dataProps,
    onHide,
    deletePayment,
    contractorList,
    client,
  } = props;

  const [logout] = useLogout();
  const [isActive, setIsActive] = useState(false);

  const updateStep2Value = (fieldId, fieldName, value) => {
    setStep2Value(
      step2Value.map((element) => (element.id === fieldId
        ? {
          ...element,
          [fieldName]: value,
        }
        : element)),
    );
  };

  const [priceDate, setPriceDate, changeDate, addDate, deleteDate] = useDate([{ reactId: Date.now(), date: null, sum: '' }]);

  const [clientContact, setClientContact, , , deleteClientContact] = useStateNew2([{ id: Date.now(), mail: '' }]);

  const [responsibleLawyer, setResponsibleLawyer, changeResponsibleLawyer] = useStateNew([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    responsibleDocument,
    setResponsibleDocument,
    changeResponsibleDocument,
  ] = useStateNew([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    responsible,
    setResponsible,
    changeResponsible,
    addResponsible,,
    checkResponsible,
    deleteResponsible,
  ] = useStateNew([{ fieldId: Date.now(), id: null, name: '' }]);

  const [bloggerLegalEntity, setBloggerLegalEntity, changeBloggerLegalEntity] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
    },
  ]);

  const [files, setFiles] = useState([]);

  useEffect(() => {
    const payment = { ...element.payment, payment_dates: priceDate };
    updateStep2Value(element.id, 'payment', payment);
  }, [priceDate]);

  useEffect(() => {
    updateStep2Value(element.id, 'lawyer', responsibleLawyer);
  }, [responsibleLawyer]);

  useEffect(() => {
    updateStep2Value(element.id, 'doc_manager', responsibleDocument);
  }, [responsibleDocument]);

  useEffect(() => {
    updateStep2Value(element.id, 'staff_contacts', responsible);
  }, [responsible]);

  useEffect(() => {
    updateStep2Value(element.id, 'client_contacts', clientContact);
  }, [clientContact]);

  useEffect(() => {
    if (element.sk_bid_type === 7) {
      updateStep2Value(element.id, 'client_legal_entity', bloggerLegalEntity[0]);
    } else {
      updateStep2Value(element.id, 'contractor_legal_entity', bloggerLegalEntity[0]);
    }
  }, bloggerLegalEntity);

  useEffect(() => {
    if (show) {
      if (element.payment.payment_dates.length > 0) {
        const dates = [];
        element.payment.payment_dates
          .forEach((date) => dates.push({ ...date, reactId: date.id, sum: numberToSum(date.sum) }));

        setPriceDate(dates);
      }

      if (element.lawyer.length > 0) {
        element.lawyer.forEach((element) => {
          setResponsibleLawyer([
            { fieldId: Date.now(), id: element.id, name: element.fullname },
          ]);
        });
      }

      if (element.doc_manager.length > 0) {
        element.doc_manager.forEach((element) => {
          setResponsibleDocument([
            { fieldId: Date.now(), id: element.id, name: element.fullname },
          ]);
        });
      }

      setResponsible(element.staff_contacts);

      if (element.client_contacts.length > 0) {
        setClientContact(element.client_contacts);
      } else if (client_contacts.length > 0) {
        setClientContact(client_contacts);
      }

      if (element.sk_bid_type === 7) {
        setBloggerLegalEntity([{
          ...element.client_legal_entity,
          name: element?.client_legal_entity?.legal_name,
          fieldId: Date.now(),
        }]);
      } else {
        setBloggerLegalEntity([{
          ...element.contractor_legal_entity,
          name: element?.contractor_legal_entity?.legal_name,
          fieldId: Date.now(),
        }]);
      }

      setFiles(element.files);
    }
  }, [show, dataProps]);

  // Расчеты
  const [ndsSum, setNdsSum] = useState('');
  const [priceWithoutNds, setPriceWithoutNds] = useState('');

  const isProcentAK = useRef(false);
  const isSumAKWithTax = useRef(false);

  const updatePayment = (fieldName, value) => {
    const payment = { ...element.payment, [fieldName]: value };
    updateStep2Value(element.id, 'payment', payment);
  };

  useEffect(() => {
    if (element.payment.is_client_nds) {
      updatePayment('client_sum_without_tax', numberToSum(getSum(element.payment.client_sum) * 0.83333333333333));
    } else {
      updatePayment('client_sum_without_tax', element.payment.client_sum);
    }
  }, [element.payment.is_client_nds, element.payment.client_sum]);

  useEffect(() => {
    element.payment.ak_percent && !isSumAKWithTax.current
      ? (isProcentAK.current = true)
      : (isProcentAK.current = false);

    if (element.payment.ak_percent && isProcentAK.current) {
      updatePayment(
        'total_sum',
        numberToSum(
          getSum(element.payment.client_sum_without_tax)
            * (getSum(element.payment.ak_percent) / 100),
        ),
      );
    } else if (!element.payment.ak_percent) {
      updatePayment('total_sum', '');
    }
  }, [element.payment.ak_percent, element.payment.client_sum_without_tax]);

  useEffect(() => {
    element.payment.total_sum && !isProcentAK.current
      ? (isSumAKWithTax.current = true)
      : (isSumAKWithTax.current = false);

    if (element.payment.total_sum && isSumAKWithTax.current) {
      updatePayment(
        'ak_percent',
        numberToSum(
          (getSum(element.payment.total_sum) * 100)
            / getSum(element.payment.client_sum_without_tax),
        ),
      );
    } else if (!element.payment.total_sum) {
      updatePayment('ak_percent', '');
    }
  }, [element.payment.client_sum_without_tax, element.payment.total_sum]);

  // useEffect(() => {
  //   isSumAKWithTax.current = false;
  //   isProcentAK.current = false;

  //   const payment = {
  //     ...element.payment,
  //     ak_percent: '',
  //     total_sum: '',
  //     is_nds: false,
  //   };
  //   updateStep2Value(element.id, 'payment', payment);
  // }, [element.client_legal_entity, element.contractor_legal_entity]);

  useEffect(() => {
    if (element.payment.is_nds) {
      setPriceWithoutNds(getSum(element.payment.total_sum) * 0.8333333333);

      setNdsSum(
        getSum(element.payment.total_sum)
          - getSum(element.payment.total_sum) * 0.8333333333,
      );
    }
  }, [element.payment.total_sum, element.payment.is_nds]);

  const addFileHandler = (event) => {
    const formData = new FormData();
    Array.from(event.target.files).forEach((file) => {
      formData.append(file.name, file);
    });

    check().then(() => {
      addSKBIDFile(element.id, formData).then(() => {
        getSKBIDFile(element.id).then((data) => {
          setFiles(data);
        });
      });
    });
  };

  const deleteFileHandler = (id) => {
    check()
      .then(() => {
        deleteBIDFile(id).then(() => {
          getSKBIDFile(element.id).then((data) => {
            setFiles(data);
          });
        });
      })
      .catch(() => {
        logout();
        onHide();
      });
  };

  useEffect(() => {
    let contractor = {
      id: null,
      name: '',
      legal_entity: [],
    };

    if (element.sk_bid_type === 4) {
      contractor = {
        id: client?.id,
        name: client?.name,
        legal_entity: client?.legal_entity,
      };
    }

    setStep2Value(
      step2Value.map((el) => (el.id === element.id
        ? {
          ...el,
          blogger: contractor,
          client_legal_entity: {},
          contractor_legal_entity: {},
          payment: {
            ...element.payment,
            payment_method: '',
          },
        }
        : el)),
    );
  }, [element.sk_bid_type]);

  useEffect(() => {
    if (element.sk_bid_type === 7) {
      if (element.is_contract) {
        updatePayment(
          'payment_method',
          '',
        );
      } else {
        updateStep2Value(
          element.id,
          'client_legal_entity',
          {},
        );
      }
    } else if (element.sk_bid_type === 4) {
      if (element.is_contract) {
        updatePayment(
          'payment_method',
          '',
        );
      } else {
        updateStep2Value(
          element.id,
          'contractor_legal_entity',
          {},
        );
      }
    }
  }, [element.is_contract]);

  return (
    <div className={classes.paymentElement__container}>
      <div
        className={classes.payment__header}
        onClick={() => setIsActive(!isActive)}
      >
        <span>{`Оплата ${index + 1}`}</span>

        <div className={classes.payment__header__item}>
          <SuccessBtn
            text={`Удалить оплату ${index + 1}`}
            type="white"
            style={{
              width: 170,
              padding: '2px 10px',
              fontSize: '12px',
            }}
            onClick={(event) => deletePayment(element.id, event)}
          />

          <AccordionArrow isActive={isActive} />
        </div>
      </div>

      <CSSTransition
        in={isActive}
        timeout={600}
        classNames="application"
        appear
        unmountOnExit
      >
        <Align direction="column" gap="10px">
          <ContractElement
            text="Вид"
            style={{ margin: 0, alignItems: 'start' }}
            textStyle={{ height: 32 }}
          >
            <CheckboxElement style={{ margin: 0 }}>
              <CheckboxItem
                text="Доход"
                status={element.sk_bid_type === 7}
                setStatus={() => updateStep2Value(element.id, 'sk_bid_type', 7)}
              />

              <CheckboxItem
                text="Расход"
                status={element.sk_bid_type === 4}
                setStatus={() => updateStep2Value(element.id, 'sk_bid_type', 4)}
              />
            </CheckboxElement>
          </ContractElement>

          <ContractElement
            text="Договор"
            style={{ margin: 0, alignItems: 'start' }}
            textStyle={{ height: 32 }}
          >
            <CheckboxElement style={{ margin: 0 }}>
              <CheckboxItem
                text="Да"
                status={element.is_contract}
                setStatus={() => updateStep2Value(element.id, 'is_contract', true)}
              />

              <CheckboxItem
                text="Нет"
                status={!element.is_contract}
                setStatus={() => updateStep2Value(element.id, 'is_contract', false)}
              />
            </CheckboxElement>
          </ContractElement>

          {element.sk_bid_type === 7 ? (
            <>
              <InputElement
                text="Заказчик"
                value={element.blogger}
                dropdown={contractorList}
                setValue={(blogger) => updateStep2Value(
                  element.id,
                  'blogger',
                  blogger,
                )}
                style={{ margin: 0 }}
              />

              {/* <ContractElement
                text="ЮЛ заказчика"
                style={{ alignItems: 'center' }}
              >
                <SearchBar
                  text={bloggerLegalEntity[0]}
                  setText={changeBloggerLegalEntity}
                  list={allLegalEntity}
                  type="legal_details"
                />
              </ContractElement> */}

              {element.is_contract
                ? (
                  <ContractElement
                    text="ЮЛ заказчика"
                    style={{ alignItems: 'center' }}
                  >
                    <SearchBar
                      text={bloggerLegalEntity[0]}
                      setText={changeBloggerLegalEntity}
                      list={allLegalEntity}
                      type="legal_details"
                      containerClassName={classes.seacrh}
                    />
                  </ContractElement>
                ) : (
                  <InputElement
                    text="Способ оплаты"
                    value={element.payment.payment_method}
                    onChange={(event) => updatePayment('payment_method', (event.target.value))}
                    style={{ margin: 0 }}
                  />
                )}

              <InputElement
                text="ЮЛ исполнителя"
                value={element.contractor_legal_entity}
                setValue={(legal_entity) => updateStep2Value(
                  element.id,
                  'contractor_legal_entity',
                  legal_entity,
                )}
                dropdown={ourLegalEntity}
                style={{ margin: 0 }}
              />
            </>
          ) : (
            <>
              <InputElement
                text="ЮЛ заказчик"
                value={element.client_legal_entity}
                setValue={(legal_entity) => updateStep2Value(
                  element.id,
                  'client_legal_entity',
                  legal_entity,
                )}
                dropdown={ourLegalEntity}
                style={{ margin: 0 }}
              />

              <InputElement
                text="Исполнитель"
                value={element?.blogger?.name}
                disabled
                style={{ margin: 0 }}
              />

              {element.is_contract
                ? (
                  <ContractElement
                    text="ЮЛ исполнителя"
                    style={{ alignItems: 'center' }}
                  >
                    <SearchBar
                      text={bloggerLegalEntity[0]}
                      setText={changeBloggerLegalEntity}
                      list={allLegalEntity}
                      type="legal_details"
                      containerClassName={classes.seacrh}
                    />
                  </ContractElement>
                ) : (
                  <InputElement
                    text="Способ оплаты"
                    value={element.payment.payment_method}
                    onChange={(event) => updatePayment('payment_method', (event.target.value))}
                    style={{ margin: 0 }}
                  />
                )}
            </>
          )}

          <InputElement
            type="number"
            text="Сумма договора"
            placeholder="0 00 Р"
            value={element.payment.client_sum}
            onChange={(event) => updatePayment('client_sum', getSumRuble(event.target.value))}
            style={{ margin: 0 }}
          />
          <div className={classes.note}>
            *Сумма исходного договора, из которого будет вычитаться СК
          </div>

          <CheckboxElement
            text="НДС*"
            style={{ margin: 0 }}
            innerStyle={{ width: 'auto' }}
          >
            <CheckboxItem
              text="Да"
              status={element.payment.is_client_nds}
              setStatus={() => updatePayment('is_client_nds', true)}
            />
            <CheckboxItem
              text="Нет"
              status={!element.payment.is_client_nds}
              setStatus={() => updatePayment('is_client_nds', false)}
            />
          </CheckboxElement>
          <div className={classes.note}>
            *Наличие НДС в исходном договоре
          </div>

          {element.payment.is_client_nds
            && (
            <InputElement
              type="number"
              text="Сумма договора без НДС"
              placeholder="0 00 Р"
              value={element.payment.client_sum_without_tax}
              disabled
              style={{ margin: 0 }}
            />
            )}

          <InputElement
            type="number"
            text="Сумма СК"
            placeholder="0 00 Р"
            value={element.payment.total_sum}
            onChange={(event) => updatePayment('total_sum', getSumRuble(event.target.value))}
            style={{ margin: 0 }}
          />

          <InputElement
            type="number"
            text="% СК"
            placeholder="%"
            value={element.payment.ak_percent}
            onChange={(event) => updatePayment('ak_percent', getSumRuble(event.target.value))}
            style={{ margin: 0 }}
          />

          <CheckboxElement
            text="НДС*"
            style={{ margin: 0 }}
            innerStyle={{ width: 'auto' }}
          >
            <CheckboxItem
              text="Да"
              status={element.payment.is_nds}
              setStatus={() => updatePayment('is_nds', true)}
            />
            <CheckboxItem
              text="Нет"
              status={!element.payment.is_nds}
              setStatus={() => updatePayment('is_nds', false)}
            />
          </CheckboxElement>
          <div className={classes.note}>
            *Наличие НДС в СК договоре
          </div>

          {element.payment.is_nds && (
          <InputElement
            type="number"
            text="Сумма СК без НДС"
            placeholder="0 00 Р"
            value={numberToSum(priceWithoutNds)}
            style={{ margin: 0 }}
            disabled
          />
          )}

          <ContractElement
            text="Порядок оплаты"
            style={{ alignItems: 'start', margin: 0 }}
          >
            <div className={classes.ourСontacts__container}>
              {priceDate.map((date) => (
                <PaymentDateNoTime
                  key={date.reactId}
                  element={date}
                  deleteDate={deleteDate}
                  changeDate={changeDate}
                />
              ))}
            </div>
          </ContractElement>

          <div className={classes.addPublication}>
            <NewAddBtn text="Добавить дату оплаты" onClick={() => addDate({ reactId: Date.now(), date: null, sum: '' }, true)} />
          </div>

          <ContractElement
            text="Контакты"
            style={{
              alignItems: 'start',
              margin: 0,
            }}
            textStyle={{ height: 32 }}
          >
            <div className={classes.ourСontacts__container}>
              {clientContact.map((element) => (
                <InputElement
                  key={element.id}
                  value={element.email}
                  placeholder="Почта(заполните данные в карточке)"
                  style={{ margin: 0 }}
                  disabled
                  cancelBtn
                  deleteHandler={() => deleteClientContact(element.id)}
                />
              ))}
            </div>
          </ContractElement>

          <ContractElement
            text="Контактные лица с нашей стороны"
            style={{ alignItems: 'start', marginBottom: 20 }}
          >
            <div className={classes.ourСontacts__container}>
              {responsibleLawyer.map((element) => (
                <SearchBar
                  key={element.fieldId}
                  list={lawyerList}
                  text={element}
                  setText={changeResponsibleLawyer}
                  placeholder="Юрист"
                  required
                  type="staff"
                />
              ))}

              {responsibleDocument.map((element) => (
                <SearchBar
                  key={element.fieldId}
                  list={documentList}
                  text={element}
                  setText={changeResponsibleDocument}
                  placeholder="Док"
                  required
                  type="staff"
                />
              ))}

              <InputElement
                value={element.manager[0].fullname}
                style={{ margin: 0 }}
                disabled
              />

              {responsible.map((element) => (
                <SearchBar
                  key={element.fieldId}
                  list={managerList}
                  text={element}
                  setText={changeResponsible}
                  deleteElement={deleteResponsible}
                  checkResponsible={checkResponsible}
                  required
                  type="staff"
                />
              ))}
              <NewAddBtn text="Добавить контакт" onClick={addResponsible} />
            </div>
          </ContractElement>

          <ContractElement text="Прикрепить файл к договору">
            <div className={classes.ourСontacts__container}>
              {files?.length > 0 && (
              <div className={classes.files}>
                {files?.map((file) => (
                  <Files
                    key={file.id}
                    file={file}
                    fetchBloggerBID={getSKBIDFile}
                    deleteFileHandler={() => deleteFileHandler(file.id)}
                    setFiles={setFiles}
                  />
                ))}
              </div>
              )}

              <MyInputFile
                onChange={(event) => addFileHandler(event)}
                multiple
                htmlFor={`payment_element_${element.id}`}
              />
            </div>
          </ContractElement>
        </Align>
      </CSSTransition>
    </div>
  );
}

export default Step2;
