export const styleForModal = {
  display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center', minHeight: '313px', height: 'auto', padding: '50px 80px',
};

export const buttonStyle = {
  top: '25px',
  left: '25px',
  width: '150px',
  height: '27px',
  padding: '0px',
  fontSize: '12px',
  zIndex: '3',
};

export const stylesForButtonApply = {
  padding: '5px 40px',
  border: '1px solid black',
  fontSize: '12px',
};

export const stylesForButtonCancel = {
  padding: '5px 40px',
  background: 'transparent',
  color: 'black',
  border: '1px solid black',
  fontSize: '12px',
};
