import qs from 'qs';

import { $authHost } from 'API';

import { getDate } from '../utils/getDate';

export const getClients = async (type, filter) => {
  const params = {};

  if (type >= 0) {
    params.type = type;
  }

  if (filter) {
    params.name = filter;
  }

  const { data } = await $authHost.get('/client_page/client/', { params });
  return data;
};

export const autocompleteClients = async () => {
  const { data } = await $authHost.get('autocomplete/clients/');
  return data;
};

export const getOneClient = async (id) => {
  const { data } = await $authHost.get(`/client_page/client/${id}`);
  return data;
};

export const getClientProjects = async (
  id,
  page,
  limit,
  controller,
  filter,
) => {
  const params = { page, limit };

  if (filter) {
    if (filter.name) {
      params.project_name = filter.name;
    }

    let tempArray = [];

    filter.manager.forEach((manager) => {
      tempArray.push(manager.id);
    });
    params.manager = tempArray;

    tempArray = [];
    filter.responsible.forEach((responsible) => {
      tempArray.push(responsible.id);
    });
    params.responsible = tempArray;

    if (filter.date_after) {
      params.date_after = getDate(filter.date_after);
    }

    if (filter.date_before) {
      params.date_before = getDate(filter.date_before);
    }

    if (filter.payment_dates_after) {
      params.payment_dates_after = getDate(filter.payment_dates_after);
    }

    if (filter.payment_dates_before) {
      params.payment_dates_before = getDate(filter.payment_dates_before);
    }

    if (filter.status.length > 0) {
      params.status = filter.status;
    }
  }

  const { data } = await $authHost.get(`/client_page/client/${id}/projects/`, {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return data;
};

export const getClientProjectsStats = async (id) => {
  const { data } = await $authHost.get(
    `/client_page/client/${id}/project_stat/`,
  );
  return data;
};

export const getClientsStat = async () => {
  const { data } = await $authHost.get('/client_page/client/type_stat/');
  return data;
};

export const getTypeClients = async () => {
  const { data } = await $authHost.get('/autocomplete/client_types/');
  return data;
};

export const patchClient = async (id, data) => {
  await $authHost.patch(`/client_page/client/${id}/`, data);
};

export const addFileClient = async (id, files, options) => {
  const response = await $authHost.post(
    `/client_page/client/${id}/file/`,
    files,
    options,
  );
  return response;
};

export const deleteFileClient = async (file_id) => {
  await $authHost.delete(`/client_page/client_file/${file_id}/`);
};

export const postClient = async (dataClient) => {
  const response = await $authHost.post('/client_page/client/', dataClient);
  return response;
};

export const deleteClientContacts = async (id) => {
  await $authHost.delete(`/client_page/contacts/${id}/`);
};

export const postClientContact = async (dataContact) => {
  const { data } = await $authHost.post('/client_page/contacts/', dataContact);
  return data;
};

export const patchClientContact = async (id, data) => {
  await $authHost.patch(`/client_page/contacts/${id}/`, data);
};

export const deleteClient = async (id) => {
  await $authHost.delete(`/client_page/client/${id}/`);
};
