// @ts-nocheck
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { exportGoogleCostsTable, getCostBids } from 'API/FinanceAPI/CostsAPI';
import { autocompleteAllLegalEntities } from 'API/LawyersAPI/AutocompleteAPI';
import { fetchAllProjects, fetchStaff } from 'API/ManagerAPI/AutocompleteAPI';
import { check } from 'API/UserAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import ModalExportGoogle from 'components/UI/Modals/ModalExportGoogle/ModalExportGoogle';
import OneTableRow from 'components/UI/OneTableRow/OneTableRow';
import PageTitle from 'components/UI/PageTitle/PageTitle';
import RegistryTable from 'components/UI/RegistryTable/RegistryTable';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from 'hooks/useLogout';

import { numberToSum, numberToSumWithoutDigits } from 'utils/getSums';

import { columns, HEADERS_GOOGLE_EXPORT_COSTS } from './consts/consts';

import CostsFinanceFilter from './CostsFinanceFilter';

import classes from './CostsFinance.module.scss';

const CostsFinance = observer(() => {
  const { projectsFinance, modal, user } = useContext(Context);

  const table = useRef();
  const isFirstRender = useRef(true);

  const controller = new AbortController();

  const [logout] = useLogout();

  const [fetching, setFetching] = useState(true);

  const [staffList, setStaffList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const [allLegalEntity, setAllLegalEntity] = useState([]);

  const [columnsList, setColumnsList] = useState([]);
  const [url, setUrl] = useState('');
  const [totalSum, setTotalSum] = useState(0);
  const [currentSum, setCurrentSum] = useState(0);

  // Первичная загрузка данных с сервера
  useEffect(() => {
    check()
      .then(() => {
        fetchStaff({ department: [0, 4, 5, 6] }).then((data) => setStaffList(data));

        fetchStaff({ job: 2 }).then((data) => setDocumentList(data));

        autocompleteAllLegalEntities()
          .then((allLegalEntityData) => setAllLegalEntity(allLegalEntityData));

        fetchAllProjects().then((data) => setProjectsList(data));
      })
      .catch((error) => logout(error));
  }, []);

  const [fetchProjects, isProjectsLoading] = useFetching(
    async (page, limit, signal, filter, update, finance) => {
      await check().catch((error) => logout(error));

      const data = await getCostBids(page, limit, signal, filter, finance);
      projectsFinance.setCostsTotalCount(data.count);

      setTotalSum(data?.main_total_sum);
      setCurrentSum(data?.total_sum);

      if (update === 'update' || projectsFinance.costsPage === 1) {
        projectsFinance.setCostsPage(2);
        projectsFinance.setCosts(data.results, 'update');
      } else {
        projectsFinance.setCostsPage(projectsFinance.costsPage + 1);
        projectsFinance.setCosts(data.results);
      }

      setFetching(false);
    },
  );

  const totalPages = Math.ceil(
    projectsFinance.costsTotalCount / projectsFinance.costsLimit,
  );

  /* Запрос на сервер при скролле до конца таблицы */

  useEffect(() => {
    if (fetching) {
      if (projectsFinance.costsFilter) {
        fetchProjects(
          projectsFinance.costsPage,
          projectsFinance.costsLimit,
          controller.signal,
          projectsFinance.costsFilter,
          '',
          user.user.job.id === 3,
        );
      } else {
        fetchProjects(
          projectsFinance.costsPage,
          projectsFinance.costsLimit,
          controller.signal,
        );
      }
    }

    return () => {
      controller.abort();
    };
  }, [fetching]);

  /* Когда доскролили до нижней части таблицы (первое условие)
    Проверяем текущую страницу с общим количеством страниц(второе условие)
    Далее делаем запрос
  */

  const scrollHandler = (event) => {
    if (
      event.target.scrollHeight
        - (event.target.scrollTop + event.target.clientHeight)
        < 600
      && projectsFinance.costsPage <= totalPages
    ) {
      setFetching(true);
    }
  };

  const click = async () => {
    try {
      const res = await exportGoogleCostsTable(
        projectsFinance.costsPage,
        projectsFinance.costsLimit,
        controller.signal,
        projectsFinance.costsFilter,
        columnsList,
        user.user.job.id === 3,
      );
      setUrl(res.sheet_url);
    } catch (err) {
      console.error(err);
    }
  };

  // Наблюдение за скроллом
  useEffect(() => {
    if (isProjectsLoading) return;

    const element = table.current;
    element.addEventListener('scroll', scrollHandler);
    return function () {
      element.removeEventListener('scroll', scrollHandler);
    };
  }, [isProjectsLoading]);

  if (isProjectsLoading && isFirstRender.current) {
    isFirstRender.current = false;
    return <Spinner animation="grow" />;
  }

  const exportGoogleHandler = () => {
    modal.setModalExportGoogleProps({
      headers: HEADERS_GOOGLE_EXPORT_COSTS,
    });
    modal.setModalExportGoogleVisible(true);
  };

  return (
    <>
      <PageTitle
        style={{ justifyContent: 'space-between' }}
        button={(
          <SuccessBtn
            text="Экспорт в Google"
            style={{ padding: '4px 18px', fontSize: 11 }}
            onClick={() => exportGoogleHandler()}
          />
        )}
      >Заявки на оплату
      </PageTitle>

      <div className={classes.status}>Статус</div>

      <CostsFinanceFilter
        projectList={projectsList}
        responsibleList={staffList}
        fetchProjects={fetchProjects}
        documentList={documentList}
        allLegalEntity={allLegalEntity}
      />

      <RegistryTable
        fetching={fetching}
        columns={columns}
        data={projectsFinance.costs}
        tableName="costsFinance"
        costsFinance
        tableRef={table}
      />

      <OneTableRow
        rowName="mainManager"
        columns={[
          { id: 'text', label: 'ИТОГО' },
          { id: 'sum', label: numberToSumWithoutDigits(currentSum) },
        ]}
      />

      <OneTableRow
        style={{ marginTop: '10px' }}
        rowName="mainManager"
        columns={[
          { id: 'text', label: 'ОБЩАЯ СУММА' },
          { id: 'sum', label: numberToSum(totalSum) },
        ]}
      />

      <ModalExportGoogle
        show={modal.modalExportGoogleVisible}
        onHide={() => modal.setModalExportGoogleVisible(false)}
        props={modal.modalExportGoogleProps}
        click={click}
        columnsList={columnsList}
        setColumnsList={setColumnsList}
        url={url}
        setUrl={setUrl}
      />
    </>
  );
});

export default CostsFinance;
