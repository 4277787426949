import qs from 'qs';

import { $authHost } from 'API';

export const fetchStaff = async (params) => {
  const { data } = await $authHost.get('autocomplete/staff/', {
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return data;
};

export const fetchStaffTeamsPage = async (params) => {
  const newParams = {};

  if (params?.department >= 0) {
    newParams.department = params.department;
  }

  if (params?.job >= 0) {
    newParams.job = params.job;
  }

  if (params?.staff_name) {
    newParams.staff_name = params.staff_name;
  }

  const { data } = await $authHost.get('/staff_page/team/', {
    params,
    paramsSerializer: (newParams) => qs.stringify(newParams, { arrayFormat: 'repeat' }),
  });
  return data;
};

export const fetchDepartments = async () => {
  const { data } = await $authHost.get('/autocomplete/departments/');
  return data;
};

export const fetchRoles = async () => {
  const { data } = await $authHost.get('/autocomplete/roles/');
  return data;
};

export const fetchTeams = async () => {
  const { data } = await $authHost.get('/autocomplete/teams/');
  return data;
};

export const fetchTeamsCreateStaff = async (params) => {
  const { data } = await $authHost.get('/autocomplete/short_teams/', {
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return data;
};

export const fetchAllProjects = async () => {
  const { data } = await $authHost.get('/autocomplete/projects/');
  return data;
};
