// @ts-nocheck
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { autocompleteBloggers } from 'API/BloggerAPI';
import { getClients } from 'API/ClientAPI';
import { fetchAllProjects, fetchStaff } from 'API/ManagerAPI/AutocompleteAPI';
import { fetchManagerProjects } from 'API/ManagerAPI/ProjectAPI';
import { check } from 'API/UserAPI';

import ModalCreateProject from 'components/UI/Modals/ModalCreateProject/ModalCreateProject';
import PageTitle from 'components/UI/PageTitle/PageTitle';

import SuccessBtn from '../../UI/Btns/SuccessBtn';
import OneTableRow from '../../UI/OneTableRow/OneTableRow';
import RegistryTable from '../../UI/RegistryTable/RegistryTable';

import { useFetching } from '../../../hooks/useFetching';
import { useLogout } from '../../../hooks/useLogout';

import ManagersFilters from './ManagersFilters';

import classes from './MainTables.module.css';

const MainMarketing = observer(() => {
  const { mainMarketing, modal, user } = useContext(Context);
  const table = useRef();
  const isFirstRender = useRef(true);
  const controller = new AbortController();
  const [logout] = useLogout();

  const [fetching, setFetching] = useState(true);

  const [managerList, setManagerList] = useState([]);
  const [responsibleList, setresponsibleList] = useState([]);
  const [directorList, setDirectorList] = useState([]);

  const [clientsList, setClientList] = useState([]);
  const [bloggersList, setBloggersList] = useState([]);
  const [projectsList, setProjectsList] = useState([]);

  const [fetchAllProjectsFunc] = useFetching(
    () => { fetchAllProjects().then((data) => setProjectsList(data)); },
  );

  // Первичная загрузка данных с сервера
  useEffect(() => {
    check()
      .then(() => {
        fetchStaff({ job: 0 }).then((data) => setManagerList(data));
        fetchStaff({ job: [4, 5, 6] }).then((data) => setresponsibleList(data));

        getClients().then((data) => setClientList(data));
        autocompleteBloggers().then((data) => setBloggersList(data));
        fetchAllProjectsFunc();

        fetchStaff({ role: 1 }).then((data) => setDirectorList(data));
      })
      .catch((error) => logout(error));
  }, []);

  const [fetchProjects, isProjectsLoading] = useFetching(
    async (page, limit, signal, filter, update) => {
      await check().catch((error) => logout(error));

      const data = await fetchManagerProjects(page, limit, signal, filter);
      mainMarketing.setProjectsTotalCount(data.count);

      if (update === 'update' || mainMarketing.projectsPage === 1) {
        mainMarketing.setProjectsPage(2);
        mainMarketing.setProjects(data.results, 'update');
      } else if (update === 'addProject') {
        mainMarketing.setProjects(data.results, 'update');
      } else {
        mainMarketing.setProjectsPage(mainMarketing.projectsPage + 1);
        mainMarketing.setProjects(data.results);
      }

      setFetching(false);
    },
  );

  const totalPages = Math.ceil(
    mainMarketing.projectsTotalCount / mainMarketing.projectsLimit,
  );

  /* Запрос на сервер при скролле до конца таблицы */

  useEffect(() => {
    if (fetching) {
      if (mainMarketing.projectsFilter) {
        fetchProjects(
          mainMarketing.projectsPage,
          mainMarketing.projectsLimit,
          controller.signal,
          mainMarketing.projectsFilter,
        );
      } else {
        fetchProjects(
          mainMarketing.projectsPage,
          mainMarketing.projectsLimit,
          controller.signal,
        );
      }
    }

    return () => {
      controller.abort();
    };
  }, [fetching]);

  useEffect(() => {
    setFetching(true);
  }, [user.department]);

  /* Когда доскролили до нижней части таблицы (первое условие)
  Проверяем текущую страницу с общим количеством страниц(второе условие)
  Далее делаем запрос
  */
  const scrollHandler = (event) => {
    if (
      event.target.scrollHeight
     - (event.target.scrollTop + event.target.clientHeight)
     < 600
     && mainMarketing.projectsPage <= totalPages
    ) {
      setFetching(true);
    }
  };

  // Наблюдение за скролломы
  useEffect(() => {
    if (isProjectsLoading) return;

    const element = table.current;
    element.addEventListener('scroll', scrollHandler);
    return function () {
      element.removeEventListener('scroll', scrollHandler);
    };
  }, [isProjectsLoading]);

  const openModalHandler = () => {
    modal.setModalCreateProjectProps({
      fetchProjects: () => fetchProjects(
        undefined,
        mainMarketing.projectsLimit * (mainMarketing.projectsPage - 1),
        controller.signal,
        mainMarketing.projectsFilter,
        'addProject',
      ),
      fetchAllProjectsFunc,
    });
    modal.setModalCreateProjectVisible(true);
  };

  let columns = [
    { id: 'client', label: 'Заказчик' },
    { id: 'title', label: 'Проект' },
    { id: 'created', label: 'Дата создания' },
    { id: 'manager', label: 'Менеджер' },
    { id: 'director', label: 'Руководитель' },
    { id: 'status', label: 'Статус' },
  ];

  if (user.department === 4 || user.department === 5 || user.department === 6) {
    columns = [
      { id: 'client', label: 'Заказчик' },
      { id: 'title', label: 'Проект' },
      { id: 'created', label: 'Дата создания' },
      { id: 'manager', label: 'Менеджер' },
      { id: 'responsible', label: 'Ответственный' },
      { id: 'status', label: 'Статус' },
    ];
  }

  if (isProjectsLoading && isFirstRender.current) {
    isFirstRender.current = false;
    return <Spinner animation="grow" />;
  }

  return (
    <>
      <div className={classes.main__wrapper}>
        <PageTitle>Проекты</PageTitle>

        <SuccessBtn
          text="Добавить новый проект"
          style={{ padding: '4px 18px', fontSize: 11 }}
          onClick={openModalHandler}
        />
      </div>

      <ManagersFilters
        projectList={projectsList}
        clientList={[...bloggersList, ...clientsList]}
        fetchProjects={fetchProjects}
        managerList={managerList}
        directorList={directorList}
        responsibleList={responsibleList}
      />

      <RegistryTable
        noDeadline
        columns={columns}
        data={mainMarketing.projects}
        fetching={fetching}
        tableName="projectsManager"
        manager1
        tableRef={table}
      />

      <OneTableRow
        rowName="mainManager"
        columns={[
          { id: 'text', label: 'Итого' },
          { id: 'sum', label: mainMarketing.projectsTotalCount },
        ]}
      />

      <ModalCreateProject
        show={modal.modalCreateProjectVisible}
        onHide={() => modal.setModalCreateProjectVisible(false)}
        props={modal.modalCreateProjectProps}
      />
    </>
  );
});

export default MainMarketing;
