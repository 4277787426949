// @ts-nocheck

import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { exportGoogleRegistryTableMain, exportGoogleRegistryTableMainLawyers } from 'API/FinanceAPI/RegistryTableAPI';
import {
  autocompleteAllLegalEntities,
} from 'API/LawyersAPI/AutocompleteAPI';
import { fetchLawyerContracts } from 'API/LawyersAPI/ContractAPI';
import { fetchAllProjects, fetchStaff } from 'API/ManagerAPI/AutocompleteAPI';
import { check } from 'API/UserAPI';

import { HEADERS_GOOGLE_EXPORT_LAWYERS_MAIN } from 'components/Finance/FinanceMainOld/consts';
import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import ModalExportGoogle from 'components/UI/Modals/ModalExportGoogle/ModalExportGoogle';
import RegistryTable from 'components/UI/RegistryTable/RegistryTable';

import OneTableRow from '../../UI/OneTableRow/OneTableRow';
import PageTitle from '../../UI/PageTitle/PageTitle';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from '../../../hooks/useLogout';

import { numberToSum } from '../../../utils/getSums';

import { columns } from './consts';

// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import Filters from './Filters';

import classes from './MainLawyers.module.css';

const MainLawyers = observer(() => {
  const { contracts, modal, projectsFinance } = useContext(Context);
  const table = useRef();
  const isFirstRender = useRef(true);
  const controller = new AbortController();
  const [logout] = useLogout();

  const [fetching, setFetching] = useState(true);
  const [url, setUrl] = useState('');
  const [totalSum, setTotalSum] = useState(0);

  const [columnsList, setColumnsList] = useState([]);

  // Нужно эти 5 состояний вынести в mobX

  const [projects, setProjects] = useState([]);
  const [lawyers, setLawyers] = useState([]);
  const [document, setDocument] = useState([]);
  const [managers, setManagers] = useState([]);
  const [allLegalEntity, setAllLegalEntity] = useState([]);

  useEffect(() => {
    check()
      .then(() => {
        fetchStaff({ job: 1 }).then((lawyersData) => setLawyers(lawyersData));

        fetchStaff({ job: 2 }).then((documentData) => setDocument(documentData));

        fetchStaff({ job: 0 }).then((managersData) => setManagers(managersData));

        autocompleteAllLegalEntities()
          .then((allLegalEntityData) => setAllLegalEntity(allLegalEntityData));

        fetchAllProjects().then((projectsData) => setProjects(projectsData));
      })
      .catch((error) => logout(error));
  }, []);

  const [fetchContracts, isContractsLoading] = useFetching(
    async (page, limit, signal, filter, update) => {
      await check().catch((error) => logout(error));

      const data = await fetchLawyerContracts(page, limit, signal, filter);
      contracts.setContractsTotalCount(data.count);

      if (update || contracts.contractsPage === 1) {
        setTotalSum(data.total_sum);
        contracts.setContractsPage(2);
        contracts.setContracts(data.results, 'update');
      } else {
        contracts.setContractsPage(contracts.contractsPage + 1);
        contracts.setContracts(data.results);
      }

      setFetching(false);
    },
  );

  const totalPages = Math.ceil(
    contracts.contractsTotalCount / contracts.contractsLimit,
  );
  /* Запрос на сервер при скролле до конца таблицы */

  useEffect(() => {
    if (fetching) {
      if (contracts.contractsFilter) {
        fetchContracts(
          contracts.contractsPage,
          contracts.contractsLimit,
          controller.signal,
          contracts.contractsFilter,
        );
      } else {
        fetchContracts(
          contracts.contractsPage,
          contracts.contractsLimit,
          controller.signal,
        );
      }
    }

    return () => {
      controller.abort();
    };
  }, [fetching]);

  // Когда доскролили до нижней части таблицы (первое условие)
  // Проверяем текущую страницу с общим количеством страниц(второе условие)
  // Далее делаем запрос

  const scrollHandler = debounce((event) => {
    if (
      event.target.scrollHeight
        - (event.target.scrollTop + event.target.clientHeight)
        < 600
      && contracts.contractsPage <= totalPages
    ) {
      setFetching(true);
    }
  }, 100);

  // Наблюдение за скроллом

  useEffect(() => {
    if (isContractsLoading) return;

    const element = table.current;
    element.addEventListener('scroll', scrollHandler);
    return function () {
      element.removeEventListener('scroll', scrollHandler);
    };
  }, [isContractsLoading]);

  const click = async () => {
    try {
      const res = await exportGoogleRegistryTableMainLawyers(
        contracts.contractsPage,
        contracts.contractsLimit,
        controller.signal,
        contracts.contractsFilter,
        columnsList,
      );
      setUrl(res.sheet_url);
    } catch (err) {
      console.error(err);
    }
  };

  const exportGoogleHandler = () => {
    modal.setModalExportGoogleProps({
      headers: HEADERS_GOOGLE_EXPORT_LAWYERS_MAIN,
    });
    modal.setModalExportGoogleVisible(true);
  };

  if (isContractsLoading && isFirstRender.current) {
    isFirstRender.current = false;
    return <Spinner animation="grow" />;
  }

  const propsForFilters = {
    projectList: projects,
    lawyersList: lawyers,
    documentList: document,
    managerList: managers,
    allLegalEntity,
    fetchContracts,
    controller,
  };

  const propsForRegistryTable = {
    fetching,
    lawyers: true,
    columns,
    noDeadline: true,
    data: contracts.contracts,
    tableName: 'projects',
    tableRef: table,
  };

  return (
    <>
      <PageTitle
        style={{ justifyContent: 'space-between' }}
        button={(
          <SuccessBtn
            text="Экспорт в Google"
            style={{ padding: '4px 18px', fontSize: 11 }}
            onClick={() => exportGoogleHandler()}
          />
        )}
      >
        Документы
      </PageTitle>

      <span className={classes.lawyers__status}>Статус документа</span>

      <Filters {...propsForFilters} />

      <RegistryTable {...propsForRegistryTable} />

      <OneTableRow
        rowName="mainLawyer"
        columns={[
          { id: 'text', label: 'ИТОГО' },
          { id: 'sum', label: contracts.contractsTotalCount },
        ]}
      />

      <OneTableRow
        rowName="mainLawyerSum"
        columns={[
          { id: 'text', label: 'ОБЩАЯ СУММА ДОГОВОРОВ' },
          { id: 'sum', label: numberToSum(totalSum, true) },
        ]}
      />

      <ModalExportGoogle
        show={modal.modalExportGoogleVisible}
        onHide={() => modal.setModalExportGoogleVisible(false)}
        props={modal.modalExportGoogleProps}
        click={click}
        columnsList={columnsList}
        setColumnsList={setColumnsList}
        url={url}
        setUrl={setUrl}
      />
    </>
  );
});

export default MainLawyers;
