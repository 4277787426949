/* eslint-disable linebreak-style */
import React, { useCallback } from 'react';
import debounce from 'lodash.debounce';

import { checkInn } from 'API/LegalEntityAPI';

import LawyersInput from 'components/UI/MyInput/LawyersInput';

import classes from './InputElements.module.css';

function InputElementInn(props) {
  const {
    type,
    text,
    placeholder,
    value,
    setValue,
    disabled,
    required,
    onChange,
    onClick,
    style,
    textStyle,
    inputStyle,
    className,
    textClassName,
    elementClassName,
    setLegalEntities,
    legalEntities,
    element,
  } = props;

  const innDebounce = useCallback(
    debounce((inn) => {
      checkInn(inn).then((data) => {
        if (data?.length >= 1) {
          const tempName = data[0]?.value.split(' ')[0];

          let tempType = { id: 8, name: '-' };

          if (tempName === 'СЗ') {
            tempType = { id: 0, name: 'СЗ' };
          } else if (tempName === 'ИП') {
            tempType = { id: 1, name: 'ИП' };
          } else if (tempName === 'ООО') {
            tempType = { id: 2, name: 'ООО' };
          } else if (tempName === 'АО') {
            tempType = { id: 3, name: 'АО' };
          } else if (tempName === 'ОАО') {
            tempType = { id: 4, name: 'ОАО' };
          } else if (tempName === 'СПАО') {
            tempType = { id: 5, name: 'СПАО' };
          } else if (tempName === 'ПАО') {
            tempType = { id: 6, name: 'ПАО' };
          } else if (tempName === 'АНО') {
            tempType = { id: 7, name: 'АНО' };
          } else if (tempName === 'ТОО') {
            tempType = { id: 8, name: 'ТОО' };
          }

          setLegalEntities(
            legalEntities.map((legalEntity) => (legalEntity.id === element.id
              ? {
                ...legalEntity,
                inn: data[0].data.inn,
                legal_name: data[0].value,
                type: tempType,
                ogrn: data[0]?.data?.ogrn ? data[0].data.ogrn : legalEntity.ogrn,
                ogrnip: data[0]?.data?.ogrn ? data[0].data.ogrn : legalEntity.ogrnip,
                address: data[0]?.data?.address?.unrestricted_value ? data[0]?.data?.address?.unrestricted_value : legalEntity.address,
                kpp: data[0]?.data?.kpp ? data[0].data.kpp : legalEntity.kpp,
                bank_details: [{
                  id: Date.now(),
                  rc: '',
                  bank: '',
                  bik: '',
                  kc: '',
                }],
              }
              : legalEntity)),
          );
        } else {
          setLegalEntities(
            legalEntities.map((legalEntity) => (legalEntity.id === element.id
              ? {
                ...legalEntity,
                legal_name: '',
                inn,
                type: { id: 8, name: '-' },
              }
              : legalEntity)),
          );
        }
      });
    }, 450),
    [],
  );

  const updateInn = (value) => {
    if (onChange) {
      onChange(value);
    }

    innDebounce(value);
  };

  return (
    <div
      className={[classes.contract__element, elementClassName, disabled && classes.disabled].join(' ')}
      style={style}
    >
      {text && (
        <div
          className={[classes.element__text, textClassName].join(' ')}
          style={textStyle}
        >
          {text}
        </div>
      )}

      <LawyersInput
        type={type}
        className={className}
        placeholder={placeholder}
        value={value}
        setValue={setValue}
        disabled={disabled}
        onChange={(event) => updateInn(event.target.value)}
        onClick={onClick}
        required={required}
        style={inputStyle}
      />

    </div>
  );
}

export default InputElementInn;
