import React from 'react';

import classes from '../Table.module.scss';

function HeadCell(props) {
  const {
    label, headCellStyle,
  } = props;

  return (
    <div className={[classes.cell].join(' ')} style={headCellStyle}>
      {label}
    </div>
  );
}

export default HeadCell;
