// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  addWeeks, endOfISOWeek, endOfMonth, startOfISOWeek, startOfMonth,
} from 'date-fns';

import { check } from 'API/UserAPI';

import InputElement from 'components/UI/InputElements/InputElement';

import { useStateNew } from 'hooks/useStateNew';
import { useLogout } from '../../../hooks/useLogout';

import {
  DATE_DROPDOWN,
  DEADLINE_STATUSES, TASKS_STATUSES_FILTER,
} from 'utils/consts';

import SuccessBtn from '../Btns/SuccessBtn';
import SecondDoubleDate from '../DoubleDate/SecondDoubleDate';
import CheckBox from '../MyCheckbox/CheckBox';
import SearchBar from '../SearchBar/SearchBar';

import classes from './MyTasksFilter.module.scss';

function MyTasksFilter(props) {
  const {
    isOpen,
    setIsOpen,
    values,
    onUpdateField,
    projects,
    fetchTasks,
    setFilter,
  } = props;

  const [logout] = useLogout();
  const classNames = [classes.wrapper, isOpen && classes.open, !isOpen && classes.closed].join(' ');

  const [project, , changeProject] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
    },
  ]);

  const [deadlineStart, setDeadlineStart] = useState('');
  const [deadlineEnd, setDeadlineEnd] = useState('');
  const [dateStatus, setDateStatus] = useState({});

  const [deadlineStatus, setDeadlineStatus] = useState({});

  useEffect(() => {
    if (dateStatus.id === 0) {
      setDeadlineStart(addWeeks(startOfISOWeek(Date.now()), -1));
      setDeadlineEnd(addWeeks(endOfISOWeek(Date.now()), -1));
    } else if (dateStatus.id === 1) {
      setDeadlineStart(startOfISOWeek(Date.now()));
      setDeadlineEnd(endOfISOWeek(Date.now()));
    } else if (dateStatus.id === 2) {
      setDeadlineStart(addWeeks(startOfISOWeek(Date.now()), 1));
      setDeadlineEnd(addWeeks(endOfISOWeek(Date.now()), 1));
    } else if (dateStatus.id === 3) {
      setDeadlineStart(startOfMonth(Date.now()));
      setDeadlineEnd(endOfMonth(Date.now()));
    }
  }, [dateStatus]);

  const handler = () => {
    setIsOpen(false);
    check()
      .then(() => {
        const filter = {
          project,
          deadlineStart,
          deadlineEnd,
          deadline: deadlineStatus,
          status: { id: values.projectStatus },
          project_status: { id: values.projectStatus },
        };

        if (setFilter) {
          setFilter(filter);
        }

        fetchTasks(filter);
      })
      .catch((error) => logout(error));
  };

  return (
    <div className={classNames}>
      <div className={classes.input__container}>
        <fieldset className={classes.fieldset}>
          <span>Статус задачи</span>

          {TASKS_STATUSES_FILTER.LIST.map((status) => (
            <CheckBox
              name={status.id}
              key={status.id}
              value={status.id}
              label={status.label}
              onChange={() => {
                if (values?.projectStatus?.includes(status.id)) {
                  const index = values?.projectStatus?.findIndex(
                    (item) => item === status.id,
                  );
                  values?.projectStatus?.splice(index, 1);
                } else {
                  values?.projectStatus?.push(status.id);
                }
                onUpdateField({ projectStatus: values?.projectStatus });
              }}
              checked={values?.projectStatus?.includes(status.id)}
              transparentBg
            />
          ))}
        </fieldset>
        <fieldset className={classes.input__fieldset}>
          <label>Проект</label>
          <SearchBar
            list={projects}
            text={project[0]}
            setText={changeProject}
            type="project"
          />
        </fieldset>

        <fieldset className={classes.input__fieldset}>
          <label>Дедлайн</label>

          <div className={classes.deadline}>
            <SecondDoubleDate
              value1={deadlineStart}
              value2={deadlineEnd}
              setValue1={(event) => setDeadlineStart(event)}
              setValue2={(event) => setDeadlineEnd(event)}
            />
            <InputElement
              value={dateStatus}
              setValue={setDateStatus}
              dropdown={DATE_DROPDOWN.LIST}
              inputStyle={{ fontSize: 13 }}
            />
          </div>

        </fieldset>

        <fieldset className={classes.input__fieldset}>
          <label>Дедлайн</label>

          <InputElement
            value={deadlineStatus}
            setValue={setDeadlineStatus}
            dropdown={DEADLINE_STATUSES.LIST}
            inputStyle={{ fontSize: 13 }}
          />
        </fieldset>
      </div>

      <div className={classes.btn}>
        <SuccessBtn
          text="Применить фильтр"
          style={{ padding: '4px 18px', fontSize: 11 }}
          onClick={() => handler()}
        />
      </div>
    </div>
  );
}

export default MyTasksFilter;
