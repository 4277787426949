export const checkActive = (key) => localStorage.getItem(key);

export const checkActiveNavbar = (className, pathTo, pathname) => {
  if (
    (pathname === '/client/' && pathTo === '/clients')
    || (pathname === '/card/' && pathTo === '/teams')
    || (pathname === '/blogger/' && pathTo === '/bloggers')
    || (pathname === '/project/' && pathTo === '/')
    || (pathname === '/contract/' && pathTo === '/contracts')
    || (pathname === '/paymentStatus/' && pathTo === '/paymentsStatus')
    || (pathname === '/cost/' && pathTo === '/costs')
    || (pathname === '/payment/' && pathTo === '/payments')
    || (pathname === '/legalEntity/' && pathTo === '/legalEntities')
  ) {
    className += 'active';
  }

  return className;
};
