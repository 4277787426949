// @ts-nocheck
import React from 'react';

import MyDateInput from 'components/UI/MyDateInput/MyDateInput';

import 'react-datepicker/dist/react-datepicker.css';
import classes from '../ModalCreateContract.module.css';

const DateElement = React.memo((props) => {
  const {
    text,
    value1,
    value2,
    setValue1,
    setValue2,
    onChange1,
    onChange2,
    style,
    inner_style,
    textStyle,
    disabled,
    onClick,
  } = props;

  return (
    <div className={classes.contract__element} style={style}>
      <div className={classes.element__text} style={textStyle}>
        {text}
      </div>

      <div className={classes.element__inner} style={inner_style}>
        <div className={classes.element__inner__item}>
          с
          <MyDateInput
            value={value1}
            setValue={setValue1}
            onChange={onChange1}
            className={classes.datePicker}
            disabled={disabled}
            onClick={onClick}
            maxDate={value2 && new Date(value2)}
          />
        </div>

        <div className={classes.element__inner__item}>
          по
          <MyDateInput
            value={value2}
            setValue={setValue2}
            onChange={onChange2}
            className={classes.datePicker}
            disabled={disabled}
            onClick={onClick}
            minDate={value1 && new Date(value1)}
          />
        </div>
      </div>
    </div>
  );
});

export default DateElement;
