import React from 'react';

import css from './Section.module.scss';

function Section(props) {
  const {
    title,
    children,
    className,
    button,
    style,
    headerStyle,
    contentStyle,
    titleStyle,
  } = props;

  return (
    <section className={css.section} style={style}>
      {!!title && (
        <div className={css.header} style={headerStyle}>
          <h6 className={css.title} style={titleStyle}>
            {title}
          </h6>
          {!!button && button}
        </div>
      )}
      <div className={[css.content, className].join(' ')} style={contentStyle}>
        {children}
      </div>
    </section>
  );
}

export default Section;
