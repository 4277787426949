// @ts-nocheck

import React, {
  useCallback,
  useContext, useEffect, useRef, useState,
} from 'react';
import { endOfISOWeek, startOfISOWeek } from 'date-fns';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../index';

import { fetchNewsTaskPage, patchNewsTaskPage, postNewsTaskPage } from 'API/NewsAPI';
import {
  createTaskUser,
  deleteTaskUser,
  fetchOneUserTasks,
  patchTaskUser,
} from 'API/TaskAPI';
import { check } from 'API/UserAPI';

import DoubleDate from 'components/UI/DoubleDate/DoubleDate';
import ModalConfirm from 'components/UI/Modals/ModalConfirm/ModalConfirm';
import Section from 'components/UI/Section/Section';
import Table from 'components/UI/Table/Table';
import WeekResults from 'components/UI/WeekResults/WeekResults';

import PageTitle from '../../UI/PageTitle/PageTitle';

import { useFetching } from 'hooks/useFetching';
import { useStateNew2 } from 'hooks/useStateNew2';
import { useLogout } from '../../../hooks/useLogout';

import { getDate, getFormattedDate } from 'utils/getDate';

import { columns } from './consts';

const TasksPageLawyer = observer(() => {
  const { user, modal, toast } = useContext(Context);
  const [logout] = useLogout();
  const isFirstRender = useRef(true);

  const [tasks, setTasks] = useState([]);

  const [startWeek] = useState(startOfISOWeek(new Date()));
  const [endWeek] = useState(endOfISOWeek(new Date()));

  const [weekNews, setWeekNews, updateNews] = useStateNew2([]);
  const [newsArchive, setNewsArchive] = useState([]);
  const [startDate, setStartDate] = useState(startOfISOWeek(new Date()));
  const [endDate, setEndDate] = useState(endOfISOWeek(new Date()));

  const [fetchTasks] = useFetching(async () => {
    const data = await fetchOneUserTasks();
    setTasks(data);
  });

  const testDebounce = useCallback(
    debounce((id, data) => {
      check()
        .then(() => {
          patchTaskUser(id, data);
        })
        .catch((error) => logout(error));
    }, 5000),
    [],
  );

  const addTaskhandler = () => {
    check()
      .then(() => {
        const data = {
          name: '',
          deadline: '',
          responsible: [{ id: user.user.staff.id }],
          status: {
            id: 0,
          },
        };
        createTaskUser(data).then(() => {
          fetchTasks();
        });
      })
      .catch((error) => logout(error));
  };

  const deleteTaskHandler = (id) => {
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить задачу?',
      click: () => {
        setTasks(tasks.filter((element) => element.id !== id));

        check()
          .then(() => {
            deleteTaskUser(id);
          })
          .catch((error) => logout(error));
      },
    });
    modal.setModalConfirmVisible(true);
  };

  const updateTaskHandler = (id, fieldName, value) => {
    setTasks(
      tasks.map((element) => (element.id === id
        ? {
          ...element,
          [fieldName]: value,
        }
        : element)),
    );
    if (fieldName === 'deadline') {
      testDebounce(id, { [fieldName]: getDate(value) });
    } else {
      testDebounce(id, { [fieldName]: value });
    }
  };

  const addSubTask = (e, id) => {
    const newTask = {
      name: '',
      deadline: '',
      responsible: [],
      status: { id: 0 },
    };
    const currentTask = tasks.find((el) => el.id === id);
    currentTask?.subtasks.unshift(newTask);

    check()
      .then(() => {
        patchTaskUser(id, { ...currentTask }).then(() => {
          fetchTasks();
        });
      })
      .catch((error) => logout(error));
  };

  const updateSubTask = (id, fieldName, value) => {
    const currentTask = tasks.find((task) => task.subtasks.find((subtask) => subtask.id === id));
    setTasks(
      tasks.map((task) => (task.id === currentTask.id
        ? {
          ...task,
          subtasks: [...task.subtasks].map((subtask) => (subtask.id === id
            ? {
              ...subtask,
              [fieldName]: value,
            }
            : subtask)),
        }
        : task)),
    );

    if (fieldName === 'deadline') {
      const editedSubtasks = currentTask.subtasks.map((subtask) => (subtask.id === id
        ? {
          ...subtask,
          deadline: getFormattedDate(value),
        }
        : subtask));
      check()
        .then(() => {
          patchTaskUser(currentTask.id, { subtasks: editedSubtasks }).then(
            () => {
              fetchTasks();
            },
          );
        })
        .catch((error) => logout(error));
    } else if (fieldName === 'status') {
      const editedSubtasks = currentTask.subtasks.map((subtask) => (subtask.id === id
        ? {
          ...subtask,
          status: { id: value },
        }
        : subtask));

      check()
        .then(() => {
          patchTaskUser(currentTask.id, { subtasks: editedSubtasks }).then(
            () => {
              fetchTasks();
            },
          );
        })
        .catch((error) => logout(error));
    } else {
      const editedSubtasks = currentTask.subtasks.map((subtask) => (subtask.id === id
        ? {
          ...subtask,
          [fieldName]: value,
        }
        : subtask));
      testDebounce(currentTask.id, { subtasks: editedSubtasks });
    }
  };

  const deleteSubTask = (parentId, subtaskId) => {
    const currentTask = tasks.find((task) => task.id === parentId);
    const taskWithoutSubtask = {
      ...currentTask,
      subtasks: currentTask.subtasks.filter(
        (subtask) => subtask.id !== subtaskId,
      ),
    };

    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить подзадачу?',
      click: () => {
        check()
          .then(() => {
            patchTaskUser(parentId, taskWithoutSubtask).then(() => {
              fetchTasks();
            });
          })
          .catch((error) => logout(error));
      },
    });
    modal.setModalConfirmVisible(true);
  };

  useEffect(() => {
    check()
      .then(() => {
        fetchTasks();

        const filter = {
          created_after: getDate(startWeek),
          created_before: getDate(endWeek),
        };

        const { id } = user.user.staff;

        fetchNewsTaskPage(id, filter).then((data) => {
          if (data.length > 0) {
            setNewsArchive(data);
            setWeekNews(data);
          } else {
            postNewsTaskPage({ staff: { id } }).then(() => {
              fetchNewsTaskPage(id, filter).then((data) => {
                setNewsArchive(data);
                setWeekNews(data);
              });
            });
          }
        });
      })
      .catch((error) => logout(error));
  }, []);

  const newsDebounce = useCallback(
    debounce((id, filter) => {
      check()
        .then(() => {
          fetchNewsTaskPage(id, filter).then((data) => setNewsArchive(data));
        })
        .catch((error) => logout(error));
    }, 250),
    [],
  );

  const saveNewsHandler = () => {
    check()
      .then(() => {
        patchNewsTaskPage(weekNews[0].id, {
          good: weekNews[0].good,
          bad: weekNews[0].bad,
        }).then(() => {
          toast.setShowSuccess(true);
          toast.setToastInfo('Новость успешно сохранена');

          fetchNewsTaskPage(user.user.staff.id, {
            created_after: getDate(startDate),
            created_before: getDate(endDate),
          }).then((data) => setNewsArchive(data));
        });
      })
      .catch((error) => logout(error));
  };

  useEffect(() => {
    if (isFirstRender.current) return;

    newsDebounce(user.user.staff.id, {
      created_after: getDate(startDate),
      created_before: getDate(endDate),
    });
  }, [startDate, endDate]);

  return (
    <>
      <PageTitle>Мои задачи</PageTitle>

      <ModalConfirm
        show={modal.modalConfirmVisible}
        onHide={() => modal.setModalConfirmVisible(false)}
        props={modal.modalConfirmProps}
      />

      <Table
        noCheckbox
        headerStyle={{ marginTop: 35 }}
        type="tasksLawyers"
        updateFunc={updateTaskHandler}
        addHandler={addTaskhandler}
        deleteHandler={deleteTaskHandler}
        columns={columns}
        data={tasks}
        tableName="lawyersTasks"
        addChildren={addSubTask}
        updateChildren={updateSubTask}
        deleteChildren={deleteSubTask}
      />

      <Section
        title="Результаты недели"
        button={<DoubleDate value1={startWeek} value2={endWeek} disabled />}
        headerStyle={{ justifyContent: 'flex-start' }}
        style={{ marginTop: 35 }}
      >
        <WeekResults
          data={weekNews}
          updateNews={updateNews}
          saveNewsHandler={saveNewsHandler}
        />
      </Section>

      <Section
        title="Архив новостей"
        button={(
          <DoubleDate
            value1={startDate}
            setValue1={setStartDate}
            value2={endDate}
            setValue2={setEndDate}
          />
        )}
        contentStyle={{ padding: 0, background: 'none' }}
        headerStyle={{ justifyContent: 'flex-start' }}
      >
        <Table
          headerStyle={{ marginTop: 35 }}
          cellStyle={{
            height: '100%',
            padding: '10px 25px',
            alignItems: 'start',
          }}
          headCellStyle={{ padding: '15px 25px', height: '100%' }}
          type="news"
          columns={[
            {
              id: 'goodNews',
              label: 'Хорошие новости',
            },
            {
              id: 'badNews',
              label: 'Плохие новости',
            },
          ]}
          data={newsArchive}
          tableName="newsArchive"
          noCheckbox
          staffCard
          news
        />
      </Section>
    </>
  );
});

export default TasksPageLawyer;
