// @ts-nocheck
import React, { useState } from 'react';

import DeleteBtn from 'components/UI/Btns/DeleteBtn';
import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import InputElement from 'components/UI/InputElements/InputElement';

import classes from '../ModalCreateEntity.module.scss';

function BankInfo(props) {
  const {
    state,
    setState,
    save,
    cancel,
    style,
    deleteBankDetails,
  } = props;

  const [activeBtn, setActiveBtn] = useState(false);

  const saveHandler = () => {
    setActiveBtn(false);
    save();
  };

  const cancelHandler = () => {
    setActiveBtn(false);
    cancel();
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}> {/* Здесь добавляем контейнер с flex */}
        <InputElement
          text="р/с"
          textStyle={{ minWidth: 230 }}
          value={state?.rc}
          onChange={(event) => setState({ ...state, rc: event.target.value })}
          onClick={() => setActiveBtn(true)}
          style={style}
        />
        <DeleteBtn onClick={() => deleteBankDetails(state.id)} className={classes.deleteBtn} />
      </div>
      <InputElement
        text="Банк"
        textStyle={{ minWidth: 230 }}
        value={state?.bank}
        onChange={(event) => setState({ ...state, bank: event.target.value })}
        onClick={() => setActiveBtn(true)}
        style={style}
      />

      <InputElement
        text="БИК"
        textStyle={{ minWidth: 230 }}
        value={state?.bik}
        onChange={(event) => setState({ ...state, bik: event.target.value })}
        onClick={() => setActiveBtn(true)}
        style={style}
      />
      <InputElement
        text="к/с"
        textStyle={{ minWidth: 230 }}
        value={state?.kc}
        onChange={(event) => setState({ ...state, kc: event.target.value })}
        onClick={() => setActiveBtn(true)}
        style={style}
      />

      {save && activeBtn && (
      <div className={classes.btnSave__wrapper}>
        <SuccessBtn
          text="Сохранить"
          style={{
            padding: '2px 20px',
            fontSize: 13,
          }}
          onClick={() => saveHandler()}
        />

        <SuccessBtn
          text="Отменить"
          type="white"
          style={{
            padding: '2px 20px',
            fontSize: 13,
          }}
          onClick={() => cancelHandler()}
        />
      </div>
      )}
    </div>
  );
}

export default BankInfo;
