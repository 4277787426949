// @ts-nocheck

import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { endOfISOWeek, startOfISOWeek } from 'date-fns';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import {
  fetchDepartments,
  fetchRoles,
  fetchTeams,
} from 'API/ManagerAPI/AutocompleteAPI';
import { fetchDeleteStaffCard, fetchTasksStaff } from 'API/StaffAPI';
import { check } from 'API/UserAPI';
import { fetchAllProjects } from '../../../API/ManagerAPI/AutocompleteAPI';
import { fetchNewsTaskPage } from '../../../API/NewsAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import DeleteUser from 'components/UI/DeleteUser/DeleteUser';
import ModalDeleteUser from 'components/UI/Modals/ModalDeleteUser/ModalDeleteUser';
import PageTitle from 'components/UI/PageTitle/PageTitle';
import ProfileAvatar from 'components/UI/ProfileAvatar/ProfileAvatar';
import Section from 'components/UI/Section/Section';
import Table from 'components/UI/Table/Table';

import DoubleDate from '../../UI/DoubleDate/DoubleDate';
import FilterBtn from '../../UI/FilterBtn/FilterBtn';
import MyTasksFilter from '../../UI/MyTasksFilter/MyTasksFilter';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from '../../../hooks/useLogout';

import { TIME_FOR_DELETE_USER } from 'utils/consts';
import { getDate } from '../../../utils/getDate';

import { stylesForStaffCardLawyersButton, stylesForStaffCardLawyersPageTitle } from './consts';

import CardInfo from './components/CardInfo';

import classes from './EmployeePage.module.scss';

const StaffCardLawyers = observer((props) => {
  const {
    staffInfo, fetchCard, setStaffInfo, isFirstRender,
  } = props;
  const { modal, toast, user } = useContext(Context);
  const { id } = useParams();
  const navigate = useNavigate();
  const [logout] = useLogout();

  const [teams, setTeams] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [filtersState, updateFiltersState] = useState({
    projectStatus: [],
    status: [],
  });
  const [filter, setFilter] = useState(undefined);

  const [startDate, setStartDate] = useState(startOfISOWeek(new Date()));
  const [endDate, setEndDate] = useState(endOfISOWeek(new Date()));

  const [newsArchive, setNewsArchive] = useState([]);

  const [tasks, setTasks] = useState([]);
  const [projects, setProjects] = useState([]);

  const [fetchTasks] = useFetching(async (filter) => {
    const data = await fetchTasksStaff(id, filter);
    setTasks(data);
  });

  const newsDebounce = useCallback(
    debounce((id, filter) => {
      check()
        .then(() => {
          fetchNewsTaskPage(id, filter).then((data) => setNewsArchive(data));
        })
        .catch((error) => logout(error));
    }, 250),
    [],
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        await check();

        fetchTasks();
        const projectsData = await fetchAllProjects();
        setProjects(projectsData);

        const departmentsData = await fetchDepartments();
        setDepartments(departmentsData);

        const rolesData = await fetchRoles();
        setRoles(rolesData);

        const teamsData = await fetchTeams();
        setTeams(teamsData);
      } catch (error) {
        logout();
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isFirstRender.current) return;

    newsDebounce(id, {
      created_after: getDate(startDate),
      created_before: getDate(endDate),
    });
  }, [startDate, endDate]);

  const deleteUserHandler = () => {
    modal.setModalConfirmVisible(true);
    modal.setModalConfirmProps({
      text: 'Вы подтверждаете удаление сотрудника?',
      click: async () => {
        try {
          await check();
          await fetchDeleteStaffCard(id);
          modal.setModalConfirmVisible(false);
          toast.setToastInfo('Сотрудник удалён');
          toast.setShowSuccess(true);
          setTimeout(() => navigate('/teams'), TIME_FOR_DELETE_USER);
        } catch (error) {
          logout();
        }
      },
    });
  };

  return (
    <>
      <div className={classes.staffCardLawyer__pageTitle__container}>
        <SuccessBtn
          text="Вернуться назад"
          type="white"
          back
          onClick={() => navigate(-1)}
          style={stylesForStaffCardLawyersButton}
        />
        <PageTitle style={stylesForStaffCardLawyersPageTitle}>
          {staffInfo.fullname}
        </PageTitle>
      </div>

      <Section style={{ marginTop: 35 }}>
        <h5 className={classes.sectionTitle}>Карточка сотрудника</h5>
        <div className={classes.employeeCard}>
          <ProfileAvatar info={staffInfo} />
          <CardInfo
            staffInfo={staffInfo}
            setStaffInfo={setStaffInfo}
            fetchCard={fetchCard}
            departments={departments}
            teams={teams}
            roles={roles}
          />
        </div>
      </Section>

      <div className={classes.title}>
        <div className={classes.sectionTitle}>Занятость сотрудника</div>

        <FilterBtn isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>

      <MyTasksFilter
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setFilter={setFilter}
        values={filtersState}
        onUpdateField={(payload) => {
          updateFiltersState({
            ...filtersState,
            ...payload,
          });
        }}
        projects={projects}
        fetchTasks={fetchTasks}
      />

      <Table
        headerStyle={{ marginTop: 15, width: '100%' }}
        style={{ marginBottom: 20 }}
        headCellStyle={{ padding: ' 20px 15px' }}
        type="staffCardMarketing"
        columns={[
          {
            id: 'name',
            label: 'Задача',
          },

          {
            id: 'deadline',
            label: 'Дедлайн',
          },
          {
            id: 'project',
            label: 'Проект',
          },
          {
            id: 'managerStatus',
            label: 'Статус задачи',
          },
        ]}
        data={tasks}
        tableName="managersTasksStaffCard"
        noCheckbox
      />

      <Section
        title="Новости за неделю"
        button={(
          <DoubleDate
            value1={startDate}
            setValue1={setStartDate}
            value2={endDate}
            setValue2={setEndDate}
          />
        )}
        style={{ marginTop: 30 }}
        contentStyle={{ padding: 0, background: 'none' }}
        headerStyle={{ justifyContent: 'flex-start' }}
      >
        <Table
          headerStyle={{ marginTop: 25, width: '100%' }}
          cellStyle={{
            height: '100%',
            maxHeight: '100%',
            padding: '10px 25px',
            alignItems: 'start',
          }}
          headCellStyle={{ padding: '15px 25px', fontSize: 16, height: '100%' }}
          type="news"
          columns={[
            {
              id: 'goodNews',
              label: 'Хорошие новости',
            },
            {
              id: 'badNews',
              label: 'Плохие новости',
            },
          ]}
          data={newsArchive}
          tableName="newsArchive"
          noCheckbox
        />
      </Section>

      {(user.user.role.id === 0 || user.user.role.id === 1)
      && (
        <>
          <DeleteUser onClick={deleteUserHandler} deleteUser />
          <ModalDeleteUser
            userName={staffInfo.fullname}
            show={modal.modalConfirmVisible}
            onHide={() => modal.setModalConfirmVisible(false)}
            props={modal.modalConfirmProps}
          />
        </>
      )}
    </>
  );
});

export default StaffCardLawyers;
