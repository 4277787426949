import { reversDate } from 'utils/getDate';
import { numberToSum } from 'utils/getSums';

export const prepareDataForTable = (data) => data.map((item) => ({
  id: item.id,
  project_id: item?.project?.project_id,
  project_name: item?.project?.name,
  client_legal_name: item?.client_legal_entity?.legal_name,
  contract_number: item?.number,
  total_sum: numberToSum(item?.total_sum),
  sum_without_tax: numberToSum(item?.sum_without_tax),
  paid_sum: numberToSum(item?.paid_sum, true),
  margin: numberToSum(item.project?.margin, true),
  contract_type: item?.type?.name,
  contractor_legal_name: item?.contractor_legal_entity?.legal_name,
  tax: item?.contractor_legal_entity?.tax.toFixed(2),
  payment_dates: item?.payments,
  credit: numberToSum(item?.credit, true),
  accounting_month: reversDate(item?.accounting_month),
  // actual_month: reversDate(item?.payment?.actual_month), // Поле пока не активно, не удалять
}));
