export const FIELDS = [
  {
    id: 'id', label: '№ проекта', type: 'number', width: 1,
  },
  {
    id: 'project',
    label: 'Название проекта',
    type: 'autocomplete',
    list: 'project',
    multiple: true,
    width: 3,
  },
  {
    id: 'client_id',
    label: 'ЮЛ заказчика',
    type: 'autocompleteLegal',
    multiple: true,
    width: 3,
  },
  {
    id: 'contractor_id',
    label: 'ЮЛ исполнителя',
    type: 'autocompleteLegal',
    multiple: true,
    width: 3,
  },
  {
    id: 'director',
    label: 'Руководитель',
    type: 'autocompleteStaff',
    list: 'directors',
    multiple: true,
    width: 3,
  },
  {
    id: 'manager',
    label: 'Менеджер',
    type: 'autocompleteStaff',
    list: 'managers',
    multiple: true,
    width: 3,
  },
  {
    id: 'responsible',
    label: 'Ответственный',
    type: 'autocompleteStaff',
    list: 'responsibles',
    multiple: true,
    width: 3,
  },
  {
    id: 'period_date_start',
    label: 'Начало периода реализации',
    type: 'doubleDate',
    value_id_1: 'date_start_after',
    value_id_2: 'date_start_before',
    width: 5,
  },
  {
    id: 'period_date_end',
    label: 'Конец периода реализации',
    type: 'doubleDate',
    value_id_1: 'date_end_after',
    value_id_2: 'date_end_before',
    width: 5,
  },
  {
    id: 'contract_format_gl',
    label: 'G/L',
    type: 'dropdown',
    list: 'contract_format_gl',
    multiple: true,
    width: 3,
  },
  {
    id: 'payment_date',
    label: 'Порядок оплаты',
    type: 'doubleDate',
    value_id_1: 'payment_date_start',
    value_id_2: 'payment_date_end',
    width: 5,
  },
  {
    id: 'is_adesk',
    label: 'Адеск',
    type: 'dropdown',
    list: 'true_false',
    width: 3,
  },
  {
    id: 'month_date',
    label: 'Месяц учета',
    type: 'doubleDate',
    value_id_1: 'month_date_start',
    value_id_2: 'month_date_end',
    width: 5,
  },
  {
    id: 'quarter',
    label: 'Квартал',
    type: 'dropdown',
    list: 'quarterList',
    multiple: true,
    width: 3,
  },
  {
    id: 'indicators',
    label: 'Индикатор',
    type: 'dropdown',
    list: 'indicators_1',
    indicators: true,
    width: 3,
  },
];
