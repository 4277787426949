import React from 'react';
import { Helmet } from 'react-helmet';

import OneLegalEntityPage from 'components/General/OneLegalEntityPage/OneLegalEntityPage';

function LegalEntityPage() {
  return (
    <section className="Mysection">
      <Helmet>
        <title>Юридическое лицо| DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <OneLegalEntityPage />
    </section>
  );
}

export default LegalEntityPage;
