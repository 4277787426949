// @ts-nocheck
import React from 'react';
import { Helmet } from 'react-helmet';

import AuthWindow from '../components/AuthWindow/AuthWindow';

function Auth() {
  return (
    <div className="container__login">
      <Helmet>
        <title>Авторизация | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <AuthWindow />
    </div>
  );
}

export default Auth;
