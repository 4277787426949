// @ts-nocheck

import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Popover } from '@mui/material';
import copy_icon from 'assets/icons/Btns/copy-icon.svg';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import {
  autocompleteAllLegalEntities,
  autocompleteBillStatuses,
  autocompleteContractStatuses,
  autocompleteContractTypes,
  autocompletePublicationTypes,
} from 'API/LawyersAPI/AutocompleteAPI';
import {
  fetchOneBloggerContractPublications,
  fetchOneContract,
  fetchOneContractIsCommon,
  fetchOneContractPublications,
  getHistoryLawyer,
  patchOneContract,
  patchOneContractPublications,
  postOneContractBankDetails,
} from 'API/LawyersAPI/ContractAPI';
import { fetchStaff } from 'API/ManagerAPI/AutocompleteAPI';
import { check } from '../../../API/UserAPI';

import SuccessBtn from '../../UI/Btns/SuccessBtn';
import HistoryСhanges from '../../UI/HistoryСhanges/HistoryСhanges';
import ModalConfirm from '../../UI/Modals/ModalConfirm/ModalConfirm';
import MyDropdown from '../../UI/MyDropdown/MyDropdown';
import PageTitle from '../../UI/PageTitle/PageTitle';
import StatusDropdown from '../../UI/StatusDropdown/StatusDropdown';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from '../../../hooks/useLogout';
import { useStateNew } from '../../../hooks/useStateNew';

import { EXPORT_TYPES } from 'utils/consts';
import { getDate } from 'utils/getDate';

import { PROJECT_ROUTE } from '../../../utils/consts';
import { stylesForMyDropdown } from '../../Marketing/ProjectPage/consts';
import { defaultLegalEntities } from './consts';

import ClientInfo from './components/ClientInfo';
import CommonInfo from './components/CommonInfo';
// eslint-disable-next-line import/no-named-as-default
import ContractDetails from './components/ContractDetails';
import PaymentInfo from './components/PaymentInfo';
import PublicationInfo from './components/publications/PublicationInfo';
import PublicationInfoCl from './components/publications/PublicationInfoCl';

import classes from './ProjectPage.module.scss';

const ProjectPageLawyers = observer(() => {
  const { modal, toast } = useContext(Context);
  const { id } = useParams();
  const navigate = useNavigate();
  const isFirstRender = useRef(true);
  const [logout] = useLogout();

  const [info, setInfo] = useState([]);

  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ

  const baseUrl = `${process.env.REACT_APP_API_URL}/contract/${id}/download/`;

  const [longExportTypesWithLink, setLongExportTypesWithLink] = useState([
    { id: 0, export: EXPORT_TYPES[0], exportLink: `${baseUrl}?type=contract` },
    { id: 1, export: EXPORT_TYPES[1], exportLink: `${baseUrl}?type=appendix` },
  ]);

  const [exportType, setExportType] = useState({
    id: null,
    export: '',
  });

  const [
    selectedContractorLegalEntity,
    setSelectedContractorLegalEntity,
    changeSelectedContractorLegalEntity,
  ] = useStateNew([
    {
      fieldId: Date.now(),
      ...defaultLegalEntities,
    },
  ]);

  const [
    selectedClientLegalEntity,
    setSelectedClientLegalEntity,
    changeSelectedClientLegalEntity,
  ] = useStateNew([
    {
      fieldId: Date.now(),
      ...defaultLegalEntities,
    },
  ]);

  const [commonInfo, setCommonInfo] = useState({});
  const [bloggerBid, setBloggerBid] = useState([]);
  const [contractDetails, setContractDetails] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({});
  const [history, setHistory] = useState([]);

  const [contractorLegalEntityList, setContractorLegalEntityList] = useState(
    [],
  );
  const [clientLegalEntityList, setClientLegalEntityList] = useState([]);

  const [contractFormatList, setContractFormatList] = useState([]);
  const [lawyerList, setLawyerList] = useState([]);
  const [documentList, setDocumentList] = useState([]);

  const [publicationTypes, setPublicationTypes] = useState([]);

  const [performers, setPerformers] = useState([]);

  const [fetchHistory] = useFetching(async () => {
    const historyLawyerData = await getHistoryLawyer(id);
    setHistory(historyLawyerData);
  });

  const [fetchContractPublications] = useFetching(async () => {
    const serverInfo = await fetchOneContractPublications(id);

    setPerformers(serverInfo);
  });

  const [fetchContract, isLoading, fetchError] = useFetching(
    async (typeUpdate) => {
      const serverInfo = await fetchOneContract(id);
      const bloggerPubInfo = await fetchOneBloggerContractPublications(id);

      if (typeUpdate === 'commonInfo') {
        setInfo(serverInfo);

        if (serverInfo?.is_contract === false) {
          if (serverInfo?.type.id === 2) {
            setSelectedContractorLegalEntity([
              {
                name: serverInfo?.blogger?.name,
                fieldId: Date.now(),
              },
            ]);

            setSelectedClientLegalEntity([
              {
                name: 'Физическая оплата',
                fieldId: Date.now(),
              },
            ]);
          } else if (serverInfo?.type.id === 0 || serverInfo?.type.id === 1) {
            setSelectedContractorLegalEntity([
              {
                name: 'Физическая оплата',
                fieldId: Date.now(),
              },
            ]);

            setSelectedClientLegalEntity([
              {
                name: serverInfo?.project?.client?.name,
                fieldId: Date.now(),
              },
            ]);
          } else if (serverInfo?.type.id === 4) {
            // Доход(нам платит клиент)
            setSelectedClientLegalEntity([
              {
                name: serverInfo?.project?.client?.name,
                fieldId: Date.now(),
              },
            ]);

            setSelectedContractorLegalEntity([
              {
                name: 'Физическая оплата',
                fieldId: Date.now(),
              },
            ]);
          } else if (serverInfo?.type.id === 7) {
            // Расход(мы платим блогеру)
            setSelectedClientLegalEntity([
              {
                name: 'Физическая оплата',
                fieldId: Date.now(),
              },
            ]);

            setSelectedContractorLegalEntity([
              {
                name: serverInfo?.blogger?.name,
                fieldId: Date.now(),
              },
            ]);
          }

          // TO DO: Узнать у маши по поводу СК(без договора)
        } else {
          if (serverInfo?.contractor_legal_entity) {
            setSelectedContractorLegalEntity([
              {
                ...serverInfo?.contractor_legal_entity,
                name: serverInfo?.contractor_legal_entity.legal_name,
                fieldId: Date.now(),
              },
            ]);
          }

          if (serverInfo?.client_legal_entity) {
            setSelectedClientLegalEntity([
              {
                ...serverInfo?.client_legal_entity,
                name: serverInfo?.client_legal_entity.legal_name,
                fieldId: Date.now(),
              },
            ]);
          }
        }

        const commonData = {
          type: serverInfo.type,
          blogger: serverInfo.blogger,
          payment_method: serverInfo.payment_method,
          manager: serverInfo.manager,
          staff_contacts: serverInfo.staff_contacts,
          lawyer: serverInfo.lawyer,
          doc_manager: serverInfo.doc_manager,
          blogger_contacts: [
            ...serverInfo.blogger_contacts,
            ...serverInfo.addition_emails,
          ],
          client_contacts: [
            ...serverInfo.client_contacts,
            ...serverInfo.addition_emails,
          ],
          adv_object: serverInfo.adv_object,
          adv_object_comment: serverInfo?.adv_object_comment,
          bid_sent_datetime: serverInfo.bid_sent_datetime,
          number: serverInfo.number,
          is_sent: serverInfo.is_sent,
          sent_datetime: serverInfo.sent_datetime,
          post_branch: serverInfo.post_branch,
          project: serverInfo.project,
          files: serverInfo.files,
          id: serverInfo.id,
        };

        if (serverInfo?.is_contract === false) {
          if (serverInfo?.type.id === 2 || serverInfo?.type.id === 7) {
            commonData.client_legal_entity = {
              name: 'Физическая оплата',
              fieldId: Date.now(),
            };

            commonData.contractor_legal_entity = {
              name: serverInfo?.blogger?.name,
              fieldId: Date.now(),
            };
          } else if (
            serverInfo?.type.id === 0
            || serverInfo?.type.id === 1
            || serverInfo?.type.id === 4
          ) {
            commonData.client_legal_entity = {
              name: serverInfo?.project?.client?.name,
              fieldId: Date.now(),
            };

            commonData.contractor_legal_entity = {
              name: 'Физическая оплата',
              fieldId: Date.now(),
            };
          }

          // TO DO: Узнать у маши по поводу СК(без договора)
        } else {
          if (serverInfo?.contractor_legal_entity) {
            commonData.contractor_legal_entity = {
              ...serverInfo?.contractor_legal_entity,
              name: serverInfo?.contractor_legal_entity.legal_name,
              fieldId: Date.now(),
            };
          } else {
            commonData.contractor_legal_entity = {
              fieldId: Date.now(),
              name: '',
              ...defaultLegalEntities,
            };
          }

          if (serverInfo?.client_legal_entity) {
            commonData.client_legal_entity = {
              ...serverInfo?.client_legal_entity,
              name: serverInfo?.client_legal_entity.legal_name,
              fieldId: Date.now(),
            };
          } else {
            commonData.client_legal_entity = {
              fieldId: Date.now(),
              name: '',
              ...defaultLegalEntities,
            };
          }
        }

        setCommonInfo(commonData);
        fetchHistory();
        return;
      }

      if (typeUpdate === 'common_files') {
        setInfo(serverInfo);
        setCommonInfo({ ...commonInfo, files: serverInfo.files });
        fetchHistory();
        return;
      }

      if (typeUpdate === 'publication') {
        if (serverInfo.type.id === 2) {
          setBloggerBid([{ ...serverInfo, platform: bloggerPubInfo }]);
        } else {
          setBloggerBid(serverInfo?.blogger_contract);
        }
        return;
      }

      if (typeUpdate === 'contract_details') {
        setInfo(serverInfo);
        setContractDetails({
          payment: {
            payment_dates: serverInfo.payments,
            total_sum: serverInfo.total_sum,
            nds_sum: serverInfo.nds_sum,
            ak_sum: serverInfo.ak_sum,
          },
          date_end: serverInfo.date_end,
          date_start: serverInfo.date_start,
          is_repost: serverInfo.is_repost,
          repost_comment: serverInfo.repost_comment,
          is_case: serverInfo.is_case,
          case_comment: serverInfo.case_comment,
          status: serverInfo.status,
          status_date: serverInfo.status_date,
          bill_status: serverInfo.bill_status,
          bill_status_date: serverInfo.bill_status_date,
          closed_status: serverInfo.closed_status,
          closed_status_date: serverInfo.closed_status_date,
          file_links: serverInfo.file_links,
          id: serverInfo.id,
          marking_data: serverInfo?.marking_data,
        });
        fetchHistory();
        return;
      }

      if (typeUpdate === 'payment' || typeUpdate === 'bank_details') {
        setInfo(serverInfo);
        setPaymentInfo({
          payment: {
            quarter_payments: serverInfo.quarter_payments,
            completed_payments: serverInfo.completed_payments,
            adesk: serverInfo.adesk,
            credit: serverInfo.credit,
            paid_sum: serverInfo.paid_sum,
            quarter_start: serverInfo.quarter_start,
            quarter_end: serverInfo.quarter_end,
            is_quarter_closing: serverInfo.is_quarter_closing,
            closing_condition: serverInfo.closing_condition,
            comment: serverInfo.comment,
            closing: serverInfo.closing,
            total_sum: serverInfo.total_sum,
            bank_details: serverInfo.bank_details,
          },
          closing_balance: serverInfo?.closing_balance,
          status: serverInfo?.status,
          status_date: serverInfo?.status_date,
          status_sum: serverInfo?.status_sum,
          bill_status: serverInfo?.bill_status,
          closing_info: serverInfo?.closing_info,
          type: serverInfo?.type,
          id: serverInfo.id,
        });
        fetchHistory();
        return;
      }

      if (serverInfo?.is_contract === false) {
        if (serverInfo?.type.id === 2) {
          setSelectedContractorLegalEntity([
            {
              name: serverInfo?.blogger?.name,
              fieldId: Date.now(),
            },
          ]);

          setSelectedClientLegalEntity([
            {
              name: 'Физическая оплата',
              fieldId: Date.now(),
            },
          ]);
        } else if (serverInfo?.type.id === 0 || serverInfo?.type.id === 1) {
          setSelectedContractorLegalEntity([
            {
              name: 'Физическая оплата',
              fieldId: Date.now(),
            },
          ]);

          setSelectedClientLegalEntity([
            {
              name: serverInfo?.project?.client?.name,
              fieldId: Date.now(),
            },
          ]);
        } else if (serverInfo?.type.id === 4) {
          // Доход(нам платит клиент)
          setSelectedClientLegalEntity([
            {
              name: serverInfo?.project?.client?.name,
              fieldId: Date.now(),
            },
          ]);

          setSelectedContractorLegalEntity([
            {
              name: 'Физическая оплата',
              fieldId: Date.now(),
            },
          ]);
        } else if (serverInfo?.type.id === 7) {
          // Расход(мы платим блогеру)
          setSelectedClientLegalEntity([
            {
              name: 'Физическая оплата',
              fieldId: Date.now(),
            },
          ]);

          setSelectedContractorLegalEntity([
            {
              name: serverInfo?.blogger?.name,
              fieldId: Date.now(),
            },
          ]);
        }

        // TO DO: Узнать у маши по поводу СК(без договора)
      } else {
        if (serverInfo?.contractor_legal_entity) {
          setSelectedContractorLegalEntity([
            {
              ...serverInfo?.contractor_legal_entity,
              name: serverInfo?.contractor_legal_entity.legal_name,
              fieldId: Date.now(),
            },
          ]);
        }

        if (serverInfo?.client_legal_entity) {
          setSelectedClientLegalEntity([
            {
              ...serverInfo?.client_legal_entity,
              name: serverInfo?.client_legal_entity.legal_name,
              fieldId: Date.now(),
            },
          ]);
        }
      }

      const commonData = {
        type: serverInfo?.type,
        blogger: serverInfo?.blogger,
        payment_method: serverInfo?.payment_method,
        manager: serverInfo?.manager,
        staff_contacts: serverInfo?.staff_contacts,
        lawyer: serverInfo?.lawyer,
        doc_manager: serverInfo?.doc_manager,
        blogger_contacts: [
          ...serverInfo.blogger_contacts,
          ...serverInfo.addition_emails,
        ],
        client_contacts: [
          ...serverInfo.client_contacts,
          ...serverInfo.addition_emails,
        ],
        adv_object: serverInfo?.adv_object,
        adv_object_comment: serverInfo?.adv_object_comment,
        bid_sent_datetime: serverInfo?.bid_sent_datetime,
        number: serverInfo?.number,
        is_sent: serverInfo?.is_sent,
        sent_datetime: serverInfo?.sent_datetime,
        post_branch: serverInfo?.post_branch,
        project: serverInfo?.project,
        files: serverInfo?.files,
        id: serverInfo?.id,
      };

      if (serverInfo?.is_contract === false) {
        if (serverInfo?.type.id === 2 || serverInfo?.type.id === 7) {
          commonData.client_legal_entity = {
            name: 'Физическая оплата',
            fieldId: Date.now(),
          };

          commonData.contractor_legal_entity = {
            name: serverInfo?.blogger?.name,
            fieldId: Date.now(),
          };
        } else if (
          serverInfo?.type.id === 0
          || serverInfo?.type.id === 1
          || serverInfo?.type.id === 4
        ) {
          commonData.client_legal_entity = {
            name: serverInfo?.project?.client?.name,
            fieldId: Date.now(),
          };

          commonData.contractor_legal_entity = {
            name: 'Физическая оплата',
            fieldId: Date.now(),
          };
        }
      } else {
        if (serverInfo?.contractor_legal_entity) {
          commonData.contractor_legal_entity = {
            ...serverInfo?.contractor_legal_entity,
            name: serverInfo?.contractor_legal_entity.legal_name,
            fieldId: Date.now(),
          };
        } else {
          commonData.contractor_legal_entity = {
            fieldId: Date.now(),
            name: '',
            ...defaultLegalEntities,
          };
        }

        if (serverInfo?.client_legal_entity) {
          commonData.client_legal_entity = {
            ...serverInfo?.client_legal_entity,
            name: serverInfo?.client_legal_entity.legal_name,
            fieldId: Date.now(),
          };
        } else {
          commonData.client_legal_entity = {
            fieldId: Date.now(),
            name: '',
            ...defaultLegalEntities,
          };
        }
      }

      setCommonInfo(commonData);
      setInfo(serverInfo);

      if (serverInfo.type.id === 2) {
        setBloggerBid([{ ...serverInfo, platform: bloggerPubInfo }]);
      } else {
        setBloggerBid(serverInfo?.blogger_contract);
      }

      setContractDetails({
        payment: {
          payment_dates: serverInfo.payments,
          total_sum: serverInfo.total_sum,
          nds_sum: serverInfo.nds_sum,
          ak_sum: serverInfo.ak_sum,
        },
        date_end: serverInfo?.date_end,
        date_start: serverInfo?.date_start,
        is_repost: serverInfo?.is_repost,
        repost_comment: serverInfo?.repost_comment,
        is_case: serverInfo?.is_case,
        case_comment: serverInfo?.case_comment,
        status: serverInfo?.status,
        status_date: serverInfo?.status_date,
        bill_status: serverInfo?.bill_status,
        bill_status_date: serverInfo?.bill_status_date,
        closed_status: serverInfo?.closed_status,
        closed_status_date: serverInfo?.closed_status_date,
        file_links: serverInfo?.file_links,
        id: serverInfo?.id,
        marking_data: serverInfo.marking_data,
      });

      setPaymentInfo({
        payment: {
          quarter_payments: serverInfo.quarter_payments,
          completed_payments: serverInfo.completed_payments,
          credit: serverInfo.credit,
          paid_sum: serverInfo.paid_sum,
          adesk: serverInfo.adesk,
          quarter_start: serverInfo.quarter_start,
          quarter_end: serverInfo.quarter_end,
          is_quarter_closing: serverInfo.is_quarter_closing,
          closing_condition: serverInfo.closing_condition,
          comment: serverInfo.comment,
          closing: serverInfo.closing,
          total_sum: serverInfo.total_sum,
          bank_details: serverInfo.bank_details,
        },
        closing_balance: serverInfo?.closing_balance,
        status: serverInfo?.status,
        status_date: serverInfo?.status_date,
        status_sum: serverInfo?.status_sum,
        bill_status: serverInfo?.bill_status,
        closing_info: serverInfo?.closing_info,
        type: serverInfo?.type,
        id: serverInfo.id,
      });
    },
  );

  const save = async (data, typeUpdate, idInFunc) => {
    check()
      .then(() => {
        if (typeUpdate === 'client_publication') {
          patchOneContractPublications(id, data)
            .then(() => {
              toast.setToastInfo('Данные сохранены');
              toast.setShowSuccess(true);
            })
            .catch((error) => {
              console.log(error);
              toast.setToastInfo('Обратитесь в техподдержку');
              toast.setShowError(true);
            });
        } else if (typeUpdate === 'bank_details') {
          postOneContractBankDetails(id, data)
            .then(() => {
              toast.setToastInfo('Данные сохранены');
              toast.setShowSuccess(true);
              fetchContract(typeUpdate);
            })
            .catch((error) => {
              console.log(error);
              toast.setToastInfo('Обратитесь в техподдержку');
              toast.setShowError(true);
            });
        } else {
          patchOneContract(idInFunc || id, data)
            .then(() => {
              toast.setToastInfo('Данные сохранены');
              toast.setShowSuccess(true);
              fetchContract(typeUpdate);
            })
            .catch((error) => {
              console.log(error);
              toast.setToastInfo('Обратитесь в техподдержку');
              toast.setShowError(true);
            });
        }
      })
      .catch((error) => logout(error));
  };

  useEffect(() => {
    check().then(() => {
      fetchContract();
      fetchContractPublications();
      fetchHistory();
      autocompleteAllLegalEntities().then((data) => {
        setContractorLegalEntityList(data);
        setClientLegalEntityList(data);
      });
      fetchOneContractIsCommon(id).then((data) => {
        if (data === 204) {
          setLongExportTypesWithLink([...longExportTypesWithLink,
            { id: 2, export: EXPORT_TYPES[2], exportLink: `${baseUrl}?type=contract&common=true` },
            { id: 3, export: EXPORT_TYPES[3], exportLink: `${baseUrl}?type=appendix&common=true` },
          ]);
        }
      }).catch((error) => {
        console.log(error);
        toast.setToastInfo('Обратитесь в техподдержку');
        toast.setShowError(true);
      });

      Promise.all([
        fetchStaff({ job: 1, is_active: true }),
        fetchStaff({ job: 2, is_active: true }),
        autocompleteContractTypes(),
        autocompletePublicationTypes(),
      ]).then(
        ([
          lawyerData,
          documentData,
          contractFormatData,
          publicationTypesData,
        ]) => {
          setLawyerList(lawyerData);
          setDocumentList(documentData);
          setContractFormatList(contractFormatData);
          setPublicationTypes(publicationTypesData);
        },
      );
    });
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(info.project?.name);
    toast.setToastInfo('Название проекта скопировано');
    toast.setShowSuccess(true);
  };

  // Публикации для КЛ договоров

  const updatePublication = (
    performerId,
    platformId,
    publicationId,
    fieldName,
    value,
  ) => {
    const tempArray = performers;
    tempArray.forEach((performer) => {
      if (performer.id === performerId) {
        performer.platform.forEach((platformElement) => {
          if (platformElement.id === platformId) {
            platformElement.publications.forEach((publication) => {
              if (publication.id === publicationId) {
                if (fieldName === 'deadline') {
                  publication[fieldName] = getDate(value);
                } else if (fieldName === 'token') {
                  publication[fieldName] = value;
                  publication.token_date = getDate(Date.now());
                } else {
                  publication[fieldName] = value;
                }
              }
            });
          }
        });
      }
    });

    setPerformers([...tempArray]);
  };

  const updateSubPublication = (
    performerId,
    platformId,
    publicationId,
    subPublicationId,
    fieldName,
    value,
  ) => {
    const tempArray = performers;
    tempArray.forEach((performer) => {
      if (performer.id === performerId) {
        performer.platform.forEach((platformElement) => {
          if (platformElement.id === platformId) {
            platformElement.publications.forEach((publication) => {
              if (publication.id === publicationId) {
                publication.subpublications.forEach((subpublication) => {
                  if (subpublication.id === subPublicationId) {
                    if (fieldName === 'deadline') {
                      subpublication[fieldName] = getDate(value);
                    } else {
                      subpublication[fieldName] = value;
                    }
                  }
                });
              }
            });
          }
        });
      }
    });

    setPerformers([...tempArray]);
  };

  // Публикации для КЛ договоров

  // При первом рендере показываем крутилку
  if (isFirstRender.current) {
    if (isLoading) {
      return <Spinner animation="grow" />;
    }
    isFirstRender.current = false;
  }

  const propsForCommonInfo = {
    lawyerList,
    documentList,
    project: commonInfo,
    selectedContractorLegalEntity,
    setSelectedContractorLegalEntity,
    changeSelectedContractorLegalEntity,
    defaultLegalEntities,
    contractFormatList,
    contractorLegalEntityList,
    selectedClientLegalEntity,
    setSelectedClientLegalEntity,
    changeSelectedClientLegalEntity,
    clientLegalEntityList,
    save,
    fetchContract,
    isContract: info?.is_contract,
  };

  const propsForClientInfo = {
    element:
      info?.type?.id === 2 || info?.type?.id === 3 || info?.type?.id === 4
        ? selectedContractorLegalEntity[0]
        : selectedClientLegalEntity[0],
    name: info?.project?.client?.name,
    type: info?.project?.client?.type,
    files:
      info?.type?.id === 2 ? info?.blogger.files : info?.project?.client?.files,
    contractType: info?.type?.id,
    save,
    payment: paymentInfo,
    setPaymentInfo,
  };

  const propsForContractDetails = {
    project: contractDetails,
    save,
    contractType: info?.type?.id,
    selectedContractorLegalEntity: selectedContractorLegalEntity[0],
  };

  return (
    !fetchError && (
      <>
        <div className={classes.header}>
          <PageTitle className={classes.pageTitleInHeader}>
            <SuccessBtn
              text="Вернуться назад"
              onClick={() => navigate(-1)}
              type="white"
              back
              style={{
                padding: '4px 10px 4px 16px',
                fontSize: '12px',
              }}
            />

            <span
              className={classes.text}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              onClick={() => navigate(`${PROJECT_ROUTE}/${info?.project?.id}`)}
            >
              № {info.project.project_id} {info.project.name}
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={info?.project?.name?.length >= 11 ? open : false}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableAutoFocus
                disableEnforceFocus
              >
                <div className={classes.popoverText}>{info?.project?.name}</div>
              </Popover>
            </span>

            <SuccessBtn
              text={<img src={copy_icon} alt="copy-icon" />}
              onClick={handleCopy}
              type="white"
              style={{
                marginLeft: '-10px',
                padding: '2px 2px 2px 2px',
                fontSize: '12px',
              }}
            />

            <StatusDropdown
              id={info.id}
              projectId={info.project.id}
              status={info?.lawyer_status?.id}
              btnStyle={{ padding: '5px 14px', marginBottom: 14 }}
              lawyer
            />
          </PageTitle>

          <div>
            <MyDropdown
              defaultText="Дополнительно"
              selected={exportType}
              setSelected={setExportType}
              list={longExportTypesWithLink}
              style={stylesForMyDropdown}
              classNameItem={classes.dropdown_item}
              inputClassName={classes.input}
              download
            />
          </div>
        </div>

        <CommonInfo {...propsForCommonInfo} />

        <ClientInfo {...propsForClientInfo} />

        {info.type.id !== 4
          && bloggerBid.map((element, index) => (
            <PublicationInfo
              key={element.id}
              element={element}
              index={index}
              publicationTypes={publicationTypes}
              contractType={info.type.id}
              save={save}
            />
          ))}

        <ContractDetails {...propsForContractDetails} />

        {/* для КЛ договоров */}
        {(info.type.id === 0 || info.type.id === 1) && (
          <PublicationInfoCl
            data={contractDetails}
            headerText="Публикации"
            performers={performers}
            updateFunc={updatePublication}
            updateChildren={updateSubPublication}
            save={save}
          />
        )}

        <PaymentInfo
          fetchContract={fetchContract}
          info={info}
          project={paymentInfo}
          save={save}
        />

        <HistoryСhanges history={history} lawyer />

        <ModalConfirm
          show={modal.modalConfirmVisible}
          onHide={() => modal.setModalConfirmVisible(false)}
          props={modal.modalConfirmProps}
        />
      </>
    )
  );
});

export default ProjectPageLawyers;
