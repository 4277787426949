const { TRUE_FALSE_STATUS } = require('utils/consts');
const { reversDate } = require('utils/getDate');
const { numberToSum } = require('utils/getSums');

export const setLocalStorageValue = (value) => {
  localStorage.setItem('financeMainHiddenColumns', JSON.stringify(value));
};

export const prepareDataForTable = (data) => data.map((item) => ({
  id: item.id,
  project_id: item.project.project_id,
  project_name: item?.project?.name,
  contract_type: item?.type?.name,
  client_legal_name: item?.client_legal_entity?.legal_name,
  contractor_legal_name: item?.contractor_legal_entity?.legal_name,
  manager: item?.manager[0]?.fullname,
  lawyer: item?.lawyer[0]?.fullname,
  doc: item?.doc_manager[0]?.fullname,
  bid_sent_date: reversDate(item?.bid_sent_datetime),
  is_sent: item?.is_sent ? 'Да' : 'Нет',
  sent_date: reversDate(item?.sent_datetime),
  post_branch: item?.post_branch,
  contract_number: item?.number,
  total_sum: numberToSum(item?.total_sum, true),
  nds_sum: numberToSum(item?.nds_sum, true),
  ak_sum: numberToSum(item?.ak_sum, true),
  payment_date: item?.payments,
  payment_sum: item?.payments,
  date_start: reversDate(item?.date_start),
  date_end: reversDate(item?.date_end),
  status: item?.status?.name,
  status_date: reversDate(item?.status_date),
  closed_status: item?.closed_status?.name,
  bill_status: item?.bill_status?.name,
  bill_status_date: reversDate(item?.bill_status_date),
  file_links: item?.file_links,
  comment: item?.payments,
  adesk: item?.adesk ? TRUE_FALSE_STATUS.LIST[1] : TRUE_FALSE_STATUS.LIST[0],
  is_case: item?.is_case ? 'Да' : 'Нет',
  manager_status: item?.project.manager_status.name,
  paid_sum: numberToSum(item?.paid_sum, true),
  credit: numberToSum(item?.credit, true),
  collection_date: reversDate(item?.collection_date),
  quarter_start: item?.quarter_start,
  quarter_end: item?.quarter_end,
  is_quarter_closing: item?.is_quarter_closing ? 'Да' : 'Нет',
  closing_condition: item?.closing_condition,
  quarter_payments: item?.quarter_payments,
  payment: item?.payment,
  type: item?.type,
  margin: numberToSum(item?.margin),
  actual_month: reversDate(item?.payment?.actual_month),
  accounting_month: reversDate(item?.accounting_month),
}));
