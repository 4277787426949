// @ts-nocheck
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import {
  addFileClient,
  deleteClient,
  deleteFileClient,
  getClientProjects,
  getClientProjectsStats,
  getOneClient,
  patchClient,
  patchClientContact,
  postClientContact,
} from 'API/ClientAPI';
import { fetchStaff } from 'API/ManagerAPI/AutocompleteAPI';
import { check } from 'API/UserAPI';
import { deleteClientContacts } from '../../../API/ClientAPI';
import {
  deleteClientLegalEntity,
  patchClientLegalEntity,
  postClientLegalEntity,
} from '../../../API/LegalEntityAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import PageTitle from 'components/UI/PageTitle/PageTitle';
import RegistryTable from 'components/UI/RegistryTable/RegistryTable';
import UploadFilesContainer from 'components/UI/UploadFilesContainer/UploadFilesContainer';

import StepBtn from '../../UI/Btns/StepBtn';
import CLientInfo from '../../UI/CLientInfo/CLientInfo';
import ContactInfo from '../../UI/ContactInfo/ContactInfo';
import ModalConfirm from '../../UI/Modals/ModalConfirm/ModalConfirm';
import OneTableRow from '../../UI/OneTableRow/OneTableRow';
import ProjectsFilterPanel from '../../UI/ProjectsFilterPanel/ProjectsFilterPanel';
import Section from '../../UI/Section/Section';

import { useMultiple } from 'hooks/useMultiple';
import { useProgress } from 'hooks/useProgress';
import { useFetching } from '../../../hooks/useFetching';
import { useLogout } from '../../../hooks/useLogout';
import { useStateNew2 } from '../../../hooks/useStateNew2';

import { defaultLegalEntitiesWithId } from 'utils/consts';
import { getDate } from 'utils/getDate';
import { checkActive } from '../../../utils/checkActive';
import { numberToSum } from '../../../utils/getSums';

import { CLIENT_TYPES } from '../../../utils/consts';

import ProjectsClientInfo from './components/ProjectsClientInfo';

import classes from './OneClientPage.module.css';

const defaultContacts = {
  id: Date.now(),
  fullname: '',
  email: '',
  phone: '',
  telegram: '',
  post: '',
  birthday: '',
};

const OneClientPage = observer(() => {
  const {
    modal, projects, user, toast,
  } = useContext(Context);
  const { id } = useParams();
  const navigate = useNavigate();
  const isFirstRender = useRef(true);
  const [logout] = useLogout();

  const table = useRef();
  const [fetching, setFetching] = useState(true);
  const controller = new AbortController();

  const [isVisible, setIsVisible] = useState(false);

  const [data, setData] = useState({});

  const initialStep = checkActive('oneClientPageStep');

  const [step, setStep] = useState(Number(initialStep));

  localStorage.setItem('oneClientPageStep', step);

  // Step 1
  const [name, setName] = useState('');
  const [typeClient, setTypeClient] = useState({ id: null, name: '' });

  const [files, setFiles] = useState([]);
  const [
    contacts,
    setContacts,
    updateContacts,
    addContact,
    deleteContact] = useStateNew2([defaultContacts]);

  // Step 2
  const [filtersState, updateFiltersState] = useState({
    name: '',
    date_after: '',
    date_before: '',
    payment_dates_after: '',
    payment_dates_before: '',
    status: [],
  });

  const [
    manager,
    changeManager,
    selectedManagerList,
    addManager,
    deleteManager,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    responsible,
    changeResponsible,
    selectedResponsibleList,
    addResponsible,
    deleteResponsible,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [staffList, setStaffList] = useState([]);
  const [secondStaffList, setSecondStaffList] = useState([]);

  const [projectsStats, setProjectsStats] = useState();

  const [fetchClientInfo, isClientInfoLoading] = useFetching(async (update) => {
    const serverData = await getOneClient(id);

    if (update === 'mainInfo') {
      setData(serverData);
      setName(serverData.name);
      setTypeClient(serverData.type);
      return;
    }

    if (update === 'legal_entity') {
      setData(serverData);
      return;
    }

    if (update === 'files') {
      setData(serverData);
      setFiles(serverData.files);
      return;
    }

    if (update === 'contacts') {
      setData(serverData);
      return;
    }

    setData(serverData);
    setName(serverData.name);
    setTypeClient(serverData.type);

    setFiles(serverData.files);

    if (serverData.contacts.length > 0) {
      setContacts(serverData.contacts);
    }
  });

  const [fetchClientProjects, isClientProjectsLoading] = useFetching(
    async (page, limit, signal, filter, update) => {
      await check().catch((error) => logout(error));

      const projectsData = await getClientProjects(
        id,
        page,
        limit,
        signal,
        filter,
      );

      projects.setProjectsTotalCount(projectsData.count);
      projects.setProjectsTotalSum(projectsData.total_sum);
      projects.setProjectsTotalCredit(projectsData.total_credit);
      projects.setProjectsTotalPaidSum(projectsData.total_paid_sum);

      if (update === 'update') {
        projects.setProjectsPage(2);
        projects.setProjects(projectsData.results, 'update');
      } else {
        projects.setProjectsPage(projects.projectsPage + 1);
        projects.setProjects(projectsData.results);
      }

      setFetching(false);
    },
  );

  const totalPages = Math.ceil(
    projects.projectsTotalCount / projects.projectsLimit,
  );

  /* Запрос на сервер при скролле до конца таблицы */

  useEffect(() => {
    if (fetching) {
      if (projects.projectsFilter) {
        fetchClientProjects(
          projects.projectsPage,
          projects.projectsLimit,
          controller.signal,
          projects.projectsFilter,
        );
      } else {
        fetchClientProjects(
          projects.projectsPage,
          projects.projectsLimit,
          controller.signal,
        );
      }
    }

    return () => {
      controller.abort();
    };
  }, [fetching]);

  const scrollHandler = (event) => {
    if (
      event.target.scrollHeight
        - (event.target.scrollTop + event.target.clientHeight)
        < 600
      && projects.projectsPage <= totalPages
    ) {
      setFetching(true);
    }
  };

  // Наблюдение за скроллом
  useEffect(() => {
    if (isClientProjectsLoading) return;

    const element = table?.current;
    element?.addEventListener('scroll', scrollHandler);
    return function () {
      element?.removeEventListener('scroll', scrollHandler);
    };
  }, [isClientProjectsLoading]);

  /* Когда доскролили до нижней части таблицы (первое условие)
    Проверяем текущую страницу с общим количеством страниц(второе условие)
    Далее делаем запрос
  */

  const [progressFiles, addFileFunc] = useProgress(
    setIsVisible,
    fetchClientInfo,
    id,
    logout,
    addFileClient,
  );

  const addFileHandler = (event) => {
    const files = [...event.target.files];
    files.forEach((file) => {
      addFileFunc(file);
    });
  };

  const deleteFileHandler = (fileId) => {
    check()
      .then(() => {
        deleteFileClient(fileId).then(() => {
          fetchClientInfo('files');
        });
      })
      .catch((error) => logout(error));
  };

  useEffect(() => {
    check().then(() => {
      fetchStaff({ job: 0 }).then((data) => setStaffList(data));

      fetchStaff({ job: [4, 5, 6] }).then((data) => setSecondStaffList(data));

      fetchClientInfo();
      getClientProjectsStats(id).then((data) => setProjectsStats(data));
    });
  }, []);

  // Если информация о контакте была получена с сервера,
  //  то для этого контакта мы возвращаем его прошлое состояние
  const cancel = (cancelType, info) => {
    if (cancelType === 'contacts') {
      let prevContactInfo = {};
      data.contacts.forEach((contact) => {
        if (info.id === contact.id) prevContactInfo = contact;
      });

      contacts.forEach((contact) => {
        if (contact.id === info.id) {
          if (prevContactInfo.id) {
            contact.fullname = prevContactInfo.fullname;
            contact.email = prevContactInfo.email;
            contact.phone = prevContactInfo.phone;
            contact.telegram = prevContactInfo.telegram;
            contact.post = prevContactInfo.post;
            contact.birthday = prevContactInfo.birthday;
          } else {
            contact.fullname = defaultContacts.fullname;
            contact.email = defaultContacts.email;
            contact.phone = defaultContacts.phone;
            contact.telegram = defaultContacts.telegram;
            contact.post = defaultContacts.post;
            contact.birthday = defaultContacts.birthday;
          }
        }
      });
      setContacts(contacts);
      return;
    }

    setName(data.name);
    setTypeClient(data.type);
  };

  const saveMainInfo = () => {
    const sentData = { name, type: { id: typeClient.id } };

    check()
      .then(() => {
        patchClient(id, sentData)
          .then(() => {
            toast.setToastInfo('Данные сохранены');
            toast.setShowSuccess(true);
            fetchClientInfo('mainInfo');
          })
          .catch(() => {
            toast.setToastInfo('Выберите тип клиента');
            toast.setShowError(true);
          });
      })
      .catch((error) => logout(error));
  };

  const removeContact = (id) => {
    check()
      .then(() => {
        let flag = false;
        data.contacts.forEach((contact) => {
          if (contact.id === id) flag = true;
        });

        if (flag) {
          deleteClientContacts(id).then(() => {
            fetchClientInfo('contacts');
            deleteContact(id);
          });
        } else {
          deleteContact(id);
        }
      })
      .catch((error) => logout(error));
  };

  const saveContacts = (contactInfo) => {
    check()
      .then(() => {
        let flag = false;
        data.contacts.forEach((contact) => {
          if (contact.id === contactInfo.id) {
            flag = true;
          }
        });

        if (flag) {
          const sentData = {
            ...contactInfo,
            birthday: contactInfo.birthday ? getDate(contactInfo.birthday) : '',
          };
          patchClientContact(contactInfo.id, sentData)
            .then(() => {
              toast.setToastInfo('Данные сохранены');
              toast.setShowSuccess(true);
              fetchClientInfo('contacts');
            })
            .catch((error) => {
              if (error.response.data.email) {
                toast.setToastInfo('Введите верный email');
              } else if (error.response.data.phone) {
                toast.setToastInfo('Значение не должно содержать больше 20 символов');
              }
              toast.setShowError(true);
              return false;
            });
        } else {
          postClientContact({
            client: { id },
            fullname: contactInfo.fullname,
            email: contactInfo.email,
            phone: contactInfo.phone,
            telegram: contactInfo.telegram,
            post: contactInfo.post,
            birthday: contactInfo.birthday ? getDate(contactInfo.birthday) : '',
          })
            .then((response) => {
              toast.setToastInfo('Данные сохранены');
              toast.setShowSuccess(true);
              setContacts([
                ...contacts.filter((contact) => contact.id !== contactInfo.id),
                response,
              ]);
              fetchClientInfo('contacts');
            })
            .catch((error) => {
              if (error.response.data.email) {
                toast.setToastInfo('Введите верный email');
              } else if (error.response.data.phone) {
                toast.setToastInfo('Значение не должно содержать больше 20 символов');
              }
              toast.setShowError(true);
            });
        }
      })
      .catch((error) => logout(error));
  };

  const applyFiltersHandler = (event) => {
    event.preventDefault();

    projects.setProjectsFilter({
      ...filtersState,
      manager: selectedManagerList,
      responsible: selectedResponsibleList,
    });

    projects.setProjectsPage(1);

    fetchClientProjects(
      projects.projectsPage,
      projects.projectsLimit,
      controller.signal,
      projects.projectsFilter,
      'update',
    );
  };

  const deleteProjectHandler = () => {
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить клиента?',
      click: () => {
        check()
          .then(() => {
            deleteClient(id).then((res) => {
              navigate(-1);
            });
          })
          .catch((error) => logout(error));
      },
    });
    modal.setModalConfirmVisible(true);
  };

  let tableName = 'projects_alternative';

  if (user.department === 4 || user.department === 5 || user.department === 6) {
    tableName = 'projects_alternative_2';
  }

  const columns = [
    { id: 'title', label: 'Проект' },
    { id: 'role', label: 'Роль' },
    { id: 'period', label: 'Период реализации' },
    { id: 'payment_date', label: 'Дата оплаты' },
    { id: 'sum', label: 'Сумма оплаты' },
    { id: 'paid_sum', label: 'Оплачено' },
    { id: 'credit', label: 'Остаток' },
    { id: 'manager', label: 'Менеджер' },
    { id: 'status', label: 'Статус' },
  ];

  const propsForRegistryTable = {
    marketing: true,
    noDeadline: true,
    tableName,
    columns,
    tableRef: table,
    style: { maxHeight: '40vh', width: 1600 },
    tableStyle: step === 0 ? { display: 'none' } : {},
    data: projects.projects,
  };

  if (isFirstRender.current) {
    if (isClientInfoLoading || isClientProjectsLoading) {
      return <Spinner animation="grow" />;
    }
    isFirstRender.current = false;
  }

  return (
    <>
      <UploadFilesContainer
        progressFiles={progressFiles}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />

      <PageTitle>
        <SuccessBtn
          text="Вернуться назад"
          type="white"
          back
          style={{ padding: '4px 10px 4px 16px', fontSize: 12 }}
          onClick={() => {
            projects.setProjects([], 'update');
            projects.setProjectsPage(1);
            projects.setProjectsFilter(undefined);
            navigate(-1);
            localStorage.removeItem('oneClientPageStep');
          }}
        />
        {name}
      </PageTitle>

      <div className={classes.btn__steps}>
        <StepBtn
          active={step === 0}
          text="Основная информация"
          onClick={() => setStep(0)}
        />
        <StepBtn
          active={step === 1}
          text="Проекты"
          onClick={() => setStep(1)}
        />
      </div>

      {step === 0 && (
        <>
          <Section title="Общая информация">
            <div className={classes.wrapper}>
              <CLientInfo
                name={name}
                setName={setName}
                typeClient={typeClient}
                setTypeClient={setTypeClient}
                typeClientList={CLIENT_TYPES.LIST}
                files={files}
                addFileHandler={addFileHandler}
                deleteFileHandler={deleteFileHandler}
                cancel={cancel}
                save={saveMainInfo}
              />
            </div>
          </Section>

          {/* <LegalEntityInfo
            legalEntities={legalEntities}
            setLegalEntities={setLegalEntities}
            updateLegalEntities={updateLegalEntities}
            removeLegalEntity={removeLegalEntity}
            addLegalEntities={addLegalEntities}
            cancel={cancel}
            save={saveLegalEntities}
            contentClassName={classes.contacts__content}
          /> */}

          <ContactInfo
            contacts={contacts}
            updateContacts={updateContacts}
            removeContact={removeContact}
            addContact={addContact}
            cancel={cancel}
            save={saveContacts}
            contentClassName={classes.contacts__content}
          />

          {/* {(user.user.role.id === 0 || user.user.role.id === 1) && (
          <DeleteRow
            title="Удалить клиента"
            deleteProjectHandler={deleteProjectHandler}
          />
          )} */}
        </>
      )}

      {step === 1 && (
        <div className={classes.container}>
          <ProjectsClientInfo projectsStats={projectsStats} />

          <ProjectsFilterPanel
            values={filtersState}
            onUpdateField={(payload) => {
              updateFiltersState({
                ...filtersState,
                ...payload,
              });
            }}
            onApplyFilters={applyFiltersHandler}
            staffList={staffList}
            secondStaffList={secondStaffList}
            manager={manager}
            changeManager={changeManager}
            selectedManagerList={selectedManagerList}
            addManager={addManager}
            deleteManager={deleteManager}
            responsible={responsible}
            changeResponsible={changeResponsible}
            selectedResponsibleList={selectedResponsibleList}
            addResponsible={addResponsible}
            deleteResponsible={deleteResponsible}
            department={user.department}
          />
        </div>
      )}

      <RegistryTable {...propsForRegistryTable} />

      {step === 1 && (
      <div className={classes.oneTableRowList}>
        {[
          { id: 1, labelText: 'ИТОГО', value: projects.projectsTotalCount },
          { id: 2, labelText: 'ОБЩАЯ ОПЛАЧЕННАЯ СУММА', value: numberToSum(projects.projectsTotalPaidSum, true) },
          { id: 3, labelText: 'ОБЩАЯ ОСТАТОЧНАЯ СУММА', value: numberToSum(projects.projectsTotalCredit, true) },
        ].map((item) => (
          <OneTableRow
            key={item.id}
            rowName="oneClientPage"
            columns={[
              { id: 'text', label: item.labelText },
              { id: 'sum', label: item.value },
            ]}
          />
        ))}
      </div>
      )}

      <ModalConfirm
        show={modal.modalConfirmVisible}
        onHide={() => modal.setModalConfirmVisible(false)}
        props={modal.modalConfirmProps}
      />
    </>
  );
});

export default OneClientPage;
