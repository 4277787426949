import React from 'react';
import { Popover } from '@mui/material';

import SuccessBtn from '../Btns/SuccessBtn';

import classes from './DeleteRow.module.css';

function DeleteRow(props) {
  const {
    title, deleteProjectHandler, tableStyle, disabled,
  } = props;

  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ

  return (
    <div className={classes.delete__container} style={tableStyle}>
      <h6 className={classes.title}>{title}</h6>

      {disabled && (
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: -20,
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableAutoFocus
        disableEnforceFocus
      >
        <div className={classes.popoverText}>Заявка оплачена, удаление невозможно</div>

      </Popover>
      )}

      <SuccessBtn
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        disabled={disabled}
        text="Удалить"
        onClick={deleteProjectHandler}
        type="white"
        style={{ padding: '2px 22px', fontSize: 11 }}
      />
    </div>
  );
}

export default DeleteRow;
