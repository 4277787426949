import React, { memo, useContext } from 'react';
import { Context } from 'index';

import {
  STATE_STATUSES,
  STATUS_FINANCE,
  STATUS_LAWYER,
  STATUS_LAWYER_COSTS,
  STATUS_PROJECT_ACCOUNTING,
} from 'utils/consts';

import {
  STATUS_COLORS,
  STATUS_COLORS_ACTIVE,
  STATUS_COLORS_FINANCE,
  STATUS_COLORS_FINANCE_ACTIVE,
  STATUS_COLORS_LAWYERS,
  STATUS_COLORS_LAWYERS_ACTIVE,
  STATUS_COLORS_MARKETING,
  STATUS_COLORS_MARKETING_ACTIVE,
  STATUS_COLORS_PROJECT_ACCOUNTING,
  STATUS_COLORS_PROJECT_ACCOUNTING_ACTIVE,
} from '../consts/consts';

import classes from './StatusBtn.module.css';

function StatusBtn(props) {
  const {
    isActive,
    onClick,
    status,
    style,
    className,
    marketing,
    lawyer,
    financeCost,
    transferProjects,
    transferProjectsList,
    ProjectAccounting,
  } = props;

  const {
    user,
  } = useContext(Context);

  let selectedClassName = '';
  let activeStyle = '';
  let text = '';

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    } else {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  switch (true) {
    case marketing:
      selectedClassName = STATUS_COLORS_MARKETING[status];
      activeStyle = STATUS_COLORS_MARKETING_ACTIVE[status];
      text = STATE_STATUSES[status];
      break;
    case lawyer:
      selectedClassName = STATUS_COLORS_LAWYERS[status];
      activeStyle = STATUS_COLORS_LAWYERS_ACTIVE[status];
      text = STATUS_LAWYER[status];
      break;
      // ОТОБРАЖЕНИЕ ДЛЯ ОСНОВНЫХ ПОЛЬЗОВАТЕЛЕЙ И ЮРИСТОВ
    case (financeCost && (user.department !== 2)):
      selectedClassName = STATUS_COLORS[status];
      activeStyle = STATUS_COLORS_ACTIVE[status];
      text = STATUS_LAWYER_COSTS[status];
      break;
      // ОТОБРАЖЕНИЕ ДЛЯ ФИНАНСОВ
    case financeCost && (user.department === 2):
      selectedClassName = STATUS_COLORS_FINANCE[status];
      activeStyle = STATUS_COLORS_FINANCE_ACTIVE[status];
      text = STATUS_FINANCE[status];
      break;
    case ProjectAccounting:
      selectedClassName = STATUS_COLORS_PROJECT_ACCOUNTING[status];
      activeStyle = STATUS_COLORS_PROJECT_ACCOUNTING_ACTIVE[status];
      text = STATUS_PROJECT_ACCOUNTING[status];
      break;
    default:
      selectedClassName = '';
      activeStyle = '';
      text = '';
  }

  const classList = [
    transferProjects ? classes.statusBtnForTransferProjects : classes.statusBtn,
    transferProjectsList ? classes.statusBtnFortransferProjectsList : classes.statusBtn,
    selectedClassName,
    className,
    isActive && activeStyle,
  ].join(' ');

  return (
    status !== undefined ? (
      <button type="button" className={classList} onClick={handleClick} style={style}>
        {text}
      </button>
    ) : (
      <button type="button" className={classes.noStatus} style={style} disabled>
        Статус отсутствует
      </button>
    )
  );
}

export default memo(StatusBtn);
