export const defaultLegalEntities = {
  id: null,
  legal_name: '',
  type: { id: null, name: '' },
  inn: '',
  ogrn: '',
  ogrnip: '',
  kpp: '',
  address: '',
  tax: '',
  bank_details: [{
    id: Date.now(),
    rc: '',
    bank: '',
    bik: '',
    kc: '',
  }],
};
