// @ts-nocheck
import React from 'react';

import LawyerSection from '../Section/LawyerSection';

import OneLegalEntityInfo from './components/OneLegalEntityInfo';

const LegalEntityInfo = React.memo((props) => {
  const {
    noDelete,
    noClose,
    legalEntities,
    setLegalEntities,
    updateLegalEntities,
    removeLegalEntity,
    addLegalEntities,
    className,
    contentClassName,
    sectionClassName,
    titleClassName,
    cancel,
    save,
    noAddLegalEntity,
  } = props;

  return (
    <LawyerSection
      title="Реквизиты"
      sectionClassName={sectionClassName}
      className={contentClassName}
      titleClassName={titleClassName}
      noClose={noClose}
      defaultActive
    >
      {legalEntities?.map((element, index) => (
        <OneLegalEntityInfo
          key={element.id}
          element={element}
          legalEntities={legalEntities}
          setLegalEntities={setLegalEntities}
          updateLegalEntities={updateLegalEntities}
          removeLegalEntity={removeLegalEntity}
          addLegalEntities={addLegalEntities}
          cancel={cancel}
          save={save}
          noDelete={noDelete}
          noClose={noClose}
          className={className}
          index={index}
          length={legalEntities.length}
          noAddLegalEntity={noAddLegalEntity}
        />
      ))}
    </LawyerSection>
  );
});

export default LegalEntityInfo;
