import React from 'react';

import MyDateInput from '../MyDateInput/MyDateInput';

import classes from './DoubleDate.module.css';

function SecondDoubleDate(props) {
  const {
    value1, value2, setValue1, setValue2, style,
  } = props;

  return (
    <>
      <div>с</div>
      <MyDateInput
        value={value1}
        onChange={setValue1}
        className={classes.date2}
        maxDate={value2 && new Date(value2)}
      />
      <div>по</div>
      <MyDateInput
        value={value2}
        onChange={setValue2}
        className={classes.date2}
        minDate={value1 && new Date(value1)}
      />
    </>
  );
}

export default SecondDoubleDate;
