import { useState } from 'react';

import { check } from 'API/UserAPI';

import { generateUniqueID } from 'utils/generateUniqueID';

export const useProgress = (
  setIsVisible,
  fetchRequest,
  id,
  logout,
  requestToServer,
) => {
  const [state, setState] = useState({ files: [] });
  const [secondState, setSecondState] = useState({ files: [] });

  const maxFileSize = 209715200;

  const func = (file, idForModal) => {
    const formData = new FormData();
    formData.append(file.name, file);

    // Если файл весит больше maxFileSize мб, меняет статус isAllow на false
    if (file.size > maxFileSize) {
      const uploadFile = {
        name: file.name,
        progress: 0,
        id: generateUniqueID(),
        isAllow: false,
        size: file.size,
      };

      setState({ files: [...state.files, uploadFile] });
      setSecondState({ files: [...state.files, uploadFile] });
      setIsVisible(true);
    } else {
      // Если файл весит меньше 10 мб, меняет статус isAllow на true
      const uploadFile = {
        name: file.name,
        progress: 0,
        id: generateUniqueID(),
        isAllow: true,
        size: file.size,
      };

      state.files = [...state.files, uploadFile];
      setIsVisible(true);

      const options = {
        // Используем внутренние методы axios для получения процесса загрузки файлов
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;

          const percent = Math.floor((loaded * 100) / total);
          if (percent) {
            uploadFile.progress = Math.round((loaded * 100) / total);
            uploadFile.size = file.size;

            const temp = {
              files: state.files.map((f) => (f.id === uploadFile.id
                ? {
                  ...uploadFile,
                }
                : f)),
            };

            setSecondState(temp);
          }
        },
      };

      check()
        .then(() => {
          requestToServer(idForModal ? idForModal : id, formData, options).then(() => {
            if (fetchRequest) {
              fetchRequest('files');
            }
          });
        })
        .catch((error) => logout(error));
    }
  };

  return [secondState, func];
};
