// @ts-nocheck
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
// Utils
import { Context } from '../../../index';

import {
  changeBloggerPhoto,
  createTaskBlogger,
  deleteBlogger,
  deleteBloggerContact,
  deleteBloggerFile,
  deleteTaskBlogger,
  getBloggerProjects,
  getBloggerProjectsStats,
  getOneBlogger,
  getOneBloggerContacts,
  getOneBloggerSocials,
  getOneBloggerTasks,
  patchBloggerContact,
  patchOneBlogger,
  patchTaskBlogger,
  postBloggerContact,
  postBloggerFiles,
  postBloggerSocials,
} from 'API/BloggerAPI';
import { check } from 'API/UserAPI';
// API
import {
  deleteBloggerLegalEntity,
  patchBloggerLegalEntity,
  postBloggerLegalEntity,
} from '../../../API/LegalEntityAPI';
import { fetchStaff } from '../../../API/ManagerAPI/AutocompleteAPI';

import StepBtn from 'components/UI/Btns/StepBtn';
// Components
import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import MainInfo from 'components/UI/Modals/ModalCreateBlogger/components/MainInfo';
import PageTitle from 'components/UI/PageTitle/PageTitle';
import LawyerSection from 'components/UI/Section/LawyerSection';
import Section from 'components/UI/Section/Section';
import Table from 'components/UI/Table/Table';
import UploadFilesContainer from 'components/UI/UploadFilesContainer/UploadFilesContainer';

import ContactInfo from '../../UI/ContactInfo/ContactInfo';
import ModalConfirm from '../../UI/Modals/ModalConfirm/ModalConfirm';
import AdditionalInfo from '../../UI/Modals/ModalCreateBlogger/components/AdditionalInfo';
import OneTableRow from '../../UI/OneTableRow/OneTableRow';
import ProjectsFilterPanel from '../../UI/ProjectsFilterPanel/ProjectsFilterPanel';
import RegistryTable from '../../UI/RegistryTable/RegistryTable';
import SocialNetworks from '../../UI/SocialNetworks/SocialNetworks';

// Hooks
import { useFetching } from 'hooks/useFetching';
import { useLogout } from 'hooks/useLogout';
import { useProgress } from 'hooks/useProgress';
import { useStateNew2 } from 'hooks/useStateNew2';
import { useMultiple } from '../../../hooks/useMultiple';

import { defaultLegalEntitiesWithId } from 'utils/consts';
import { getDate, getFormattedDate } from 'utils/getDate';
import { numberToSum } from '../../../utils/getSums';

import ProjectsClientInfo from '../OneClientPage/components/ProjectsClientInfo';
import Avatar from '../SettingsPage/components/Avatar';

import classes from './OneBloggerPage.module.css';

const defaultCard = {
  id: Date.now(),
  bank: '',
  number: '',
};

const defaultContacts = {
  id: Date.now(),
  email: '',
  password: '',
  phone: '',
  telegram: '',
  address: '',
  responsible: [],
  note: '',
};

const defaultSocials = {
  id: Date.now(),
  type: { id: null, name: '' },
  blogger: { id: null, name: '' },
  nickname: '',
  subscribers: null,
  link: '',
  update_date: '',
};

const defaultAdditional = {
  category: '',
  clothing_size: '',
  params: '',
  children: '',
  animals: '',
  exclusive: '',
  prohibited: '',
  instagram_links: [{ id: Date.now(), link: '' }],
  vk_links: [{ id: Date.now(), link: '' }],
  youtube_links: [{ id: Date.now(), link: '' }],
  tiktok_links: [{ id: Date.now(), link: '' }],
  twitch_links: [{ id: Date.now(), link: '' }],
  telegram_links: [{ id: Date.now(), link: '' }],
};

const OneBloggerPage = observer(() => {
  const {
    modal, projects, user, toast,
  } = useContext(Context);
  const { id } = useParams();
  const navigate = useNavigate();
  const isFirstRender = useRef(true);
  const [logout] = useLogout();

  const table = useRef();
  const [fetching, setFetching] = useState(true);
  const controller = new AbortController();

  const [isVisible, setIsVisible] = useState(false);

  const [step, setStep] = useState(0);

  const [data, setData] = useState({});
  const [contactsData, setContactsData] = useState([]);
  const [socialsData, setSocialsData] = useState([]);

  const [mainInfo, setMainInfo] = useState({
    type: { id: 0, label: 'Блогер' },
    fullname: '',
    name: '',
  });

  const [card, setCard, changeCard, addCard, deleteCard] = useStateNew2([
    defaultCard,
  ]);

  const [files, setFiles] = useState([]);

  const [contacts, setContacts, updateContacts, addContact, deleteContact] = useStateNew2([defaultContacts]);

  const [socials, setSocials, updateSocials, addSocials, deleteSocials] = useStateNew2([defaultSocials]);

  const [additionalInfo, setAdditionalInfo] = useState([defaultAdditional]);

  // Step 2
  const [filtersState, updateFiltersState] = useState({
    name: '',
    date_after: '',
    date_before: '',
    payment_dates_after: '',
    payment_dates_before: '',
    status: [],
  });

  const [
    manager,
    changeManager,
    selectedManagerList,
    addManager,
    deleteManager,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    responsible,
    changeResponsible,
    selectedResponsibleList,
    addResponsible,
    deleteResponsible,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [staffList, setStaffList] = useState([]);
  const [secondStaffList, setSecondStaffList] = useState([]);
  const [allStaff, setAllStaff] = useState([]);

  const [projectsStats, setProjectsStats] = useState();

  const [fetchBlogger, isBloggerLoading] = useFetching(async (typeUpdate) => {
    const serverData = await getOneBlogger(id);
    const contactsServerData = await getOneBloggerContacts(id);
    const socialsServerData = await getOneBloggerSocials(id);

    if (typeUpdate === 'photo') {
      setData(serverData);
      return;
    }

    if (typeUpdate === 'cards') {
      setData(serverData);
      setCard([...defaultCard, ...serverData.bank_cards]);
      return;
    }

    if (typeUpdate === 'mainInfo') {
      setData(serverData);
      setMainInfo({
        type: serverData.type,
        fullname: serverData.fullname,
        name: serverData.name,
      });
      setCard([...defaultCard, ...serverData.bank_cards]);
      return;
    }

    if (typeUpdate === 'files') {
      setFiles(serverData.files);
      return;
    }

    if (typeUpdate === 'contacts') {
      setContactsData(contactsServerData);
      return;
    }

    if (typeUpdate === 'socials') {
      setSocialsData(socialsServerData);
      return;
    }

    if (typeUpdate === 'additionalInfo') {
      setData(serverData);
      setAdditionalInfo({
        category: serverData.category,
        clothing_size: serverData.clothing_size,
        params: serverData.params,
        children: serverData.children,
        animals: serverData.animals,
        exclusive: serverData.exclusive,
        prohibited: serverData.prohibited,
      });
      return;
    }

    setData(serverData);
    setContactsData(JSON.parse(JSON.stringify(contactsServerData)));
    setSocialsData(JSON.parse(JSON.stringify(socialsServerData)));

    if (contactsServerData.length > 0) {
      setContacts(JSON.parse(JSON.stringify(contactsServerData)));
    }

    setMainInfo({
      type: serverData.type,
      fullname: serverData.fullname,
      name: serverData.name,
    });

    setCard([...card, ...serverData.bank_cards]);

    setFiles(serverData.files);

    setSocials(socialsServerData);

    setAdditionalInfo({
      category: serverData.category,
      clothing_size: serverData.clothing_size,
      params: serverData.params,
      children: serverData.children,
      animals: serverData.animals,
      exclusive: serverData.exclusive,
      prohibited: serverData.prohibited,
    });
  });

  const [fetchClientProjects, isClientProjectsLoading] = useFetching(
    async (page, limit, signal, filter, update) => {
      await check().catch((error) => logout(error));

      const projectsData = await getBloggerProjects(
        id,
        page,
        limit,
        signal,
        filter,
      );

      projects.setProjectsTotalCount(projectsData.count);
      projects.setProjectsTotalSum(projectsData.total_sum);
      projects.setProjectsTotalCredit(projectsData.total_credit);
      projects.setProjectsTotalPaidSum(projectsData.total_paid_sum);

      if (update) {
        projects.setProjectsPage(2);
        projects.setProjects(projectsData.results, 'update');
      } else {
        projects.setProjectsPage(projects.projectsPage + 1);
        projects.setProjects(projectsData.results);
      }

      setFetching(false);
    },
  );

  const totalPages = Math.ceil(
    projects.projectsTotalCount / projects.projectsLimit,
  );

  /* Запрос на сервер при скролле до конца таблицы */

  useEffect(() => {
    if (fetching) {
      if (projects.projectsFilter) {
        fetchClientProjects(
          projects.projectsPage,
          projects.projectsLimit,
          controller.signal,
          projects.projectsFilter,
        );
      } else {
        fetchClientProjects(
          projects.projectsPage,
          projects.projectsLimit,
          controller.signal,
        );
      }
    }
    return () => {
      controller.abort();
    };
  }, [fetching]);

  /* Когда доскролили до нижней части таблицы (первое условие)
    Проверяем текущую страницу с общим количеством страниц(второе условие)
    Далее делаем запрос
  */

  const scrollHandler = (event) => {
    if (
      event.target.scrollHeight
        - (event.target.scrollTop + event.target.clientHeight)
        < 600
      && projects.projectsPage <= totalPages
    ) {
      setFetching(true);
    }
  };

  // Наблюдение за скроллом
  useEffect(() => {
    if (isClientProjectsLoading) return;

    const element = table?.current;
    element?.addEventListener('scroll', scrollHandler);
    return function () {
      element?.removeEventListener('scroll', scrollHandler);
    };
  }, [isClientProjectsLoading]);

  const updatePhoto = (event) => {
    check()
      .then(() => {
        const formData = new FormData();
        formData.append(event.target.files[0].name, event.target.files[0]);

        changeBloggerPhoto(id, formData).then(() => {
          fetchBlogger('photo');
        });
      })
      .catch((error) => logout(error));
  };

  const [tasks, setTasks] = useState([]);

  const [fetchTasks, isTasksLoading] = useFetching(async () => {
    const data = await getOneBloggerTasks(id);
    setTasks(data);
  });

  useEffect(() => {
    check().then(() => {
      fetchTasks();
      fetchBlogger();
      fetchStaff({ job: 0 }).then((data) => setStaffList(data));
      fetchStaff({ job: [4, 5, 6] }).then((data) => setSecondStaffList(data));
      fetchStaff({ job: [0, 4, 5, 6], is_active: true }).then((data) => setAllStaff(data));

      getBloggerProjectsStats(id).then((data) => setProjectsStats(data));
    });
  }, []);

  const [progressFiles, addFileFunc] = useProgress(
    setIsVisible,
    fetchBlogger,
    id,
    logout,
    postBloggerFiles,
  );

  const addFileHandler = (event) => {
    const files = [...event.target.files];
    files.forEach((file) => {
      addFileFunc(file);
    });
  };

  const deleteFileHandler = (id) => {
    check()
      .then(() => {
        deleteBloggerFile(id).then(() => {
          fetchBlogger('files');
        });
      })
      .catch((error) => logout(error));
  };

  const cancel = (cancelType, info) => {
    if (cancelType === 'contacts') {
      let prevContactInfo = {};

      contactsData.forEach((contact) => {
        if (info.id === contact.id) prevContactInfo = contact;
      });

      contacts.forEach((contact) => {
        if (contact.id === info.id) {
          if (prevContactInfo.id) {
            contact.address = prevContactInfo.address;
            contact.phone = prevContactInfo.phone;
            contact.telegram = prevContactInfo.telegram;
            contact.email = prevContactInfo.email;
            contact.password = prevContactInfo.password;
            contact.responsible = prevContactInfo.responsible;
            contact.note = prevContactInfo.note;
          } else {
            contact.address = defaultContacts.fullname;
            contact.phone = defaultContacts.phone;
            contact.telegram = defaultContacts.telegram;
            contact.email = defaultContacts.email;
            contact.password = defaultContacts.password;
            contact.responsible = defaultContacts.responsible;
            contact.note = defaultContacts.note;
          }
        }
      });
      setContacts([...contacts]);
      return;
    }

    if (cancelType === 'socials') {
      setSocials(socialsData);
      return;
    }

    if (cancelType === 'additionalInfo') {
      setAdditionalInfo({
        category: data.category,
        clothing_size: data.clothing_size,
        params: data.params,
        children: data.children,
        animals: data.animals,
        exclusive: data.exclusive,
        prohibited: data.prohibited,
      });
      return;
    }

    setMainInfo({
      type: data.type,
      fullname: data.fullname,
      name: data.name,
    });
    setCard([defaultCard, ...data.bank_cards]);
  };

  const removeCard = (cardId) => {
    deleteCard(cardId);

    const newArray = card.slice(1).filter((element) => element.id !== cardId);
    check().then(() => {
      patchOneBlogger(id, { bank_cards: newArray }).then(() => fetchBlogger('cards'));
    });
  };

  const saveMainInfo = () => {
    check()
      .then(() => {
        const sentData = {
          type: { id: mainInfo.type.id },
          fullname: mainInfo.fullname,
          name: mainInfo.name,
          bank_cards: card.slice(1),
        };

        patchOneBlogger(id, sentData)
          .then(() => {
            toast.setToastInfo('Данные сохранены');
            toast.setShowSuccess(true);
            fetchBlogger('mainInfo');
          })
          .catch((error) => {
            toast.setToastInfo('Заполните поля со звездочкой');
            toast.setShowError(true);
          });
      })
      .catch((error) => logout(error));
  };

  const removeContact = (id) => {
    check()
      .then(() => {
        let flag = false;
        contactsData.forEach((contact) => {
          if (contact.id === id) flag = true;
        });

        if (flag) {
          deleteBloggerContact(id).then(() => {
            fetchBlogger('contacts');
            deleteContact(id);
          });
        } else {
          deleteContact(id);
        }
      })
      .catch((error) => logout(error));
  };

  const saveContacts = (contactInfo) => {
    check()
      .then(() => {
        let flag = false;
        contactsData.forEach((contact) => {
          if (contact.id === contactInfo.id) {
            flag = true;
          }
        });

        const responsibleArray = [];

        contactInfo.responsible.forEach((element) => {
          if (element.id) {
            responsibleArray.push({ id: element.id });
          }
        });

        if (flag) {
          const sentData = {
            ...contactInfo,
            blogger: { id },
            responsible: responsibleArray,
          };
          patchBloggerContact(contactInfo.id, sentData)
            .then(() => {
              toast.setToastInfo('Данные сохранены');
              toast.setShowSuccess(true);
              fetchBlogger('contacts');
            })
            .catch((error) => {
              if (error.response.data.email) {
                toast.setToastInfo('Введите верный email');
                toast.setShowError(true);
                return false;
              }
            });
        } else {
          postBloggerContact({
            blogger: { id },
            address: contactInfo.address,
            phone: contactInfo.phone,
            telegram: contactInfo.telegram,
            email: contactInfo.email,
            password: contactInfo.password,
            responsible: responsibleArray,
            note: contactInfo.note,
          })
            .then((response) => {
              toast.setToastInfo('Данные сохранены');
              toast.setShowSuccess(true);
              fetchBlogger('contacts');
              setContacts([
                ...contacts.filter((contact) => contact.id !== contactInfo.id),
                response,
              ]);
            })
            .catch((error) => {
              if (error.response.data.email) {
                toast.setToastInfo('Введите верный email');
                toast.setShowError(true);
              }
            });
        }
      })
      .catch((error) => logout(error));
  };

  const saveSocialNetworksHandler = () => {
    socials.forEach((element) => {
      element.subscribers = Number(element.subscribers);
      element.blogger = { id: element.blogger.id };
      element.type = { id: element.type.id };
      element.update_date = getDate(
        element.update_date ? element.update_date : '',
      );
    });

    // Избаваление от ссылочной зависимости
    const resultsWithoutId = JSON.parse(
      JSON.stringify(
        socials.filter(
          ({ id: id1 }) => !socialsData.some(({ id: id2 }) => id2 === id1),
        ),
      ),
    );

    const resultsWithId = socials.filter(({ id: id1 }) => socialsData.some(({ id: id2 }) => id2 === id1));

    resultsWithoutId.forEach((element) => delete element.id);

    check()
      .then(() => {
        postBloggerSocials(id, [...resultsWithId, ...resultsWithoutId]).then(
          () => {
            fetchBlogger('socials');
          },
        );
      })
      .catch((error) => logout(error));
  };

  const saveAdditionalInfo = () => {
    const sentData = {
      category: additionalInfo.category,
      clothing_size: additionalInfo.clothing_size,
      params: additionalInfo.params,
      children: additionalInfo.children,
      animals: additionalInfo.animals,
      exclusive: additionalInfo.exclusive,
      prohibited: additionalInfo.prohibited,
    };

    check()
      .then(() => {
        patchOneBlogger(id, sentData).then(() => {
          toast.setToastInfo('Данные сохранены');
          toast.setShowSuccess(true);
          fetchBlogger('additionalInfo');
        });
      })
      .catch((error) => logout(error));
  };

  const applyFiltersHandler = (event) => {
    event.preventDefault();

    projects.setProjectsFilter({
      ...filtersState,
      manager: selectedManagerList,
      responsible: selectedResponsibleList,
    });

    projects.setProjectsPage(1);

    fetchClientProjects(
      projects.projectsPage,
      projects.projectsLimit,
      controller.signal,
      projects.projectsFilter,
      'update',
    );
  };

  const deleteProjectHandler = () => {
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить блогера?',
      click: () => {
        check()
          .then(() => {
            deleteBlogger(id).then((res) => {
              navigate(-1);
            });
          })
          .catch((error) => logout(error));
      },
    });
    modal.setModalConfirmVisible(true);
  };

  const tableName = 'projects_alternative';

  const columns = [
    { id: 'title', label: 'Проект' },
    { id: 'role', label: 'Роль' },
    { id: 'period', label: 'Период реализации' },
    { id: 'payment_date', label: 'Дата оплаты' },
    { id: 'sum', label: 'Сумма оплаты' },
    { id: 'paid_sum', label: 'Оплачено' },
    { id: 'credit', label: 'Остаток' },
    { id: 'manager', label: 'Менеджер' },
    { id: 'status', label: 'Статус' },
  ];

  const propsForRegistryTable = {
    marketing: true,
    noDeadline: true,
    tableName,
    columns,
    tableRef: table,
    style: { maxHeight: '40vh' },
    tableStyle: step === 0 || step === 2 ? { display: 'none' } : {},
    data: projects.projects,
  };

  const addTask = () => {
    check()
      .then(() => {
        const data = {
          name: '',
          deadline: '',
          responsible: [],
          blogger: { id: Number(id) },
          status: { id: 0 },
        };
        createTaskBlogger(data).then(() => {
          fetchTasks();
        });
      })
      .catch((error) => logout(error));
  };

  const deleteTask = (id) => {
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить задачу?',
      click: () => {
        setTasks(tasks.filter((element) => element.id !== id));

        check()
          .then(() => {
            deleteTaskBlogger(id);
          })
          .catch((error) => logout(error));
      },
    });
    modal.setModalConfirmVisible(true);
  };

  const taskDebounce = useCallback(
    debounce((id, data) => {
      check()
        .then(() => {
          patchTaskBlogger(id, data).then(() => {});
        })
        .catch((error) => logout(error));
    }, 350),
    [],
  );

  const updateTask = (id, fieldName, value) => {
    setTasks(
      tasks.map((element) => (element.id === id
        ? {
          ...element,
          [fieldName]: value,
        }
        : element)),
    );

    if (fieldName === 'status') {
      check()
        .then(() => {
          patchTaskBlogger(id, { status: { id: value } }).then(() => {
            fetchTasks();
          });
        })
        .catch((error) => logout(error));
    } else if (fieldName === 'deadline') {
      taskDebounce(id, { [fieldName]: getFormattedDate(value) });
    } else {
      taskDebounce(id, { [fieldName]: value });
    }
  };

  const addSubTask = (e, id) => {
    const newTask = {
      name: '',
      deadline: '',
      responsible: [],
      project: { id: Number(id) },
      status: { id: 0 },
    };
    const currentTasks = tasks.find((el) => el.id === id);
    currentTasks?.subtasks.unshift(newTask);

    check()
      .then(() => {
        patchTaskBlogger(id, currentTasks).then(() => {
          fetchTasks();
        });
      })
      .catch((error) => logout(error));
  };

  const updateSubTask = (id, fieldName, value) => {
    const currentTask = tasks.find((task) => task.subtasks.find((subtask) => subtask.id === id));
    setTasks(
      tasks.map((task) => (task.id === currentTask.id
        ? {
          ...task,
          subtasks: [...task.subtasks].map((subtask) => (subtask.id === id
            ? {
              ...subtask,
              [fieldName]: value,
            }
            : subtask)),
        }
        : task)),
    );

    if (fieldName === 'deadline') {
      const editedSubtasks = currentTask.subtasks.map((subtask) => (subtask.id === id
        ? {
          ...subtask,
          deadline: getFormattedDate(value),
        }
        : subtask));
      taskDebounce(currentTask.id, { subtasks: editedSubtasks });
    } else if (fieldName === 'status') {
      const editedSubtasks = currentTask.subtasks.map((subtask) => (subtask.id === id
        ? {
          ...subtask,
          status: { id: value },
        }
        : subtask));

      check()
        .then(() => {
          patchTaskBlogger(currentTask.id, { subtasks: editedSubtasks }).then(
            () => {
              fetchTasks();
            },
          );
        })
        .catch((error) => logout(error));
    } else {
      const editedSubtasks = currentTask.subtasks.map((subtask) => (subtask.id === id
        ? {
          ...subtask,
          [fieldName]: value,
        }
        : subtask));
      taskDebounce(currentTask.id, { subtasks: editedSubtasks });
    }
  };

  const deleteSubTask = (parentId, subtaskId) => {
    const currentTask = tasks.find((task) => task.id === parentId);
    const taskWithoutSubtask = {
      ...currentTask,
      subtasks: currentTask.subtasks.filter(
        (subtask) => subtask.id !== subtaskId,
      ),
    };
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить подзадачу?',
      click: () => {
        check()
          .then(() => {
            patchTaskBlogger(parentId, taskWithoutSubtask).then(() => {
              fetchTasks();
            });
          })
          .catch((error) => logout(error));
      },
    });
    modal.setModalConfirmVisible(true);
  };

  if (isFirstRender.current) {
    if (isBloggerLoading) {
      return <Spinner animation="grow" />;
    }
    isFirstRender.current = false;
  }

  return (
    <>
      <UploadFilesContainer
        progressFiles={progressFiles}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />

      <PageTitle>
        <SuccessBtn
          text="Вернуться назад"
          type="white"
          back
          style={{ padding: '4px 10px 4px 16px', fontSize: 12 }}
          onClick={() => {
            projects.setProjects([], 'update');
            projects.setProjectsPage(1);
            projects.setProjectsFilter(undefined);
            navigate(-1);
          }}
        />
        {mainInfo.name}
      </PageTitle>

      <div className={classes.btn__steps}>
        <StepBtn
          active={step === 0}
          text="Основная информация"
          onClick={() => setStep(0)}
        />
        <StepBtn
          active={step === 1}
          text="Проекты"
          onClick={() => setStep(1)}
        />
        {(user.department === 6 || user.department === 3) && (
          <StepBtn
            active={step === 2}
            text="Задачи"
            onClick={() => setStep(2)}
          />
        )}
      </div>

      {step === 2 && (
        <Table
          setTasks
          style={{ overflow: 'visible' }}
          headerStyle={{ marginTop: 35 }}
          headCellStyle={{ padding: ' 20px 15px' }}
          type="projectTasksMarketing"
          updateFunc={updateTask}
          deleteHandler={deleteTask}
          addHandler={addTask}
          updateChildren={updateSubTask}
          deleteChildren={deleteSubTask}
          addChildren={addSubTask}
          noCheckbox
          columns={[
            {
              id: 'name',
              label: 'Задача',
            },
            {
              id: 'responsible',
              label: 'Ответственный',
            },
            {
              id: 'deadline',
              label: 'Дедлайн',
            },
            {
              id: 'status',
              label: 'Статус задачи',
            },
          ]}
          data={tasks}
          responsibleList={allStaff}
          tableName="projectTasks"
          fetchMyTasks={() => fetchTasks()}
        />
      )}

      {step === 0 && (
        <>
          <Section title="Общая информация">
            <div className={classes.wrapper}>
              <Avatar info={data} updatePhoto={updatePhoto} />

              <MainInfo
                state={mainInfo}
                setState={setMainInfo}
                card={card}
                changeCard={changeCard}
                addCard={addCard}
                removeCard={removeCard}
                files={files}
                deleteFileHandler={deleteFileHandler}
                addFileHandler={addFileHandler}
                save={saveMainInfo}
                cancel={cancel}
              />
            </div>
          </Section>

          {/* <LegalEntityInfo
            legalEntities={legalEntities}
            setLegalEntities={setLegalEntities}
            updateLegalEntities={updateLegalEntities}
            removeLegalEntity={removeLegalEntity}
            addLegalEntities={addLegalEntities}
            cancel={cancel}
            save={saveLegalEntities}
            contentClassName={classes.contacts__content}
          /> */}

          <ContactInfo
            contacts={contacts}
            updateContacts={updateContacts}
            removeContact={removeContact}
            addContact={addContact}
            staffList={allStaff}
            blogger
            cancel={cancel}
            save={saveContacts}
            contentClassName={classes.contacts__content}
          />

          <SocialNetworks
            socials={socials}
            updateSocials={updateSocials}
            addSocials={addSocials}
            deleteSocials={deleteSocials}
            save={saveSocialNetworksHandler}
            cancel={cancel}
          />

          {mainInfo.type.id === 3 && (
            <LawyerSection title="Дополнительная информация">
              <div className={classes.contacts__wrapper}>
                <AdditionalInfo
                  state={additionalInfo}
                  setState={setAdditionalInfo}
                  save={saveAdditionalInfo}
                  cancel={cancel}
                />
              </div>
            </LawyerSection>
          )}

          {/* {(user.user.role.id === 0 || user.user.role.id === 1) && (
            <DeleteRow
              title="Удалить блогера"
              deleteProjectHandler={deleteProjectHandler}
            />
          )} */}
        </>
      )}

      {step === 1 && (
        <div className={classes.container}>
          <ProjectsClientInfo projectsStats={projectsStats} />

          <ProjectsFilterPanel
            values={filtersState}
            onUpdateField={(payload) => updateFiltersState({ ...filtersState, ...payload })}
            onApplyFilters={applyFiltersHandler}
            staffList={staffList}
            secondStaffList={secondStaffList}
            manager={manager}
            changeManager={changeManager}
            selectedManagerList={selectedManagerList}
            addManager={addManager}
            deleteManager={deleteManager}
            responsible={responsible}
            changeResponsible={changeResponsible}
            selectedResponsibleList={selectedResponsibleList}
            addResponsible={addResponsible}
            deleteResponsible={deleteResponsible}
            department={user.department}
          />
        </div>
      )}

      <RegistryTable {...propsForRegistryTable} />

      {step === 1 && (
      <div className={classes.oneTableRowList}>
        {[
          { id: 1, labelText: 'ИТОГО', value: projects.projectsTotalCount },
          { id: 2, labelText: 'ОБЩАЯ ОПЛАЧЕННАЯ СУММА', value: numberToSum(projects.projectsTotalPaidSum, true) },
          { id: 3, labelText: 'ОБЩАЯ ОСТАТОЧНАЯ СУММА', value: numberToSum(projects.projectsTotalCredit, true) },
        ].map((item) => (
          <OneTableRow
            key={item.id}
            rowName="oneBloggerPage"
            columns={[
              { id: 'text', label: item.labelText },
              { id: 'sum', label: item.value },
            ]}
          />
        ))}
      </div>
      )}

      <ModalConfirm
        show={modal.modalConfirmVisible}
        onHide={() => modal.setModalConfirmVisible(false)}
        props={modal.modalConfirmProps}
      />
    </>
  );
});

export default OneBloggerPage;
