// @ts-nocheck
import React from 'react';
import { Helmet } from 'react-helmet';

import ProjectAccounting from 'components/Finance/FinanceMain/ProjectAccounting';

function ProjectAccountingPage() {
  return (
    <section className="Mysection__project__accounting">
      <Helmet>
        <title>Учёт проектов | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <ProjectAccounting />
    </section>
  );
}

export default ProjectAccountingPage;
