// @ts-nocheck
import { makeAutoObservable } from 'mobx';

// Стор для хранения данных о пользователе
export default class UserStore {
  constructor() {
    this._isAuth = false;
    this._user = {};
    this._department = 2;

    makeAutoObservable(this);
  }

  setIsAuth(bool) {
    this._isAuth = bool;
  }

  setUser(user) {
    this._user = user;
  }

  setDepartment(id) {
    // if (id) {
    //   return this._department;
    // }
    this._department = id;
  }

  get isAuth() {
    return this._isAuth;
  }

  get user() {
    return this._user;
  }

  get department() {
    return this._department;
  }
}
