import React from 'react';

import { getDate, getTime, reversDate } from 'utils/getDate';

import Files from '../Files/Files';

import classes from './Comment.module.scss';

function Comment(props) {
  const { data } = props;

  const date = reversDate(getDate(data?.created));
  const time = getTime(data?.created);
  const commentHTML = data?.text.replace(/\n/g, '<br>');

  return (
    <div className={classes.comment__container}>
      <div className={classes.comment__container__header}>
        <div className={classes.comment__container__name}>
          {data?.staff?.fullname}
        </div>
        <div className={classes.comment__container__date}>
          {date}&ensp;
          {time}
        </div>
      </div>
      <div className={classes.comment__container__content}>
        <div
          className={classes.comment__container__commentItem}
          dangerouslySetInnerHTML={{ __html: commentHTML }}
        />
        {data?.files?.length > 0 && (
          <div className={classes.comment__container__files}>
            {data?.files?.map((file) => (
              <Files
                style={{ display: 'block', margin: '0 0 5px 5px' }}
                fileNameStyle={{ width: '100px', marginLeft: '10px' }}
                key={file.id}
                file={file}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Comment;
