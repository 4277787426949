export const FIELDS = [
  {
    id: 'id', label: '№ проекта', type: 'number', width: 3,
  },
  {
    id: 'id_status',
    label: '№ проекта',
    type: 'dropdown',
    list: 'project_sent_date_deadline',
    width: 3,
  },
  {
    id: 'project',
    label: 'Название проекта',
    type: 'autocomplete',
    list: 'project',
    multiple: true,
    width: 3,
  },
  {
    id: 'client_id',
    label: 'ЮЛ заказчика',
    type: 'autocompleteLegal',
    multiple: true,
    width: 3,
  },
  {
    id: 'contract_basis',
    label: 'Договор (основание)',
    type: 'text',
    width: 3,
  },
  {
    id: 'total_sum', label: 'Общая сумма', type: 'sum', width: 3,
  },
  {
    id: 'contract_format',
    label: 'Формат договора',
    type: 'dropdown',
    list: 'contract_format',
    multiple: true,
    width: 3,
  },
  {
    id: 'contractor_id',
    label: 'ЮЛ исполнителя',
    type: 'autocompleteLegal',
    multiple: true,
    width: 3,
  },

  {
    id: 'margin_status',
    label: 'Маржа по проекту',
    type: 'dropdown',
    list: 'contract_sent_date_deadline',
    width: 3,
  },

  {
    id: 'director',
    label: 'Руководитель',
    type: 'autocompleteStaff',
    list: 'directors',
    multiple: true,
    width: 3,
  },
  {
    id: 'manager',
    label: 'Менеджер',
    type: 'autocompleteStaff',
    list: 'managers',
    multiple: true,
    width: 3,
  },
  {
    id: 'documents_date',
    label: 'Месяц учета фактического дохода',
    type: 'doubleDate',
    value_id_1: 'documents_date_start',
    value_id_2: 'documents_date_end',
    width: 5,
  },
  {
    id: 'deadline',
    label: 'Месяц учета дохода',
    type: 'doubleDate',
    value_id_1: 'accounting_month_start',
    value_id_2: 'accounting_month_end',
    width: 5,
  },
];
