// @ts-nocheck
import React, {
  useContext, useEffect, useLayoutEffect, useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import FilterBtn from 'components/UI/FilterBtn/FilterBtn';
import StatusBtn from 'components/UI/StatusBtns/StatusBtn/StatusBtn';

import FiltersPanel from '../../UI/FiltersPanel/FiltersPanel';

import { useMultiple } from 'hooks/useMultiple';

import { getNextFiveQuarters } from '../../../utils/getCurrentQuarter';

import { FIELDS } from './consts';

import classes from './ProjectAccounting.module.css';

const FilterProjectAccounting = observer((props) => {
  const {
    fetchProjects,
    projectList,
    allLegalEntity,
    directorList,
    managerList,
    responsibleList,
    controller,
  } = props;

  const { projectsFinance, filters } = useContext(Context);

  const [filtersPanelOpen, setFiltersPanelOpen] = useState(false);

  const [quarterList, setQuarterList] = useState([]);

  const [
    project,
    changeProject,
    selectedProjectList,
    addSelectedProject,
    deleteSelectedProject,
    setSelectedProjectList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    ourLegalEntity,
    changeOurLegalEntity,
    selectedOurLegalEntityList,
    addOurLegalEntity,
    deleteOurLegalEntity,
    setSelectedOurLegalEntityList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    clientLegalEntity,
    changeClientLegalEntity,
    selectedClientLegalEntityList,
    addClientLegalEntity,
    deleteClientLegalEntity,
    setSelectedClientLegalEntityList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    director,
    changeDirector,
    selectedDirectorList,
    addDirector,
    deleteDirector,
    setSelectedDirectorList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    manager,
    changeManager,
    selectedManagerList,
    addManager,
    deleteManager,
    setSelectedManagerList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    responsible,
    changeResponsible,
    selectedResponsibleList,
    addResponsible,
    deleteResponsible,
    setSelectedResponsibleList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    contractFormatGL,,
    selectedContractFormatGLList,
    addContractFormatGL,
    deleteContractFormatGL,
    setSelectedContractFormatGLList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    quarter,,
    selectedQuarterList,
    addSelectedQuarter,
    deleteSelectedQuarter,
    setSelectedQuarterList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  // Состояние фильтра из стора

  useEffect(() => {
    filters.setProjectAccountingProjectFilter(selectedProjectList);
  }, [selectedProjectList]);

  useEffect(() => {
    filters.setProjectAccountingClientLegalEntityFilter(selectedClientLegalEntityList);
  }, [selectedClientLegalEntityList]);

  useEffect(() => {
    filters.setProjectAccountingContractorLegalEntityFilter(selectedOurLegalEntityList);
  }, [selectedOurLegalEntityList]);

  useEffect(() => {
    filters.setProjectAccountingDirectorFilter(selectedDirectorList);
  }, [selectedDirectorList]);

  useEffect(() => {
    filters.setProjectAccountingManagerFilter(selectedManagerList);
  }, [selectedManagerList]);

  useEffect(() => {
    filters.setProjectAccountingResponsibleFilter(selectedResponsibleList);
  }, [selectedResponsibleList]);

  useEffect(() => {
    filters.setProjectAccountingContractTypesFilter(selectedContractFormatGLList);
  }, [selectedContractFormatGLList]);

  useEffect(() => {
    filters.setProjectAccountingQuarterFilter(selectedQuarterList);
  }, [selectedQuarterList]);

  useLayoutEffect(() => {
    setSelectedProjectList(filters.projectAccountingProjectFilter);

    setSelectedOurLegalEntityList(filters.projectAccountingContractorLegalEntityFilter);

    setSelectedClientLegalEntityList(filters.projectAccountingClientLegalEntityFilter);

    setSelectedDirectorList(filters.projectAccountingDirectorFilter);

    setSelectedManagerList(filters.projectAccountingManagerFilter);

    setSelectedResponsibleList(filters.projectAccountingResponsibleFilter);

    setSelectedContractFormatGLList(filters.projectAccountingContractTypesFilter);

    setSelectedQuarterList(filters.projectAccountingQuarterFilter);

    const tempQuarter = [];

    getNextFiveQuarters().forEach((element, index) => {
      tempQuarter.push({
        id: index,
        label: element,
      });
    });

    setQuarterList(tempQuarter);
  }, []);

  const updateFieldHandler = (payload) => {
    filters.setProjectAccountingFilter({
      ...filters.projectAccountingFilter,
      ...payload,
    });
  };

  const statusHandler = (value) => {
    const tempArray = Array.from(filters.projectAccountingFilter.status);

    if (tempArray.includes(value)) {
      const index = tempArray.findIndex((item) => item === value);
      tempArray.splice(index, 1);
    } else {
      tempArray.push(value);
    }

    filters.setProjectAccountingFilter({
      ...filters.projectAccountingFilter,
      status: tempArray,
    });
  };

  useEffect(() => {
    let func = () => {};
    if (projectsFinance.projectsPage > 1) {
      if (fetchProjects) {
        func = () => {
          projectsFinance.setProjectsFilter({
            ...filters.projectAccountingFilter,
            project: filters.projectAccountingProjectFilter,
            our_legal_entity: filters.projectAccountingContractorLegalEntityFilter,
            client_legal_entity: filters.projectAccountingClientLegalEntityFilter,
            manager: filters.projectAccountingManagerFilter,
            director: filters.projectAccountingDirectorFilter,
            responsible: filters.projectAccountingResponsibleFilter,
            contract_format_gl: filters.projectAccountingContractTypesFilter,
            quarter: filters.projectAccountingQuarterFilter,
          });

          fetchProjects(
            1,
            projectsFinance.projectsLimit,
            controller.signal,
            projectsFinance.projectsFilter,
            'update',
          ).then(() => projectsFinance.setProjectsPage(2));
        };
      }
    }

    const test = setTimeout(func, 220);
    return () => {
      clearTimeout(test);
    };
  }, [filters.projectAccountingFilter.status.length]);

  const applyFiltersHandler = (event) => {
    event.preventDefault();

    setFiltersPanelOpen(false);

    if (fetchProjects) {
      projectsFinance.setProjectsFilter({
        ...filters.projectAccountingFilter,
        project: selectedProjectList,
        our_legal_entity: selectedOurLegalEntityList,
        client_legal_entity: selectedClientLegalEntityList,
        manager: selectedManagerList,
        director: selectedDirectorList,
        responsible: selectedResponsibleList,
        contract_format_gl: selectedContractFormatGLList,
        quarter: selectedQuarterList,
      });

      projectsFinance.setProjectsPage(1);

      fetchProjects(
        projectsFinance.projectsPage,
        projectsFinance.projectsLimit,
        controller.signal,
        projectsFinance.projectsFilter,
        'update',
      );
    }
  };

  const propsForFiltersPanel = {
    isPanelOpen: filtersPanelOpen,
    fields: FIELDS,
    values: filters.projectAccountingFilter,
    onUpdateField: updateFieldHandler,
    onApplyFilters: applyFiltersHandler,

    projectList,
    project,
    changeProject,
    selectedProjectList,
    addSelectedProject,
    deleteSelectedProject,

    allLegalEntity,
    ourLegalEntity,
    changeOurLegalEntity,
    deleteOurLegalEntity,
    addOurLegalEntity,
    selectedOurLegalEntityList,

    clientLegalEntity,
    changeClientLegalEntity,
    selectedClientLegalEntityList,
    addClientLegalEntity,
    deleteClientLegalEntity,

    directorList,
    director,
    changeDirector,
    selectedDirectorList,
    addDirector,
    deleteDirector,

    managerList,
    manager,
    changeManager,
    selectedManagerList,
    addManager,
    deleteManager,

    responsibleList,
    responsible,
    changeResponsible,
    selectedResponsibleList,
    addResponsible,
    deleteResponsible,

    contractFormatGL,
    selectedContractFormatGLList,
    addContractFormatGL,
    deleteContractFormatGL,

    quarterList,
    quarter,
    selectedQuarterList,
    addSelectedQuarter,
    deleteSelectedQuarter,
  };

  return (
    <>
      <div className={classes.filters__container}>
        <div className={classes.filters__status__container}>
          {[0, 1, 2, 3, 4, 5].map((statusElement) => (
            <StatusBtn
              key={statusElement}
              status={statusElement}
              onClick={() => statusHandler(statusElement)}
              isActive={filters.projectAccountingFilter.status.includes(statusElement)}
              style={{ padding: '5px 15px' }}
              ProjectAccounting
            />
          ))}
        </div>

        <FilterBtn isOpen={filtersPanelOpen} setIsOpen={setFiltersPanelOpen} />
      </div>

      <FiltersPanel {...propsForFiltersPanel} />
    </>
  );
});

export default FilterProjectAccounting;
