import React from 'react';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import SearchBar from 'components/UI/SearchBar/SearchBar';

import { useStateNew } from 'hooks/useStateNew';

import classes from './NameFilter.module.css';

function NameFilter(props) {
  const {
    click, list, type, disabled,
  } = props;

  const [name, , changeName] = useStateNew([
    { fieldId: Date.now(), id: null, name: '' },
  ]);

  return (
    <div className={classes.filter__container}>
      <div className={classes.filter__text}>Поиск</div>

      <div className={classes.filter__input}>
        <SearchBar
          text={name[0]}
          setText={changeName}
          list={list}
          type={type}
          style={{ position: 'static', width: '100%' }}
          onClickDropdownItem={(event, value) => click(event, value)}
          onKeyPress={(event) => click(event, name[0].name)}
          disabled={disabled}
        />

        <SuccessBtn
          text="Найти"
          type="white"
          style={{ padding: '1px 0px', fontSize: 13 }}
          onClick={(event) => click(event, name[0].name)}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default NameFilter;
