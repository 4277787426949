import React from 'react';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import ClientString from 'components/UI/ClientString/ClientString';

import classes from '../LegalEntityPage.module.scss';

function AllLegalEntitiesWindow(props) {
  const { openHandler, counter } = props;

  return (
    <div className={classes.wrapper}>
      <ClientString text="Всего юридических лиц" count={counter} all />

      <SuccessBtn
        text="Добавить"
        style={{ marginTop: 10, padding: '3px 15px', fontSize: 13 }}
        onClick={() => openHandler()}
      />
    </div>
  );
}

export default AllLegalEntitiesWindow;
