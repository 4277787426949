// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import {
  endOfISOWeek, endOfMonth, startOfISOWeek, startOfMonth,
} from 'date-fns';
import { Context } from 'index';

import FilterBtn from 'components/UI/FilterBtn/FilterBtn';
import FiltersPanel from 'components/UI/FiltersPanel/FiltersPanel';
import StatusBtn from 'components/UI/StatusBtns/StatusBtn/StatusBtn';

import { useMultiple } from 'hooks/useMultiple';

import { getDate } from 'utils/getDate';

import { STATUS_QUANTITY, STATUS_QUANTITY_FINANCE } from './consts/consts';

import { COSTS_FIELDS } from './filterFields';

import classes from './CostsFinance.module.scss';

function CostsFinanceFilter(props) {
  const {
    projectList, responsibleList, fetchProjects, documentList, allLegalEntity,
  } = props;

  const { projectsFinance, user } = useContext(Context);

  const controller = new AbortController();

  const [filtersPanelOpen, setFiltersPanelOpen] = useState(false);
  const [filtersPanelValues, setFiltersPanelValues] = useState({
    id: '',
    payment_id: '',
    id_contract: '',
    date_bid_sent_start: '',
    date_bid_sent_end: '',
    date_bid_sent_status: {},
    payment_date_start: '',
    payment_date_end: '',
    payment_date_status: {},
    indicators: {},
    status: [],
  });

  const [
    project,
    changeProject,
    selectedProjectList,
    addSelectedProject,
    deleteSelectedProject,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    ourLegalEntity,
    changeOurLegalEntity,
    selectedOurLegalEntityList,
    addOurLegalEntity,
    deleteOurLegalEntity,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    clientLegalEntity,
    changeClientLegalEntity,
    selectedClientLegalEntityList,
    addClientLegalEntity,
    deleteClientLegalEntity,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    manager,
    changeManager,
    selectedManagerList,
    addManager,
    deleteManager,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    document,
    changeDocument,
    selectedDocumentList,
    addDocument,
    deleteDocument,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [, , selectedPaymentTypeList, addPaymentType, deletePaymentType] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    contractStatus,,
    selectedContractStatusList,
    addContractStatus,
    deleteContractStatus,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const stageHandler = (value) => {
    const tempArray = filtersPanelValues.status;
    if (tempArray.includes(value)) {
      const index = tempArray.findIndex((item) => item === value);
      tempArray.splice(index, 1);
    } else {
      tempArray.push(value);
    }

    setFiltersPanelValues({
      ...filtersPanelValues,
      status: tempArray,
    });
  };

  const updateFieldHandler = (payload) => {
    setFiltersPanelValues({
      ...filtersPanelValues,
      ...payload,
    });
  };

  const applyFiltersHandler = (event) => {
    event.preventDefault();
    setFiltersPanelOpen(false);
    if (fetchProjects) {
      projectsFinance.setCostsFilter({
        ...filtersPanelValues,
        project: selectedProjectList,
        responsible: selectedManagerList,
        type: selectedPaymentTypeList,
        selectedOurLegalEntityList,
        selectedClientLegalEntityList,
        selectedDocumentList,
        selectedContractStatusList,
      });
      projectsFinance.setCostsPage(1);

      fetchProjects(
        projectsFinance.costsPage,
        projectsFinance.costsLimit,
        controller.signal,
        projectsFinance.costsFilter,
        'update',
        user.user.job.id === 3,
      );
    }
  };

  useEffect(() => {
    if (projectsFinance.costsPage > 1) {
      if (fetchProjects) {
        setTimeout(() => {
          projectsFinance.setCostsFilter({
            ...filtersPanelValues,
            project: selectedProjectList,
            responsible: selectedManagerList,
            selectedOurLegalEntityList,
            selectedClientLegalEntityList,
            selectedDocumentList,
            selectedContractStatusList,
          });
          projectsFinance.setCostsPage(1);

          fetchProjects(
            projectsFinance.costsPage,
            projectsFinance.costsLimit,
            controller.signal,
            projectsFinance.costsFilter,
            'update',
            user.user.job.id === 3,
          );
        }, 220);
      }
    }
  }, [filtersPanelValues.status.length]);

  useEffect(() => {
    if (filtersPanelValues?.date_bid_sent_status?.id === 0) {
      setFiltersPanelValues({
        ...filtersPanelValues,
        date_bid_sent_start: getDate(),
        date_bid_sent_end: getDate(),
      });
    } else if (filtersPanelValues?.date_bid_sent_status?.id === 1) {
      setFiltersPanelValues({
        ...filtersPanelValues,
        date_bid_sent_start: startOfISOWeek(Date.now()),
        date_bid_sent_end: endOfISOWeek(Date.now()),
      });
    } else if (filtersPanelValues?.date_bid_sent_status?.id === 2) {
      setFiltersPanelValues({
        ...filtersPanelValues,
        date_bid_sent_start: startOfMonth(Date.now()),
        date_bid_sent_end: endOfMonth(Date.now()),
      });
    }
  }, [filtersPanelValues.date_bid_sent_status]);

  useEffect(() => {
    if (filtersPanelValues?.payment_date_status?.id === 0) {
      setFiltersPanelValues({
        ...filtersPanelValues,
        payment_date_start: getDate(),
        payment_date_end: getDate(),
      });
    } else if (filtersPanelValues?.payment_date_status?.id === 1) {
      setFiltersPanelValues({
        ...filtersPanelValues,
        payment_date_start: startOfISOWeek(Date.now()),
        payment_date_end: endOfISOWeek(Date.now()),
      });
    } else if (filtersPanelValues?.payment_date_status?.id === 2) {
      setFiltersPanelValues({
        ...filtersPanelValues,
        payment_date_start: startOfMonth(Date.now()),
        payment_date_end: endOfMonth(Date.now()),
      });
    }
  }, [filtersPanelValues.payment_date_status]);

  const propsForFiltersPanel = {
    isPanelOpen: filtersPanelOpen,
    fields: COSTS_FIELDS,
    values: filtersPanelValues,
    onUpdateField: updateFieldHandler,
    onApplyFilters: applyFiltersHandler,

    documentList,
    allLegalEntity,
    projectList,
    managerList: responsibleList,

    project,
    changeProject,
    selectedProjectList,
    addSelectedProject,
    deleteSelectedProject,

    ourLegalEntity,
    changeOurLegalEntity,
    selectedOurLegalEntityList,
    addOurLegalEntity,
    deleteOurLegalEntity,

    clientLegalEntity,
    changeClientLegalEntity,
    selectedClientLegalEntityList,
    addClientLegalEntity,
    deleteClientLegalEntity,

    manager,
    changeManager,
    selectedManagerList,
    addManager,
    deleteManager,

    responsibleDocument: document,
    changeResponsibleDocument: changeDocument,
    selectedDocumentList,
    addDocument,
    deleteDocument,

    addPaymentType,
    deletePaymentType,
    selectedPaymentTypeList,

    contractStatus,
    addContractStatus,
    selectedContractStatusList,
    deleteContractStatus,
  };

  return (
    <>
      <div className={classes.filters__container}>
        <div className={classes.filters__status__container}>
          {(user.user.job.id === 3 ? STATUS_QUANTITY_FINANCE : STATUS_QUANTITY).map((statusElement) => (
            <StatusBtn
              key={statusElement}
              status={statusElement}
              onClick={() => stageHandler(statusElement)}
              isActive={filtersPanelValues.status.includes(statusElement)}
              style={{ padding: '5px 15px' }}
              financeCost
            />
          ))}
        </div>

        <FilterBtn isOpen={filtersPanelOpen} setIsOpen={setFiltersPanelOpen} />
      </div>

      <FiltersPanel {...propsForFiltersPanel} />
    </>
  );
}

export default CostsFinanceFilter;
