// @ts-nocheck
import React, { useCallback, useEffect } from 'react';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';

import {
  ACCOUNT_STATUS,
  CONTRACT_STATUS,
  CONTRACT_TYPES,
  CONTRACT_TYPES_GL,
  DATE_DROPDOWN,
  DEADLINE_STATUSES,
  ID_PROJECT_STATUSES,
  INDICATORS_1_STATUS,
  INDICATORS_2_STATUS,
  IS_CONTRACT_CREATED,
  PAYMENT_TYPES,
  QUARTER_CLOSING,
  STATE_STATUSES,
  STATUS_CLOSE_DOC,
  TRUE_FALSE_STATUS,
} from 'utils/consts';
import { getSumRuble } from 'utils/getSums';

import SecondDoubleDate from '../DoubleDate/SecondDoubleDate';
import Fieldset from '../Fieldset/Fieldset';
import InputElement from '../InputElements/InputElement';
import MultipleBtn from '../MultipleBtn/MultipleBtn';
import CheckBox from '../MyCheckbox/CheckBox';
import MyDateTimeInput from '../MyDateTimeInput/MyDateTimeInput';
import MyDropdown from '../MyDropdown/MyDropdown';
import NewInput from '../MyInput/NewInput';
import SearchBar from '../SearchBar/SearchBar';

import classes from './FiltersPanel.module.scss';

function FiltersPanel(props) {
  const {
    isPanelOpen,
    fields,
    values,
    deadlineStatus,
    setDeadlineStatus,
    onUpdateField,
    onApplyFilters,
    projectList,
    clientList,
    lawyersList,
    documentList,
    managerList,
    directorList,
    responsibleList,
    changeProject,
    changeClient,
    changeManager,
    changeResponsible,
    changeDirector,
    changeResponsibleLawyer,
    changeResponsibleDocument,
    addSelectedProject,
    addSelectedClient,
    addOurLegalEntity,
    addClientLegalEntity,
    addManager,
    addDirector,
    addResponsible,
    addLawyer,
    addDocument,
    addContractFormat,
    addContractStatus,
    addContractClosedStatus,
    addStatusScore,
    addProjectStatus,
    addQuarterClosing,
    addQuarterStart,
    addQuarterEnd,
    addPaymentType,
    project,
    client,
    responsibleLawyer,
    responsibleDocument,
    manager,
    director,
    responsible,
    contractFormat,
    contractStatus,
    quarterClosing,
    quarterStart,
    quarterEnd,
    contractClosedStatus,
    statusScore,
    projectStatus,
    allLegalEntity,
    changeOurLegalEntity,
    ourLegalEntity,
    clientLegalEntity,
    changeClientLegalEntity,
    selectedProjectList,
    selectedClientList,
    selectedOurLegalEntityList,
    selectedClientLegalEntityList,
    selectedManagerList,
    selectedDirectorList,
    selectedResponsibleList,
    selectedLawyerList,
    selectedDocumentList,
    selectedContractFormatList,
    selectedContractStatusList,
    selectedContractClosedStatusList,
    selectedStatusScoreList,
    selectedProjectStatusList,
    selectedQuarterClosingList,
    selectedQuarterStartList,
    selectedQuarterEndList,
    selectedPaymentTypeList,
    deleteSelectedProject,
    deleteClient,
    deleteOurLegalEntity,
    deleteClientLegalEntity,
    deleteManager,
    deleteDirector,
    deleteResponsible,
    deleteLawyer,
    deleteDocument,
    deleteContractFormat,
    deleteContractStatus,
    deleteContractClosedStatus,
    deleteStatusScore,
    deleteProjectStatus,
    deleteQuarterClosing,
    deleteQuarterStart,
    deleteQuarterEnd,
    deletePaymentType,

    quarter,
    quarterList,
    selectedQuarterList,
    addSelectedQuarter,
    deleteSelectedQuarter,

    contractFormatGL,
    selectedContractFormatGLList,
    addContractFormatGL,
    deleteContractFormatGL,

    financeMain,
    mainLawyers,
    CancelAllFiltersHandler,
  } = props;

  const formClassNames = [
    classes.form,
    isPanelOpen && classes.open,
    !isPanelOpen && classes.closed,
  ].join(' ');

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' && isPanelOpen) {
        onApplyFilters(event);
      }
    },
    [onApplyFilters],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div>
      <form
        id="myForm"
        className={formClassNames}
        onSubmit={(event) => onApplyFilters(event)}
        onKeyDown={(event) => handleKeyDown(event)}
      >
        <div className={classes.fields}>
          {fields.map((f) => (f.type === 'checkbox_group' ? (
            <div
              key={f.id}
              style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}
            >

              {f.checkboxes.map((field) => (
                <Fieldset
                  className={classes[`fieldset_${field.width}`]}
                  key={`filters_panel_fieldset_${field.id}`}
                >

                  <span className={classes.label}>
                    {field.label}
                  </span>

                  {field.checkboxes.map((checkbox) => (
                    <CheckBox
                      key={`checkbox_group_checkbox_${checkbox.id}`}
                      id={`${field.id}_${checkbox.id}`}
                      name={`${field.id}_${checkbox.id}`}
                      value={`${field.id}_${checkbox.id}`}
                      htmlFor={`${checkbox.id}_${field.id}`}
                      label={checkbox.label}
                      onChange={() => {
                        if (values[field.id]?.includes(checkbox.id)) {
                          const index = values[field.id].findIndex(
                            (item) => item === checkbox.id,
                          );
                          values[field.id].splice(index, 1);
                        } else {
                          values[field.id].push(checkbox.id);
                        }
                        onUpdateField({ [field.id]: values[field.id] });
                      }}
                      checked={values[field.id]?.includes(checkbox.id)}
                      transparentBg
                    />
                  ))}

                </Fieldset>

              ))}
            </div>

          ) : (
            <Fieldset
              className={classes[`fieldset_${f.width}`]}
              key={`filters_panel_fieldset_${f.id}`}
            >
              <span className={classes.label}>
                {f.label}
              </span>

              {(f.type === 'text' || f.type === 'number') && (
              <NewInput
                type={f.type}
                id={f.id}
                name={f.id}
                value={values[f.id]}
                placeholder={f.placeholder}
                onInput={(val) => onUpdateField({ [f.id]: val })}
              />
              )}

              {f.type === 'sum' && (
              <InputElement
                type={f.type}
                id={f.id}
                name={f.id}
                value={values[f.id]}
                placeholder={f.placeholder}
                onChange={(event) => onUpdateField({ [f.id]: getSumRuble(event.target.value) })}
                style={{ width: '100%' }}
              />

              )}

              {f.type === 'autocomplete' && (
              <SearchBar
                text={
                    (f.list === 'project' && project[0])
                    || (f.list === 'client' && client[0])
                  }
                setText={
                    (f.list === 'project' && changeProject)
                    || (f.list === 'client' && changeClient)
                  }
                list={
                    (f.list === 'project' && projectList)
                    || (f.list === 'client' && clientList)
                  }
                type={f.list}
                multiple={
                    f.multiple
                    && ((f.list === 'project' && addSelectedProject)
                    || (f.list === 'client' && addSelectedClient))
                  }
                style={{ position: 'static', width: '100%' }}
              />
              )}

              {f.type === 'autocompleteStaff' && (
              <SearchBar
                text={
                    (f.list === 'managers' && manager[0])
                    || (f.list === 'directors' && director[0])
                    || (f.list === 'responsibles' && responsible[0])
                    || (f.list === 'lawyers' && responsibleLawyer[0])
                    || (f.list === 'documents' && responsibleDocument[0])
                  }
                setText={
                    (f.list === 'managers' && changeManager)
                    || (f.list === 'directors' && changeDirector)
                    || (f.list === 'responsibles' && changeResponsible)
                    || (f.list === 'lawyers' && changeResponsibleLawyer)
                    || (f.list === 'documents' && changeResponsibleDocument)
                  }
                list={
                    (f.list === 'managers' && managerList)
                    || (f.list === 'directors' && directorList)
                    || (f.list === 'responsibles' && responsibleList)
                    || (f.list === 'lawyers' && lawyersList)
                    || (f.list === 'documents' && documentList)
                  }
                type="staff"
                multiple={
                    f.multiple
                    && ((f.list === 'managers' && addManager)
                    || (f.list === 'directors' && addDirector)
                    || (f.list === 'responsibles' && addResponsible)
                    || (f.list === 'lawyers' && addLawyer)
                    || (f.list === 'documents' && addDocument))
                  }
                style={{ position: 'static', width: '100%' }}
              />
              )}

              {f.type === 'autocompleteLegal' && (
              <SearchBar
                text={
                    (f.id === 'contractor_id' && ourLegalEntity[0])
                    || (f.id === 'client_id' && clientLegalEntity[0])
                  }
                setText={
                    (f.id === 'contractor_id' && changeOurLegalEntity)
                    || (f.id === 'client_id' && changeClientLegalEntity)
                  }
                list={allLegalEntity}
                type="legal_details"
                multiple={
                    f.multiple
                    && ((f.id === 'contractor_id' && addOurLegalEntity)
                    || (f.id === 'client_id' && addClientLegalEntity))
                  }
                style={{ position: 'static', width: '100%' }}
              />
              )}

              {f.type === 'dropdown' && (
              <InputElement
                id={f.id}
                value={
                    (f.id === 'contract_format' && contractFormat)
                    || (f.id === 'contract_status' && contractStatus)
                    || (f.id === 'contract_closed_status' && contractClosedStatus)
                    || (f.id === 'status_score' && statusScore)
                    || (f.id === 'quarter_closing' && quarterClosing)
                    || (f.id === 'quarter_start' && quarterStart)
                    || (f.id === 'quarter_end' && quarterEnd)
                    || (f.list === 'project_status' && projectStatus)
                    || (f.id === 'quarter' && quarter)
                    || (f.id === 'contract_format_gl' && contractFormatGL)
                    || ((f.list === 'true_false'
                    || f.indicators
                    || f.list === 'contract_sent_date_deadline'
                    || f.list === 'is_contract_created'
                    || f.list === 'project_sent_date_deadline')
                    && values[f.id])
                  }
                setValue={
                    (f.list === 'true_false'
                    || f.indicators
                    || f.list === 'contract_sent_date_deadline'
                    || f.list === 'is_contract_created'
                    || f.list === 'project_sent_date_deadline')
                    && ((value) => onUpdateField({ [f.id]: value }))
                  }
                multipleSelect={
                    (f.list === 'contract_format' && addContractFormat)
                    || (f.list === 'contract_status' && addContractStatus)
                    || (f.id === 'contract_closed_status' && addContractClosedStatus)
                    || (f.id === 'status_score' && addStatusScore)
                    || (f.list === 'project_status' && addProjectStatus)
                    || (f.id === 'quarter_closing' && addQuarterClosing)
                    || (f.id === 'quarter_start' && addQuarterStart)
                    || (f.id === 'quarter_end' && addQuarterEnd)
                    || (f.id === 'quarter' && addSelectedQuarter)
                    || (f.id === 'contract_format_gl' && addContractFormatGL)
                  }
                dropdown={
                    (f.list === 'contract_format' && CONTRACT_TYPES.LIST)
                    || (f.list === 'contract_status' && CONTRACT_STATUS.LIST)
                    || (f.list === 'contract_closed_status' && STATUS_CLOSE_DOC.LIST)
                    || (f.list === 'status_score' && ACCOUNT_STATUS.LIST)
                    || (f.list === 'true_false' && TRUE_FALSE_STATUS.LIST)
                    || (f.list === 'indicators_1' && INDICATORS_1_STATUS.LIST)
                    || (f.list === 'indicators_2' && INDICATORS_2_STATUS.LIST)
                    || (f.list === 'contract_sent_date_deadline' && DEADLINE_STATUSES.LIST)
                    || (f.list === 'is_contract_created' && IS_CONTRACT_CREATED.LIST)
                    || (f.list === 'project_sent_date_deadline' && ID_PROJECT_STATUSES.LIST)
                    || (f.list === 'project_status' && STATE_STATUSES.LIST)
                    || ((f.list === 'quarter_closing'
                    || f.list === 'quarter_start'
                    || f.list === 'quarter_end') && QUARTER_CLOSING.LIST)
                    || (f.list === 'quarterList' && quarterList)
                    || (f.list === 'contract_format_gl' && CONTRACT_TYPES_GL.LIST)
                  }
                multiple={f.multiple}
                style={{ width: '100%' }}
                className={classes[`fieldset_${f.width}`]}
              />
              )}

              {f.type === 'multipleDropdown' && (
              <MyDropdown
                defaultText="Выбрать"
                setSelected={f.id === 'payment_type' && addPaymentType}
                list={
                    (f.list === 'payment_type' && PAYMENT_TYPES.LIST)
                    || (f.list === 'payment_type_long' && PAYMENT_TYPES.LIST_LONG)
                  }
              />

              )}

              {f.multiple && (
              <div className={classes.selected}>

                {(
                  (f.list === 'managers' && selectedManagerList)
                    || (f.list === 'directors' && selectedDirectorList)
                    || (f.list === 'responsibles' && selectedResponsibleList)
                    || (f.list === 'lawyers' && selectedLawyerList)
                    || (f.list === 'documents' && selectedDocumentList)
                    || (f.list === 'project' && selectedProjectList)
                    || (f.list === 'client' && selectedClientList)
                    || (f.id === 'contractor_id' && selectedOurLegalEntityList)
                    || (f.id === 'client_id' && selectedClientLegalEntityList)
                    || (f.list === 'contract_format' && selectedContractFormatList)
                    || (f.list === 'contract_status' && selectedContractStatusList)
                    || (f.list === 'contract_closed_status' && selectedContractClosedStatusList)
                    || (f.list === 'status_score' && selectedStatusScoreList)
                    || (f.list === 'project_status' && selectedProjectStatusList)
                    || (f.id === 'quarter_closing' && selectedQuarterClosingList)
                    || (f.id === 'quarter_start' && selectedQuarterStartList)
                    || (f.id === 'quarter_end' && selectedQuarterEndList)
                    || (f.id === 'payment_type' && selectedPaymentTypeList)
                    || (f.id === 'quarter' && selectedQuarterList)
                    || (f.id === 'contract_format_gl' && selectedContractFormatGLList)
                ).map((element) => (
                  <MultipleBtn
                    key={element.id}
                    element={element}
                    deleteEl={
                        (f.list === 'managers' && deleteManager)
                        || (f.list === 'directors' && deleteDirector)
                        || (f.list === 'responsibles' && deleteResponsible)
                        || (f.list === 'lawyers' && deleteLawyer)
                        || (f.list === 'documents' && deleteDocument)
                        || (f.list === 'project' && deleteSelectedProject)
                        || (f.list === 'client' && deleteClient)
                        || (f.id === 'contractor_id' && deleteOurLegalEntity)
                        || (f.id === 'client_id' && deleteClientLegalEntity)
                        || (f.list === 'contract_format' && deleteContractFormat)
                        || (f.list === 'contract_status' && deleteContractStatus)
                        || (f.list === 'contract_closed_status' && deleteContractClosedStatus)
                        || (f.list === 'status_score' && deleteStatusScore)
                        || (f.list === 'project_status' && deleteProjectStatus)
                        || (f.id === 'quarter_closing' && deleteQuarterClosing)
                        || (f.id === 'quarter_start' && deleteQuarterStart)
                        || (f.id === 'quarter_end' && deleteQuarterEnd)
                        || (f.id === 'payment_type' && deletePaymentType)
                        || (f.id === 'quarter' && deleteSelectedQuarter)
                        || (f.id === 'contract_format_gl' && deleteContractFormatGL)
                      }
                  />

                ))}
              </div>
              )}

              {f.type === 'date' && (
              <MyDateTimeInput
                id={f.id}
                value={values[f.id]}
                className={classes[`fieldset_${f.width}`]}
                placeholder={f.placeholder}
                onChange={(date) => onUpdateField({ [f.id]: date })}
                style={{ width: '100%' }}
              />

              )}

              {f.type === 'deadline' && (
              <InputElement
                id={f.id}
                value={deadlineStatus}
                setValue={setDeadlineStatus}
                dropdown={DEADLINE_STATUSES.LIST}
                style={{ width: '100%' }}
                className={classes[`fieldset_${f.width}`]}
              />
              )}

              {f.type === 'doubleDate' && (

              <div
                className={[
                  classes.doubleDate__container,
                  classes[`fieldset_${f.width}`],
                ].join(' ')}
                id={f.id}
              >

                <SecondDoubleDate
                  value1={values[f.value_id_1]}
                  value2={values[f.value_id_2]}
                  setValue1={(date) => onUpdateField({ [f.value_id_1]: date })}
                  setValue2={(date) => onUpdateField({ [f.value_id_2]: date })}
                />

              </div>
              )}

              {f.type === 'doubleDateWithStatus' && (

              <div
                className={[
                  classes.doubleDateWithStatus__container,
                  classes[`fieldset_${f.width}`],
                ].join(' ')}
                id={f.id}
              >

                <SecondDoubleDate
                  value1={values[f.value_id_1]}
                  value2={values[f.value_id_2]}
                  setValue1={(date) => onUpdateField({ [f.value_id_1]: date })}
                  setValue2={(date) => onUpdateField({ [f.value_id_2]: date })}
                />

                <InputElement
                  value={values[f.date_status_value]}
                  setValue={(status) => onUpdateField({ [f.date_status_value]: status })}
                  dropdown={f?.list ? f?.list : DATE_DROPDOWN.LIST}
                  inputStyle={{ fontSize: 13 }}
                  elementClassName={classes.height_32}
                  className={classes.height_100}
                  classNameDropdown={classes.height_100}
                />

              </div>
              )}

            </Fieldset>

          )))}
        </div>
        <div className={classes.button_wrapper}>
          <div className={classes.submitButtonWrapper}>
            <SuccessBtn
              form="myForm"
              type="black"
              text="Применить фильтр"
              style={{ padding: '4px 14px', fontSize: 11 }}
              onKeyDown={handleKeyDown}
            />
          </div>
          {(financeMain || mainLawyers)
      && (
      <div className={classes.cancelButtonWrapper}>
        <SuccessBtn
          type="white"
          text="Отменить все"
          style={{ padding: '3px 29px', fontSize: 11 }}
          onClick={CancelAllFiltersHandler}
        />
      </div>
      )}
        </div>
      </form>
    </div>
  );
}

export default FiltersPanel;
