// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { Context } from 'index';

import { postBlogger } from 'API/BloggerAPI';
import { check } from 'API/UserAPI';
import { fetchStaff } from '../../../../API/ManagerAPI/AutocompleteAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import ContactInfo from 'components/UI/ContactInfo/ContactInfo';
import MyModal from 'components/UI/MyModal/MyModal';

import { useLogout } from 'hooks/useLogout';
import { useStateNew2 } from 'hooks/useStateNew2';

import AdditionalInfo from './components/AdditionalInfo';
import MainInfo from './components/MainInfo';

import classes from './ModalCreateBlogger.module.css';

const defaultState = {
  type: { id: 3, label: 'Блогер' },
  fullname: '',
  name: '',
  category: '',
  clothing_size: '',
  params: '',
  children: '',
  animals: '',
  exclusive: '',
  prohibited: '',
  instagram_links: [{ id: Date.now(), link: '' }],
  vk_links: [{ id: Date.now(), link: '' }],
  youtube_links: [{ id: Date.now(), link: '' }],
  tiktok_links: [{ id: Date.now(), link: '' }],
  twitch_links: [{ id: Date.now(), link: '' }],
  telegram_links: [{ id: Date.now(), link: '' }],
};

const defaultCard = {
  id: Date.now(),
  bank: '',
  number: '',
};

const defaultContacts = {
  id: Date.now(),
  email: '',
  password: '',
  phone: '',
  telegram: '',
  birthday: '',
  address: '',
  responsible: [],
  note: '',
};

function ModalCreateBlogger({ show, onHide, props }) {
  const { fetchBloggers } = props;

  const { toast } = useContext(Context);
  const [logout] = useLogout();

  const [staffList, setStaffList] = useState([]);

  const [state, setState] = useState(defaultState);

  const [card, setCard, changeCard, addCard, deleteCard] = useStateNew2([defaultCard]);

  const [contacts, setContacts, updateContacts, addContact, deleteContact] = useStateNew2([
    defaultContacts,
  ]);

  useEffect(() => {
    check().then(() => {
      fetchStaff({ job: 0, is_active: true }).then((data) => setStaffList(data));
    });
  }, []);

  const closeModal = () => {
    setTimeout(() => {
      setState(defaultState);
      setContacts([defaultContacts]);
      setCard([defaultCard]);
    }, 140);
    onHide();
  };

  const createBlogger = () => {
    const data = {
      type: { id: state.type.id },
      fullname: state.fullname,
      name: state.name,
      category: state.category,
      clothing_size: state.clothing_size,
      params: state.params,
      children: state.children,
      animals: state.animals,
      exclusive: state.exclusive,
      prohibited: state.prohibited,
    };

    const contactsList = [];
    contacts.forEach((element) => {
      const responsibleArray = [];

      element.responsible.forEach((element) => {
        if (element.id) {
          responsibleArray.push({ id: element.id });
        }
      });

      contactsList.push({
        address: element.address,
        phone: element.phone,
        telegram: element.telegram,
        email: element.email,
        password: element.password,
        responsible: responsibleArray,
        note: element.note,
      });
    });

    data.contacts = contactsList;
    data.bank_cards = card.slice(1);

    check()
      .then(() => {
        postBlogger(data)
          .then(() => {
            closeModal();
            fetchBloggers();
            toast.setShowSuccess(true);
            toast.setToastInfo('Новый блогер создан');
          })
          .catch((error) => {
            if (error.response.data.contacts) {
              toast.setToastInfo('Введите правильный email');
              toast.setShowError(true);
            } else if (error.response.data.legal_entity) {
              toast.setToastInfo('Выберите форму ЮЛ');
              toast.setShowError(true);
            } else if (error.response.data.name) {
              toast.setToastInfo('Блогер с таким ником уже создан');
              toast.setShowError(true);
            } else {
              toast.setToastInfo('Обратитесь в техподдержку');
              toast.setShowError(true);
            }
          });
      })
      .catch((error) => logout(error));
  };

  return (
    <MyModal show={show} onHide={closeModal} style={{ minHeight: '60vh' }}>
      <h1 className={classes.new}>Новый блогер/подрядчик</h1>

      <div className={classes.wrapper}>
        <header className={classes.header}>Общая информация</header>

        <MainInfo
          state={state}
          setState={setState}
          card={card}
          changeCard={changeCard}
          addCard={addCard}
          removeCard={deleteCard}
          inModal
        />

        <ContactInfo
          contacts={contacts}
          updateContacts={updateContacts}
          addContact={addContact}
          removeContact={deleteContact}
          sectionClassName={classes.section_header}
          titleClassName={classes.section_title}
          contentClassName={classes.contacts}
          className={classes.contacts}
          staffList={staffList}
          blogger
          inModal
          noClose
        />

        {state.type.id === 0 && (
        <>
          <header className={classes.header}>
            Дополнительная информация
          </header>

          <AdditionalInfo state={state} setState={setState} noDelete />
        </>
        )}
      </div>

      <div className={classes.btn__container}>
        <SuccessBtn
          text="Добавить"
          style={{ padding: '4px 15px', fontSize: 13 }}
          onClick={() => createBlogger()}
        />
      </div>
    </MyModal>
  );
}

export default ModalCreateBlogger;
