// @ts-nocheck
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { fetchReportLawyersNews } from 'API/LawyersAPI/NewsAPI';
import { fetchReportProjects } from 'API/ManagerAPI/ProjectAPI';
import { fetchReportTasks } from 'API/ManagerAPI/TasksAPI';
import { fetchReportNews } from 'API/NewsAPI';
import { check } from 'API/UserAPI';

import StepBtn from 'components/UI/Btns/StepBtn';
import PageTitle from 'components/UI/PageTitle/PageTitle';
import RegistryTable from 'components/UI/RegistryTable/RegistryTable';
import Table from 'components/UI/Table/Table';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from 'hooks/useLogout';

import { numberToSum } from 'utils/getSums';

import {
  columns, headCellStyle, headerStyle, newsColumns, tasksColumns, tasksDevelopmentColumns, tasksLawyerColumns,
} from './consts/consts';

import FilterForReportPage from './FilterForReportPage';

import classes from './ReportPage.module.scss';

const ReportPage = observer(() => {
  const {
    user, reportProjects, reportTasks, reportNews, filters,
  } = useContext(Context);

  const [logout] = useLogout();

  const table = useRef();
  const tableTasks = useRef();
  const tableLawyerTasks = useRef();
  const tableNews = useRef();
  const isFirstRender = useRef(true);

  const controller = new AbortController();
  const controllerTasks = new AbortController();
  const controllerLawyerTasks = new AbortController();
  const controllerNews = new AbortController();

  const [step, setStep] = useState(0);
  const [fetchingProjects, setFetchingProjects] = useState(true);
  const [fetchingTasks, setFetchingTasks] = useState(true);
  const [fetchingNews, setFetchingNews] = useState(true);

  // Маржинальность проектов

  const [fetchingReportProjects, isProjectsLoading] = useFetching(
    async (page, limit, signal, filter, update) => {
      await check().catch((error) => logout(error));

      if (user.department !== 1) {
        const data = await fetchReportProjects(page, limit, signal, filter);

        reportProjects.setReportProjectsTotalCount(data.count);
        reportProjects.setReportTotalCreditSum(data.total_credit_sum);
        reportProjects.setReportTotalMarginSum(data.total_margin_sum);
        reportProjects.setReportTotalPaidSum(data.total_paid_sum);
        reportProjects.setReportTotalSum(data.total_sum);

        if (update === 'update' || reportProjects.reportProjectsPage === 1) {
          reportProjects.setReportProjectsPage(2);
          reportProjects.setReportProjects(data.results, 'update');
        } else {
          reportProjects.setReportProjectsPage(reportProjects.reportProjectsPage + 1);
          reportProjects.setReportProjects(data.results);
        }

        setFetchingProjects(false);
      } else {
        setFetchingProjects(false);
      }
    },
  );

  const totalPages = Math.ceil(
    reportProjects.reportProjectsTotalCount / reportProjects.reportProjectsLimit,
  );

  /* Запрос на сервер при скролле до конца таблицы */

  useEffect(() => {
    if (fetchingProjects) {
      if (reportProjects.reportProjectsFilter) {
        fetchingReportProjects(
          reportProjects.reportProjectsPage,
          reportProjects.reportProjectsLimit,
          controller.signal,
          reportProjects.reportProjectsFilter,
        );
      } else {
        fetchingReportProjects(
          reportProjects.reportProjectsPage,
          reportProjects.reportProjectsLimit,
          controller.signal,
        );
      }
    }

    return () => {
      controller.abort();
    };
  }, [fetchingProjects]);

  /* Когда доскролили до нижней части таблицы (первое условие)
  Проверяем текущую страницу с общим количеством страниц(второе условие)
  Далее делаем запрос
  */
  const scrollHandlerProjects = (event) => {
    if (
      (event.target.scrollHeight
       - (event.target.scrollTop + event.target.clientHeight)
       < 600
       && reportProjects.reportProjectsPage <= totalPages)
    ) {
      setFetchingProjects(true);
    }
  };

  // Наблюдение за скролломы
  useEffect(() => {
    if (isProjectsLoading) return;
    if (table.current) {
      const element = table.current;
      element.addEventListener('scroll', scrollHandlerProjects);
      return function () {
        element.removeEventListener('scroll', scrollHandlerProjects);
      };
    }
  }, [isProjectsLoading]);

  // Занятость сотрудника

  const [fetchingReportTasks, isTasksLoading] = useFetching(
    async (page, limit, signal, filter, update) => {
      await check().catch((error) => logout(error));

      const data = await fetchReportTasks(page, limit, signal, filter);

      reportTasks.setReportTasksTotalCount(data.count);

      if (update === 'update' || reportTasks.reportTasksPage === 1) {
        reportTasks.setReportTasksPage(2);
        reportTasks.setReportTasks(data.results, 'update');
      } else {
        reportTasks.setReportTasksPage(reportTasks.reportTasksPage + 1);
        reportTasks.setReportTasks(data.results);
      }

      setFetchingTasks(false);
    },
  );

  const totalPagesTasks = Math.ceil(
    reportTasks.reportTasksTotalCount / reportTasks.reportTasksLimit,
  );

  /* Запрос на сервер при скролле до конца таблицы */

  useEffect(() => {
    if (fetchingTasks) {
      if (reportTasks.reportTasksFilter) {
        fetchingReportTasks(
          reportTasks.reportTasksPage,
          reportTasks.reportTasksLimit,
          controllerTasks.signal,
          reportTasks.reportTasksFilter,
        );
      } else {
        fetchingReportTasks(
          reportTasks.reportTasksPage,
          reportTasks.reportTasksLimit,
          controllerTasks.signal,
        );
      }

      return () => {
        controllerLawyerTasks.abort();
      };
    }
  }, [fetchingTasks]);

  /* Когда доскролили до нижней части таблицы (первое условие)
  Проверяем текущую страницу с общим количеством страниц(второе условие)
  Далее делаем запрос
  */
  const scrollHandlerTasks = (event) => {
    if (
      (event.target.scrollHeight
       - (event.target.scrollTop + event.target.clientHeight)
       < 600
       && (reportTasks.reportTasksPage <= totalPagesTasks))
    ) {
      setFetchingTasks(true);
    }
  };

  // Наблюдение за скролломы
  useEffect(() => {
    if (isTasksLoading) return;
    if (tableTasks.current || tableLawyerTasks.current) {
      const element = tableTasks.current || tableLawyerTasks.current;
      element.addEventListener('scroll', scrollHandlerTasks);
      return function () {
        element.removeEventListener('scroll', scrollHandlerTasks);
      };
    }
  }, [isTasksLoading]);

  // Новости

  const [fetchingReportNews, isNewsLoading] = useFetching(
    async (page, limit, signal, filter, update) => {
      await check().catch((error) => logout(error));
      let data;
      if (user.department === 1) {
        data = await fetchReportLawyersNews(page, limit, signal, filter);
      } else {
        data = await fetchReportNews(page, limit, signal, filter);
      }

      reportNews.setReportNewsTotalCount(data.count);

      if (update === 'update' || reportNews.reportNewsPage === 1) {
        reportNews.setReportNewsPage(2);
        reportNews.setReportNews(data.results, 'update');
      } else {
        reportNews.setReportNewsPage(reportNews.reportNewsPage + 1);
        reportNews.setReportNews(data.results);
      }

      setFetchingNews(false);
    },
  );

  const totalPagesNews = Math.ceil(
    reportNews.reportNewsTotalCount / reportNews.reportNewsLimit,
  );

  /* Запрос на сервер при скролле до конца таблицы */

  useEffect(() => {
    if (fetchingNews) {
      if (reportNews.reportNewsFilter) {
        fetchingReportNews(
          reportNews.reportNewsPage,
          reportNews.reportNewsLimit,
          controllerNews.signal,
          reportNews.reportNewsFilter,
        );
      } else {
        fetchingReportNews(
          reportNews.reportNewsPage,
          reportNews.reportNewsLimit,
          controllerNews.signal,
        );
      }

      return () => {
        controllerNews.abort();
      };
    }
  }, [fetchingNews]);

  /* Когда доскролили до нижней части таблицы (первое условие)
  Проверяем текущую страницу с общим количеством страниц(второе условие)
  Далее делаем запрос
  */
  const scrollHandlerNews = (event) => {
    if (
      (event.target.scrollHeight
       - (event.target.scrollTop + event.target.clientHeight)
       < 600
       && (reportNews.reportNewsPage <= totalPagesNews))
    ) {
      setFetchingNews(true);
    }
  };

  // Наблюдение за скролломы
  useEffect(() => {
    if (isNewsLoading) return;

    if (tableNews.current) {
      const element = tableNews.current;
      element.addEventListener('scroll', scrollHandlerNews);
      return function () {
        element.removeEventListener('scroll', scrollHandlerNews);
      };
    }
  }, [isNewsLoading]);

  useEffect(() => {
    if (user.department === 1) {
      setFetchingTasks(true);
      setFetchingNews(true);
      setStep(1);
    } else {
      setFetchingProjects(true);
      setFetchingTasks(true);
      setFetchingNews(true);
      setStep(0);
    }
  }, [user.department]);

  if (isProjectsLoading && isFirstRender.current) {
    isFirstRender.current = false;
    return <Spinner animation="grow" />;
  }

  const steps = [
    {
      text: 'Маржинальность проектов',
      index: 0,
      show: user.department !== 1,
    },
    {
      text: 'Занятость сотрудников',
      index: 1,
      show: true,
    },
    {
      text: 'Новости',
      index: 2,
      show: true,
    },
  ];

  const tableStyles = {
    0: step !== 0 ? { display: 'none' } : {},
    1: step !== 1 ? { display: 'none' } : {},
    2: step !== 2 ? { display: 'none' } : {},
  };

  // Стили в зависимости от выбранного отдела
  const tableNameForTableTasks = (filters.reportFilter.selectedDepartment.id === 1 && 'reportPageLawyerTasks')
  || (filters.reportFilter.selectedDepartment.id === 6 && 'reportPageDevelopmentTasks')
  || 'reportPageTasks';

  // Колонки в зависимости от выбранного отдела
  const columnsForTableTasks = (filters.reportFilter.selectedDepartment.id === 1 && tasksLawyerColumns)
  || (filters.reportFilter.selectedDepartment.id === 6 && tasksDevelopmentColumns)
  || tasksColumns;

  // Типы в зависимости от шага
  const typesForTable = (step === 1 && 'reportTasks')
  || (step === 2 && 'reportPageNews');

  // Колонки для строки "Итого"
  const columnsOneTableRow = [
    { id: 'text', label: 'Итого' },
    { id: 'count', label: reportProjects.reportProjectsTotalCount },
    { id: 'void_1', label: '' },
    { id: 'void_2', label: '' },
    { id: 'total_sum', label: numberToSum(reportProjects.reportTotalSum, true) },
    { id: 'void_2', label: '' },
    { id: 'paid_sum', label: numberToSum(reportProjects.reportTotalPaidSum, true) },
    { id: 'credit_sum', label: numberToSum(reportProjects.reportTotalCreditSum, true) },
    { id: 'margin_sum', label: numberToSum(reportProjects.reportTotalMarginSum, true) },
  ];

  return (
    <div>
      <PageTitle>
        Отчет
      </PageTitle>

      <div className={classes.btn__steps}>
        {steps.map(({ text, index, show }) => (show ? (
          <StepBtn
            key={index}
            active={step === index}
            text={text}
            onClick={() => setStep(index)}
          />
        ) : null))}
      </div>

      <FilterForReportPage
        step={step}
        fetchingReportProjects={fetchingReportProjects}
        fetchingReportTasks={fetchingReportTasks}
        fetchingReportNews={fetchingReportNews}
      />

      {/* Маржинальность проектов */}
      {user.department !== 1 && (
        <RegistryTable
          fetching={fetchingReportProjects}
          noDeadline
          columns={columns}
          data={reportProjects.reportProjects}
          tableName="reportProjects"
          report
          tableRef={table}
          tableStyle={tableStyles[0]}
          columnsOneTableRow={columnsOneTableRow}
        />
      )}

      {/* Занятость сотрудников */}

      {/* Если в фильтре выбран отдел развития */}
      {filters.reportFilter.selectedDepartment.id === 6 && (
      <Table
        headerStyle={headerStyle}
        headCellStyle={headCellStyle}
        type={typesForTable}
        noCheckbox
        columns={columnsForTableTasks}
        data={reportTasks.reportTasks}
        tableName={tableNameForTableTasks}
        tableRef={tableTasks}
        tableStyle={tableStyles[1]}
        fetching={fetchingReportTasks}
      />
      )}
      {/* Если в фильтре выбран отдел юристов */}
      {filters.reportFilter.selectedDepartment.id === 1 && (
      <Table
        headerStyle={headerStyle}
        headCellStyle={headCellStyle}
        type={typesForTable}
        noCheckbox
        columns={columnsForTableTasks}
        data={reportTasks.reportTasks}
        tableName={tableNameForTableTasks}
        tableRef={tableTasks}
        tableStyle={tableStyles[1]}
        fetching={fetchingReportTasks}
      />
      )}
      {/* Если в фильтре выбраны остальные отделы */}
      {(filters.reportFilter.selectedDepartment.id !== 6 && filters.reportFilter.selectedDepartment.id !== 1) && (
        <Table
          headerStyle={headerStyle}
          headCellStyle={headCellStyle}
          type={typesForTable}
          noCheckbox
          columns={columnsForTableTasks}
          data={reportTasks.reportTasks}
          tableName={tableNameForTableTasks}
          tableRef={tableTasks}
          tableStyle={tableStyles[1]}
          fetching={fetchingReportTasks}
        />
      )}

      {/* Новости */}
      <Table
        headerStyle={headerStyle}
        cellStyle={{ height: '100%', alignItems: 'start' }}
        type={typesForTable}
        columns={newsColumns}
        data={reportNews.reportNews}
        tableName="reportPageNews"
        tableRef={tableNews}
        noCheckbox
        news
        tableStyle={tableStyles[2]}
        fetching={fetchingReportNews}
      />
    </div>
  );
});
export default ReportPage;
