export const checkPhone = (event) => {
  if (!/[0-9]/.test(event.key) && event.charCode !== 43) {
    event.preventDefault();
    return false;
  }
  return true;
};

export const checkNumber = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const checkSum = (event) => {
  if (!/[0-9,.]/.test(event.key)) {
    event.preventDefault();
  }
};
