import React from 'react';
import { Helmet } from 'react-helmet';

import OneCostPage from 'components/Finance/OneCostPage/OneCostPage';

function CostPage() {
  return (
    <section className="Mysection">
      <Helmet>
        <title> Платежное поручение | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <OneCostPage />
    </section>
  );
}

export default CostPage;
