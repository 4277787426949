// @ts-nocheck
import React, { memo, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import AccordionArrow from 'components/UI/AccordionArrows/AccordionArrow/AccordionArrow';

import {
  ONE_BLOGGER_ROUTE,
  ONE_CLIENT_ROUTE,
  STAFF_CARD_ROUTE,
} from 'utils/consts';

import image from '../../../../assets/icons/Lawyers/TeamsArrow.svg';

import UsersElementItem from './UsersElementItem';

import classes from '../styles/TeamsPage.module.css';

function UsersElement(props) {
  const {
    array, text, client, blogger, update, setUpdate,
  } = props;
  const [active, setActive] = useState(false);

  let route = STAFF_CARD_ROUTE;

  if (client) {
    route = ONE_CLIENT_ROUTE;
  } else if (blogger) {
    route = ONE_BLOGGER_ROUTE;
  }

  useEffect(() => {
    if (array.length > 0) {
      if (update > 0) {
        setActive(true);
        setUpdate(update - 1);
      } else if (update < 0) {
        setUpdate(update + 1);
        setActive(false);
      }
    }
  }, [array]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.team__element} onClick={() => setActive(!active)}>
        <span>{text}</span>

        <AccordionArrow isActive={active && array.length > 0} />
      </div>

      <CSSTransition
        in={active && array.length > 0}
        timeout={400}
        classNames="lawyers-team"
        appear
        unmountOnExit
      >
        <div>
          {array.map((listElement) => (
            <UsersElementItem
              key={listElement.id}
              element={listElement}
              image={image}
              to={route}
              blogger={blogger}
            />
          ))}
        </div>
      </CSSTransition>
    </div>
  );
}

export default memo(UsersElement);
