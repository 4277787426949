import React from 'react';

import { getFileExtension } from 'utils/getFileExtension';

import doc from '../../../assets/icons/FilesIcons/doc.svg';
import heif from '../../../assets/icons/FilesIcons/HEIF.svg';
import jpeg from '../../../assets/icons/FilesIcons/JPEG.svg';
import key from '../../../assets/icons/FilesIcons/KEY.svg';
import pdf from '../../../assets/icons/FilesIcons/pdf.svg';
import png from '../../../assets/icons/FilesIcons/PNG.svg';
import ppt from '../../../assets/icons/FilesIcons/PPT.svg';
import xls from '../../../assets/icons/FilesIcons/xls.svg';
import CancelBtn from '../Btns/CancelBtn';

import classes from './Files.module.css';

function Files(props) {
  const {
    file, deleteFileHandler, style, fileNameStyle,
  } = props;

  let image;
  const extension = getFileExtension(file.name ? file.name : '');

  if (extension === 'doc') {
    image = doc;
  } else if (extension === 'pdf') {
    image = pdf;
  } else if (extension === 'xls') {
    image = xls;
  } else if (extension === 'ppt') {
    image = ppt;
  } else if (extension === 'jpeg') {
    image = jpeg;
  } else if (extension === 'heif') {
    image = heif;
  } else if (extension === 'png') {
    image = png;
  } else if (extension === 'key') {
    image = key;
  }

  return (
    <div className={classes.file} style={style}>
      <img src={image} alt="" />
      <a
        href={file.file}
        download
        className={classes.file__name}
        style={fileNameStyle}
      >
        {file.name ? file.name : 'None'}
      </a>

      {deleteFileHandler && (
        <CancelBtn
          text="x"
          onClick={deleteFileHandler}
          style={{
            padding: '0px 6px',
          }}
        />
      )}
    </div>
  );
}

export default Files;
