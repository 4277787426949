import React, { useState } from 'react';

import { updateNotificationStatus } from 'API/StaffAPI';
import { check } from 'API/UserAPI';

import classes from './MySwitch.module.css';

function MySwitch(props) {
  const {
    fetchUser, id, status, name,
  } = props;
  const [state, setState] = useState(status);

  const changeHandler = () => {
    setState(!state);

    check().then(() => {
      updateNotificationStatus(id, !status, name).then(() => {
        fetchUser();
      });
    });
  };

  return (
    <label className={classes.switch}>
      <input
        type="checkbox"
        className={classes.slider__input}
        checked={state}
        onChange={changeHandler}
      />
      <span className={[classes.slider, classes.round].join(' ')} />
    </label>
  );
}

export default MySwitch;
