import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler';
import close_icon from 'assets/icons/Btns/CloseIconNoBg.svg';

import css from '../PaymentsTable.module.scss';

function HeaderCell({
  id, label, onHideColumn, className,
}) {
  const [isOpen, setOpen] = useState(false);
  return (
    <th className={[css.headerCell, className].join(' ')} onClick={() => setOpen(!isOpen)}>
      <button type="button">{label}</button>

      {/* {isOpen && (
        <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
          <div className={css.hideColumnDropdown}>
            <button type="button" onClick={() => onHideColumn({ id, label })}>
              <img src={close_icon} alt="" />
              Скрыть столбец
            </button>
          </div>
        </OutsideClickHandler>
      )} */}
    </th>
  );
}

export default HeaderCell;
