import { useState } from 'react';

import { useAdd } from 'hooks/useAdd';

import { useStateNew } from './useStateNew';

export const useMultiple = (initualValue) => {
  const [state, , change] = useStateNew(initualValue || []);

  const [selectedList, setSelectedList] = useState([]);
  const [add] = useAdd();

  const addSelected = (element) => {
    add(
      selectedList,
      () => setSelectedList([element]),
      () => setSelectedList([...selectedList, element]),
      element,
    );
  };

  const deleteSelected = (id) => {
    setSelectedList(selectedList.filter((el) => el.id !== id));
  };
  return [
    state,
    change,
    selectedList,
    addSelected,
    deleteSelected,
    setSelectedList,
  ];
};
