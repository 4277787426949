import React from 'react';
import { Helmet } from 'react-helmet';

import ClientLawyers from 'components/General/OneClientPage/OneClientPage';

function ClientPage() {
  return (
    <section className="Mysection">
      <Helmet>
        <title>Клиент | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <ClientLawyers />
    </section>
  );
}

export default ClientPage;
