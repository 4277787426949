import qs from 'qs';

import { $authHost } from 'API';

import { getSum } from 'utils/getSums';
import { getDate } from '../../utils/getDate';

export const fetchLawyerContracts = async (page, limit, controller, filter) => {
  const params = { page, limit };

  if (filter) {
    if (filter.status.length > 0) {
      params.lawyer_status = filter.status;
    }

    if (filter.id) {
      params.project_project_id = Number(filter.id);
    }

    if (filter.id_status?.id === 0) {
      params.o = 'project_id';
    }
    if (filter.id_status?.id === 1) {
      params.o = '-project_id';
    }

    if (filter.marking_data?.id === 0) {
      params.marking_data = false;
    }
    if (filter.marking_data?.id === 1) {
      params.marking_data = true;
    }

    let tempArray = [];
    filter.project.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.project_id = tempArray;
    }

    tempArray = [];
    filter.client_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.client_legal_entity = tempArray;
    }

    tempArray = [];
    filter.our_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.contractor_legal_entity = tempArray;
    }

    tempArray = [];
    filter.responsible.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.manager = tempArray;
    }

    tempArray = [];
    filter.lawyer.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.lawyer = tempArray;
    }

    tempArray = [];
    filter.doc_manager.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.doc_manager = tempArray;
    }

    if (filter.bid_sent_date_start) {
      params.bid_sent_date_after = getDate(filter.bid_sent_date_start);
    }

    if (filter.bid_sent_date_end) {
      params.bid_sent_date_before = getDate(filter.bid_sent_date_end);
    }

    if (filter.contract_sent_date_start) {
      params.sent_date_after = getDate(filter.contract_sent_date_start);
    }

    if (filter.contract_sent_date_end) {
      params.sent_date_before = getDate(filter.contract_sent_date_end);
    }

    if (filter.branch_name) {
      params.post_branch = filter.branch_name;
    }

    if (filter.contract_id) {
      params.number = filter.contract_id;
    }

    if (filter.total_sum) {
      params.total_sum = getSum(filter.total_sum);
    }

    if (filter.payment_date_start) {
      params.payment_dates_after = getDate(filter.payment_date_start);
    }

    if (filter.payment_date_end) {
      params.payment_dates_before = getDate(filter.payment_date_end);
    }

    if (filter.service_start) {
      params.date_after = getDate(filter.service_start);
    }

    if (filter.service_end) {
      params.date_before = getDate(filter.service_end);
    }

    if (filter.service_end_start) {
      params.date_end_after = getDate(filter.service_end_start);
    }

    if (filter.service_end_end) {
      params.date_end_before = getDate(filter.service_end_end);
    }

    if (filter.contract_type.length > 0) {
      params.type = filter.contract_type;
    }

    if (filter.contract_status.length > 0) {
      params.status = filter.contract_status;
    }

    if (filter.closing_document_status.length > 0) {
      params.closed_status = filter.closing_document_status;
    }

    if (filter.receipt_status.length > 0) {
      params.bill_status = filter.receipt_status;
    }

    const indicators = [
      'green',
      'yellow',
      'red',
    ];

    tempArray = [];
    filter.indicator.forEach((item) => tempArray.push(indicators[item]));
    if (tempArray.length > 0) {
      params.indicator = tempArray;
    }
  }

  const { data } = await $authHost.get('/contract_main/contract/', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};

export const getHistoryLawyer = async (id) => {
  const { data } = await $authHost.get(`/contract_card/contract/${id}/history/`);
  return data;
};

export const fetchOneContract = async (id) => {
  const { data } = await $authHost.get(`/contract_card/contract/${id}/`);
  return data;
};

export const fetchOneContractIsCommon = async (id) => {
  const { data } = await $authHost.get(`/contract/${id}/common_legal_entity/`);
  return data;
};

export const fetchOneBloggerContractPublications = async (id) => {
  const { data } = await $authHost.get(`/contract_card/contract/${id}/publications/`);
  return data;
};

export const fetchOneContractPublications = async (id) => {
  const { data } = await $authHost.get(`/contract_card/contract/${id}/bloggers_publications/`);
  return data;
};

export const patchOneContract = async (id, data) => {
  await $authHost.patch(`/contract_card/contract/${id}/`, data);
};

export const patchOneContractPublications = async (id, data) => {
  await $authHost.patch(`/contract_card/contract/${id}/bloggers_publications/`, data);
};

export const postOneContractBankDetails = async (id, data) => {
  await $authHost.post(`/contract_card/contract/${id}/bank_details/`, data);
};

export const postOneContractFiles = async (id, files, options) => {
  const response = await $authHost.post(`/contract_card/contract/${id}/file/`, files, options);
  return response;
};

export const deleteOneContractFiles = async (id) => {
  await $authHost.delete(`/contract_card/contract_file/${id}/`);
};
