// @ts-nocheck
import React from 'react';
import { Helmet } from 'react-helmet';

import ProjectPageMarketing from 'components/Marketing/ProjectPage/ProjectPageMarketing';

function ProjectPage() {
  return (
    <section className="Mysection">
      <Helmet>
        <title> Проект | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <ProjectPageMarketing />
    </section>
  );
}

export default ProjectPage;
