// @ts-nocheck
import React from 'react';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import Files from 'components/UI/Files/Files';
import InputElement from 'components/UI/InputElements/InputElement';
import MyInputFile from 'components/UI/MyInput/MyInputFile';
import Section from 'components/UI/Section/Section';

import classes from '../ProjectPage.module.scss';

function FilesInfo(props) {
  const {
    files, addFileHandler, deleteFileHandler, id, updateProject, links, setLinks, tableStyle,
  } = props;
  return (
    <div style={tableStyle}>
      <Section title="Основная информация ">

        <InputElement
          value={links}
          setValue={setLinks}
          textarea
          placeholder="Введите текст"
          textareaStyle={{ height: 130, maxHeight: 'none', fontSize: 15 }}
        />

        <div className={classes.btn__wrapper}>
          <SuccessBtn
            text="Сохранить"
            style={{ padding: '4px 15px', fontSize: 13 }}
            onClick={() => updateProject({ file_links: links }, 'links')}
          />
        </div>

        <div className={classes.files__container}>
          {files.length > 0 && (
          <div className={classes.files}>
            {files.map((file) => (
              <Files
                style={{ width: '300px', margin: '0px 40px 20px 0px' }}
                fileNameStyle={{ width: '200px' }}
                key={file.id}
                file={file}
                deleteFileHandler={() => deleteFileHandler(file.name, file.id)}
              />
            ))}
          </div>
          )}

          <div>
            <MyInputFile
              onChange={(event) => addFileHandler(event)}
              multiple
              accept=".ppt, .pptx, .xls, .xlsx, .doc, .docx, .jpeg, .jpg, .png, .pdf, .key, .heif"
              htmlFor={`project_page_${id}`}
            />
          </div>
        </div>
      </Section>
    </div>
  );
}

export default FilesInfo;
