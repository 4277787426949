import React from 'react';
import { Helmet } from 'react-helmet';

import OnePaymentStatusPage from 'components/General/OnePaymentStatusPage/OnePaymentStatusPage';

function PaymentStatus() {
  return (
    <section className="Mysection">
      <Helmet>
        <title>Статус оплат | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <OnePaymentStatusPage />
    </section>
  );
}

export default PaymentStatus;
