// @ts-nocheck
import React, { useState } from 'react';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import Files from 'components/UI/Files/Files';
import InputElement from 'components/UI/InputElements/InputElement';
import OneLegalEntityInfo from 'components/UI/LegalEntityInfo/components/OneLegalEntityInfo';
import LawyerSection from 'components/UI/Section/LawyerSection';

import classes from '../ProjectPage.module.scss';

function ClientInfo(props) {
  const {
    element, name, type, contractType, files, payment, setPaymentInfo, save,
  } = props;

  const [activeBtn, setActiveBtn] = useState(false);

  const saveHandler = () => {
    const data = {
      id: payment?.payment?.bank_details.id,
    };

    save(data, 'bank_details').then(() => {
      setActiveBtn(false);
    });
  };

  const cancelHandler = () => {
    setActiveBtn(false);
    // cancel('legal_entity', element);
  };

  return (
    <LawyerSection title={(contractType === 2 || contractType === 4) ? 'Информация об исполнителе' : 'Информация о заказчике'} defaultActive>
      <div className={classes.wrapper}>
        {contractType !== 2 && contractType !== 3 && contractType === 4 && (
        <>
          <InputElement
            text="Наименование"
            textStyle={{ minWidth: 230 }}
            value={name}
            disabled
          />

          <InputElement
            text="Тип*"
            textStyle={{ minWidth: 230 }}
            value={type}
            dropdown={[]}
            disabled
          />
        </>
        )}

        <OneLegalEntityInfo
          element={element}
          legalEntitiesList={[]}
          className={classes.contacts}
          withoutBankDetails
          allDisabled
          noClose
          noDelete
        />

        <div className={classes.miniheader}>
          <div>Банковские реквизиты</div>

          <div className={classes.bank_details__container}>
            <InputElement
              text="р/с"
              value={payment?.payment?.bank_details?.rc || ''}
              textStyle={{ minWidth: 230 }}
              disabled
            />

            <InputElement
              text="Банк"
              value={payment?.payment?.bank_details || {}}
              setValue={(value) => setPaymentInfo({
                ...payment,
                payment: { ...payment.payment, bank_details: value },
              })}
              dropdown={element?.bank_details || []}
              textStyle={{ minWidth: 230 }}
              onClick={() => setActiveBtn(true)}
            />

            <InputElement
              text="БИК"
              value={payment?.payment?.bank_details?.bik || ''}
              textStyle={{ minWidth: 230 }}
              disabled
            />

            <InputElement
              text="к/c"
              value={payment?.payment?.bank_details?.kc || ''}
              textStyle={{ minWidth: 230 }}
              disabled
            />
          </div>

        </div>

        {activeBtn && (
        <div className={classes.btn__wrapper}>
          <SuccessBtn
            text="Сохранить"
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={() => saveHandler()}
          />

          <SuccessBtn
            text="Отменить"
            type="white"
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={() => cancelHandler()}
          />
        </div>
        )}

        <div className={classes.files__container}>
          {files?.length > 0 && (
          <div className={classes.files}>
            {files.map((file) => (
              <Files
                key={file.id}
                file={file}
                style={{ gridTemplateColumns: '40px 1fr' }}
                fileNameStyle={{ width: 100 }}
              />
            ))}
          </div>
          )}
        </div>
      </div>
    </LawyerSection>
  );
}

export default ClientInfo;
