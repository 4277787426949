// @ts-nocheck
import React from 'react';

import OneSocialElement from './OneSocialElement';

import classes from '../SocialNetworks.module.scss';

const OneSocial = React.memo((props) => {
  const {
    social, label, updateSocials, addSocials, deleteSocials, click,
  } = props;

  return (
    <div className={classes.row}>
      <div>{label}</div>

      <div className={classes.row__inner}>
        {social?.map((element, index) => (
          <OneSocialElement
            key={element.id}
            element={element}
            updateSocials={updateSocials}
            addSocials={addSocials}
            deleteSocials={deleteSocials}
            index={index}
            click={click}
          />
        ))}
      </div>
    </div>
  );
});

export default OneSocial;
