// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { patchOnePaymentStatus } from 'API/FinanceAPI/PaymentsAPI';
import {
  fetchOnePaymentStatus,
  fetchOnePaymentStatusExpense,
  fetchOnePaymentStatusIncome,
  fetchOnePaymentStatusStat,
} from 'API/PaymentStatusAPI';
import { check } from 'API/UserAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import PageTitle from 'components/UI/PageTitle/PageTitle';

import PaymentsTable from '../../UI/PaymentsTable/PaymentsTable';
import ProjectMarginBreakdown from '../../UI/ProjectMarginBreakdown/ProjectMarginBreakdown';

import { useLogout } from 'hooks/useLogout';

import { formatedDate, reversDate } from 'utils/getDate';
import { numberToSum } from 'utils/getSums';

const INCOME_HEADERS = [
  { id: 'project_id', label: '№ проекта' },
  { id: 'project_name', label: 'Название проекта' },
  { id: 'client_legal_name', label: 'ЮЛ клиента' },
  { id: 'contract_number', label: '№ договора' },
  { id: 'total_sum', label: 'Общая сумма' },
  { id: 'sum_without_tax', label: 'Сумма без налога/налога на АК' },
  { id: 'paid_sum', label: 'Получено' },
  { id: 'contract_type', label: 'Формат договора' },
  { id: 'contractor_legal_name', label: 'Наше ЮЛ' },
  { id: 'tax', label: 'Налог' },
  { id: 'payment_date', label: 'Порядок оплаты от клиента', special: true },
  { id: 'sum', label: 'Сумма', special: true },
  { id: 'credit', label: 'К доплате по счету' },
  { id: 'actual_month', label: 'Месяц фактического дохода' },
  { id: 'accounting_month', label: 'Месяц учета дохода', special: true },
];

const prepareIncomeData = ({ data, projectInfo }) => data.map((item) => ({
  id: item?.id,
  project_id: projectInfo?.project?.project_id,
  project_name: projectInfo?.project?.name,
  client_legal_name: projectInfo?.client_legal_entity?.legal_name,
  contract_number: projectInfo?.number,
  total_sum: numberToSum(item?.total_sum, true),
  sum_without_tax: numberToSum(item?.sum_without_tax, true),
  paid_sum: numberToSum(item?.paid_sum, true),
  contract_type: item?.contract?.type?.name,
  contractor_legal_name: projectInfo?.contractor_legal_entity?.legal_name,
  tax: projectInfo?.contractor_legal_entity?.tax.toFixed(2),
  payment_dates: item?.payment_dates,
  credit: numberToSum(item?.credit, true),
  accounting_month: reversDate(item?.accounting_month),
  actual_month: reversDate(item?.actual_month),
}));

const EXPENSE_HEADERS = [
  { id: 'expense_type', label: 'Вид расхода (блогер, др)' },
  { id: 'client_sum', label: 'Стоимость для клиента' },
  { id: 'total_sum', label: 'Стоимость для нас, с налогом' },
  { id: 'paid_sum', label: 'Оплачено' },
  { id: 'director', label: 'Руководитель' },
  { id: 'publication_status', label: 'Статус публикации' },
  { id: 'manager', label: 'Менеджер' },
  { id: 'payment_type', label: 'Способ оплаты' },
  { id: 'payment_date', label: 'Порядок оплаты', special: true },
  { id: 'sum', label: 'Сумма', special: true },
  { id: 'actual_month', label: 'Месяц фактического расхода' },
  { id: 'accounting_month', label: 'Месяц учета расхода', special: true },
];

const prepareExpenseData = ({ data, projectInfo }) => data.map((item) => ({
  id: item?.id,
  expense_type: item?.type?.name,
  client_sum: numberToSum(item?.client_sum, true),
  total_sum: numberToSum(item?.total_sum, true),
  margin: numberToSum(item?.margin, true),
  actual_month: reversDate(item?.actual_month),
  paid_sum: numberToSum(item?.paid_sum, true),
  director: item?.project.director.map((d) => d.fullname).filter(Boolean),
  publication_status: item?.contract?.publication_status,
  accounting_month: reversDate(item?.accounting_month),
  manager: item?.project?.manager?.map((m) => m.fullname).filter(Boolean),
  payment_type: item?.payment_method,
  payment_dates: item?.payment_dates,
}));

function OnePaymentStatusPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [logout] = useLogout();

  const [projectLoaded, setProjectLoaded] = useState(false);
  const [projectInfo, setProjectInfo] = useState({});
  const [incomeData, setIncomeData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [paymentStats, setPaymentStats] = useState({});

  const updatePaymentStatus = (row, fieldName, value) => {
    check()
      .then(() => {
        if (fieldName === 'accounting_month') {
          patchOnePaymentStatus(row.id, { [fieldName]: formatedDate(value) });
        }
      })
      .catch((error) => logout(error));
  };

  useEffect(() => {
    check()
      .then(() => {
        fetchOnePaymentStatus(id).then((data) => {
          setProjectInfo(data);
          setProjectLoaded(true);
        });
      })
      .catch((error) => logout(error));
  }, []);

  useEffect(() => {
    if (projectInfo.id) {
      check()
        .then(() => {
          fetchOnePaymentStatusIncome(projectInfo.project.id).then(
            (serverData) => {
              setIncomeData(
                prepareIncomeData({ data: serverData, projectInfo }),
              );
            },
          );

          fetchOnePaymentStatusExpense(projectInfo.project.id).then(
            (serverData) => setExpenseData(
              prepareExpenseData({ data: serverData, projectInfo }),
            ),
          );

          fetchOnePaymentStatusStat(projectInfo.project.id)
            .then((serverData) => setPaymentStats(serverData));
        })
        .catch((error) => logout(error));
    }
  }, [projectInfo]);

  return (
    <>
      <PageTitle>
        <SuccessBtn
          text="Вернуться назад"
          type="white"
          back
          onClick={() => navigate(-1)}
          style={{
            padding: '4px 10px 4px 16px',
            fontSize: '12px',
          }}
        />
        {projectLoaded
          ? `№${projectInfo?.project?.project_id} ${projectInfo?.project?.name}`
          : 'Загрузка...'}
      </PageTitle>

      <ProjectMarginBreakdown {...paymentStats} />

      <section style={{ marginTop: '35px' }}>
        <PaymentsTable
          headers={INCOME_HEADERS}
          data={incomeData}
          updateFieldHandler={updatePaymentStatus}
          title="Детализация доходов по проекту"
        />
        <PaymentsTable
          headers={EXPENSE_HEADERS}
          data={expenseData}
          updateFieldHandler={updatePaymentStatus}
          title="Детализация расходов по проекту"
        />
      </section>
    </>
  );
}

export default OnePaymentStatusPage;
