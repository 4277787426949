import React from 'react';

function Align({
  direction = undefined,
  gap = '0px',
  justifyContent = 'normal',
  alignItems = 'normal',
  width = 'auto',
  height = 'auto',
  className = undefined,
  children,
}) {
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexDirection: direction,
        gap,
        justifyContent,
        alignItems,
        width,
        height,
      }}
    >
      {children}
    </div>
  );
}

export default Align;
