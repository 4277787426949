// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';

import { patchOneCostBid } from 'API/FinanceAPI/CostsAPI';
import { check } from 'API/UserAPI';

import CancelBtn from 'components/UI/Btns/CancelBtn';
import NewAddBtn from 'components/UI/Btns/NewAddBtn';
import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import InputElement from 'components/UI/InputElements/InputElement';
import MyModal from 'components/UI/MyModal/MyModal';

import { useLogout } from 'hooks/useLogout';

import { getSum, getSumRuble, numberToSum } from 'utils/getSums';
import { getDate } from '../../../../utils/getDate';

import ContractElement from '../ModalCreateContract/components/ContractElement';

import classes from './ModalEditPayments.module.scss';

function ModalEditPayments({
  show, onHide, props, fetchCost,
}) {
  const {
    commonInfo,
  } = props;

  const logout = useLogout();

  const defaultPayment = useMemo(() => ({
    date: '',
    sum: '',
    is_done: false,
  }), []);

  const [paymentsDates, setPaymentsDates] = useState([]);

  const addPayment = () => {
    setPaymentsDates([...paymentsDates, { date: '', sum: '', done: false }]);
  };

  const removePayment = (index) => {
    const newPayments = [...paymentsDates];
    newPayments.splice(index, 1);
    setPaymentsDates(newPayments);
  };

  const updatePayment = (index, field, value) => {
    const newPayments = [...paymentsDates];
    newPayments[index][field] = value;

    setPaymentsDates(newPayments);
  };

  const cancelHandler = () => {
    setPaymentsDates([]);
    onHide();
  };

  const savePayments = () => {
    const temp = paymentsDates.map((item) => ({
      ...item,
      date: item.date ? getDate(item.date) : '',
      sum: getSum(item.sum),
    }));
    check()
      .then(() => {
        patchOneCostBid(commonInfo?.id, { payment_dates: temp }).then(() => {
          fetchCost();
        });
      })
      .catch((error) => logout(error));
    onHide();
  };

  useEffect(() => {
    if (commonInfo?.payment_dates.length === 0) {
      setPaymentsDates([defaultPayment]);
    } else {
      commonInfo?.payment_dates.forEach((item) => item.sum = numberToSum(item.sum));
      setPaymentsDates(commonInfo?.payment_dates);
    }
  }, [commonInfo?.payment_dates, defaultPayment]);

  const closeModal = () => {
    onHide();
  };

  return (
    <MyModal show={show} onHide={closeModal} style={{ minHeight: '50vh' }}>
      <SuccessBtn
        text="Вернуться назад"
        type="white"
        back
        onClick={cancelHandler}
        style={{
          padding: '4px 10px 4px 16px',
          marginBottom: '20px',
          fontSize: '12px',
        }}
      />
      <h1 className={classes.new}>Изменение порядка оплат</h1>

      {paymentsDates?.map((payment, index) => (
        <ContractElement
          key={index}
          text={index === 0 ? 'Фактическая оплата' : ''}
          elementClassName={classes.margin_zero}
        >
          <>
            <InputElement
              style={{ color: '#212529', marginLeft: '-40px' }}
              type="date"
              value={payment?.date}
              date
              onChange={(event) => updatePayment(index, 'date', event)}
              disabled={payment.done}
            />

            <InputElement
              style={{
                color: '#212529', height: '32px', width: '230px', marginLeft: '20px', marginBottom: '11px',
              }}
              value={payment?.sum}
              onChange={(e) => updatePayment(index, 'sum', getSumRuble(e.target.value))}
              disabled={payment.done}
            />

            <CancelBtn onClick={() => removePayment(index)} style={{ margin: '7px' }} />
          </>
        </ContractElement>
      ))}

      <NewAddBtn
        className={classes.newButton}
        onClick={addPayment}
        text="Добавить оплату"
      />
      <div className={classes.message}><strong className={classes.boldText}>ОБРАТИТЬ ВНИМАНИЕ!</strong><br />
        <div className={classes.main__message}>
          Данная функция предусмотрена только для форс-мажоров и изменений условий договора за собой не повлечет!<br />
          При увеличении количества траншей, общая сумма оплаты не может быть изменена!<br />
          Любые изменения условий договора (добавить/удалить/увеличить/уменьшить суммы оплаты) необходимо производить через Заявку на документы!
        </div>
      </div>
      <div className={classes.btn__container}>
        <SuccessBtn
          onClick={savePayments}
          text="Сохранить"
          style={{ padding: '8px 70px', fontSize: 13 }}
        />
      </div>
    </MyModal>
  );
}

export default ModalEditPayments;
