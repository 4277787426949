import { useContext } from 'react';
import { Context } from 'index';

export const useLogout = () => {
  const { user, toast } = useContext(Context);

  const logout = (err) => {
    if (err?.response?.status === 400 || err?.response?.status === 401) {
      user.setIsAuth(false);
      localStorage.clear();
    } else {
      toast.setToastInfo('Сервер временно недоступен. Пожалуйста, подождите и повторите запрос');
      toast.setShowError(true);
    }
  };

  return [logout];
};
