// @ts-nocheck
import React from 'react';
import { Spinner } from 'react-bootstrap';

import OneTableRow from '../OneTableRow/OneTableRow';

import RegistryTableHeader from './components/RegistryTableHeader';
import RegistryTableRow from './components/RegistryTableRow';

import classes from './RegistryTable.module.scss';

const TABLE_PARAMS = {
  projects: {
    className: classes.projectsTable,
    headClassName: classes.head__lawyerProjects,
    bodyClassName: classes.body__lawyerProjects,
    rowClassName: classes.row__lawyerProjects,
    cellClassName: classes.cell__lawyerProjects,
    linkTo: '/contract',
    loader: classes.loader_lawyerProjects,
  },
  projects_alternative: {
    className: classes.projectsAlternativeTable,
    headClassName: classes.head__projectsAlternativeTable,
    bodyClassName: classes.body__projectsAlternativeTable,
    rowClassName: classes.row__projectsAlternativeTable,
    cellClassName: classes.cell__projectsAlternativeTable,
    linkTo: '/project',
  },
  projects_alternative_2: {
    className: classes.projectsAlternativeTable2,
    headClassName: classes.head__projectsAlternativeTable2,
    rowClassName: classes.row__projectsAlternativeTable2,
    cellClassName: classes.cell__projectsAlternativeTable2,
    linkTo: '/project',
  },
  projectsManager: {
    className: classes.projectsManager,
    linkTo: '/project',
  },
  costsFinance: {
    className: classes.costsFinance,
    headClassName: classes.head__costsFinance,
    bodyClassName: classes.body__costsFinance,
    rowClassName: classes.row__costsFinance,
    cellClassName: classes.cell__costsFinance,
    linkTo: '/cost',
    loader: classes.loader_costsFinance,
  },
  reportProjects: {
    className: classes.reportProjects,
    headClassName: classes.head__reportProjects,
    bodyClassName: classes.body__reportProjects,
    rowClassName: classes.row__reportProjects,
    cellClassName: classes.cell__reportProjects,
    linkTo: '/project',
    loader: classes.loader_reportProjects,
  },
  projectAccounting: {
    className: classes.projectAccounting,
    headClassName: classes.head__projectAccounting,
    bodyClassName: classes.body__projectAccounting,
    rowClassName: classes.row__projectAccounting,
    cellClassName: classes.cell__projectAccounting,
    linkTo: '/project',
  },

};

function RegistryTable(props) {
  const {
    tableName,
    columns,
    data,
    tableRef,
    style,
    tableStyle,
    manager1,
    marketing,
    lawyers,
    costsFinance,
    report,
    staffCard,
    noDeadline,
    columnsOneTableRow,
    projectAccounting,
    fetching,
  } = props;

  return (
    <div
      className={[classes.table, TABLE_PARAMS[tableName]?.className].join(' ')}
      style={tableStyle}
    >
      <RegistryTableHeader
        columns={columns}
        headClassName={TABLE_PARAMS[tableName]?.headClassName}
        cellClassName={TABLE_PARAMS[tableName]?.cellClassName}
      />

      <div
        className={[classes.body, TABLE_PARAMS[tableName].bodyClassName].join(' ')}
        ref={tableRef}
        style={style}
      >
        {Array.isArray(data)
          && data.map((row, index) => (
            <RegistryTableRow
            // индекс добавлен для того что бы не появлялись лишние строки при фильтрации (Так как было совпадения по айди)
              key={`${row.id}_${index}`}
              columns={columns}
              row={row}
              number={data.length}
              manager1={manager1}
              marketing={marketing}
              lawyers={lawyers}
              costsFinance={costsFinance}
              staffCard={staffCard}
              tableName={tableName}
              linkTo={TABLE_PARAMS[tableName].linkTo}
              rowClassName={TABLE_PARAMS[tableName]?.rowClassName}
              cellClassName={TABLE_PARAMS[tableName]?.cellClassName}
              noDeadline={noDeadline}
              report={report}
              projectAccounting={projectAccounting}
            />
          ))}
        <div style={{ display: 'flex', justifyContent: 'center', height: '45px' }} className={TABLE_PARAMS[tableName]?.loader}>
          {fetching && <Spinner size="md" animation="border" style={{ color: 'gray', display: 'flex', marginTop: '5px' }} />}
        </div>
      </div>

      {tableName === 'reportProjects' && (
      <OneTableRow
        columns={columnsOneTableRow}
        report
        rowName="reportPage"
      />
      )}
    </div>
  );
}

export default RegistryTable;
