import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { addPushDevice } from 'API/WebpushAPI';

const firebaseConfig = {
  apiKey: 'AIzaSyAI0XRQrzClYVpupBlYjIc8fwZVudvJHGs',
  authDomain: 'crm-didenok.firebaseapp.com',
  projectId: 'crm-didenok',
  storageBucket: 'crm-didenok.appspot.com',
  messagingSenderId: '222585874070',
  appId: '1:222585874070:web:a58b7fb1f850cc9b1184b7',
  measurementId: 'G-GD994PESCJ',
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const publicKey = process.env.REACT_APP_VAPID_KEY;

export const getTokenFunc = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: publicKey });

    if (currentToken) {
      addPushDevice(currentToken, 'web');
    }
  } catch (error) {
    console.log('An error occurred while retrieving token.', error);
  }
};

export const onMessageListener = () => new Promise((resolve) => {
  onMessage(messaging, (payload) => {
    resolve(payload);
  });
});
