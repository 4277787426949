import React, { useContext } from 'react';
import { Context } from 'index';

import Files from 'components/UI/Files/Files';
import MyInputFile from 'components/UI/MyInput/MyInputFile';
import Section from 'components/UI/Section/Section';

import classes from '../OneCostPage.module.scss';

function FilesInfo(props) {
  const {
    files, deleteFileHandler, status, addFileHandler,
  } = props;
  const { modal } = useContext(Context);

  const openModalDeleteFile = (name, id) => {
    modal.setModalConfirmVisible(true);
    modal.setModalConfirmProps({
      text: `Вы хотите удалить файл ${name}?`,
      click: () => deleteFileHandler(id),
    });
  };

  return (
    <Section title="Файлы">
      <div className={classes.files__container}>
        {files?.length > 0 && (
          <div className={classes.files}>
            {files.map((file) => (
              <Files
                fileNameStyle={{ width: '100px' }}
                key={file.id}
                file={file}
                deleteFileHandler={() => openModalDeleteFile(file.name, file.id)}
              />
            ))}
          </div>
        )}

        {status !== 3 && (
          <div>
            <MyInputFile
              onChange={(event) => addFileHandler(event)}
              multiple
              accept=".ppt, .pptx, .xls, .xlsx, .doc, .docx, .jpeg, .jpg, .png, .pdf, .key, .heif"
            />
          </div>
        )}
      </div>
    </Section>
  );
}

export default FilesInfo;
