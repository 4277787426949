export const FIRST_STEP_FIELDS = [
  {
    id: 'project_name',
    label: 'Название проекта',
    type: 'string',
    disabled: true,
  },
  {
    id: 'adv_object',
    label: 'Объект рекламирования',
    type: 'string',
    placeholder: 'Товарный знак/название бренда/название продукта...',
  },
  {
    id: 'adv_object_comment',
    label: 'Комментарий',
    type: 'string',
    placeholder: 'Введите текст...',
  },
  {
    id: 'dateTerm',
    label: 'Срок оказания услуг*',
    type: 'doubleDate',
    dates: [{ id: 'date_start' }, { id: 'date_end' }],
  },
];
