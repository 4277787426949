import classes from './ProjectPage.module.scss';

export const stylesForContractElement = { width: '220px', minWidth: '220px' };

export const styleForContractLastElement = {
  width: '220px',
  minWidth: '220px',
  paddingRight: '12px',
};

export const stylesForMyDropdown = {
  border: '1px solid #A29ADE',
  borderRadius: 74,
  padding: '2px 15px 2px 14px',
};

export const columnsForTableMargin = [
  {
    id: 'department',
    label: 'Отдел',
  },
  {
    id: 'team',
    label: 'Руководитель',
  },
  {
    id: 'staff',
    label: 'Сотрудник',
  },
  {
    id: 'sum',
    label: 'Сумма',
  },
  {
    id: 'percent',
    label: '%',
  },
];

export const TABLE_PARAMS_MARGIN = {
  'Корректное распределение маржи': {
    className: classes.correct,
  },
  'Не корректное распределение маржи': {
    className: classes.incorrect,
  },
  'Пустое распределение маржи': {
    className: classes.empty,
  },
};
