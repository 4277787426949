import React, { memo } from 'react';
import { Dropdown } from 'react-bootstrap';

import { TASKS_STATUSES } from 'utils/consts';

import { stylesDropDownMenu } from '../consts/consts';

import classes from './TaskStatusDropdown.module.scss';

function TaskStatusBtn(props) {
  const {
    status,
    id,
    updateFunc,
    disabled,
  } = props;

  let statusButtonColor;
  switch (status?.name) {
    case 'К выполнению':
      statusButtonColor = classes.preparing;
      break;
    case 'В работе':
      statusButtonColor = classes.processing;
      break;
    case 'Готово':
      statusButtonColor = classes.finishing;
      break;
    default:
      statusButtonColor = '';
  }

  const changeTaskStatusHandler = (newStatus) => {
    updateFunc(id, 'status', TASKS_STATUSES[newStatus]);
  };

  return (
    <Dropdown className={classes.dropdown}>
      <Dropdown.Toggle
        disabled={disabled}
        className={`${classes.status__button} ${statusButtonColor
        || classes.loading}`}
      >
        {status?.name || 'Загрузка...'}
      </Dropdown.Toggle>
      <Dropdown.Menu style={stylesDropDownMenu}>
        <Dropdown.Item
          className={`${classes.status__button} ${classes.preparing} ${classes.status__option__button}`}
          onClick={() => changeTaskStatusHandler('preparing')}
        >
          К выполнению
        </Dropdown.Item>
        <Dropdown.Item
          className={`${classes.status__button} ${classes.processing} ${classes.status__option__button}`}
          onClick={() => changeTaskStatusHandler('processing')}
        >
          В работе
        </Dropdown.Item>
        <Dropdown.Item
          className={`${classes.status__button} ${classes.finishing} ${classes.status__option__button}`}
          onClick={() => changeTaskStatusHandler('finishing')}
        >
          Готово
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default memo(TaskStatusBtn);
