// @ts-nocheck
import React, {
  useContext,
} from 'react';
import { Context } from 'index';

import { postLegalEntity } from 'API/LegalEntityAPI';
import { check } from 'API/UserAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import MyModal from 'components/UI/MyModal/MyModal';

import { useLogout } from 'hooks/useLogout';
import { useStateNew2 } from '../../../../hooks/useStateNew2';

import { defaultLegalEntitiesWithId } from 'utils/consts';

import LegalEntityInfo from '../../LegalEntityInfo/LegalEntityInfo';

import classes from './ModalCreateEntity.module.scss';

function ModalCreateEntity({ show, onHide }) {
  const { toast } = useContext(Context);
  const [logout] = useLogout();

  const [
    legalEntities,
    setLegalEntities,
    updateLegalEntities,
    addLegalEntities,
  ] = useStateNew2([defaultLegalEntitiesWithId]);

  const closeModal = () => {
    setTimeout(() => {
      setLegalEntities([defaultLegalEntitiesWithId]);
    }, 140);
    onHide();
  };

  const createEntity = () => {
    legalEntities[0].bank_details.forEach((el) => delete el.id);

    const data = {
      type: { id: legalEntities[0].type.id },
      legal_name: legalEntities[0].legal_name,
      passport_details: legalEntities[0].passport_details,
      inn: legalEntities[0].inn,
      ogrn: legalEntities[0].ogrn,
      ogrnip: legalEntities[0].ogrnip,
      kpp: legalEntities[0].kpp,
      address: legalEntities[0].address,
      file_links: legalEntities[0].file_links,
      bank_details: legalEntities[0].bank_details,
    };

    check()
      .then(() => {
        postLegalEntity(data)
          .then(() => {
            closeModal();
            toast.setShowSuccess(true);
            toast.setToastInfo('Новое юридическое лицо создано');
          })
          .catch((error) => {
            if (error.response.data.contacts) {
              toast.setToastInfo('Введите правильный email');
              toast.setShowError(true);
            } else if (error.response.data.type) {
              toast.setToastInfo('Выберите форму ЮЛ');
              toast.setShowError(true);
            } else if (error?.response?.data[0]) {
              toast.setToastInfo(error.response.data[0]);
              toast.setShowError(true);
            } else {
              toast.setToastInfo('Обратитесь в техподдержку');
              toast.setShowError(true);
            }
          });
      })
      .catch((error) => logout(error));
  };

  return (
    <MyModal show={show} onHide={closeModal} style={{ minHeight: '60vh' }}>
      <h1 className={classes.new}>Новое юридическое лицо</h1>

      <div className={classes.wrapper}>
        <LegalEntityInfo
          legalEntities={legalEntities}
          setLegalEntities={setLegalEntities}
          updateLegalEntities={updateLegalEntities}
          addLegalEntities={addLegalEntities}
          sectionClassName={classes.section_header}
          titleClassName={classes.section_title}
          contentClassName={classes.contacts}
          className={classes.contacts}
          noDelete
          noClose
          noAddLegalEntity
        />
      </div>

      <div className={classes.btn__container}>
        <SuccessBtn
          text="Добавить юридическое лицо"
          style={{ padding: '4px 15px', fontSize: 13 }}
          onClick={() => createEntity()}
        />
      </div>
    </MyModal>
  );
}

export default ModalCreateEntity;
