import qs from 'qs';

import { $authHost } from 'API';

import { getDate } from 'utils/getDate';
import { getSum } from 'utils/getSums';

export const getCostBids = async (page, limit, controller, filter, finance) => {
  const params = { page, limit };

  if (filter) {
    if (filter.status.length > 0) {
      if (finance) {
        params.financial_status = filter.status;
      } else {
        params.status = filter.status;
      }
    }

    if (filter.id) {
      params.project_project_id = Number(filter.id);
    }

    if (filter.payment_id) {
      params.id = Number(filter.payment_id);
    }

    let tempArray = [];
    filter.project?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.project_id = tempArray;
    }

    tempArray = [];
    filter.selectedClientLegalEntityList?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.client_legal_entity = tempArray;
    }

    tempArray = [];
    filter.selectedOurLegalEntityList?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.contractor_legal_entity = tempArray;
    }

    tempArray = [];
    filter.responsible?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.responsible = tempArray;
    }

    tempArray = [];
    filter.selectedDocumentList?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.doc_manager = tempArray;
    }

    tempArray = [];
    filter?.type?.forEach((item) => {
      if (item.id === 0) {
        tempArray.push(true);
      } else {
        tempArray.push(false);
      }
    });
    if (tempArray.length > 0) {
      params.is_contract = tempArray;
    }

    tempArray = [];
    filter?.selectedContractStatusList?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.contract_status = tempArray;
    }

    if (filter.id_contract) {
      params.contract_number = filter.id_contract;
    }

    if (filter.date_bid_sent_start) {
      params.sent_date_after = getDate(filter.date_bid_sent_start);
    }

    if (filter.date_bid_sent_end) {
      params.sent_date_before = getDate(filter.date_bid_sent_end);
    }

    if (filter.payment_date_start) {
      params.payment_dates_after = getDate(filter.payment_date_start);
    }

    if (filter.payment_date_end) {
      params.payment_dates_before = getDate(filter.payment_date_end);
    }

    if (filter?.indicators?.id === 0) {
      params.indicator = 'red';
    }
  }

  const { data } = await $authHost.get('/payment_main/payment/', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return data;
};

export const getOneCostBid = async (id) => {
  const { data } = await $authHost.get(`payment_card/payment/${id}/`);
  return data;
};

export const patchOneCostBid = async (id, data) => {
  await $authHost.patch(`payment_card/payment/${id}/`, data);
};

export const deleteOneCostBid = async (id) => {
  await $authHost.delete(`payment_card/payment/${id}/`);
};

export const deleteOneCostFile = async (id) => {
  await $authHost.delete(`/payment_card/payment_file/${id}/`);
};

export const exportGoogleCostsTable = async (
  page,
  limit,
  controller,
  filter,
  columnsList,
  finance,
) => {
  const params = { page, limit };

  const columnsListTempArray = [];
  columnsList?.forEach((item) => (finance && item === 'status' ? columnsListTempArray.push('financial_status') : columnsListTempArray.push(item)));

  if (columnsListTempArray.length > 0) {
    params.fields = columnsListTempArray;
  }

  if (filter) {
    // Массив для хранения сортировок по возрастанию/убыванию
    const sortArray = [];

    if (filter.payment_id) {
      params.id = Number(filter.payment_id);
    }

    if (filter.status.length > 0) {
      if (finance) {
        params.financial_status = filter.status;
      } else {
        params.status = filter.status;
      }
    }

    if (filter.id) {
      params.project_project_id = Number(filter.id);
    }

    if (filter.id_status?.id === 0) {
      sortArray.push('project_id');
    }
    if (filter.id_status?.id === 1) {
      sortArray.push('-project_id');
    }

    let tempArray = [];
    filter.project?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.project_id = tempArray;
    }

    tempArray = [];
    filter?.type?.forEach((item) => {
      if (item.id === 0) {
        tempArray.push(true);
      } else {
        tempArray.push(false);
      }
    });
    if (tempArray.length > 0) {
      params.is_contract = tempArray;
    }

    tempArray = [];
    filter.client_legal_entity?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.client_legal_entity = tempArray;
    }

    tempArray = [];
    filter.our_legal_entity?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.contractor_legal_entity = tempArray;
    }

    tempArray = [];
    filter.responsible?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.responsible = tempArray;
    }

    tempArray = [];
    filter.lawyer?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.lawyer = tempArray;
    }

    tempArray = [];
    filter.doc_manager?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.doc_manager = tempArray;
    }

    tempArray = [];
    filter.selectedDocumentList?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.doc_manager = tempArray;
    }

    tempArray = [];
    filter.selectedClientLegalEntityList?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.client_legal_entity = tempArray;
    }

    tempArray = [];
    filter.selectedOurLegalEntityList?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.contractor_legal_entity = tempArray;
    }

    if (filter.date_bid_sent_start) {
      params.sent_date_after = getDate(filter.date_bid_sent_start);
    }

    if (filter.date_bid_sent_end) {
      params.sent_date_before = getDate(filter.date_bid_sent_end);
    }

    if (filter?.deadline_status?.id === 0) {
      sortArray.push('bid_sent_date');
    } else if (filter?.deadline_status?.id === 1) {
      sortArray.push('-bid_sent_date');
    }

    if (filter?.is_contract_sent?.id === 0) {
      params.is_sent = false;
    } else if (filter?.is_contract_sent?.id === 1) {
      params.is_sent = true;
    }

    if (filter.contract_sent_date_start) {
      params.sent_date = getDate(filter.contract_sent_date_start);
    }

    if (filter.contract_sent_date_end) {
      params.sent_date = getDate(filter.contract_sent_date_end);
    }

    if (filter?.contract_sent_date_deadline?.id === 0) {
      sortArray.push('sent_date');
    } else if (filter?.contract_sent_date_deadline?.id === 1) {
      sortArray.push('-sent_date');
    }

    if (filter.branch_name) {
      params.post_branch = filter.branch_name;
    }

    if (filter.id_contract) {
      params.contract_number = filter.id_contract;
    }

    if (filter.contract_sum) {
      params.total_sum = getSum(filter.contract_sum);
    }

    if (filter.ak_sum) {
      params.ak_sum = getSum(filter.ak_sum);
    }

    if (filter.payment_date_start) {
      params.payment_dates_after = getDate(filter.payment_date_start);
    }

    if (filter.payment_date_end) {
      params.payment_dates_before = getDate(filter.payment_date_end);
    }

    if (filter.service_start) {
      params.date_after = getDate(filter.service_start);
    }

    if (filter.service_end) {
      params.date_before = getDate(filter.service_end);
    }

    if (filter.service_end_start) {
      params.date_end_after = getDate(filter.service_end_start);
    }

    if (filter.service_end_end) {
      params.date_end_before = getDate(filter.service_end_end);
    }

    tempArray = [];
    filter?.selectedContractStatusList?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.contract_status = tempArray;
    }

    tempArray = [];
    filter.contract_status?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.status = tempArray;
    }

    tempArray = [];
    filter.closed_status?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.closed_status = tempArray;
    }

    tempArray = [];
    filter.bill_status?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.bill_status = tempArray;
    }

    if (filter?.is_adesk?.id === 0) {
      params.is_adesk = false;
    } else if (filter?.is_adesk?.id === 1) {
      params.is_adesk = true;
    }

    if (filter?.is_case?.id === 0) {
      params.is_case = false;
    } else if (filter?.is_case?.id === 1) {
      params.is_case = true;
    }

    tempArray = [];
    filter.project_manager_status?.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.project_manager_status = tempArray;
    }

    if (filter.date_take_documents_start) {
      params.collection_date_after = getDate(filter.date_take_documents_start);
    }

    if (filter.date_take_documents_end) {
      params.collection_date_before = getDate(filter.date_take_documents_end);
    }

    tempArray = [];
    filter.quarter_start?.forEach((item) => tempArray.push(item.label));
    if (tempArray.length > 0) {
      params.quarter_start = tempArray;
    }

    tempArray = [];
    filter.quarter_end?.forEach((item) => tempArray.push(item.label));
    if (tempArray.length > 0) {
      params.quarter_end = tempArray;
    }

    tempArray = [];
    filter.quarter_closing?.forEach((item) => tempArray.push(item.label));
    if (tempArray.length > 0) {
      params.quarter_closing = tempArray;
    }

    params.o = sortArray;
  }

  const response = await $authHost.get('/payment_main/export', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return response.data;
};
