import React, {
  memo, useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { Dropdown } from 'react-bootstrap';
import { Context } from 'index';

import { patchClosingInfoProjectAccounting } from 'API/FinanceAPI/ProjectAccounting';

import {
  checkFormat, getCurrentQuarter, getNextFiveQuarters, getQuarter,
} from 'utils/getCurrentQuarter';

import classes from './QuarterDropdown.module.scss';

function QuarterDropdown({
  initialQuarter, row, setComments, setIndicateRed, setAdditionalRowIndicateRed, setAdditionalRowComments, closingInfo, status,
}) {
  const { user } = useContext(Context);
  const rootEl = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [selectedQuarter, setSelectedQuarter] = useState('-');
  const isFinance = (user.user.role.id === 2 || user.user.department.id === 2 || user.user.department.id === 3);
  const isNotApply = status !== 2;
  const isApply = status === 2;

  useEffect(() => {
    if (isApply) {
      if (checkFormat(initialQuarter) !== '-') {
        setSelectedQuarter(initialQuarter);
      } else if (row?.date_end) {
        setSelectedQuarter(getQuarter(row?.date_end));
      } else if (closingInfo?.quarter) {
        setSelectedQuarter(closingInfo?.quarter);
      } else {
        setSelectedQuarter(getCurrentQuarter);
      }
    }
  }, [initialQuarter, closingInfo, row?.accounting_status?.id, status, isApply, row?.date_end]);

  useEffect(() => {
    const onClick = (e) => {
      if (rootEl.current) {
        rootEl.current.contains(e.target) || setIsActive(false);
      }
    };
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  const handleSelect = (e, quarter) => {
    e.preventDefault();
    e.stopPropagation();
    patchClosingInfoProjectAccounting(!closingInfo ? row?.closing_info[0]?.id : closingInfo?.id, { quarter })
      .then((response) => {
        if (!closingInfo) {
          setComments((prevComments) => [...prevComments, 'Изменение поля Квартал']);
          setIndicateRed(true);
        } else {
          setAdditionalRowComments((prevComments) => [...prevComments, 'Изменение поля Квартал']);
          setAdditionalRowIndicateRed(true);
        }
      })
      .catch((error) => {
        console.error('Ошибка при обновлении: ', error);
      });

    setSelectedQuarter(quarter);
    setIsActive(false);
  };

  const toggleDropdown = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsActive(!isActive);
  }, [isActive]);

  return (
    <div ref={rootEl}>
      <button
        type="button"
        className={`${classes.quarter} ${(isNotApply || !isFinance) && classes.disabled}`}
        onClick={toggleDropdown}
        disabled={isNotApply || !isFinance}
      >
        {isApply ? selectedQuarter : '-'}
      </button>

      <Dropdown.Menu
        className={['my__dropdown__inner__alt', isActive && classes.newDropdown].join(' ')}
        show={isActive}
        align="start"
      >
        {getNextFiveQuarters().map((quarter, index) => (
          <div
            className={classes.quarter}
            key={index}
            onClick={(e) => handleSelect(e, quarter)}
          >
            {quarter}
          </div>
        ))}
      </Dropdown.Menu>
    </div>
  );
}

export default memo(QuarterDropdown);
