// @ts-nocheck
import { makeAutoObservable } from 'mobx';

export default class ToastStore {
  constructor() {
    this._showSuccess = false;
    this._toastTitle = '';
    this._showError = false;
    this._toastInfo = '';

    makeAutoObservable(this);
  }

  setShowSuccess(boolean) {
    this._showSuccess = boolean;
  }

  setShowError(boolean) {
    this._showError = boolean;
  }

  setToastTitle(text) {
    this._toastTitle = text;
  }

  setToastInfo(text) {
    this._toastInfo = text;
  }

  get showSuccess() {
    return this._showSuccess;
  }

  get showError() {
    return this._showError;
  }

  get toastInfo() {
    return this._toastInfo;
  }

  get toastTitle() {
    return this._toastTitle;
  }
}
