import React from 'react';

import classes from './ClientString.module.css';

function ClientString(props) {
  const {
    text, count, all, style,
  } = props;
  return (
    <div
      className={all ? classes.allClients__inner : classes.oneClient__inner}
      style={style}
    >
      <div>{text}</div>
      <div>{count}</div>
    </div>
  );
}

export default ClientString;
