import React from 'react';

import classes from './NewsComponent.module.scss';

function NewsComponent(props) {
  const { value, style } = props;
  return (
    <div className={classes.newsComponent} style={style}>
      {value}
    </div>
  );
}

export default NewsComponent;
