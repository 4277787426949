export const getFileExtension = (filename) => {
  const result = filename.split('.').pop().toLowerCase();

  if (result === 'pdf') {
    return 'pdf';
  } if (result === 'doc' || result === 'docx') {
    return 'doc';
  } if (result === 'xls' || result === 'xlsx') {
    return 'xls';
  } if (result === 'ppt' || result === 'pptx') {
    return 'ppt';
  } if (result === 'jpeg' || result === 'jpg') {
    return 'jpeg';
  } if (result === 'heif') {
    return 'heif';
  } if (result === 'png') {
    return 'png';
  } if (result === 'key') {
    return 'key';
  }
};
