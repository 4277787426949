import React from 'react';
import { Helmet } from 'react-helmet';

import MainLawyers from 'components/Lawyers/MainLawyers/MainLawyers';

function Contracts() {
  return (
    <section className="Mysection">
      <Helmet>
        <title>Документы | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <MainLawyers />
    </section>
  );
}

export default Contracts;
