export const FIELDS = [
  { id: 'passport_details', label: 'Паспорт' },
  { id: 'address', label: 'Адрес регистрации' },
];
export const OGRNIP_FIELDS = [
  { id: 'ogrnip', label: 'ОГРНИП' },
  { id: 'address', label: 'Юр.адрес' },
];

export const OGRN_FIELDS = [
  { id: 'ogrn', label: 'ОГРН' },
  { id: 'kpp', label: 'КПП' },
  { id: 'address', label: 'Юр.адрес' },
];

export const BANK_FIELDS = [
  { id: 'rc', label: 'р/с', delete: true },
  { id: 'bank', label: 'Банк' },
  { id: 'bik', label: 'БИК' },
  { id: 'kc', label: 'к/с' },
];
