import qs from 'qs';

import { $authHost } from 'API';

import { getDate } from 'utils/getDate';
import { getSum } from 'utils/getSums';

export const getProjectsAccountingTable = async (page, limit, controller, filter) => {
  const params = { page, limit };

  if (filter) {
    if (filter.status.length > 0) {
      params.accounting_status = filter.status;
    }

    if (filter.id) {
      params.project_project_id = Number(filter.id);
    }

    let tempArray = [];
    filter.project.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.project_id = tempArray;
    }

    tempArray = [];
    filter.client_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.client_legal_entity = tempArray;
    }

    tempArray = [];
    filter.our_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.contractor_legal_entity = tempArray;
    }

    tempArray = [];
    filter.director.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.director = tempArray;
    }

    tempArray = [];
    filter.manager.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.manager = tempArray;
    }

    tempArray = [];
    filter.responsible.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.manager = tempArray;
    }

    if (filter.date_start_after) {
      params.date_start_after = getDate(filter.date_start_after);
    }

    if (filter.date_start_before) {
      params.date_start_before = getDate(filter.date_start_before);
    }

    if (filter.date_end_after) {
      params.date_end_after = getDate(filter.date_end_after);
    }

    if (filter.date_end_before) {
      params.date_end_before = getDate(filter.date_end_before);
    }

    tempArray = [];
    filter.contract_format_gl.forEach((item) => {
      if (item.id === 0) {
        tempArray.push(0, 1);
      } else if (item.id === 1 || item.id === 5) {
        tempArray.push(2);

        if (item.id === 5) params.is_contract = false;
      } else if (item.id === 2) {
        tempArray.push(4);
      } else if (item.id === 3) {
        tempArray.push(7);
      } else {
        tempArray.push(8);
      }
    });

    if (tempArray.length > 0) {
      params.type = tempArray;
    }

    if (filter.payment_date_start) {
      params.payment_dates_after = getDate(filter.payment_date_start);
    }

    if (filter.payment_date_end) {
      params.payment_dates_before = getDate(filter.payment_date_end);
    }

    if (filter?.is_adesk?.id === 0) {
      params.adesk = false;
    } else if (filter?.is_adesk?.id === 1) {
      params.adesk = true;
    }

    if (filter?.indicators?.id === 0) {
      params.indicator = 'red';
    } else if (filter?.indicators?.id === 1) {
      params.indicator = 'yellow';
    }

    if (filter.month_date_start) {
      params.accounting_month_after = getDate(filter.month_date_start);
    }

    if (filter.month_date_end) {
      params.accounting_month_before = getDate(filter.month_date_end);
    }

    tempArray = [];
    filter.quarter.forEach((item) => tempArray.push(item.label));
    if (tempArray.length > 0) {
      params.quarter = tempArray;
    }
  }

  const response = await $authHost.get('/project_accounting/contract/', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return response.data;
};

export const patchClosingInfoProjectAccounting = async (id, data) => {
  const response = await $authHost.patch(`/project_accounting/closing_info/${id}/`, data);
  return response.data;
};

export const patchMainRowProjectAccounting = async (id, data) => {
  const response = await $authHost.patch(`/project_accounting/contract/${id}/`, data);
  return response;
};

export const patchContractAccounting = async (id, data) => {
  const response = await $authHost.patch(`/project_accounting/contract/${id}/`, data);
  return response.data;
};

export const postIncomeDatesAccounting = async (id, data) => {
  const response = await $authHost.post(`/project_accounting/income_date/${id}/`, data);
  return response.data;
};

export const exportGoogleRegistryTableProjectAccounting = async (
  page,
  limit,
  controller,
  filter,
  columnsList,
) => {
  const params = { page, limit };

  const tempArray = [];
  columnsList?.forEach((item) => tempArray.push(item));
  if (tempArray.length > 0) {
    params.fields = tempArray;
  }

  if (filter) {
    if (filter.status.length > 0) {
      params.accounting_status = filter.status;
    }

    if (filter.id) {
      params.project_project_id = Number(filter.id);
    }

    let tempArray = [];
    filter.project.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.project_id = tempArray;
    }

    tempArray = [];
    filter.client_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.client_legal_entity = tempArray;
    }

    tempArray = [];
    filter.our_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.contractor_legal_entity = tempArray;
    }

    tempArray = [];
    filter.director.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.director = tempArray;
    }

    tempArray = [];
    filter.manager.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.manager = tempArray;
    }

    tempArray = [];
    filter.responsible.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.manager = tempArray;
    }

    if (filter.period_date_start) {
      params.date_start_after = getDate(filter.period_date_start);
    }

    if (filter.period_date_end) {
      params.date_end_before = getDate(filter.period_date_end);
    }

    tempArray = [];
    filter.contract_format_gl.forEach((item) => {
      if (item.id === 0) {
        tempArray.push(0, 1);
      } else if (item.id === 1 || item.id === 5) {
        tempArray.push(2);

        if (item.id === 5) params.is_contract = false;
      } else if (item.id === 2) {
        tempArray.push(4);
      } else if (item.id === 3) {
        tempArray.push(7);
      } else {
        tempArray.push(8);
      }
    });

    if (tempArray.length > 0) {
      params.type = tempArray;
    }

    if (filter.payment_date_start) {
      params.payment_dates_after = getDate(filter.payment_date_start);
    }

    if (filter.payment_date_end) {
      params.payment_dates_before = getDate(filter.payment_date_end);
    }

    if (filter?.is_adesk?.id === 0) {
      params.adesk = false;
    } else if (filter?.is_adesk?.id === 1) {
      params.adesk = true;
    }

    if (filter.month_date_start) {
      params.accounting_month_after = getDate(filter.month_date_start);
    }

    if (filter.month_date_end) {
      params.accounting_month_before = getDate(filter.month_date_end);
    }

    tempArray = [];
    filter.quarter.forEach((item) => tempArray.push(item.label));
    if (tempArray.length > 0) {
      params.quarter = tempArray;
    }
  }

  const response = await $authHost.get('/project_accounting/export/', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return response.data;
};
