// @ts-nocheck
import { makeAutoObservable } from 'mobx';

export default class ReportProjectStore {
  constructor() {
    this._reportProjects = [];
    this._reportProjectsTotalCount = 0;
    this._reportProjectsPage = 1;
    this._reportProjectsLimit = 15;
    this._reportProjectsFilter = undefined;
    this._reportTotalCreditSum = 0;
    this._reportTotalMarginSum = 0;
    this._reportTotalPaidSum = 0;
    this._reportTotalSum = 0;

    this._modalReportProjects = [];
    this._modalReportProjectsTotalCount = 0;
    this._modalReportProjectsPage = 1;
    this._modalReportProjectsLimit = 15;
    this._modalReportProjectsFilter = undefined;

    makeAutoObservable(this);
  }

  setReportProjects(reportProjects, update) {
    if (update) {
      return (this._reportProjects = reportProjects);
    }

    if (this._reportProjectsPage === 1) {
      return (this._reportProjects = reportProjects);
    }

    reportProjects.forEach((reportProject) => this._reportProjects.push(reportProject));
  }

  setReportProjectsTotalCount(count) {
    this._reportProjectsTotalCount = count;
  }

  setReportProjectsPage(page) {
    this._reportProjectsPage = page;
  }

  setReportProjectsFilter(filter) {
    this._reportProjectsFilter = filter;
  }

  setReportTotalCreditSum(num) {
    this._reportTotalCreditSum = num;
  }

  setReportTotalMarginSum(num) {
    this._reportTotalMarginSum = num;
  }

  setReportTotalPaidSum(num) {
    this._reportTotalPaidSum = num;
  }

  setReportTotalSum(num) {
    this._reportTotalSum = num;
  }

  setModalReportProjects(reportProjects, update) {
    if (update) {
      return (this._modalReportProjects = reportProjects);
    }

    if (this._modalReportProjectsPage === 1) {
      return (this._modalReportProjects = reportProjects);
    }

    reportProjects.forEach((reportProject) => this._modalReportProjects.push(reportProject));
  }

  setModalReportProjectsTotalCount(count) {
    this._modalReportProjectsTotalCount = count;
  }

  setModalReportProjectsPage(page) {
    this._modalReportProjectsPage = page;
  }

  setModalReportProjectsFilter(filter) {
    this._modalReportProjectsFilter = filter;
  }

  get reportProjects() {
    return this._reportProjects;
  }

  get reportProjectsTotalCount() {
    return this._reportProjectsTotalCount;
  }

  get reportProjectsPage() {
    return this._reportProjectsPage;
  }

  get reportProjectsLimit() {
    return this._reportProjectsLimit;
  }

  get reportProjectsFilter() {
    return this._reportProjectsFilter;
  }

  get reportTotalCreditSum() {
    return this._reportTotalCreditSum;
  }

  get reportTotalMarginSum() {
    return this._reportTotalMarginSum;
  }

  get reportTotalPaidSum() {
    return this._reportTotalPaidSum;
  }

  get reportTotalSum() {
    return this._reportTotalSum;
  }

  get modalReportProjects() {
    return this._modalReportProjects;
  }

  get modalReportProjectsTotalCount() {
    return this._modalReportProjectsTotalCount;
  }

  get modalReportProjectsPage() {
    return this._modalReportProjectsPage;
  }

  get modalReportProjectsLimit() {
    return this._modalReportProjectsLimit;
  }

  get modalReportProjectsFilter() {
    return this._modalReportProjectsFilter;
  }
}
