// @ts-nocheck
import React from 'react';

import OneCell from './OneCell';

import classes from './OneTableRow.module.scss';

const ROW_PARAMS = {
  mainManager: {
    className: classes.mainManager,
  },
  mainLawyer: {
    className: classes.mainLawyer,
  },
  mainLawyerSum: {
    bodyClassName: classes.body__mainLawyerSum,
    className: classes.mainLawyerSum,
  },
  oneBloggerPage: {
    bodyClassName: classes.body__oneBloggerPage,
    className: classes.oneBloggerPage,
  },
  oneClientPage: {
    bodyClassName: classes.body__oneClientPage,
    className: classes.oneClientPage,
  },
  reportPage: {
    bodyClassName: classes.body__reportProjects,
    className: classes.reportPage,
  },
  projectPageMargin: {
    className: classes.projectPageMargin,
  },
  projectPageEstimate: {
    className: classes.projectPageEstimate,
  },
};

const OneTableRow = React.memo((props) => {
  const {
    rowName, columns, style, rowStyle,
  } = props;

  const classNames = [classes.row, ROW_PARAMS[rowName].className].join(' ');

  return (
    <div
      className={[classes.body, ROW_PARAMS[rowName].bodyClassName].join(' ')}
      style={style}
    >
      <div className={classNames} style={rowStyle}>
        {columns?.map((column, index) => <OneCell key={index} column={column} rowName={rowName} />)}
      </div>
    </div>
  );
});

export default OneTableRow;
