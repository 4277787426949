import React from 'react';

import classes from './DepartmentAccordionArrow.module.scss';

function DepartmentAccordionArrow(props) {
  const {
    isActive, onClick, style, arrowStyle,
  } = props;
  return (
    <div className={classes.arrow__button__container}>
      <div className={classes.arrow__button__text}>
        {isActive ? 'Свернуть' : 'Развернуть'}
      </div>
      <div
        className={
        isActive
          ? [classes.arrow__button, classes.active].join(' ')
          : classes.arrow__button
      }
        onClick={onClick}
        style={style}
      >
        <div className={classes.arrow} style={arrowStyle} />
      </div>
    </div>
  );
}

export default DepartmentAccordionArrow;
