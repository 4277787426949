import qs from 'qs';

import { $authHost } from 'API';

import { getDate } from '../../utils/getDate';

export const fetchReportLawyersNews = async (page, limit, controller, filter) => {
  const params = { page, limit };

  if (filter) {
    if (filter.periodStart) {
      params.created_after = getDate(filter.periodStart);
    }

    if (filter.periodEnd) {
      params.created_before = getDate(filter.periodEnd);
    }

    if (Number.isInteger(filter.selectedDepartment.id)) {
      params.department = filter.selectedDepartment.id;
    }

    let tempArray = [];
    filter?.selectedDirectorList?.forEach((grouphead) => tempArray.push(grouphead.id));
    if (tempArray.length > 0) {
      params.grouphead = tempArray;
    }

    tempArray = [];
    filter?.selectedResponsibleList?.forEach((person) => tempArray.push(person.id));
    if (tempArray.length > 0) {
      params.staff = tempArray;
    }
  }

  const { data } = await $authHost.get('report_page/news', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return data;
};
