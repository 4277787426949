import React from 'react';
import { Helmet } from 'react-helmet';

import OneBloggerPage from 'components/General/OneBloggerPage/OneBloggerPage';

function BloggerPage() {
  return (
    <section className="Mysection">
      <Helmet>
        <title>Блогер | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <OneBloggerPage />
    </section>
  );
}

export default BloggerPage;
