// @ts-nocheck
import React from 'react';

import MyModal from 'components/UI/MyModal/MyModal';

import SuccessBtn from '../../Btns/SuccessBtn';

import classes from './ModalConfirm.module.css';

function ModalConfirm({ show, onHide, props }) {
  const {
    click, closeClick, text, save,
  } = props;

  const send = () => {
    click();
    onHide();
  };

  const close = () => {
    if (closeClick) {
      closeClick();
    }

    onHide();
  };
  return (
    <MyModal show={show} onHide={onHide} type="confirm" noClose>
      <div className={classes.text}>{text}</div>

      <div className={classes.btns}>
        <SuccessBtn
          text={save ? 'Сохранить' : 'Да'}
          style={{
            padding: '2px 20px',
            fontSize: 13,
          }}
          onClick={() => send()}
        />

        <SuccessBtn
          text={save ? 'Отменить' : 'Нет'}
          type="white"
          style={{
            padding: '2px 20px',
            fontSize: 13,
          }}
          onClick={() => close()}
        />
      </div>
    </MyModal>
  );
}
export default ModalConfirm;
