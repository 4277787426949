// @ts-nocheck
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
// Utils
import { Context } from '../../../index';

import {
  deleteBloggerFile,
  postBloggerFiles,
} from 'API/BloggerAPI';
import { fetchOneLegalEntities, patchOneEntity } from 'API/LegalEntityAPI';
import { check } from 'API/UserAPI';

import NewAddBtn from 'components/UI/Btns/NewAddBtn';
// Components
import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import BankInfo from 'components/UI/Modals/ModalCreateEntity/components/BankInfo';
import MainInfo from 'components/UI/Modals/ModalCreateEntity/components/MainInfo';
import PageTitle from 'components/UI/PageTitle/PageTitle';
import Section from 'components/UI/Section/Section';
import UploadFilesContainer from 'components/UI/UploadFilesContainer/UploadFilesContainer';

import ModalConfirm from '../../UI/Modals/ModalConfirm/ModalConfirm';

// Hooks
import { useLogout } from 'hooks/useLogout';
import { useProgress } from 'hooks/useProgress';

import classes from './OneLegalEntityPage.module.scss';

const OneLegalEntityPage = observer(() => {
  const {
    modal, projects, user, toast,
  } = useContext(Context);
  const { id } = useParams();
  const navigate = useNavigate();
  const isFirstRender = useRef(true);
  const [logout] = useLogout();

  const defaultState = {
    type: { id: 0, name: 'СЗ' },
    legal_name: '',
    ogrnip: '',
    ogrn: '',
    inn: '',
    kpp: '',
    address: '',
    passport_details: '',
    bank_details: [{
      rc: '',
      bank: '',
      bik: '',
      kc: '',
    }],
  };

  const [isVisible, setIsVisible] = useState(false);

  const [mainInfo, setMainInfo] = useState(defaultState);
  const [initialInfo, setInitialInfo] = useState(defaultState);

  const [files, setFiles] = useState([]);

  useEffect(() => {
    check().then(() => {
      fetchOneLegalEntities(id).then((data) => {
        setMainInfo(data);
        setInitialInfo(data);
      });
    });
  }, []);

  const [progressFiles, addFileFunc] = useProgress(
    setIsVisible,
    id,
    logout,
    postBloggerFiles,
  );

  const addFileHandler = (event) => {
    const files = [...event.target.files];
    files.forEach((file) => {
      addFileFunc(file);
    });
  };

  const deleteFileHandler = (id) => {
    check()
      .then(() => {
        deleteBloggerFile(id).then(() => {
        });
      })
      .catch((error) => logout(error));
  };

  const saveMainInfo = () => {
    check()
      .then(() => {
        const sentData = {
          type: { id: mainInfo.type.id },
          legal_name: mainInfo.legal_name,
          passport_details: mainInfo.passport_details,
          ogrn: mainInfo.ogrn,
          ogrnip: mainInfo.ogrnip,
          kpp: mainInfo.kpp,
          inn: mainInfo.inn,
          address: mainInfo.address,
          file_links: mainInfo.file_links,
          bank_details: initialInfo.bank_details,
        };

        patchOneEntity(id, sentData)
          .then(() => {
            fetchOneLegalEntities(id).then((data) => {
              setMainInfo(data);
              setInitialInfo(data);
            });
            toast.setToastInfo('Данные сохранены');
            toast.setShowSuccess(true);
          })
          .catch((error) => {
            toast.setToastInfo('Данный ИНН уже есть в системе');
            toast.setShowError(true);
          });
      })
      .catch((error) => logout(error));
  };

  const saveBankInfo = () => {
    check()
      .then(() => {
        const sentData = {
          type: { id: mainInfo.type.id },
          inn: mainInfo.inn,
          legal_name: mainInfo.legal_name,
          bank_details: mainInfo.bank_details,
        };

        patchOneEntity(id, sentData)
          .then(() => {
            fetchOneLegalEntities(id).then((data) => {
              setMainInfo(data);
              setInitialInfo(data);
            });
            toast.setToastInfo('Данные сохранены');
            toast.setShowSuccess(true);
          })
          .catch((error) => {
            toast.setToastInfo('Ошибка при сохранении реквизитов');
            toast.setShowError(true);
          });
      })
      .catch((error) => logout(error));
  };

  const cancel = () => {
    setMainInfo((prev) => ({
      ...initialInfo,
      bank_details: prev.bank_details,
    }));
  };

  const addBankDetails = () => {
    setMainInfo((prev) => ({
      ...prev,
      bank_details: [
        ...prev.bank_details,
        {
          rc: '',
          bank: '',
          bik: '',
          kc: '',
        },
      ],
    }));
  };

  if (isFirstRender.current) {
    // if (isBloggerLoading) {
    //   return <Spinner animation="grow" />;
    // }
    isFirstRender.current = false;
  }

  const deleteBankDetailHandler = (bankId) => {
    const dataWithoutDeleted = {
      type: { id: mainInfo.type.id },
      inn: mainInfo.inn,
      legal_name: mainInfo.legal_name,
      bank_details: mainInfo.bank_details.filter((bank) => bank.id !== bankId),

    };
    setMainInfo((prev) => ({
      ...prev,
      bank_details: prev.bank_details.filter((bank) => bank.id !== bankId),
    }));
    check()
      .then(() => {
        patchOneEntity(id, dataWithoutDeleted)
          .then(() => {
            fetchOneLegalEntities(id).then((data) => {
              setMainInfo(data);
              setInitialInfo(data);
            });
            toast.setToastInfo('Данные сохранены');
            toast.setShowSuccess(true);
          })
          .catch((error) => {
            toast.setToastInfo('Что-то пошло не так');
            toast.setShowError(true);
          });
      })
      .catch((error) => logout(error));
  };

  const updateBankDetails = (bankId, updatedBank) => {
    setMainInfo((prev) => ({
      ...prev,
      bank_details: prev.bank_details.map((bank) => (bank.id === bankId ? { ...bank, ...updatedBank } : bank)),
    }));
  };

  const deleteBankDetails = (bankId) => {
    modal.setModalConfirmVisible(true);
    modal.setModalConfirmProps({
      text: 'Вы хотите удалить эти банковские реквизиты?',
      click: () => {
        deleteBankDetailHandler(bankId);
      },
    });
  };

  return (
    <>
      <UploadFilesContainer
        progressFiles={progressFiles}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />

      <PageTitle>
        <SuccessBtn
          text="Вернуться назад"
          type="white"
          back
          style={{ padding: '4px 10px 4px 16px', fontSize: 12 }}
          onClick={() => {
            projects.setProjects([], 'update');
            projects.setProjectsPage(1);
            projects.setProjectsFilter(undefined); navigate(-1);
          }}
        />
        {mainInfo.legal_name}
      </PageTitle>

      <Section title="Основная информация" style={{ marginTop: '30px' }}>
        <div className={classes.wrapper}>

          <MainInfo
            state={mainInfo}
            setState={setMainInfo}
            save={saveMainInfo}
            style={{ width: '70%' }}
            cancel={cancel}
          />
        </div>
      </Section>

      <Section title="Банковские реквизиты">
        <div className={classes.bank_details__container}>
          {mainInfo?.bank_details.map((bank) => (
            <div className={classes.bank_details} key={bank.id}>
              <div className={classes.bank_info_container}>
                <BankInfo
                  state={bank}
                  setState={(updatedBank) => updateBankDetails(bank.id, updatedBank)}
                  cancel={cancel}
                  style={{ width: '70%' }}
                  save={saveBankInfo}
                  deleteBankDetails={deleteBankDetails}
                />
              </div>
            </div>
          ))}
        </div>
        <div className={classes.addBtn__container}>
          <NewAddBtn
            text="Добавить банковские реквизиты"
            onClick={() => addBankDetails()}
          />
        </div>
      </Section>

      <ModalConfirm
        show={modal.modalConfirmVisible}
        onHide={() => modal.setModalConfirmVisible(false)}
        props={modal.modalConfirmProps}
      />
    </>
  );
});

export default OneLegalEntityPage;
