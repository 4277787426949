// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../../index';

import { patchOneCostBid } from 'API/FinanceAPI/CostsAPI';
import { check } from 'API/UserAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import InputElement from 'components/UI/InputElements/InputElement';
import ContractElement from 'components/UI/Modals/ModalCreateContract/components/ContractElement';
import CheckBox from 'components/UI/MyCheckbox/CheckBox';
import TextAreaAutoSize from 'components/UI/MyTextArea/TextAreaAutoSize';
import Section from 'components/UI/Section/Section';

import { useLogout } from 'hooks/useLogout';

import { numberToSum } from 'utils/getSums';

import classes from '../OneCostPage.module.scss';

const COSTS_TYPES = {
  CONTRACOTOR_PAYMENT: 'Оплата исполнителю',
  SK_EXPENSE_PAYMENT: 'СК расход',
  ADDITIONAL_PAYMENT: 'Дополнительный расход',
  UNDEFINED_PAYMENT: 'Не указан',
};

const PaymentInfo = observer((props) => {
  const {
    commonInfo, setCommonInfo, fetchCost, id,
  } = props;
  const logout = useLogout();
  const { user, modal } = useContext(Context);

  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    let total = 0;
    commonInfo?.payment_dates?.forEach((item) => {
      total += item.sum;
    });

    setTotalSum(total);
  }, [commonInfo?.payment_dates]);

  const openModalHandler = () => {
    modal.setModalEditPaymentsProps({
      commonInfo,
    });
    modal.setModalEditPaymentsVisible(true);
  };

  const isDoneHandler = (id) => {
    const newPaymentDates = commonInfo.payment_dates.map((date) => {
      if (date.id === id) {
        return { ...date, is_done: !date.is_done };
      }
      return date;
    });

    setCommonInfo({
      ...commonInfo,
      payment_dates: newPaymentDates,
    });

    check()
      .then(() => {
        patchOneCostBid(commonInfo?.id, { payment_dates: newPaymentDates }).then(() => {
          fetchCost();
        });
      })
      .catch((error) => logout(error));
  };

  return (
    <Section title="Оплата">
      <div className={classes.wrapper}>

        <ContractElement
          text="Порядок оплаты"
          elementClassName={classes.margin_zero}
        >

          <>

            <InputElement
              style={{ color: '#212529', marginLeft: '-40px' }}
              type="date"
              value={commonInfo?.date}
              date
              disabled
            />

            <InputElement
              style={{
                color: '#212529', height: '32px', width: '230px', marginLeft: '20px', marginBottom: '11px',
              }}
              className={commonInfo?.sum !== totalSum && commonInfo?.payment_dates.length > 0 && classes.red}
              value={numberToSum(commonInfo?.sum)}
              disabled
            />
          </>

        </ContractElement>

        {commonInfo?.payment_dates
        && (

          commonInfo?.payment_dates.map((item, index) => (
            <ContractElement
              key={item.id}
              text={index === 0 ? 'Фактическая оплата' : ''}
              elementClassName={classes.margin_zero}
            >

              <>

                <InputElement
                  style={{ color: '#212529', marginLeft: '-40px' }}
                  type="date"
                  value={item?.date}
                  date
                  disabled
                />

                <InputElement
                  style={{
                    color: '#212529',
                    height: '32px',
                    width: '230px',
                    marginLeft: '20px',
                    marginBottom: '11px',

                  }}
                  value={numberToSum(item?.sum)}
                  disabled
                />

                <CheckBox
                  onChange={() => isDoneHandler(item.id)}
                  className={classes.checkboxPP}
                  checked={item?.is_done}
                />

              </>

            </ContractElement>

          ))

        )}

        <div className={classes.buttonContainer}>
          <SuccessBtn
            text="Изменить порядок оплаты"
            style={{ padding: '4px 30px', fontSize: 13 }}
            onClick={openModalHandler}
          />
        </div>

        <div className={classes.message}><b>ОБРАТИТЬ ВНИМАНИЕ!</b><br />
          Данная функция предусмотрена только для форс-мажоров и изменений условий договора за собой не повлечет!<br />
          При увеличении количества траншей, общая сумма оплаты не может быть изменена!<br />
          Любые изменения условий договора (добавить/удалить/увеличить/уменьшить суммы оплаты) необходимо производить через Заявку на документы!
        </div>

        <ContractElement text="Способ оплаты">
          <TextAreaAutoSize
            value={commonInfo?.comment || '-'}
            placeholder="Введите текст..."
            style={{ marginBottom: '20px', maxHeight: '300px' }}
            minRows={4}
          />

        </ContractElement>

      </div>

    </Section>
  );
});

export default PaymentInfo;
