import React from 'react';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import ClientString from 'components/UI/ClientString/ClientString';

import classes from '../ClientsPage.module.css';

function AllClientsWindow(props) {
  const { openHandler, clientStats } = props;

  return (
    <div className={classes.wrapper}>
      <ClientString text="Всего клиентов" count={clientStats.total} all />

      {clientStats?.types?.map((element) => (
        <ClientString
          key={element.id}
          text={element.name}
          count={element.client_amount}
        />
      ))}

      <SuccessBtn
        text="Добавить клиента"
        style={{ marginTop: 10, padding: '3px 15px', fontSize: 13 }}
        onClick={() => openHandler()}
      />
    </div>
  );
}

export default AllClientsWindow;
