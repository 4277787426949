import axios from 'axios';

// Запросы всех пользователей
const $host = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Запросы авторизованных пользователей
const $authHost = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Добавляем перехватчик ответов
$authHost.interceptors.response.use(
  (response) => response,
  (error) => {
    //
    if (error.response && (error.code === 'ERR_NETWORK')) {
      // Генерируем исключение для обработки в блоке catch
      return Promise.reject(error);
    }

    // Для всех других ошибок, также возвращаем исключение
    return Promise.reject(error);
  },
);

const $inn = axios.create({
  baseURL: process.env.REACT_APP_INN_API_URL,
});

const authInterceptor = (config) => {
  config.headers.authorization = `JWT ${localStorage.getItem('accessToken')}`;
  return config;
};

const innInterceptor = (config) => {
  config.headers.authorization = `Token ${process.env.REACT_APP_INN_API_KEY}`;
  return config;
};

$authHost.interceptors.request.use(authInterceptor);
$inn.interceptors.request.use(innInterceptor);

export { $authHost, $host, $inn };
