// @ts-nocheck
import React, { useEffect, useState } from 'react';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import DateElement from 'components/UI/Modals/ModalCreateContract/components/DateElement';
import LawyerSection from 'components/UI/Section/LawyerSection';

import InputElement from '../../../../UI/InputElements/InputElement';

import { getDate } from 'utils/getDate';
import { numberToSum } from 'utils/getSums';

import PublicationInfoElement from './PublicationInfoElement';

import classes from '../../ProjectPage.module.scss';

function PublicationInfo(props) {
  const {
    element, publicationTypes, contractType, save,
  } = props;

  const [activeBtn, setActiveBtn] = useState(false);

  const [firstDate, setFirstDate] = useState('');
  const [secondDate, setSecondDate] = useState('');
  const [servicePeriodFirstDate, setServicePeriodFirstDate] = useState('');
  const [servicePeriodSecondDate, setServicePeriodSecondDate] = useState('');
  const [comment, setComment] = useState('');
  const [deleteDescription, setDeleteDescription] = useState('');

  useEffect(() => {
    if (element.id) {
      setFirstDate(getDate(element?.placement_period?.date_start));
      setSecondDate(getDate(element?.placement_period?.date_end));
      setServicePeriodFirstDate(element?.date_start);
      setServicePeriodSecondDate(element?.date_end);
      setComment(element?.comment);
      setDeleteDescription(element?.non_removal_period);
    }
  }, []);

  const cancelHandler = () => {
    if (element.id) {
      setActiveBtn(false);
      setFirstDate(getDate(element?.placement_period?.date_start));
      setSecondDate(getDate(element?.placement_period?.date_end));
      setServicePeriodFirstDate(element?.date_start);
      setServicePeriodSecondDate(element?.date_end);
      setComment(element?.comment);
      setDeleteDescription(element?.non_removal_period);
    }
  };

  const saveHandler = () => {
    const data = {
      date_start: getDate(servicePeriodFirstDate),
      date_end: getDate(servicePeriodSecondDate),
      placement_period: {
        date_start: getDate(firstDate),
        date_end: getDate(secondDate),
      },
      comment,
      non_removal_period: deleteDescription,
    };

    save(data, 'publication', element.id).then(() => {
      setActiveBtn(false);
    });
  };

  let text = '';

  if (contractType === 2) {
    text = 'Публикации';
  } else {
    text = element.blogger.name;
  }

  return (
    <LawyerSection title={text} defaultActive={contractType === 2}>
      <div className={classes.wrapper}>
        <InputElement
          text="Исполнитель"
          value={element?.blogger?.name}
          textStyle={{ minWidth: 230 }}
          disabled
        />

        {element?.platform?.map((platform) => (
          <PublicationInfoElement
            key={platform.id}
            platform={platform}
            publicationTypes={publicationTypes}
            textStyle={{ minWidth: 230 }}
          />
        ))}

        <InputElement
          text="KPI"
          value={element?.kpi}
          textStyle={{ minWidth: 230 }}
          disabled
        />

        <DateElement
          value1={firstDate}
          value2={secondDate}
          setValue1={setFirstDate}
          setValue2={setSecondDate}
          type="date"
          text="Период размещения контента"
          style={{ margin: '10px 0 10px' }}
          inner_style={{ width: 'auto' }}
          textStyle={{
            minWidth: 230,
            width: 230,
          }}
          onClick={() => setActiveBtn(true)}
          disabled={contractType === 0 || contractType === 1 || contractType === 2}
        />

        <InputElement
          text="Комментарий"
          value={element?.period_comment}
          placeholders="Комментарий периода размещения контента"
          textStyle={{ minWidth: 230 }}
          disabled
        />

        <InputElement
          text="Эксклюзив"
          value={element?.is_exclusive ? 'Да' : 'Нет'}
          textStyle={{ minWidth: 230 }}
          disabled
        />

        {element?.is_exclusive && (
          <InputElement
            value={element?.exclusive_comment}
            placeholder="Комментарий к эксклюзиву (заполните в заявке)"
            disabled
            style={{ marginLeft: 230 }}
          />
        )}

        {contractType !== 2 && contractType !== 4 && (
          <>
            <InputElement
              text="Договор"
              value={element?.is_contract ? 'Да' : 'Нет'}
              textStyle={{ minWidth: 230 }}
              disabled
            />

            <DateElement
              value1={servicePeriodFirstDate}
              value2={servicePeriodSecondDate}
              setValue1={setServicePeriodFirstDate}
              setValue2={setServicePeriodSecondDate}
              type="date"
              text="Срок оказания услуг"
              style={{ margin: '10px 0 10px' }}
              inner_style={{ width: 'auto' }}
              textStyle={{
                minWidth: 230,
                width: 230,
              }}
              onClick={() => setActiveBtn(true)}
              disabled={contractType === 0 || contractType === 1}
            />

            {/* <InputElement
              text="Показываем клиенту баинг"
              value={element?.payment?.show_client ? 'Да' : 'Нет'}
              disabled
              textStyle={{ minWidth: 230 }}
            /> */}

            {/* Убрано в рамках задачи CRM-836 */}
            {/* <InputElement
              text="Сумма договора для нас"
              value={numberToSum(element?.total_sum, true)}
              placeholder="Заполните в заявке"
              disabled
              textStyle={{ minWidth: 230 }}
            />

            <InputElement
              text="Сумма договора для клиента"
              value={numberToSum(element?.client_sum_without_tax, true)}
              placeholder="Заполните в заявке"
              disabled
              textStyle={{ minWidth: 230 }}
            /> */}
            {/* Убрано в рамках задачи CRM-836 */}
          </>
        )}

        {contractType === 2 && (
          <>
            <InputElement
              text="Возможен ли кейс"
              value={element?.is_case ? 'Да' : 'Нет'}
              disabled
              textStyle={{ minWidth: 230 }}
            />

            {element?.is_case && (
              <InputElement
                value={element?.case_comment}
                placeholder="Комментарий к кейсу (заполните в заявке)"
                disabled
                style={{ marginLeft: 230 }}
              />
            )}
          </>
        )}

        <InputElement
          text="Общий комментарий"
          value={comment}
          setValue={setComment}
          textStyle={{ minWidth: 230 }}
          placeholder="Комментарий (заполните в заявке)"
          onClick={() => setActiveBtn(true)}
          disabled={contractType === 0 || contractType === 1 || contractType === 2}
        />

        {activeBtn && (
        <div className={classes.btn__wrapper}>
          <SuccessBtn
            text="Сохранить"
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={() => saveHandler()}
          />

          <SuccessBtn
            text="Отменить"
            type="white"
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={() => cancelHandler()}
          />
        </div>
        )}
      </div>

    </LawyerSection>
  );
}

export default PublicationInfo;
