import React, { useState } from 'react';

import { updateNotificationStatus } from 'API/StaffAPI';
import { check } from 'API/UserAPI';

import NewMyCheckbox from '../../../UI/MyCheckbox/NewMyCheckbox';

import { useLogout } from 'hooks/useLogout';

import classes from '../SettingsPage.module.css';

function NotificationListElement(props) {
  const {
    text, type, fetchUser, id, status, name,
  } = props;
  const [logout] = useLogout();

  const [state, setState] = useState(status);

  const changeHandler = () => {
    setState(!state);

    check()
      .then(() => {
        updateNotificationStatus(id, !status, name, type).then(() => {
          fetchUser();
        });
      })
      .catch((error) => logout(error));
  };

  return (
    <div className={classes.notifications__element}>
      <NewMyCheckbox
        status={state}
        style={{ padding: '4px 0 0 0 ' }}
        onClick={changeHandler}
      />
      <span onClick={changeHandler}>{text}</span>
    </div>
  );
}

export default NotificationListElement;
