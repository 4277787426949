import React from 'react';

import classes from './AccordionArrow.module.css';

function AccordionArrow(props) {
  const {
    isActive, onClick, style, arrowStyle, isTable,
  } = props;
  return (
    <div
      className={
        isActive
          ? isTable
            ? [classes.arrow__buttonBlack, classes.active].join(' ')
            : [classes.arrow__button, classes.active].join(' ')
          : isTable
            ? classes.arrow__buttonBlack
            : classes.arrow__button
      }
      onClick={onClick}
      style={style}
    >
      <span
        className={isTable ? classes.arrowBlack : classes.arrow}
        style={arrowStyle}
      />
    </div>
  );
}

export default AccordionArrow;
