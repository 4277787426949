// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { getBloggers, getShow } from 'API/BloggerAPI';
import { autocompleteLegalTypes } from 'API/LawyersAPI/AutocompleteAPI';
import { check } from 'API/UserAPI';

import PageTitle from 'components/UI/PageTitle/PageTitle';

import ModalCreateBlogger from '../../UI/Modals/ModalCreateBlogger/ModalCreateBlogger';
import NameFilter from '../../UI/NameFilter/NameFilter';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from 'hooks/useLogout';

import TeamElement from '../TeamsPage/TeamElements/TeamElement';

import AllBloggersWindow from './components/AllBloggersWindow';

import classes from './BloggersPage.module.css';

const BloggersPage = observer(() => {
  const { modal } = useContext(Context);
  const [logout] = useLogout();
  const [allBloggers, setAllBloggers] = useState([]);

  const [bloggers, setBloggers] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [show, setShow] = useState([]);
  const [exclusive, setExclusive] = useState([]);

  const [update, setUpdate] = useState(false);

  const [bloggerStats, setBloggerStatsStats] = useState('');
  const [contractorsStats, setContractorsStatsStats] = useState('');

  const [fetchBloggers] = useFetching(async (filter) => {
    const data = await getBloggers({ filter, type: 3 });
    const data2 = await getBloggers({ filter, type: 4 });
    const data3 = await getBloggers({ filter, type: 5 });
    const data4 = await getBloggers({ filter, type: 6 });
    setBloggers(data);
    setContractors(data2);
    setShow(data3);
    setExclusive(data4);

    if (!filter) {
      setBloggerStatsStats(data.length);
      setContractorsStatsStats(data2.length);
    }
  });

  useEffect(() => {
    check()
      .then(() => {
        fetchBloggers();
        getBloggers().then((data) => setAllBloggers(data));
      })
      .catch((error) => logout(error));
  }, []);

  const click = (event, name) => {
    if (event.key === 'Enter' || event.type === 'click') {
      check()
        .then(() => {
          fetchBloggers(name).then(() => {
            if (name) {
              setUpdate(true);
            }
          });
        })
        .catch((error) => logout(error));
    }
  };

  const openHandler = () => {
    modal.setModalCreateBloggerVisible(true);
    modal.setModalCreateBloggerProps({ fetchBloggers });
  };

  return (
    <>
      <PageTitle>Блогеры и подрядчики</PageTitle>

      <div className={classes.container}>
        <div style={{ width: '100%' }}>
          <NameFilter list={allBloggers} click={click} type="blogger" />

          <TeamElement
            array={bloggers}
            text="Блогеры"
            update={update}
            setUpdate={setUpdate}
            blogger
          />
          <TeamElement
            array={contractors}
            text="Подрядчики"
            update={update}
            setUpdate={setUpdate}
            blogger
          />
          <TeamElement
            array={show}
            text="Шоу"
            update={update}
            setUpdate={setUpdate}
            blogger
          />
          <TeamElement
            array={exclusive}
            text="Exclusive"
            update={update}
            setUpdate={setUpdate}
            blogger
          />
        </div>

        <AllBloggersWindow
          openHandler={openHandler}
          clientStats={bloggerStats}
          contractorsStats={contractorsStats}
        />
      </div>

      <ModalCreateBlogger
        show={modal.modalCreateBloggerVisible}
        onHide={() => modal.setModalCreateBloggerVisible(false)}
        props={modal.modalCreateBloggerProps}
      />
    </>
  );
});

export default BloggersPage;
