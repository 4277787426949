// @ts-nocheck
import React, {
  memo,
  useContext, useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { sendTransferProjects } from 'API/LawyersAPI/ProjectAPI';
import { fetchDeleteStaffCard, fetchProjectStaff } from 'API/StaffAPI';
import { check } from 'API/UserAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import CheckBox from 'components/UI/MyCheckbox/CheckBox';
import MyDropdown from 'components/UI/MyDropdown/MyDropdown';
import MyModal from 'components/UI/MyModal/MyModal';
import StatusBtn from 'components/UI/StatusBtns/StatusBtn/StatusBtn';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from 'hooks/useLogout';

import { TIME_FOR_DELETE_USER } from 'utils/consts';

import {
  STATUS_QUANTITY, styleForButtonSend, styleForModal, stylesForButtonAddNewEmployee,
} from '../consts/consts';

import classes from './ModalTransferProjects.module.scss';

const ModalTransferProjects = observer(({
  show,
  onHide,
  teams,
  projectList,
  setProjectList,
  id,
  modalOpen,
}) => {
  const { projects, toast } = useContext(Context);
  const controller = new AbortController();
  const table = useRef();
  const logout = useLogout();
  const navigate = useNavigate();

  const [fetching, setFetching] = useState(true);
  const [isAddNewEmployee, setIsAddNewEmployee] = useState(false);
  const [isCheckedChooseAll, setIsCheckedChooseAll] = useState(false);

  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);

  const [employeeList, setEmployeeList] = useState([]);
  const [employeeListToServer, setEmployeeListToServer] = useState([]);

  const [fetchProjects, isProjectsLoading] = useFetching(
    async (page, limit, signal, filter, update) => {
      await check().catch((error) => logout(error));

      const data = await fetchProjectStaff(
        page,
        limit,
        signal,
        filter,
        id,
      );
      projects.setModalProjectsTotalCount(data.count);

      if (update === 'update' || projects.modalProjectsPage === 1) {
        projects.setModalProjectsPage(2);
        projects.setModalProjects(data.results, 'update');
      } else if (update === 'addProject') {
        projects.setModalProjects(data.results, 'update');
      } else {
        projects.setModalProjectsPage(projects.modalProjectsPage + 1);
        projects.setModalProjects(data.results);
      }

      setFetching(false);
    },
  );

  const totalPages = Math.ceil(
    projects.modalProjectsTotalCount / projects.modalProjectsLimit,
  );

  /* Когда доскролили до нижней части таблицы (первое условие)
  Проверяем текущую страницу с общим количеством страниц(второе условие)
  Далее делаем запрос
  */

  const scrollHandler = (event) => {
    if (
      event.target.scrollHeight
     - (event.target.scrollTop + event.target.clientHeight)
     < 600
     && projects.modalProjectsPage <= totalPages && modalOpen
    ) {
      setFetching(true);
    }
  };

  // Наблюдение за скроллом

  useEffect(() => {
    if (isProjectsLoading) return;

    const element = table?.current;
    element?.addEventListener('scroll', scrollHandler);
    return () => {
      element?.removeEventListener('scroll', scrollHandler);
    };
  }, [isProjectsLoading]);

  const stageHandler = (value) => {
    if (filterStatus.includes(value)) {
      setFilterStatus(filterStatus.filter((item) => item !== value));
    } else {
      setFilterStatus([...filterStatus, value]);
    }
  };

  useEffect(() => {
    if (modalOpen) {
      setTimeout(() => {
        projects.setModalProjectsFilter({ status: filterStatus });
        projects.setModalProjectsPage(1);

        fetchProjects(
          projects.modalProjectsPage,
          projects.modalProjectsLimit,
          controller.signal,
          projects.modalProjectsFilter,
          'update',
        );
      }, 220);
    }
  }, [filterStatus.length]);

  useEffect(() => {
    if (modalOpen && fetching) {
      if (projects.modalProjectsFilter) {
        fetchProjects(
          projects.modalProjectsPage,
          projects.modalProjectsLimit,
          controller.signal,
          projects.modalProjectsFilter,
        );
      } else {
        fetchProjects(
          projects.modalProjectsPage,
          projects.modalProjectsLimit,
          controller.signal,
        );
      }
    }

    return () => {
      controller.abort();
    };
  }, [fetching, modalOpen]);

  const close = () => {
    onHide();
  };

  const addNewEmployeeHandler = () => {
    // Если выбран сотрудник
    if (selectedUser.fullname && projectList.length > 0) {
      const employeeItem = {
        staff: {
          id: selectedUser.id,
          name: selectedUser.fullname,
        },
        projects: projectList,
      };

      setEmployeeList((prevState) => [...prevState, employeeItem]);
      setEmployeeListToServer((prevState) => [...prevState, employeeItem]);
      setIsAddNewEmployee(true);
      setSelectedTeam([]);
      setSelectedUser([]);
      setProjectList([]);
      toast.setToastInfo('Сотрудник добавлен в список');
      toast.setShowSuccess(true);
    } else {
      // Если не выбран сотрудник
      toast.setToastInfo('Выберите сотрудника и проекты');
      toast.setShowError(true);
    }
  };

  const backPage = () => {
    // Удаляем последнего сотрудника из списка
    const deleteLastElement = employeeList.slice(0, -1);
    if (employeeList.length === 1) {
      setEmployeeList(deleteLastElement);
      setEmployeeListToServer(deleteLastElement);
      setIsAddNewEmployee(false);
      toast.setToastInfo('Сотрудник удалён из списка');
      toast.setShowSuccess(true);
    } else {
      setEmployeeList(deleteLastElement);
      setEmployeeListToServer(deleteLastElement);
      toast.setToastInfo('Сотрудник удалён из списка');
      toast.setShowSuccess(true);
    }
  };

  const sendHandler = async () => {
    // Если проекты переносятся больше чем на одного сотрудника, employeeList пополняется
    if (employeeList.length > 0) {
      if (selectedUser.fullname) {
        if (projectList.length === 0) {
          toast.setToastInfo('Выберите проекты');
          toast.setShowError(true);
          return;
        }
        if (selectedUser.id) {
          toast.setToastInfo('Добавьте выбранного сотрудника в список');
          toast.setShowError(true);
          return;
        }
        const employeeItem = {
          staff: {
            id: selectedUser.id,
            name: selectedUser.fullname,
          },
          projects: projectList,
        };
        setEmployeeListToServer((prevState) => [...prevState, employeeItem]);
      }
      try {
        const data = employeeListToServer.map((employee) => ({
          staff: { id: employee.staff.id },
          projects: employee.projects,
        }));
        // Запрос на перенос проектов
        await sendTransferProjects(id, data);
        // Запрос на удаление сотрудника
        await fetchDeleteStaffCard(id);
        toast.setToastInfo('Сотрудник удалён, проекты перенесены');
        toast.setShowSuccess(true);
        onHide();
        setTimeout(() => navigate('/teams'), TIME_FOR_DELETE_USER);
      } catch {
        toast.setToastInfo('Вы не можете удалить руководителя');
        toast.setShowError(true);
      }
    } else {
      // Перенос проектов на одного сотрудника
      if (!selectedUser.fullname) {
        toast.setToastInfo('Выберите сотрудника');
        toast.setShowError(true);
        return;
      } if (projectList.length === 0) {
        toast.setToastInfo('Выберите проекты');
        toast.setShowError(true);
        return;
      }
      try {
        const data = [{
          staff: { id: selectedUser.id },
          projects: projectList,
        }];
        // Запрос на перенос проектов
        await sendTransferProjects(id, data);
        // Запрос на удаление сотрудника
        await fetchDeleteStaffCard(id);
        toast.setToastInfo('Сотрудник удалён, проекты перенесены');
        toast.setShowSuccess(true);
        onHide();
        setTimeout(() => navigate('/teams'), TIME_FOR_DELETE_USER);
      } catch (error) {
        toast.setToastInfo('Вы не можете удалить руководителя');
        toast.setShowError(true);
      }
    }
  };

  const onChangeChooseAllHandler = () => {
    if (projectList?.length > 0) {
      setProjectList([]);
      setIsCheckedChooseAll(false);
    } else {
      setProjectList(...projectList, projects?.modalProjects);
      setIsCheckedChooseAll(true);
    }
  };

  const onChangeChooseOneHandler = (column) => {
    if (projectList?.some((item) => item?.id === column?.id)) {
      const deleteIndex = projectList?.filter((item) => item?.id !== column?.id);
      setProjectList(deleteIndex);
    } else {
      setProjectList([...projectList, { id: column?.id, name: column?.name }]);
    }
  };

  const isCheckedChooseOneHandler = (column) => projectList?.some(
    (item) => item?.id === column?.id,
  );

  return (
    <MyModal
      backPage={isAddNewEmployee && backPage}
      buttonBack
      show={show}
      onHide={close}
      style={styleForModal}
    >
      <h1>Перенос проектов</h1>
      <div className={classes.newEmployee_container}>
        {isAddNewEmployee
        && employeeList?.map((employee, index) => (
          <div key={employee.staff.id} className={classes.newEmployee_container__inner}>
            <div className={classes.newEmployee_container__inner__employee}>
              <div className={classes.newEmployee_container__inner__employee__title}>
                Cотрудник
                {' '}
                {index + 1}
              </div>
              <div className={classes.newEmployee_container__inner__employee__body}>
                {employee.staff.name}
              </div>
            </div>
            <div className={classes.newEmployee_container__inner__projects}>
              <div className={classes.newEmployee_container__inner__projects__title}>
                Проекты
              </div>
              <div className={classes.newEmployee_container__inner__projects__body}>
                {employee.projects.map((project) => `${project.name}, `)}
              </div>
            </div>
          </div>
        ))}
      </div>

      <header className={classes.header}>Передать проекты</header>
      <div className={classes.container}>
        <div className={classes.container__team__innerContainer}>
          <div className={classes.container__team__innerContainer__team__title}>
            Команда
          </div>
          <div className={classes.container__team__innerContainer__team__body}>
            <MyDropdown
              transferProject
              defaultText="Выбрать"
              selected={selectedTeam}
              setSelected={setSelectedTeam}
              list={teams}
            />
          </div>
        </div>
        <div className={classes.container__employee__innerContainer}>
          <div className={classes.container__employee__innerContainer__employee__title}>
            Сотрудник
          </div>
          <div className={classes.container__employee__innerContainer__employee__body}>
            <MyDropdown
              transferProject
              defaultText="Выбрать"
              selected={selectedUser}
              setSelected={setSelectedUser}
              list={selectedTeam.team}
            />
          </div>
        </div>
        <div className={classes.container__status__innerContainer}>
          <div className={classes.container__status__innerContainer__status__title}>
            Статус
          </div>
          <div className={classes.container__status__innerContainer__status__body}>
            {STATUS_QUANTITY.map((statusElement) => (
              <StatusBtn
                transferProjects
                key={statusElement}
                status={statusElement}
                onClick={() => stageHandler(statusElement)}
                isActive={filterStatus.includes(statusElement)}
                marketing
              />
            ))}
          </div>
        </div>
        <div className={classes.container__projects__innerContainer}>
          <div className={classes.container__projects__innerContainer__projects__title}>
            Выбор проектов
          </div>
          <div
            ref={table}
            className={classes.container__projects__innerContainer__projects__body}
          >
            <CheckBox
              transferProjects
              label={isProjectsLoading ? 'Идет загрузка...' : 'Выбрать все'}
              onChange={() => onChangeChooseAllHandler()}
              checked={isCheckedChooseAll}
            />
            {projects?.modalProjects?.map((column) => (
              <div key={column?.id} className={classes.wrapper}>
                <CheckBox
                  transferProjects
                  name={column?.id}
                  key={column?.id}
                  value={column?.id}
                  label={column?.name}
                  onChange={() => onChangeChooseOneHandler(column)}
                  checked={isCheckedChooseOneHandler(column)}
                />
                <div>
                  <StatusBtn
                    transferProjectsList
                    marketing
                    key={column?.manager_status?.id}
                    status={column?.manager_status?.id}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.btn__container}>
          <SuccessBtn
            text="Выбрать следующего сотрудника"
            style={stylesForButtonAddNewEmployee}
            onClick={() => addNewEmployeeHandler()}
          />
          <SuccessBtn
            text="Перенести проекты"
            style={styleForButtonSend}
            onClick={() => sendHandler()}
          />
        </div>
      </div>
    </MyModal>
  );
});

export default memo(ModalTransferProjects);
