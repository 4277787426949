import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { checkActiveNavbar } from 'utils/checkActive';

import classes from '../Navbar.module.css';

function NewNavbarItem(props) {
  const {
    path, onClick, icon, title, notificationTimer,
  } = props;
  const pathname = useLocation().pathname.replace(/\d+/, '');

  let className = 'new__navbar__item ';

  className = checkActiveNavbar(className, path, pathname);

  return (
    <NavLink className={className} to={path} onClick={onClick} ref={notificationTimer}>
      <img className={classes.new__navbar__image} src={icon} alt="" />
      <span>{title}</span>
    </NavLink>
  );
}

export default NewNavbarItem;
