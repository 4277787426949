import React from 'react';

import NotificationList from './NotificationList';

import classes from '../SettingsPage.module.css';

function Notifications({ fetchUser, info, id }) {
  return (
    <div className={classes.profile__container}>
      <div className={classes.notification__container}>
        <NotificationList
          text="Уведомления браузера"
          type={info.notification_browser}
          fetchUser={fetchUser}
          id={id}
          name="notification_browser"
        />
        <NotificationList
          text="E-mail уведомления"
          type={info.notification_email}
          fetchUser={fetchUser}
          id={id}
          name="notification_email"
        />
      </div>
    </div>
  );
}

export default Notifications;
