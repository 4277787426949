// @ts-nocheck
import React, { useState } from 'react';
import { Popover } from 'react-bootstrap';
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler';
import close_icon from 'assets/icons/Btns/CloseIconNoBg.svg';
// Assets
import dropdown_icon from 'assets/icons/Btns/hiddenBtn.svg';

import FilterBtn from '../FilterBtn/FilterBtn';

import HeaderCell from './components/HeaderCell';
import TableCell from './components/TableCell';

import css from './PaymentsTable.module.scss';

function PaymentsTable(props) {
  const {
    headers,
    data,
    additionalData,
    title,
    onOpenDetails,
    tableRef,
    updateFieldHandler,
    setLocalStorageValue,
    initialHiddenColumns,
    setFilterIsOpen,
    filterIsOpen,
    filterWindow,
  } = props;

  const [isHiddenColumnsDropdownOpen, setIsHiddenColumnsDropdownOpen] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState(initialHiddenColumns || []);

  const onShowColumn = (id) => {
    if (setLocalStorageValue) {
      setLocalStorageValue(hiddenColumns.filter((c) => c.id !== id));
    }
    setHiddenColumns(hiddenColumns.filter((c) => c.id !== id));

    if (hiddenColumns.length === 1) {
      setIsHiddenColumnsDropdownOpen(false);
    }
  };

  const onHideColumn = (payload) => {
    if (setLocalStorageValue) {
      setLocalStorageValue([...hiddenColumns, payload]);
    }

    setHiddenColumns([...hiddenColumns, payload]);
  };

  return (
    <section className={css.container}>
      {/* {!!title && <h4 className={css.tableTitle}>{title}</h4>} */}
      {/* <div className={css.hiddenColumnsDropdown}>
        <div>
          <button
            type="button"
            onClick={() => setIsHiddenColumnsDropdownOpen(!isHiddenColumnsDropdownOpen)}
            disabled={hiddenColumns.length === 0}
            className={css.hiddenColumnsTrigger}
          >
            Скрытые столбцы
            {' '}
            {hiddenColumns.length}
            <img
              className={isHiddenColumnsDropdownOpen ? css.rotated : ''}
              src={dropdown_icon}
              alt=""
            />
          </button>

          {isHiddenColumnsDropdownOpen && (
            <OutsideClickHandler
              onOutsideClick={() => setIsHiddenColumnsDropdownOpen(false)}
            >
              <div className={css.hiddenColumnsBody}>
                {hiddenColumns.map((c) => (
                  <button
                    type="button"
                    key={c.id}
                    onClick={() => onShowColumn(c.id)}
                    className={css.hiddenColumnBtn}
                  >
                    {c.label}
                    <img src={close_icon} alt="" />
                  </button>
                ))}
              </div>
            </OutsideClickHandler>
          )}
        </div>

        {setFilterIsOpen && (
          <FilterBtn isOpen={filterIsOpen} setIsOpen={setFilterIsOpen} />
        )}
      </div>

      {!!filterWindow && filterWindow} */}

      <table className={css.table} cellSpacing="1" ref={tableRef}>
        <thead>
          <tr>
            {headers.map(
              (h) => hiddenColumns.findIndex((c) => c.id === h.id) < 0 && (
              <HeaderCell
                {...h}
                onHideColumn={onHideColumn}
                key={`payments_table_header_cell_${h.id}`}
                className={h.id === 'project_id' && css.test}
              />
              ),
            )}
          </tr>
        </thead>

        <tbody>
          {data?.map((row, i) => (
            <tr
              onClick={() => !!onOpenDetails && onOpenDetails(row.id)}
              key={`payments_table_row_${i}`}
            >
              {headers.map(
                (header) => hiddenColumns.findIndex((c) => c.id === header.id) < 0 && (
                <td
                  key={`payments_table_cell_${header.id}`}
                  className={[
                    header.id === 'project_id' ? css.test : '',
                    header.id === 'adesk' ? css.adeskCell : '',
                    css[header?.className],
                  ].join(' ')}
                >
                  {header.special ? (
                    <TableCell
                      header={header}
                      row={row}
                      updateFieldHandler={updateFieldHandler}
                      index={i}
                      additionalData={additionalData}
                    />
                  ) : (
                    row[header.id]
                  )}
                </td>
                ),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

export default PaymentsTable;
