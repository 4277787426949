/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Btns.module.scss';

function SuccessBtn(props) {
  const {
    type,
    btnType,
    onClick,
    style,
    back,
    text,
    link,
    href,
    download,
    target,
    className,
    createUser,
    disabled,
    image,
    onMouseEnter,
    onMouseLeave,
  } = props;

  let btnClassNames = [classes.btn__success, className].join(' ');

  if (type === 'white') {
    btnClassNames = [
      classes.btn__success,
      classes.btn__success__white,
      (back && !createUser) && classes.back,
      (back && createUser) && classes.back__createUser,
      className,
    ].join(' ');
  }

  if (disabled) {
    btnClassNames = `${btnClassNames} ${classes.disabled}`;
  }

  return (
    link ? (
      <Link to={link} className={btnClassNames} style={style}>
        <span>{text}</span>
      </Link>
    ) : href ? (
      <a
        className={btnClassNames}
        style={style}
        href={href}
        onClick={onClick}
        download={download}
        target={target}
      >
        {text}
      </a>
    ) : (
      <button
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={btnClassNames}
        type={btnType}
        onClick={!disabled ? onClick : undefined}
        style={style}
      >
        {text ? (
          <div className={classes.btn__success__inner}>{text}</div>
        ) : (
          <img alt="icon" src={image} />
        )}
      </button>
    )
  );
}

export default SuccessBtn;
