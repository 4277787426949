// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Context } from 'index';

import { postClient } from 'API/ClientAPI';
import { fetchStaff } from 'API/ManagerAPI/AutocompleteAPI';
import { createProject } from 'API/ManagerAPI/ProjectAPI';
import { check } from 'API/UserAPI';
import { autocompleteBloggers } from '../../../../API/BloggerAPI';
import { autocompleteClients } from '../../../../API/ClientAPI';

import InputElement from 'components/UI/InputElements/InputElement';
import InputElementDateTime from 'components/UI/InputElements/InputElementDateTime';
import MyModal from 'components/UI/MyModal/MyModal';

import { useStateNew } from 'hooks/useStateNew';
import { useLogout } from '../../../../hooks/useLogout';

// Utils
import { getDate } from 'utils/getDate';

import {
  BLOGGER_TYPES,
  CLIENT_TYPES,
  STATE_STATUSES,
} from '../../../../utils/consts';

import NewAddBtn from '../../Btns/NewAddBtn';
import SuccessBtn from '../../Btns/SuccessBtn';
import SearchBar from '../../SearchBar/SearchBar';
import ContractElement from '../ModalCreateContract/components/ContractElement';

// Classes
import classes from './ModalCreateProject.module.css';

const initialDate = getDate();

function ModalCreateProject({ show, onHide, props }) {
  const {
    fetchProjects,
    fetchAllProjectsFunc,
  } = props;

  const { toast } = useContext(Context);

  const [logout] = useLogout();

  const [managerList, setManagerList] = useState([]);
  const [directorList, setDirectorList] = useState([]);
  const [responsibleList, setResponsibleList] = useState([]);

  const [bloggersList, setBloggersList] = useState([]);
  const [clientsList, setClientsList] = useState([]);

  const [typeClient, setTypeClient] = useState({
    id: null,
    name: '',
  });

  const [addClientVisible, setAddClientVisible] = useState(false);
  const [matches, setMatches] = useState([{}]);

  const [created, setCreated] = useState(initialDate);
  const [client, setClient, changeClient] = useStateNew([
    { fieldId: Date.now(), id: null, name: '' },
  ]);
  const [projectName, setProjectName] = useState('');
  const [deadline, setDeadline] = useState('');
  const [
    manager,
    setManager,
    changeManager,
    addManager,
    removeManager,
    checkManager,
  ] = useStateNew([{ fieldId: Date.now(), name: '', id: null }]);

  const [
    director,
    setDirector,
    changeDirector,
    addDirector,
    removeDirector,
    checkDirector,
  ] = useStateNew([{ fieldId: Date.now(), name: '', id: null }]);

  const [
    responsible,
    setResponsible,
    changeResponsible,
    addResponsible,
    removeResponsible,
    checkResponsible,
  ] = useStateNew([{ fieldId: Date.now(), name: '', id: null }]);

  const [selectedStatus, setSelectedStatus] = useState({ id: null, label: '' });

  useEffect(() => {
    if (show) {
      check()
        .then(() => {
          autocompleteBloggers().then((data) => setBloggersList(data));
          autocompleteClients().then((data) => setClientsList(data));

          fetchStaff({ role: 1, is_active: true })
            .then((dataDirector) => setDirectorList(dataDirector));

          fetchStaff({ job: 0, is_active: true })
            .then((dataManagers) => setManagerList(dataManagers));

          fetchStaff({ job: [4, 5, 6], is_active: true })
            .then((dataResponsible) => setResponsibleList(dataResponsible));
        })
        .catch((error) => logout(error));
    }
  }, [show]);

  useEffect(() => {
    if (client[0].name.length > 0) {
      setMatches(
        [...bloggersList, ...clientsList].filter((element) => {
          const regex = new RegExp(`${client[0].name}`, 'gi');
          return element?.name?.match(regex) || element?.fullname?.match(regex);
        }),
      );
    }
  }, [client[0].name]);

  const closeHandler = () => {
    setTimeout(() => {
      setAddClientVisible(false);
      setMatches([{}]);
      setTypeClient({ id: null, name: '' });

      setClient([{ fieldId: Date.now(), id: null, name: '' }]);
      setProjectName('');
      setDeadline('');

      setManager([{ fieldId: Date.now(), name: '', id: null }]);
      setDirector([{ fieldId: Date.now(), name: '', id: null }]);
      setResponsible([{ fieldId: Date.now(), name: '', id: null }]);

      setSelectedStatus({});
    }, 170);
    onHide();
  };

  const addClientHadler = () => {
    setAddClientVisible(true);
  };

  const createClientHandler = () => {
    const data = {
      name: client[0].name,
      type: { id: typeClient.id },
      contacts: [],
      legal_entity: [],
    };

    check().then(() => {
      postClient(data).then((response) => {
        setAddClientVisible(false);
        setClientsList([...bloggersList, response.data]);
        setMatches([{}]);

        toast.setToastInfo('Новый заказчик создан');
        toast.setShowSuccess(true);

        setTypeClient({
          id: null,
          name: '',
        });
        setClient([
          {
            fieldId: Date.now(),
            id: response.data.id,
            name: response.data.name,
          },
        ]);
      }).catch((error) => {
        if (error.response.data.type) {
          toast.setToastInfo('Выберите тип клиента');
          toast.setShowError(true);
        }
      });
    }).catch((error) => logout(error));
  };

  const addProjectHandler = (event) => {
    event.preventDefault();

    if (!client[0].id) {
      toast.setToastInfo('Выберите клиента из списка');
      toast.setShowError(true);
      return;
    }

    check()
      .then(() => {
        const sendManager = [];
        manager.forEach((el) => {
          if (el?.id) {
            sendManager.push({ id: el.id });
          }
        });

        const sendDirector = [];
        director.forEach((el) => {
          if (el?.id) {
            sendDirector.push({ id: el.id });
          }
        });

        const sendResponsible = [];
        responsible.forEach((el) => {
          if (el?.id) {
            sendResponsible.push({ id: el.id });
          }
        });

        const data = {
          created: getDate(created),
          client: { id: client[0].id },
          name: projectName,
          manager: sendManager,
          director: sendDirector,
          responsible: sendResponsible,
          status: { id: selectedStatus.id ? selectedStatus.id : 0 },
          deadline: deadline ? getDate(deadline, true) : '',
        };

        createProject(data).then(() => {
          closeHandler();
          toast.setToastInfo('Новый проект создан');
          fetchProjects();
          fetchAllProjectsFunc();
          toast.setShowSuccess(true);
        }).catch((error) => {
          if (error.response.data.deadline || error.response.data.created) {
            toast.setToastInfo('Неправильный формат даты');
          } else if (error.response.data.name) {
            toast.setToastInfo('Проект с таким именем уже создан');
          } else {
            toast.setToastInfo('Обратитесь в техподдержку');
          }
          toast.setShowError(true);
        });
      })
      .catch(() => logout());
  };

  return (
    <MyModal show={show} onHide={closeHandler}>
      <h1 className={classes.nameProject}>Новый проект</h1>
      <Form onSubmit={(event) => addProjectHandler(event)}>
        <header className={classes.header}>Общая информация</header>
        <InputElement
          text="Дата создания"
          type="date"
          value={created}
          setValue={setCreated}
          date
          textStyle={{ minWidth: 220 }}
          disabled
        />

        <ContractElement
          text="Заказчик"
          textStyle={{ minWidth: 220 }}
          style={{}}
        >
          <div className={classes.searchBar__container} style={{ margin: 0 }}>
            <SearchBar
              list={[...bloggersList, ...clientsList]}
              text={client[0]}
              setText={changeClient}
              type="client"
              required
            />

            {matches.length === 0 && !addClientVisible && (
            <div className={classes.addButton}>
              <NewAddBtn
                text="Добавить заказчика"
                onClick={addClientHadler}
              />
            </div>
            )}
          </div>
        </ContractElement>

        {addClientVisible && (
        <div className={classes.addClient__container}>
          <InputElement
            text="Тип*"
            textStyle={{ minWidth: 220 }}
            value={typeClient}
            setValue={setTypeClient}
            dropdown={[...CLIENT_TYPES.LIST, ...BLOGGER_TYPES.LIST]}
          />

          <SuccessBtn
            text="Создать заказчика"
            type="white"
            btnType="button"
            className={classes.addClient__btn}
            onClick={createClientHandler}
          />
        </div>
        )}

        <InputElement
          text="Проект"
          value={projectName}
          setValue={setProjectName}
          textStyle={{ minWidth: 220 }}
          required
        />

        <InputElementDateTime
          type="date"
          text="Дедлайн"
          value={deadline}
          setValue={setDeadline}
          date
          textStyle={{ minWidth: 220 }}
        />

        <ContractElement
          text="Руководитель"
          textStyle={{ minWidth: 220, height: 32 }}
        >
          <div className={classes.searchBar__container}>
            {director.map((element) => (
              <SearchBar
                key={element.fieldId}
                list={directorList}
                text={element}
                setText={changeDirector}
                deleteElement={removeDirector}
                checkResponsible={checkDirector}
                type="staff"
              />
            ))}

            <NewAddBtn text="Добавить" onClick={addDirector} />
          </div>
        </ContractElement>

        <ContractElement
          text="Менеджер"
          textStyle={{ minWidth: 220, height: 32 }}
        >
          <div className={classes.searchBar__container}>
            {manager.map((element) => (
              <SearchBar
                key={element.fieldId}
                list={managerList}
                text={element}
                setText={changeManager}
                deleteElement={removeManager}
                checkResponsible={checkManager}
                type="staff"
              />
            ))}

            <NewAddBtn text="Добавить" onClick={addManager} />
          </div>
        </ContractElement>

        <ContractElement
          text="Ответственный"
          textStyle={{ minWidth: 220, height: 32 }}
        >
          <div className={classes.searchBar__container}>
            {responsible.map((element) => (
              <SearchBar
                key={element.fieldId}
                list={responsibleList}
                text={element}
                setText={changeResponsible}
                deleteElement={removeResponsible}
                checkResponsible={checkResponsible}
                type="staff"
              />
            ))}

            <NewAddBtn text="Добавить" onClick={addResponsible} />
          </div>
        </ContractElement>

        <InputElement
          text="Статус проекта"
          value={selectedStatus}
          setValue={setSelectedStatus}
          dropdown={STATE_STATUSES.LIST}
          textStyle={{ minWidth: 220 }}
        />

        <div className={classes.btn}>
          <SuccessBtn
            text="Создать новый проект"
            style={{ padding: '5px 15px', fontSize: 12 }}
          />
        </div>
      </Form>
    </MyModal>
  );
}

export default ModalCreateProject;
