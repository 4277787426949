import React from 'react';

import classes from './AccordionArrow.module.css';

function SquareArrow(props) {
  const { active, setActive } = props;
  return (
    <div
      className={[classes.squareArrow, active ? classes.active : ''].join(' ')}
      onClick={() => setActive(!active)}
    />
  );
}

export default SquareArrow;
