import React, { useContext } from 'react';
import { Toast } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import classes from './MyToast.module.css';

const MyToast = observer(() => {
  const { toast } = useContext(Context);

  return (
    <div className="toast__container">
      <Toast
        onClose={() => toast.setShowSuccess(false)}
        show={toast.showSuccess || false}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <strong className="me-auto">{toast.toastTitle || 'Успешно'}</strong>

          <div className={classes.done} />
        </Toast.Header>
        <Toast.Body>{toast.toastInfo}</Toast.Body>
      </Toast>

      <Toast
        onClose={() => toast.setShowError(false)}
        show={toast.showError || false}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <strong className="me-auto">{toast.toastTitle || 'Ошибка'}</strong>

          <div className={classes.bad} />
        </Toast.Header>
        <Toast.Body>{toast.toastInfo}</Toast.Body>
      </Toast>
    </div>
  );
});

export default MyToast;
