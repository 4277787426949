// @ts-nocheck
import React, { useContext, useState } from 'react';
import { Context } from 'index';

import { SOCIAL_TYPES } from 'utils/consts';

import SuccessBtn from '../Btns/SuccessBtn';
import LawyerSection from '../Section/LawyerSection';

import OneSocial from './components/OneSocial';

import classes from './SocialNetworks.module.scss';

function SocialNetworks(props) {
  const {
    socials,
    updateSocials,
    addSocials,
    deleteSocials,
    sectionClassName,
    contentClassName,
    titleClassName,
    save,
    cancel,
  } = props;

  const [activeBtn, setActiveBtn] = useState(false);

  const { toast } = useContext(Context);

  const saveHandler = () => {
    setActiveBtn(false);
    save();
    toast.setShowSuccess(true);
    toast.setToastInfo('Данные сохранены');
  };

  const cancelHandler = () => {
    setActiveBtn(false);
    cancel('socials');
  };

  const tempObj = {
    instagram: [],
    youtube: [],
    vk: [],
    telegram: [],
    twitch: [],
    tiktok: [],
  };

  socials.forEach((element) => {
    if (element.type.id === 0) {
      tempObj.instagram = [...tempObj.instagram, element];
    } else if (element.type.id === 1) {
      tempObj.youtube = [...tempObj.youtube, element];
    } else if (element.type.id === 2) {
      tempObj.vk = [...tempObj.vk, element];
    } else if (element.type.id === 3) {
      tempObj.telegram = [...tempObj.telegram, element];
    } else if (element.type.id === 4) {
      tempObj.twitch = [...tempObj.twitch, element];
    } else if (element.type.id === 5) {
      tempObj.tiktok = [...tempObj.tiktok, element];
    }
  });

  return (
    <LawyerSection
      title="Социальные сети"
      sectionClassName={sectionClassName}
      className={contentClassName}
      titleClassName={titleClassName}
      defaultActive
    >
      <div className={classes.body}>
        {SOCIAL_TYPES.LIST.map((social) => (
          <OneSocial
            key={social.id}
            social={tempObj[social.type]}
            label={social.label}
            updateSocials={updateSocials}
            addSocials={addSocials}
            deleteSocials={deleteSocials}
            click={() => setActiveBtn(true)}
          />
        ))}

        {save && activeBtn && (
          <div className={classes.btn__wrapper}>
            <SuccessBtn
              text="Сохранить"
              style={{
                padding: '2px 20px',
                fontSize: 13,
              }}
              onClick={() => saveHandler()}
            />

            <SuccessBtn
              text="Отменить"
              type="white"
              style={{
                padding: '2px 20px',
                fontSize: 13,
              }}
              onClick={() => cancelHandler()}
            />
          </div>
        )}
      </div>
    </LawyerSection>
  );
}

export default SocialNetworks;
