import React, { useState } from 'react';
import { Popover } from '@mui/material';

import LawyersInput from 'components/UI/MyInput/LawyersInput';

import SuccessBtn from '../Btns/SuccessBtn';

import classes from './ProjectPageTitle.module.scss';

function ProjectPageTitle(props) {
  const {
    title, setTitle, updateContract, children,
  } = props;
  const [edit, setEdit] = useState(false);

  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ

  const click = (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      updateContract({ name: title }, 'title');
      setEdit(false);
    }
  };

  const text = `${title.substring(0, 19)}${title.length > 19 ? '...' : ''}`;

  return (
    <div className={classes.title}>
      {children}

      {edit ? (
        <div className={classes.input__container}>
          <LawyersInput
            style={{ width: '100%', border: '1px solid #a29ade' }}
            value={title}
            setValue={setTitle}
            onKeyPress={(event) => click(event)}
          />

          <SuccessBtn
            text="Сохранить"
            style={{
              fontSize: 14,
              padding: '3px 30px',
            }}
            onClick={(event) => click(event)}
          />
        </div>
      ) : (
        <div
          className={classes.text}
          onClick={() => setEdit(true)}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <span className={classes.temp}>{text}</span>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={title.length >= 19 ? open : false}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableAutoFocus
            disableEnforceFocus
          >
            <div className={classes.popoverText}>{title}</div>
          </Popover>
        </div>
      )}
    </div>
  );
}

export default ProjectPageTitle;
