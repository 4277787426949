import React from 'react';

import classes from './Btns.module.scss';

function CancelBtn(props) {
  return (
    <div
      className={classes.btn__cancel}
      onClick={props?.onClick}
      style={props?.style}
    />
  );
}

export default CancelBtn;
