// @ts-nocheck
import { makeAutoObservable } from 'mobx';

export default class CommentStore {
  constructor() {
    this._comments = [];
    this._commentsTotalCount = 0;
    this._commentsPage = 1;
    this._commentsLimit = 15;
    this._commentsFilter = undefined;

    makeAutoObservable(this);
  }

  setComments(comments, update) {
    if (update) {
      return (this._comments = comments);
    }

    if (this._commentsPage === 1) {
      return (this._comments = comments);
    }

    comments.forEach((comment) => this._comments.push(comment));
  }

  setCommentsTotalCount(count) {
    this._commentsTotalCount = count;
  }

  setCommentsPage(page) {
    this._commentsPage = page;
  }

  setCommentsFilter(filter) {
    this._commentsFilter = filter;
  }

  clearComments() {
    this._comments = [];
  }

  get comments() {
    return this._comments;
  }

  get commentsTotalCount() {
    return this._commentsTotalCount;
  }

  get commentsPage() {
    return this._commentsPage;
  }

  get commentsLimit() {
    return this._commentsLimit;
  }

  get commentsFilter() {
    return this._commentsFilter;
  }
}
