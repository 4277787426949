import { $authHost } from 'API';

export const autocompleteAllLegalEntities = async () => {
  const { data } = await $authHost.get('/autocomplete/legal_entities/');
  return data;
};

export const autocompleteLegalEntities = async () => {
  const { data } = await $authHost.get(
    '/autocomplete/our_legal_entities/',
  );
  return data;
};

export const autocompleteClientLegalEntities = async () => {
  const { data } = await $authHost.get(
    '/autocomplete/client_legal_entities/',
  );
  return data;
};

export const autocompletePublicationTypes = async () => {
  const { data } = await $authHost.get(
    '/autocomplete/publication_types/',
  );
  return data;
};

export const autocompleteBillStatuses = async () => {
  const { data } = await $authHost.get('/autocomplete/bill_statuses/');
  return data;
};

export const autocompleteContractStatuses = async () => {
  const { data } = await $authHost.get(
    '/autocomplete/contract_statuses/',
  );
  return data;
};

export const autocompleteContractTypes = async () => {
  const { data } = await $authHost.get('/autocomplete/contract_types/');
  return data;
};
