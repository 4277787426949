// @ts-nocheck
import React from 'react';

import MyDropdown from 'components/UI/MyDropdown/MyDropdown';
import LawyersInput from 'components/UI/MyInput/LawyersInput';

import { checkPhone, checkSum } from 'utils/checkInputField';

import CancelBtn from '../Btns/CancelBtn';
import CheckboxItem from '../CheckboxItem/CheckboxItem';
import MyDateInput from '../MyDateInput/MyDateInput';
import NewTextArea from '../MyTextArea/NewTextArea';

import classes from './InputElements.module.css';

const InputElement = React.memo((props) => {
  const {
    type,
    text,
    tooltipText,
    tooltipPosition,
    checkboxText,
    placeholder,
    dropdown,
    checkbox,
    textarea,
    value,
    setValue,
    disabled,
    blur,
    required,
    onChange,
    onKeyPress,
    onClick,
    onInput,
    date,
    updateDate,
    id,
    style,
    textStyle,
    textareaStyle,
    inputStyle,
    className,
    classNameLink,
    classNameDropdownMenu,
    classNameDropdown,
    textClassName,
    elementClassName,
    multiple,
    multipleSelect,
    deleteBtn,
    deleteHandler,
    cancelBtn,
    left,
    defaultText,
    isPopover,
    noArrow,
    isLink,
    invalid,
    pattern,
    onlyWord,
  } = props;

  const isNumber = (event) => {
    if (type === 'number' || type === 'sum') {
      checkSum(event);
    }

    if (type === 'phone') {
      checkPhone(event);
    }
  };

  let inputType = 'input';

  if (dropdown) {
    inputType = 'dropdown';
  } else if (date) {
    inputType = 'date';
  } else if (checkbox) {
    inputType = 'checkbox';
  } else if (textarea) {
    inputType = 'textarea';
  }

  const setDate = (changeValue) => {
    if (updateDate) {
      updateDate(changeValue, 'date', id);
    }
  };

  return (
    <div
      className={[classes.contract__element, elementClassName, disabled && blur && classes.disabled].join(' ')}
      style={style}
    >
      {text && (
        <div
          className={[classes.element__text, textClassName].join(' ')}
          style={textStyle}
        >
          {text}
        </div>
      )}
      {inputType === 'input' && (
        <LawyersInput
          onlyWord={onlyWord}
          pattern={pattern}
          type={type}
          className={className}
          classNameLink={classNameLink}
          placeholder={placeholder}
          value={value}
          setValue={setValue}
          onInput={onInput}
          onKeyPress={onKeyPress || ((event) => isNumber(event))}
          disabled={disabled}
          onChange={onChange}
          onClick={onClick}
          required={required}
          style={inputStyle}
          isPopover={isPopover}
          tooltipText={tooltipText}
          tooltipPosition={tooltipPosition}
          isLink={isLink}
          invalid={invalid}
        />
      )}

      {inputType === 'dropdown' && (
        <MyDropdown
          id={id}
          selected={value}
          setSelected={setValue}
          className={className}
          classNameMenu={classNameDropdownMenu}
          classNameDropdown={classNameDropdown}
          inputStyle={inputStyle}
          list={dropdown}
          onClick={onClick}
          multiple={multiple}
          multipleSelect={multipleSelect}
          defaultText={defaultText}
          disabled={disabled}
          style={style}
          noArrow={noArrow}

        />
      )}

      {inputType === 'date' && (
        <MyDateInput
          value={value}
          setValue={setValue || setDate}
          className={[classes.date__input, className].join(' ')}
          style={inputStyle || { width: '100%' }}
          onClick={onClick}
          onChange={onChange}
          disabled={disabled}
        />
      )}

      {inputType === 'checkbox' && (
        <CheckboxItem
          status={value}
          setStatus={setValue}
          text={checkboxText}
          style={{ width: '100%' }}
          onClick={onClick}
        />
      )}

      {inputType === 'textarea' && (
        <NewTextArea
          value={value}
          setValue={setValue}
          onChange={onChange}
          onClick={onClick}
          style={textareaStyle}
          placeholder={placeholder}
          disabled={disabled}
        />
      )}

      {deleteBtn && (
        <div className={classes.delete} onClick={() => deleteHandler()} />
      )}

      {cancelBtn && (
        <CancelBtn
          onClick={deleteHandler}
          style={{
            padding: '1px 8px',
            left: left || 412,
            position: 'absolute',
          }}
        />
      )}
    </div>
  );
});

export default InputElement;
