// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import { Context } from 'index';

import { patchOneCostBid } from 'API/FinanceAPI/CostsAPI';
import { check } from 'API/UserAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import InputElement from 'components/UI/InputElements/InputElement';
import ContractElement from 'components/UI/Modals/ModalCreateContract/components/ContractElement';
import Section from 'components/UI/Section/Section';

import { useLogout } from 'hooks/useLogout';

import { formatNumberCard, getSum, numberToSum } from 'utils/getSums';

import classes from '../OneCostPage.module.scss';

function PaymentInfoAdditional(props) {
  const { commonInfo, fetchCost } = props;

  const { logout } = useLogout();
  const { toast, modal } = useContext(Context);

  const [currentSum, setCurrentSum] = useState(0);
  const [active, setActive] = useState(false);

  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ

  useEffect(() => {
    if (commonInfo.sum) {
      setCurrentSum(commonInfo.sum);
    }
  }, [commonInfo.sum]);

  const cancel = () => {
    setCurrentSum(commonInfo.sum);
    setActive(false);
  };

  const send = () => {
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите подтвердить действие?',
      click: () => {
        check()
          .then(() => {
            patchOneCostBid(commonInfo.id, { sum: getSum(currentSum) })
              .then(() => {
                setActive(false);
                toast.setToastInfo('Сумма оплаты была изменена');
                toast.setShowSuccess(true);
                fetchCost('commonUpdate');
              })
              .catch((error) => {
                if (error.response?.data?.non_field_errors) {
                  toast.setToastInfo(error?.response?.data?.non_field_errors[0]);
                  toast.setShowError(true);
                } else {
                  toast.setToastInfo('Ошибка при изменении суммы оплаты. Обратитесь в поддержку.');
                  toast.setShowError(true);
                }
              });
          })
          .catch((error) => logout(error));
      },
    });
    modal.setModalConfirmVisible(true);
  };

  return (
    <Section title="Оплата">
      <div className={classes.wrapper}>

        <ContractElement
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          text="Порядок оплаты"
        >

          <InputElement
            style={{ color: '#212529', marginLeft: '-40px' }}
            type="date"
            value={commonInfo?.date}
            date
            disabled
          />

          {commonInfo.financial_status.id === 4 && (
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: -20,
            }}
            onClose={handlePopoverClose}
            disableAutoFocus
            disableEnforceFocus
          >
            <div className={classes.popoverText}>Заявка оплачена, внести изменения невозможно</div>

          </Popover>
          )}

          <InputElement
            style={{
              color: '#212529', height: '32px', width: '230px', marginLeft: '20px', marginBottom: '11px',
            }}
            value={numberToSum(currentSum || 0)}
            setValue={setCurrentSum}
            onClick={() => setActive(true)}
            disabled={commonInfo.financial_status.id === 4}
          />

        </ContractElement>

        <InputElement
          style={{ color: '#212529' }}
          text="НДС"
          value={commonInfo?.contract?.is_nds ? 'Да' : 'Нет'}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="Сумма НДС"
          value={numberToSum(commonInfo?.contract?.nds_sum || 0)}
          textClassName="textClassName2"
          placeholder="0, 00 Р"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="Способ оплаты"
          value={commonInfo?.payment_method}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="Номер телефона"
          value={commonInfo?.bank_details?.phone_number}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="Номер карты"
          value={formatNumberCard(commonInfo?.bank_details?.card_number || '')}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="ФИО получателя"
          value={commonInfo?.bank_details?.recipient_name}
          textClassName="textClassName2"
          disabled
        />
        {active && (
        <div className={classes.btn__container}>
          <SuccessBtn
            text="Сохранить"
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={send}
          />
          <SuccessBtn
            text="Отменить"
            type="white"
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={cancel}
          />
        </div>
        )}
      </div>
    </Section>
  );
}

export default PaymentInfoAdditional;
