import React from 'react';

import classes from './OneTableRow.module.scss';

const CELL_PARAMS = {
  projectPageMargin: {
    className: classes.projectPageMargin__cell,
  },
  projectPageEstimate: {
    className: classes.projectPageEstimate__cell,
  },
  reportPage: {
    className: classes.reportPage__cell,
  },
  mainManager: {
    className: classes.mainManager__cell,
  },
  mainLawyer: {
    className: classes.mainLawyer__cell,
  },
  mainLawyerSum: {
    className: classes.mainLawyerSum__cell,
  },
  oneClientPage: {
    className: classes.oneClientPage__cell,
  },
  oneBloggerPage: {
    className: classes.oneBloggerPage__cell,
  },
};

function OneCell(props) {
  const { column, rowName } = props;

  return (
    <div className={[classes.cell, CELL_PARAMS[rowName].className].join(' ')}>
      {column?.label}
    </div>
  );
}

export default OneCell;
