export const columns = [
  { id: 'title', label: 'Проект' },
  { id: 'period', label: 'Период реализации' },
  { id: 'payment_date', label: 'Дата оплаты' },
  { id: 'payment_sum', label: 'Сумма оплаты (вкл НДС)' },
  { id: 'accounting_month', label: 'Месяц учета' },
  { id: 'paid', label: 'Оплачено' },
  { id: 'debt', label: 'Долг' },
  { id: 'margin', label: 'Маржа' },
  { id: 'project_status', label: 'Статус проекта' },
];

export const tasksColumns = [
  {
    id: 'number',
    label: '№',
  },
  {
    id: 'project',
    label: 'Проект',
  },
  {
    id: 'name',
    label: 'Задача',
  },
  {
    id: 'deadline',
    label: 'Дедлайн',
  },
  {
    id: 'staff',
    label: 'Сотрудник',
  },
  {
    id: 'managerStatus',
    label: 'Статус задачи',
  },
];

export const tasksDevelopmentColumns = [
  {
    id: 'number',
    label: '№',
  },
  {
    id: 'project',
    label: 'Проект',
  },
  {
    id: 'blogger',
    label: 'Блогер',
  },
  {
    id: 'name',
    label: 'Задача',
  },
  {
    id: 'deadline',
    label: 'Дедлайн',
  },
  {
    id: 'staff',
    label: 'Сотрудник',
  },
  {
    id: 'managerStatus',
    label: 'Статус задачи',
  },
];

export const tasksLawyerColumns = [
  {
    id: 'number',
    label: '№',
  },
  {
    id: 'client_legal_entity',
    label: 'ЮЛ Заказчика',
  },
  {
    id: 'contractor_legal_entity',
    label: 'ЮЛ Исполнителя',
  },
  {
    id: 'name',
    label: 'Задача',
  },
  {
    id: 'deadline',
    label: 'Дедлайн',
  },
  {
    id: 'staff',
    label: 'Сотрудник',
  },
  {
    id: 'managerStatus',
    label: 'Статус задачи',
  },
];

export const newsColumns = [
  {
    id: 'staff',
    label: 'Сотрудник',
  },
  {
    id: 'telegram',
    label: 'Телеграм',
  },
  {
    id: 'goodNews',
    label: 'Хорошие новости',
  },
  {
    id: 'badNews',
    label: 'Плохие новости',
  },
];

export const headerStyle = { marginTop: 35 };
export const headCellStyle = { padding: ' 20px 15px' };
