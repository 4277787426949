import React, { useState } from 'react';

import classes from './Tooltip.module.scss';

function Tooltip({ icon, text, tooltipPosition }) {
  const [show, setShow] = useState(false);

  // Проверяем, содержит ли текст HTML теги
  const isHtml = /<\/?[a-z][\s\S]*>/i.test(text);

  // Если текст не содержит HTML, разделяем его для создания списка
  const items = !isHtml ? text.split(', ') : null;

  return (
    <div className={classes.tooltip__container}>
      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <img src={icon} alt="icon" />
      </div>
      {show && (
        <div className={`${isHtml ? classes.tooltip__contentHTML : classes.tooltip__content} ${classes[tooltipPosition]}`}>
          {isHtml ? (
            // Если текст - это HTML, выводим его как есть
            <div dangerouslySetInnerHTML={{ __html: text }} />
          ) : (
            // Если текст - это не HTML, формируем список
            <ul>
              {items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}

export default Tooltip;
