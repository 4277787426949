export const getStyle = (list) => {
  let style;

  if (list.length === 1) {
    style = { marginTop: 65 };
  } else if (list.length === 2) {
    style = { marginTop: 85 };
  } else if (list.length === 3) {
    style = { marginTop: 110 };
  } else if (list.length === 4) {
    style = { marginTop: 135 };
  } else if (list.length === 5) {
    style = { marginTop: 155 };
  } else if (list.length === 6) {
    style = { marginTop: 180 };
  } else if (list.length >= 7) {
    style = { marginTop: 200 };
  }

  return style;
};
