// @ts-nocheck
import React from 'react';

import DeleteBtn from 'components/UI/Btns/DeleteBtn';
import NewAddBtn from 'components/UI/Btns/NewAddBtn';
import InputElement from 'components/UI/InputElements/InputElement';

import classes from '../SocialNetworks.module.scss';

const OneSocialElement = React.memo((props) => {
  const {
    element, updateSocials, addSocials, deleteSocials, index, click,
  } = props;

  const addHandler = () => {
    addSocials({
      type: { id: element.type.id, name: element.type.name },
      blogger: { id: element.blogger.id, name: element.blogger.name },
      nickname: '',
      subscribers: 0,
      link: '',
      update_date: '',
    });
    click();
  };

  const deleteHandler = (id) => {
    deleteSocials(id);
    click();
  };

  return (
    <div className={classes.element}>
      <InputElement
        value={element?.nickname}
        onChange={(event) => updateSocials(element.id, 'nickname', event.target.value)}
        elementClassName={classes.input}
        onClick={click}
        placeholder="Ссылка на соцсеть..."
      />
      <InputElement
        value={element?.subscribers}
        onChange={(event) => updateSocials(element.id, 'subscribers', event.target.value)}
        type="number"
        elementClassName={classes.input}
        onClick={click}
        placeholder="0"
      />
      <InputElement
        value={element?.link}
        onChange={(event) => updateSocials(element.id, 'link', event.target.value)}
        elementClassName={classes.input}
        onClick={click}
        placeholder="Ссылка на статистику..."
      />
      <InputElement
        value={element?.update_date}
        onChange={(event) => updateSocials(element.id, 'update_date', event)}
        date
        elementClassName={classes.input}
        onClick={click}
      />

      {index === 0 && <NewAddBtn onClick={() => addHandler()} />}
      {index > 0 && <DeleteBtn onClick={() => deleteHandler(element.id)} />}
    </div>
  );
});

export default OneSocialElement;
