// @ts-nocheck
import React, { useContext, useState } from 'react';
import { Context } from '../../../index';

import InputElement from 'components/UI/InputElements/InputElement';

import SuccessBtn from '../Btns/SuccessBtn';
import Files from '../Files/Files';
import ContractElement from '../Modals/ModalCreateContract/components/ContractElement';
import MyInputFile from '../MyInput/MyInputFile';

import classes from './CLientInfo.module.css';

function CLientInfo(props) {
  const {
    name,
    setName,
    typeClient,
    setTypeClient,
    typeClientList,
    addFileHandler,
    deleteFileHandler,
    files,
    save,
    cancel,
  } = props;
  const { modal } = useContext(Context);

  const [activeBtn, setActiveBtn] = useState(false);

  const saveHandler = () => {
    setActiveBtn(false);
    save();
  };

  const cancelHandler = () => {
    setActiveBtn(false);
    cancel();
  };

  const openModalDeleteFile = (name, id) => {
    setActiveBtn(false);
    modal.setModalConfirmVisible(true);
    modal.setModalConfirmProps({
      text: `Вы хотите удалить файл ${name}?`,
      click: () => deleteFileHandler(id),
    });
  };

  return (
    <>
      <InputElement
        text="Тип*"
        textStyle={{ minWidth: 230 }}
        value={typeClient}
        setValue={setTypeClient}
        onClick={() => setActiveBtn(true)}
        dropdown={typeClientList}
      />

      <InputElement
        text="Наименование*"
        textStyle={{ minWidth: 230 }}
        value={name}
        setValue={setName}
        onClick={() => setActiveBtn(true)}
        required
      />

      {addFileHandler && (
        <ContractElement textStyle={{ minWidth: 230 }}>
          <div className={classes.files__container}>
            {files.length > 0 && (
              <div className={classes.files}>
                {files.map((file) => (
                  <Files
                    key={file.id}
                    file={file}
                    deleteFileHandler={() => openModalDeleteFile(file.name, file.id)}
                  />
                ))}
              </div>
            )}

            <div>
              <MyInputFile
                onChange={(event) => addFileHandler(event)}
                multiple
                accept=".ppt, .pptx, .xls, .xlsx, .doc, .docx, .jpeg, .jpg, .png, .pdf, .key, .heif"
              />
            </div>
          </div>
        </ContractElement>
      )}

      {save && activeBtn && (
        <div className={classes.btn__wrapper}>
          <SuccessBtn
            text="Сохранить"
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={() => saveHandler()}
          />

          <SuccessBtn
            text="Отменить"
            type="white"
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={() => cancelHandler()}
          />
        </div>
      )}
    </>
  );
}

export default CLientInfo;
