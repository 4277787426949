import qs from 'qs';

import { $authHost } from 'API';

export const fetchNotifications = async (page, limit, controller, filter) => {
  const params = { page, limit };

  if (filter.is_new === false) {
    params.is_new = false;
  }

  if (filter.is_new === true) {
    params.is_new = true;
  }

  const { data } = await $authHost.get('/notification/notification/', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};

export const fetchNewAmountNotification = async () => {
  const { data } = await $authHost.get('/notification/notification/new_amount/');
  return data;
};

export const setAsSeenNotification = async (id) => {
  const { data } = await $authHost.post(`/notification/notification/${id}/set_as_seen/`);
  return data;
};

export const setAllAsSeenNotifications = async () => {
  const { data } = await $authHost.post('/notification/notification/set_all_as_seen/');
  return data;
};

export const setFewAsSeenNotifications = async (sentData) => {
  const { data } = await $authHost.post('/notification/notification/set_few_as_seen/', sentData);
  return data;
};

export const setFewAsUnseenNotifications = async (sentData) => {
  const { data } = await $authHost.post('/notification/notification/set_few_as_unread/', sentData);
  return data;
};
