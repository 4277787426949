import qs from 'qs';

import { $authHost } from 'API';

import { getDate } from '../utils/getDate';

export const fetchOneUserTasks = async (filter) => {
  const params = {};

  if (filter) {
    if (Number.isInteger(filter.project[0].id)) {
      params.project = filter.project[0].id;
    }

    if (filter.deadlineStart) {
      params.deadline_after = getDate(filter.deadlineStart);
    }
    if (filter.deadlineEnd) {
      params.deadline_before = getDate(filter.deadlineEnd);
    }

    if (filter.deadline?.id === 0) {
      params.o = 'deadline';
    }
    if (filter.deadline?.id === 1) {
      params.o = '-deadline';
    }

    if (filter.status.id) {
      params.status = filter.status.id;
    }

    if (filter.project_status.length > 0) {
      params.project_status = filter.project_status;
    }
  }

  const { data } = await $authHost.get('/task_page/task/', {
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return data;
};

export const createTaskUser = async (data) => {
  await $authHost.post('/task_page/task/', data);
};

export const patchTaskUser = async (task_id, data) => {
  await $authHost.patch(`/task_page/task/${task_id}/`, data);
};

export const deleteTaskUser = async (task_id) => {
  await $authHost.delete(`/task_page/task/${task_id}/`);
};
