import qs from 'qs';

import { $authHost } from 'API/index';

import { getDate } from 'utils/getDate';
import { getSum } from 'utils/getSums';

export const getRegistryTableMain = async (page, limit, controller, filter) => {
  const params = { page, limit };

  if (filter) {
    // Массив для хранения сортировок по возрастанию/убыванию
    const sortArray = [];

    if (filter.id) {
      params.project_project_id = Number(filter.id);
    }

    if (filter.id_status?.id === 0) {
      sortArray.push('project_id');
    }
    if (filter.id_status?.id === 1) {
      sortArray.push('-project_id');
    }

    let tempArray = [];
    filter.project.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.project_id = tempArray;
    }

    tempArray = [];
    filter.contract_type.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.type = tempArray;
    }

    tempArray = [];
    filter.client_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.client_legal_entity = tempArray;
    }

    tempArray = [];
    filter.our_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.contractor_legal_entity = tempArray;
    }

    tempArray = [];
    filter.responsible.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.manager = tempArray;
    }

    tempArray = [];
    filter.lawyer.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.lawyer = tempArray;
    }

    tempArray = [];
    filter.doc_manager.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.doc_manager = tempArray;
    }

    if (filter.bid_sent_date_start) {
      params.bid_sent_date_after = getDate(filter.bid_sent_date_start);
    }

    if (filter.bid_sent_date_end) {
      params.bid_sent_date_before = getDate(filter.bid_sent_date_end);
    }

    if (filter?.deadline_status?.id === 0) {
      sortArray.push('bid_sent_date');
    } else if (filter?.deadline_status?.id === 1) {
      sortArray.push('-bid_sent_date');
    }

    if (filter?.is_contract_sent?.id === 0) {
      params.is_sent = false;
    } else if (filter?.is_contract_sent?.id === 1) {
      params.is_sent = true;
    }

    if (filter.contract_sent_date_start) {
      params.sent_date_after = getDate(filter.contract_sent_date_start);
    }

    if (filter.contract_sent_date_end) {
      params.sent_date_before = getDate(filter.contract_sent_date_end);
    }

    if (filter?.contract_sent_date_deadline?.id === 0) {
      sortArray.push('sent_date');
    } else if (filter?.contract_sent_date_deadline?.id === 1) {
      sortArray.push('-sent_date');
    }

    if (filter.branch_name) {
      params.post_branch = filter.branch_name;
    }

    if (filter.contract_number) {
      params.number = filter.contract_number;
    }

    if (filter.contract_sum) {
      params.total_sum = getSum(filter.contract_sum);
    }

    if (filter.ak_sum) {
      params.ak_sum = getSum(filter.ak_sum);
    }

    if (filter.payment_date_start) {
      params.payment_dates_after = getDate(filter.payment_date_start);
    }

    if (filter.payment_date_end) {
      params.payment_dates_before = getDate(filter.payment_date_end);
    }

    if (filter.service_start) {
      params.date_after = getDate(filter.service_start);
    }

    if (filter.service_end) {
      params.date_before = getDate(filter.service_end);
    }

    if (filter.service_end_start) {
      params.date_end_after = getDate(filter.service_end_start);
    }

    if (filter.service_end_end) {
      params.date_end_before = getDate(filter.service_end_end);
    }

    tempArray = [];
    filter.contract_status.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.status = tempArray;
    }

    tempArray = [];
    filter.closed_status.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.closed_status = tempArray;
    }

    tempArray = [];
    filter.bill_status.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.bill_status = tempArray;
    }

    if (filter?.is_case?.id === 0) {
      params.is_case = false;
    } else if (filter?.is_case?.id === 1) {
      params.is_case = true;
    }

    if (filter?.is_adesk?.id === 0) {
      params.adesk = false;
    } else if (filter?.is_adesk?.id === 1) {
      params.adesk = true;
    }

    tempArray = [];
    filter.project_manager_status.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.project_manager_status = tempArray;
    }

    if (filter.date_take_documents_start) {
      params.collection_date_after = getDate(filter.date_take_documents_start);
    }

    if (filter.date_take_documents_end) {
      params.collection_date_before = getDate(filter.date_take_documents_end);
    }

    tempArray = [];
    filter.quarter_start.forEach((item) => tempArray.push(item.label));
    if (tempArray.length > 0) {
      params.quarter_start = tempArray;
    }

    tempArray = [];
    filter.quarter_end.forEach((item) => tempArray.push(item.label));
    if (tempArray.length > 0) {
      params.quarter_end = tempArray;
    }

    tempArray = [];
    filter.quarter_closing.forEach((item) => tempArray.push(item.label));
    if (tempArray.length > 0) {
      params.quarter_closing = tempArray;
    }

    if (filter.accounting_month_start) {
      params.accounting_month_after = getDate(filter.accounting_month_start);
    }

    if (filter.accounting_month_end) {
      params.accounting_month_before = getDate(filter.accounting_month_end);
    }

    if (filter.actual_month_start) {
      params.actual_month_after = getDate(filter.actual_month_start);
    }

    if (filter.actual_month_end) {
      params.actual_month_before = getDate(filter.actual_month_end);
    }

    params.o = sortArray;
  }

  const response = await $authHost.get('/registry/contract/', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return response.data;
};

export const patchRegistryTableMain = async (contractId, data) => {
  await $authHost.patch(`/registry/contract/${contractId}/`, data);
};

export const exportGoogleRegistryTableMain = async (
  page,
  limit,
  controller,
  filter,
  columnsList,
) => {
  const params = { page, limit };

  const tempArray = [];
  columnsList?.forEach((item) => tempArray.push(item));
  if (tempArray.length > 0) {
    params.fields = tempArray;
  }

  if (filter) {
    // Массив для хранения сортировок по возрастанию/убыванию
    const sortArray = [];

    if (filter.id) {
      params.project_project_id = Number(filter.id);
    }

    if (filter.id_status?.id === 0) {
      sortArray.push('project_id');
    }
    if (filter.id_status?.id === 1) {
      sortArray.push('-project_id');
    }

    let tempArray = [];
    filter.project.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.project_id = tempArray;
    }

    tempArray = [];
    filter.contract_type.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.type = tempArray;
    }

    tempArray = [];
    filter.client_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.client_legal_entity = tempArray;
    }

    tempArray = [];
    filter.our_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.contractor_legal_entity = tempArray;
    }

    tempArray = [];
    filter.responsible.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.manager = tempArray;
    }

    tempArray = [];
    filter.lawyer.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.lawyer = tempArray;
    }

    tempArray = [];
    filter.doc_manager.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.doc_manager = tempArray;
    }

    if (filter.bid_sent_date_start) {
      params.bid_sent_date_after = getDate(filter.bid_sent_date_start);
    }

    if (filter.bid_sent_date_end) {
      params.bid_sent_date_before = getDate(filter.bid_sent_date_end);
    }

    if (filter?.deadline_status?.id === 0) {
      sortArray.push('bid_sent_date');
    } else if (filter?.deadline_status?.id === 1) {
      sortArray.push('-bid_sent_date');
    }

    if (filter?.is_contract_sent?.id === 0) {
      params.is_sent = false;
    } else if (filter?.is_contract_sent?.id === 1) {
      params.is_sent = true;
    }

    if (filter.contract_sent_date_start) {
      params.sent_date = getDate(filter.contract_sent_date_start);
    }

    if (filter.contract_sent_date_end) {
      params.sent_date = getDate(filter.contract_sent_date_end);
    }

    if (filter?.contract_sent_date_deadline?.id === 0) {
      sortArray.push('sent_date');
    } else if (filter?.contract_sent_date_deadline?.id === 1) {
      sortArray.push('-sent_date');
    }

    if (filter.branch_name) {
      params.post_branch = filter.branch_name;
    }

    if (filter.contract_number) {
      params.number = filter.contract_number;
    }

    if (filter.contract_sum) {
      params.total_sum = getSum(filter.contract_sum);
    }

    if (filter.ak_sum) {
      params.ak_sum = getSum(filter.ak_sum);
    }

    if (filter.payment_date_start) {
      params.payment_dates_after = getDate(filter.payment_date_start);
    }

    if (filter.payment_date_end) {
      params.payment_dates_before = getDate(filter.payment_date_end);
    }

    if (filter.service_start) {
      params.date_after = getDate(filter.service_start);
    }

    if (filter.service_end) {
      params.date_before = getDate(filter.service_end);
    }

    if (filter.service_end_start) {
      params.date_end_after = getDate(filter.service_end_start);
    }

    if (filter.service_end_end) {
      params.date_end_before = getDate(filter.service_end_end);
    }

    tempArray = [];
    filter.contract_status.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.status = tempArray;
    }

    tempArray = [];
    filter.closed_status.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.closed_status = tempArray;
    }

    tempArray = [];
    filter.bill_status.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.bill_status = tempArray;
    }

    if (filter?.is_adesk?.id === 0) {
      params.is_adesk = false;
    } else if (filter?.is_adesk?.id === 1) {
      params.is_adesk = true;
    }

    if (filter?.is_case?.id === 0) {
      params.is_case = false;
    } else if (filter?.is_case?.id === 1) {
      params.is_case = true;
    }

    tempArray = [];
    filter.project_manager_status.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.project_manager_status = tempArray;
    }

    if (filter.date_take_documents_start) {
      params.collection_date_after = getDate(filter.date_take_documents_start);
    }

    if (filter.date_take_documents_end) {
      params.collection_date_before = getDate(filter.date_take_documents_end);
    }

    tempArray = [];
    filter.quarter_start.forEach((item) => tempArray.push(item.label));
    if (tempArray.length > 0) {
      params.quarter_start = tempArray;
    }

    tempArray = [];
    filter.quarter_end.forEach((item) => tempArray.push(item.label));
    if (tempArray.length > 0) {
      params.quarter_end = tempArray;
    }

    tempArray = [];
    filter.quarter_closing.forEach((item) => tempArray.push(item.label));
    if (tempArray.length > 0) {
      params.quarter_closing = tempArray;
    }

    params.o = sortArray;
  }

  const response = await $authHost.get('/contract_main/export/', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return response.data;
};

export const exportGoogleRegistryTableMainLawyers = async (
  page,
  limit,
  controller,
  filter,
  columnsList,
) => {
  const params = { page, limit };

  const tempArray = [];
  columnsList?.forEach((item) => tempArray.push(item));
  if (tempArray.length > 0) {
    params.fields = tempArray;
  }

  if (filter) {
    if (filter.status.length > 0) {
      params.lawyer_status = filter.status;
    }

    if (filter.id) {
      params.project_project_id = Number(filter.id);
    }

    if (filter.id_status?.id === 0) {
      params.o = 'project_id';
    }
    if (filter.id_status?.id === 1) {
      params.o = '-project_id';
    }

    let tempArray = [];
    filter.project.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.project_id = tempArray;
    }

    tempArray = [];
    filter.client_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.client_legal_entity = tempArray;
    }

    tempArray = [];
    filter.our_legal_entity.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.contractor_legal_entity = tempArray;
    }

    tempArray = [];
    filter.responsible.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.manager = tempArray;
    }

    tempArray = [];
    filter.lawyer.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.lawyer = tempArray;
    }

    tempArray = [];
    filter.doc_manager.forEach((item) => tempArray.push(item.id));
    if (tempArray.length > 0) {
      params.doc_manager = tempArray;
    }

    if (filter.bid_sent_date_start) {
      params.bid_sent_date_after = getDate(filter.bid_sent_date_start);
    }

    if (filter.bid_sent_date_end) {
      params.bid_sent_date_before = getDate(filter.bid_sent_date_end);
    }

    if (filter.contract_sent_date_start) {
      params.sent_date_after = getDate(filter.contract_sent_date_start);
    }

    if (filter.contract_sent_date_end) {
      params.sent_date_before = getDate(filter.contract_sent_date_end);
    }

    if (filter.branch_name) {
      params.post_branch = filter.branch_name;
    }

    if (filter.contract_id) {
      params.number = filter.contract_id;
    }

    if (filter.total_sum) {
      params.total_sum = getSum(filter.total_sum);
    }

    if (filter.payment_date_start) {
      params.payment_dates_after = getDate(filter.payment_date_start);
    }

    if (filter.payment_date_end) {
      params.payment_dates_before = getDate(filter.payment_date_end);
    }

    if (filter.service_start) {
      params.date_after = getDate(filter.service_start);
    }

    if (filter.service_end) {
      params.date_before = getDate(filter.service_end);
    }

    if (filter.service_end_start) {
      params.date_end_after = getDate(filter.service_end_start);
    }

    if (filter.service_end_end) {
      params.date_end_before = getDate(filter.service_end_end);
    }

    if (filter.contract_type.length > 0) {
      params.type = filter.contract_type;
    }

    if (filter.contract_status.length > 0) {
      params.status = filter.contract_status;
    }

    if (filter.closing_document_status.length > 0) {
      params.closed_status = filter.closing_document_status;
    }

    if (filter.receipt_status.length > 0) {
      params.bill_status = filter.receipt_status;
    }
  }

  const response = await $authHost.get('/contract_main/export/', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return response.data;
};
