import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import AccordionArrow from '../AccordionArrows/AccordionArrow/AccordionArrow';

import css from './Section.module.scss';

function LawyerSection(props) {
  const {
    title,
    children,
    className,
    sectionClassName,
    titleClassName,
    style,
    headerStyle,
    titleStyle,
    defaultActive,
    noClose,
  } = props;

  const [activeSection, setActiveSection] = useState(defaultActive);

  const toggleHandler = () => {
    if (!noClose) {
      setActiveSection(!activeSection);
    }
  };

  const classNames = [
    activeSection ? css.content : css.content__close,
    className,
  ].join(' ');
  const sectionClassNames = [css.section, sectionClassName].join(' ');
  const titleClassNames = [css.title, titleClassName].join(' ');

  return (
    <section className={sectionClassNames} style={style}>
      <div
        className={css.lawyer_header}
        style={headerStyle}
        onClick={() => toggleHandler()}
      >
        <h6 className={titleClassNames} style={titleStyle}>
          {title}
        </h6>

        {!noClose && <AccordionArrow isActive={activeSection} />}
      </div>

      <CSSTransition
        in={activeSection}
        timeout={500}
        classNames="lawyers-contract"
        appear
        unmountOnExit
      >
        <div>
          <div className={classNames}>{children}</div>
        </div>
      </CSSTransition>
    </section>
  );
}

export default LawyerSection;
