export const FIELDS = [
  { id: 'phone', label: 'Номер телефона:', type: 'phone' },
  { id: 'telegram', label: 'Телеграм:' },
  { id: 'address', label: 'Адрес проживания:' },
  { id: 'citizenship', label: 'Гражданство:' },
];

export const stylesForStaffCardLawyersPageTitle = {
  display: 'flex', justifyContent: 'space-between', width: '100%', margin: '0 0 0 50px',
};

export const stylesForStaffCardLawyersButton = {
  width: '157px',
  padding: '4px 10px 4px 16px',
  fontSize: '12px',
};
