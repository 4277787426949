import React from 'react';

import SuccessBtn from '../Btns/SuccessBtn';
import InputElement from '../InputElements/InputElement';

import classes from './WeekResults.module.css';

function WeekResults(props) {
  const { data, updateNews, saveNewsHandler } = props;

  return (
    <>
      <div className={classes.container}>
        <div className={classes.column}>
          <span>Хорошие новости</span>

          {data.map((news) => (
            <InputElement
              key={news.id}
              value={news.good}
              onChange={(event) => updateNews(news.id, 'good', event.target.value)}
              textarea
              placeholder="Введите текст"
              textareaStyle={{ height: 200, maxHeight: 'none', fontSize: 15 }}
            />
          ))}
        </div>

        <div className={classes.column}>
          <span>Плохие новости</span>

          {data.map((news) => (
            <InputElement
              key={news.id}
              value={news.bad}
              onChange={(event) => updateNews(news.id, 'bad', event.target.value)}
              textarea
              placeholder="Введите текст"
              textareaStyle={{ height: 200, maxHeight: 'none' }}
            />
          ))}
        </div>
      </div>

      <div className={classes.btn__wrapper}>
        <SuccessBtn
          text="Сохранить"
          style={{ padding: '4px 15px', fontSize: 13 }}
          onClick={() => saveNewsHandler()}
        />
      </div>
    </>
  );
}

export default WeekResults;
