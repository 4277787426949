import React from 'react';
import { Helmet } from 'react-helmet';

import ProjectPageLawyers from 'components/Lawyers/ProjectPageLawyers/ProjectPageLawyers';

function ContractPage() {
  return (
    <section className="Mysection">
      <Helmet>
        <title> Договор | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <ProjectPageLawyers />
    </section>
  );
}

export default ContractPage;
