import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import './ProgressBar.css';

function ProgressBar(props) {
  const { progress } = props;

  return (
    <Box sx={{ width: '100px' }}>
      <LinearProgress
        style={{
          backgroundColor: '#D9D9D9', height: '4px', borderRadius: '4px', width: '100px',
        }}
        variant="determinate"
        value={progress || 0}
      />
    </Box>
  );
}

export default ProgressBar;
