// @ts-nocheck
import React, { useContext, useState } from 'react';
import { Context } from 'index';

import NewAddBtn from 'components/UI/Btns/NewAddBtn';
import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import Files from 'components/UI/Files/Files';
import InputElement from 'components/UI/InputElements/InputElement';
import MyInputFile from 'components/UI/MyInput/MyInputFile';

import { BLOGGER_TYPES } from 'utils/consts';

import ContractElement from '../../ModalCreateContract/components/ContractElement';
import { BANK_DETAILS_FIELD } from '../Fields';

import classes from '../ModalCreateBlogger.module.css';

function MainInfo(props) {
  const {
    state,
    setState,
    card,
    changeCard,
    addCard,
    removeCard,
    files,
    deleteFileHandler,
    addFileHandler,
    save,
    cancel,
    inModal,
  } = props;
  const { modal } = useContext(Context);

  const [activeBtn, setActiveBtn] = useState(false);

  const saveHandler = () => {
    setActiveBtn(false);
    save();
  };

  const cancelHandler = () => {
    setActiveBtn(false);
    cancel();
  };

  const addCardHandler = () => {
    setActiveBtn(true);
    addCard();
  };

  const openModalDeleteCard = (id) => {
    setActiveBtn(false);
    modal.setModalConfirmVisible(true);
    modal.setModalConfirmProps({
      text: 'Вы хотите удалить личную карту?',
      click: () => removeCard(id),
    });
  };

  const openModalDeleteFile = (name, id) => {
    if (save) {
      setActiveBtn(false);
      modal.setModalConfirmVisible(true);
      modal.setModalConfirmProps({
        text: `Вы хотите удалить файл ${name}?`,
        click: () => deleteFileHandler(id),
      });
    } else {
      deleteFileHandler(id);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <InputElement
        text="Тип"
        textStyle={{ minWidth: 230 }}
        value={state?.type}
        setValue={(type) => setState({ ...state, type })}
        dropdown={BLOGGER_TYPES.LIST}
        onClick={() => setActiveBtn(true)}
      />

      <InputElement
        text="Имя"
        textStyle={{ minWidth: 230 }}
        value={state?.name}
        onChange={(event) => setState({ ...state, name: event.target.value })}
        onClick={() => setActiveBtn(true)}
      />

      <InputElement
        text={state?.type?.id === 3 ? 'ФИО' : 'Комментарий'}
        textStyle={{ minWidth: 230 }}
        value={state?.fullname}
        onChange={(event) => setState({ ...state, fullname: event.target.value })}
        onClick={() => setActiveBtn(true)}
      />

      <div className={classes.card__title}>
        {save && <span>Личная карта</span>}

        {card?.map((element, index) => (
          <div key={element.id}>
            {index > 0 && (
              <div style={{ marginTop: 15 }}>
                {BANK_DETAILS_FIELD.map((field) => (
                  <InputElement
                    key={field.id}
                    text={field.label}
                    textStyle={{ minWidth: 230 }}
                    value={element[field.id]}
                    onChange={(event) => changeCard(element.id, field.id, event.target.value)}
                    onClick={() => setActiveBtn(true)}
                    deleteBtn={(field.delete && activeBtn) || (field.delete && inModal)}
                    deleteHandler={() => openModalDeleteCard(element.id)}
                  />
                ))}
              </div>
            )}
          </div>
        ))}

        <div className={classes.addBtn__container}>
          <NewAddBtn
            text="Добавить личную карту"
            onClick={() => addCardHandler()}
          />
        </div>
      </div>

      {addFileHandler && (
        <ContractElement textStyle={{ minWidth: 230 }}>
          <div
            className={classes.files__container}
            style={files?.length > 0 ? { marginTop: 25 } : {}}
          >
            {files?.length > 0 && (
              <div className={classes.files}>
                {files.map((file, index) => (
                  <Files
                    key={file.id || index}
                    file={file}
                    deleteFileHandler={() => openModalDeleteFile(file.name, file.id || index)}
                  />
                ))}
              </div>
            )}

            <div>
              <MyInputFile
                onChange={(event) => addFileHandler(event)}
                multiple
                accept=".ppt, .pptx, .xls, .xlsx, .doc, .docx, .jpeg, .jpg, .png, .pdf, .key, .heif"
                htmlFor="BloggerFiles"
              />
            </div>
          </div>
        </ContractElement>
      )}

      {save && activeBtn && (
        <div className={classes.btnSave__wrapper}>
          <SuccessBtn
            text="Сохранить"
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={() => saveHandler()}
          />

          <SuccessBtn
            text="Отменить"
            type="white"
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={() => cancelHandler()}
          />
        </div>
      )}
    </div>
  );
}

export default MainInfo;
