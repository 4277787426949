import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import UserStatusIcon from 'components/UI/UserStatuses/UserStatusIcon/UserStatusIcon';

import photo from '../../../../assets/images/pngegg.png';

import classes from '../styles/TeamsPage.module.css';

function UsersElementItem(props) {
  const {
    element,
    to,
  } = props;

  return (
    <Link className={classes.teamList__container} to={`${to}/${element.id}`}>
      <div className={classes.teamList__container__staffInfo}>
        <img className={classes.teamList__container__staffInfo__img} src={element?.photo?.file || photo} alt="staffPhoto" />
        <div className={classes.teamList__container__staffInfo__text}>
          <div className={classes.teamList__container__staffInfo__name}>
            {element.fullname || element.name}
          </div>
          <div className={classes.teamList__container__staffInfo__post}>
            {element?.info?.post}
          </div>
        </div>
      </div>
      <div className={classes.teamList__container__status}>
        <UserStatusIcon element={element} />
      </div>
    </Link>
  );
}

export default memo(UsersElementItem);
