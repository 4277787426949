import React, { useContext, useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import { Context } from 'index';

import { FORMAT_TEXT } from 'components/UI/Modals/ModalCreateContract/consts';
import MyDropdown from 'components/UI/MyDropdown/MyDropdown';
import LawyersInput from 'components/UI/MyInput/LawyersInput';

import { TRUE_FALSE_STATUS } from 'utils/consts';
import { reversDate } from 'utils/getDate';
import {
  getSum, getSumRuble, numberToSum, stringToDate,
} from 'utils/getSums';

import css from '../PaymentsTable.module.scss';

function TableCell(props) {
  const {
    header, row, updateFieldHandler, index, additionalData,
  } = props;
  const { modal } = useContext(Context);

  // const [comment, setComment] = useState('');
  const [paidSum, setPaidSum] = useState('');
  const [accountingMonth, setAccountingMonth] = useState('');

  const [one22, setOne22] = useState('');
  const [two22, setTwo22] = useState('');
  const [three22, setThree22] = useState('');
  const [four22, setFour22] = useState('');

  const [one23, setOne23] = useState('');
  const [two23, setTwo23] = useState('');
  const [three23, setThree23] = useState('');
  const [four23, setFour23] = useState('');

  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ

  // useEffect(() => {
  //   // if (row?.comment) {
  //   //   setComment(row?.comment);
  //   // }
  //   if (row?.paid_sum) {
  //     setPaidSum(row.paid_sum);
  //   }
  //   if (row?.accounting_month) {
  //     setAccountingMonth(row?.accounting_month);
  //   }

  //   if (row?.quarter_payments) {
  //     setOne22(numberToSum(row.quarter_payments[0].sum, true));
  //     setTwo22(numberToSum(row.quarter_payments[1].sum, true));
  //     setThree22(numberToSum(row.quarter_payments[2].sum, true));
  //     setFour22(numberToSum(row.quarter_payments[3].sum, true));

  //     setOne23(numberToSum(row.quarter_payments[4].sum, true));
  //     setTwo23(numberToSum(row.quarter_payments[5].sum, true));
  //     setThree23(numberToSum(row.quarter_payments[6].sum, true));
  //     setFour23(numberToSum(row.quarter_payments[7].sum, true));
  //   }
  // }, [row]);

  // const handleDateChange = (event) => {
  //   const inputDate = event.target.value;
  //   const datePattern = /^([0-9]{2}\.){2}[0-9]{4}$/; // Проверяем, что дата введена в формате "дд.мм.гггг"

  //   // Если дата введена полностью, обновляем состояние компонента и отправляем запрос на сервер
  //   if (datePattern.test(inputDate)) {
  //     setAccountingMonth(stringToDate(event.target.value));
  //     updateFieldHandler(row, 'accounting_month', event.target.value);
  //   } else {
  //     // Если дата не введена полностью, обновляем состояние компонента
  //     // без отправки запроса на сервер
  //     setAccountingMonth(stringToDate(event.target.value));
  //   }
  // };

  // const openModal = (event, fieldName) => {
  //   if (event.key === 'Enter') {
  //     let close;
  //     let callback;

  //     const allPayments = [
  //       { name: '1кв22', sum: getSum(one22) },
  //       { name: '2кв22', sum: getSum(two22) },
  //       { name: '3кв22', sum: getSum(three22) },
  //       { name: '4кв22', sum: getSum(four22) },

  //       { name: '1кв23', sum: getSum(one23) },
  //       { name: '2кв23', sum: getSum(two23) },
  //       { name: '3кв23', sum: getSum(three23) },
  //       { name: '4кв23', sum: getSum(four23) },
  //     ];

  //     if (fieldName === 'comment') {
  //       // callback = () => updateFieldHandler(row, 'comment', comment);
  //       // close = () => setComment(row?.comment)
  //     } else if (fieldName === 'paid_sum') {
  //       callback = () => updateFieldHandler(row, 'paid_sum', paidSum);
  //     } else {
  //       callback = () => updateFieldHandler(row, 'quarter_payments', allPayments);
  //     }

  //     modal.setModalConfirmVisible(true);
  //     modal.setModalConfirmProps({
  //       text: `Вы внесли изменения в реестр Проект №${row.project_id} \n Название ${row.project_name}`,
  //       save: true,
  //       click: callback,
  //       closeClick: close,
  //     });
  //   }
  // };

  const className = [
    header.id === 'sum' || header.id === 'payment_date' ? css.payment : '',

  ].join(' ');

  return (
    <div
      className={className}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {header.id === 'contractor' && (
      <div>
        {row?.blogger[0]?.id ? row?.blogger[0]?.name : `Исполнитель ${index + 1}`}
      </div>
      )}

      {header.id === 'dates' && (
        <div className={css.dates}>
          <div>с {reversDate(additionalData?.date_start)}</div>
          <div>по {reversDate(additionalData?.date_end)}</div>
        </div>
      )}

      {header.id === 'link' && (
        row?.platform?.map((item) => (
          <div
            className={css.linkCell}
            key={item.id || item.tempId}
            style={{ height: item?.publication?.length ? item?.publication?.length * 35 : 35 }}
          >
            <span className={css.linkText}>{item?.link}</span>
          </div>
        ))
      )}

      {header.id === 'format' && (
        row?.platform?.map((item) => (
          <div
            className={css.linkCell}
            key={item.id || item.tempId}
            style={{ height: item?.publication?.length ? item?.publication?.length * 35 : 35 }}
          >
            {item.publication.map((publication) => (
              <div key={item.id || item.tempId}>
                {publication?.type?.name} - {publication?.amount}/{publication?.timing}
              </div>
            ))}
          </div>
        ))
      )}

      {header.id === 'delete_period' && (
        row?.platform?.map((item) => (
          <div
            className={css.linkCell}
            key={item.id || item.tempId}
            style={{ height: item?.publication?.length ? item?.publication?.length * 35 : 35 }}
          >
            {item.publication.map((publication) => (
              <div key={item.id || item.tempId}>
                {publication?.non_removal_period} {publication?.non_removal_period_units}
              </div>
            ))}
          </div>
        ))
      )}

      {header.id === 'rights' && (
        row?.platform?.map((item) => (
          <div
            className={css.linkCell}
            key={item?.id || item?.tempId}
            style={{ height: item?.publication?.length ? item?.publication?.length * 35 : 35 }}
          >
            {item?.is_assignment
              ? `Да - ${item?.assignment_type?.name} / ${item?.assignment_comment}`
              : 'Нет'}
          </div>
        ))
      )}

      {header.id === 'repost' && (
        row?.platform?.map((item) => (
          <div
            className={css.linkCell}
            key={item?.id || item?.tempId}
            style={{ height: item?.publication?.length ? item?.publication?.length * 35 : 35 }}
          >
            {item?.is_repost
              ? 'Да'
              : 'Нет'}
          </div>
        ))
      )}

      {header.id === 'kpi' && (
        <div className={css.dates}>
          <div>{row?.kpi}</div>
        </div>
      )}

      {header.id === 'period' && (
        <div className={css.dates}>
          <div>с {reversDate(row?.placement_period?.date_start)}</div>
          <div>по {reversDate(row?.placement_period?.date_end)}</div>
        </div>
      )}

      {header.id === 'exclusive' && (
        <div className={css.dates}>
          <div>{row?.is_exclusive ? 'Да' : 'Нет'}</div>
        </div>
      )}

      {header.id === 'is_contract' && (
        <div className={css.dates}>
          <div>{row?.is_contract ? 'Да' : 'Нет'}</div>
        </div>
      )}

      {header.id === 'client_legal_entity' && (
        <div className={css.dates}>
          <div>{row?.is_contract ? row?.client_legal_entity?.name : 'Физическая оплата'}</div>
        </div>
      )}

      {header.id === 'contractor_legal_entity' && (
        <div className={css.dates}>
          <div>{row?.contractor_legal_entity?.name}</div>
        </div>
      )}

      {header.id === 'sum' && (
        <div className={css.dates}>
          <div>{numberToSum(row?.payment?.total_sum, true)}</div>
        </div>
      )}

      {header.id === 'nds' && (
        <div className={css.dates}>
          <div>{row?.payment?.is_nds ? numberToSum(row?.payment?.nds_sum, true) : 'Нет'}</div>
        </div>
      )}

      {header.id === 'payment_date' && (
        row?.payment?.payment_dates?.map((item) => (
          <div
            className={css.linkCell}
            key={item.id || item.tempId}
            // style={{ height: item?.publication?.length ? item?.publication?.length * 35 : 35 }}
          >
            <span className={css.linkText}>{reversDate(item?.date)}</span>
          </div>
        ))
      )}

      {header.id === 'payment_sum' && (
        row?.payment?.payment_dates?.map((item) => (
          <div
            className={css.linkCell}
            key={item.id || item.tempId}
            // style={{ height: item?.publication?.length ? item?.publication?.length * 35 : 35 }}
          >
            <span className={css.linkText}>{numberToSum(item?.sum, true)}</span>
          </div>
        ))
      )}

      {header.id === 'sum_without_nds' && (
        <div className={css.dates}>
          <div>{numberToSum(row?.payment?.client_sum_without_tax, true) }</div>
        </div>
      )}

      {header.popover
        && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableAutoFocus
          disableEnforceFocus
        >
          <div className={css.popoverText}>{header?.popover2 ? row[header?.popover]?.[header?.popover2] : row[header?.popover]}</div>
        </Popover>
        )}

      {/* {header.id === 'adesk' && (
        <MyDropdown
          className={css.dropdown}
          classNameMenu={css.dropdown__inner}
          selected={row[header.id]}
          setSelected={(element) => updateFieldHandler(row, 'adesk', element)}
          list={TRUE_FALSE_STATUS.LIST}
          noArrow
          noSelection
        />
      )}

      {header.id === 'sum' && (
        row?.payment_sum?.map((item) => (
          <div key={item.id} className={css.paymentCell}>
            {numberToSum(item.sum, true)}
          </div>
        ))
      )}

      {header.id === 'payment_date' && (
        row?.payment_date?.map((item) => (
          <div key={item.id} className={css.paymentCell}>
            {reversDate(item.date)}
          </div>
        ))
      )}

      {header.id === 'accounting_month' && (

      <LawyersInput
        disabled
        className={css.input}
        value={accountingMonth}
        onChange={handleDateChange}
      />

      )}

      {header.id === 'comment' && (

        row?.comment?.map((item) => (
          <div key={item.id} className={css.paymentCell}>
            <LawyersInput
              className={css.input}
              value={item.comment}
              disabled
              // setValue={setComment}
              style={
                item.comment.length * 7 > 140
                  ? { width: item.comment.length * 7 }
                  : { width: 140 }
          }
              onKeyPress={(event) => openModal(event, 'comment')}
            />
          </div>
        ))

      )}

      {header.id === 'paid_sum' && (
        <LawyersInput
          className={css.input}
          value={numberToSum(paidSum)}
          onChange={(event) => setPaidSum(getSumRuble(event.target.value))}
          onKeyDown={(event) => openModal(event, 'paid_sum')}
          disabled={row?.type?.id !== 0 && row?.type?.id !== 1 && row?.type?.id !== 7}
          style={
            paidSum.length * 7 > 100
              ? { width: paidSum.length * 7 }
              : { width: 100 }
          }
        />
      )}

      {header.id === 'project_name' && (
      <>
        <p
          style={{ margin: 0 }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {row?.project_name}
        </p>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={row?.project_name?.length >= 28 ? open : false}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableAutoFocus
          disableEnforceFocus
        >
          <div className={css.popoverText}>{row?.project_name}</div>
        </Popover>
      </>
      )}

      {header.id === '1q22' && (
        <LawyersInput
          className={css.input}
          value={numberToSum(one22, true)}
          onChange={(event) => setOne22(getSumRuble(event.target.value))}
          style={
            one22.length * 7 > 100
              ? { width: one22.length * 7 }
              : { width: 100 }
          }
          onKeyPress={(event) => openModal(event, '1q22')}
        />
      )}

      {header.id === '2q22' && (
        <LawyersInput
          className={css.input}
          value={numberToSum(two22, true)}
          onChange={(event) => setTwo22(getSumRuble(event.target.value))}
          style={
            two22.length * 7 > 100
              ? { width: two22.length * 7 }
              : { width: 100 }
          }
          onKeyPress={(event) => openModal(event, '2q22')}
        />
      )}

      {header.id === '3q22' && (
        <LawyersInput
          className={css.input}
          value={numberToSum(three22, true)}
          onChange={(event) => setThree22(getSumRuble(event.target.value))}
          style={
            three22.length * 7 > 100
              ? { width: three22.length * 7 }
              : { width: 100 }
          }
          onKeyPress={(event) => openModal(event, '3q22')}
        />
      )}

      {header.id === '4q22' && (
        <LawyersInput
          className={css.input}
          value={numberToSum(four22, true)}
          onChange={(event) => setFour22(getSumRuble(event.target.value))}
          style={
            four22.length * 7 > 100
              ? { width: four22.length * 7 }
              : { width: 100 }
          }
          onKeyPress={(event) => openModal(event, '4q22')}
        />
      )}

      {header.id === '1q23' && (
        <LawyersInput
          className={css.input}
          value={numberToSum(one23, true)}
          onChange={(event) => setOne23(getSumRuble(event.target.value))}
          style={
            one23.length * 7 > 100
              ? { width: one23.length * 7 }
              : { width: 100 }
          }
          onKeyPress={(event) => openModal(event, '1q23')}
        />
      )}

      {header.id === '2q23' && (
        <LawyersInput
          className={css.input}
          value={numberToSum(two23, true)}
          onChange={(event) => setTwo23(getSumRuble(event.target.value))}
          style={
            two23.length * 7 > 100
              ? { width: two23.length * 7 }
              : { width: 100 }
          }
          onKeyPress={(event) => openModal(event, '2q23')}
        />
      )}

      {header.id === '3q23' && (
        <LawyersInput
          className={css.input}
          value={numberToSum(three23, true)}
          onChange={(event) => setThree23(getSumRuble(event.target.value))}
          style={
            three23.length * 7 > 100
              ? { width: three23.length * 7 }
              : { width: 100 }
          }
          onKeyPress={(event) => openModal(event, '3q23')}
        />
      )}

      {header.id === '4q23' && (
        <LawyersInput
          className={css.input}
          value={numberToSum(four23, true)}
          onChange={(event) => setFour23(getSumRuble(event.target.value))}
          style={
            four23.length * 7 > 100
              ? { width: four23.length * 7 }
              : { width: 100 }
          }
          onKeyPress={(event) => openModal(event, '4q23')}
        />
      )} */}
    </div>
  );
}

export default TableCell;
