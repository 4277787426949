// @ts-nocheck
import React, {
  useContext, useEffect, useRef,
  useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../index';

import { getClients, getClientsStat } from 'API/ClientAPI';
import { check } from 'API/UserAPI';

import PageTitle from 'components/UI/PageTitle/PageTitle';

import ModalCreateClient from '../../UI/Modals/ModalCreateClient/ModalCreateClient';
import NameFilter from '../../UI/NameFilter/NameFilter';

import { useFetching } from '../../../hooks/useFetching';
import { useLogout } from '../../../hooks/useLogout';

import TeamElement from '../TeamsPage/TeamElements/TeamElement';

import AllClientsWindow from './components/AllClientsWindow';

import classes from './ClientsPage.module.css';

const ClientsPage = observer(() => {
  const { modal } = useContext(Context);
  const isFirstRender = useRef(true);
  const [logout] = useLogout();

  const [update, setUpdate] = useState(false);

  const [clientsList, setClientList] = useState([]);

  const [clientStats, setClientStats] = useState([]);

  const [brands, setBrands] = useState([]);
  const [mediaAgency, setMediaAgency] = useState([]);
  const [independentAgency, setIndependentAgency] = useState([]);

  const [fetchClientsStat] = useFetching(async () => {
    const data = await getClientsStat();
    setClientStats(data);
  });

  const [fetchBrands, brandsLoading] = useFetching(async (filter) => {
    const data = await getClients(0, filter);
    setBrands(data);
  });

  const [fetchMediaAgency, mediaAgencyLoading] = useFetching(async (filter) => {
    const data = await getClients(1, filter);
    setMediaAgency(data);
  });

  const [fetchIndependentAgency, independentAgencyLoading] = useFetching(
    async (filter) => {
      const data = await getClients(2, filter);
      setIndependentAgency(data);
    },
  );

  const [fetchClientsList, ClientsListLoading] = useFetching(async () => {
    const data = await getClients();
    setClientList(data);
  });

  useEffect(() => {
    check()
      .then(() => {
        fetchBrands();

        fetchMediaAgency();

        fetchIndependentAgency();

        fetchClientsStat();

        fetchClientsList();
      })
      .catch((error) => logout(error));
  }, []);

  const openHandler = () => {
    modal.setModalCreateClientVisible(true);
    modal.setModalCreateClientProps({
      fetchBrands,
      fetchMediaAgency,
      fetchIndependentAgency,
      fetchClientsStat,
      fetchClientsList,
    });
  };

  const click = (event, name) => {
    if (event?.key === 'Enter' || event.type === 'click') {
      check()
        .then(() => {
          fetchBrands(name).then(() => {
            if (name) {
              setUpdate(true);
            }
          });
          fetchMediaAgency(name).then(() => {
            if (name) {
              setUpdate(true);
            }
          });
          fetchIndependentAgency(name).then(() => {
            if (name) {
              setUpdate(true);
            }
          });
        })
        .catch((error) => logout(error));
    }
  };

  if (isFirstRender.current) {
    if (
      ClientsListLoading
      || brandsLoading
      || mediaAgencyLoading
      || independentAgencyLoading
    ) {
      return <Spinner animation="grow" />;
    }
    isFirstRender.current = false;
  }

  return (
    <>
      <PageTitle>Клиенты и контрагенты</PageTitle>

      <div className={classes.container}>
        <div style={{ width: '100%' }}>
          <NameFilter click={click} list={clientsList} type="client" />

          <TeamElement
            array={brands}
            text="Бренд"
            client
            update={update}
            setUpdate={setUpdate}
          />
          <TeamElement
            array={mediaAgency}
            text="Медийное агентство"
            client
            update={update}
            setUpdate={setUpdate}
          />
          <TeamElement
            array={independentAgency}
            text="Независимое агентство"
            client
            update={update}
            setUpdate={setUpdate}
          />
        </div>

        <AllClientsWindow openHandler={openHandler} clientStats={clientStats} />
      </div>

      <ModalCreateClient
        show={modal.modalCreateClientVisible}
        onHide={() => modal.setModalCreateClientVisible(false)}
        props={modal.modalCreateClientProps}
      />
    </>
  );
});

export default ClientsPage;
