import React from 'react';

import classes from './CheckBox.module.scss';

function NewMyCheckbox(props) {
  const {
    status, style, onClick, onChange, disabled,
  } = props;

  return (
    <label className={classes.new__cheсkbox__container} style={style}>
      <input
        type="checkbox"
        checked={status}
        className={classes.new__cheсkbox__input}
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
      />
      <span className={classes.new__cheсkbox__item} />
    </label>
  );
}

export default NewMyCheckbox;
