import React from 'react';

import ClientString from 'components/UI/ClientString/ClientString';

import { numberToSum } from 'utils/getSums';

import classes from '../OneClientPage.module.css';

function ProjectsClientInfo(props) {
  const { projectsStats } = props;

  return (
    <div className={classes.wrapper__info}>
      <ClientString text="Всего проектов" count={projectsStats?.total} all />

      {projectsStats?.statuses?.map((status) => (
        <ClientString
          key={status.id}
          text={status.name}
          count={status.count}
          style={{ border: 'none', padding: '0 0 15px 0' }}
        />
      ))}

      <ClientString
        text="Общая сумма сделок"
        count={numberToSum(projectsStats?.sum)}
        all
        style={{ marginTop: 5 }}
      />
    </div>
  );
}

export default ProjectsClientInfo;
