// @ts-nocheck
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { fetchNewAmountNotification } from 'API/LawyersAPI/NotificationAPI';
import { check } from 'API/UserAPI';

import { useFetching } from 'hooks/useFetching';

import {
  BLOGGERS_ROUTE,
  CLIENTS_ROUTE,
  CONTRACTS_MAIN_ROUTE,
  COST_ROUTE,
  FINANCE_MAIN_ROUTE,
  LEGAL_ENTITY_ROUTE,
  LOGIN_ROUTE,
  MAIN_ROUTE,
  NOTIFICATION_ROUTE,
  PAYMENT_STATUS_ROUTE,
  PROJECT_ACCOUNTING_ROUTE,
  REPORT_ROUTE,
  SETTING_ROUTE,
  TASKS_ROUTE,
  TEAMS_ROUTE,
} from 'utils/consts';

import whiteLogo from '../../../assets/images/white-logo.svg';
import ModalConfirm from '../Modals/ModalConfirm/ModalConfirm';

import BloggersIcon from './assets/Bloggers.svg';
import ClientsIcon from './assets/Clients.svg';
import CostIcon from './assets/Cost.svg';
import DocumentsIcon from './assets/Documents.svg';
import LogoutIcon from './assets/Logout.svg';
import MainIcon from './assets/Main.svg';
import NotificationOffIcon from './assets/NotificationOffIcon.svg';
import NotificationOnIcon from './assets/NotificationOnIcon.svg';
import PaymentStatusIcon from './assets/PaymentStatus.svg';
import ProjectRegistryIcon from './assets/ProjectRegistry.svg';
import ReportIcon from './assets/Report.svg';
import SettingsIcon from './assets/Settings.svg';
import TasksIcon from './assets/Tasks.svg';
import TeamsIcon from './assets/Teams.svg';
import ExitItem from './components/ExitItem';
import NewNavbarItem from './components/NewNavbarItem';

import classes from './Navbar.module.css';

const Navbar = observer(() => {
  const {
    user,
    mainMarketing,
    contracts,
    projects,
    projectsFinance,
    reportProjects,
    reportTasks,
    reportNews,
    toast,
    notifications,
    comments,
    modal,
  } = useContext(Context);

  const [active, setActive] = useState(true);
  const notificationTimer = useRef(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [fetchingNewAmountNotification, , isError] = useFetching(async () => {
    const data = await fetchNewAmountNotification();
    notifications.setIsNewNotifications(data);
  });

  useEffect(() => {
    notificationTimer.current = null;

    if (!notifications.isNewNotifications) {
      notificationTimer.current = setInterval(() => {
        fetchingNewAmountNotification();
      }, 15000);
    } else {
      clearInterval(notificationTimer.current);
    }

    return () => {
      clearInterval(notificationTimer.current);
    };
  }, [notifications.isNewNotifications]);

  useEffect(() => {
    if (isError) {
      check().then(() => {
        fetchingNewAmountNotification();
      });
    }
  }, [isError]);

  const toggleNavbar = () => {
    setActive(!active);
  };

  const changePage = (newPathname) => {
    if (newPathname !== pathname) {
      mainMarketing.setProjectsPage(1);
      mainMarketing.setProjects([]);

      contracts.setContractsPage(1);
      contracts.setContracts([]);

      contracts.setPaymentStatusContractsPage(1);
      contracts.setPaymentStatusContracts([]);

      projectsFinance.setProjectsPage(1);
      projectsFinance.setProjects([]);

      projectsFinance.setCostsPage(1);
      projectsFinance.setCosts([]);

      projects.setProjectsPage(1);
      projects.setProjects([]);
      projects.setProjectsFilter(undefined);

      reportProjects.setReportProjectsPage(1);
      reportProjects.setReportProjects([]);

      reportTasks.setReportTasksPage(1);
      reportTasks.setReportTasks([]);

      reportNews.setReportNewsPage(1);
      reportNews.setReportNews([]);

      notifications.setNotificationsPage(1);
      notifications.setNotifications([]);

      comments.setCommentsPage(1);
      comments.setComments([]);

      toast.setToastInfo('');

      localStorage.removeItem('oneClientPageStep');
    }
  };

  const logout = () => {
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите выйти из системы?',
      click: () => {
        user.setIsAuth(false);
        localStorage.clear();
        navigate(LOGIN_ROUTE);
      },
    });
    modal.setModalConfirmVisible(true);
  };

  // От состояния active зависит какой стиль применяем
  return user.isAuth ? (
    <nav className={active ? classes.new__navbar : classes.new__navbar__hidden}>
      <div
        className={
          active ? classes.navbar__burger : classes.navbar__burger__hidden
        }
        onClick={toggleNavbar}
      />

      <div
        className={
          active ? classes.navbar__inner : classes.navbar__inner__hidden
        }
      >
        <img className={classes.navbar__image} src={whiteLogo} alt="" />

        <ul className={classes.navbar__container}>
          <NewNavbarItem
            path={MAIN_ROUTE}
            title="Проекты"
            onClick={() => changePage(MAIN_ROUTE)}
            icon={MainIcon}
          />

          <NewNavbarItem
            path={CONTRACTS_MAIN_ROUTE}
            title="Документы"
            onClick={() => changePage(CONTRACTS_MAIN_ROUTE)}
            icon={DocumentsIcon}
          />

          {/* <NewNavbarItem
            path={PAYMENT_STATUS_ROUTE}
            title="Статус оплат"
            onClick={() => changePage(PAYMENT_STATUS_ROUTE)}
            icon={PaymentStatusIcon}
          /> */}

          <NewNavbarItem
            path={COST_ROUTE}
            title="Заявки на оплату"
            onClick={() => changePage(COST_ROUTE)}
            icon={CostIcon}
          />

          <NewNavbarItem
            path={NOTIFICATION_ROUTE}
            title="Уведомления"
            onClick={() => changePage(NOTIFICATION_ROUTE)}
            icon={notifications.isNewNotifications ? NotificationOnIcon : NotificationOffIcon}
            notificationTimer={notificationTimer}
          />

          <NewNavbarItem
            path={TASKS_ROUTE}
            title="Мои задачи"
            onClick={() => changePage(TASKS_ROUTE)}
            icon={TasksIcon}
          />

          {(user.user.role.id === 1 || user.user.role.id === 0 || user.department === 2)
              && (
              <NewNavbarItem
                path={REPORT_ROUTE}
                title="Отчет"
                onClick={() => changePage(REPORT_ROUTE)}
                icon={ReportIcon}
              />
              )}

          {/* {(user.user.role.id === 1 || user.user.role.id === 0 || user.department === 2) && (
          <NewNavbarItem
            path={FINANCE_MAIN_ROUTE}
            title="Реестр проектов"
            onClick={() => changePage(FINANCE_MAIN_ROUTE)}
            icon={ProjectRegistryIcon}
          />
          )} */}

          <NewNavbarItem
            path={PROJECT_ACCOUNTING_ROUTE}
            title="Учёт проектов"
            onClick={() => changePage(PROJECT_ACCOUNTING_ROUTE)}
            icon={ProjectRegistryIcon}
          />

          {/* <NewNavbarItem
            path={FINANCE_MAIN_ROUTE}
            title="Учёт проектов"
            onClick={() => changePage(FINANCE_MAIN_ROUTE)}
            icon={ProjectRegistryIcon}
          /> */}

          {/* {user.user.role.id !== 2 &&
            (user.department === 0 || user.department === 3) && (
              <NewNavbarItem
                path={SALARIES_ROUTE}
                title='Зарплаты'
                onClick={() => changePage(SALARIES_ROUTE)}
                icon={SalariesIcon}
              />
            )} */}

          <NewNavbarItem
            path={LEGAL_ENTITY_ROUTE}
            title="Юридические лица"
            onClick={() => changePage(LEGAL_ENTITY_ROUTE)}
            icon={TeamsIcon}
          />

          <NewNavbarItem
            path={CLIENTS_ROUTE}
            title="Клиенты и контрагенты"
            onClick={() => changePage(CLIENTS_ROUTE)}
            icon={ClientsIcon}
          />

          <NewNavbarItem
            path={BLOGGERS_ROUTE}
            title="Блогеры"
            onClick={() => changePage(BLOGGERS_ROUTE)}
            icon={BloggersIcon}
          />

          {user.user.role.id !== 2 && (
            <NewNavbarItem
              path={TEAMS_ROUTE}
              title="Сотрудники"
              onClick={() => changePage(TEAMS_ROUTE)}
              icon={TeamsIcon}
            />
          )}

          <NewNavbarItem
            path={SETTING_ROUTE}
            title="Настройки"
            onClick={() => changePage(SETTING_ROUTE)}
            icon={SettingsIcon}
          />

          <hr className={classes.line} />

          <ExitItem
            title="Выйти из системы"
            onClick={() => logout()}
            icon={LogoutIcon}
          />
        </ul>
      </div>

      <ModalConfirm
        show={modal.modalConfirmVisible}
        onHide={() => modal.setModalConfirmVisible(false)}
        props={modal.modalConfirmProps}
      />
    </nav>
  ) : (
    <div />
  );
});

export default Navbar;
