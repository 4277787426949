import React from 'react';

import DeleteBtn from '../Btns/DeleteBtn';

import classes from './DeleteUser.module.scss';

function DeleteUser(props) {
  const { onClick, deleteUser } = props;

  return (
    <div className={classes.deleteUser__container}>
      <div className={classes.deleteUser__container__text}>
        Удаление сотрудника
      </div>
      <DeleteBtn onClick={onClick} deleteUser text="Удалить" />
    </div>
  );
}

export default DeleteUser;
