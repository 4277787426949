import React, { memo } from 'react';

import classes from './MyTextArea.module.css';

function NewTextArea(props) {
  const {
    value,
    setValue,
    onChange,
    onClick,
    placeholder,
    style,
    disabled,
    className,
    onKeyPress,
  } = props;

  const changeHandler = (event) => {
    setValue(event.target.value);
  };

  return (
    <textarea
      className={[classes.new__textarea, className].join(' ')}
      style={style}
      value={value}
      onChange={onChange || changeHandler}
      onClick={onClick}
      placeholder={placeholder}
      disabled={disabled}
      onKeyPress={onKeyPress}
    />
  );
}

export default memo(NewTextArea);
