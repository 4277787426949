// @ts-nocheck
import React from 'react';

import TeamElementItem from 'components/General/TeamsPage/TeamElements/TeamElementItem';

import {
  ONE_BLOGGER_ROUTE,
  ONE_CLIENT_ROUTE,
  ONE_LEGAL_ENTITY_ROUTE,
  STAFF_CARD_ROUTE,
} from 'utils/consts';

import image from '../../../../assets/icons/Lawyers/TeamsArrow.svg';

import classes from '../LegalEntityPage.module.scss';

function LegalElement(props) {
  const {
    array, client, blogger, legalEntity,
  } = props;

  let route = STAFF_CARD_ROUTE;

  if (client) {
    route = ONE_CLIENT_ROUTE;
  } else if (blogger) {
    route = ONE_BLOGGER_ROUTE;
  } else if (legalEntity) {
    route = ONE_LEGAL_ENTITY_ROUTE;
  }

  return (
    <div className={classes.wrapper_list}>
      <div>
        {array.map((listElement) => (
          <TeamElementItem
            key={listElement.id}
            element={listElement}
            image={image}
            to={route}
          />
        ))}
      </div>
    </div>
  );
}

export default LegalElement;
