import { $authHost } from 'API';

export const fetchOneLawyerProjects = async (id) => {
  const { data } = await $authHost.get(`/lawyer/staff_page/staff/${id}/contracts/`);
  return data;
};

export const sendTransferProjects = async (id, sentData) => {
  const { data } = await $authHost.post(`/staff_page/staff/${id}/project_transfer/`, sentData);
  return data;
};

export const patchOneContractPublication = async (id, data) => {
  await $authHost.patch(`/lawyer/contract_card/publication/${id}/`, data);
};
