// @ts-nocheck
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import FiltersPanel from 'components/UI/FiltersPanel/FiltersPanel';

import { useMultiple } from 'hooks/useMultiple';

import { FIELDS } from './consts';

const FinanceFilter = observer((props) => {
  const {
    isOpen,
    setIsOpen,
    projectList,
    responsibleList,
    lawyersList,
    documentList,
    allLegalEntity,
    fetchProjects,
  } = props;

  const { projectsFinance, filters } = useContext(Context);

  const controller = new AbortController();

  const [
    project,
    changeProject,
    selectedProjectList,
    addSelectedProject,
    deleteSelectedProject,
    setSelectedProjectList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    contractFormat,,
    selectedContractFormatList,
    addContractFormat,
    deleteContractFormat,
    setSelectedContractFormatList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    ourLegalEntity,
    changeOurLegalEntity,
    selectedOurLegalEntityList,
    addOurLegalEntity,
    deleteOurLegalEntity,
    setSelectedOurLegalEntityList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    clientLegalEntity,
    changeClientLegalEntity,
    selectedClientLegalEntityList,
    addClientLegalEntity,
    deleteClientLegalEntity,
    setSelectedClientLegalEntityList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    manager,
    changeManager,
    selectedManagerList,
    addManager,
    deleteManager,
    setSelectedManagerList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    lawyer,
    changeLawyer,
    selectedLawyerList,
    addLawyer,
    deleteLawyer,
    setSelectedLawyerList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    document,
    changeDocument,
    selectedDocumentList,
    addDocument,
    deleteDocument,
    setSelectedDocumentList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    contractStatus,,
    selectedContractStatusList,
    addContractStatus,
    deleteContractStatus,
    setSelectedContractStatusList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    contractClosedStatus,,
    selectedContractClosedStatusList,
    addContractClosedStatus,
    deleteContractClosedStatus,
    setSelectedContractClosedStatusList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    statusScore,,
    selectedStatusScoreList,
    addStatusScore,
    deleteStatusScore,
    setSelectedStatusScoreList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    projectStatus,,
    selectedProjectStatusList,
    addProjectStatus,
    deleteProjectStatus,
    setSelectedProjectStatusList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    quarterClosing,,
    selectedQuarterClosingList,
    addQuarterClosing,
    deleteQuarterClosing,
    setSelectedQuarterClosingList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    quarterStart,,
    selectedQuarterStartList,
    addQuarterStart,
    deleteQuarterStart,
    setSelectedQuarterStartList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    quarterEnd,,
    selectedQuarterEndList,
    addQuarterEnd,
    deleteQuarterEnd,
    setSelectedQuarterEndList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  // Состояние фильтра из стора

  useEffect(() => {
    filters.setFinanceProjectFilter(selectedProjectList);
  }, [selectedProjectList]);

  useEffect(() => {
    filters.setFinanceContractFormatFilter(selectedContractFormatList);
  }, [selectedContractFormatList]);

  useEffect(() => {
    filters.setFinanceClientLegalEntityFilter(selectedClientLegalEntityList);
  }, [selectedClientLegalEntityList]);

  useEffect(() => {
    filters.setFinanceContractorLegalEntityFilter(selectedOurLegalEntityList);
  }, [selectedOurLegalEntityList]);

  useEffect(() => {
    filters.setFinanceManagerFilter(selectedManagerList);
  }, [selectedManagerList]);

  useEffect(() => {
    filters.setFinanceLawyerFilter(selectedLawyerList);
  }, [selectedLawyerList]);

  useEffect(() => {
    filters.setFinanceDocFilter(selectedDocumentList);
  }, [selectedDocumentList]);

  useEffect(() => {
    filters.setFinanceContractStatusFilter(selectedContractStatusList);
  }, [selectedContractStatusList]);

  useEffect(() => {
    filters.setFinanceContractClosedStatusFilter(
      selectedContractClosedStatusList,
    );
  }, [selectedContractClosedStatusList]);

  useEffect(() => {
    filters.setFinanceStatusScoreFilter(selectedStatusScoreList);
  }, [selectedStatusScoreList]);

  useEffect(() => {
    filters.setFinanceProjectStatusFilter(selectedProjectStatusList);
  }, [selectedProjectStatusList]);

  useEffect(() => {
    filters.setFinanceQuarterClosingFilter(selectedQuarterClosingList);
  }, [selectedQuarterClosingList]);

  useEffect(() => {
    filters.setFinanceQuarterStartFilter(selectedQuarterStartList);
  }, [selectedQuarterStartList]);

  useEffect(() => {
    filters.setFinanceQuarterEndFilter(selectedQuarterEndList);
  }, [selectedQuarterEndList]);

  useLayoutEffect(() => {
    setSelectedProjectList(filters.financeProjectFilter);

    setSelectedContractFormatList(filters.financeContractFormatFilter);

    setSelectedOurLegalEntityList(filters._financeContractFormatFilter);

    setSelectedClientLegalEntityList(filters.financeClientLegalEntityFilter);

    setSelectedManagerList(filters.financeManagerFilter);
    setSelectedLawyerList(filters.financeLawyerFilter);
    setSelectedDocumentList(filters.financeDocFilter);

    setSelectedContractStatusList(filters.financeContractStatusFilter);
    setSelectedContractClosedStatusList(
      filters.financeContractClosedStatusFilter,
    );
    setSelectedStatusScoreList(filters.financeStatusScoreFilter);
    setSelectedProjectStatusList(filters.financeProjectStatusFilter);

    setSelectedQuarterClosingList(filters.financeQuarterClosingFilter);
    setSelectedQuarterStartList(filters.financeQuarterStartFilter);
    setSelectedQuarterEndList(filters.financeQuarterEndFilter);
  }, []);

  const updateFieldHandler = (payload) => {
    filters.setFinanceFilter({
      ...filters.financeFilter,
      ...payload,
    });
  };

  const applyFiltersHandler = (event) => {
    event.preventDefault();

    setIsOpen(false);

    if (fetchProjects) {
      projectsFinance.setProjectsFilter({
        ...filters.financeFilter,
        project: selectedProjectList,
        contract_type: selectedContractFormatList,
        our_legal_entity: selectedOurLegalEntityList,
        client_legal_entity: selectedClientLegalEntityList,
        responsible: selectedManagerList,
        lawyer: selectedLawyerList,
        doc_manager: selectedDocumentList,
        contract_status: selectedContractStatusList,
        closed_status: selectedContractClosedStatusList,
        bill_status: selectedStatusScoreList,
        project_manager_status: selectedProjectStatusList,
        quarter_closing: selectedQuarterClosingList,
        quarter_start: selectedQuarterStartList,
        quarter_end: selectedQuarterEndList,
      });

      projectsFinance.setProjectsPage(1);

      fetchProjects(
        projectsFinance.projectsPage,
        projectsFinance.projectsLimit,
        controller.signal,
        projectsFinance.projectsFilter,
        'update',
      );
    }
  };

  const CancelAllFiltersHandler = () => {
    filters.setFinanceFilter({
      ...filters.financeFilter,
      ak_sum: '',
      bid_sent_date_end: '',
      bid_sent_date_start: '',
      branch_name: '',
      contract_number: '',
      contract_sent_date_deadline: {},
      contract_sent_date_end: '',
      contract_sent_date_start: '',
      contract_sum: '',
      date_take_documents_end: '',
      date_take_documents_start: '',
      deadline_status: {},
      id: '',
      id_status: { id: '', label: '' },
      is_adesk: {},
      is_case: {},
      is_contract_sent: {},
      payment_date_end: '',
      payment_date_start: '',
      quarter_end: '',
      quarter_start: '',
      service_end: '',
      service_end_end: '',
      service_end_start: '',
      service_start: '',
    });

    filters.setFinanceProjectFilter([]);
    filters.setFinanceContractFormatFilter([]);
    filters.setFinanceClientLegalEntityFilter([]);
    filters.setFinanceContractorLegalEntityFilter([]);
    filters.setFinanceManagerFilter([]);
    filters.setFinanceLawyerFilter([]);
    filters.setFinanceDocFilter([]);
    filters.setFinanceContractStatusFilter([]);
    filters.setFinanceContractClosedStatusFilter([]);
    filters.setFinanceStatusScoreFilter([]);
    filters.setFinanceProjectStatusFilter([]);
    filters.setFinanceQuarterClosingFilter([]);
    filters.setFinanceQuarterStartFilter([]);
    filters.setFinanceQuarterEndFilter([]);

    setSelectedProjectList([]);
    setSelectedContractFormatList([]);
    setSelectedOurLegalEntityList([]);
    setSelectedClientLegalEntityList([]);
    setSelectedManagerList([]);
    setSelectedLawyerList([]);
    setSelectedDocumentList([]);
    setSelectedContractStatusList([]);
    setSelectedContractClosedStatusList([]);
    setSelectedStatusScoreList([]);
    setSelectedProjectStatusList([]);
    setSelectedQuarterClosingList([]);
    setSelectedQuarterStartList([]);
    setSelectedQuarterEndList([]);

    projectsFinance.setProjectsFilter({
      ...filters.financeFilter,
      project: '',
      contract_type: '',
      our_legal_entity: '',
      client_legal_entity: '',
      responsible: '',
      lawyer: '',
      doc_manager: '',
      contract_status: '',
      closed_status: '',
      bill_status: '',
      project_manager_status: '',
      quarter_closing: '',
      quarter_start: '',
      quarter_end: '',
    });

    projectsFinance.setProjectsPage(1);

    fetchProjects(
      projectsFinance.projectsPage,
      projectsFinance.projectsLimit,
      controller.signal,
      projectsFinance.projectsFilter,
      'update',
    );
  };

  const propsForFiltersPanel = {
    isPanelOpen: isOpen,
    fields: FIELDS,
    values: filters.financeFilter,
    onUpdateField: updateFieldHandler,
    onApplyFilters: applyFiltersHandler,
    projectList,
    managerList: responsibleList,
    lawyersList,
    documentList,
    allLegalEntity,
    ourLegalEntity,
    changeOurLegalEntity,
    clientLegalEntity,
    changeClientLegalEntity,
    addSelectedProject,
    addOurLegalEntity,
    addClientLegalEntity,
    addManager,
    addLawyer,
    addDocument,
    addContractFormat,
    addContractStatus,
    addContractClosedStatus,
    addStatusScore,
    addProjectStatus,
    addQuarterClosing,
    addQuarterStart,
    addQuarterEnd,
    changeProject,
    changeManager,
    changeResponsibleLawyer: changeLawyer,
    changeResponsibleDocument: changeDocument,
    project,
    manager,
    responsibleLawyer: lawyer,
    responsibleDocument: document,
    contractFormat,
    contractStatus,
    contractClosedStatus,
    statusScore,
    projectStatus,
    quarterClosing,
    quarterStart,
    quarterEnd,
    selectedProjectList,
    selectedOurLegalEntityList,
    selectedClientLegalEntityList,
    selectedManagerList,
    selectedLawyerList,
    selectedDocumentList,
    selectedContractFormatList,
    selectedContractStatusList,
    selectedContractClosedStatusList,
    selectedStatusScoreList,
    selectedProjectStatusList,
    selectedQuarterClosingList,
    selectedQuarterStartList,
    selectedQuarterEndList,
    deleteSelectedProject,
    deleteOurLegalEntity,
    deleteClientLegalEntity,
    deleteManager,
    deleteLawyer,
    deleteDocument,
    deleteContractFormat,
    deleteContractStatus,
    deleteContractClosedStatus,
    deleteStatusScore,
    deleteProjectStatus,
    deleteQuarterClosing,
    deleteQuarterStart,
    deleteQuarterEnd,
    CancelAllFiltersHandler,
    financeMain: 'financeMain',
  };

  return (
    <div>
      <FiltersPanel
        {...propsForFiltersPanel}
      />
    </div>
  );
});
export default FinanceFilter;
