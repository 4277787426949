import qs from 'qs';

import { $authHost } from 'API';

import { getDate } from '../utils/getDate';

export const getBloggers = async (filter) => {
  const params = {};

  if (filter) {
    if (filter.filter) {
      params.name = filter.filter;
    }

    if (filter.type >= 0) {
      params.type = filter.type;
    }
  }

  const { data } = await $authHost.get('/blogger_page/blogger/', {
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return data;
};

export const autocompleteBloggers = async () => {
  const { data } = await $authHost.get('autocomplete/bloggers/');
  return data;
};

export const postBlogger = async (sendData) => {
  const { data } = await $authHost.post('/blogger_page/blogger/', sendData);
  return data;
};

export const changeBloggerPhoto = async (blogger_id, photo, options) => {
  const response = await $authHost.post(
    `/blogger_page/blogger/${blogger_id}/set_photo/`,
    photo,
    options,
  );
  return response;
};

export const postBloggerFiles = async (blogger_id, data, options) => {
  const response = await $authHost.post(`/blogger_page/blogger/${blogger_id}/file/`, data, options);
  return response;
};

export const deleteBloggerFile = async (fileId) => {
  await $authHost.delete(`/blogger_page/blogger_file/${fileId}/`);
};

export const getOneBlogger = async (blogger_id) => {
  const { data } = await $authHost.get(`/blogger_page/blogger/${blogger_id}/`);
  return data;
};

export const getOneBloggerContacts = async (blogger_id) => {
  const { data } = await $authHost.get(
    `/blogger_page/blogger/${blogger_id}/contacts/`,
  );
  return data;
};

export const getOneBloggerSocials = async (blogger_id) => {
  const { data } = await $authHost.get(
    `/blogger_page/blogger/${blogger_id}/social_networks/`,
  );
  return data;
};

export const patchOneBlogger = async (blogger_id, sendData) => {
  await $authHost.patch(`/blogger_page/blogger/${blogger_id}/`, sendData);
};

export const deleteBloggerContact = async (contact_id) => {
  await $authHost.delete(`/blogger_page/contacts/${contact_id}/`);
};

export const postBloggerContact = async (dataContact) => {
  const { data } = await $authHost.post('/blogger_page/contacts/', dataContact);
  return data;
};

export const postBloggerSocials = async (blogger_id, dataContact) => {
  const { data } = await $authHost.post(
    `blogger_page/blogger/${blogger_id}/social_networks/`,
    dataContact,
  );
  return data;
};

export const patchBloggerContact = async (id, data) => {
  await $authHost.patch(`/blogger_page/contacts/${id}/`, data);
};

export const getBloggerProjects = async (
  id,
  page,
  limit,
  controller,
  filter,
) => {
  const params = { page, limit };

  if (filter) {
    if (filter.name) {
      params.project_name = filter.name;
    }

    let tempArray = [];

    filter.manager.forEach((manager) => {
      tempArray.push(manager.id);
    });
    params.manager = tempArray;

    tempArray = [];
    filter.responsible.forEach((responsible) => {
      tempArray.push(responsible.id);
    });
    params.responsible = tempArray;

    if (tempArray.length > 0) {
      params.responsible = tempArray;
    }

    if (filter.date_after) {
      params.date_after = getDate(filter.date_after);
    }

    if (filter.date_before) {
      params.date_before = getDate(filter.date_before);
    }

    if (filter.payment_dates_after) {
      params.payment_dates_after = getDate(filter.payment_dates_after);
    }

    if (filter.payment_dates_before) {
      params.payment_dates_before = getDate(filter.payment_dates_before);
    }

    if (filter.status.length > 0) {
      params.status = filter.status;
    }
  }

  const { data } = await $authHost.get(
    `/blogger_page/blogger/${id}/projects/`,
    {
      params,
      signal: controller,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  );
  return data;
};

export const getBloggerProjectsStats = async (id) => {
  const { data } = await $authHost.get(
    `/blogger_page/blogger/${id}/project_stat/`,
  );
  return data;
};

export const deleteBlogger = async (id) => {
  await $authHost.delete(`/blogger_page/blogger/${id}/`);
};

export const getOneBloggerTasks = async (id, filter) => {
  const params = {};

  if (filter) {
    if (filter.status >= 0) {
      params.status = filter.status;
    }

    if (filter.deadline === 0) {
      params.o = 'deadline';
    }
    if (filter.deadline === 1) {
      params.o = '-deadline';
    }
    if (filter.responsible_name) {
      params.responsible_name = filter.responsible_name;
    }
  }

  const { data } = await $authHost.get(
    `/blogger_page/blogger/${id}/tasks`,
    {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  );
  return data;
};

export const createTaskBlogger = async (data) => {
  await $authHost.post('/blogger_page/task/', data);
};

export const patchTaskBlogger = async (task_id, data) => {
  await $authHost.patch(`/blogger_page/task/${task_id}/`, data);
};

export const deleteTaskBlogger = async (task_id) => {
  await $authHost.delete(`/blogger_page/task/${task_id}/`);
};
