// @ts-nocheck
import React, { useContext, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Context } from 'index';

import { login, resetPassword, reсoverPassword } from 'API/UserAPI';

import LawyersInput from 'components/UI/MyInput/LawyersInput';

import SuccessBtn from '../UI/Btns/SuccessBtn';

import {
  FORGOT_ROUTE,
  LOGIN_ROUTE,
  MAIN_ROUTE,
  RECOVER_ROUTE,
} from 'utils/consts';

// import lamp from './assets/lamps.png'; НОВОГОДНИЙ ФОН
import image from './assets/logo.svg';

import classes from './AuthWindow.module.css';

function AuthWindow() {
  const { user, toast } = useContext(Context);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const [isRecover, setIsRecover] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const isLogin = location.pathname === LOGIN_ROUTE;
  const isRecoverRoute = location.pathname.slice(0, 8) === RECOVER_ROUTE;

  // В завимости от того на какой странице мы находимся делаем запрос на вход/восстановление
  const loginHandler = async (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      try {
        if (isLogin) {
          const data = await login(email, password);
          user.setIsAuth(true);
          user.setUser(data);
          user.setDepartment(data.department.id);
          navigate(MAIN_ROUTE);
          setIsRecover(true);
        } else {
          await resetPassword(email);
          setIsRecover(true);
        }
      } catch (error) {
        if (error.response.status === 401) {
          toast.setToastInfo('Неправильный пароль или пользователь не существует');
          toast.setShowError(true);
        } else if (error.response.status === 400) {
          toast.setToastInfo('Введите верный email');
          toast.setShowError(true);
        } else {
          toast.setToastInfo('Ошибка на сервере. Пожалуйста, повторите запрос.');
          toast.setShowError(true);
        }
      }
    }
  };

  const recoverHandler = (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      if (!password) {
        toast.setToastInfo('Новый пароль не может быть пустым');
        toast.setShowError(true);
        return;
      }

      if (password !== password2) {
        toast.setToastInfo('Пароли не совпадают');
        toast.setShowError(true);
        return;
      }

      const temp = location.pathname.slice(9).split('/');

      reсoverPassword(password, temp[0], temp[1]).then(() => {
        navigate(LOGIN_ROUTE);
        setIsRecover(false);
      });
    }
  };

  const backToLoginHandler = () => {
    navigate(LOGIN_ROUTE);
    setIsRecover(false);
  };

  return (
    <div className={classes.window__container}>
      {/* <img className={classes.lamp_img} src={lamp} alt="" /> НОВОГОДНИЙ ФОН */}
      <div style={{ textAlign: 'center' }}>
        <img className={classes.auth__img} src={image} alt="" />
      </div>

      {isRecoverRoute ? (
        <div>
          <span className={classes.text}>Восстановление пароля</span>

          <div className={classes.input__container}>
            <label className={classes.input__text}>Введите пароль</label>
            <LawyersInput
              style={{
                width: '100%',
                fontSize: 17,
                padding: '6px 0 6px 8px',
              }}
              placeholder="Password"
              type="password"
              value={password}
              setValue={setPassword}
              onKeyPress={(event) => recoverHandler(event)}
            />
          </div>

          <label className={classes.input__text}>Повторите пароль</label>
          <LawyersInput
            style={{
              width: '100%',
              fontSize: 17,
              padding: '6px 0 6px 8px',
            }}
            placeholder="Password"
            type="password"
            value={password2}
            setValue={setPassword2}
            onKeyPress={(event) => recoverHandler(event)}
          />

          <div className={classes.auth__btn__container}>
            <SuccessBtn
              text="Восстановить"
              onClick={recoverHandler}
              style={{
                padding: '6px 40px',
                fontSize: 14,
              }}
            />
          </div>
        </div>
      ) : (
        <div>
          <span className={classes.text}>
            {isLogin ? 'Авторизация' : 'Восстановление пароля'}
          </span>

          {isRecover ? (
            <>
              <span className={classes.miniText}>
                Ссылка для восстановления пароля отправлена на ваш email
              </span>

              <div className={classes.auth__btn__container}>
                <SuccessBtn
                  text="Вернуться назад"
                  onClick={backToLoginHandler}
                  style={{
                    padding: '6px 40px',
                    fontSize: 14,
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className={classes.input__container}>
                <label className={classes.input__text}>
                  Введите адрес электронной почты
                </label>

                <LawyersInput
                  style={{
                    width: '100%',
                    fontSize: 17,
                    padding: '6px 0 6px 8px',
                  }}
                  placeholder="Email"
                  type="email"
                  value={email}
                  setValue={setEmail}
                  onKeyPress={(event) => loginHandler(event)}
                />
              </div>

              {isLogin && (
              <>
                <label className={classes.input__text}>
                  Введите пароль
                </label>
                <LawyersInput
                  style={{
                    width: '100%',
                    fontSize: 17,
                    padding: '6px 0 6px 8px',
                  }}
                  placeholder="Password"
                  type="password"
                  value={password}
                  setValue={setPassword}
                  onKeyPress={(event) => loginHandler(event)}
                />
              </>
              )}

              <div className={classes.auth__btn__container}>
                <SuccessBtn
                  text={isLogin ? 'Войти' : 'Восстановить'}
                  onClick={loginHandler}
                  style={{
                    padding: '6px 40px',
                    fontSize: 14,
                  }}
                />
              </div>

              <span className={classes.auth__inner__footer}>
                {isLogin ? (
                  <NavLink
                    to={FORGOT_ROUTE}
                    className={classes.auth__inner__footer__text}
                  >
                    Забыли пароль?
                  </NavLink>
                ) : (
                  <NavLink
                    to={LOGIN_ROUTE}
                    className={classes.auth__inner__footer__text}
                  >
                    Вспомнил пароль, хочу войти
                  </NavLink>
                )}
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
}
export default AuthWindow;
