import jwt_decode from 'jwt-decode';

import { $authHost, $host } from 'API';

export const login = async (username, password) => {
  const { data } = await $host.post('/auth/jwt/token/', {
    username,
    password,
  });

  localStorage.setItem('accessToken', data.access);
  localStorage.setItem('refreshToken', data.refresh);
  return jwt_decode(data.access);
};

export const resetPassword = async (email) => {
  await $host.post('/auth/users/reset_password/', {
    email,
  });
};

export const reсoverPassword = async (password, uid, token) => {
  await $host.post('/auth/users/reset_password_confirm/', {
    uid,
    token,
    new_password: password,
    re_new_password: password,
  });
};

// Если токен не валидный, отправляем запрос на рефреш токена
export const check = async () => {
  try {
    await $authHost.post('/auth/jwt/verify/', {
      token: localStorage.getItem('accessToken'),
    });

    return jwt_decode(localStorage.getItem('accessToken'));
  } catch (error) {
    const { data } = await $authHost.post('/auth/jwt/refresh/', {
      refresh: localStorage.getItem('refreshToken'),
    });

    localStorage.setItem('accessToken', data.access);
    localStorage.setItem('refreshToken', data.refresh);

    await $authHost.post('/auth/jwt/verify/', {
      token: localStorage.getItem('accessToken'),
    });

    return jwt_decode(localStorage.getItem('accessToken'));
  }
};

export const changePassword = async (new_password, current_password) => {
  await $authHost.post('/auth/users/set_password/', {
    new_password,
    current_password,
  });
};

export const createUser = async (data) => {
  await $authHost.post('/auth/users/', data);
};
