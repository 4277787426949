import qs from 'qs';

import { $authHost } from 'API';

import { getDate } from 'utils/getDate';

export const fetchNewsTaskPage = async (id, filter) => {
  const params = {};

  if (filter) {
    if (filter.created_after) {
      params.created_after = filter.created_after;
    }

    if (filter.created_before) {
      params.created_before = filter.created_before;
    }
  }

  const { data } = await $authHost.get(`/staff_page/staff/${id}/news/`, { params });
  return data;
};

export const postNewsTaskPage = async (data) => {
  await $authHost.post('/news/', data);
};

export const patchNewsTaskPage = async (id, data) => {
  await $authHost.patch(`/news/${id}/`, data);
};

export const fetchReportNews = async (page, limit, controller, filter) => {
  const params = { page, limit };

  if (filter) {
    if (filter.news_period_start) {
      params.created_after = getDate(filter.news_period_start);
    }

    if (filter.news_period_end) {
      params.created_before = getDate(filter.news_period_end);
    }

    if (Number.isInteger(filter.selectedDepartment.id)) {
      params.department = filter.selectedDepartment.id;
    }

    let tempArray = [];
    filter?.selectedDirectorList?.forEach((grouphead) => tempArray.push(grouphead.id));
    if (tempArray.length > 0) {
      params.grouphead = tempArray;
    }

    tempArray = [];
    filter?.selectedResponsibleList?.forEach((person) => tempArray.push(person.id));
    if (tempArray.length > 0) {
      params.staff = tempArray;
    }

    tempArray = [];
    filter?.selectedDepartmentList?.forEach((person) => tempArray.push(person.id));
    if (tempArray.length > 0) {
      params.department = tempArray;
    }
  }

  const { data } = await $authHost.get('/report_page/news/', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return data;
};
