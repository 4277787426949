import React, { useEffect, useState } from 'react';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import MyModal from 'components/UI/MyModal/MyModal';

import classes from './ModalEditLink.module.scss';

function ModalEditLink({
  show, onChange, setValue, value: initialValue, closeModal,
}) {
  const [value, setInternalValue] = useState('');

  useEffect(() => {
    if (initialValue) {
      setInternalValue(initialValue);
    }
  }, [initialValue]);

  const handleCancel = () => {
    setInternalValue(initialValue);
    closeModal(false);
  };

  const handleSave = () => {
    if (onChange) {
      const event = { target: { value } };
      onChange(event);
    } else {
      setValue(value);
    }
    closeModal(false);
  };

  return (
    <MyModal style={{ padding: '50px 70px' }} show={show} type="confirm" noClose>
      <div className={classes.text}>Редактирование ссылки</div>

      <div className={classes.block}>
        <input
          type="text"
          className={classes.info__input}
          value={value}
          onChange={(event) => setInternalValue(event.target.value)}
        />
        <div className={classes.buttons}>
          <SuccessBtn
            type="white"
            text="Отмена"
            className={classes.btn}
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={handleCancel}
          />

          <SuccessBtn
            text="Сохранить"
            className={classes.btn}
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={handleSave}
          />
        </div>

      </div>
    </MyModal>
  );
}
export default ModalEditLink;
