// @ts-nocheck
import React from 'react';
import { Helmet } from 'react-helmet';

import MainMarketing from '../components/Marketing/MainTables/MainMarketing';

function Main() {
  return (
    <section className="Mysection">
      <Helmet>
        <title>Проекты | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <MainMarketing />
    </section>
  );
}

export default Main;
