/* eslint-disable no-restricted-syntax */
// @ts-nocheck
import React, {
  useContext, useEffect,
  useRef, useState,
} from 'react';
import { Context } from 'index';

import {
  addBIDFile,
  deleteBIDFile,
  getBid,
  patchBid,
  putBid,
} from 'API/ApplicationAPI';
import { autocompleteBloggers } from 'API/BloggerAPI';
import { autocompleteClients } from 'API/ClientAPI';
import {
  autocompleteLegalEntities,
} from 'API/LawyersAPI/AutocompleteAPI';
import { postLegalEntity } from 'API/LegalEntityAPI';
import { fetchStaff } from 'API/ManagerAPI/AutocompleteAPI';
import { check } from 'API/UserAPI';
import { deleteBloggerBID } from '../../../../API/ApplicationAPI';
import { autocompleteAllLegalEntities } from '../../../../API/LawyersAPI/AutocompleteAPI';

import NewAddBtn from 'components/UI/Btns/NewAddBtn';
import MyInputFile from 'components/UI/MyInput/MyInputFile';

import { useDate } from '../../../../hooks/UseDate';
import { useLogout } from '../../../../hooks/useLogout';
import { useStateNew } from '../../../../hooks/useStateNew';
import { useStateNew2 } from '../../../../hooks/useStateNew2';

import {
  defaultLegalEntitiesWithId, LEGAL_TYPES, RESPONSIBLE_TOKEN, RESPONSIBLE_TOKEN_CLIENT,
} from 'utils/consts';
import { getDate } from 'utils/getDate';
import { getSum, getSumRuble, numberToSum } from 'utils/getSums';

import { CHECKBOX_QUANTITY, HEADERS } from './consts';

import SuccessBtn from '../../Btns/SuccessBtn';
import CheckboxElement from '../../CheckboxItem/CheckboxElement';
import CheckboxItem from '../../CheckboxItem/CheckboxItem';
import Files from '../../Files/Files';
import InputElement from '../../InputElements/InputElement';
import InputElementInn from '../../InputElements/InputElementInn';
import MyModal from '../../MyModal/MyModal';
import PaymentsTable from '../../PaymentsTable/PaymentsTable';
import SearchBar from '../../SearchBar/SearchBar';
import ModalConfirm from '../ModalConfirm/ModalConfirm';

import BloggerElement from './components/BloggerElement';
import ContractElement from './components/ContractElement';
import DateElement from './components/DateElement';
import PaymentDateNoTime from './components/PaymentDateNoTime';
import {
  FIRST_STEP_FIELDS,
} from './Fields';

import 'react-datepicker/dist/react-datepicker.css';
import classes from './ModalCreateContract.module.css';

function ModalCreateContract({ show, onHide, props }) {
  const { user, modal, toast } = useContext(Context);
  const [step, setStep] = useState(1);

  const tooltipText = `<b>Для ИП:</b> ИНН, ОГРНИП, адрес регистрации и банковские реквизиты<br>
  <b>Для СЗ:</b> ИНН, паспортные данные (серия номер паспорта, кем выдан, когда выдан, код подразделения), адрес регистрации и банковские реквизиты<br>
  <b>Для ООО:</b> ИНН, ОГРН, КПП, юридический адрес и банковские реквизиты`;

  const firstRender = useRef(true);

  const [logout] = useLogout();

  const {
    id,
    name,
    client_name,
    dataProps,
    fetchProject,
    fetchPublications,
    isSent,
    fetchMargin,
    fetchEstimate,
    fetchHistory,
  } = props;

  const [managerList, setManagerList] = useState([]);
  const [lawyerList, setLawyerList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [bloggersList, setBloggersList] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [ourLegalEntity, setOurLegalEntity] = useState([]);
  const [allLegalEntity, setAllLegalEntity] = useState([]);

  useEffect(() => {
    if (show) {
      check()
        .then(() => {
          autocompleteBloggers().then((data) => setBloggersList(data));
          autocompleteClients().then((data) => setClientsList(data));

          fetchStaff({ job: 1, is_active: true }).then((data) => setLawyerList(data));

          fetchStaff({ job: 2, is_active: true }).then((data) => setDocumentList(data));

          fetchStaff({ job: 0, is_active: true }).then((data) => setManagerList(data));

          autocompleteLegalEntities().then((data) => {
            setOurLegalEntity([{ id: 'customId', legal_name: 'Неизвестно' }, ...data]);
          });

          autocompleteAllLegalEntities().then((data) => {
            data.push({ id: 'customId', legal_name: 'Неизвестно' });
            setAllLegalEntity(data);
          });
          toast.setToastTitle('Обратите внимание');
          toast.setToastInfo('Невозможно изменить количество оплат исполнителю после сохранения черновика');
          toast.setShowError(true);
        })
        .catch((error) => logout(error));
    }
  }, [show]);

  // 1 STEP
  const [firstStepValues, setFirstStepValues] = useState({
    project_id: '',
    project_name: '',
    adv_object: '',
    adv_object_comment: '',
    date_start: '',
    date_end: '',
  });

  const updateFirstStepValues = (fieldName, value) => {
    setFirstStepValues({ ...firstStepValues, [fieldName]: value });
  };

  // 2 STEP
  const [secondStepValues, setSecondStepValues] = useState({
    is_contract: true,
    type: { id: 0 },
    post_branch: '',
  });

  const updateSecondStepValues = (fieldName, value) => {
    setSecondStepValues({ ...secondStepValues, [fieldName]: value });
  };

  const [client, setClient, changeClient] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
    },
  ]);

  const [selectedOurLegalEntity, setSelectedOurLegalEntity, changeSelectedOurLegalEntity] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
    },
  ]);

  // Состояние для создания нового ЮЛ

  const [
    legalEntities,
    setLegalEntities,
    updateLegalEntities,
  ] = useStateNew2([defaultLegalEntitiesWithId]);

  const [addLegalEntityVisible, setAddLegalEntityVisible] = useState(false);
  const [legalEntityMatches, setLegalEntityMatches] = useState([{}]);

  const closeCreateLegalEntityHandler = () => {
    setAddLegalEntityVisible(false);
    setLegalEntityMatches([{}]);
    setLegalEntities([defaultLegalEntitiesWithId]);
  };

  const createLegalEntityHandler = () => {
    let data = {};

    if (legalEntities[0].legal_name) {
      data = {
        type: { id: legalEntities[0].type.id },
        legal_name: legalEntities[0].legal_name,
        inn: legalEntities[0].inn,
        file_links: legalEntities[0].file_links,
        bank_details: [],
      };
    }

    check()
      .then(() => {
        postLegalEntity(data)
          .then((response) => {
            toast.setShowSuccess(true);
            toast.setToastInfo('Новое ЮЛ создано');
            setClient([{
              fieldId: Date.now(),
              id: response.id,
              name: response.legal_name,
            }]);

            setAllLegalEntity([...allLegalEntity, {
              id: response.id,
              legal_name: response.legal_name,
            }]);

            setAddLegalEntityVisible(false);
            setLegalEntityMatches([{}]);
            setLegalEntities([defaultLegalEntitiesWithId]);
          })
          .catch((error) => {
            if (error.response.data.contacts) {
              toast.setToastInfo('Введите правильный email');
              toast.setShowError(true);
            } else if (error.response.data.type) {
              toast.setToastInfo('Выберите форму ЮЛ');
              toast.setShowError(true);
            } else if (error?.response?.data[0]) {
              toast.setToastInfo(error.response.data[0]);
              toast.setShowError(true);
            } else {
              toast.setToastInfo('Обратитесь в техподдержку');
              toast.setShowError(true);
            }
          });
      })
      .catch((error) => logout(error));
  };

  useEffect(() => {
    if (client[0]?.name?.length > 0) {
      setLegalEntityMatches(
        allLegalEntity.filter((element) => {
          const regex = new RegExp(`${client[0].name}`, 'gi');
          return element?.legal_name?.match(regex);
        }),
      );
    } else {
      setLegalEntityMatches([{}]);
    }
  }, [client[0]?.name]);

  useEffect(() => {
    if (secondStepValues.is_contract === false) {
      setClient([{
        fieldId: Date.now(),
        id: 'customId',
        name: client_name,
      }]);

      setSelectedOurLegalEntity([{
        fieldId: Date.now(),
        id: 'customId',
        name: 'Физическая оплата',
      }]);

      setAddLegalEntityVisible(false);
      setLegalEntityMatches([{}]);
      setLegalEntities([defaultLegalEntitiesWithId]);
    } else {
      setClient([{
        fieldId: Date.now(),
        id: null,
        name: '',
      }]);

      setSelectedOurLegalEntity([{
        fieldId: Date.now(),
        id: null,
        name: '',
      }]);
      setAddLegalEntityVisible(false);
    }
  }, [secondStepValues.is_contract]);

  // Договор услуг
  const [secondStepValuesServices, setSecondStepValuesServices] = useState({
    total_sum: '',
    is_nds: false,
  });

  const updateSecondStepValuesServices = (fieldName, value) => {
    setSecondStepValuesServices({
      ...secondStepValuesServices,
      [fieldName]: value,
    });
  };

  // Агентский договор
  const isProcentAK = useRef(false);
  const isSumAK = useRef(false);

  const [secondStepValuesAgency, setSecondStepValuesAgency] = useState({
    total_sum: '',
    ak_sum: '',
    is_nds: false,
  });
  const [akProcentAgency, setAkProcentAgency] = useState('');

  const updateSecondStepValuesAgency = (fieldName, value) => {
    setSecondStepValuesAgency({
      ...secondStepValuesAgency,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    secondStepValuesAgency.ak_sum && !isProcentAK.current
      ? (isSumAK.current = true)
      : (isSumAK.current = false);

    if (secondStepValuesAgency.total_sum && isSumAK.current) {
      setAkProcentAgency(
        numberToSum(
          (getSum(secondStepValuesAgency.ak_sum) * 100)
            / getSum(secondStepValuesAgency.total_sum),
        ),
      );
    } else if (!secondStepValuesAgency.total_sum || !secondStepValuesAgency.ak_sum) {
      setAkProcentAgency('');
    }
  }, [secondStepValuesAgency.ak_sum, secondStepValuesAgency.total_sum]);

  useEffect(() => {
    akProcentAgency && !isSumAK.current
      ? (isProcentAK.current = true)
      : (isProcentAK.current = false);

    if (secondStepValuesAgency.total_sum && isProcentAK.current) {
      setSecondStepValuesAgency({
        ...secondStepValuesAgency,
        ak_sum: numberToSum(
          getSum(secondStepValuesAgency.total_sum) * (akProcentAgency / 100),
        ),
      });
    } else if (!secondStepValuesAgency.total_sum || !akProcentAgency) {
      updateSecondStepValuesAgency('ak_sum', '');
    }
  }, [akProcentAgency, secondStepValuesAgency.total_sum]);

  // Порядок оплаты и тд

  const [priceDate, setPriceDate, changeDate, addDate, deleteDate] = useDate([{ reactId: Date.now(), date: null, sum: '' }]);

  const [isRepost, setIsRepost] = useState(false);
  const [repostComment, setRepostComment] = useState('');

  const [isCase, setIsCase] = useState(false);
  const [caseComment, setCaseComment] = useState('');

  const [responsibleLawyer, setResponsibleLawyer, changeResponsibleLawyer] = useStateNew([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    responsibleDocument,
    setResponsibleDocument,
    changeResponsibleDocument,
  ] = useStateNew([{ fieldId: Date.now(), id: null, name: '' }]);

  const [manager, setManager] = useState([]);

  const [
    responsible,
    setResponsible,
    changeResponsible,
    addResponsible,,
    checkResponsible,
    deleteResponsible,
  ] = useStateNew([{ fieldId: Date.now(), id: null, name: '' }]);

  const [clientContact, setClientContact, , , deleteClientContact] = useStateNew2([{ id: Date.now(), email: '' }]);

  const [additionalClientContact, setAdditionalClientContact, changeAdditionalClientContact, addAdditionalClientContact, deleteAdditionalClientContact] = useStateNew2([{ id: Date.now(), email: '' }]);

  const [files, setFiles] = useState([]);

  const addFileHandler = (event) => {
    const formData = new FormData();
    Array.from(event.target.files).forEach((file) => {
      formData.append(file.name, file);
    });

    check()
      .then(() => {
        addBIDFile(dataProps.id, formData).then(() => {
          getBid(dataProps.id).then((data) => {
            setFiles(data.files);
          });
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          onHide();
        }
        logout(error);
      });
  };

  const deleteFileHandler = (id) => {
    check()
      .then(() => {
        deleteBIDFile(id).then(() => {
          getBid(dataProps.id).then((data) => {
            setFiles(data.files);
          });
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          onHide();
        }
        logout(error);
      });
  };

  const addDateHandler = () => {
    addDate({ reactId: Date.now(), date: null, sum: '' }, true);
    toast.setToastTitle('Обратите внимание');
    toast.setToastInfo('Невозможно изменить количество оплат исполнителю после сохранения черновика');
    toast.setShowError(true);
  };

  // 3 STEP
  const [bloggers, setBloggers] = useState([
    {
      id: Date.now(),
      blogger: [
        {
          fieldId: Date.now(),
          id: null,
          name: '',
          legal_entity: [],
          contacts: [],
        },
      ],
      platform: [
        {
          tempId: Date.now(),
          link: '',
          publication: [],
          is_assignment: false,
          assignment_type: { id: 0, name: 'Отчуждение' },
          assignment_comment: '',
          is_repost: false,
          repost_comment: '',
        },
      ],
      kpi: '',
      placement_period: null,
      period_comment: '',
      non_removal_period: '',
      is_exclusive: false,
      exclusive_comment: '',
      comment: '',
      is_contract: false,
      client_legal_entity: null,
      contractor_legal_entity: null,
      payment: {
        total_sum: '',
        client_sum_without_tax: '',
        show_client: false,
        payment_dates: [],
        is_nds: false,
        nds_sum: '',
      },
      lawyer: [{ fieldId: Date.now(), id: null, name: '' }],
      doc_manager: [{ fieldId: Date.now(), id: null, name: '' }],
      manager: [{ fieldId: Date.now(), id: null, name: '' }],
      blogger_contacts: [],
      addition_emails: [],
      files: [],
    },
  ]);

  const updateBlogger = (fieldId, fieldName, value) => {
    setBloggers(
      bloggers.map((blogger) => (blogger.id === fieldId
        ? {
          ...blogger,
          [fieldName]: value,
        }
        : blogger)),
    );
  };

  const addBlogger = () => {
    check()
      .then(() => {
        const blogger_contracts = [];
        bloggers.forEach((element) => {
          blogger_contracts.push({ id: element.id });
        });

        const newBlogger = {
          kpi: bloggers[0].kpi,
          placement_period: bloggers[0].placement_period,
          period_comment: bloggers[0].period_comment,
          non_removal_period: bloggers[0].non_removal_period,
          is_exclusive: bloggers[0].is_exclusive,
          exclusive_comment: bloggers[0].exclusive_comment,
          comment: bloggers[0].comment,
        };

        if (bloggers[0]?.manager[0]?.id) {
          newBlogger.manager = [{ id: bloggers[0]?.manager[0]?.id }];
        }

        if (bloggers[0]?.lawyer[0]?.id) {
          newBlogger.lawyer = [{ id: bloggers[0]?.lawyer[0]?.id }];
        }

        if (bloggers[0]?.doc_manager[0]?.id) {
          newBlogger.doc_manager = [{ id: bloggers[0]?.doc_manager[0]?.id }];
        }

        if (bloggers[0]?.staff_contacts?.length > 0) {
          const tempArray = [];
          bloggers[0]?.staff_contacts?.forEach((element) => {
            tempArray.push({
              id: element.id,
            });
          });

          newBlogger.staff_contacts = tempArray;
        }

        const tempPlatform = [];
        bloggers[0].platform.forEach((platformElement) => {
          platformElement.publication.forEach((element) => {
            delete element.id;
          });

          const dataPlatform = {
            publication: platformElement.publication,
            is_assignment: platformElement.is_assignment,
            assignment_comment: platformElement.assignment_comment,
            is_repost: platformElement.is_repost,
            repost_comment: platformElement.repost_comment,
          };

          if (platformElement.is_assignment) {
            if (Number.isInteger(platformElement.assignment_type.id)) {
              dataPlatform.assignment_type = {
                id: platformElement.assignment_type.id,
              };
            }
          }

          tempPlatform.push(dataPlatform);
        });

        newBlogger.platform = tempPlatform;

        blogger_contracts.push(newBlogger);

        const createData = { project: { id }, blogger_contracts };
        patchBid(dataProps.id, createData).then(() => {
          getBid(dataProps.id).then((data) => {
            const tempArray = JSON.parse(JSON.stringify(data.blogger_contracts));

            tempArray.forEach((element) => {
              if (element.blogger) {
                element.blogger = [element.blogger];
              } else {
                element.blogger = [
                  {
                    fieldId: Date.now(),
                    id: null,
                    name: '',
                    legal_entity: [],
                    contacts: [],
                  },
                ];
              }

              if (element.platform?.length === 0) {
                element.platform = [
                  {
                    tempId: Date.now(),
                    link: '',
                    publication: [],
                    is_assignment: false,
                    assignment_type: { id: 0, name: 'Отчуждение' },
                    assignment_comment: '',
                    is_repost: false,
                    repost_comment: '',
                  },
                ];
              }

              const dates = [];

              element.payments.forEach((date) => {
                dates.push({ ...date, sum: numberToSum(date.sum), reactId: date.id });
              });

              element.payment = {
                total_sum: numberToSum(element.total_sum),
                client_sum_without_tax: numberToSum(element.client_sum_without_tax),
                payment_dates: dates,
                show_client: element.show_client,
                is_nds: element.is_nds,
                nds_sum: element.nds_sum,
              };

              element.manager = [
                {
                  fieldId: Date.now(),
                  id: user.user.staff.id,
                  fullname: user.user.staff.fullname,
                },
              ];

              if (element.staff_contacts?.length > 0) {
                const tempArray = [];
                element.staff_contacts.forEach((element) => {
                  tempArray.push({
                    fieldId: Date.now() + element.id,
                    id: element.id,
                    name: element.fullname,
                  });
                });

                element.staff_contacts = tempArray;
              }
            });

            if (bloggers?.length === 0) {
              setBloggers(tempArray);
            } else {
              const results = tempArray.filter(
                ({ id: id1 }) => !bloggers.some(({ id: id2 }) => id2 === id1),
              );

              setBloggers([...bloggers, ...results]);
            }
          });
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          onHide();
        }
        logout(error);
      });
  };

  const deleteBlogger = (bloggerId, event) => {
    event.stopPropagation();
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить исполнителя?',
      click: () => {
        check()
          .then(() => {
            deleteBloggerBID({ bid_id: dataProps.id, bl_id: bloggerId })
              .then(() => {
                setBloggers(bloggers.filter((item) => item.id !== bloggerId));
              })
              .catch((error) => {
                if (error?.response?.data) {
                  toast.setToastInfo(error?.response?.data[0]);
                  toast.setShowError(true);
                }
              });
          });
      },
    });
    modal.setModalConfirmVisible(true);
  };

  // 4 STEP
  const [fourthStepValues, setFourthStepValues] = useState({
    is_marked: false,
    responsible: {},
    client: {},
    comment: '',
  });

  const updateFourthStepValuesAgency = (fieldName, value) => {
    setFourthStepValues({
      ...fourthStepValues,
      [fieldName]: value,
    });
  };

  // General

  // Замена данных на полученные с сервера
  useEffect(() => {
    if (dataProps && show) {
      // 1 STEP
      setFirstStepValues({
        project_id: dataProps?.project?.project_id || '',
        project_name: name,
        adv_object: dataProps?.adv_object,
        adv_object_comment: dataProps?.adv_object_comment,
        date_start: dataProps?.date_start,
        date_end: dataProps?.date_end,
      });

      // 2 STEP

      setSecondStepValues({
        is_contract: dataProps.is_contract,
        type: dataProps.type,
        post_branch: dataProps.post_branch,
      });

      if (dataProps.is_contract === false) {
        setClient([{
          fieldId: Date.now(),
          id: 'customId',
          name: client_name,
        }]);
      } else if (dataProps.client_legal_entity) {
        setClient([
          {
            fieldId: Date.now(),
            id: dataProps.client_legal_entity.id,
            name: dataProps.client_legal_entity.legal_name,
          },
        ]);
      } else {
        setClient([{ fieldId: Date.now(), id: null, name: '' }]);
      }

      if (dataProps.is_contract === false) {
        setSelectedOurLegalEntity([{
          fieldId: Date.now(),
          id: 'customId',
          name: 'Физическая оплата',
        }]);
      } else if (dataProps.contractor_legal_entity) {
        setSelectedOurLegalEntity([
          {
            fieldId: Date.now(),
            id: dataProps.contractor_legal_entity.id,
            name: dataProps.contractor_legal_entity.legal_name,
          },
        ]);
      } else {
        setSelectedOurLegalEntity([
          {
            fieldId: Date.now(),
            id: null,
            name: '',
          },
        ]);
      }

      setSecondStepValuesServices({
        total_sum: numberToSum(dataProps?.total_sum),
        is_nds: dataProps?.is_nds,
      });

      setSecondStepValuesAgency({
        total_sum: numberToSum(dataProps?.total_sum),
        is_nds: dataProps?.is_nds,
        ak_sum: numberToSum(dataProps?.ak_sum),
      });

      if (dataProps.payments?.length > 0) {
        const dates = [];
        dataProps.payments
          .forEach((date) => dates.push({ ...date, sum: numberToSum(date.sum), reactId: date.id }));

        setPriceDate(dates);
      }

      setIsRepost(dataProps.is_repost);
      setRepostComment(dataProps.repost_comment);

      setIsCase(dataProps.is_case);
      setCaseComment(dataProps.case_comment);

      // Ответственные
      if (dataProps.lawyer?.length > 0) {
        const tempArray = [];
        dataProps.lawyer.forEach((element) => {
          tempArray.push({
            fieldId: Date.now() + element.id,
            id: element.id,
            name: element.fullname,
          });
        });

        setResponsibleLawyer(tempArray);
      }

      if (dataProps.doc_manager?.length > 0) {
        const tempArray = [];
        dataProps.doc_manager.forEach((element) => {
          tempArray.push({
            fieldId: Date.now() + element.id,
            id: element.id,
            name: element.fullname,
          });
        });

        setResponsibleDocument(tempArray);
      }

      if (dataProps.manager?.length === 0) {
        setManager([
          {
            fieldId: Date.now(),
            id: user.user.staff.id,
            fullname: user.user.staff.fullname,
          },
        ]);
      } else {
        setManager(dataProps.manager);
      }

      if (dataProps.staff_contacts?.length > 0) {
        const tempArray = [];
        dataProps.staff_contacts.forEach((element) => {
          tempArray.push({
            fieldId: Date.now() + element.id,
            id: element.id,
            name: element.fullname,
          });
        });

        setResponsible(tempArray);
      } else {
        setResponsible([]);
      }

      if (dataProps.client_contacts?.length > 0) {
        setClientContact(
          (dataProps.client_contacts),
        );
      } else if (dataProps.project.client.contacts?.length > 0) {
        setClientContact(dataProps.project.client.contacts);
      }

      if (dataProps.addition_emails?.length > 0) {
        setAdditionalClientContact(dataProps.addition_emails);
      } else {
        setAdditionalClientContact([]);
      }

      setFiles(dataProps.files);

      // 3 STEP
      if (dataProps.blogger_contracts?.length === 0) {
        check()
          .then(() => {
            const createData = {
              project: { id: Number(id) },
              blogger_contracts: [{}],
            };
            patchBid(dataProps.id, createData).then(() => {
              getBid(dataProps.id).then((data) => {
                modal.setModalApplicationContractProps({
                  id,
                  name,
                  client_name,
                  ourLegalEntity,
                  dataProps: data,
                  fetchProject,
                  fetchPublications,
                  fetchMargin,
                  fetchEstimate,
                  fetchHistory,
                  isSent,
                });
              });
            });
          })
          .catch((error) => {
            if (error.response.status === 401) {
              onHide();
            }
            logout(error);
          });
      } else {
        const tempArray = JSON.parse(JSON.stringify(dataProps.blogger_contracts));

        tempArray?.forEach((element) => {
          if (element.blogger) {
            element.blogger = [element.blogger];
          } else {
            element.blogger = [
              {
                fieldId: Date.now(),
                id: null,
                name: '',
                legal_entity: [],
                contacts: [],
              },
            ];
          }

          if (element.platform?.length === 0) {
            element.platform = [
              {
                tempId: Date.now(),
                link: '',
                publication: [],
                is_assignment: false,
                assignment_type: { id: 0, name: 'Отчуждение' },
                assignment_comment: '',
                is_repost: false,
                repost_comment: '',
              },
            ];
          }

          if (!element.is_contract) {
            element.client_legal_entity = {
              id: 'customId',
              legal_name: 'Физическая оплата',
              name: 'Физическая оплата',
            };

            element.contractor_legal_entity = {
              id: 'customId',
              legal_name: element?.blogger[0]?.name,
              name: element?.blogger[0]?.name,
            };
          } else {
            if (element?.client_legal_entity) {
              element.client_legal_entity.name = element?.client_legal_entity?.legal_name;
            }

            if (element?.contractor_legal_entity) {
              element.contractor_legal_entity.name = element?.contractor_legal_entity?.legal_name;
            }
          }

          const dates = [];

          element.payments.forEach((date) => {
            dates.push({ ...date, sum: numberToSum(date.sum), reactId: date.id });
          });

          element.payment = {
            total_sum: numberToSum(element.total_sum),
            client_sum_without_tax: numberToSum(element.client_sum_without_tax),
            show_client: element.show_client,
            payment_dates: dates,
            is_nds: element.is_nds,
            nds_sum: element.nds_sum,
          };

          if (element.manager?.length === 0) {
            element.manager = [
              {
                fieldId: Date.now(),
                id: user.user.staff.id,
                fullname: user.user.staff.fullname,
              },
            ];
          }

          if (element.staff_contacts?.length > 0) {
            const tempArray = [];
            element.staff_contacts.forEach((element) => {
              tempArray.push({
                fieldId: Date.now() + element.id,
                id: element.id,
                name: element.fullname,
              });
            });

            element.staff_contacts = tempArray;
          }
        });

        setBloggers(tempArray);
      }

      // 4 Step

      if (dataProps?.marking_data?.length) {
        setFourthStepValues({
          is_marked: true,
          responsible: RESPONSIBLE_TOKEN.LIST[dataProps?.marking_data[0].responsible],
          client: RESPONSIBLE_TOKEN_CLIENT.LIST[dataProps?.marking_data[0].client],
          comment: dataProps?.marking_data[0].comment,
        });
      } else {
        setFourthStepValues({
          is_marked: false,
          responsible: {},
          client: {},
          comment: '',
        });
      }
    }
  }, [dataProps, show]);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setBloggers([
          {
            id: Date.now(),
            blogger: [
              {
                fieldId: Date.now(),
                id: null,
                name: '',
                legal_entity: [],
                contacts: [],
              },
            ],
            platform: [
              {
                tempId: Date.now(),
                link: '',
                publication: [],
                is_assignment: false,
                assignment_type: { id: 0, name: 'Отчуждение' },
                assignment_comment: '',
                is_repost: false,
                repost_comment: '',
              },
            ],
            kpi: '',
            placement_period: null,
            period_comment: '',
            non_removal_period: '',
            is_exclusive: false,
            exclusive_comment: '',
            comment: '',
            is_contract: false,
            client_legal_entity: null,
            contractor_legal_entity: null,
            payment: {
              total_sum: '',
              client_sum_without_tax: '',
              show_client: false,
              payment_dates: [],
              is_nds: false,
              nds_sum: '',
            },
            lawyer: [{ fieldId: Date.now(), id: null, name: '' }],
            doc_manager: [{ fieldId: Date.now(), id: null, name: '' }],
            manager: [{ fieldId: Date.now(), id: null, name: '' }],
            blogger_contacts: [],
            addition_emails: [],
            files: [],
          },
        ]);

        setResponsibleLawyer([{ fieldId: Date.now(), id: null, name: '' }]);
        setResponsibleDocument([{ fieldId: Date.now(), id: null, name: '' }]);
        setResponsible([{ fieldId: Date.now(), id: null, name: '' }]);
      }, 200);
    }
  }, [show]);

  const saveDraftHandler = (noClose) => {
    check()
      .then(() => {
        const sentBloggers = [];
        let isError = false;
        let errorText = '';

        bloggers?.forEach((bloggerElement) => {
          const {
            blogger,
            platform,
            client_legal_entity,
            contractor_legal_entity,
            placement_period,
            lawyer,
            doc_manager,
            manager,
            staff_contacts,
            blogger_contacts,
            addition_emails,
            files,
            ...sent
          } = bloggerElement;

          // Публикации блогера
          const tempPlatform = [];

          platform.forEach((platformElement) => {
            const dataPlatform = {
              link: platformElement.link,
              publication: platformElement.publication,
              is_assignment: platformElement.is_assignment,
              assignment_comment: platformElement.assignment_comment,
              is_repost: platformElement.is_repost,
              repost_comment: platformElement.repost_comment,
            };

            const isPublicationFormat = platformElement.publication.length === 0;

            if (isPublicationFormat) {
              isError = true;
              errorText = 'Выберите тип формат публикаций у исполнителя';
              return;
            }

            if (platformElement.is_assignment) {
              if (Number.isInteger(platformElement.assignment_type.id)) {
                dataPlatform.assignment_type = {
                  id: platformElement.assignment_type.id,
                };
              } else {
                isError = true;
                errorText = 'Выберите тип передач прав';
              }
            }

            if (platformElement.id) {
              dataPlatform.id = platformElement.id;
            }

            tempPlatform.push(dataPlatform);
          });
          sent.platform = tempPlatform;

          if (Number.isInteger(blogger[0]?.id)) {
            sent.blogger = { id: blogger[0].id };
          }

          // Юр лица блогера
          if (Number.isInteger(client_legal_entity?.id)
          || client_legal_entity?.id === 'customId'
          || sent.is_contract !== true
          ) {
            if (client_legal_entity?.id === 'customId') {
              sent.client_legal_entity = null;
            } else if (Number.isInteger(client_legal_entity?.id)) {
              sent.client_legal_entity = { id: client_legal_entity?.id };
            }
          }

          if (Number.isInteger(contractor_legal_entity?.id)
          || contractor_legal_entity?.id === 'customId'
          || sent.is_contract !== true
          ) {
            if (contractor_legal_entity?.id === 'customId') {
              sent.contractor_legal_entity = null;
            } else if (Number.isInteger(contractor_legal_entity?.id)) {
              sent.contractor_legal_entity = { id: contractor_legal_entity?.id };
            }
          }

          sent.placement_period = {
            id: placement_period?.id,
            date_start: placement_period?.date_start ? getDate(placement_period?.date_start) : '',
            date_end: placement_period?.date_end ? getDate(placement_period?.date_end) : '',
          };

          // Даты оплат блогера
          const sentPaymentDates = [];

          sent.payment.payment_dates.forEach((element) => {
            if (element?.id) {
              sentPaymentDates.push({
                id: element.id,
                date: element.date ? getDate(element.date) : '',
                sum: getSum(element.sum),
                payment_method: element.payment_method,
              });
            } else {
              sentPaymentDates.push({
                date: element.date ? getDate(element.date) : '',
                sum: getSum(element.sum),
                payment_method: element.payment_method,
              });
            }
          });

          // Суммы блогера
          sent.total_sum = getSum(sent.payment.total_sum);
          sent.client_sum_without_tax = getSum(sent.payment.client_sum_without_tax);
          sent.show_client = sent.payment.show_client;
          sent.payments = sentPaymentDates;
          sent.is_nds = sent.payment.is_nds;
          sent.nds_sum = sent.payment.nds_sum;

          // Ответственные блогера
          const sentLawyers = [];
          const sentDocuments = [];
          const sentStaffContacts = [];

          lawyer?.forEach((element) => {
            if (element.id) {
              sentLawyers.push({ id: element.id });
            }
          });
          doc_manager?.forEach((element) => {
            if (element.id) {
              sentDocuments.push({ id: element.id });
            }
          });
          staff_contacts?.forEach((element) => {
            if (element.id) {
              sentStaffContacts.push({ id: element.id });
            }
          });

          sent.lawyer = sentLawyers;
          sent.doc_manager = sentDocuments;
          sent.manager = [{ id: manager[0].id }];
          sent.staff_contacts = sentStaffContacts;

          // Контакты блогера
          const sentBloggerContacts = [];
          const sentAdditionalContacts = [];

          blogger_contacts.forEach((element) => {
            if (element.email) {
              sentBloggerContacts.push({ id: element.id, email: element.email });
            }
          });

          addition_emails.forEach((element) => {
            if (element.email) {
              sentAdditionalContacts.push({ id: element.id, email: element.email });
            }
          });

          sent.blogger_contacts = sentBloggerContacts;
          sent.addition_emails = sentAdditionalContacts;

          sentBloggers.push(sent);
        });

        // Ответственные
        const sentLawyers = [];
        const sentDocuments = [];
        const sentStaffContacts = [];

        responsibleLawyer.forEach((element) => {
          if (element.id) {
            sentLawyers.push({ id: element.id });
          }
        });
        responsibleDocument.forEach((element) => {
          if (element.id) {
            sentDocuments.push({ id: element.id });
          }
        });
        responsible.forEach((element) => {
          if (element.id) {
            sentStaffContacts.push({ id: element.id });
          }
        });

        // Контакты
        const client_contacts = [];
        const addition_emails = [];

        clientContact.forEach((contact) => {
          if (contact.email) {
            client_contacts.push({ id: contact.id, email: contact.email });
          }
        });

        additionalClientContact.forEach((contact) => {
          if (contact.email) {
            addition_emails.push({ id: contact.id, email: contact.email });
          }
        });

        const marking_data = [];

        if (fourthStepValues.is_marked) {
          const objectSend = {
            comment: fourthStepValues.comment,
          };

          if (fourthStepValues.responsible.id >= 0) {
            objectSend.responsible = fourthStepValues.responsible.id;
          }
          if (fourthStepValues.client.id >= 0) {
            objectSend.client = fourthStepValues.client.id;
          }

          marking_data.push(objectSend);
        }

        // Основная информация
        const sentData = {
          project: {
            id: Number(id),
          },
          adv_object: firstStepValues.adv_object,
          adv_object_comment: firstStepValues.adv_object_comment,
          date_start: firstStepValues.date_start ? getDate(firstStepValues.date_start) : '',
          date_end: firstStepValues.date_end ? getDate(firstStepValues.date_end) : '',
          is_contract: secondStepValues.is_contract,
          type: { id: secondStepValues.type.id },
          post_branch: secondStepValues.post_branch,
          is_repost: isRepost,
          repost_comment: repostComment,
          is_case: isCase,
          case_comment: caseComment,
          doc_manager: sentDocuments,
          lawyer: sentLawyers,
          manager: [{ id: manager[0].id }],
          staff_contacts: sentStaffContacts,
          client_contacts,
          addition_emails,
          blogger_contracts: sentBloggers,
          marking_data,
        };

        // Даты оплат
        const sentPriceDate = [];

        priceDate.forEach((element) => {
          if (element?.id >= 0) {
            sentPriceDate.push({
              id: element.id,
              date: element.date ? getDate(element.date) : '',
              sum: getSum(element.sum),
            });
          } else {
            sentPriceDate.push({
              date: element.date ? getDate(element.date) : '',
              sum: getSum(element.sum),
            });
          }
        });
        sentData.payments = sentPriceDate;

        // if (priceDate[0].date === '') {
        //   isError = true;
        //   errorText = 'Не выбрана дата оплаты от заказчика';
        // }

        if (firstStepValues.project_id > 0) {
          sentData.project = {
            ...sentData.project,
            project_id: Number(firstStepValues.project_id),
          };
        }

        // Суммы
        if (secondStepValues.type.id === 0) {
          sentData.total_sum = getSum(secondStepValuesServices.total_sum);
          sentData.is_nds = (secondStepValuesServices.is_nds);
        } else {
          sentData.total_sum = getSum(secondStepValuesAgency.total_sum);
          sentData.ak_sum = getSum(secondStepValuesAgency.ak_sum);
          sentData.ak_percent = getSum(akProcentAgency);
          sentData.is_nds = (secondStepValuesAgency.is_nds);
        }

        // Юр лица
        if (Number.isInteger(selectedOurLegalEntity[0].id)
        || selectedOurLegalEntity[0].id === 'customId'
        || sentData.is_contract !== true) {
          if (selectedOurLegalEntity[0].id === 'customId') {
            sentData.contractor_legal_entity = null;
          } else if (Number.isInteger(selectedOurLegalEntity[0].id)) {
            sentData.contractor_legal_entity = { id: selectedOurLegalEntity[0].id };
          }
        }

        if (Number.isInteger(client[0].id)
          || client[0].id === 'customId'
          || sentData.is_contract !== true) {
          if (client[0].id === 'customId') {
            sentData.client_legal_entity = null;
          } else if (Number.isInteger(client[0].id)) {
            sentData.client_legal_entity = { id: client[0].id };
          }
        }

        if (isError) {
          toast.setToastInfo(errorText);
          toast.setShowError(true);
          return;
        }

        patchBid(dataProps.id, sentData).then((response) => {
          if (noClose) {
            // Ответственные
            if (response?.data?.lawyer?.length > 0) {
              const tempArray = [];
              response?.data?.lawyer.forEach((element) => {
                tempArray.push({
                  fieldId: Date.now() + element.id,
                  id: element.id,
                  name: element.fullname,
                });
              });

              setResponsibleLawyer(tempArray);
            }

            if (response?.data?.doc_manager?.length > 0) {
              const tempArray = [];
              response?.data?.doc_manager.forEach((element) => {
                tempArray.push({
                  fieldId: Date.now() + element.id,
                  id: element.id,
                  name: element.fullname,
                });
              });

              setResponsibleDocument(tempArray);
            }

            if (response?.data?.manager?.length === 0) {
              setManager([
                {
                  fieldId: Date.now(),
                  id: user.user.staff.id,
                  fullname: user.user.staff.fullname,
                },
              ]);
            } else {
              setManager(response?.data?.manager);
            }

            if (response?.data?.staff_contacts?.length > 0) {
              const tempArray = [];
              response.data.staff_contacts.forEach((element) => {
                tempArray.push({
                  fieldId: Date.now() + element.id,
                  id: element.id,
                  name: element.fullname,
                });
              });

              setResponsible(tempArray);
            } else {
              setResponsible([]);
            }
          } else {
            toast.setToastInfo('Черновик сохранен');
            toast.setShowSuccess(true);
            onHide();
          }
        }).catch((error) => {
          console.log(error);
          toast.setToastInfo('Обратитесь в техподдержку');
          toast.setShowError(true);
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          onHide();
        }
        logout(error);
      });
  };

  const nextStep = () => {
    if (step >= 5) {
      return;
    }
    // Убрал из-за ошибки уведомления
    // if (!isSent) {
    //   saveDraftHandler(true);
    // }

    setStep(step + 1);
  };

  const prevStep = () => {
    if (step <= 1) {
      return;
    }
    setStep(step - 1);
  };

  const sendDraft = () => {
    check()
      .then(() => {
        const sentBloggers = [];
        let isError = false;
        let errorText = '';

        bloggers?.forEach((bloggerElement) => {
          const {
            blogger,
            platform,
            placement_period,
            client_legal_entity,
            contractor_legal_entity,
            lawyer,
            doc_manager,
            manager,
            staff_contacts,
            blogger_contacts,
            addition_emails,
            files,
            ...sent
          } = bloggerElement;

          // Публикации блогера
          const tempPlatform = [];

          platform.forEach((platformElement) => {
            const dataPlatform = {
              link: platformElement.link,
              publication: platformElement.publication,
              is_assignment: platformElement.is_assignment,
              assignment_comment: platformElement.assignment_comment,
              is_repost: platformElement.is_repost,
              repost_comment: platformElement.repost_comment,
            };

            if (platformElement.publication?.length === 0) {
              isError = true;
              errorText = `Выберите формат публикации у ${blogger[0].name}`;
            }

            if (platformElement.is_assignment) {
              if (Number.isInteger(platformElement.assignment_type.id)) {
                dataPlatform.assignment_type = {
                  id: platformElement.assignment_type.id,
                };
              } else {
                isError = true;
                errorText = 'Выберите тип передач прав';
              }
            }

            if (platformElement.id) {
              dataPlatform.id = platformElement.id;
            }

            tempPlatform.push(dataPlatform);
          });
          sent.platform = tempPlatform;

          if (Number.isInteger(blogger[0]?.id)) {
            sent.blogger = { id: blogger[0].id };
          } else {
            isError = true;
            errorText = 'Заполните поле исполнитель в шаге 3';
          }

          if (!firstStepValues?.date_start) {
            isError = true;
            errorText = 'Не выбрана дата начала оказания услуг';
          }

          if (!firstStepValues?.date_end) {
            isError = true;
            errorText = 'Не выбрана дата окончания оказания услуг';
          }

          if (getDate(placement_period?.date_start) < getDate(firstStepValues?.date_start)
          || getDate(placement_period?.date_start) > getDate(firstStepValues?.date_end)
          || getDate(placement_period?.date_end) < getDate(firstStepValues?.date_start)
          || getDate(placement_period?.date_end) > getDate(firstStepValues?.date_end)
          ) {
            isError = true;
            errorText = `Поле "Период размещения контента" должен попадать в период, указанный в поле "Срок оказания услуг" ${blogger[0].name ? blogger[0].name : ''}`;
          } else {
            sent.placement_period = {
              id: placement_period?.id,
              date_start: placement_period?.date_start ? getDate(placement_period?.date_start) : '',
              date_end: placement_period?.date_end ? getDate(placement_period?.date_end) : '',
            };
          }

          // Юр лица блогера
          if (Number.isInteger(client_legal_entity?.id)
          || client_legal_entity?.id === 'customId'
          || sent.is_contract !== true
          ) {
            if (client_legal_entity?.id === 'customId') {
              sent.client_legal_entity = null;
            } else if (Number.isInteger(client_legal_entity?.id)) {
              sent.client_legal_entity = { id: client_legal_entity?.id };
            }
          } else if (sent.is_contract) {
            isError = true;
            errorText = blogger[0]?.name ? `Выберите ЮЛ заказчика (${blogger[0]?.name})`
              : 'Выберите ЮЛ заказчика';
          }

          if (Number.isInteger(contractor_legal_entity?.id)
          || contractor_legal_entity?.id === 'customId'
          || sent.is_contract !== true
          ) {
            if (contractor_legal_entity?.id === 'customId') {
              sent.contractor_legal_entity = null;
            } else if (Number.isInteger(contractor_legal_entity?.id)) {
              sent.contractor_legal_entity = { id: contractor_legal_entity?.id };
            }
          } else if (sent.is_contract) {
            isError = true;
            errorText = `Выберите ЮЛ исполнителя (${blogger[0]?.name})`;
          }

          // Даты оплат блогера
          const sentPaymentDates = [];
          let totalPaymentSum = 0;

          sent.payment.payment_dates.forEach((element) => {
            totalPaymentSum += getSum(element.sum);

            if (element?.id) {
              sentPaymentDates.push({
                id: element.id,
                date: element.date ? getDate(element.date) : null,
                sum: getSum(element.sum),
                responsible: element?.responsible?.id
                  ? { id: element?.responsible?.id }
                  : { id: manager[0]?.id },
              });
            } else {
              sentPaymentDates.push({
                date: element.date ? getDate(element.date) : null,
                sum: getSum(element.sum),
                responsible: element?.responsible?.id
                  ? { id: element?.responsible?.id }
                  : { id: manager[0]?.id },
              });
            }
          });

          // Проверка сумм блогера
          if (totalPaymentSum > getSum(sent.payment.total_sum)) {
            isError = true;
            errorText = `У Исполнителя Сумма в поле Порядок оплаты больше суммы в поле Стоимость исполнителя для нас (${blogger[0]?.name})`;
          } else if (totalPaymentSum < getSum(sent.payment.total_sum)) {
            isError = true;
            errorText = `У Исполнителя Сумма в поле Порядок оплаты меньше суммы в поле Стоимость исполнителя для нас (${blogger[0]?.name})`;
          }

          // Суммы блогера
          sent.total_sum = getSum(sent.payment.total_sum);
          sent.client_sum_without_tax = getSum(sent.payment.client_sum_without_tax);
          sent.show_client = sent.payment.show_client;
          sent.is_nds = sent.payment.is_nds;
          sent.nds_sum = sent.payment.nds_sum;

          // Ответственные блогера
          const sentLawyers = [];
          const sentDocuments = [];
          const sentStaffContacts = [];

          if (!lawyer.length) {
            isError = true;
            errorText = `Выберите юриста (${blogger[0]?.name})`;
          }

          if (!doc_manager.length) {
            isError = true;
            errorText = `Выберите юриста (${blogger[0]?.name})`;
          }

          lawyer?.forEach((element) => {
            if (element.id) {
              sentLawyers.push({ id: element.id });
            } else {
              isError = true;
              errorText = `Выберите юриста (${blogger[0]?.name})`;
            }
          });
          doc_manager?.forEach((element) => {
            if (element.id) {
              sentDocuments.push({ id: element.id });
            } else {
              isError = true;
              errorText = `Выберите ДОКа (${blogger[0]?.name})`;
            }
          });
          staff_contacts?.forEach((element) => {
            if (element.id) {
              sentStaffContacts.push({ id: element.id });
            }
          });

          sent.lawyer = sentLawyers;
          sent.doc_manager = sentDocuments;
          sent.manager = [{ id: manager[0].id }];
          sent.staff_contacts = sentStaffContacts;

          // Контакты блогера
          const sentBloggerContacts = [];
          const sentAdditionalContacts = [];

          blogger_contacts.forEach((element) => {
            if (element.email) {
              sentBloggerContacts.push({ id: element.id, email: element.email });
            }
          });

          addition_emails.forEach((element) => {
            if (element.email) {
              sentAdditionalContacts.push({ id: element.id, email: element.email });
            }
          });

          if (sentBloggerContacts?.length === 0 && sentAdditionalContacts?.length === 0) {
            isError = true;
            errorText = `У вас не заполнены контакты исполнителя (${blogger[0]?.name}). Пожалуйста, заполните поле Дополнительные контакты`;
          }

          sent.blogger_contacts = sentBloggerContacts;
          sent.addition_emails = sentAdditionalContacts;

          sent.payments = sentPaymentDates;
          delete sent.payment;

          sentBloggers.push(sent);
        });

        // Ответственные
        const sentLawyers = [];
        const sentDocuments = [];
        const sentStaffContacts = [];

        responsibleLawyer.forEach((element) => {
          if (element.id) {
            sentLawyers.push({ id: element.id });
          } else if (secondStepValues.is_contract !== null) {
            isError = true;
            errorText = 'Выберите юриста во 2 шаге';
          }
        });
        responsibleDocument.forEach((element) => {
          if (element.id) {
            sentDocuments.push({ id: element.id });
          } else if (secondStepValues.is_contract !== null) {
            isError = true;
            errorText = 'Выберите ДОКа во 2 шаге';
          }
        });
        responsible.forEach((element) => {
          if (element.id) {
            sentStaffContacts.push({ id: element.id });
          }
        });

        // Контакты
        const client_contacts = [];
        const addition_emails = [];

        clientContact.forEach((contact) => {
          if (contact.email) {
            client_contacts.push({ id: contact.id, email: contact.email });
          }
        });

        additionalClientContact.forEach((contact) => {
          if (contact.email) {
            addition_emails.push({ id: contact.id, email: contact.email });
          }
        });

        if (client_contacts.length === 0 && addition_emails?.length === 0 && secondStepValues.is_contract !== null) {
          isError = true;
          errorText = 'У вас не заполнены контакты заказчика. Пожалуйста, заполните поле Дополнительные контакты';
        }

        if (getDate(firstStepValues.date_start) > getDate(firstStepValues.date_end) && secondStepValues.is_contract !== null) {
          isError = true;
          errorText = 'Конечная дата срока оказания услуг меньше начальной';
        }

        const marking_data = [];

        if (fourthStepValues.is_marked) {
          const objectSend = {
            comment: fourthStepValues.comment,
          };

          if (fourthStepValues.responsible.id >= 0) {
            objectSend.responsible = fourthStepValues.responsible.id;
          }
          if (fourthStepValues.client.id >= 0) {
            objectSend.client = fourthStepValues.client.id;
          }

          marking_data.push(objectSend);
        }

        // Основная информация
        const sentData = {
          project: {
            id: Number(id),
          },
          adv_object: firstStepValues.adv_object,
          adv_object_comment: firstStepValues.adv_object_comment,
          date_start: firstStepValues.date_start ? getDate(firstStepValues.date_start) : '',
          date_end: firstStepValues.date_end ? getDate(firstStepValues.date_end) : '',
          is_contract: secondStepValues.is_contract,
          type: { id: secondStepValues.type.id },
          post_branch: secondStepValues.post_branch,
          is_repost: isRepost,
          repost_comment: repostComment,
          is_case: isCase,
          case_comment: caseComment,
          doc_manager: sentDocuments,
          lawyer: sentLawyers,
          manager: [{ id: manager[0].id }],
          staff_contacts: sentStaffContacts,
          client_contacts,
          addition_emails,
          blogger_contracts: sentBloggers,
          marking_data,
        };

        // Даты оплат
        const sentPriceDate = [];
        let totalPaymentSum = 0;

        priceDate.forEach((element) => {
          totalPaymentSum += getSum(element.sum);

          if (element?.id >= 0) {
            sentPriceDate.push({
              id: element.id,
              date: element.date ? getDate(element.date) : '',
              sum: getSum(element.sum),
              responsible: element?.responsible?.id
                ? { id: element?.responsible?.id }
                : { id: manager[0]?.id },
            });
          } else {
            sentPriceDate.push({
              date: element.date ? getDate(element.date) : '',
              sum: getSum(element.sum),
              responsible: element?.responsible?.id
                ? { id: element?.responsible?.id }
                : { id: manager[0]?.id },
            });
          }
        });
        sentData.payments = sentPriceDate;

        if (sentData.payments[0].date === '' && secondStepValues.is_contract !== null) {
          isError = true;
          errorText = 'Не выбрана дата оплаты от заказчика';
        }

        // Проверка сумм
        let totalSumThirdStep = getSum(secondStepValuesServices.total_sum);
        if (secondStepValues.type.id === 1) {
          totalSumThirdStep = getSum(secondStepValuesAgency.total_sum);
        }

        if (secondStepValues.is_contract === false) {
          totalSumThirdStep = secondStepValuesServices.total_sum ? getSum(secondStepValuesServices.total_sum) : getSum(secondStepValuesAgency.total_sum);
        }

        if (totalPaymentSum > totalSumThirdStep) {
          isError = true;
          errorText = 'Сумма оплаты от заказчика превышает ранее введенную стоимость';
        } else if (totalPaymentSum < totalSumThirdStep) {
          isError = true;
          errorText = 'Сумма оплаты от заказчика меньше ранее введенной стоимости';
        }

        if (firstStepValues.project_id > 0) {
          sentData.project = {
            ...sentData.project,
            project_id: Number(firstStepValues.project_id),
          };
        }

        // Суммы
        if (secondStepValues.type.id === 0) {
          sentData.total_sum = getSum(secondStepValuesServices.total_sum);
          sentData.is_nds = (secondStepValuesServices.is_nds);
        } else {
          sentData.total_sum = getSum(secondStepValuesAgency.total_sum);
          sentData.ak_sum = getSum(secondStepValuesAgency.ak_sum);
          sentData.ak_percent = getSum(akProcentAgency);
          sentData.is_nds = (secondStepValuesAgency.is_nds);
        }

        // Юр лица
        if (Number.isInteger(selectedOurLegalEntity[0].id)
        || selectedOurLegalEntity[0].id === 'customId'
        || sentData.is_contract !== true) {
          if (selectedOurLegalEntity[0].id === 'customId') {
            sentData.contractor_legal_entity = null;
          } else if (Number.isInteger(selectedOurLegalEntity[0].id)) {
            sentData.contractor_legal_entity = { id: selectedOurLegalEntity[0].id };
          }
        } else if (sentData.is_contract) {
          isError = true;
          errorText = 'Выберите ЮЛ исполнителя во 2 шаге';
        }

        if (Number.isInteger(client[0].id)
          || client[0].id === 'customId'
          || sentData.is_contract !== true) {
          if (client[0].id === 'customId') {
            sentData.client_legal_entity = null;
          } else if (Number.isInteger(client[0].id)) {
            sentData.client_legal_entity = { id: client[0].id };
          }
        } else if (sentData.is_contract) {
          isError = true;
          errorText = 'Выберите ЮЛ заказчика во 2 шаге';
        }

        if (isError) {
          toast.setToastInfo(errorText);
          toast.setShowError(true);
          return;
        }

        putBid(dataProps.id, sentData).then(() => {
          onHide();
          toast.setToastInfo('Заявка отправлена');
          toast.setShowSuccess(true);
          fetchProject('application');
          fetchPublications();

          fetchMargin();
          fetchEstimate();
          fetchHistory();
        })
          .catch((error) => {
            console.log(error);
            toast.setToastInfo('Обратитесь в техподдержку');
            toast.setShowError(true);
          });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          onHide();
        }
        logout(error);
      });
  };

  const closeModal = () => {
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите закрыть заявку?',
      click: () => {
        onHide();
      },
    });
    modal.setModalConfirmVisible(true);
  };

  const draftModal = () => {
    modal.setModalConfirmProps({
      text: 'Невозможно изменить количество оплат исполнителю после сохранения черновика',
      click: () => {
        saveDraftHandler();
      },
      save: true,
    });
    modal.setModalConfirmVisible(true);
  };

  return (
    <MyModal
      show={show}
      onHide={closeModal}
      style={{ minHeight: 500 }}
      classNameDialogProps={step === 5 ? classes.dialog : ''}
      classNameCloseProps={step === 5 ? classes.dialog_close : ''}
    >
      <div className={classes.wrapper}>
        <div>
          {step === 1 && (
          <>
            <h1>Заявка на документы</h1>
            <div>
              <span className={classes.miniHeader}>
                Шаг
                {' '}
                {step}
                . Общая информация
              </span>

              <div className={classes.newContract__container}>
                {FIRST_STEP_FIELDS.map((field) => (
                  <div key={field.id}>
                    {(field.type === 'string') && (field.label !== 'Комментарий') && (
                    <InputElement
                      text={field.label}
                      placeholder={field.placeholder}
                      value={firstStepValues[field.id]}
                      onChange={(event) => updateFirstStepValues(
                        field.id,
                        event.target.value,
                      )}
                      type={field.type}
                      disabled={field.disabled}
                    />
                    )}

                    {field.label === 'Комментарий'
                          && (
                          <InputElement
                            text={field.label}
                            placeholder={field.placeholder}
                            value={firstStepValues[field.id]}
                            onChange={(event) => updateFirstStepValues(
                              field.id,
                              event.target.value,
                            )}
                            type={field.type}
                            textarea
                            textareaStyle={{ maxHeight: '90px', height: '90px' }}
                            disabled={field.disabled}
                            elementClassName={classes.comment__container}
                          />
                          )}

                    {field.type === 'doubleDate' && (
                    <DateElement
                      value1={firstStepValues.date_start}
                      value2={firstStepValues.date_end}
                      onChange1={(event) => updateFirstStepValues('date_start', event)}
                      onChange2={(event) => updateFirstStepValues('date_end', event)}
                      type="date"
                      text={field.label}
                      style={{ margin: '10px 0' }}
                      inner_style={{ width: 'auto' }}
                    />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
          )}

          {step === 2 && (
          <div>
            <div className={classes.contract__header}>
              <SuccessBtn
                text="Вернуться назад"
                onClick={prevStep}
                type="white"
                back
                style={{
                  width: 142,
                  padding: '2px 10px',
                  fontSize: '12px',
                  marginRight: 40,
                }}
              />

              <span className={classes.miniHeader}>
                Шаг
                {' '}
                {step}
                . Информация о заказчике
              </span>
            </div>

            <div className={classes.newContract__container}>
              <CheckboxElement
                text="Договор"
                className={classes.margin_zero}
              >
                <CheckboxItem
                  text="Да"
                  status={secondStepValues.is_contract === true}
                  setStatus={() => updateSecondStepValues('is_contract', true)}
                  className={classes.checkbox__mini}
                />

                <CheckboxItem
                  text="Нет"
                  status={secondStepValues.is_contract === false}
                  setStatus={() => updateSecondStepValues('is_contract', false)}
                  className={classes.checkbox__mini}
                />

                <CheckboxItem
                  text="Будет позже"
                  status={secondStepValues.is_contract === null}
                  setStatus={() => updateSecondStepValues('is_contract', null)}
                  className={classes.checkbox}
                />
              </CheckboxElement>

              {secondStepValues.is_contract !== false && (
              <CheckboxElement
                text="Тип договора"
                className={classes.margin_zero}
                disabled={secondStepValues.is_contract === null}
              >
                {CHECKBOX_QUANTITY.map((element) => (
                  <CheckboxItem
                    key={element}
                    text={element === 0 ? 'Услуг' : 'Агентский'}
                    status={secondStepValues.type.id === element}
                    setStatus={() => updateSecondStepValues('type', { id: element })}
                    className={classes.checkbox__mini}
                    disabled={secondStepValues.is_contract === null}
                  />
                ))}
              </CheckboxElement>
              )}

              <ContractElement
                text="ЮЛ заказчика*"
                style={{ margin: 0 }}
                disabled={secondStepValues.is_contract !== true}
                blur={secondStepValues.is_contract === null}
              >
                <div className={classes.ourСontacts__container}>
                  <SearchBar
                    text={client[0]}
                    setText={changeClient}
                    list={allLegalEntity}
                    type="legal_details"
                    disabled={secondStepValues.is_contract !== true}
                  />

                  {legalEntityMatches?.length === 0 && !client[0].id && !addLegalEntityVisible && (
                  <div className={classes.addButton}>
                    <NewAddBtn
                      text="Добавить ЮЛ"
                      onClick={() => {
                        updateLegalEntities(legalEntities[0].id, 'legal_name', client[0].name);
                        setClient([{ ...client[0], name: '' }]);
                        setAddLegalEntityVisible(true);
                      }}
                    />
                  </div>
                  )}
                </div>
              </ContractElement>

              {addLegalEntityVisible
          && (
          <div className={classes.addBlogger__container}>
            <InputElement
              text="Юридическое лицо"
              placeholder="Введите текст"
              value={legalEntities[0]?.legal_name}
              onChange={(event) => updateLegalEntities(legalEntities[0].id, 'legal_name', event.target.value)}
            />

            <InputElement
              text="Форма ЮЛ"
              placeholder="Введите текст"
              value={legalEntities[0]?.type}
              setValue={(value) => updateLegalEntities(legalEntities[0].id, 'type', value)}
              dropdown={LEGAL_TYPES.LIST}
            />

            <InputElementInn
              text="ИНН"
              element={legalEntities[0]}
              setLegalEntities={setLegalEntities}
              legalEntities={legalEntities}
              value={legalEntities[0]?.inn}
              onChange={(value) => updateLegalEntities(legalEntities[0].id, 'inn', value)}
            />
            <div className={classes.note}>
              <div>
                *При вводе ИНН наименование ЮЛ будет заполнено
                автоматически (Кроме СЗ и иностранных ЮЛ)
              </div>
            </div>

            <InputElement
              text="Ссылка на документы"
              placeholder="Введите текст"
              value={legalEntities[0]?.file_links}
              onChange={(event) => updateLegalEntities(legalEntities[0].id, 'file_links', event.target.value)}
              tooltipText={tooltipText}
            />

            <div className={classes.addLegalEntity__btn__container}>
              <SuccessBtn
                text="Создать"
                btnType="button"
                className={classes.addLegalEntity__btn}
                onClick={() => createLegalEntityHandler()}
              />

              <SuccessBtn
                text="Отменить"
                type="white"
                btnType="button"
                className={classes.addLegalEntity__btn}
                onClick={() => closeCreateLegalEntityHandler()}
              />
            </div>
          </div>
          )}

              <ContractElement
                text="ЮЛ исполнителя*"
                style={{ margin: 0 }}
                disabled={secondStepValues.is_contract !== true}
                blur={secondStepValues.is_contract === null}
              >
                <div className={classes.ourСontacts__container}>
                  <SearchBar
                    text={selectedOurLegalEntity[0]}
                    setText={changeSelectedOurLegalEntity}
                    list={allLegalEntity}
                    type="legal_details"
                    disabled={secondStepValues.is_contract !== true}
                  />
                </div>
              </ContractElement>

              {secondStepValues.type.id === 0 && (
                <>
                  <InputElement
                    text="Сумма договора с заказчиком"
                    type="number"
                    placeholder="0,00 Р"
                    value={secondStepValuesServices.total_sum}
                    onChange={(event) => updateSecondStepValuesServices(
                      'total_sum',
                      getSumRuble(event.target.value),
                    )}
                    elementClassName={classes.margin_zero}
                    disabled={secondStepValues.is_contract === null}
                    blur={secondStepValues.is_contract === null}
                  />

                  {secondStepValues.is_contract !== false
                    && (
                    <CheckboxElement
                      text="НДС"
                      className={classes.margin_zero}
                      disabled={secondStepValues.is_contract === null}
                    >
                      <CheckboxItem
                        text="Да"
                        status={secondStepValuesServices.is_nds}
                        setStatus={() => updateSecondStepValuesServices('is_nds', true)}
                        className={classes.checkbox__mini}
                        disabled={secondStepValues.is_contract === null}
                      />

                      <CheckboxItem
                        text="Нет"
                        status={!secondStepValuesServices.is_nds}
                        setStatus={() => updateSecondStepValuesServices('is_nds', false)}
                        className={classes.checkbox__mini}
                        disabled={secondStepValues.is_contract === null}
                      />
                    </CheckboxElement>
                    )}

                  {secondStepValuesServices.is_nds && secondStepValues.is_contract !== false && (
                  <>
                    <InputElement
                      text="Сумма НДС"
                      type="number"
                      placeholder="0,00 Р"
                      value={numberToSum((getSum(secondStepValuesServices.total_sum) * 20) / 120)}
                      elementClassName={classes.margin_zero}
                      disabled
                    />

                    <InputElement
                      text="Сумма договора без НДС"
                      type="number"
                      placeholder="0,00 Р"
                      value={numberToSum(getSum(secondStepValuesServices.total_sum) / 1.2)}
                      elementClassName={classes.margin_zero}
                      disabled
                    />
                  </>
                  )}

                </>
              )}

              {secondStepValues.type.id === 1 && (
              <>
                <InputElement
                  text="Сумма договора с заказчиком (с АК)"
                  type="number"
                  placeholder="0,00 Р"
                  value={secondStepValuesAgency.total_sum}
                  onChange={(event) => updateSecondStepValuesAgency(
                    'total_sum',
                    getSumRuble(event.target.value),
                  )}
                  elementClassName={classes.margin_zero}
                  textClassName={classes.padding_40}
                  disabled={secondStepValues.is_contract === null}
                />

                <InputElement
                  text="Процент АК"
                  type="number"
                  value={akProcentAgency}
                  onChange={(event) => setAkProcentAgency(getSumRuble(event.target.value))}
                  elementClassName={classes.margin_zero}
                  disabled={secondStepValues.is_contract === null}
                />

                <InputElement
                  text="Сумма АК"
                  type="number"
                  placeholder="0,00 Р"
                  value={secondStepValuesAgency.ak_sum}
                  onChange={(event) => updateSecondStepValuesAgency(
                    'ak_sum',
                    getSumRuble(event.target.value),
                  )}
                  elementClassName={classes.margin_zero}
                  disabled={secondStepValues.is_contract === null}
                />

                <CheckboxElement
                  text="НДС"
                  className={classes.margin_zero}
                  disabled={secondStepValues.is_contract === null}
                >
                  <CheckboxItem
                    text="Да"
                    status={secondStepValuesAgency.is_nds}
                    setStatus={() => updateSecondStepValuesAgency('is_nds', true)}
                    className={classes.checkbox__mini}
                    disabled={secondStepValues.is_contract === null}
                  />

                  <CheckboxItem
                    text="Нет"
                    status={!secondStepValuesAgency.is_nds}
                    setStatus={() => updateSecondStepValuesAgency('is_nds', false)}
                    className={classes.checkbox__mini}
                    disabled={secondStepValues.is_contract === null}
                  />
                </CheckboxElement>

                {secondStepValuesAgency.is_nds && (
                  <>
                    <InputElement
                      text="Сумма НДС"
                      type="number"
                      placeholder="0,00 Р"
                      value={numberToSum((getSum(secondStepValuesAgency.ak_sum) * 20) / 120)}
                      elementClassName={classes.margin_zero}
                      disabled
                    />

                    <InputElement
                      text="Сумма договора без НДС"
                      type="number"
                      placeholder="0,00 Р"
                      value={numberToSum(getSum(secondStepValuesAgency.ak_sum) / 1.2)}
                      elementClassName={classes.margin_zero}
                      disabled
                    />
                  </>
                )}
              </>
              )}

              <ContractElement
                text="Порядок оплаты от заказчика*"
                disabled={secondStepValues.is_contract === null}
                blur={secondStepValues.is_contract === null}
              >
                <div className={classes.date__container}>
                  {priceDate.map((date) => (
                    <PaymentDateNoTime
                      isSent={isSent}
                      key={date.reactId}
                      element={date}
                      deleteDate={deleteDate}
                      changeDate={changeDate}
                      disabled={secondStepValues.is_contract === null}
                    />
                  ))}
                </div>
              </ContractElement>

              <div className={classes.addDate}>
                <NewAddBtn
                  text="Добавить оплату"
                  onClick={secondStepValues.is_contract === null ? null
                    : addDateHandler}
                />
              </div>

              <CheckboxElement
                text="Репост"
                className={classes.margin_zero}
                disabled={secondStepValues.is_contract === null}
              >
                <CheckboxItem
                  text="Да"
                  status={isRepost}
                  setStatus={() => setIsRepost(true)}
                  className={classes.checkbox__mini}
                  disabled={secondStepValues.is_contract === null}
                />

                <CheckboxItem
                  text="Нет"
                  status={!isRepost}
                  setStatus={() => setIsRepost(false)}
                  className={classes.checkbox__mini}
                  disabled={secondStepValues.is_contract === null}
                />
              </CheckboxElement>

              {isRepost && (
                <InputElement
                  type="text"
                  value={repostComment}
                  setValue={setRepostComment}
                  style={{ marginLeft: 260 }}
                  placeholder="Введите комментарий"
                  disabled={secondStepValues.is_contract === null}
                />
              )}

              <CheckboxElement
                text="Возможен ли кейс"
                className={classes.margin_zero}
                disabled={secondStepValues.is_contract === null}
              >
                <CheckboxItem
                  text="Да"
                  status={isCase}
                  setStatus={() => setIsCase(true)}
                  className={classes.checkbox__mini}
                  disabled={secondStepValues.is_contract === null}
                />

                <CheckboxItem
                  text="Нет"
                  status={!isCase}
                  setStatus={() => setIsCase(false)}
                  className={classes.checkbox__mini}
                  disabled={secondStepValues.is_contract === null}
                />
              </CheckboxElement>

              {isCase && (
                <InputElement
                  type="text"
                  value={caseComment}
                  setValue={setCaseComment}
                  style={{ marginLeft: 260 }}
                  placeholder="Введите комментарий"
                  disabled={secondStepValues.is_contract === null}
                />
              )}

              <ContractElement
                text="Контактные лица с нашей стороны"
                style={{ alignItems: 'start', marginBottom: 10 }}
                disabled={secondStepValues.is_contract === null}
                blur={secondStepValues.is_contract === null}
              >
                <div className={classes.ourСontacts__container}>
                  {responsibleLawyer.map((element) => (
                    <SearchBar
                      key={element.id}
                      list={lawyerList}
                      text={element}
                      setText={changeResponsibleLawyer}
                      placeholder="Юрист"
                      type="staff"
                      disabled={secondStepValues.is_contract === null}
                    />
                  ))}

                  {responsibleDocument.map((element) => (
                    <SearchBar
                      key={element.id}
                      list={documentList}
                      text={element}
                      setText={changeResponsibleDocument}
                      placeholder="Док"
                      type="staff"
                      disabled={secondStepValues.is_contract === null}
                    />
                  ))}

                  <InputElement
                    value={manager[0].fullname}
                    style={{ margin: 0 }}
                    disabled
                  />

                  {responsible.map((element) => (
                    <SearchBar
                      key={element.fieldId}
                      list={managerList}
                      text={element}
                      setText={changeResponsible}
                      checkResponsible={checkResponsible}
                      deleteElement={deleteResponsible}
                      type="staff"
                      disabled={secondStepValues.is_contract === null}
                    />
                  ))}

                  <NewAddBtn
                    text="Добавить контакт"
                    onClick={secondStepValues.is_contract === null ? null : () => addResponsible()}
                  />
                </div>
              </ContractElement>

              {clientContact.length > 0
                && (
                <ContractElement
                  text="Контакты заказчика"
                  style={{
                    alignItems: 'start',
                    marginBottom: 20,
                  }}
                  textStyle={{ height: 32 }}
                  disabled={secondStepValues.is_contract === null}
                  blur={secondStepValues.is_contract === null}
                >
                  <div className={classes.ourСontacts__container}>
                    {clientContact.map((contact) => (
                      <InputElement
                        key={contact.id}
                        value={contact.email}
                        style={{ margin: 0 }}
                        deleteHandler={() => deleteClientContact(contact.id, true)}
                        placeholder="Почта из карточки заказчика"
                        cancelBtn
                        disabled
                      />
                    ))}
                  </div>
                </ContractElement>
                )}

              <ContractElement
                text="Дополнительные контакты"
                style={{
                  alignItems: additionalClientContact?.length ? 'start' : 'center',
                  marginBottom: 20,
                }}
                textStyle={{ height: 32 }}
                disabled={secondStepValues.is_contract === null}
                blur={secondStepValues.is_contract === null}
              >
                <div className={classes.ourСontacts__container}>
                  {additionalClientContact.map((contact) => (
                    <InputElement
                      key={contact.id}
                      value={contact.email}
                      onChange={(event) => changeAdditionalClientContact(contact.id, 'email', event.target.value)}
                      deleteHandler={() => deleteAdditionalClientContact(contact.id, true)}
                      disabled={secondStepValues.is_contract === null}
                      style={{ margin: 0 }}
                      placeholder="Введите почту..."
                      cancelBtn
                    />
                  ))}

                  <NewAddBtn
                    text="Добавить контакт"
                    onClick={secondStepValues.is_contract === null ? null : () => addAdditionalClientContact()}
                  />
                </div>
              </ContractElement>

              <InputElement
                text="Название ветки"
                value={secondStepValues.post_branch}
                onChange={(event) => updateSecondStepValues('post_branch', event.target.value)}
                placeholder="Введите текст..."
                elementClassName={classes.margin_zero}
                disabled={secondStepValues.is_contract === null}
                blur={secondStepValues.is_contract === null}
              />

              <ContractElement
                text="Прикрепить файл к договору заказчика"
                disabled={secondStepValues.is_contract === null}
                elementClassName={classes.margin_15}
                blur={secondStepValues.is_contract === null}
              >
                <div className={classes.ourСontacts__container}>
                  {files?.length > 0 && (
                    <div className={classes.files}>
                      {files.map((file) => (
                        <Files
                          key={file.id}
                          file={file}
                          deleteFileHandler={() => deleteFileHandler(file.id)}
                          fetchBID={getBid}
                          setFiles={setFiles}
                        />
                      ))}
                    </div>
                  )}

                  <MyInputFile
                    onChange={(event) => addFileHandler(event)}
                    multiple
                    accept=".ppt, .pptx, .xls, .xlsx, .doc, .docx, .jpeg, .jpg, .png, .pdf, .key, .heif"
                    htmlFor={`bid_${dataProps?.id}`}
                    disabled={secondStepValues.is_contract === null}
                  />
                </div>
              </ContractElement>
            </div>
          </div>
          )}

          {step === 3 && (
          <div>
            <div className={classes.contract__header}>
              <SuccessBtn
                text="Вернуться назад"
                onClick={prevStep}
                type="white"
                back
                style={{
                  width: 142,
                  padding: '2px 10px',
                  fontSize: '12px',
                  marginRight: 40,
                }}
              />

              <span className={classes.miniHeader}>
                Шаг
                {' '}
                {step}
                . Заявка на исполнителя
              </span>
            </div>

            <div className={classes.contract__container}>
              <div>
                {bloggers.map((blogger, index) => (
                  <BloggerElement
                    key={blogger.id}
                    index={index}
                    blogger={blogger}
                    bloggersList={[...clientsList, ...bloggersList]}
                    allLegalEntity={allLegalEntity}
                    ourLegalEntity={ourLegalEntity}
                    setAllLegalEntity={setAllLegalEntity}
                    setBloggersList={setBloggersList}
                    deleteBlogger={deleteBlogger}
                    updateBlogger={updateBlogger}
                    dataProps={dataProps}
                    managerList={managerList}
                    lawyerList={lawyerList}
                    documentList={documentList}
                    contractType={secondStepValues.type.id}
                    show={show}
                    onHide={onHide}
                  />
                ))}
              </div>

              <ModalConfirm
                show={modal.modalConfirmVisible}
                onHide={() => modal.setModalConfirmVisible(false)}
                props={modal.modalConfirmProps}
              />

              <div className={classes.addBtn__container}>
                <NewAddBtn
                  text="Добавить исполнителя"
                  onClick={() => addBlogger()}
                />
              </div>
            </div>
          </div>
          )}

          {step === 4 && (
          <div>
            <div className={classes.contract__header}>
              <SuccessBtn
                text="Вернуться назад"
                onClick={prevStep}
                type="white"
                back
                style={{
                  width: 142,
                  padding: '2px 10px',
                  fontSize: '12px',
                  marginRight: 40,
                }}
              />

              <span className={classes.miniHeader}>
                Шаг
                {' '}
                {step}
                . Маркировка
              </span>
            </div>

            <div className={classes.newContract__container}>
              <CheckboxElement
                text="Наличие маркировки"
                className={classes.margin_zero}
              >
                <CheckboxItem
                  text="Да"
                  status={fourthStepValues.is_marked === true}
                  setStatus={() => updateFourthStepValuesAgency('is_marked', true)}
                  className={classes.checkbox__mini}
                />

                <CheckboxItem
                  text="Нет"
                  status={fourthStepValues.is_marked === false}
                  setStatus={() => updateFourthStepValuesAgency('is_marked', false)}
                  className={classes.checkbox__mini}
                />

              </CheckboxElement>

              {fourthStepValues.is_marked && (
              <>

                <InputElement
                  text="Ответственный за токен"
                  placeholder="Введите текст"
                  value={fourthStepValues?.responsible}
                  setValue={(value) => updateFourthStepValuesAgency('responsible', value)}
                  dropdown={RESPONSIBLE_TOKEN.LIST}
                  elementClassName={classes.margin_zero}
                />

                <InputElement
                  text="Заказчик"
                  placeholder="Введите текст"
                  value={fourthStepValues?.client}
                  setValue={(value) => updateFourthStepValuesAgency('client', value)}
                  dropdown={RESPONSIBLE_TOKEN_CLIENT.LIST}
                  elementClassName={classes.margin_zero}
                />

                <InputElement
                  text="Дополнительная информация"
                  placeholder="Введите текст"
                  type="string"
                  value={fourthStepValues.comment}
                  onChange={(event) => updateFourthStepValuesAgency(
                    'comment',
                    event.target.value,
                  )}
                  textarea
                  textareaStyle={{ maxHeight: '130px', height: '130px' }}
                  elementClassName={classes.comment__container}
                />
              </>
              )}
            </div>
          </div>
          )}

          {step === 5 && (
          <div>
            <div className={classes.contract__header}>
              <SuccessBtn
                text="Вернуться назад"
                onClick={prevStep}
                type="white"
                back
                style={{
                  width: 142,
                  padding: '2px 10px',
                  fontSize: '12px',
                  marginRight: 40,
                }}
              />

              <span className={classes.miniHeader}>
                Сводная информация по заявке
              </span>
            </div>

            <div className={classes.newContract__container}>
              <PaymentsTable
                headers={HEADERS}
                data={bloggers}
                additionalData={firstStepValues}
              />
            </div>
          </div>
          )}
        </div>

        <div className={classes.btn__container}>
          {!isSent && (
          <SuccessBtn
            text="Сохранить черновик"
            type="white"
            onClick={() => draftModal()}
            style={{ padding: '3px 15px', fontSize: 13, width: 200 }}
          />
          )}

          {step === 5 ? (
            <SuccessBtn
              text={
                  isSent ? 'Заменить данные в договоре' : 'Отправить заявку'
                }
              onClick={sendDraft}
              style={{ padding: '3px 15px', fontSize: 13, width: 260 }}
            />
          ) : (
            <SuccessBtn
              text="Продолжить"
              onClick={nextStep}
              style={{ padding: '3px 15px', fontSize: 13, width: 200 }}
            />
          )}
        </div>
      </div>
    </MyModal>
  );
}

export default ModalCreateContract;
