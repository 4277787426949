import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { MAIN_ROUTE } from 'utils/consts';

const Salaries = observer(() => {
  const { user } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.department === 1 || user.department === 2) {
      navigate(MAIN_ROUTE);
    }
  }, [user.department]);

  return (
    <section className="Mysection">
      <Helmet>
        <title>Зарплаты | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
    </section>
  );
});

export default Salaries;
