// @ts-nocheck
import React from 'react';

import DeleteBtn from 'components/UI/Btns/DeleteBtn';

import { getDate } from 'utils/getDate';
import { numberToSum } from 'utils/getSums';
import { getSumRuble } from '../../../utils/getSums';

import { ACCOUNT_STATUS, CONTRACT_STATUS } from '../../../utils/consts';

import InputElement from '../InputElements/InputElement';

import classes from './ContractStatusBlock.module.css';

function ContractStatusBlock(props) {
  const {
    text,
    element,
    updateFunc,
    type,
    index,
    onClick,
    disabledSum,
    state,
    setState,
    deleteFunc,
    info,
  } = props;

  const valueForDropdown = (type === 'billStatus' && {
    id: element?.type_id,
    label: ACCOUNT_STATUS.LIST[element?.type_id]?.label,
  })
    || (type === 'closingStatus' && {
      id: element?.closing_type_id,
      label: CONTRACT_STATUS.LIST[element?.closing_type_id]?.label,
    })
    || (type === 'contractStatus' && element.status);

  const funcUpdateForDropdown = (value) => {
    if (type === 'billStatus') {
      setState(
        state.map((el) => (el.id === element.id
          ? { ...el, date: getDate(Date.now()), type_id: value.id }
          : el)),
      );
    } else if (type === 'closingStatus') {
      setState(
        state.map((el) => (el.id === element.id
          ? {
            ...el,
            status_date: getDate(Date.now()),
            closing_type_id: value.id,
            date: getDate(info.date_end),
          }
          : el)),
      );
    } else if (type === 'contractStatus') {
      updateFunc(element.id, 'status', value);
    }
  };

  return (
    <div className={classes.wrapper}>
      {index === 0 || type === 'contractStatus' ? <span>{text}</span> : <div />}
      <div className={classes.container}>
        <InputElement
          elementClassName={classes.margin_zero}
          value={numberToSum(element?.sum)}
          onChange={(event) => updateFunc(element.id, 'sum', getSumRuble(event.target.value))}
          placeholder="0,00 Р"
          onClick={onClick}
          disabled={disabledSum}
        />
        <InputElement
          date
          value={
            type === 'closingStatus' ? element?.status_date : element?.date
          }
          onChange={(event) => updateFunc(
            element.id,
            type === 'closingStatus' ? 'status_date' : 'date',
            event,
          )}
          elementClassName={classes.margin_zero}
          className={classes.date}
          onClick={onClick}
        />
        <InputElement
          value={valueForDropdown}
          setValue={(value) => funcUpdateForDropdown(value)}
          dropdown={
            (type === 'billStatus' && ACCOUNT_STATUS.LIST)
            || ((type === 'closingStatus' || type === 'contractStatus')
              && CONTRACT_STATUS.LIST)
          }
          elementClassName={classes.margin_zero}
          onClick={onClick}
        />

        {type === 'closingStatus' && (
          <InputElement
            elementClassName={classes.margin_zero}
            value={numberToSum(element?.quarter)}
            disabled
          />
        )}

        {index !== 0 && (type === 'closingStatus' || type === 'billStatus') && (
          <DeleteBtn
            onClick={() => {
              deleteFunc(element.id);
              onClick();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ContractStatusBlock;
