export const columns = [
  { id: 'type', label: 'Формат договора' },
  { id: 'client', label: 'ЮЛ заказчика' },
  { id: 'contractor', label: 'ЮЛ исполнителя' },
  { id: 'lawyer', label: 'Юрист' },
  { id: 'doc_manager', label: 'ДОК' },
  { id: 'status', label: 'Статус документа' },
  { id: 'marking_data', label: 'Маркировка' },
  { id: 'payment_date', label: 'Порядок оплаты' },
  { id: 'paid_sum', label: 'Оплачено' },
  { id: 'credit', label: 'Остаток' },
  { id: 'comment', label: 'Комментарий' },
];

export const FIELDS = [
  {
    id: 'id',
    label: '№ проекта',
    type: 'number',
    width: 1,
  },
  {
    id: 'id_status',
    label: '№ проекта',
    type: 'dropdown',
    list: 'project_sent_date_deadline',
    width: 3,
  },
  {
    id: 'project',
    label: 'Название проекта',
    type: 'autocomplete',
    list: 'project',
    multiple: true,
    width: 3,
  },
  {
    id: 'client_id',
    label: 'ЮЛ заказчика',
    type: 'autocompleteLegal',
    multiple: true,
    width: 3,
  },
  {
    id: 'contractor_id',
    label: 'ЮЛ исполнителя',
    type: 'autocompleteLegal',
    multiple: true,
    width: 3,
  },
  {
    id: 'manager',
    label: 'Менеджер',
    type: 'autocompleteStaff',
    list: 'managers',
    multiple: true,
    width: 3,
  },
  {
    id: 'lawyer',
    label: 'Юрист',
    type: 'autocompleteStaff',
    list: 'lawyers',
    multiple: true,
    width: 3,
  },
  {
    id: 'document',
    label: 'ДОК',
    type: 'autocompleteStaff',
    list: 'documents',
    multiple: true,
    width: 3,
  },
  {
    id: 'bid_sent_date',
    label: 'Дата отправки заявки',
    type: 'doubleDateWithStatus',
    value_id_1: 'bid_sent_date_start',
    value_id_2: 'bid_sent_date_end',
    date_status_value: 'bid_sent_date_status',
    width: 6,
  },
  {
    id: 'contract_sent_date',
    label: 'Дата отправки договора',
    type: 'doubleDateWithStatus',
    value_id_1: 'contract_sent_date_start',
    value_id_2: 'contract_sent_date_end',
    date_status_value: 'contract_sent_date_status',
    width: 6,
  },
  {
    id: 'branch_name',
    label: 'Название ветки',
    type: 'text',
    width: 3,
  },
  {
    id: 'contract_id',
    label: '№ договора',
    type: 'text',
    width: 1,
  },
  {
    id: 'total_sum',
    label: 'Сумма договора',
    type: 'sum',
    width: 3,
  },
  {
    id: 'payment_date',
    label: 'Дата оплаты',
    type: 'doubleDateWithStatus',
    value_id_1: 'payment_date_start',
    value_id_2: 'payment_date_end',
    date_status_value: 'payment_date_status',
    width: 6,
  },
  {
    id: 'service_date_start',
    label: 'Срок оказания услуг (н)',
    type: 'doubleDate',
    value_id_1: 'service_start',
    value_id_2: 'service_end',
    width: 5,
  },
  {
    id: 'service_date_end',
    label: 'Срок оказания услуг (к)',
    type: 'doubleDate',
    value_id_1: 'service_end_start',
    value_id_2: 'service_end_end',
    width: 5,
  },
  {
    id: 'marking_data',
    label: 'Маркировка',
    type: 'dropdown',
    list: 'true_false',
    width: 3,
  },
  {
    id: 'checkbox_group',
    type: 'checkbox_group',
    checkboxes: [
      {
        id: 'contract_type',
        label: 'Формат договора',
        type: 'checkboxes',
        width: 2,
        checkboxes: [
          { id: 0, label: 'Клиентский (у)' },
          { id: 1, label: 'Клиентский (а)' },
          { id: 2, label: 'Блогерский' },
          { id: 3, label: 'Промежуточный' },
          { id: 4, label: 'СК расход' },
          { id: 7, label: 'СК доход' },
        ],
      },
      {
        id: 'contract_status',
        label: 'Статус договора',
        type: 'checkboxes',
        width: 3,
        checkboxes: [
          { id: 0, label: 'Согласование' },
          { id: 1, label: 'Направлен в ЭДО' },
          { id: 2, label: 'Подписан в ЭДО' },
          { id: 3, label: 'Получены сканы' },
          { id: 4, label: 'Направлены сканы' },
          { id: 5, label: 'Направлены оригиналы' },
          { id: 6, label: 'Обменялись оригиналами' },
        ],
      },
      {
        id: 'closing_document_status',
        label: 'Статус закрывающих документов',
        type: 'checkboxes',
        width: 4,
        checkboxes: [
          { id: 0, label: 'Согласование' },
          { id: 1, label: 'Направлен в ЭДО' },
          { id: 2, label: 'Подписан в ЭДО' },
          { id: 3, label: 'Получены сканы' },
          { id: 4, label: 'Направлены сканы' },
          { id: 5, label: 'Направлены оригиналы' },
          { id: 6, label: 'Обменялись оригиналами' },
          { id: 7, label: 'Чек получен' },
        ],
      },
      {
        id: 'receipt_status',
        label: 'Статус счета',
        type: 'checkboxes',
        width: '1_5',
        checkboxes: [
          { id: 0, label: 'Счет направлен' },
          { id: 1, label: 'Счет получен' },
        ],
      },
      {
        id: 'indicator',
        label: 'Индикатор',
        type: 'checkboxes',
        width: 1,
        checkboxes: [
          { id: 0, label: 'Зеленый', param: 'green' },
          { id: 1, label: 'Желтый', param: 'yellow' },
          { id: 2, label: 'Красный', param: 'red' },
        ],
      },
    ],
  },
];

export const SHORT_FIELDS = [
  {
    id: 'id',
    label: '№ проекта',
    type: 'number',
    width: 1,
  },
  {
    id: 'project_title',
    label: 'Название проекта',
    type: 'text',
    width: 3,
  },
  {
    id: 'client_id',
    label: 'ЮЛ',
    type: 'autocompleteLegal',
    list: 'client',
    width: 3,
  },
  {
    id: 'contractor_id',
    label: 'ЮЛ',
    type: 'autocompleteLegal',
    list: 'our',
    width: 3,
  },
];

export const STATUS_QUANTITY = [0, 1, 2, 3, 4, 5, 6, 7];
