import { DATE_DROPDOWN_SHORT } from 'utils/consts';

export const COSTS_FIELDS = [
  {
    id: 'id', label: '№ проекта', type: 'number', width: 1,
  },
  {
    id: 'payment_id', label: '№ платежа(ID)', type: 'number', width: 1,
  },
  {
    id: 'project',
    label: 'Название проекта',
    type: 'autocomplete',
    list: 'project',
    multiple: true,
    width: 3,
  },
  {
    id: 'client_id',
    label: 'ЮЛ заказчика',
    type: 'autocompleteLegal',
    multiple: true,
    width: 3,
  },
  {
    id: 'contractor_id',
    label: 'ЮЛ исполнителя',
    type: 'autocompleteLegal',
    multiple: true,
    width: 3,
  },
  {
    id: 'manager',
    label: 'Отправитель',
    type: 'autocompleteStaff',
    list: 'managers',
    multiple: true,
    width: 3,
  },
  {
    id: 'document',
    label: 'ДОК',
    type: 'autocompleteStaff',
    list: 'documents',
    multiple: true,
    width: 3,
  },
  {
    id: 'payment_type',
    label: 'Тип платежа',
    type: 'multipleDropdown',
    list: 'payment_type',
    multiple: true,
    width: 3,
  },
  {
    id: 'id_contract', label: '№ договора', type: 'text', width: 2,
  },
  {
    id: 'contract_status',
    label: 'Статус договора',
    type: 'dropdown',
    list: 'contract_status',
    multiple: true,
    width: 3,
  },
  {
    id: 'date_bid_sent',
    label: 'Дата отправки заявки',
    type: 'doubleDateWithStatus',
    value_id_1: 'date_bid_sent_start',
    value_id_2: 'date_bid_sent_end',
    date_status_value: 'date_bid_sent_status',
    list: DATE_DROPDOWN_SHORT.LIST,
    width: 6,
  },
  {
    id: 'payment_date',
    label: 'Дата оплаты',
    type: 'doubleDateWithStatus',
    value_id_1: 'payment_date_start',
    value_id_2: 'payment_date_end',
    date_status_value: 'payment_date_status',
    list: DATE_DROPDOWN_SHORT.LIST,
    width: 6,
  },
  {
    id: 'indicators',
    label: 'Индикатор',
    type: 'dropdown',
    list: 'indicators_2',
    indicators: true,
    width: 3,
  },
];
