// @ts-nocheck
import React, { memo, useCallback, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import arrowClose from '../../../assets/icons/Btns/arrow_project_accounting.svg';
import arrow from '../../../assets/icons/Btns/arrow_project_accounting_close.svg';

import RegistryTableHeader from './components/RegistryTableHeader';
import RegistryTableRow from './components/RegistryTableRow';

import classes from './RegistryTable.module.scss';

const TABLE_PARAMS = {
  projects: {
    className: classes.projectsTable,
    headClassName: classes.head__lawyerProjects,
    bodyClassName: classes.body__lawyerProjects,
    rowClassName: classes.row__lawyerProjects,
    cellClassName: classes.cell__lawyerProjects,
    linkTo: '/contract',
  },
  projects_alternative: {
    className: classes.projectsAlternativeTable,
    headClassName: classes.head__projectsAlternativeTable,
    bodyClassName: classes.body__projectsAlternativeTable,
    rowClassName: classes.row__projectsAlternativeTable,
    cellClassName: classes.cell__projectsAlternativeTable,
    linkTo: '/project',
  },
  projects_alternative_2: {
    className: classes.projectsAlternativeTable2,
    headClassName: classes.head__projectsAlternativeTable2,
    rowClassName: classes.row__projectsAlternativeTable2,
    cellClassName: classes.cell__projectsAlternativeTable2,
    linkTo: '/project',
  },
  projectsManager: {
    className: classes.projectsManager,
    linkTo: '/project',
  },
  costsFinance: {
    className: classes.costsFinance,
    headClassName: classes.head__costsFinance,
    bodyClassName: classes.body__costsFinance,
    rowClassName: classes.row__costsFinance,
    cellClassName: classes.cell__costsFinance,
    linkTo: '/cost',
  },
  reportProjects: {
    className: classes.reportProjects,
    headClassName: classes.head__reportProjects,
    bodyClassName: classes.body__reportProjects,
    rowClassName: classes.row__reportProjects,
    cellClassName: classes.cell__reportProjects,
    linkTo: '/project',
  },
  projectAccounting: {
    className: classes.projectAccounting,
    headClassName: classes.head__projectAccounting,
    bodyClassName: classes.body__projectAccounting,
    rowClassName: classes.row__projectAccounting,
    cellClassName: classes.cell__projectAccounting,
    linkTo: '/project',
  },

};

function RegistryTableProjectAccounting(props) {
  const {
    tableName,
    columns,
    data,
    tableRef,
    style,
    tableStyle,
    noDeadline,
    projectAccounting,
    fetching,
  } = props;

  const [expandedRowId, setExpandedRowId] = useState(null);

  const toggleExpand = useCallback((rowId) => {
    setExpandedRowId((prevRowId) => (prevRowId === rowId ? null : rowId));
  }, []);

  return (
    <div className={classes.container}>
      <div className={[classes.table, TABLE_PARAMS[tableName]?.className].join(' ')} style={tableStyle}>
        <RegistryTableHeader
          columns={columns}
          headClassName={TABLE_PARAMS[tableName]?.headClassName}
          cellClassName={TABLE_PARAMS[tableName]?.cellClassName}
        />

        <div
          className={[classes.body, TABLE_PARAMS[tableName].bodyClassName].join(' ')}
          ref={tableRef}
          style={style}
        >
          {Array.isArray(data)
          && data.map((row) => (
            <div key={row.id}>
              <div className={`${classes.row__content} ${row.closing_info?.length <= 1 && classes.margin}`}>
                {/* Кнопка для отображения доп. строк */}
                {(row.closing_info?.length > 1) && (
                  <div className={classes.row__content__button__wrapper}>
                    <div className={classes.row__content__button} onClick={() => toggleExpand(row.id)}>
                      <img
                        className={`${classes.row__content__button_img}`}
                        src={expandedRowId === row.id ? arrowClose : arrow}
                        alt="arrow"
                      />
                    </div>
                  </div>
                )}
                {/* Кнопка для отображения доп. строк */}

                {/* Строка */}
                <RegistryTableRow
                  key={row.id}
                  columns={columns}
                  row={row}
                  number={data.length}
                  tableName={tableName}
                  linkTo={TABLE_PARAMS[tableName].linkTo}
                  rowClassName={TABLE_PARAMS[tableName]?.rowClassName}
                  cellClassName={TABLE_PARAMS[tableName]?.cellClassName}
                  noDeadline={noDeadline}
                  projectAccounting={projectAccounting}
                  closingInfo={row?.closing_info[0]}
                  mainRow
                />
              </div>
              {/* Строка */}

              {/* Дополнительные строки (Разворачивающиеся) */}
              {(expandedRowId === row.id && row.closing_info?.length) && (row.closing_info?.map((info) => (
                <div className={classes.additional__row} key={info.id}>
                  <RegistryTableRow
                    key={info.id}
                    columns={columns}
                    row={row}
                    number={data.length}
                    tableName={tableName}
                    rowClassName={TABLE_PARAMS[tableName]?.rowClassName}
                    cellClassName={TABLE_PARAMS[tableName]?.cellClassName}
                    noDeadline={noDeadline}
                    closingInfo={info}
                    additionalRow
                  />
                </div>
              ))
              )}
              {/* Дополнительные строки (Разворачивающиеся) */}
            </div>
          ))}
          <div style={{
            display: 'flex', justifyContent: 'center', height: '50px', width: '51.5%',
          }}
          >
            {fetching && <Spinner size="md" animation="border" style={{ color: 'gray', display: 'flex', marginTop: '5px' }} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(RegistryTableProjectAccounting);
