/* eslint-disable no-nested-ternary */
// @ts-nocheck
import React from 'react';
import { Spinner } from 'react-bootstrap';

import NewAddBtn from 'components/UI/Btns/NewAddBtn';

import { TABLE_PARAMS } from './consts/consts';

import CancelBtn from '../Btns/CancelBtn';
import OneTableRow from '../OneTableRow/OneTableRow';

import HeadCell from './components/HeadCell';
// eslint-disable-next-line import/no-named-as-default
import TableRow from './components/TableRow';

import classes from './Table.module.scss';

function Table(props) {
  const {
    tableName,
    cellStyle,
    headerStyle,
    headCellStyle,
    style,
    staffCard,
    type,
    columns,
    data,
    updateFunc,
    deleteHandler,
    addHandler,
    addText,
    projectsList,
    responsibleList,
    bloggersList,
    fetchMyTasks,
    noCheckbox,
    teams,
    addChildren,
    updateChildren,
    deleteChildren,
    departamentList,
    news,
    tableRef,
    tableStyle,
    columnsOneTableRow,
    rowName,
    fetching,
  } = props;

  const mainClassName = [
    classes.row,
    classes.head,
    TABLE_PARAMS[tableName]?.className,
  ].join(' ');

  let right = 12;

  if (data?.length <= 10) right = 65;

  return (
    <div style={tableStyle}>
      <div className={mainClassName} style={headerStyle}>

        {!noCheckbox && (
        <div
          className={[classes.cell].join(' ')}
          style={headCellStyle}
        />
        )}

        {columns.map((c) => (
          <HeadCell {...c} key={c.id} headCellStyle={headCellStyle} />
        ))}
      </div>

      <div
        className={[
          classes.body,
        ].join(' ')}
        style={style}
        ref={tableRef && tableRef}
      >
        {data?.map((row) => (
          (row.subtasks?.length > 0 // ПОДЗАДАЧИ
            ? (
              <>
                <div
                  key={row.id}
                  className={[classes.row,
                    TABLE_PARAMS[tableName].className].join(' ')}
                  style={data.length <= 10 ? { position: 'static' } : {}}
                >
                  {/* РОДИТЕЛЬ */}
                  <TableRow
                    columns={columns}
                    row={row}
                    type={type}
                    updateFunc={updateFunc}
                    tableName={tableName}
                    staffCard={staffCard}
                    data={data}
                    projectsList={projectsList}
                    responsibleList={responsibleList}
                    bloggersList={bloggersList}
                    fetchMyTasks={fetchMyTasks}
                    noCheckbox={noCheckbox}
                    cellStyle={cellStyle}
                    teams={teams}
                    addChildren={addChildren}

                  />
                  {type !== 'expenseProjectPage' && deleteHandler && (
                  <CancelBtn
                    onClick={() => deleteHandler(row.id)}
                    style={{ position: 'absolute', right }}
                  />
                  )}
                  {/* РОДИТЕЛЬ */}
                </div>
                {row.subtasks.map((task) => (

                  <div
                    key={task.id}
                    className={[classes.row, TABLE_PARAMS[tableName].className].join(
                      ' ',
                    )}
                    style={data.length <= 10 ? { position: 'static' } : {}}
                  >
                    {/* РЕБЕНОК */}
                    <TableRow
                      columns={columns}
                      row={task}
                      type={type}
                      updateFunc={updateChildren}
                      tableName={tableName}
                      staffCard={staffCard}
                      data={data}
                      responsibleList={responsibleList}
                      fetchMyTasks={fetchMyTasks}
                      noCheckbox={noCheckbox}
                      cellStyle={cellStyle}
                      teams={teams}
                      parent={row}

                    />
                    {type !== 'expenseProjectPage' && deleteHandler && (
                    <CancelBtn
                      onClick={() => deleteChildren(row.id, task.id)}
                      style={{ position: 'absolute', right }}
                    />
                    )}
                    {/* РЕБЕНОК */}
                  </div>

                ))}

              </>
            )
            : (
              row?.publications?.length ? ( // ПОДПУБЛИКАЦИИ
                row.publications?.map((publication, index) => (
                  <>
                    <div
                      key={publication.id}
                      className={[classes.row, TABLE_PARAMS[tableName].className].join(
                        ' ',
                      )}
                      style={data.length <= 10 ? { position: 'static' } : {}}
                    >
                      <TableRow
                        columns={columns}
                        row={row}
                        publication={publication}
                        type={type}
                        updateFunc={updateFunc}
                        tableName={tableName}
                        staffCard={staffCard}
                        data={data}
                        responsibleList={responsibleList}
                        fetchMyTasks={fetchMyTasks}
                        noCheckbox={noCheckbox}
                        cellStyle={cellStyle}
                        teams={teams}
                        parent={row}
                        rowIndex={index}
                        addChildren={addChildren}
                        deleteChildren={deleteChildren}
                      />
                    </div>
                    {publication.subpublications?.map((subpublication) => (

                      <div
                        key={subpublication.id}
                        className={[classes.row, TABLE_PARAMS[tableName].className].join(
                          ' ',
                        )}
                        style={data.length <= 10 ? { position: 'static' } : {}}
                      >
                        {/* РЕБЕНОК */}
                        <TableRow
                          columns={columns}
                          row={row}
                          publication={publication}
                          subpublication={subpublication}
                          type="projectSubPublications"
                          updateFunc={updateChildren}
                          tableName={tableName}
                          staffCard={staffCard}
                          data={data}
                          responsibleList={responsibleList}
                          fetchMyTasks={fetchMyTasks}
                          noCheckbox={noCheckbox}
                          cellStyle={cellStyle}
                          teams={teams}
                          parent={row}
                          deleteChildren={deleteChildren}
                        />
                        {type !== 'expenseProjectPage' && deleteHandler && (
                        <CancelBtn
                          onClick={() => deleteChildren(row.id, subpublication.id)}
                          style={{ position: 'absolute', right }}
                        />
                        )}
                        {/* РЕБЕНОК */}
                      </div>

                    ))}
                  </>
                ))
              ) : ( // ВСЕ ОСТАЛЬНЫЕ СЛУЧАИ
                <div
                  key={row.id}
                  className={[classes.row, TABLE_PARAMS[tableName].className].join(' ')}
                  style={
    data.length <= 10 ? (
      row?.payment_dates?.length > 0 || row?.payments_data?.length > 0
        ? {
          position: 'static',
          height: `${row.payments_data.reduce((acc, payment) => acc + 38 + (payment.payment_dates ? 24 * payment.payment_dates.length : 0), row?.payment_dates?.length ? 38 * row.payment_dates.length : 0)}px`,
        }
        : { position: 'static' }
    ) : {}
  }
                >

                  {/* ОБЫЧНЫЙ РОДИТЕЛЬ БЕЗ ДЕТЕЙ */}
                  <TableRow
                    columns={columns}
                    row={row}
                    type={type}
                    updateFunc={updateFunc}
                    tableName={tableName}
                    staffCard={staffCard}
                    data={data}
                    projectsList={projectsList}
                    departamentList={departamentList}
                    responsibleList={responsibleList}
                    bloggersList={bloggersList}
                    fetchMyTasks={fetchMyTasks}
                    noCheckbox={noCheckbox}
                    cellStyle={cellStyle}
                    addChildren={addChildren}
                    teams={teams}
                    news={news}
                    reportTask
                  />
                  {type !== 'expenseProjectPage' && deleteHandler && (
                  <CancelBtn
                    onClick={() => deleteHandler(row.id)}
                    style={{ position: 'absolute', right }}
                  />
                  )}

                  {type === 'expenseProjectPage' && row.type.id === 2 && (
                  <CancelBtn
                    onClick={() => deleteHandler(row.id)}
                    style={{ position: 'absolute', right }}
                  />
                  )}
                  {/* ОБЫЧНЫЙ РОДИТЕЛЬ БЕЗ ДЕТЕЙ */}
                </div>
              )))

        ))}
        {fetching
          && (
          <div className={classes.spinner__container}>
            {fetching && <Spinner size="md" animation="border" className={classes.spinner} />}
          </div>
          )}
        {/* <div style={{ display: 'flex', justifyContent: 'center', height: '50px' }}>
          {fetching && <Spinner size="md" animation="border" style={{ color: 'gray', display: 'flex', marginTop: '10px' }} />}
        </div> */}
      </div>

      {(type === 'projectEstimate' || type === 'projectMargin') && ( // СТРОКА ИТОГО
        <OneTableRow
          rowName={rowName}
          columns={columnsOneTableRow}
          style={{ marginTop: 0, paddingRight: 0 }}
          rowStyle={{ paddingRight: 8 }}
        />
      )}

      {addHandler && (
        <div className={[classes.row, classes.buttonRow].join(' ')}>
          <NewAddBtn
            onClick={() => addHandler()}
            text={addText || 'Добавить задачу'}
          />
        </div>
      )}
    </div>
  );
}

export default Table;
