import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';

import classes from '../UI/MyToast/MyToast.module.css';

function ReactNotification({ notification, deleteNotification }) {
  const [show, setShow] = useState(true);

  const close = () => {
    deleteNotification(notification.messageId);
    setShow(false);
  };

  return (
    <Toast onClose={close} show={show}>
      <Toast.Header>
        <strong className="me-auto">{notification.notification.title}</strong>

        <div className={classes.done} />
      </Toast.Header>
      <Toast.Body>{notification.notification.body}</Toast.Body>
    </Toast>
  );
}
export default ReactNotification;
