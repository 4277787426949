// @ts-nocheck
import React, { useContext, useState } from 'react';
import { Context } from 'index';

import { checkInn } from 'API/LegalEntityAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import Files from 'components/UI/Files/Files';
import InputElement from 'components/UI/InputElements/InputElement';
import MyInputFile from 'components/UI/MyInput/MyInputFile';

import { LEGAL_TYPES } from 'utils/consts';
import { isValidURL } from 'utils/isValidUrl';

import ContractElement from '../../ModalCreateContract/components/ContractElement';

import classes from '../ModalCreateEntity.module.scss';

function MainInfo(props) {
  const {
    state,
    setState,
    files,
    deleteFileHandler,
    addFileHandler,
    save,
    cancel,
    style,
  } = props;
  const { modal } = useContext(Context);

  const [activeBtn, setActiveBtn] = useState(false);

  const tooltipText = `<b>Для ИП:</b> ИНН, ОГРНИП, адрес регистрации и банковские реквизиты<br>
  <b>Для СЗ:</b> ИНН, паспортные данные (серия номер паспорта, кем выдан, когда выдан, код подразделения), адрес регистрации и банковские реквизиты<br>
  <b>Для ООО:</b> ИНН, ОГРН, КПП, юридический адрес и банковские реквизиты`;
  const tooltipTextPasport = 'Серия номер паспорта, Кем выдан, Когда выдан, Код подразделения';

  const saveHandler = () => {
    setActiveBtn(false);
    save();
  };

  const cancelHandler = () => {
    setActiveBtn(false);
    cancel();
  };

  const openModalDeleteFile = (name, id) => {
    if (save) {
      setActiveBtn(false);
      modal.setModalConfirmVisible(true);
      modal.setModalConfirmProps({
        text: `Вы хотите удалить файл ${name}?`,
        click: () => deleteFileHandler(id),
      });
    } else {
      deleteFileHandler(id);
    }
  };

  const innDebounceOnCnange = (inn) => {
    setState(
      { ...state, inn },
    );
    if (inn.length >= 10) {
      checkInn(inn).then((data) => {
        if (data?.length >= 1) {
          const tempName = data[0]?.value.split(' ')[0];

          let tempType = { id: 8, name: '-' };

          if (tempName === 'СЗ') {
            tempType = { id: 0, name: 'СЗ' };
          } else if (tempName === 'ИП') {
            tempType = { id: 1, name: 'ИП' };
          } else if (tempName === 'ООО') {
            tempType = { id: 2, name: 'ООО' };
          } else if (tempName === 'АО') {
            tempType = { id: 3, name: 'АО' };
          } else if (tempName === 'ОАО') {
            tempType = { id: 4, name: 'ОАО' };
          } else if (tempName === 'СПАО') {
            tempType = { id: 5, name: 'СПАО' };
          } else if (tempName === 'ПАО') {
            tempType = { id: 6, name: 'ПАО' };
          } else if (tempName === 'АНО') {
            tempType = { id: 7, name: 'АНО' };
          } else if (tempName === 'ТОО') {
            tempType = { id: 9, name: 'ТОО' };
          }

          setState({
            ...state,
            legal_name: data[0].value,
            inn: data[0].data.inn,
            type: tempType,
            ogrn: data[0]?.data?.ogrn ? data[0].data.ogrn : state.ogrn,
            ogrnip: data[0]?.data?.ogrn ? data[0].data.ogrn : state.ogrnip,
            address: data[0]?.data?.address?.unrestricted_value ? data[0]?.data?.address?.unrestricted_value : state.address,
            kpp: data[0]?.data?.kpp ? data[0].data.kpp : state.kpp,
          });
        }
      });
    } else {
      setState(
        { ...state, inn },
      );
    }
  };

  return (
    <div style={{ width: '100%' }}>

      <InputElement
        text="Юридическое лицо"
        textStyle={{ minWidth: 230 }}
        value={state?.legal_name}
        onChange={(event) => setState({ ...state, legal_name: event.target.value })}
        onClick={() => setActiveBtn(true)}
        style={style}
      />

      <InputElement
        text="Форма ЮЛ"
        textStyle={{ minWidth: 230 }}
        value={state?.type}
        setValue={(type) => setState({ ...state, type })}
        dropdown={LEGAL_TYPES.LIST}
        onClick={() => setActiveBtn(true)}
        style={style}
      />

      {state.type.id === 0 && (
        <>
          <InputElement
            text="ИНН"
            textStyle={{ minWidth: 230 }}
            value={state?.inn}
            onChange={(event) => innDebounceOnCnange(event.target.value)}
            onClick={() => setActiveBtn(true)}
            style={style}
          />

          <InputElement
            text="Паспорт"
            textStyle={{ minWidth: 230 }}
            value={state?.passport_details}
            onChange={(event) => setState({ ...state, passport_details: event.target.value })}
            onClick={() => setActiveBtn(true)}
            style={style}
            tooltipText={tooltipTextPasport}
          />

          <InputElement
            text="Адрес регистрации"
            textStyle={{ minWidth: 230 }}
            value={state?.address}
            onChange={(event) => setState({ ...state, address: event.target.value })}
            onClick={() => setActiveBtn(true)}
            style={style}
          />
        </>
      )}

      {state.type.id === 1 && (
      <>
        <InputElement
          text="ИНН"
          textStyle={{ minWidth: 230 }}
          value={state?.inn}
          onChange={(event) => innDebounceOnCnange(event.target.value)}
          onClick={() => setActiveBtn(true)}
          style={style}
        />

        <InputElement
          text="ОГРНИП"
          textStyle={{ minWidth: 230 }}
          value={state?.ogrnip}
          onChange={(event) => setState({ ...state, ogrnip: event.target.value })}
          onClick={() => setActiveBtn(true)}
          style={style}
        />

        <InputElement
          text="Юридический адрес"
          textStyle={{ minWidth: 230 }}
          value={state?.address}
          onChange={(event) => setState({ ...state, address: event.target.value })}
          onClick={() => setActiveBtn(true)}
          style={style}
        />
      </>
      )}

      {state.type.id !== 0 && state.type.id !== 1 && (
      <>
        <InputElement
          text="ИНН"
          textStyle={{ minWidth: 230 }}
          value={state?.inn}
          onChange={(event) => innDebounceOnCnange(event.target.value)}
          onClick={() => setActiveBtn(true)}
          style={style}
        />

        <InputElement
          text="ОГРН"
          textStyle={{ minWidth: 230 }}
          value={state?.ogrn}
          onChange={(event) => setState({ ...state, ogrn: event.target.value })}
          onClick={() => setActiveBtn(true)}
          style={style}
        />

        <InputElement
          text="КПП"
          textStyle={{ minWidth: 230 }}
          value={state?.kpp}
          onChange={(event) => setState({ ...state, kpp: event.target.value })}
          onClick={() => setActiveBtn(true)}
          style={style}
        />

        <InputElement
          text="Юридический адрес"
          textStyle={{ minWidth: 230 }}
          value={state?.address}
          onChange={(event) => setState({ ...state, address: event.target.value })}
          onClick={() => setActiveBtn(true)}
          style={style}
        />
      </>
      )}

      <InputElement
        text="Ссылка на документы"
        textStyle={{ minWidth: 230 }}
        value={state?.file_links}
        onChange={(event) => {
          setState({ ...state, file_links: event.target.value });
          setActiveBtn(true);
        }}
        onClick={() => setActiveBtn(true)}
        style={style}
        tooltipText={tooltipText}
        tooltipPosition="top"
        isLink={isValidURL(state?.file_links)}
      />

      {addFileHandler && (
        <ContractElement textStyle={{ minWidth: 230 }}>
          <div
            className={classes.files__container}
            style={files?.length > 0 ? { marginTop: 25 } : {}}
          >
            {files?.length > 0 && (
              <div className={classes.files}>
                {files.map((file, index) => (
                  <Files
                    key={file.id || index}
                    file={file}
                    deleteFileHandler={() => openModalDeleteFile(file.name, file.id || index)}
                  />
                ))}
              </div>
            )}

            <div>
              <MyInputFile
                onChange={(event) => addFileHandler(event)}
                multiple
                accept=".ppt, .pptx, .xls, .xlsx, .doc, .docx, .jpeg, .jpg, .png, .pdf, .key, .heif"
                htmlFor="BloggerFiles"
              />
            </div>
          </div>
        </ContractElement>
      )}

      {save && activeBtn && (
        <div className={classes.btnSave__wrapper}>
          <SuccessBtn
            text="Сохранить"
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={() => saveHandler()}
          />

          <SuccessBtn
            text="Отменить"
            type="white"
            style={{
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={() => cancelHandler()}
          />
        </div>
      )}
    </div>
  );
}

export default MainInfo;
