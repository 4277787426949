import qs from 'qs';

import { $authHost, $inn } from 'API';

export const deleteClientLegalEntity = async (id) => {
  await $authHost.delete(`/client_page/legal_entity/${id}/`);
};

export const postClientLegalEntity = async (dataLegalEntity) => {
  const { data } = await $authHost.post(
    '/client_page/legal_entity/',
    dataLegalEntity,
  );
  return data;
};

export const patchClientLegalEntity = async (id, data) => {
  await $authHost.patch(`/client_page/legal_entity/${id}/`, data);
};

export const deleteBloggerLegalEntity = async (id) => {
  await $authHost.delete(`/blogger_page/legal_entity/${id}/`);
};

export const postBloggerLegalEntity = async (dataLegalEntity) => {
  const { data } = await $authHost.post(
    '/blogger_page/legal_entity/',
    dataLegalEntity,
  );
  return data;
};

export const patchBloggerLegalEntity = async (id, data) => {
  await $authHost.patch(`/blogger_page/legal_entity/${id}/`, data);
};

export const checkInn = async (inn) => {
  const { data } = await $inn.post('/api/4_1/rs/findById/party/', {
    query: inn,
  });

  return data.suggestions;
};

export const postLegalEntity = async (dataLegalEntity) => {
  const { data } = await $authHost.post(
    '/legal_entity_page/legal_entity/',
    dataLegalEntity,
  );
  return data;
};

export const getLegalEntities = async (filter) => {
  const params = {};

  if (filter) {
    if (filter.filter) {
      params.legal_name = filter.filter;
    }
  }
  const { data } = await $authHost.get(
    '/legal_entity_page/legal_entity/',
    {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  );
  return data;
};

export const fetchOneLegalEntities = async (id) => {
  const { data } = await $authHost.get(
    `/legal_entity_page/legal_entity/${id}`,
  );
  return data;
};

export const patchOneEntity = async (id, sendData) => {
  await $authHost.put(`/legal_entity_page/legal_entity/${id}/`, sendData);
};
