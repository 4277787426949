// @ts-nocheck
/* eslint-disable no-cond-assign */
import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { setAsSeenNotification } from 'API/LawyersAPI/NotificationAPI';

import { getDate, getTime, reversDate } from 'utils/getDate';

import CheckBox from '../MyCheckbox/CheckBox';

import classes from './NotificationContainer.module.scss';

const NotificationContainer = observer((props) => {
  const {
    notification, isNew, noLink, selectedSetting, selectedNotifications, handleNotificationCheck,
  } = props;

  const { notifications } = useContext(Context);

  const isSelected = selectedNotifications?.has(notification.id);
  const isEditing = selectedSetting === 'Выбрать' || selectedSetting === 'Выбрать все';
  const date = useMemo(() => reversDate(getDate(notification.date_created)), [notification.date_created]);
  const time = useMemo(() => getTime(notification.date_created), [notification.date_created]);
  const path = useMemo(() => {
    let modelPath = '';
    switch (notification.content_type?.model) {
      case 'basecontract':
        modelPath = 'contract';
        break;
      case 'project':
        modelPath = 'project';
        break;
      case 'basepayment':
        modelPath = 'cost';
        break;
      default:
        break;
    }
    return `/${modelPath}/${notification.object_id}`;
  }, [notification.content_type, notification.object_id]);

  const className = useMemo(() => [classes.notification__container, isNew && classes.unreaded, isSelected && classes.isSelected].join(' '), [isNew, isSelected]);
  const linkClassName = useMemo(() => [classes.links, classes.disabled, isSelected && classes.isSelected].join(' '), [isSelected]);

  const setAsSeenHandler = () => {
    notifications.markOneNotificationAsRead(notification.id);
    setAsSeenNotification(notification.id);
  };

  const NotificationElement = (
    <>
      <span className={classes.notification__container__date}>
        {date}&ensp;{time}
      </span>
      <div className={classes.notification__container__info}>
        <span
          className={classes.tag}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: notification.comment }}
        />
      </div>
    </>
  );

  return (
    <div>
      <div className={`${classes.notification__wrapper} ${isEditing && classes.editing}`}>
        {isEditing && (
          <div className={classes.notification__wrapper__checkbox}>
            <CheckBox
              notification
              checked={isSelected}
              onChange={(e) => handleNotificationCheck(notification.id, e.target.checked)}
            />
          </div>
        )}
        {noLink ? (
          <div className={classes.notification__wrapper__element}>
            {NotificationElement}
          </div>
        ) : (
          <Link className={linkClassName} to={path} onClick={setAsSeenHandler}>
            <div className={className}>
              {NotificationElement}
            </div>
          </Link>
        )}
      </div>
    </div>
  );
});

export default NotificationContainer;
