// @ts-nocheck
import { makeAutoObservable } from 'mobx';

// Стор для проектов блогеров/клиентов
export default class MainMarketingStore {
  constructor() {
    this._projects = [];
    this._projectsTotalCount = 0;
    this._projectsPage = 1;
    this._projectsLimit = 15;
    this._projectsFilter = undefined;
    this._projectsTotalSum = 0;

    makeAutoObservable(this);
  }

  setProjects(projects, update) {
    if (update) {
      return (this._projects = projects);
    }

    if (this._projectsPage === 1) {
      return (this._projects = projects);
    }

    projects.forEach((project) => this._projects.push(project));
  }

  setProjectsTotalCount(count) {
    this._projectsTotalCount = count;
  }

  setProjectsPage(page) {
    this._projectsPage = page;
  }

  setProjectsFilter(filter) {
    this._projectsFilter = filter;
  }

  setProjectsTotalSum(sum) {
    if (this._projectsPage === 1) {
      this._projectsTotalSum = sum;
    }
  }

  get projects() {
    return this._projects;
  }

  get projectsTotalCount() {
    return this._projectsTotalCount;
  }

  get projectsPage() {
    return this._projectsPage;
  }

  get projectsLimit() {
    return this._projectsLimit;
  }

  get projectsFilter() {
    return this._projectsFilter;
  }

  get projectsTotalSum() {
    return this._projectsTotalSum;
  }
}
