import React from 'react';

import MySwitch from 'components/UI/MySwitch/MySwitch';

import NotificationListElement from './NotificationListElement';

import classes from '../SettingsPage.module.css';

function NotificationList(props) {
  const {
    text, type, fetchUser, id, name,
  } = props;

  return (
    <div>
      <span className={classes.notification__name}>{text}</span>
      <MySwitch
        fetchUser={fetchUser}
        id={id}
        status={type.status}
        name={name}
      />

      <div className={classes.notification__list}>
        <NotificationListElement
          text="Назначение на задачи"
          status={type.assignmentTasks}
          name={name}
          type="assignmentTasks"
          fetchUser={fetchUser}
          id={id}
        />
        <NotificationListElement
          text="Завершение задач, в которых вы принимаете участие"
          status={type.completingTasks}
          name={name}
          type="completingTasks"
          fetchUser={fetchUser}
          id={id}
        />

        <NotificationListElement
          text="Истечение срока выполнения задачи"
          status={type.expirationTasks}
          name={name}
          type="expirationTasks"
          fetchUser={fetchUser}
          id={id}
        />
      </div>
    </div>
  );
}

export default NotificationList;
