// @ts-nocheck
import React from 'react';

import classes from '../ModalCreateContract.module.css';

const ContractElement = React.memo((props) => {
  const {
    text, children, textStyle, style, textClassName, elementClassName, disabled, blur, onMouseEnter, onMouseLeave,
  } = props;
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={[classes.contract__element, elementClassName, disabled && blur && classes.disabled].join(' ')}
      style={style || { alignItems: 'start' }}
    >
      <div
        className={[classes.element__text, textClassName].join(' ')}
        style={textStyle}
      >
        {text}
      </div>

      {children}
    </div>
  );
});

export default ContractElement;
