// @ts-nocheck
import React, { forwardRef } from 'react';
import { TextareaAutosize } from '@mui/material';

import classes from './MyTextArea.module.css';

const TextAreaAutoSize = forwardRef((props, ref) => {
  const {
    value,
    setValue,
    onChange,
    onClick,
    placeholder,
    style,
    disabled,
    className,
    onKeyDown,
    minRows,
    text,
    textStyle,
    containerStyle,
  } = props;

  const changeHandler = (event) => {
    if (setValue) setValue(event.target.value);
  };

  return (
    <div className={classes.textArea__container} style={containerStyle}>
      {text && (
        <div
          className={[classes.element__text].join(' ')}
          style={textStyle}
        >
          {text}
        </div>
      )}
      <TextareaAutosize
        minRows={minRows}
        ref={ref}
        className={[classes.new__textarea, className].join(' ')}
        style={style}
        value={value}
        onChange={onChange || changeHandler}
        onClick={onClick}
        placeholder={placeholder}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
    </div>
  );
});

export default TextAreaAutoSize;
