import React, {
  memo, useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { Dropdown } from 'react-bootstrap';
import { Context } from 'index';

import classes from './MyImageDropdown.module.scss';

function MyImageDropdown(props) {
  const {
    image, list, selected, setSelected, setAllAsSeenHandler, setSelectedNotifications, fetch,
  } = props;
  const { notifications } = useContext(Context);
  const rootEl = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const controller = new AbortController();

  // const handleSelect = (e, quarter) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };

  useEffect(() => {
    const onClick = (e) => {
      if (rootEl.current && !rootEl.current.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  const toggleDropdown = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsActive(!isActive);
  }, [isActive]);

  const updateSelected = (value) => {
    if (selected === value.name) {
      setSelected('');
      setSelectedNotifications(new Set());
      setIsActive(!isActive);
      return;
    }
    if (value.name === 'Выбрать все') {
      setSelected(value.name);
      const allIds = notifications.notifications.map((notification) => notification.id);
      setSelectedNotifications(new Set(allIds));
      setIsActive(!isActive);
      return;
    }

    if (value.name === 'Показать только прочитанные') {
      notifications.setNotificationsFilter({ is_new: false });
      notifications.setNotificationsPage(1);
      fetch(
        notifications.notificationsPage,
        notifications.notificationsLimit,
        controller.signal,
        notifications.notificationsFilter,
      );
      setSelectedNotifications(new Set());
    }

    if (value.name === 'Показать только непрочитанные') {
      notifications.setNotificationsFilter({ is_new: true });
      notifications.setNotificationsPage(1);
      fetch(
        notifications.notificationsPage,
        notifications.notificationsLimit,
        controller.signal,
        notifications.notificationsFilter,
      );
      setSelectedNotifications(new Set());
    }

    if (value.name === 'Показать все уведомления') {
      notifications.setNotificationsFilter({});
      notifications.setNotificationsPage(1);
      fetch(
        notifications.notificationsPage,
        notifications.notificationsLimit,
        controller.signal,
        notifications.notificationsFilter,
      );
      setSelectedNotifications(new Set());
    }
    setSelected(value.name);
    setIsActive(!isActive);
  };

  return (
    <div ref={rootEl}>
      <button
        type="button"
        className={`${classes.quarter}`}
        onClick={toggleDropdown}
      >
        <img alt="icon" src={image} />
      </button>

      <Dropdown.Menu
        className={['my__dropdown__inner__alt', isActive && classes.newDropdown].join(' ')}
        show={isActive}
        align="start"
      >
        {list && list.map((listElement) => (
          <Dropdown.Item
            key={listElement.id}
            onClick={() => updateSelected(listElement)}
            className="my__dropdown__item"
          >
            {listElement.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </div>
  );
}

export default memo(MyImageDropdown);
