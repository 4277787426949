// @ts-nocheck

import React, {
  memo,
  useContext, useEffect, useState,
} from 'react';
import { Context } from 'index';

import { updateStaff } from 'API/StaffAPI';
import { check } from 'API/UserAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import InputElement from 'components/UI/InputElements/InputElement';
import UserStatus from 'components/UI/UserStatuses/UserStatusDropdown/UserStatusDropdown';

import { useLogout } from 'hooks/useLogout';

import { getDate } from 'utils/getDate';

import { FIELDS } from '../consts';

import classes from '../EmployeePage.module.scss';

function CardInfo(props) {
  const {
    staffInfo, fetchCard, departments, teams, roles, setStaffInfo,
  } = props;
  const [logout] = useLogout();

  const { user, toast } = useContext(Context);

  const [fullname, setFullname] = useState('');
  const [mail, setMail] = useState('');
  const [info, setInfo] = useState({
    address: '',
    birthday: '',
    citizenship: '',
    comment: '',
    contract_type: '',
    onboarding_date: '',
    telegram: '',
    phone: '',
    salary: '',
    post: '',
  });
  const [departament, setDepartament] = useState({
    id: null,
    name: '',
    jobs: [],
  });
  const [team, setTeam] = useState({ id: null, name: '' });
  const [role, setRole] = useState({ id: null, name: '' });

  const [post, setPost] = useState([]);
  const [selectedPost, setSelectedPost] = useState({
    id: null,
    name: undefined,
  });

  const [activeBtn, setActiveBtn] = useState(false);

  useEffect(() => {
    setPost(departament.jobs);
  }, [departament]);

  const updateInfoHandler = (fieldName, value) => {
    setInfo({ ...info, [fieldName]: value });
  };

  const clickHandler = () => {
    setActiveBtn(true);
  };

  const cancelHandler = () => {
    setActiveBtn(false);
    setFullname(staffInfo.fullname);
    setMail(staffInfo.email);
    setInfo(staffInfo.info);
    setDepartament(staffInfo.department);
    setRole(staffInfo.role);
    setSelectedPost(staffInfo.job);

    if (staffInfo.team) {
      setTeam(staffInfo.team);
    }
  };

  const saveHandler = () => {
    const name = fullname.replace(/ +/g, ' ').trim().split(' ')[0];
    const surname = fullname.replace(/ +/g, ' ').trim().split(' ')[1];

    if (!name) {
      toast.setToastInfo('Введите имя');
      toast.setShowError(true);
      return;
    }

    if (!surname) {
      toast.setToastInfo('Введите фамилию');
      toast.setShowError(true);
      return;
    }

    setActiveBtn(false);

    const data = {
      name,
      surname,
      info: {
        ...info,
        birthday: info.birthday ? getDate(info.birthday) : '',
        onboarding_date: info.onboarding_date ? getDate(info.onboarding_date) : '',
      },
      job: { id: selectedPost.id },
      status: { id: staffInfo?.status?.id, name: staffInfo?.status?.name },
    };

    check()
      .then(() => {
        updateStaff(staffInfo.id, data).then(() => {
          fetchCard(staffInfo.id);
          toast.setToastInfo('Данные сохранены');
          toast.setShowSuccess(true);
        });
      })
      .catch((error) => logout(error));
  };

  useEffect(() => {
    setFullname(staffInfo.fullname);
    setMail(staffInfo.email);
    setInfo(staffInfo.info);
    setDepartament(staffInfo.department);
    setRole(staffInfo.role);
    setSelectedPost(staffInfo.job);

    if (staffInfo.team) {
      setTeam(staffInfo.team);
    }
  }, [staffInfo]);

  return (
    <>
      <UserStatus
        fetchCard={fetchCard}
        staffInfo={staffInfo}
        setStaffInfo={setStaffInfo}
        clickHandler={clickHandler}
        setActiveBtn={setActiveBtn}
      />
      <div className={classes.info__wrapper}>
        <InputElement
          text="ФИО:"
          textStyle={{ minWidth: 200 }}
          inputStyle={{ fontSize: 14 }}
          value={fullname}
          setValue={setFullname}
          onClick={clickHandler}
          placeholder="Иванов Иван"
        />

        <InputElement
          text="Дата рождения:"
          type="date"
          textStyle={{ minWidth: 200 }}
          inputStyle={{ fontSize: 14, width: '100%' }}
          value={info.birthday}
          onChange={(event) => updateInfoHandler('birthday', event)}
          onClick={clickHandler}
          date
        />

        <InputElement
          text="Отдел:"
          value={departament}
          setValue={setDepartament}
          textStyle={{ minWidth: 200 }}
          inputStyle={{ fontSize: 14 }}
          dropdown={departments}
          onClick={clickHandler}
          disabled
        />
        {user.user.role.id === 0 || user.user.role.id === 1
          ? (
            <InputElement
              text="Уровень доступа:"
              value={role}
              setValue={setRole}
              textStyle={{ minWidth: 200 }}
              inputStyle={{ fontSize: 14 }}
              dropdown={roles}
              onClick={clickHandler}
              disabled
            />
          ) : (
            <InputElement
              text="Уровень доступа:"
              value={role}
              setValue={setRole}
              textStyle={{ minWidth: 200 }}
              inputStyle={{ fontSize: 14 }}
              dropdown={roles}
              onClick={clickHandler}
              disabled
            />
          )}
        {departament.id === 0 && role.id === 2 && (
          <InputElement
            text="Команда:"
            textStyle={{ minWidth: 200 }}
            inputStyle={{ fontSize: 14 }}
            value={team}
            setValue={setTeam}
            dropdown={teams}
            disabled
          />
        )}

        <InputElement
          text="Почта (рабочая):"
          textStyle={{ minWidth: 200 }}
          inputStyle={{ fontSize: 14 }}
          value={mail}
          setValue={setMail}
          onClick={clickHandler}
          disabled
        />

        {post?.length > 1 && (
          <InputElement
            text="Направление:"
            textStyle={{ minWidth: 200 }}
            inputStyle={{ fontSize: 14 }}
            value={selectedPost}
            setValue={setSelectedPost}
            dropdown={post}
            onClick={clickHandler}
          />
        )}

        <InputElement
          text="Должность:"
          textStyle={{ minWidth: 200 }}
          inputStyle={{ fontSize: 14 }}
          value={info.post}
          onChange={(event) => updateInfoHandler('post', event.target.value)}
          onClick={clickHandler}
        />

        <InputElement
          type="date"
          text="Дата приема на работу:"
          textStyle={{ minWidth: 200 }}
          inputStyle={{ fontSize: 14, width: '100%' }}
          value={info.onboarding_date}
          onChange={(event) => updateInfoHandler('onboarding_date', event)}
          date
          onClick={clickHandler}
        />
        <InputElement
          text="Оформление:"
          textStyle={{ minWidth: 200 }}
          value={info.contract_type}
          onChange={(event) => updateInfoHandler('contract_type', event.target.value)}
          onClick={clickHandler}
        />

        {FIELDS.map((field) => (
          <InputElement
            key={field.id}
            text={field.label}
            textStyle={{ minWidth: 200 }}
            inputStyle={{ fontSize: 14 }}
            type={field.type}
            value={info[field.id]}
            onChange={(event) => updateInfoHandler(field.id, field.date
              ? event
              : event.target.value)}
            date={field.date}
            onClick={clickHandler}
          />
        ))}

        {activeBtn && (
          <div className={classes.btn__wrapper}>
            <SuccessBtn
              text="Сохранить"
              style={{
                padding: '2px 20px',
                fontSize: 13,
              }}
              onClick={() => saveHandler()}
            />

            <SuccessBtn
              text="Отменить"
              type="white"
              style={{
                padding: '2px 20px',
                fontSize: 13,
              }}
              onClick={() => cancelHandler()}
            />
          </div>
        )}
      </div>
    </>
  );
}
export default memo(CardInfo);
