import React, { memo } from 'react';

import NewMyCheckbox from 'components/UI/MyCheckbox/NewMyCheckbox';

import classes from './CheckboxItem.module.css';

function CheckboxItem(props) {
  const {
    text,
    status,
    setStatus,
    linkStatus,
    setLinkStatus,
    linkStatus2,
    setLinkStatus2,
    style,
    onClick,
    className,
    disabled,
  } = props;

  const changeHandler = () => {
    setStatus(!status);
    if (linkStatus) {
      setLinkStatus(!linkStatus);
    }
    if (linkStatus2) {
      setLinkStatus2(!linkStatus2);
    }
    if (onClick) {
      onClick();
    }
  };

  const classNames = [classes.checkbox__container, className].join(' ');

  return (
    <div
      className={classNames}
      style={style}
    >
      <NewMyCheckbox
        style={{ margin: '3px 4px' }}
        status={status}
        onChange={changeHandler}
        disabled={disabled}
      />
      {text}
    </div>
  );
}

export default memo(CheckboxItem);
