import React from 'react';

import { STATE_STATUSES } from 'utils/consts';

import SuccessBtn from '../Btns/SuccessBtn';
import InputElement from '../InputElements/InputElement';
import MultipleBtn from '../MultipleBtn/MultipleBtn';
import CheckBox from '../MyCheckbox/CheckBox';
import SearchBar from '../SearchBar/SearchBar';

import classes from './ProjectsFilterPanel.module.scss';

function ProjectsFilterPanel(props) {
  const {
    values,
    onUpdateField,
    onApplyFilters,
    staffList,
    secondStaffList,
    manager,
    changeManager,
    selectedManagerList,
    addManager,
    deleteManager,
    responsible,
    changeResponsible,
    selectedResponsibleList,
    addResponsible,
    deleteResponsible,
    department,
  } = props;

  return (
    <div className={classes.wrapper}>
      <form
        className={classes.form}
        onSubmit={(event) => onApplyFilters(event)}
      >
        <div className={classes.columns}>
          <div className={classes.columns__first}>
            <fieldset className={classes.fieldset}>
              <span>Проект</span>
              <InputElement
                value={values.name}
                onChange={(event) => onUpdateField({
                  ...values,
                  name: event.target.value,
                })}
                style={{ width: '100%' }}
              />
            </fieldset>

            <fieldset className={classes.fieldset}>
              <span>Менеджер</span>

              <SearchBar
                text={manager[0]}
                setText={changeManager}
                list={staffList}
                type="staff"
                multiple={addManager}
                style={{ position: 'static', width: '100%' }}
              />

              <div className={classes.selected}>
                {selectedManagerList.map((element) => (
                  <MultipleBtn
                    key={element.id}
                    element={element}
                    deleteEl={deleteManager}
                  />
                ))}
              </div>
            </fieldset>

            <div className={classes.date__container}>
              <fieldset className={classes.fieldset}>
                <span>Период реализации</span>
                <div className={classes.date}>
                  c
                  <InputElement
                    value={values.date_after}
                    onChange={(event) => onUpdateField({
                      ...values,
                      date_after: event,
                    })}
                    date
                    style={{ width: '100%' }}
                  />
                </div>

                <div className={classes.date}>
                  по
                  <InputElement
                    value={values.date_before}
                    onChange={(event) => onUpdateField({
                      ...values,
                      date_before: event,
                    })}
                    date
                    style={{ width: '100%' }}
                  />
                </div>
              </fieldset>
            </div>
          </div>

          <fieldset className={classes.fieldset}>

            <span>Статус</span>

            {STATE_STATUSES.LIST.map((status) => (
              <CheckBox
                name={status.id}
                key={status.id}
                value={status.id}
                label={status.label}
                onChange={() => {
                  if (values?.status?.includes(status.id)) {
                    const index = values?.status?.findIndex(
                      (item) => item === status.id,
                    );
                    values?.status?.splice(index, 1);
                  } else {
                    values?.status?.push(status.id);
                  }
                  onUpdateField({ status: values?.status });
                }}
                checked={values?.status?.includes(status.id)}
                transparentBg
              />
            ))}

            {(department === 4 || department === 5 || department === 6) && (
              <>
                <span>Ответственный</span>

                <SearchBar
                  text={responsible[0]}
                  setText={changeResponsible}
                  list={secondStaffList}
                  type="staff"
                  multiple={addResponsible}
                  style={{ position: 'static', width: '100%' }}
                />

                <div className={classes.selected}>
                  {selectedResponsibleList.map((element) => (
                    <MultipleBtn
                      key={element.id}
                      element={element}
                      deleteEl={deleteResponsible}
                    />
                  ))}
                </div>
              </>
            )}
            <div style={{ marginTop: 21 }}>
              <fieldset className={classes.fieldset}>
                <span>Дата оплаты</span>
                <div className={classes.date}>
                  c
                  <InputElement
                    value={values.payment_dates_after}
                    onChange={(event) => onUpdateField({
                      ...values,
                      payment_dates_after: event,
                    })}
                    date
                    style={{ width: '100%' }}
                  />
                </div>

                <div className={classes.date}>
                  по
                  <InputElement
                    value={values.payment_dates_before}
                    onChange={(event) => onUpdateField({
                      ...values,
                      payment_dates_before: event,
                    })}
                    date
                    style={{ width: '100%' }}
                  />
                </div>
              </fieldset>
            </div>
          </fieldset>
        </div>

        <div className={classes.applyFiltersButtonWrapper}>
          <SuccessBtn
            type="submit"
            text="Применить фильтр"
            style={{ padding: '3px 10px', fontSize: '12px' }}
          />
        </div>
      </form>
    </div>
  );
}

export default ProjectsFilterPanel;
