import React from 'react';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';

import 'react-datepicker/dist/react-datepicker.css';
import classes from './MyDateTimeInput.module.scss';

function MyDateTimeInput(props) {
  const {
    value, setValue, className, onChange, style, onClick, disabled,
  } = props;

  return (
    <div style={style} onClick={onClick}>
      <DatePicker
        selected={value && new Date(value)}
        onChange={onChange || ((date) => setValue(date))}
        className={[classes.myDate, className].join(' ')}
        locale={ru}
        dateFormat="Pp"
        timeFormat="p"
        showTimeSelect
        timeIntervals={10}
        disabled={disabled}
      />
    </div>
  );
}

export default MyDateTimeInput;
