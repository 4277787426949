// @ts-nocheck

import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { check } from 'API/UserAPI';
import { exportGoogleReportProjects } from '../../../../API/ManagerAPI/ProjectAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import SecondDoubleDate from 'components/UI/DoubleDate/SecondDoubleDate';

import InputElement from '../../../UI/InputElements/InputElement';
import MultipleBtn from '../../../UI/MultipleBtn/MultipleBtn';

import { useMultiple } from '../../../../hooks/useMultiple';

import { STATE_STATUSES } from 'utils/consts';

import FilterStaffBlock from './FilterStaffBlock';

import classes from '../ReportPage.module.scss';

const MarginFilter = observer((props) => {
  const { reportProjects, filters } = useContext(Context);
  const { departments, directorsList, fetchingReportProjects } = props;

  const controller = new AbortController();

  const [
    projectStatus,,
    selectedProjectStatusList,
    addProjectStatus,
    deleteProjectStatus,,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const apllyFilterHandler = () => {
    reportProjects.setReportProjectsFilter({
      ...filters.reportFilter,
      selectedDepartmentList: filters.reportSelectedDepartmentList,
      selectedDirectorList: filters.reportSelectedDirectorList,
      selectedResponsibleList: filters.reportselectedResponsibleList,
      selectedProjectStatusList,
    });

    reportProjects.setReportProjectsPage(1);

    fetchingReportProjects(
      reportProjects.reportProjectsPage,
      reportProjects.reportProjectsLimit,
      controller.signal,
      reportProjects.reportProjectsFilter,
      'update',
    );
  };

  const [url, setUrl] = useState('');
  const [isClicked, setIsClicked] = useState(false);

  const exportHandler = () => {
    setIsClicked(true);

    check().then(() => {
      exportGoogleReportProjects({
        ...filters.reportFilter,
        selectedDepartmentList: filters.reportSelectedDepartmentList,
        selectedDirectorList: filters.reportSelectedDirectorList,
        selectedResponsibleList: filters.reportselectedResponsibleList,
        selectedProjectStatusList,
      }).then((dataUrl) => {
        setUrl(dataUrl.sheet_url);
        setIsClicked(false);
      });
    });
  };

  const redirectHandler = () => {
    setUrl('');
  };

  return (
    <>
      <div className={classes.filter__container__inner}>
        <div className={classes.filter__block}>
          <div className={classes.filter__item}>
            <span className={classes.filter__text}>Период реализации</span>
            <div className={classes.doubleDate__container}>
              <SecondDoubleDate
                value1={filters.reportFilter.periodStart}
                value2={filters.reportFilter.periodEnd}
                setValue1={(event) => filters.setReportFilter({ periodStart: event })}
                setValue2={(event) => filters.setReportFilter({ periodEnd: event })}
              />
            </div>
          </div>

          <div className={classes.filter__item}>
            <span className={classes.filter__text}>Месяц учета</span>

            <div className={classes.doubleDate__container}>
              <SecondDoubleDate
                value1={filters.reportFilter.monthStart}
                value2={filters.reportFilter.monthEnd}
                setValue1={(event) => filters.setReportFilter({ monthStart: event })}
                setValue2={(event) => filters.setReportFilter({ monthEnd: event })}
              />
            </div>
          </div>

          <div className={classes.filter__item}>
            <span className={classes.filter__text}>Статус проекта</span>
            <div className={classes.input__container}>

              <InputElement
                value={projectStatus}
                multipleSelect={addProjectStatus}
                dropdown={STATE_STATUSES.LIST}
                multiple
              />

            </div>
          </div>

          <div className={selectedProjectStatusList.length ? classes.selected__input : classes.noSelected__input}>
            {selectedProjectStatusList.map((element) => (
              <MultipleBtn
                key={element.id}
                element={element}
                deleteEl={deleteProjectStatus}
              />
            ))}
          </div>
        </div>

        <FilterStaffBlock
          departments={departments}
          directorsList={directorsList}
        />
      </div>

      <div className={classes.btns}>
        <SuccessBtn
          text="Применить фильтр"
          style={{ padding: '4px 14px', fontSize: 11 }}
          onClick={() => apllyFilterHandler()}
        />

        {url
          ? (
            <SuccessBtn
              type="white"
              text="Открыть таблицу"
              style={{ padding: '4px 14px', fontSize: 11 }}
              onClick={() => redirectHandler()}
              target="_blank"
              href={url}
            />
          )
          : (
            <SuccessBtn
              type="white"
              text={isClicked ? 'Подождите...' : 'Экспорт в Google'}
              style={{ padding: '4px 14px', fontSize: 11 }}
              onClick={isClicked ? null : () => exportHandler()}
            />
          )}
      </div>
    </>
  );
});
export default MarginFilter;
