// @ts-nocheck
import React, { useContext, useRef, useState } from 'react';
import { Context } from 'index';

import { updateStaff } from 'API/StaffAPI';
import { changePassword, check } from 'API/UserAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import InputElement from 'components/UI/InputElements/InputElement';
import ContractElement from 'components/UI/Modals/ModalCreateContract/components/ContractElement';

import classes from '../SettingsPage.module.css';

function Info(props) {
  const { info } = props;

  const { toast } = useContext(Context);

  const [fullname, setFullname] = useState(info.fullname);
  const [active, setActive] = useState(false);
  const prevFullname = useRef(info.fullname);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const cancel = () => {
    setFullname(prevFullname.current);
    setCurrentPassword('');
    setNewPassword('');
    setRepeatPassword('');
    setActive(false);
  };

  const send = () => {
    if (fullname !== prevFullname.current) {
      check().then(() => {
        const name = fullname.replace(/ +/g, ' ').trim().split(' ')[0];
        const surname = fullname.replace(/ +/g, ' ').trim().split(' ')[1];

        if (!surname) {
          toast.setToastInfo('Введите фамилию');
          toast.setShowError(true);
          return;
        }

        updateStaff(info.id, { name, surname }).then(() => {
          setActive(false);
          prevFullname.current = fullname;
        });
      });
    }

    if (!newPassword) {
      toast.setToastInfo('Новый пароль не может быть пустым');
      toast.setShowError(true);
      return;
    }

    if (newPassword !== repeatPassword) {
      toast.setToastInfo('Пароли не совпадают');
      toast.setShowError(true);
      return;
    }

    if (currentPassword || newPassword || repeatPassword) {
      check().then(() => {
        changePassword(newPassword, currentPassword)
          .then(() => {
            prevFullname.current = fullname;
            setActive(false);
            toast.setToastInfo('Пароль успешно сменен');
            toast.setShowSuccess(true);
            setCurrentPassword('');
            setNewPassword('');
            setRepeatPassword('');
          })
          .catch(() => {
            toast.setToastInfo('Текущий пароль введен неверно');
            toast.setShowError(true);
          });
      });
    }
  };

  return (
    <div className={classes.inputs__container}>
      <InputElement
        text="Сотрудник:"
        value={fullname}
        setValue={setFullname}
        placeholder="ФИО"
        textStyle={{ minWidth: 185, fontWeight: 600 }}
        onClick={() => setActive(true)}
      />
      <InputElement
        text="E-mail:"
        value={info.email}
        disabled
        textStyle={{ minWidth: 185, fontWeight: 600 }}
      />
      <InputElement
        text=" Отдел:"
        disabled
        value={info.department.name}
        textStyle={{ minWidth: 185, fontWeight: 600 }}
      />
      <InputElement
        text=" Уровень доступа:"
        disabled
        value={info.role.name}
        textStyle={{ minWidth: 185, fontWeight: 600 }}
      />

      {info.role.id === 2 && info.job.id === 0 && (
      <InputElement
        text="Команда:"
        value={info.team ? info.team.name : 'Не указано'}
        textStyle={{ minWidth: 185, fontWeight: 600 }}
        disabled
      />
      )}

      {info.role.id === 2 && (info.job.id === 1 || info.job.id === 2) && (
      <InputElement
        text="Должность:"
        value={info.job ? info.job.name : 'Не указано'}
        textStyle={{ minWidth: 185, fontWeight: 600 }}
        disabled
      />
      )}

      <ContractElement
        text="Пароль:"
        style={{ alignItems: 'center' }}
        textStyle={{ minWidth: 185, fontWeight: 600 }}
      >
        <div className={classes.password__container}>
          <InputElement
            type="password"
            value={currentPassword}
            setValue={setCurrentPassword}
            placeholder="Старый пароль"
            style={{ marginBottom: 0 }}
            onClick={() => setActive(true)}
          />

          <InputElement
            type="password"
            value={newPassword}
            setValue={setNewPassword}
            placeholder="Новый пароль"
            style={{ marginBottom: 0 }}
            onClick={() => setActive(true)}
          />

          <InputElement
            type="password"
            value={repeatPassword}
            setValue={setRepeatPassword}
            placeholder="Повторите"
            style={{ marginBottom: 0 }}
            onClick={() => setActive(true)}
          />
        </div>
      </ContractElement>

      {active && (
      <div className={classes.btn__container}>
        <SuccessBtn
          text="Сохранить"
          style={{
            padding: '2px 20px',
            fontSize: 13,
          }}
          onClick={send}
        />
        <SuccessBtn
          text="Отменить"
          type="white"
          style={{
            padding: '2px 20px',
            fontSize: 13,
          }}
          onClick={cancel}
        />
      </div>
      )}
    </div>
  );
}

export default Info;
