export const getCurrentQuarter = () => {
  const now = new Date();
  const month = now.getMonth() + 1;
  const quarter = Math.ceil(month / 3);

  const year = now.getFullYear();

  return `${quarter}кв${year}`;
};

export const getQuarter = (value) => {
  if (value === '-') return value;
  const [year, month, day] = value.split('-').map(Number);
  const date = new Date(year, month - 1, day);

  const monthNumber = date.getMonth() + 1;
  const quarter = Math.ceil(monthNumber / 3);

  const yearNumber = date.getFullYear();

  return `${quarter}кв${yearNumber}`;
};

export const checkFormat = (value) => {
  const regex = /^\dкв\d{4}$/;
  return regex.test(value) ? value : '-';
};

export const getNextFiveQuarters = () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;

  const quarters = [];

  // Добавляем 4 квартал прошлого года
  quarters.push(`4кв${currentYear - 1}`);

  // Добавляем все кварталы текущего года
  for (let i = 1; i <= 4; i++) {
    quarters.push(`${i}кв${currentYear}`);
  }

  // Добавляем 1 квартал следующего года
  quarters.push(`1кв${currentYear + 1}`);

  return quarters;
};
