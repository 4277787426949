// @ts-nocheck
import { makeAutoObservable } from 'mobx';

// Стор для проектов блогеров/клиентов
export default class ProjectStore {
  constructor() {
    this._projects = [];
    this._projectsTotalCount = 0;
    this._projectsPage = 1;
    this._projectsLimit = 15;
    this._projectsFilter = undefined;
    this._projectsTotalSum = 0;
    this._projectsTotalCredit = 0;
    this._projectsTotalPaidSum = 0;

    this._modalProjects = [];
    this._modalProjectsTotalCount = 0;
    this._modalProjectsPage = 1;
    this._modalProjectsLimit = 15;
    this._modalProjectsFilter = undefined;
    makeAutoObservable(this);
  }

  setProjects(projects, update) {
    if (update) {
      return (this._projects = projects);
    }

    if (this._projectsPage === 1) {
      return (this._projects = projects);
    }

    projects.forEach((project) => this._projects.push(project));
  }

  setProjectsTotalCount(count) {
    this._projectsTotalCount = count;
  }

  setProjectsPage(page) {
    this._projectsPage = page;
  }

  setProjectsFilter(filter) {
    this._projectsFilter = filter;
  }

  setProjectsTotalSum(sum) {
    if (this._projectsPage === 1) {
      this._projectsTotalSum = sum;
    }
  }

  setProjectsTotalCredit(credit) {
    this._projectsTotalCredit = credit;
  }

  setProjectsTotalPaidSum(paidSum) {
    this._projectsTotalPaidSum = paidSum;
  }

  setModalProjects(projects, update) {
    if (update) {
      return (this._modalProjects = projects);
    }

    if (this._modalProjectsPage === 1) {
      return (this._modalProjects = projects);
    }

    projects.forEach((project) => this._modalProjects.push(project));
  }

  setModalProjectsTotalCount(count) {
    this._modalProjectsTotalCount = count;
  }

  setModalProjectsPage(page) {
    this._modalProjectsPage = page;
  }

  setModalProjectsFilter(filter) {
    this._modalProjectsFilter = filter;
  }

  get projects() {
    return this._projects;
  }

  get projectsTotalCount() {
    return this._projectsTotalCount;
  }

  get projectsPage() {
    return this._projectsPage;
  }

  get projectsLimit() {
    return this._projectsLimit;
  }

  get projectsFilter() {
    return this._projectsFilter;
  }

  get projectsTotalSum() {
    return this._projectsTotalSum;
  }

  get projectsTotalCredit() {
    return this._projectsTotalCredit;
  }

  get projectsTotalPaidSum() {
    return this._projectsTotalPaidSum;
  }

  get modalProjects() {
    return this._modalProjects;
  }

  get modalProjectsTotalCount() {
    return this._modalProjectsTotalCount;
  }

  get modalProjectsPage() {
    return this._modalProjectsPage;
  }

  get modalProjectsLimit() {
    return this._modalProjectsLimit;
  }

  get modalProjectsFilter() {
    return this._modalProjectsFilter;
  }
}
