// @ts-nocheck
import React, {
  useContext, useEffect, useLayoutEffect,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import FilterBtn from 'components/UI/FilterBtn/FilterBtn';
import StatusBtn from 'components/UI/StatusBtns/StatusBtn/StatusBtn';

import FiltersPanel from '../../UI/FiltersPanel/FiltersPanel';

import { useMultiple } from 'hooks/useMultiple';

import { STATUS_QUANTITY } from './consts';

import { FIELDS } from './filterFields';

import classes from './MainTables.module.css';

const ManagersFilters = observer((props) => {
  const {
    projectList,
    clientList,
    fetchProjects,
    responsibleList,
    directorList,
    managerList,
  } = props;
  const { mainMarketing, filters } = useContext(Context);
  const controller = new AbortController();

  const [filtersPanelOpen, setFiltersPanelOpen] = useState(false);

  const [
    project,
    changeProject,
    selectedProjectList,
    addSelectedProject,
    deleteSelectedProject,
    setSelectedProjectList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    client,
    changeClient,
    selectedClientList,
    addSelectedClient,
    deleteClient,
    setSelectedClientList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    manager,
    changeManager,
    selectedManagerList,
    addManager,
    deleteManager,
    setSelectedManagerList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    director,
    changeDirector,
    selectedDirectorList,
    addDirector,
    deleteDirector,
    setSelectedDirectorList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    responsible,
    changeResponsible,
    selectedResponsibleList,
    addResponsible,
    deleteResponsible,
    setSelectedResponsibleList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  // Состояние фильтра из стора

  useEffect(() => {
    filters.setManagersProjectFilter(selectedProjectList);
  }, [selectedProjectList]);

  useEffect(() => {
    filters.setManagersClientsFilter(selectedClientList);
  }, [selectedClientList]);

  useEffect(() => {
    filters.setManagersManagerFilter(selectedManagerList);
  }, [selectedManagerList]);

  useEffect(() => {
    filters.setManagersDirectorFilter(selectedDirectorList);
  }, [selectedDirectorList]);

  useEffect(() => {
    filters.setManagersResponsibleFilter(selectedResponsibleList);
  }, [selectedResponsibleList]);

  useLayoutEffect(() => {
    setSelectedProjectList(filters.managersProjectFilter);

    setSelectedClientList(filters.managersClientsFilter);

    setSelectedManagerList(filters.managersManagerFilter);

    setSelectedDirectorList(filters.managersDirectorFilter);

    setSelectedResponsibleList(filters.managersResponsibleFilter);
  }, []);

  const updateFieldHandler = (payload) => {
    filters.setManagersFilter({
      ...filters.managersFilter,
      ...payload,
    });
  };

  const stageHandler = (value) => {
    const tempArray = Array.from(filters.managersFilter.status);
    if (tempArray.includes(value)) {
      const index = tempArray.findIndex((item) => item === value);
      tempArray.splice(index, 1);
    } else {
      tempArray.push(value);
    }

    filters.setManagersFilter({
      ...filters.managersFilter,
      status: tempArray,
    });
  };

  const applyFiltersHandler = (event) => {
    event.preventDefault();
    setFiltersPanelOpen(false);
    if (fetchProjects) {
      mainMarketing.setProjectsFilter({
        ...filters.managersFilter,
        projectName: project[0].name,
        project: selectedProjectList,
        client: selectedClientList,
        manager: selectedManagerList,
        director: selectedDirectorList,
        responsible: selectedResponsibleList,
      });

      mainMarketing.setProjectsPage(1);

      fetchProjects(
        mainMarketing.projectsPage,
        mainMarketing.projectsLimit,
        controller.signal,
        mainMarketing.projectsFilter,
        'update',
      );
    }
  };

  useEffect(() => {
    let func = () => {};
    if (mainMarketing.projectsPage > 1) {
      if (fetchProjects) {
        func = () => {
          mainMarketing.setProjectsFilter({
            ...filters.managersFilter,
            projectName: project[0].name,
            project: selectedProjectList,
            client: selectedClientList,
            manager: selectedManagerList,
            director: selectedDirectorList,
            responsible: selectedResponsibleList,
          });

          fetchProjects(
            1,
            mainMarketing.projectsLimit,
            controller.signal,
            mainMarketing.projectsFilter,
            'update',
          ).then(() => mainMarketing.setProjectsPage(2));
        };
      }
    }

    const test = setTimeout(func, 220);

    return () => {
      clearTimeout(test);
    };
  }, [filters.managersFilter.status]);

  const propsForFiltersPanel = {
    isPanelOpen: filtersPanelOpen,
    fields: FIELDS,
    values: filters.managersFilter,
    onUpdateField: updateFieldHandler,
    onApplyFilters: applyFiltersHandler,
    projectList,
    clientList,
    managerList,
    responsibleList,
    directorList,
    addSelectedProject,
    addSelectedClient,
    addManager,
    addDirector,
    addResponsible,
    changeProject,
    changeClient,
    changeManager,
    changeDirector,
    changeResponsible,
    project,
    client,
    manager,
    director,
    responsible,
    selectedProjectList,
    selectedClientList,
    selectedManagerList,
    selectedDirectorList,
    selectedResponsibleList,
    deleteSelectedProject,
    deleteClient,
    deleteManager,
    deleteDirector,
    deleteResponsible,
  };

  return (
    <>
      <div className={classes.managers__status}>
        <span>Статус проекта</span>

        <FilterBtn isOpen={filtersPanelOpen} setIsOpen={setFiltersPanelOpen} />
      </div>

      <div className={classes.filters__status__container}>
        {STATUS_QUANTITY.map((statusElement) => (
          <StatusBtn
            key={statusElement}
            status={statusElement}
            onClick={() => stageHandler(statusElement)}
            isActive={filters.managersFilter.status.includes(statusElement)}
            marketing
          />
        ))}
      </div>

      <FiltersPanel {...propsForFiltersPanel} />
    </>
  );
});

export default ManagersFilters;
