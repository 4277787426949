// @ts-nocheck
import React, { useEffect, useState } from 'react';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import ContractElement from 'components/UI/Modals/ModalCreateContract/components/ContractElement';
import MyDateInput from 'components/UI/MyDateInput/MyDateInput';
import MyDropdown from 'components/UI/MyDropdown/MyDropdown';
import LawyerSection from 'components/UI/Section/LawyerSection';

import InputElement from '../../../../UI/InputElements/InputElement';

import { getDate } from 'utils/getDate';
import { isValidURL } from 'utils/isValidUrl';

import { RESPONSIBLE_TOKEN, RESPONSIBLE_TOKEN_CLIENT } from '../../../../../utils/consts';

import classes from '../../ProjectPage.module.scss';

function PublicationInfoCl(props) {
  const {
    headerText, performers, updateFunc, updateChildren, save, data,
  } = props;

  const getClientById = (id) => RESPONSIBLE_TOKEN_CLIENT.LIST.find((client) => client.id === id) || {};
  const getResponsibleById = (id) => RESPONSIBLE_TOKEN.LIST.find((responsible) => responsible.id === id) || {};

  const [active, setActive] = useState(false);

  const [comment, setComment] = useState('');
  const [responsible, setResponsible] = useState({});
  const [client, setClient] = useState({});
  const [isMarkingInfo, setIsMarkingInfo] = useState(false);

  useEffect(() => {
    if (data.marking_data.length > 0) {
      setIsMarkingInfo(true);
      setResponsible(getResponsibleById(data.marking_data[0].responsible));
      setClient(getClientById(data.marking_data[0].client));
      setComment(data.marking_data[0].comment);
    }
  }, [data.marking_data]);

  const savePublicationInfoClHandler = () => {
    save(performers, 'client_publication');

    const dataMarkingInfo = {
      marking_data: [{
        comment,
        responsible: responsible.id,
        client: client.id,
      }],
    };
    if (isMarkingInfo) {
      save(dataMarkingInfo);
    } else {
      save({ marking_data: [] });
    }

    setActive(false);
  };

  return (
    <LawyerSection title={headerText}>

      <ContractElement
        text="Наличие маркировки"
        textClassName={classes.title_publication}
        style={{ marginBottom: 10 }}

      >
        <InputElement
          checkboxText="Да"
          value={isMarkingInfo}
          setValue={() => setIsMarkingInfo(true)}
          checkbox
          onClick={() => setActive(true)}
          style={{ width: '160px', marginRight: '10px', marginBottom: 0 }}
        />
        <InputElement
          checkboxText="Нет"
          value={!isMarkingInfo}
          setValue={() => setIsMarkingInfo(false)}
          checkbox
          onClick={() => setActive(true)}
          style={{ width: '160px', marginRight: '10px' }}
        />

      </ContractElement>
      {
isMarkingInfo && (
<>
  <ContractElement
    text="Ответственный за токен"
    textClassName={classes.title_publication}
  >
    <MyDropdown
      style={{ width: '450px' }}
      selected={responsible}
      setSelected={setResponsible}
      list={RESPONSIBLE_TOKEN.LIST}
      onClick={() => setActive(true)}
    />
  </ContractElement>

  <ContractElement
    text="Заказчик"
    textClassName={classes.title_publication}
  >
    <MyDropdown
      style={{ width: '450px' }}
      selected={client}
      setSelected={setClient}
      list={RESPONSIBLE_TOKEN_CLIENT.LIST}
      onClick={() => setActive(true)}
    />
  </ContractElement>

  <ContractElement
    text="Дополнительная информация"
    textClassName={classes.title_publication}
  >
    <InputElement
      value={comment}
      onChange={(e) => setComment(e.target.value)}
      textareaStyle={{ width: '450px', minHeight: '200px' }}
          // textStyle={{ minWidth: 230 }}
      placeholder={`- получатели токенов (уточнить, клиент или блогер, и   какую часть токенов получает самостоятельно)
- рекламодатель (ЮЛ, ИНН)
- исполнитель по изначальному договору (ЮЛ, ИНН)
- договор №__ от + прил № от
- признак (НДС/без НДС)`}
      onClick={() => setActive(true)}
      textarea
    />
  </ContractElement>
</>
)

}

      {performers?.map((performer) => (
        <div className={classes.performer__container} key={performer.id}>
          <div className={classes.performer__container__name}>

            <InputElement
              value={performer?.contractor_legal_entity?.legal_name || 'Неизвестно'}
              textStyle={{ minWidth: 230 }}
              placeholder="ЮЛ исполнителя"
              text={performer?.blogger?.name}
              onClick={() => setActive(true)}
              disabled
            />
          </div>

          {performer?.platform?.map((platform) => (
            <div key={platform.id}>
              <div className={classes.performer__container__link}>
                <InputElement
                  value={platform?.link}
                  textStyle={{ minWidth: 230 }}
                  placeholder="Ссылка на соц.сеть"
                  disabled
                  onClick={() => setActive(true)}
                  isLink={isValidURL(platform?.link)}
                  className={classes.input__link__padding}
                  classNameLink={classes.input__link}
                />
              </div>

              {platform?.publications?.map((pub) => (
                <div key={pub.id}>
                  <div className={classes.performer__container__info} key={pub.id}>
                    <MyDateInput
                      value={pub?.deadline}
                      className={classes.date}
                      onChange={(event) => updateFunc(performer.id, platform.id, pub.id, 'deadline', event)}
                      onClick={() => setActive(true)}
                    />
                    <InputElement
                      value={pub?.type?.name}
                      textStyle={{ minWidth: 230 }}
                      placeholder="Формат публикации"
                      onClick={() => setActive(true)}
                      disabled
                    />
                    <InputElement
                      value={pub?.link}
                      textStyle={{ minWidth: 230 }}
                      placeholder="Ссылка на публикацию"
                      onChange={(event) => updateFunc(performer.id, platform.id, pub.id, 'link', event.target.value)}
                      onClick={() => setActive(true)}
                      isLink={isValidURL(pub?.link)}
                      className={classes.input__linkPublication__padding}
                      classNameLink={classes.input__linkPublication}
                    />
                    <InputElement
                      value={pub?.token}
                      textStyle={{ minWidth: 230 }}
                      placeholder="Токен"
                      onChange={(event) => updateFunc(performer.id, platform.id, pub.id, 'token', event.target.value)}
                      onClick={() => setActive(true)}
                    />
                    <MyDateInput
                      value={pub?.token_date}
                      className={classes.date}
                      onChange={(event) => updateFunc(performer.id, platform.id, pub.id, 'token_date', getDate(event))}
                      onClick={() => setActive(true)}
                    />
                  </div>

                  {pub?.subpublications?.map((subpub) => (
                    <div className={classes.performer__container__info} key={subpub.id}>
                      <MyDateInput
                        value={subpub.deadline}
                        className={classes.date}
                        onChange={(event) => updateChildren(performer?.id, platform.id, pub?.id, subpub?.id, 'deadline', event)}
                        onClick={() => setActive(true)}
                      />
                      <InputElement
                        value={subpub.type.name}
                        textStyle={{ minWidth: 230 }}
                        placeholder="Формат публикации"
                        onClick={() => setActive(true)}
                        disabled
                      />
                      <InputElement
                        value={subpub.link}
                        textStyle={{ minWidth: 230 }}
                        placeholder="Ссылка на публикацию"
                        onChange={(event) => updateChildren(performer?.id, platform.id, pub?.id, subpub?.id, 'link', event.target.value)}
                        onClick={() => setActive(true)}
                      />
                      <InputElement
                        value={subpub.token}
                        textStyle={{ minWidth: 230 }}
                        placeholder="Токен"
                        onChange={(event) => updateChildren(performer?.id, platform.id, pub?.id, subpub?.id, 'token', event.target.value)}
                        onClick={() => setActive(true)}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}

      {active && (
      <div className={classes.btn__wrapper}>
        <SuccessBtn
          text="Сохранить"
          style={{
            padding: '2px 20px',
            fontSize: 13,
          }}
          onClick={savePublicationInfoClHandler}
        />

        {/* <SuccessBtn
          text="Отменить"
          type="white"
          style={{
            padding: '2px 20px',
            fontSize: 13,
          }}
          onClick={cancelHandler}
        /> */}
      </div>
      )}
    </LawyerSection>
  );
}

export default PublicationInfoCl;
