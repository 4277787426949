import React from 'react';

import MyDateInput from '../MyDateInput/MyDateInput';

import classes from './DoubleDate.module.css';

function DoubleDate(props) {
  const {
    value1, setValue1, value2, setValue2, disabled,
  } = props;

  return (
    <div className={classes.container}>
      <MyDateInput
        value={value1}
        setValue={setValue1}
        className={classes.date}
        disabled={disabled}
        maxDate={value2 && new Date(value2)}
      />

      <span>-</span>

      <MyDateInput
        value={value2}
        setValue={setValue2}
        className={classes.date}
        disabled={disabled}
        minDate={value1 && new Date(value1)}
      />
    </div>
  );
}

export default DoubleDate;
