import React from 'react';

import { getFileExtension } from 'utils/getFileExtension';

import doc from '../../../assets/icons/FilesIcons/doc.svg';
import heif from '../../../assets/icons/FilesIcons/HEIF.svg';
import jpeg from '../../../assets/icons/FilesIcons/JPEG.svg';
import key from '../../../assets/icons/FilesIcons/KEY.svg';
import pdf from '../../../assets/icons/FilesIcons/pdf.svg';
import png from '../../../assets/icons/FilesIcons/PNG.svg';
import ppt from '../../../assets/icons/FilesIcons/PPT.svg';
import xls from '../../../assets/icons/FilesIcons/xls.svg';

import classes from './FilesProgressBar.module.css';

function FilesProgressBar(props) {
  const { file } = props;

  let image;
  const extension = getFileExtension(file.name ? file.name : '');

  if (extension === 'doc') {
    image = doc;
  } else if (extension === 'pdf') {
    image = pdf;
  } else if (extension === 'xls') {
    image = xls;
  } else if (extension === 'ppt') {
    image = ppt;
  } else if (extension === 'jpeg') {
    image = jpeg;
  } else if (extension === 'heif') {
    image = heif;
  } else if (extension === 'png') {
    image = png;
  } else if (extension === 'key') {
    image = key;
  }

  return (
    <div className={classes.file}>
      <img src={image} alt="" />
      <div
        className={classes.file__name}
      >
        {file.name ? file.name : 'None'}
      </div>
    </div>
  );
}

export default FilesProgressBar;
