// @ts-nocheck
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { endOfISOWeek, startOfISOWeek } from 'date-fns';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { getBloggers } from 'API/BloggerAPI';
import { fetchAllProjects } from 'API/ManagerAPI/AutocompleteAPI';
import {
  createTaskUser,
  deleteTaskUser,
  fetchOneUserTasks,
  patchTaskUser,
} from 'API/TaskAPI';
import { check } from 'API/UserAPI';
import {
  fetchNewsTaskPage,
  patchNewsTaskPage,
  postNewsTaskPage,
} from '../../../API/NewsAPI';

import ModalConfirm from 'components/UI/Modals/ModalConfirm/ModalConfirm';
import Table from 'components/UI/Table/Table';

import DoubleDate from '../../UI/DoubleDate/DoubleDate';
import FilterBtn from '../../UI/FilterBtn/FilterBtn';
import MyTasksFilter from '../../UI/MyTasksFilter/MyTasksFilter';
import PageTitle from '../../UI/PageTitle/PageTitle';
import Section from '../../UI/Section/Section';
import WeekResults from '../../UI/WeekResults/WeekResults';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from 'hooks/useLogout';
import { useStateNew2 } from '../../../hooks/useStateNew2';

import { getDate, getFormattedDate } from 'utils/getDate';

import classes from './MyTasks.module.css';

const TasksPageDevelopment = observer(() => {
  const { user, toast, modal } = useContext(Context);
  const [logout] = useLogout();
  const isFirstRender = useRef(true);

  const [isOpen, setIsOpen] = useState(false);

  const [filter, setFilter] = useState(undefined);

  const [filtersState, updateFiltersState] = useState({
    projectStatus: [],
    status: [],
  });

  const [tasks, setTasks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [bloggers, setBloggers] = useState([]);

  const [startWeek] = useState(startOfISOWeek(new Date()));
  const [endWeek] = useState(endOfISOWeek(new Date()));

  const [weekNews, setWeekNews, updateNews] = useStateNew2([]);

  const [startDate, setStartDate] = useState(startOfISOWeek(new Date()));
  const [endDate, setEndDate] = useState(endOfISOWeek(new Date()));

  const [newsArchive, setNewsArchive] = useState([]);

  const [fetchTasks, isTasksLoading] = useFetching(async (filter) => {
    const data = await fetchOneUserTasks(filter);
    setTasks(data);
  });

  const taskDebounce = useCallback(
    debounce((id, data) => {
      check()
        .then(() => {
          patchTaskUser(id, data);
        })
        .catch((error) => logout(error));
    }, 350),
    [],
  );

  const newsDebounce = useCallback(
    debounce((id, filter) => {
      check()
        .then(() => {
          fetchNewsTaskPage(id, filter).then((data) => setNewsArchive(data));
        })
        .catch((error) => logout(error));
    }, 250),
    [],
  );

  const addTask = () => {
    check()
      .then(() => {
        const data = {
          name: '',
          deadline: '',
          responsible: [{ id: user.user.staff.id }],
          status: {
            id: 0,
          },
        };
        createTaskUser(data).then(() => {
          fetchTasks(filter);
        });
      })
      .catch((error) => logout(error));
  };

  const deleteTask = (id, event) => {
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить задачу?',
      click: () => {
        setTasks(tasks.filter((element) => element.id !== id));
        check()
          .then(() => {
            deleteTaskUser(id);
          })
          .catch((error) => logout(error));
      },
    });
    modal.setModalConfirmVisible(true);
  };

  const updateTask = (id, fieldName, value) => {
    setTasks(
      tasks.map((element) => (element.id === id
        ? {
          ...element,
          [fieldName]: value,
        }
        : element)),
    );

    if (fieldName === 'status' || fieldName === 'deadline') {
      check()
        .then(() => {
          if (fieldName === 'status') {
            patchTaskUser(id, { status: { id: value } }).then(() => {
              fetchTasks(filter);
            });
          } else if (fieldName === 'deadline') {
            patchTaskUser(id, { deadline: getFormattedDate(value) }).then(
              () => {
                fetchTasks(filter);
              },
            );
          }
        })
        .catch((error) => logout(error));
    } else {
      taskDebounce(id, { [fieldName]: value });
    }
  };

  const saveNewsHandler = () => {
    check()
      .then(() => {
        patchNewsTaskPage(weekNews[0].id, {
          good: weekNews[0].good,
          bad: weekNews[0].bad,
        }).then(() => {
          toast.setShowSuccess(true);
          toast.setToastInfo('Новость успешно сохранена');

          fetchNewsTaskPage(user.user.staff.id, {
            created_after: getDate(startDate),
            created_before: getDate(endDate),
          }).then((data) => setNewsArchive(data));
        });
      })
      .catch((error) => logout(error));
  };

  const addSubTask = (e, id) => {
    const newTask = {
      name: '',
      deadline: '',
      responsible: [],
      status: { id: 0 },
    };
    const currentTask = tasks.find((el) => el.id === id);
    currentTask?.subtasks.unshift(newTask);

    check()
      .then(() => {
        patchTaskUser(id, { ...currentTask })
          .then(() => {
            fetchTasks();
          });
      })
      .catch((error) => logout(error));
  };

  const deleteSubTask = (parentId, subtaskId) => {
    const currentTask = tasks.find((task) => task.id === parentId);
    const taskWithoutSubtask = {
      ...currentTask,
      project: { id: currentTask.project.id },
      subtasks: currentTask.subtasks.filter((subtask) => subtask.id !== subtaskId),
    };

    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить подзадачу?',
      click: () => {
        check()
          .then(() => {
            patchTaskUser(parentId, taskWithoutSubtask).then(() => {
              fetchTasks();
            });
          })
          .catch((error) => logout(error));
      },
    });
    modal.setModalConfirmVisible(true);
  };

  const updateSubTask = (id, fieldName, value) => {
    const currentTask = tasks.find((task) => task.subtasks.find((subtask) => subtask.id === id));
    setTasks(
      tasks.map((task) => (task.id === currentTask.id
        ? {
          ...task,
          subtasks: [...task.subtasks].map((subtask) => ((subtask.id === id)
            ? {
              ...subtask,
              [fieldName]: value,
            }
            : subtask)),
        }
        : task
      )),
    );

    if (fieldName === 'deadline') {
      const editedSubtasks = currentTask.subtasks.map((subtask) => ((subtask.id === id)
        ? {
          ...subtask,
          deadline: getFormattedDate(value),
        } : subtask
      ));
      check()
        .then(() => {
          patchTaskUser(currentTask.id, { subtasks: editedSubtasks }).then(() => {
            fetchTasks(filter);
          });
        })
        .catch((error) => logout(error));
      // taskDebounce(currentTask.id, { subtasks: editedSubtasks });
    } else if (fieldName === 'status') {
      const editedSubtasks = currentTask.subtasks.map((subtask) => ((subtask.id === id)
        ? {
          ...subtask,
          status: { id: value },
        } : subtask
      ));

      check()
        .then(() => {
          patchTaskUser(currentTask.id, { subtasks: editedSubtasks }).then(() => {
            fetchTasks(filter);
          });
        })
        .catch((error) => logout(error));
    } else {
      const editedSubtasks = currentTask.subtasks.map((subtask) => ((subtask.id === id)
        ? {
          ...subtask,
          [fieldName]: value,
        } : subtask
      ));
      taskDebounce(currentTask.id, { subtasks: editedSubtasks });
    }
  };

  useEffect(() => {
    check()
      .then(() => {
        fetchTasks();
        getBloggers().then((dataBloggers) => setBloggers(dataBloggers));

        fetchAllProjects().then((data) => setProjects(data));

        const filter = {
          created_after: getDate(startWeek),
          created_before: getDate(endWeek),
        };

        const { id } = user.user.staff;

        fetchNewsTaskPage(id, filter).then((data) => {
          if (data.length > 0) {
            setNewsArchive(data);
            setWeekNews(data);
          } else {
            postNewsTaskPage({ staff: { id } }).then(() => {
              fetchNewsTaskPage(id, filter).then((data) => {
                setNewsArchive(data);
                setWeekNews(data);
              });
            });
          }
        });
      })
      .catch((error) => logout(error));
  }, []);

  useEffect(() => {
    if (isFirstRender.current) return;

    newsDebounce(user.user.staff.id, {
      created_after: getDate(startDate),
      created_before: getDate(endDate),
    });
  }, [startDate, endDate]);

  // При первом рендере показываем крутилку
  if (isFirstRender.current) {
    if (isTasksLoading) {
      return <Spinner animation="grow" />;
    }
    isFirstRender.current = false;
  }

  return (
    <>
      <ModalConfirm
        show={modal.modalConfirmVisible}
        onHide={() => modal.setModalConfirmVisible(false)}
        props={modal.modalConfirmProps}
      />

      <PageTitle
        button={<FilterBtn isOpen={isOpen} setIsOpen={setIsOpen} />}
        style={{ justifyContent: 'space-between' }}
      >
        Мои задачи
      </PageTitle>
      <div className={classes.TasksPage__title}>Занятость сотрудника</div>

      <MyTasksFilter
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setFilter={setFilter}
        values={filtersState}
        onUpdateField={(payload) => {
          updateFiltersState({
            ...filtersState,
            ...payload,
          });
        }}
        projects={projects}
        fetchTasks={fetchTasks}
      />

      <Table
        setTasks
        headerStyle={{ marginTop: 35 }}
        headCellStyle={{ padding: ' 20px 15px' }}
        type="tasksDevelopment"
        updateFunc={updateTask}
        deleteHandler={deleteTask}
        addHandler={addTask}
        updateChildren={updateSubTask}
        deleteChildren={deleteSubTask}
        addChildren={addSubTask}
        noCheckbox
        columns={
          [
            {
              id: 'name',
              label: 'Задача',
            },
            {
              id: 'deadline',
              label: 'Дедлайн',
            },
            {
              id: 'project',
              label: 'Проект',
            },
            {
              id: 'blogger',
              label: 'Блогер',
            },
            {
              id: 'managerStatus',
              label: 'Статус задачи',
            },
          ]
      }
        data={tasks}
        projectsList={projects}
        bloggersList={bloggers}
        tableName="tasksDevelopment"
        fetchMyTasks={() => fetchTasks(filter)}
      />

      <Section
        title="Результаты недели"
        button={<DoubleDate value1={startWeek} value2={endWeek} disabled />}
        headerStyle={{ justifyContent: 'flex-start' }}
        style={{ marginTop: 35 }}
      >
        <WeekResults
          data={weekNews}
          updateNews={updateNews}
          saveNewsHandler={saveNewsHandler}
        />
      </Section>

      <Section
        title="Архив новостей"
        button={(
          <DoubleDate
            value1={startDate}
            setValue1={setStartDate}
            value2={endDate}
            setValue2={setEndDate}
          />
        )}
        contentStyle={{ padding: 0, background: 'none' }}
        headerStyle={{ justifyContent: 'flex-start' }}
      >
        <Table
          headerStyle={{ marginTop: 35 }}
          cellStyle={{
            height: '100%',
            padding: '10px 25px',
            alignItems: 'start',
          }}
          headCellStyle={{ padding: '15px 25px', height: '100%' }}
          type="news"
          columns={[
            {
              id: 'goodNews',
              label: 'Хорошие новости',
            },
            {
              id: 'badNews',
              label: 'Плохие новости',
            },
          ]}
          data={newsArchive}
          tableName="newsArchive"
          noCheckbox
          staffCard
          news
        />
      </Section>
    </>
  );
});

export default TasksPageDevelopment;
