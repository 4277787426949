// @ts-nocheck
import React from 'react';
import { Helmet } from 'react-helmet';

import SettingsPage from '../components/General/SettingsPage/SettingsPage';

function Settings() {
  return (
    <div className="Mysection">
      <Helmet>
        <title>Настройки | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <SettingsPage />
    </div>
  );
}

export default Settings;
