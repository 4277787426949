export const styleForModal = { height: '791px', padding: '60px 60px 100px 60px' };

export const stylesForButtonChooseAll = {
  padding: '5px 35px',
  border: '1px solid black',
  fontSize: '11px',
};

export const stylesForButtonCancelAll = {
  padding: '5px 35px',
  background: 'transparent',
  color: 'black',
  border: '1px solid black',
  fontSize: '11px',
};

export const stylesForButtonApplyOn = { padding: '5px 35px' };
export const stylesForButtonOpenGoogleTable = { padding: '5px 35px' };
export const stylesForButtonApplyOff = { padding: '5px 35px', backgroundColor: '#a3a2a2' };
