import React from 'react';

import classes from './Btns.module.scss';

function StepBtn(props) {
  const {
    active, onClick, text, style,
  } = props;
  return (
    <div
      className={[classes.btn__step, active && classes.active].join(' ')}
      onClick={onClick}
      style={style}
    >
      {text}
    </div>
  );
}

export default StepBtn;
