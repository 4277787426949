// @ts-nocheck
import React, {
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import {
  fetchDepartments,
  fetchRoles,
  fetchStaff,
  fetchStaffTeamsPage,
} from 'API/ManagerAPI/AutocompleteAPI';
import { check } from 'API/UserAPI';

import DepartmentAccordionArrow from 'components/UI/AccordionArrows/DepartmentAccordionArrow/DepartmentAccordionArrow';
import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import ModalCreateStaff from 'components/UI/Modals/ModalCreateStaff/ModalCreateStaff';
import NameFilter from 'components/UI/NameFilter/NameFilter';
import PageTitle from 'components/UI/PageTitle/PageTitle';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from 'hooks/useLogout';

import UsersElement from '../UsersElements/UsersElement';

import classes from '../styles/TeamsPage.module.css';

const TeamsPage = observer(() => {
  const { modal, user } = useContext(Context);
  const [logout] = useLogout();
  const isFirstRender = useRef(true);

  const [update, setUpdate] = useState(0);
  const [activeMarketing, setActiveMarketing] = useState(false);
  const [activeLawyers, setActiveLawyers] = useState(false);
  const [activeFinance, setActiveFinance] = useState(false);
  const [activeDevelopment, setActiveDevelopment] = useState(false);
  const [activeDeleteUsers, setActiveDeleteUsers] = useState(false);
  const [activeCreative, setActiveCreative] = useState(false);
  const [activeProduction, setActiveProduction] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);

  // Marketing
  const [allUsers, setAllUsers] = useState([]);

  const [fetchAllUsersFunc, isAllUsersLoading] = useFetching(async (name) => {
    const data = await fetchStaff();
    setAllUsers(data);
  });

  const [managerList, setManagerList] = useState([]);

  const [fetchManager, isFetchManager] = useFetching(async (staff_name) => {
    const data = await fetchStaffTeamsPage({ department: 0, staff_name });
    setManagerList(data);
    return data;
  });

  // Lawyers
  const [lawyers, setLawyers] = useState([]);
  const [docManagers, setDocManagers] = useState([]);

  const [fetchLawyers, isFetchLawyers] = useFetching(async (staff_name) => {
    const data = await fetchStaffTeamsPage({ department: 1, job: 1, staff_name });
    setLawyers(data);
    return data;
  });

  const [fetchDocManagers, isFetchDocManagers] = useFetching(async (staff_name) => {
    const data = await fetchStaffTeamsPage({ department: 1, job: 2, staff_name });
    setDocManagers(data);
    return data;
  });

  // Finance
  const [finance, setFinance] = useState([]);

  const [fetchFinance, isFetchFinance] = useFetching(async (staff_name) => {
    const data = await fetchStaffTeamsPage({ department: 2, staff_name });
    setFinance(data);
    return data;
  });

  // Development
  const [development, setDevelopment] = useState([]);

  const [fetchDevelopment, isFetchDevelopment] = useFetching(async (staff_name) => {
    const data = await fetchStaffTeamsPage({ department: 6, staff_name });
    setDevelopment(data);
    return data;
  });

  // Creative
  const [creative, setCreative] = useState([]);

  const [fetchCreative, isFetchCreative] = useFetching(async (staff_name) => {
    const data = await fetchStaffTeamsPage({ department: 4, staff_name });
    setCreative(data);
    return data;
  });

  // Production
  const [production, setProduction] = useState([]);

  const [fetchProduction, isFetchProduction] = useFetching(async (staff_name) => {
    const data = await fetchStaffTeamsPage({ department: 5, staff_name });
    setProduction(data);
    return data;
  });

  // Delete Users
  const [DeletedUsers, setDeletedUsers] = useState([]);

  const [fetchDeletedUsers, isFetchDeletedUsers] = useFetching(async (name) => {
    const data = await fetchStaff({ is_active: false, name });
    setDeletedUsers(data);
    return data;
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await check();
        const [departmentsData, rolesData] = await Promise.all([
          fetchDepartments(),
          fetchRoles(),
        ]);
        setDepartments(departmentsData);
        setRoles(rolesData);

        fetchAllUsersFunc();
        fetchLawyers();
        fetchDocManagers();
        fetchManager();
        fetchFinance();
        fetchDevelopment();
        fetchCreative();
        fetchProduction();
        fetchDeletedUsers();
      } catch (error) {
        logout();
      }
    };

    fetchData();
  }, [user.department]);

  const openModalHandler = () => {
    modal.setModalCreateStaffVisible(true);
    modal.setModalCreateStaffProps({
      departments,
      roles,
      fetchAllUsersFunc,
      fetchManager,
      fetchLawyers,
      fetchDocManagers,
      fetchFinance,
      fetchDevelopment,
      fetchCreative,
      fetchProduction,
      fetchDeletedUsers,
    });
  };

  const click = (event, name) => {
    if (event.key === 'Enter' || event.type === 'click') {
      check()
        .then(() => {
          fetchManager(name).then((data) => {
            if (name && data.length > 0) {
              setActiveMarketing(true);
            }
          });
          fetchLawyers(name).then((data) => {
            if (name && data.length > 0) {
              setActiveLawyers(true);
            }
          });
          fetchDocManagers(name).then((data) => {
            if (name && data.length > 0) {
              setActiveLawyers(true);
            }
          });
          fetchFinance(name).then((data) => {
            if (name && data.length > 0) {
              setActiveFinance(true);
            }
          });
          fetchDevelopment(name).then((data) => {
            if (name && data.length > 0) {
              setActiveDevelopment(true);
            }
          });
          fetchCreative(name).then((data) => {
            if (name && data.length > 0) {
              setActiveCreative(true);
            }
          });
          fetchProduction(name).then((data) => {
            if (name && data.length > 0) {
              setActiveProduction(true);
            }
          });
          fetchDeletedUsers(name).then((data) => {
            if (name && data.length > 0) {
              setActiveDeleteUsers(true);
            }
          });

          if (!name) {
            setActiveMarketing(false);
            setActiveLawyers(false);
            setActiveFinance(false);
            setActiveDevelopment(false);
            setActiveCreative(false);
            setActiveProduction(false);
            setActiveDeleteUsers(false);
          }

          if (name) {
            setUpdate(9);
          } else {
            setUpdate(-9);
          }
        })
        .catch((error) => logout(error));
    }
  };

  const isLoading = () => (isAllUsersLoading
      || isFetchManager || isFetchLawyers || isFetchDocManagers
      || isAllUsersLoading || isFetchFinance || isFetchDevelopment
      || isFetchCreative || isFetchProduction || isFetchDeletedUsers
  );

  if (isFirstRender.current) {
    const showSpinner = isLoading();

    if (showSpinner) {
      return <Spinner animation="grow" />;
    }

    isFirstRender.current = false;
  }

  return (
    <>
      <div className={classes.teams__wrapper}>
        <PageTitle>Сотрудники</PageTitle>

        {(user.user.role.id === 0 || user.user.role.id === 1) && (
          <SuccessBtn
            text="Добавить сотрудника"
            style={{ padding: '3px 15px', fontSize: 13 }}
            onClick={openModalHandler}
          />
        )}
      </div>
      {/* Boss */}
      {user.department === 3 ? (
        <>
          <NameFilter
            click={click}
            list={allUsers}
            type="staff"
            disabled={isLoading()}
          />

          <div
            className={classes.department}
            style={{ marginTop: 0 }}
            onClick={() => setActiveMarketing(!activeMarketing)}
          >
            Рекламный отдел
            <DepartmentAccordionArrow isActive={activeMarketing && managerList.length > 0} />
          </div>
          <CSSTransition
            in={activeMarketing && managerList.length > 0}
            timeout={600}
            classNames="department-accordion"
            appear
            unmountOnExit
          >
            <div>
              {managerList.map((teamElement) => (
                <UsersElement
                  key={teamElement.id}
                  array={teamElement.team}
                  text={teamElement.name}
                  update={update}
                  setUpdate={setUpdate}
                />
              ))}
            </div>
          </CSSTransition>

          <div className={classes.department} onClick={() => setActiveLawyers(!activeLawyers)}>
            Юридический отдел
            <DepartmentAccordionArrow isActive={activeLawyers && (lawyers.length > 0
            || docManagers.length > 0)}
            />
          </div>
          <CSSTransition
            in={activeLawyers}
            timeout={400}
            classNames="department-accordion"
            appear
            unmountOnExit
          >
            <div>
              {lawyers.map((teamElement) => (
                <UsersElement
                  key={teamElement.id}
                  array={teamElement.team}
                  text={teamElement.name}
                  update={update}
                  setUpdate={setUpdate}
                />
              ))}
              {docManagers.map((teamElement) => (
                <UsersElement
                  key={teamElement.id}
                  array={teamElement.team}
                  text={teamElement.name}
                  update={update}
                  setUpdate={setUpdate}
                />
              ))}
            </div>
          </CSSTransition>

          <div className={classes.department} onClick={() => setActiveFinance(!activeFinance)}>
            Финансовый отдел
            <DepartmentAccordionArrow isActive={activeFinance && finance.length > 0} />
          </div>
          <CSSTransition
            in={activeFinance}
            timeout={400}
            classNames="department-accordion"
            appear
            unmountOnExit
          >
            <div>
              {finance.map((teamElement) => (
                <UsersElement
                  key={teamElement.id}
                  array={teamElement.team}
                  text={teamElement.name}
                  update={update}
                  setUpdate={setUpdate}
                />
              ))}
            </div>
          </CSSTransition>

          <div
            className={classes.department}
            onClick={() => setActiveDevelopment(!activeDevelopment)}
          >
            Отдел развития
            <DepartmentAccordionArrow isActive={activeDevelopment && development.length > 0} />
          </div>
          <CSSTransition
            in={activeDevelopment}
            timeout={400}
            classNames="department-accordion"
            appear
            unmountOnExit
          >
            <div>
              {development.map((teamElement) => (
                <UsersElement
                  key={teamElement.id}
                  array={teamElement.team}
                  text={teamElement.name}
                  update={update}
                  setUpdate={setUpdate}
                />
              ))}
            </div>
          </CSSTransition>

          <div
            className={classes.department}
            onClick={() => setActiveCreative(!activeCreative)}
          >
            Отдел креатива
            <DepartmentAccordionArrow isActive={activeCreative && creative.length > 0} />
          </div>
          <CSSTransition
            in={activeCreative}
            timeout={400}
            classNames="department-accordion"
            appear
            unmountOnExit
          >
            <div>
              {creative.map((teamElement) => (
                <UsersElement
                  key={teamElement.id}
                  array={teamElement.team}
                  text={teamElement.name}
                  update={update}
                  setUpdate={setUpdate}
                />
              ))}
            </div>
          </CSSTransition>

          <div
            className={classes.department}
            onClick={() => setActiveProduction(!activeProduction)}
          >
            Отдел продакшна
            <DepartmentAccordionArrow isActive={activeProduction && production.length > 0} />
          </div>
          <CSSTransition
            in={activeProduction}
            timeout={400}
            classNames="department-accordion"
            appear
            unmountOnExit
          >
            <div>
              {production.map((teamElement) => (
                <UsersElement
                  key={teamElement.id}
                  array={teamElement.team}
                  text={teamElement.name}
                  update={update}
                  setUpdate={setUpdate}
                />
              ))}
            </div>
          </CSSTransition>

          <div
            className={classes.department}
            onClick={() => setActiveDeleteUsers(!activeDeleteUsers)}
          >
            Удалённые сотрудники
            <DepartmentAccordionArrow isActive={activeDeleteUsers && DeletedUsers.length > 0} />
          </div>
          <CSSTransition
            in={activeDeleteUsers && DeletedUsers.length > 0}
            timeout={400}
            classNames="department-accordion"
            appear
            unmountOnExit
          >

            <UsersElement
              array={DeletedUsers}
              text="Удалённые сотрудники"
              update={update}
              setUpdate={setUpdate}
            />
          </CSSTransition>
        </>
      ) : (
        /* Staff */
        <>
          <NameFilter
            click={click}
            list={allUsers}
            type="staff"
            disabled={isLoading()}
          />

          <div className={classes.department} style={{ marginTop: 0 }}>
            Рекламный отдел
          </div>

          {managerList.map((teamElement) => (
            <UsersElement
              key={teamElement.id}
              array={teamElement.team}
              text={teamElement.name}
              update={update}
              setUpdate={setUpdate}
            />
          ))}

          <div className={classes.department}>Юридический отдел</div>
          {lawyers.map((teamElement) => (
            <UsersElement
              key={teamElement.id}
              array={teamElement.team}
              text={teamElement.name}
              update={update}
              setUpdate={setUpdate}
            />
          ))}
          {docManagers.map((teamElement) => (
            <UsersElement
              key={teamElement.id}
              array={teamElement.team}
              text={teamElement.name}
              update={update}
              setUpdate={setUpdate}
            />
          ))}

          <div className={classes.department}>Финансовый отдел</div>

          <>
            {finance.map((teamElement) => (
              <UsersElement
                key={teamElement.id}
                array={teamElement.team}
                text={teamElement.name}
                update={update}
                setUpdate={setUpdate}
              />
            ))}
          </>

          <div className={classes.department}>Отдел развития</div>

          <>
            {development.map((teamElement) => (
              <UsersElement
                key={teamElement.id}
                array={teamElement.team}
                text={teamElement.name}
                update={update}
                setUpdate={setUpdate}
              />
            ))}
          </>

          <div className={classes.department}>Отдел креатива</div>

          <>
            {creative.map((teamElement) => (
              <UsersElement
                key={teamElement.id}
                array={teamElement.team}
                text={teamElement.name}
                update={update}
                setUpdate={setUpdate}
              />
            ))}
          </>

          <div className={classes.department}>Отдел продакшна</div>

          <>
            {production.map((teamElement) => (
              <UsersElement
                key={teamElement.id}
                array={teamElement.team}
                text={teamElement.name}
                update={update}
                setUpdate={setUpdate}
              />
            ))}
          </>

          <div className={classes.department}>Удалённые сотрудники</div>

          <UsersElement
            array={DeletedUsers}
            text="Удалённые сотрудники"
            update={update}
            setUpdate={setUpdate}
          />
        </>
      )}

      <ModalCreateStaff
        show={modal._modalCreateStaffVisible}
        onHide={() => modal.setModalCreateStaffVisible(false)}
        props={modal._modalCreateStaffProps}
      />
    </>
  );
});

export default memo(TeamsPage);
