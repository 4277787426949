import React from 'react';

import classes from './Fieldset.module.scss';

function Fieldset(props) {
  const {
    children, horizontal, textarea, disabled, className,
  } = props;
  return (
    <fieldset
      className={[
        classes.fieldset,
        className,
        horizontal && classes.horizontal,
        textarea && classes.textareaWrapper,
        disabled && classes.disabled,
      ].join(' ')}
    >
      {children}
    </fieldset>
  );
}

export default Fieldset;
