import React, { useState } from 'react';
import { Popover } from '@mui/material';

import image from '../../../assets/icons/Btns/AddBtn.svg';
import whiteButton from '../../../assets/icons/Btns/AddWhiteButton.svg';

import classes from './Btns.module.scss';

function NewAddBtn(props) {
  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ

  const {
    text, onClick, className, type, isPopoverText,
  } = props;

  return (
    <div
      className={[classes.newAddBtn__container, className].join(' ')}
      onClick={onClick}
    >
      <img
        className={classes.newAddBtn__img}
        src={type !== 'white' ? image : whiteButton}
        alt=""
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      {text}
      {isPopoverText ? (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div className={classes.popoverText}>{isPopoverText}</div>
        </Popover>
      ) : null}
    </div>
  );
}

export default NewAddBtn;
