// @ts-nocheck
import React, { useEffect, useState } from 'react';

import NewAddBtn from 'components/UI/Btns/NewAddBtn';
import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import ContractElement from 'components/UI/Modals/ModalCreateContract/components/ContractElement';
import SearchBar from 'components/UI/SearchBar/SearchBar';
import Section from 'components/UI/Section/Section';

import StatusDropdown from '../../../UI/StatusDropdown/StatusDropdown';

import { useStateNew } from 'hooks/useStateNew';

import { ONE_BLOGGER_ROUTE, ONE_CLIENT_ROUTE } from 'utils/consts';

import ContractItem from './ContractItem';

import classes from '../ProjectPage.module.scss';

const defaultInfo = {
  status: { id: null, name: '' },
};

function CommonInfo(props) {
  const {
    projectCard,
    project,
    managerList,
    responsibleList,
    directorList,
    updateContract,
    clientsList,
    contracts,
    tableStyle,
  } = props;
  const [activeBtn, setActiveBtn] = useState(false);

  const [info, setInfo] = useState(defaultInfo);

  const [
    director,
    setDirector,
    changeDirector,
    addDirector,
    removeDirector,
    checkDirector,
  ] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
    },
  ]);

  const [
    manager,
    setManager,
    changeManager,
    addManager,
    removeManager,
    checkManager,
  ] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
    },
  ]);

  const [
    responsible,
    setResponsible,
    changeResponsible,
    addResponsible,,
    checkResponsible,
    removeResponsible,
  ] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
    },
  ]);

  const [client, setClient, changeClient] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
      type: { id: null, name: '' },
    },
  ]);

  useEffect(() => {
    setInfo({
      status: project.status,
    });

    let tempArray = [];
    project.director.forEach((element) => {
      tempArray.push({
        fieldId: Date.now() + element.id,
        id: element.id,
        name: element.fullname,
      });
    });
    setDirector(tempArray);

    tempArray = [];
    project.responsible.forEach((element) => {
      tempArray.push({
        fieldId: Date.now() + element.id,
        id: element.id,
        name: element.fullname,
      });
    });
    setResponsible(tempArray);

    tempArray = [];
    project.manager.forEach((element) => {
      tempArray.push({
        fieldId: Date.now() + element.id,
        id: element.id,
        name: element.fullname,
      });
    });
    setManager(tempArray);

    setClient([{ ...project.client, fieldId: Date.now() }]);
  }, [project]);

  const click = () => {
    setActiveBtn(true);
  };

  const deleteDirector = (id) => {
    removeDirector(id);
    click();
  };

  const deleteResponsible = (id) => {
    removeResponsible(id);
    click();
  };

  const deleteManager = (id) => {
    removeManager(id);
    click();
  };

  const saveCommonHandler = () => {
    const data = {
      client: { id: client[0].id },
    };

    const directorArray = [];
    director.forEach((element) => {
      if (element.id) {
        directorArray.push({ id: element.id });
      }
    });
    data.director = directorArray;

    const responsibleArray = [];

    responsible.forEach((element) => {
      if (element.id) {
        responsibleArray.push({ id: element.id });
      }
    });
    data.responsible = responsibleArray;

    const managerArray = [];
    manager.forEach((element) => {
      if (element.id) {
        managerArray.push({ id: element.id });
      }
    });
    data.manager = managerArray;

    updateContract(data, 'commonInfo');
    setActiveBtn(false);
  };

  const cancel = () => {
    setInfo({
      status: project.status,
    });

    let tempArray = [];
    project.director.forEach((element) => {
      tempArray.push({
        fieldId: Date.now() + element.id,
        id: element.id,
        name: element.fullname,
      });
    });
    setDirector(tempArray);

    tempArray = [];
    project.responsible.forEach((element) => {
      tempArray.push({
        fieldId: Date.now() + element.id,
        id: element.id,
        name: element.fullname,
      });
    });
    setResponsible(tempArray);

    tempArray = [];
    project.manager.forEach((element) => {
      tempArray.push({
        fieldId: Date.now() + element.id,
        id: element.id,
        name: element.fullname,
      });
    });
    setManager(tempArray);

    setClient([{ ...project.client, fieldId: Date.now() }]);

    setActiveBtn(false);
  };

  let link = `${ONE_BLOGGER_ROUTE}/${project.client.id}`;

  if (project.client.type.id === 0
    || project.client.type.id === 1
     || project.client.type.id === 2) {
    link = `${ONE_CLIENT_ROUTE}/${project.client.id}`;
  }

  return (
    <div style={tableStyle}>
      <Section title="Общая информация">
        <div className={classes.wrapper}>
          <div>
            <ContractElement
              text="Заказчик"
              textStyle={{ minWidth: 220, width: 220 }}
            >
              <SearchBar
                list={clientsList}
                text={client[0]}
                setText={changeClient}
                onClick={click}
                style={{ width: '100%' }}
                required
                type="client"
              />
            </ContractElement>

            <ContractElement
              text="Руководитель"
              textClassName={
              director.length === 1
                ? classes.oneResponsible__wrapper
                : classes.moreResponsible__wrapper
            }
            >
              <div className={classes.searchBar__container}>
                {director.map((element) => (
                  <SearchBar
                    key={element.fieldId}
                    list={directorList}
                    text={element}
                    setText={changeDirector}
                    deleteElement={deleteDirector}
                    checkResponsible={checkDirector}
                    onClick={click}
                    required
                    type="staff"
                  />
                ))}

                <NewAddBtn text="Добавить" onClick={addDirector} />
              </div>
            </ContractElement>

            <ContractElement
              text="Менеджер"
              textClassName={
              manager.length === 1
                ? classes.oneResponsible__wrapper
                : classes.moreResponsible__wrapper
            }
            >
              <div className={classes.searchBar__container}>
                {manager.map((element) => (
                  <SearchBar
                    key={element.fieldId}
                    list={managerList}
                    text={element}
                    setText={changeManager}
                    deleteElement={deleteManager}
                    checkResponsible={checkManager}
                    onClick={click}
                    required
                    type="staff"
                  />
                ))}

                <NewAddBtn text="Добавить" onClick={addManager} />
              </div>
            </ContractElement>

            <ContractElement
              text="Ответственный"
              textClassName={
              responsible.length === 1
                ? classes.oneResponsible__wrapper
                : classes.moreResponsible__wrapper
            }
            >
              <div className={classes.searchBar__container}>
                {responsible.map((element) => (
                  <SearchBar
                    key={element.fieldId}
                    list={responsibleList}
                    text={element}
                    setText={changeResponsible}
                    deleteElement={deleteResponsible}
                    checkResponsible={checkResponsible}
                    onClick={click}
                    required
                    type="staff"
                  />
                ))}

                <NewAddBtn text="Добавить" onClick={addResponsible} />
              </div>
            </ContractElement>

            <ContractElement
              text="Статус проекта"
              textStyle={{ minWidth: 220, width: 220 }}
            >
              <StatusDropdown
                id={project.id}
                status={project.status.id}
                manager
              />
            </ContractElement>

            {contracts.map((contract) => (
              <ContractItem key={contract.id} element={contract} />
            ))}

            {activeBtn && (
            <div className={classes.btn__wrapper}>
              <SuccessBtn
                text="Сохранить"
                style={{
                  padding: '2px 20px',
                  fontSize: 13,
                }}
                onClick={saveCommonHandler}
              />

              <SuccessBtn
                text="Отменить"
                type="white"
                style={{
                  padding: '2px 20px',
                  fontSize: 13,
                }}
                onClick={cancel}
              />
            </div>
            )}
          </div>

          <SuccessBtn
            text="Перейти в карточку заказчика"
            type="white"
            style={{ padding: '2px 6px', fontSize: '11px', marginTop: 4 }}
            link={link}
          />
        </div>
      </Section>
    </div>
  );
}

export default CommonInfo;
