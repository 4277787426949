// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { Context } from 'index';

import NewAddBtn from 'components/UI/Btns/NewAddBtn';
import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import InputElement from 'components/UI/InputElements/InputElement';
import ContractElement from 'components/UI/Modals/ModalCreateContract/components/ContractElement';
import SearchBar from 'components/UI/SearchBar/SearchBar';

import { useStateNew } from 'hooks/useStateNew';

import { CONTACT_BLOGGER_FIELDS, CONTACT_FIELDS } from '../Fields';

import classes from '../ContactInfo.module.css';

function OneContactInfo(props) {
  const {
    contact,
    updateContacts,
    save,
    cancel,
    addContact,
    removeContact,
    inModal,
    noClose,
    className,
    index,
    length,
    blogger,
    staffList,
  } = props;
  const { modal } = useContext(Context);

  const [activeBtn, setActiveBtn] = useState(false);

  const [
    responsible,
    setResponsible,
    changeResponsible,
    addResponsible,
    removeResponsible,
    checkResponsible,
  ] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
    },
  ]);

  useEffect(() => {
    if (contact?.responsible?.length > 0) {
      const tempArray = [];
      contact.responsible.forEach((element) => {
        tempArray.push({
          fieldId: Date.now() + element.id,
          id: element.id,
          name: element?.fullname || element?.name,
        });
      });
      setResponsible(tempArray);
    }
  }, []);

  useEffect(() => {
    updateContacts(contact?.id, 'responsible', responsible);
  }, [responsible]);

  const click = () => {
    setActiveBtn(true);
  };

  const deleteResponsibleHandler = (id) => {
    removeResponsible(id);
    click();
  };
  const addResponsibleHandler = () => {
    addResponsible();
    click();
  };

  const saveHandler = (contactInfo) => {
    setActiveBtn(false);
    save(contactInfo);
  };

  const cancelHandler = () => {
    setActiveBtn(false);
    cancel('contacts', contact);

    if (contact?.responsible?.length > 0) {
      const tempArray = [];
      contact.responsible.forEach((element) => {
        tempArray.push({
          fieldId: Date.now() + element.id,
          id: element.id,
          name: element.fullname,
        });
      });
      setResponsible(tempArray);
    } else {
      setResponsible([
        {
          fieldId: Date.now(),
          id: null,
          name: '',
        },
      ]);
    }
  };

  const openModalHandler = (contactId) => {
    setActiveBtn(false);
    modal.setModalConfirmVisible(true);
    modal.setModalConfirmProps({
      text: 'Вы хотите удалить контакт?',
      click: () => removeContact(contactId),
    });
  };

  return (
    <div
      className={[classes.oneContact__container, className].join(' ')}
      style={index === 0 ? { margin: 0 } : {}}
    >
      <div className={!noClose ? classes.wrapper : classes.wrapper__noWidth}>
        {(blogger ? CONTACT_BLOGGER_FIELDS : CONTACT_FIELDS).map((field) => (
          <div key={field.id}>
            {field.autocomplete ? (
              <ContractElement
                text="Менеджер"
                elementClassName={classes.responsible__element}
                textClassName={
                    responsible.length === 1
                      ? classes.oneResponsible__wrapper
                      : classes.moreResponsible__wrapper
                  }
              >
                <div className={classes.searchBar__container}>
                  {responsible.map((element) => (
                    <SearchBar
                      key={element.fieldId}
                      list={staffList}
                      text={element}
                      setText={changeResponsible}
                      deleteElement={deleteResponsibleHandler}
                      checkResponsible={checkResponsible}
                      onClick={() => setActiveBtn(true)}
                      required
                      type="staff"
                    />
                  ))}

                  <NewAddBtn
                    text="Добавить"
                    onClick={addResponsibleHandler}
                  />
                </div>
              </ContractElement>
            ) : (
              <InputElement
                text={field.label}
                type={field.type}
                textStyle={{ minWidth: 230 }}
                value={contact[field.id]}
                onChange={(event) => updateContacts(
                  contact.id,
                  field.id,
                  field.date ? event : event.target.value,
                )}
                onClick={click}
                deleteBtn={(field.delete && activeBtn) || (field.delete && inModal)}
                deleteHandler={() => openModalHandler(contact.id)}
                date={field.date}
              />
            )}
          </div>
        ))}

        {index === length - 1 && (
          <div className={classes.addBtn__container}>
            <NewAddBtn text="Добавить контакт" onClick={() => addContact()} />
          </div>
        )}

        {save && activeBtn && (
          <div className={classes.btn__wrapper}>
            <SuccessBtn
              text="Сохранить"
              style={{
                padding: '2px 20px',
                fontSize: 13,
              }}
              onClick={() => saveHandler(contact)}
            />

            <SuccessBtn
              text="Отменить"
              type="white"
              style={{
                padding: '2px 20px',
                fontSize: 13,
              }}
              onClick={() => cancelHandler()}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default OneContactInfo;
