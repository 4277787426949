// @ts-nocheck
// UserStatusDropdown.jsx

import React, { memo } from 'react';
import { Dropdown } from 'react-bootstrap';

import { stylesDropDownMenu } from '../consts/consts';

import classes from './UserStatusDropdown.module.scss';

function UserStatusDropdown(props) {
  const {
    staffInfo, setStaffInfo, clickHandler, element,
  } = props;

  const changeStatusHandler = (newStatusID, newStatusName) => {
    const data = {
      id: newStatusID,
      name: newStatusName,
    };
    setStaffInfo({ ...staffInfo, status: { ...data } });
    clickHandler();
  };
  let dropDownToggleStyles;

  switch (staffInfo?.status?.name || element?.status?.name) {
    case 'Оффлайн':
      dropDownToggleStyles = classes.offline;
      break;
    case 'Онлайн':
      dropDownToggleStyles = classes.online;
      break;
    case 'Отпуск':
      dropDownToggleStyles = classes.vacation;
      break;
    case 'Больничный':
      dropDownToggleStyles = classes.sickLeave;
      break;
    default:
      dropDownToggleStyles = undefined;
  }

  return (
    <div className={classes.user__status}>
      <Dropdown>
        <Dropdown.Toggle className={`${classes.status__button} ${dropDownToggleStyles}`}>
          {staffInfo ? staffInfo?.status?.name : element?.status?.name}
        </Dropdown.Toggle>
        <Dropdown.Menu style={stylesDropDownMenu}>
          <Dropdown.Item
            className={`${classes.status__button} ${classes.online} ${classes.status__option__button}`}
            onClick={() => changeStatusHandler(1, 'Онлайн')}
          >
            Онлайн
          </Dropdown.Item>
          <Dropdown.Item
            className={`${classes.status__button} ${classes.offline} ${classes.status__option__button}`}
            onClick={() => changeStatusHandler(0, 'Оффлайн')}
          >
            Оффлайн
          </Dropdown.Item>
          <Dropdown.Item
            className={`${classes.status__button} ${classes.vacation} ${classes.status__option__button}`}
            onClick={() => changeStatusHandler(2, 'Отпуск')}
          >
            Отпуск
          </Dropdown.Item>
          <Dropdown.Item
            className={`${classes.status__button} ${classes.sickLeave} ${classes.status__option__button}`}
            onClick={() => changeStatusHandler(3, 'Больничный')}
          >
            Больничный
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default memo(UserStatusDropdown);
