import React from 'react';
import { Helmet } from 'react-helmet';

import CostsFinance from 'components/Finance/CostsFinance/CostsFinance';

function CostsPage() {
  return (
    <section className="Mysection">
      <Helmet>
        <title>Заявки на оплату | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <CostsFinance />
    </section>
  );
}

export default CostsPage;
