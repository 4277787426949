export const useAdd = () => {
  const add = (array, setArray, setArray2, element) => {
    if (array.length === 0) {
      setArray();
    } else {
      let flag;

      array.forEach((item) => {
        if (item.id === element.id) {
          flag = true;
        }
      });

      if (!flag) {
        setArray2();
      }
    }
  };
  return [add];
};
