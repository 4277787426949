import React from 'react';

import classes from './PageTitle.module.scss';

function PageTitle(props) {
  const {
    className, children, button, style,
  } = props;

  return (
    <h1 className={[classes.title, className].join(' ')} style={style}>

      {children}

      {!!button && button}
    </h1>
  );
}

export default PageTitle;
