import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { LOGIN_ROUTE, MAIN_ROUTE } from 'utils/consts';

import { privateRoutes, publicRoutes } from '../router/Routes';

const AppRouter = observer(() => {
  const { user } = useContext(Context);

  return user.isAuth ? (
    <Routes>
      {privateRoutes.map((route) => (
        <Route path={route.path} element={<route.element />} key={route.path} />
      ))}
      {/* Куда перенаправить если указан не правильный адрес */}
      <Route path="*" element={<Navigate replace to={MAIN_ROUTE} />} />
    </Routes>
  ) : (
    <Routes>
      {publicRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={<route.element />} />
      ))}

      <Route path="*" element={<Navigate replace to={LOGIN_ROUTE} />} />
    </Routes>
  );
});

export default AppRouter;
