import React, { memo, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import image from '../../../assets/icons/Lawyers/Arrow.svg';

import classes from './MyDropdown.module.css';

const defaultLegalEntities = {
  id: null,
  legal_name: '',
  type: { id: null, name: '' },
  inn: '',
  ogrn: '',
  ogrnip: '',
  kpp: '',
  address: '',
  tax: '',
  bank_details: [{
    rc: '',
    bank: '',
    bik: '',
    kc: '',
  }],
};

function MyDropdown(props) {
  const {
    defaultText,
    selected,
    setSelected,
    className,
    classNameMenu,
    classNameItem,
    classNameDropdown,
    list,
    style,
    inputStyle,
    inputClassName,
    onClick,
    multiple,
    multipleSelect,
    disabled,
    noArrow,
    noSelection,
    transferProject,
    download,
  } = props;
  const [state, setState] = useState({ id: null, name: '' });

  useEffect(() => {
    if (selected) {
      if (selected.hasOwnProperty('label')) {
        setState({
          id: selected.id,
          name: selected.label,
        });
      } else if (
        selected.hasOwnProperty('name')
        || selected.hasOwnProperty('fieldId')
      ) {
        setState({ id: selected.id, name: selected.name });
      } else if (selected.hasOwnProperty('fullname')) {
        setState({ id: selected.id, name: selected.fullname });
      } else if (selected.hasOwnProperty('application') || selected.hasOwnProperty('export')) {
        setState({ id: null, name: '' });
      } else if (selected.hasOwnProperty('blogger')) {
        setState({ id: selected.id, name: selected.blogger.name });
      } else if (selected?.type?.name === 'СК') {
        setState({
          id: selected.id,
          name: `${selected.contractor_legal_entity.legal_name} / ${selected.client_legal_entity.legal_name}`,
        });
      } else if (selected.hasOwnProperty('bank')) {
        setState({ id: selected.id, name: selected.bank });
      } else if (selected?.hasOwnProperty('team')) {
        setState({ id: selected.id, name: selected.legal_name });
      } else if (transferProject) {
        setState({ id: selected.id, name: selected.name });
      } else if (selected?.hasOwnProperty('email')) {
        setState({ id: selected.id, name: selected.email });
      } else {
        setState({ id: selected.id, name: selected.legal_name });
      }
    }
  }, [selected]);

  const updateSelected = (element) => {
    if (selected?.id === element?.id && !noSelection) {
      if (element.type?.name === 'СК' || element.type?.name === 'БЛ') {
        setSelected({
          id: null,
          lawyer: [],
          client_legal_entity: {},
          contractor_legal_entity: {},
          number: '',
          file_links: '',
          status: {},
          closed_status: {},
          payment: {},
        });
        return;
      }

      if (selected.hasOwnProperty('label')) {
        setSelected({ id: null, label: '' });
        return;
      }

      if (
        selected.hasOwnProperty('name')
        || selected.hasOwnProperty('fieldId')
        || selected.hasOwnProperty('blogger')
      ) {
        setSelected({ id: null, name: '' });
        return;
      }

      if (selected.hasOwnProperty('fullname')) {
        setSelected({ id: null, fullname: '' });
        return;
      }

      if (selected.hasOwnProperty('legal_name')) {
        setSelected(defaultLegalEntities);
      }

      if (transferProject) {
        setSelected([]);
        setSelected(element);
      }
    } else {
      setSelected(element);
    }
  };

  return (
    <Dropdown className={['my__dropdown', classNameDropdown].join(' ')} onClick={onClick}>
      <Dropdown.Toggle
        variant="Warning"
        className={`my__dropdown__toggle ${className}`}
        style={
          multiple
            ? { padding: '8px 4px 8px 4px', justifyContent: 'flex-end' }
            : style
        }
        disabled={disabled}
      >
        <span style={inputStyle} className={inputClassName}>
          {multiple ? '' : state.name || defaultText || 'Не выбрано'}
        </span>

        {!noArrow && <img className={classes.arrow__btn} src={image} alt="" />}
      </Dropdown.Toggle>

      <Dropdown.Menu
        className={`my__dropdown__inner ${classNameMenu}`}
        style={multiple ? { width: 197 } : {}}
      >
        {list
          && list.map((listElement) => (
            <Dropdown.Item
              key={listElement.id}
              onClick={() => (multiple
                ? multipleSelect(listElement)
                : updateSelected(listElement))}
              className={`my__dropdown__item ${classNameItem}`}
              download={download}
              href={listElement.exportLink}
            >

              {listElement.name
                || listElement.fullname
                || listElement.legal_name
                || listElement.label
                || listElement?.blogger?.name
                || listElement.application
                || listElement.bank
                || listElement.email
                || listElement.export}

              {listElement?.type?.name === 'СК' && (
                `${listElement.contractor_legal_entity.legal_name} / ${listElement.client_legal_entity.legal_name} `
              )}

            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default memo(MyDropdown);
