// @ts-nocheck
import { makeAutoObservable } from 'mobx';

export default class RegistryFinanceStore {
  constructor() {
    // Состояние для проектов на главной странице финансов
    this._projects = [];
    this._projectsTotalCount = 0;
    this._projectsPage = 1;
    this._projectsLimit = 15;
    this._projectsFilter = undefined;

    // Состояние для страницы заявки на оплату
    this._costs = [];
    this._costsTotalCount = 0;
    this._costsPage = 1;
    this._costsLimit = 15;
    this._costsFilter = undefined;
    makeAutoObservable(this);
  }

  setProjects(projects, update) {
    if (update) {
      return (this._projects = projects);
    }
    if (this._projectsPage === 1) {
      return (this._projects = projects);
    }

    projects.forEach((project) => this._projects.push(project));
  }

  setProjectsTotalCount(count) {
    this._projectsTotalCount = count;
  }

  setProjectsPage(page) {
    this._projectsPage = page;
  }

  setProjectsFilter(filter) {
    this._projectsFilter = filter;
  }

  get projects() {
    return this._projects;
  }

  get projectsTotalCount() {
    return this._projectsTotalCount;
  }

  get projectsPage() {
    return this._projectsPage;
  }

  get projectsLimit() {
    return this._projectsLimit;
  }

  get projectsFilter() {
    return this._projectsFilter;
  }

  // Costs

  setCosts(costs, update) {
    if (update) {
      return (this._costs = costs);
    }
    if (this._costsPage === 1) {
      return (this._costs = costs);
    }

    costs.forEach((cost) => this._costs.push(cost));
  }

  setCostsTotalCount(count) {
    this._costsTotalCount = count;
  }

  setCostsPage(page) {
    this._costsPage = page;
  }

  setCostsFilter(filter) {
    this._costsFilter = filter;
  }

  get costs() {
    return this._costs;
  }

  get costsTotalCount() {
    return this._costsTotalCount;
  }

  get costsPage() {
    return this._costsPage;
  }

  get costsLimit() {
    return this._costsLimit;
  }

  get costsFilter() {
    return this._costsFilter;
  }
}
