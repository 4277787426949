// @ts-nocheck

import React, { useEffect, useState } from 'react';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import InputElement from 'components/UI/InputElements/InputElement';
import ContractElement from 'components/UI/Modals/ModalCreateContract/components/ContractElement';
import PaymentDateNoTime from 'components/UI/Modals/ModalCreateContract/components/PaymentDateNoTime';
import LawyerSection from 'components/UI/Section/LawyerSection';

import { useStateNew2 } from 'hooks/useStateNew2';

import { getDate } from 'utils/getDate';
import { getSum, numberToSum } from 'utils/getSums';
import { isValidURL } from 'utils/isValidUrl';
import { getSumRuble } from '../../../../utils/getSums';

import classes from '../ProjectPage.module.scss';

function ContractDetails(props) {
  const {
    project,
    save,
    contractType,
    selectedContractorLegalEntity,
  } = props;

  const [activeBtn, setActiveBtn] = useState(false);

  const [paymentDates, setPaymentDates, updateDate] = useStateNew2([
    { reactId: Date.now(), date: null, sum: '' },
  ]);

  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const [totalSum, setTotalSum] = useState('');

  const [fileLinks, setFileLinks] = useState('');

  const clickHandler = () => {
    setActiveBtn(true);
  };

  const cancelHandler = () => {
    const {
      payment,
      date_start,
      date_end,
      file_links,

    } = project;

    if (!project.id) {
      return;
    }

    setActiveBtn(false);

    if (payment.payment_dates.length > 0) {
      const dates = payment.payment_dates.map((date) => ({ ...date, sum: numberToSum(date.sum) }));
      setPaymentDates(dates);
    } else {
      setPaymentDates([{ reactId: Date.now(), date: '', sum: '' }]);
    }

    setDateStart(date_start);
    setDateEnd(date_end);

    setTotalSum(numberToSum(payment.total_sum));

    setFileLinks(file_links);
  };

  useEffect(() => {
    if (project.id) {
      if (project.payment.payment_dates.length > 0) {
        const dates = [];
        project.payment.payment_dates
          .forEach((date) => dates.push({ ...date, sum: numberToSum(date.sum) }));

        setPaymentDates(dates);
      } else {
        setPaymentDates([{ reactId: Date.now(), date: '', sum: '' }]);
      }
      setDateStart(project.date_start);
      setDateEnd(project.date_end);

      setTotalSum(numberToSum(project.payment.total_sum));

      setFileLinks(project.file_links);
    }
  }, [project]);

  const saveDetailesHandler = () => {
    const sentPriceDate = [];

    paymentDates.forEach((element) => {
      if (element?.id >= 0) {
        sentPriceDate.push({
          id: element.id,
          date: element.date ? getDate(element.date) : '',
          sum: getSum(element.sum),
        });
      } else {
        sentPriceDate.push({
          date: element.date ? getDate(element.date) : '',
          sum: getSum(element.sum),
        });
      }
    });

    const data = {
      total_sum: getSum(totalSum),
      payments: sentPriceDate,
      date_start: dateStart ? getDate(dateStart) : '',
      date_end: dateEnd ? getDate(dateEnd) : dateEnd,
      file_links: fileLinks,
    };

    save(data, 'contract_details').then(() => {
      setActiveBtn(false);
    });
  };

  return (
    <LawyerSection
      title="Детали договора"
      defaultActive
      contentStyle={{ overflow: 'hidden' }}
    >

      <div className={classes.wrapper}>

        {contractType !== 4 && (
          <InputElement
            text="Сумма договора"
            textStyle={{ minWidth: 230 }}
            onClick={clickHandler}
            value={totalSum}
            onChange={(event) => setTotalSum(getSumRuble(event.target.value))}
            setValue={setTotalSum}
            type="number"
            disabled
          />

        )}

        {contractType === 0 && (
          <InputElement
            text="НДС"
            textStyle={{ minWidth: 230 }}
            value={numberToSum(project.payment.nds_sum, true)}
            placeholder="0,00 Р"
            onClick={clickHandler}
            disabled
          />

        )}

        {contractType === 1 && (
          <>
            <InputElement
              text="Сумма АК с налогом"
              textStyle={{ minWidth: 230 }}
              value={numberToSum(project.payment.ak_sum, true)}
              onClick={clickHandler}
              disabled
            />

            <InputElement
              text="Налог на АК"
              textStyle={{ minWidth: 230 }}
              value={numberToSum(
                project.payment.ak_sum
                - project.payment.ak_sum * selectedContractorLegalEntity.tax,
              )}
              onClick={clickHandler}
              placeholder="0,00 Р"
              disabled
            />

          </>
        )}

        {contractType === 2 && (
          <InputElement
            text="НДС"
            textStyle={{ minWidth: 230 }}
            value={
                numberToSum(project.payment.nds_sum, true)
            }
            onClick={clickHandler}
            placeholder="0,00 Р"
            disabled
          />

        )}

        {contractType === 4 && (
          <>
            <InputElement
              text="Сумма СК"
              value={numberToSum(project.payment.total_sum)}
              textStyle={{ minWidth: 230 }}
              onClick={clickHandler}
              disabled
            />

            <InputElement
              text="НДС от СК"
              textStyle={{ minWidth: 230 }}
              value={numberToSum(project.payment.nds_sum, true)}
              placeholder="0,00 Р"
              onClick={clickHandler}
              disabled
            />

          </>
        )}

        {contractType !== 3 && (
          <ContractElement
            text={
              contractType === 0 || contractType === 1
                ? 'Порядок оплаты от заказчика'
                : 'Порядок оплаты исполнителю'
            }
            textStyle={{ minWidth: 230, width: 230, height: 32 }}
          >
            <div className={classes.payment__wrapper}>
              {paymentDates.map((date) => (
                <PaymentDateNoTime
                  key={date.id || date.reactId}
                  id={date.id}
                  element={date}
                  changeDate={updateDate}
                  onClick={clickHandler}
                />
              ))}
            </div>
          </ContractElement>
        )}

        <ContractElement
          text="Срок оказания услуг"
          textStyle={{
            minWidth: 230,
            width: 230,
            height: 32,
          }}
        >
          <div className={classes.date__wrapper}>
            <InputElement
              type="date"
              value={dateStart}
              setValue={setDateStart}
              date
              onClick={clickHandler}
              style={{ margin: 0 }}
              disabled
            />

            <InputElement
              type="date"
              value={dateEnd}
              setValue={setDateEnd}
              date
              onClick={clickHandler}
              disabled
            />
          </div>

        </ContractElement>

        {(contractType === 0 || contractType === 1) && (
          <>
            <InputElement
              text="Репост"
              textStyle={{ minWidth: 230 }}
              value={project.is_repost ? 'Да' : 'Нет'}
              disabled
              onClick={clickHandler}
            />

            {project.is_repost && (
              <InputElement
                style={{ marginLeft: 230 }}
                placeholder="Комментарий к репосту"
                value={project.repost_comment}
                disabled
                onClick={clickHandler}
              />

            )}

            <InputElement
              text="Возможен ли кейс"
              textStyle={{ minWidth: 230 }}
              value={project.is_case ? 'Да' : 'Нет'}
              disabled
              onClick={clickHandler}
            />

            {project.is_case && (
              <InputElement
                style={{ marginLeft: 230 }}
                placeholder="Комментарий к кейсу"
                value={project.case_comment}
                disabled
                onClick={clickHandler}
              />

            )}

          </>
        )}

        {contractType === 2 && (
          <>
            {/* <InputElement
              text="Показываем клиенту баинг"
              textStyle={{ minWidth: 230 }}
              value={project.payment.show_client ? 'Да' : 'Нет'}
              disabled
              onClick={clickHandler}
            /> */}

            {/* <InputElement
              text="Сумма договора для клиента"
              textStyle={{ minWidth: 230 }}
              value={numberToSum(project.payment.client_sum)}
              disabled
              onClick={clickHandler}
            /> */}

          </>
        )}

        {/* <InputElement
          text="Статус договора"
          value={status}
          setValue={(element) => {
            setStatus(element);
            setStatusDate(Date.now());
          }}
          dropdown={statusList}
          textStyle={{ minWidth: 230 }}
          onClick={clickHandler}
        />

        <InputElement
          text="Дата статуса договора"
          type="date"
          value={statusDate}
          setValue={setStatusDate}
          textStyle={{ minWidth: 230 }}
          date
          onClick={clickHandler}
        />

        <InputElement
          text="Статус закрывающих документов"
          value={closedStatus}
          setValue={(element) => {
            setClosedStatus(element);
            setClosedStatusDate(Date.now());
          }}
          dropdown={closedStatusList}
          textStyle={{ minWidth: 230 }}
          onClick={clickHandler}
        />

        <InputElement
          type="date"
          text="Дата статуса закрывающих документов"
          value={closedStatusDate}
          setValue={setClosedStatusDate}
          textStyle={{ minWidth: 230 }}
          date
          onClick={clickHandler}
        />

        <InputElement
          text="Статус счета"
          value={billStatus}
          setValue={(element) => {
            setBillStatus(element);
            setBillStatusDate(Date.now());
          }}
          dropdown={billStatusList}
          textStyle={{ minWidth: 230 }}
          onClick={clickHandler}
        />

        <InputElement
          type="date"
          text="Дата статуса счета"
          value={billStatusDate}
          setValue={setBillStatusDate}
          textStyle={{ minWidth: 230 }}
          date
          onClick={clickHandler}
        /> */}

        {
       (contractType !== 0 && contractType !== 1 && contractType !== 2) && (
         <InputElement
           text="Ссылка на договор и акт"
           placeholder="Введите текст"
           textStyle={{ minWidth: 230 }}
           value={fileLinks}
           setValue={setFileLinks}
           onClick={clickHandler}
           isLink={isValidURL(fileLinks)}
         />
       )
        }

        {activeBtn && (
          <div className={classes.btn__wrapper}>
            <SuccessBtn
              text="Сохранить"
              style={{
                padding: '2px 20px',
                fontSize: 13,
              }}
              onClick={saveDetailesHandler}
            />

            <SuccessBtn
              text="Отменить"
              type="white"
              style={{
                padding: '2px 20px',
                fontSize: 13,
              }}
              onClick={cancelHandler}
            />

          </div>

        )}
      </div>

    </LawyerSection>
  );
}

export default ContractDetails;
