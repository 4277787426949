// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

import { fetchStaffCard } from 'API/StaffAPI';
import { check } from '../API/UserAPI';

import { useFetching } from '../hooks/useFetching';
import { useLogout } from '../hooks/useLogout';

import StaffCardLawyers from '../components/Lawyers/StaffCardLawyers/StaffCardLawyers';
import StaffCardMarketing from '../components/Marketing/StaffCard/StaffCardMarketing';

function StaffCard() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [logout] = useLogout();
  const isFirstRender = useRef(true);

  const [staffInfo, setStaffInfo] = useState({});

  const [fetchCard, isCardLoading, fetchError] = useFetching(async (id) => {
    const data = await fetchStaffCard(id);
    setStaffInfo(data);
  });

  useEffect(() => {
    check()
      .then(() => {
        fetchCard(id);
      })
      .catch((error) => logout(error));
  }, []);

  if (fetchError) {
    navigate(-1);
    return <Spinner animation="grow" />;
  }

  if (isFirstRender.current) {
    if (isCardLoading) {
      return <Spinner animation="grow" />;
    }
    isFirstRender.current = false;
  }

  const departmentId = staffInfo.department.id;
  const departmentsLikeMarketing = [0, 3, 4, 5, 6];
  const departmentsLikeLawyers = [1, 2];

  return (
    <div className="Mysection" style={{ position: 'static' }}>
      <Helmet>
        <title>Сотрудник | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      {departmentsLikeMarketing.includes(departmentId) && (
        <StaffCardMarketing
          staffInfo={staffInfo}
          setStaffInfo={setStaffInfo}
          fetchCard={fetchCard}
          isFirstRender={isFirstRender}
        />
      )}

      {departmentsLikeLawyers.includes(departmentId) && (
        <StaffCardLawyers
          staffInfo={staffInfo}
          fetchCard={fetchCard}
          setStaffInfo={setStaffInfo}
          isFirstRender={isFirstRender}
        />
      )}
    </div>
  );
}

export default StaffCard;
