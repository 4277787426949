// @ts-nocheck
import { makeAutoObservable } from 'mobx';

export default class NotificationStore {
  constructor() {
    this._notifications = [];
    this._notificationsTotalCount = 0;
    this._notificationsPage = 1;
    this._notificationsLimit = 15;
    this._notificationsFilter = undefined;
    this._isNewNotifications = 0;

    makeAutoObservable(this);
  }

  setNotifications(notifications, update) {
    if (update) {
      return (this._notifications = notifications);
    }

    if (this._notificationsPage === 1) {
      return (this._notifications = notifications);
    }

    notifications.forEach((notification) => this._notifications.push(notification));
  }

  setNotificationsTotalCount(count) {
    this._notificationsTotalCount = count;
  }

  setNotificationsPage(page) {
    this._notificationsPage = page;
  }

  setNotificationsFilter(filter) {
    this._notificationsFilter = filter;
  }

  setIsNewNotifications(count) {
    this._isNewNotifications = count;
  }

  markOneNotificationAsRead(notificationId) {
    this._notifications = this._notifications.map((notification) => (notification.id === notificationId ? { ...notification, is_new: false } : notification));
  }

  markFewNotificationsAsRead(notificationIds) {
    this._notifications = this._notifications.map((notification) => (notificationIds.includes(notification.id) ? { ...notification, is_new: false } : notification));
  }

  markFewNotificationsAsUnread(notificationIds) {
    this._notifications = this._notifications.map((notification) => (notificationIds.includes(notification.id) ? { ...notification, is_new: true } : notification));
  }

  markAllNotificationsAsRead() {
    this._notifications = this._notifications
      .map((notification) => ({ ...notification, is_new: false }));
  }

  get notifications() {
    return this._notifications;
  }

  get notificationsTotalCount() {
    return this._notificationsTotalCount;
  }

  get notificationsPage() {
    return this._notificationsPage;
  }

  get notificationsLimit() {
    return this._notificationsLimit;
  }

  get notificationsFilter() {
    return this._notificationsFilter;
  }

  get isNewNotifications() {
    return this._isNewNotifications;
  }
}
