import React, { memo } from 'react';
import DatePicker from 'react-datepicker';
import { ru } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';
import classes from './MyDateInput.module.scss';

function MyDateInput(props) {
  const {
    value,
    setValue,
    className,
    containerClassName,
    onChange,
    style,
    onClick,
    disabled,
    minDate,
    maxDate,
  } = props;

  return (
    <div style={style} onClick={onClick} className={containerClassName}>
      <DatePicker
        selected={value && new Date(value)}
        onChange={onChange || ((date) => setValue(date))}
        className={[classes.myDate, className].join(' ')}
        dateFormat="dd.MM.yyyy"
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        locale={ru}
      />
    </div>
  );
}

export default memo(MyDateInput);
