// @ts-nocheck
import React from 'react';

import trashImg from '../../../assets/icons/Btns/trash.svg';

import classes from './Btns.module.scss';

function DeleteBtn(props) {
  const {
    text, onClick, className, deleteUser,
  } = props;
  return (
    deleteUser
      ? (
        <div
          className={[classes.deleteUser__button, className].join(' ')}
          onClick={onClick}
        >
          {text}
        </div>
      )
      : (
        <div
          className={[classes.delete__trach_button, className].join(' ')}
          onClick={onClick}
        >
          <img className="" src={trashImg} alt="" />
        </div>
      )
  );
}

export default DeleteBtn;
