// @ts-nocheck
import React from 'react';
import { Helmet } from 'react-helmet';

import TeamsPage from '../components/General/TeamsPage/TeamPage/TeamsPage';

function Teams() {
  return (
    <div className="Mysection">
      <Helmet>
        <title>Команды | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <TeamsPage />
    </div>
  );
}

export default Teams;
