// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { getBloggers } from 'API/BloggerAPI';
import { autocompleteAllLegalEntities } from 'API/LawyersAPI/AutocompleteAPI';
import { fetchDepartments, fetchStaffTeamsPage } from 'API/ManagerAPI/AutocompleteAPI';
import { check } from 'API/UserAPI';

import { useLogout } from 'hooks/useLogout';

import MarginFilter from './Filters/MarginFilter';
import NewsFilter from './Filters/NewsFilter';
import TasksFilter from './Filters/TasksFilter';

import classes from './ReportPage.module.scss';

function FilterForReportPage(props) {
  const {
    step, fetchingReportProjects, fetchingReportTasks, fetchingReportNews,
  } = props;
  const [logout] = useLogout();

  const [departments, setDepartments] = useState([]);
  const [directorsList, setDirectorsList] = useState([]);
  const [bloggerList, setBloggerList] = useState([]);
  const [allLegalEntity, setAllLegalEntity] = useState([]);

  useEffect(() => {
    check()
      .then(() => {
        fetchDepartments().then((data) => setDepartments(data));
        fetchStaffTeamsPage().then((data) => setDirectorsList(data));
        getBloggers({ type: [3, 4, 5, 6] }).then((data) => setBloggerList(data));
        autocompleteAllLegalEntities()
          .then((allLegalEntityData) => setAllLegalEntity(allLegalEntityData));
      })
      .catch((error) => logout(error));
  }, []);

  return (
    <div className={classes.filter__container}>

      {step === 0 && (
      <MarginFilter
        departments={departments}
        directorsList={directorsList}
        fetchingReportProjects={fetchingReportProjects}
      />
      )}

      {step === 1 && (
        <TasksFilter
          departments={departments}
          directorsList={directorsList}
          bloggerList={bloggerList}
          allLegalEntity={allLegalEntity}
          fetchingReportTasks={fetchingReportTasks}
        />
      )}

      {step === 2 && (
        <NewsFilter
          departments={departments}
          directorsList={directorsList}
          bloggerList={bloggerList}
          fetchingReportNews={fetchingReportNews}
        />
      )}

    </div>
  );
}

export default FilterForReportPage;
