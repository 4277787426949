import React from 'react';

import StatusBtn from '../StatusBtns/StatusBtn/StatusBtn';

import { ReactComponent as CheckmarkIcon } from './assets/checkmark.svg';

import classes from './CheckBox.module.scss';

function CheckBox(props) {
  const {
    className,
    name,
    value,
    checked,
    onChange,
    disabled,
    htmlFor,
    label,
    transparentBg,
    transferProjects,
    notification,
  } = props;
  return (
    <label
      className={[
        transferProjects
          ? classes.customCheckboxWrapperTransferProjects
          : classes.customCheckboxWrapper,
        transparentBg && classes.transparentBg,
        className,
      ].join(' ')}
      htmlFor={htmlFor || label}
    >
      <input
        className={classes.nativeInput}
        id={htmlFor || label}
        name={name}
        value={value}
        checked={checked}
        onChange={(e) => {
          if (notification) {
            onChange(e);
          } else {
            onChange({ [value]: !checked });
          }
        }}
        disabled={disabled}
        type="checkbox"
      />
      <div
        className={[classes.customCheckboxBox, checked && classes.checked].join(
          ' ',
        )}
      >
        <CheckmarkIcon />
      </div>
      {label && <span className={classes.customCheckboxLabel}>{label}</span>}
    </label>
  );
}

export default CheckBox;
