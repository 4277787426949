import React from 'react';

import { checkSum } from 'utils/checkInputField';

import classes from './NewInput.module.scss';

function NewInput(props) {
  const {
    type,
    id,
    name,
    value,
    onInput,
    placeholder,
    disabled,
    readOnly,
    autoComplete,
    invalid,
  } = props;

  const isNumber = (event) => {
    if (type === 'number') {
      checkSum(event);
    }
  };

  return (
    <label className={classes.nativeLabel} htmlFor={id}>
      <input
        className={[classes.input, invalid && classes.invalid].join(' ')}
        type={type}
        id={id}
        name={name}
        value={value}
        onInput={({ currentTarget: { value } }) => onInput(value)}
        onKeyPress={(event) => isNumber(event)}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        autoComplete={autoComplete}
      />
    </label>
  );
}

export default NewInput;
