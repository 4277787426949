export const getSumRuble = (value) => {
  if (value) {
    value = value?.replace(/\s/g, '').replace(/,/, '.');

    if (value?.substr(-1) === '.') {
      return value;
    }

    return parseFloat(value).toLocaleString('ru-RU', {
      maximumFractionDigits: 2,
    });
  }
  return value;
};

export const getSum = (value) => {
  if (value) {
    value = value.replace(/\s/g, '').replace(/,/, '.');
    return parseFloat(value);
  }
  return 0;
};

export const numberToSum = (value, show) => {
  if (value || show) {
    return value?.toLocaleString('ru-RU', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } if (value === 0) {
    return '';
  }
  return '';
};

export const formatInputNumber = (value) => {
  if (!value) return '0,00';
  const floatValue = parseFloat(value.replace(',', '.'));
  return floatValue.toLocaleString('ru-RU', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatNumber = (num, separator = ' ') => {
  if (!num) {
    return '';
  }
  return `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, separator) || '';
};

export const formatNumberCard = (num) => num
  ?.toString()
  ?.slice(0, 19)
  ?.replace(/\s/g, '')
  ?.replace(/\d{4}(?=.)/g, '$& ');

export const stringToDate = (str) => {
  // Удаляем все символы, кроме цифр
  const filteredInput = str.replace(/[^\d]/g, '');

  // Добавляем точки между днями, месяцами и годами
  let day = filteredInput.slice(0, 2);
  let month = filteredInput.slice(2, 4);
  const year = filteredInput.slice(4, 8);
  // Проверяем, что день не превышает 31
  if (day > 31) {
    day = '31';
  }

  // Проверяем, что месяц не превышает 12
  if (month > 12) {
    month = '12';
  }

  // Склеиваем день, месяц и год с точками между ними
  let formattedDate = '';

  if (day) {
    formattedDate += day;
  }

  if (month) {
    formattedDate += `.${month}`;
  }

  if (year) {
    formattedDate += `.${year}`;
  }

  // Возвращаем отформатированную дату
  return formattedDate;
};

export const numberToSumWithoutDigits = (value, show) => {
  if (value || show) {
    return value?.toLocaleString('ru-RU', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } if (value === 0) {
    return '';
  }
  return '';
};
