import React from 'react';

import imageActive from '../../../assets/icons/Lawyers/close.svg';
import image from '../../../assets/icons/Lawyers/Filter.svg';

import classes from './FilterBtn.module.css';

function FilterBtn({ isOpen, setIsOpen }) {
  return (
    <img
      onClick={() => setIsOpen(!isOpen)}
      src={isOpen ? imageActive : image}
      className={classes.FilterBtn}
      alt=""
    />
  );
}

export default FilterBtn;
