export const columns = [
  {
    id: 'project_id',
    label: '№',
    // filterOptions: [
    //   { id: 'asc', label: 'по возрастанию', asc: true },
    //   { id: 'desc', label: 'по убыванию', asc: false },
    // ],
  },
  {
    id: 'client_legal_entity',
    label: 'ЮЛ заказчика',
    // filterOptions: [
    //   { id: 'asc', label: 'по возрастанию', asc: true },
    //   { id: 'desc', label: 'по убыванию', asc: false },
    // ],
  },
  {
    id: 'contractor_legal_entity',
    label: 'ЮЛ исполнителя',
    // filterOptions: [
    //   { id: 'asc', label: 'по возрастанию', asc: true },
    //   { id: 'desc', label: 'по убыванию', asc: false },
    // ],
  },
  {
    id: 'name',
    label: 'Задача',
    filterOptions: [
      {
        id: '0',
        label: 'Невыполненные задачи',
      },
      {
        id: '1',
        label: 'Выполненные задачи',
      },
    ],
  },
  {
    id: 'deadline',
    label: 'Дедлайн',
  },
  {
    id: 'status',
    label: 'Статус',
    filterOptions: [
      { id: 'asc', label: 'по возрастанию', asc: true },
      { id: 'desc', label: 'по убыванию', asc: false },
    ],
  },
];
