// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { Context } from 'index';

import { patchClosingInfoProjectAccounting, patchContractAccounting } from 'API/FinanceAPI/ProjectAccounting';
import { check } from 'API/UserAPI';
import { patchMainRowProjectAccounting } from '../../../../API/FinanceAPI/ProjectAccounting';

import CancelBtn from 'components/UI/Btns/CancelBtn';
import NewAddBtn from 'components/UI/Btns/NewAddBtn';
import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import InputElement from 'components/UI/InputElements/InputElement';
import MyModal from 'components/UI/MyModal/MyModal';

import { useLogout } from 'hooks/useLogout';

import { getDate } from 'utils/getDate';
import { getSum, getSumRuble, numberToSum } from 'utils/getSums';

import {
  buttonStyle, styleForModal, stylesForButtonApply, stylesForButtonCancel,
} from './consts';

import arrow from '../../../../assets/icons/Btns/arrow_right.svg';
import ContractElement from '../ModalCreateContract/components/ContractElement';

import classes from './ModalEditRow.module.scss';

function ModalEditRow({
  show,
  onHide,
  props,
}) {
  const {
    row, closingInfo, setIncomeDate, setPaymentsDate, setAccountingDate, isMainRow,
  } = props;

  const { toast, projectsFinance } = useContext(Context);

  const logout = useLogout();
  const [paymentsDates, setPaymentsDates] = useState([]);
  const [paymentsOrder, setPaymentsOrder] = useState([]);
  const [accountingDates, setAccountingDates] = useState(null);
  const [creditValue, setCreditValue] = useState('');
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (row?.income_dates) {
      const updatedPaidSum = row?.income_dates?.map((income_date) => ({
        id: income_date.id,
        date: income_date.date,
        sum: numberToSum(income_date.sum) || numberToSum(0),
      }));
      setPaymentsDates(updatedPaidSum);
    }

    if (row?.payments) {
      const updatedPaidSum = row?.payments?.map((payment) => ({
        id: payment.id,
        date: payment.date,
        sum: numberToSum(payment.sum),
      }));
      setPaymentsOrder(updatedPaidSum);
    }

    if (row?.credit) {
      setCreditValue(numberToSum(row?.credit));
    }

    if (closingInfo) {
      setAccountingDates({ date: closingInfo.date, sum: numberToSum(closingInfo.sum) });
    }
  }, [row]);

  const handleCreditChange = (event) => {
    setCreditValue(getSumRuble(event.target.value));
  };

  const handleAccountingDatesChange = (event, type) => {
    if (type === 'date') {
      setAccountingDates({ ...accountingDates, date: event });
    } else {
      setAccountingDates({ ...accountingDates, sum: getSumRuble(event.target.value) });
    }
  };

  const close = () => {
    setPaymentsDates([]);
    onHide();
    setStep(0);
  };

  const addPayment = () => {
    setPaymentsDates([...paymentsDates, {
      id: Date.now(), date: '', sum: '', done: false,
    }]);
  };

  const updatePayment = (index, field, value, type) => {
    if (type === 'payments_dates') {
      const newPayments = [...paymentsDates];
      newPayments[index][field] = value;

      setPaymentsDates(newPayments);
    } else if (type === 'payments_order') {
      const newPayments = [...paymentsOrder];
      newPayments[index][field] = value;

      setPaymentsOrder(newPayments);
    }
  };

  const removePayment = (index) => {
    const newPayments = [...paymentsDates];
    newPayments.splice(index, 1);
    setPaymentsDates(newPayments);
  };

  const savePayments = (type) => {
    if (isMainRow && type === 'money') {
      let isError = false;

      paymentsDates.forEach((el) => {
        if (!el.date || !el.sum) {
          isError = true;
        }
      });

      if (isError) {
        toast.setToastInfo('Введите дату и сумму оплаты');
        toast.setShowError(true);
        return;
      }

      const paymentsDateWithoutId = paymentsDates.filter(
        ({ id: id1 }) => !row?.income_dates?.some(({ id: id2 }) => id2 === id1),
      );
      paymentsDateWithoutId.forEach((element) => {
        delete element.id;
        element.date = element.date ? getDate(element.date) : '';
        element.sum = getSum(element.sum);
      });

      const paymentsDateWithId = paymentsDates.filter(
        ({ id: id1 }) => row?.income_dates?.some(({ id: id2 }) => id2 === id1),
      );
      paymentsDateWithId.forEach((element) => {
        element.date = element.date ? getDate(element.date) : '';
        element.sum = getSum(element.sum);
      });

      const temp2 = paymentsOrder.map((item) => ({
        ...item,
        id: item.id,
        date: item.date ? getDate(item.date) : '',
        sum: getSum(item.sum),
      }));
      check()
        .then(() => {
          patchContractAccounting(row.id, { income_dates: [...paymentsDateWithoutId, ...paymentsDateWithId] }).then((data) => {
            setIncomeDate([...paymentsDateWithoutId, ...paymentsDateWithId]);
            setPaymentsDate(temp2);
            toast.setToastInfo('Данные сохранены');
            toast.setShowSuccess(true);

            projectsFinance.setProjects(projectsFinance.projects.map((project) => (project.id === row.id
              ? {
                ...data,
              } : project)), 'update');
          });
        })
        .catch((error) => logout(error));
      setPaymentsDates([]);
      onHide();
    } else if (type === 'month') {
      if (!accountingDates?.date || !accountingDates?.sum) {
        toast.setToastInfo('Введите фактический месяц и сумму учета');
        toast.setShowError(true);
        return;
      }

      check()
        .then(() => {
          patchClosingInfoProjectAccounting(closingInfo.id, { date: getDate(accountingDates?.date), sum: getSum(accountingDates?.sum) }).then((data) => {
            setAccountingDate({ date: accountingDates?.date, sum: accountingDates?.sum });
            toast.setToastInfo('Данные сохранены');
            toast.setShowSuccess(true);

            const newData = row.closing_info.map((el) => (
              el.id === data.id
                ? data : el));

            projectsFinance.setProjects(projectsFinance.projects.map((project) => (project.id === row.id
              ? {
                ...project,
                closing_info: newData,
              } : project)), 'update');
          });
        })
        .catch((error) => logout(error));
      setPaymentsDates([]);
      onHide();
    }
  };

  return (
    <MyModal buttonStyle={buttonStyle} buttonBack={step !== 0} backPage={() => setStep(0)} show={show} type="projectAccounting" onHide={close} style={styleForModal}>

      {((row?.type === 'КЛ (у)' || row?.type === 'КЛ (а)' || row?.type === 'СК доход') && row.closing_info?.length === 1)
        && (
        <>
          {step === 0 && (
          <div className={classes.wrapper}>
            <h1 className={classes.wrapper__h1}>Выберите действие</h1>
            <div className={classes.wrapper__container} onClick={() => setStep(1)}>
              <div className={classes.wrapper__container__text}>Поступление средств</div>
              <img className={classes.wrapper__container__img} src={arrow} alt="" />
            </div>
            <div className={classes.wrapper__container} onClick={() => setStep(2)}>
              <div className={classes.wrapper__container__text}>Изменение месяца учета</div>
              <img className={classes.wrapper__container__img} src={arrow} alt="" />
            </div>
            <div />
          </div>
          )}

          {step === 1 && (
          <div className={classes.wrapper__step1}>
            <h1 className={classes.wrapper__h1__step1}>Поступление средств</h1>
            {paymentsOrder?.map((payment, index) => (
              <ContractElement
                style={{ width: '94%', marginBottom: 0 }}
                textStyle={{ minWidth: 70, maxWidth: 215, width: '94%' }}
                key={index}
                text="Порядок оплаты по договору"
                elementClassName={classes.margin_zero}
              >
                <>
                  <InputElement
                    style={{ color: '#212529', marginLeft: '-25px' }}
                    type="date"
                    value={payment?.date}
                    date
                    onChange={(event) => updatePayment(index, 'date', event, 'payments_order')}
                    disabled
                  />

                  <InputElement
                    style={{
                      color: '#212529', height: '32px', width: '230px', marginLeft: '20px', marginBottom: '11px',
                    }}
                    value={payment?.sum || '0,00'}
                    onChange={(e) => updatePayment(index, 'sum', getSumRuble(e.target.value), 'payments_order')}
                    disabled
                  />

                  {/* <CancelBtn onClick={() => removePayment(index)} style={{ margin: '7px', width: 27, height: 19 }} /> */}
                </>
              </ContractElement>
            ))}

            {paymentsDates?.map((payment, index) => (
              <ContractElement
                key={index}
                style={{ marginBottom: 0 }}
                textStyle={{ minWidth: 230 }}
                text={index === 0 && 'Оплачено'}
                elementClassName={classes.margin_zero}
              >
                <>
                  <InputElement
                    style={{ color: '#212529', marginLeft: '-40px' }}
                    type="date"
                    value={payment?.date}
                    date
                    onChange={(event) => updatePayment(index, 'date', event, 'payments_dates')}
                    disabled={payment.done}
                  />

                  <InputElement
                    style={{
                      color: '#212529', height: '32px', width: '230px', marginLeft: '20px',
                    }}
                    value={payment?.sum}
                    onChange={(e) => updatePayment(index, 'sum', getSumRuble(e.target.value), 'payments_dates')}
                    disabled={payment.done}
                    placeholder="0,00 Р"
                  />

                  <CancelBtn onClick={() => removePayment(index)} style={{ margin: '7px 7px 17px 7px', width: 27, height: 19 }} />
                </>
              </ContractElement>
            ))}
            <NewAddBtn
              className={classes.newButton}
              onClick={addPayment}
              text="Добавить оплату"
            />
            <InputElement
              text="Остаток"
              type="number"
              value={creditValue || '0,00'}
              onChange={handleCreditChange}
              placeholder="Сумма"
              style={{ width: '94%', marginBottom: 30 }}
              textStyle={{ minWidth: 348 }}
              disabled
            />
            <div className={classes.btn__container}>
              <SuccessBtn
                text="Отменить изменения"
                style={stylesForButtonCancel}
                onClick={close}
              />
              <SuccessBtn
                text="Сохранить"
                style={stylesForButtonApply}
                onClick={() => savePayments('money')}
              />
            </div>
          </div>
          )}

          {step === 2 && (
          <div className={classes.wrapper__step1}>
            <h1 className={classes.wrapper__h1__step1}>Изменение месяца учета</h1>
            <ContractElement
              textStyle={{ minWidth: 348 }}
              text="Плановый месяц и сумма учета"
              elementClassName={classes.margin_zero}
              style={{ alignItems: 'center', marginBottom: '10px' }}
            >
              <>
                <InputElement
                  style={{ color: '#212529', marginLeft: '-40px', marginBottom: '0px' }}
                  type="date"
                  value={row?.date_end}
                  date
                  disabled
                />

                <InputElement
                  style={{
                    color: '#212529', height: '32px', width: '230px', marginLeft: '20px',
                  }}
                  value={numberToSum(closingInfo?.sum)}
                  disabled
                />
              </>
            </ContractElement>

            <ContractElement
              textStyle={{ minWidth: 348 }}
              text="Фактический месяц и сумма учета"
              elementClassName={classes.margin_zero}
              style={{ alignItems: 'center' }}
            >
              <>
                <InputElement
                  style={{ color: '#212529', marginLeft: '-40px', marginBottom: '0px' }}
                  type="date"
                  value={accountingDates?.date}
                  onChange={(event) => handleAccountingDatesChange(event, 'date')}
                  date
                />

                <InputElement
                  style={{
                    color: '#212529', height: '32px', width: '230px', marginLeft: '20px',
                  }}
                  value={getSumRuble(accountingDates?.sum)}
                  placeholder="0,00 Р"
                  onChange={(event) => handleAccountingDatesChange(event, 'sum')}
                />
              </>
            </ContractElement>
            <div className={classes.btn__container}>
              <SuccessBtn
                text="Отменить изменения"
                style={stylesForButtonCancel}
                onClick={close}
              />
              <SuccessBtn
                text="Сохранить"
                style={stylesForButtonApply}
                onClick={() => savePayments('month')}
              />
            </div>
          </div>
          )}
        </>
        )}

      {((!isMainRow && row?.closing_info?.length !== 1) || (isMainRow && (row?.type !== 'КЛ (у)' && row?.type !== 'КЛ (а)' && row?.type !== 'СК доход' && row?.closing_info?.length === 1)))
      && (
      <div className={classes.wrapper__step1}>
        <h1 className={classes.wrapper__h1__step1}>Изменение месяца учета</h1>
        <ContractElement
          textStyle={{ minWidth: 348 }}
          text="Плановый месяц и сумма учета"
          elementClassName={classes.margin_zero}
          style={{ alignItems: 'center', marginBottom: '10px' }}
        >
          <>
            <InputElement
              style={{ color: '#212529', marginLeft: '-40px', marginBottom: '0px' }}
              type="date"
              value={row?.date_end}
              date
              disabled
            />

            <InputElement
              style={{
                color: '#212529', height: '32px', width: '230px', marginLeft: '20px',
              }}
              value={numberToSum(closingInfo?.sum)}
              disabled
            />
          </>
        </ContractElement>

        <ContractElement
          textStyle={{ minWidth: 348 }}
          text="Фактический месяц и сумма учета"
          elementClassName={classes.margin_zero}
          style={{ alignItems: 'center' }}
        >
          <>
            <InputElement
              style={{ color: '#212529', marginLeft: '-40px', marginBottom: '0px' }}
              type="date"
              value={accountingDates?.date}
              onChange={(event) => handleAccountingDatesChange(event, 'date')}
              date
            />

            <InputElement
              style={{
                color: '#212529', height: '32px', width: '230px', marginLeft: '20px',
              }}
              value={getSumRuble(accountingDates?.sum) || ''}
              placeholder="0,00 Р"
              onChange={(event) => handleAccountingDatesChange(event, 'sum')}
            />
          </>
        </ContractElement>
        <div className={classes.btn__container}>
          <SuccessBtn
            text="Отменить изменения"
            style={stylesForButtonCancel}
            onClick={close}
          />
          <SuccessBtn
            text="Сохранить"
            style={stylesForButtonApply}
            onClick={() => savePayments('month')}
          />
        </div>
      </div>
      )}

      {((row?.type === 'КЛ (у)' || row?.type === 'КЛ (а)' || row?.type === 'СК доход') && row.closing_info?.length !== 1 && isMainRow)
      && (
      <div className={classes.wrapper__step1}>
        <h1 className={classes.wrapper__h1__step1}>Поступление средств</h1>
        {paymentsOrder?.map((payment, index) => (
          <ContractElement
            style={{ width: '94%', marginBottom: 0 }}
            textStyle={{ minWidth: 70, maxWidth: 215, width: '94%' }}
            key={index}
            text="Порядок оплаты по договору"
            elementClassName={classes.margin_zero}
          >
            <>
              <InputElement
                style={{ color: '#212529', marginLeft: '-25px' }}
                type="date"
                value={payment?.date}
                date
                onChange={(event) => updatePayment(index, 'date', event, 'payments_order')}
                disabled
              />

              <InputElement
                style={{
                  color: '#212529', height: '32px', width: '230px', marginLeft: '20px', marginBottom: '11px',
                }}
                value={payment?.sum || '0,00'}
                onChange={(e) => updatePayment(index, 'sum', getSumRuble(e.target.value), 'payments_order')}
                disabled
              />

              {/* <CancelBtn onClick={() => removePayment(index)} style={{ margin: '7px', width: 27, height: 19 }} /> */}
            </>
          </ContractElement>
        ))}

        {paymentsDates?.map((payment, index) => (
          <ContractElement
            key={index}
            style={{ marginBottom: 0 }}
            textStyle={{ minWidth: 230 }}
            text={index === 0 && 'Оплачено'}
            elementClassName={classes.margin_zero}
          >
            <>
              <InputElement
                style={{ color: '#212529', marginLeft: '-40px' }}
                type="date"
                value={payment?.date}
                date
                onChange={(event) => updatePayment(index, 'date', event, 'payments_dates')}
                disabled={payment.done}
              />

              <InputElement
                style={{
                  color: '#212529', height: '32px', width: '230px', marginLeft: '20px',
                }}
                value={payment?.sum}
                onChange={(e) => updatePayment(index, 'sum', getSumRuble(e.target.value), 'payments_dates')}
                disabled={payment.done}
                placeholder="0,00 Р"
              />

              <CancelBtn onClick={() => removePayment(index)} style={{ margin: '7px 7px 17px 7px', width: 27, height: 19 }} />
            </>
          </ContractElement>
        ))}
        <NewAddBtn
          className={classes.newButton}
          onClick={addPayment}
          text="Добавить оплату"
        />
        <InputElement
          text="Остаток"
          type="number"
          value={creditValue || '0,00'}
          onChange={handleCreditChange}
          placeholder="Сумма"
          style={{ width: '94%', marginBottom: 30 }}
          textStyle={{ minWidth: 348 }}
          disabled
        />
        <div className={classes.btn__container}>
          <SuccessBtn
            text="Отменить изменения"
            style={stylesForButtonCancel}
            onClick={close}
          />
          <SuccessBtn
            text="Сохранить"
            style={stylesForButtonApply}
            onClick={() => savePayments('money')}
          />
        </div>
      </div>
      )}

    </MyModal>
  );
}

export default ModalEditRow;
