export const FIELDS = [
  {
    id: 'id',
    label: '№ проекта',
    type: 'number',
    width: 1,
  },
  {
    id: 'id_status',
    label: '№ проекта',
    type: 'dropdown',
    list: 'project_sent_date_deadline',
    width: 3,
  },
  {
    id: 'client',
    label: 'Заказчик',
    type: 'autocomplete',
    list: 'client',
    multiple: true,
    width: 3,
  },
  {
    id: 'project',
    label: 'Проект',
    type: 'autocomplete',
    list: 'project',
    multiple: true,
    width: 3,
  },
  {
    id: 'deadline',
    label: 'Дата создания',
    type: 'doubleDate',
    value_id_1: 'deadline_start',
    value_id_2: 'deadline_end',
    width: 5,
  },
  {
    id: 'deadline_status',
    label: 'Дата создания',
    type: 'dropdown',
    list: 'contract_sent_date_deadline',
    width: 3,
  },
  {
    id: 'manager',
    label: 'Менеджер',
    type: 'autocompleteStaff',
    list: 'managers',
    multiple: true,
    width: 3,
  },
  {
    id: 'director',
    label: 'Руководитель',
    type: 'autocompleteStaff',
    list: 'directors',
    multiple: true,
    width: 3,
  },
  {
    id: 'responsible',
    label: 'Ответственный',
    type: 'autocompleteStaff',
    list: 'responsibles',
    multiple: true,
    width: 3,
  },
  {
    id: 'doc_created',
    label: 'По проекту создан документ',
    type: 'dropdown',
    list: 'is_contract_created',
    width: 5,
  },
];
