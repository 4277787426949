// @ts-nocheck
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import FiltersPanel from 'components/UI/FiltersPanel/FiltersPanel';

import { useMultiple } from 'hooks/useMultiple';

import { FIELDS } from './fields';

const FilterForPaymentStatus = observer((props) => {
  const {
    isOpen,
    setIsOpen,
    projectList,
    managersList,
    directorList,
    allLegalEntity,
    fetchContracts,
  } = props;
  const { contracts, filters } = useContext(Context);
  const controller = new AbortController();

  const [
    project,
    changeProject,
    selectedProjectList,
    addSelectedProject,
    deleteSelectedProject,
    setSelectedProjectList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    contractFormat,,
    selectedContractFormatList,
    addContractFormat,
    deleteContractFormat,
    setSelectedContractFormatList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    ourLegalEntity,
    changeOurLegalEntity,
    selectedOurLegalEntityList,
    addOurLegalEntity,
    deleteOurLegalEntity,
    setSelectedOurLegalEntityList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    clientLegalEntity,
    changeClientLegalEntity,
    selectedClientLegalEntityList,
    addClientLegalEntity,
    deleteClientLegalEntity,
    setSelectedClientLegalEntityList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    manager,
    changeManager,
    selectedManagerList,
    addManager,
    deleteManager,
    setSelectedManagerList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    director,
    changeDirector,
    selectedDirectorList,
    addDirector,
    deleteDirector,
    setSelectedDirectorList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  // Состояние фильтра из стора

  useEffect(() => {
    filters.setPaymentStatusProjectFilter(selectedProjectList);
  }, [selectedProjectList]);

  useEffect(() => {
    filters.setPaymentStatusContractFormatFilter(selectedContractFormatList);
  }, [selectedContractFormatList]);

  useEffect(() => {
    filters.setPaymentStatusClientLegalEntityFilter(
      selectedClientLegalEntityList,
    );
  }, [selectedClientLegalEntityList]);

  useEffect(() => {
    filters.setPaymentStatusContractorLegalEntityFilter(
      selectedOurLegalEntityList,
    );
  }, [selectedOurLegalEntityList]);

  useEffect(() => {
    filters.setPaymentStatusManagerFilter(selectedManagerList);
  }, [selectedManagerList]);

  useEffect(() => {
    filters.setPaymentStatusDirectorFilter(selectedDirectorList);
  }, [selectedDirectorList]);

  useLayoutEffect(() => {
    setSelectedProjectList(filters.paymentStatusProjectFilter);

    setSelectedContractFormatList(filters.paymentStatusContractFormatFilter);

    setSelectedOurLegalEntityList(
      filters.paymentStatusContractorLegalEntityFilter,
    );

    setSelectedClientLegalEntityList(
      filters.paymentStatusClientLegalEntityFilter,
    );

    setSelectedManagerList(filters.paymentStatusManagerFilter);

    setSelectedDirectorList(filters.paymentStatusDirectorFilter);
  }, []);

  const updateFieldHandler = (payload) => {
    filters.setPaymentStatusFilter({
      ...filters.paymentStatusFilter,
      ...payload,
    });
  };

  const applyFiltersHandler = (event) => {
    event.preventDefault();
    setIsOpen(false);
    if (fetchContracts) {
      contracts.setPaymentStatusContractsFilter({
        ...filters.paymentStatusFilter,
        project: selectedProjectList,
        contract_format: selectedContractFormatList,
        our_legal_entity: selectedOurLegalEntityList,
        client_legal_entity: selectedClientLegalEntityList,
        responsible: selectedManagerList,
        director: selectedDirectorList,
      });

      contracts.setPaymentStatusContractsPage(1);

      fetchContracts(
        contracts.paymentStatusContractsPage,
        contracts.paymentStatusContractsLimit,
        controller.signal,
        contracts.paymentStatusContractsFilter,
        'update',
      );
    }
  };

  return (
    <FiltersPanel
      isPanelOpen={isOpen}
      fields={FIELDS}
      values={filters.paymentStatusFilter}
      onUpdateField={updateFieldHandler}
      onApplyFilters={applyFiltersHandler}
      projectList={projectList}
      managerList={managersList}
      directorList={directorList}
      allLegalEntity={allLegalEntity}
      addSelectedProject={addSelectedProject}
      addOurLegalEntity={addOurLegalEntity}
      addClientLegalEntity={addClientLegalEntity}
      addManager={addManager}
      addDirector={addDirector}
      addContractFormat={addContractFormat}
      changeProject={changeProject}
      changeOurLegalEntity={changeOurLegalEntity}
      changeClientLegalEntity={changeClientLegalEntity}
      changeManager={changeManager}
      changeDirector={changeDirector}
      project={project}
      ourLegalEntity={ourLegalEntity}
      clientLegalEntity={clientLegalEntity}
      manager={manager}
      director={director}
      contractFormat={contractFormat}
      selectedProjectList={selectedProjectList}
      selectedOurLegalEntityList={selectedOurLegalEntityList}
      selectedClientLegalEntityList={selectedClientLegalEntityList}
      selectedManagerList={selectedManagerList}
      selectedDirectorList={selectedDirectorList}
      selectedContractFormatList={selectedContractFormatList}
      deleteSelectedProject={deleteSelectedProject}
      deleteOurLegalEntity={deleteOurLegalEntity}
      deleteClientLegalEntity={deleteClientLegalEntity}
      deleteManager={deleteManager}
      deleteDirector={deleteDirector}
      deleteContractFormat={deleteContractFormat}
    />
  );
});

export default FilterForPaymentStatus;
