/* eslint-disable no-nested-ternary */
// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { Context } from 'index';

import CancelBtn from 'components/UI/Btns/CancelBtn';
import NewAddBtn from 'components/UI/Btns/NewAddBtn';
import InputElement from 'components/UI/InputElements/InputElement';
import MyDateInput from 'components/UI/MyDateInput/MyDateInput';
import MyDateTimeInput from 'components/UI/MyDateTimeInput/MyDateTimeInput';
import NewsComponent from 'components/UI/NewsComponent/NewsComponent';
import SearchBar from 'components/UI/SearchBar/SearchBar';
import StatusBtn from 'components/UI/StatusBtns/StatusBtn/StatusBtn';
import TaskStatusBtn from 'components/UI/StatusBtns/TaskStatusDropdown/TaskStatusDropdown';
import StatusDropdown from 'components/UI/StatusDropdown/StatusDropdown';

import { useStateNew } from '../../../../hooks/useStateNew';

import { EMPTY_VALUE } from 'utils/consts';
import { isValidURL } from 'utils/isValidUrl';
import { getSumRuble, numberToSum } from '../../../../utils/getSums';

import classes from '../Table.module.scss';

function TableRow(props) {
  const {
    columns,
    row,
    type,
    updateFunc,
    staffCard,
    data,
    projectsList,
    responsibleList,
    departamentList,
    bloggersList,
    fetchMyTasks,
    noCheckbox,
    cellStyle,
    teams,
    addChildren,
    deleteChildren,
    news,
    parent,
    rowIndex,
    publication,
    subpublication,
  } = props;

  const {
    user,
  } = useContext(Context);

  const [project, setProject, changeProject] = useStateNew([
    {
      id: null,
      name: '',
      deadline: '',
      client: {},
    },
  ]);

  const [blogger, setBlogger, changeBlogger] = useStateNew([
    {
      id: null,
      name: '',
      client: {},
    },
  ]);

  const [responsibleMargin, setResponsibleMargin] = useState(null);

  const [
    responsible,
    setResponsible,
    changeResponsible,
    checkResponsible,
  ] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
    },
  ]);

  const [
    department,
    setDepartment,
    changeDepartment,
    checkDepartment,
  ] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
    },
  ]);

  useEffect(() => {
    if (row?.project) {
      setProject([row.project]);
    }

    if (row?.responsible?.length > 0) {
      setResponsible([
        {
          fieldId: Date.now(),
          id: row?.responsible[0]?.id,
          name: row?.responsible[0]?.fullname,
        },
      ]);
    }

    if (row?.blogger) {
      setBlogger([
        {
          fieldId: Date.now(),
          id: row?.blogger?.id,
          name: row?.blogger?.name,
        },
      ]);
    }

    if (row?.staff && row?.percent) {
      setResponsible([
        {
          fieldId: Date.now(),
          id: row?.staff?.id,
          name: row?.staff?.fullname,
        },

      ]);
    }
  }, [data]);

  // Функция для получения данных из SearchBar для таблицы "РАСПРЕДЕЛЕНИЕ МАРЖИ"
  useEffect(() => {
    if (updateFunc && responsibleMargin) {
      updateFunc(row.id, 'staff', responsibleMargin);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsibleMargin]);

  return (
    <>

      {(!noCheckbox && type === 'projectPublications') && (
      <div
        className={classes.cell}
        onClick={() => updateFunc && updateFunc(publication?.id, 'status', !publication?.status)}
      >
        <div
          className={[classes.statusIcon, publication?.status && classes.active].join(
            ' ',
          )}
        />
      </div>
      )}

      {(!noCheckbox && type === 'projectSubPublications') && (
      <div
        className={classes.cell}
        onClick={() => updateFunc && updateFunc(row.id, publication.id, subpublication.id, 'status', !subpublication?.status)}
      >
        <div
          className={[classes.statusIcon, subpublication?.status && classes.active].join(
            ' ',
          )}
        />
      </div>
      )}

      {columns.map((c) => (
        <div
          className={[
            !news ? classes.cell : classes.cell__news,
            type === 'reportPageNews' && classes.reportPageNews__cell_news,
            (c.id === 'paid_sum' || c.id === 'type' || c.id === 'payment_type') && classes.paidCell,
          ].join(' ')}
          key={c.id}
          style={
    c.id === 'deadline'
      ? { padding: '6px 0px', ...cellStyle }
      : row?.payments_data?.length > 0
        ? {
          ...cellStyle,
          maxHeight: `${38 * row.payments_data.reduce((acc, payment) => acc + 1 + (payment.payment_dates ? payment.payment_dates.length : 0), 0)}px`,
        }
        : cellStyle
  }
        >

          {type === 'staffCardLawyers' && (
          <>
            {c.id === 'name' && (
            <InputElement
              value={row[c.id]}
              style={{ width: '100%' }}
              className={classes.input}
              disabled
            />
            )}

            {c.id === 'deadline' && (
            <MyDateTimeInput
              value={row[c.id]}
              className={classes.date}
              disabled
            />
            )}
          </>
          )}

          {type === 'tasksLawyers' && (
          <>
            {c.id === 'project_id' && (
            <InputElement
              value={row?.project?.project_id}
              style={{ width: '100%' }}
              className={classes.input}
              disabled
            />
            )}
            {c.id === 'client_legal_entity' && (
            <InputElement
              value={row?.contract?.client_legal_entity?.legal_name}
              style={{ width: '100%' }}
              className={classes.input}
              disabled
            />
            )}
            {c.id === 'contractor_legal_entity' && (
            <InputElement
              value={row?.contract?.contractor_legal_entity?.legal_name}
              style={{ width: '100%' }}
              className={classes.input}
              disabled
            />
            )}

            {c.id === 'name' && (
            <>
              <InputElement
                isPopover
                value={row[c.id]}
                style={row?.subtasks
                  ? { width: '100%', marginBottom: '0px' }
                  : { width: '100%', marginLeft: '30px', marginBottom: '0px' }}
                className={classes.input}
                onChange={(event) => updateFunc(row.id, 'name', event.target.value)}
                isLink={isValidURL(row[c.id])}
              />
              {row?.subtasks
                  && (
                  <NewAddBtn
                    onClick={(event) => addChildren(event, row.id)}
                    type="white"
                    isPopoverText="Добавить подзадачу"
                  />
                  )}
            </>
            )}

            {c.id === 'deadline' && (
            <MyDateTimeInput
              value={row[c.id]}
              className={classes.date}
              onChange={(event) => updateFunc(row.id, 'deadline', event)}
            />
            )}

            {c.id === 'status' && (
            <TaskStatusBtn
              id={row.id}
              status={row?.status}
              updateFunc={updateFunc}
            />
            )}

          </>
          )}

          {type === 'staffCardMarketing' && (
          <>
            {c.id === 'name' && (
            <InputElement
              value={row[c.id]}
              style={row?.subtasks ? { width: '100%' } : { width: '100%', marginLeft: '30px' }}
              className={classes.input}
              disabled
            />
            )}

            {c.id === 'deadline' && (
            <MyDateTimeInput
              value={row[c.id]}
              className={classes.date}
              disabled
            />
            )}

            {c.id === 'project' && (
            <InputElement
              value={project[0].name}
              style={{ width: '100%' }}
              className={classes.input}
              disabled
            />
            )}

            {c.id === 'managerStatus' && (
            <TaskStatusBtn
              id={row.id}
              status={row?.status}
              updateFunc={updateFunc}
            />
            )}
          </>
          )}

          {type === 'news' && (c.id === 'goodNews' || c.id === 'badNews') && (
          <NewsComponent value={c.id === 'goodNews' ? row.good : row.bad} />
          )}

          {type === 'reportPageNews' && (
          <>
            {c.id === 'staff' && (
            <NewsComponent value={row.staff?.fullname} />
            )}
            {c.id === 'telegram' && (
            <NewsComponent value={row.staff?.telegram} />
            )}
            {(c.id === 'goodNews' || c.id === 'badNews') && (
            <NewsComponent value={c.id === 'goodNews' ? row.good : row.bad} />
            )}
          </>
          )}

          {type === 'tasksMarketing' && (
          <>
            {c.id === 'name' && (
            <>
              <InputElement
                isPopover
                value={row[c.id]}
                style={row?.subtasks
                  ? { width: '100%', marginBottom: '0px' }
                  : { width: '100%', marginLeft: '30px', marginBottom: '0px' }}
                className={classes.input}
                onChange={(event) => updateFunc(row.id, 'name', event.target.value)}
                isLink={isValidURL(row[c.id])}
              />
              {row?.subtasks
                  && (
                  <NewAddBtn
                    onClick={(event) => addChildren(event, row.id)}
                    type="white"
                    isPopoverText="Добавить подзадачу"
                  />
                  )}
            </>
            )}

            {c.id === 'deadline' && (
            <MyDateTimeInput
              value={row[c.id]}
              className={classes.date}
              onChange={(event) => updateFunc(row.id, 'deadline', event)}
            />
            )}

            {c.id === 'project' && (
            <SearchBar
              list={projectsList}
              text={project[0]}
              setText={changeProject}
              className={classes.input}
              fetchMyTasks={fetchMyTasks}
              updateProject={() => updateFunc(row.id, 'project', null)}
              id={row.id}
              disabled={staffCard || !projectsList}
              style={data.length <= 14 ? { position: 'static' } : {}}
              inputStyle={{ fontSize: 14 }}
              parent={parent}
            />
            )}

            {c.id === 'managerStatus' && (
            <TaskStatusBtn
              id={row.id}
              status={row?.status}
              updateFunc={updateFunc}
            />
            )}
          </>
          )}

          {type === 'reportTasks' && (
          <>
            {c.id === 'number' && (
            <InputElement
              value={row.project?.id || EMPTY_VALUE}
              style={{}}
              className={classes.input}
              disabled
            />
            )}

            {c.id === 'name' && (
            <InputElement
              value={row[c.id] || EMPTY_VALUE}
              className={classes.input}
              elementClassName={classes.input__container}
              disabled
            />
            )}

            {c.id === 'blogger' && (
            <InputElement
              value={row?.blogger?.name}
              className={classes.input}
              elementClassName={classes.input__container}
              disabled
            />
            )}

            {c.id === 'deadline' && (
            <MyDateTimeInput
              value={row.deadline}
              className={classes.date}
              disabled
            />
            )}

            {c.id === 'project' && (
            <SearchBar
              text={project[0]}
              className={classes.input}
              containerClassName={classes.input__container}
              disabled
            />
            )}

            {c.id === 'client_legal_entity' && (
            <InputElement
              value={row.contract?.client_legal_entity?.legal_name || EMPTY_VALUE}
              className={classes.input}
              elementClassName={classes.input__container}
              disabled
            />
            )}

            {c.id === 'contractor_legal_entity' && (
            <InputElement
              value={row.contract?.contractor_legal_entity?.legal_name || EMPTY_VALUE}
              className={classes.input}
              containerClassName={classes.input__container}
              disabled
            />
            )}

            {c.id === 'staff' && (
            <InputElement
              value={row.responsible?.map((r) => r.fullname) || EMPTY_VALUE}
              className={classes.input}
              disabled
            />
            )}

            {c.id === 'managerStatus' && (
            <TaskStatusBtn
              id={row.id}
              status={row?.status}
              disabled
            />
            )}
          </>
          )}

          {type === 'tasksDevelopment' && (
          <>
            {c.id === 'name' && (
            <>
              <InputElement
                isPopover
                value={row[c.id]}
                style={row?.subtasks
                  ? { width: '100%', marginBottom: '0px' }
                  : { width: '100%', marginLeft: '30px', marginBottom: '0px' }}
                className={classes.input}
                onChange={(event) => updateFunc(row.id, 'name', event.target.value)}
                isLink={isValidURL(row?.link)}
              />
              {row?.subtasks
                  && (
                  <NewAddBtn
                    onClick={(event) => addChildren(event, row.id)}
                    type="white"
                    isPopoverText="Добавить подзадачу"
                  />
                  )}
            </>
            )}

            {c.id === 'deadline' && (
            <MyDateTimeInput
              value={row[c.id]}
              className={classes.date}
              onChange={(event) => updateFunc(row.id, 'deadline', event)}
            />
            )}

            {c.id === 'blogger' && (
            <SearchBar
              list={bloggersList}
              text={blogger[0]}
              setText={changeBlogger}
              className={classes.input}
              fetchMyTasks={fetchMyTasks}
              updateProject={() => updateFunc(row.id, 'blogger', null)}
              id={row.id}
              disabled={staffCard || !bloggersList}
              style={data.length <= 14 ? { position: 'static' } : {}}
              inputStyle={{ fontSize: 14 }}
              parent={parent}
              type="bloggerApplication"
            />
            )}

            {c.id === 'project' && (
            <SearchBar
              list={projectsList}
              text={project[0]}
              setText={changeProject}
              className={classes.input}
              fetchMyTasks={fetchMyTasks}
              updateProject={() => updateFunc(row.id, 'project', null)}
              id={row.id}
              disabled={staffCard || !projectsList}
              style={data.length <= 14 ? { position: 'static' } : {}}
              inputStyle={{ fontSize: 14 }}
              parent={parent}
            />
            )}

            {c.id === 'managerStatus' && (
            <TaskStatusBtn
              id={row.id}
              status={row?.status}
              updateFunc={updateFunc}
            />
            )}
          </>
          )}

          {type === 'projectTasksMarketing' && (
          <>
            {c.id === 'name' && (
            <>
              <InputElement
                isPopover
                value={row[c.id]}
                style={row?.subtasks
                  ? { width: '100%', marginBottom: '0px' }
                  : { width: '100%', marginLeft: '30px', marginBottom: '0px' }}
                className={classes.input}
                onChange={(event) => updateFunc(row.id, 'name', event.target.value)}
                isLink={isValidURL(row[c.id])}
              />
              {row?.subtasks
                  && (
                  <NewAddBtn
                    onClick={(event) => addChildren(event, row.id)}
                    type="white"
                    isPopoverText="Добавить подзадачу"
                  />
                  )}
            </>
            )}

            {c.id === 'deadline' && (
            <MyDateTimeInput
              value={row[c.id]}
              className={classes.date}
              onChange={(event) => updateFunc(row.id, 'deadline', event)}
            />
            )}

            {c.id === 'responsible' && (
            <SearchBar
              list={responsibleList}
              text={responsible[0]}
              setText={changeResponsible}
              className={classes.input}
              type="staff"
              fetchMyTasksResponsible={fetchMyTasks}
              id={row.id}
              disabled={staffCard}
              parent={parent}
              style={data.length <= 14 ? { position: 'static' } : {}}
              inputStyle={{ fontSize: 14, width: '270px' }}
            />
            )}
            {c.id === 'status' && (
            <TaskStatusBtn
              id={row.id}
              status={row?.status}
              updateFunc={updateFunc}
            />
            )}
          </>
          )}

          {type === 'projectPublications' && (
          <>
            {c.id === 'deadline' && (
            <MyDateInput
              type="date"
              value={publication?.deadline}
              className={classes.date}
              onChange={(event) => updateFunc(publication.id, 'deadline', event)}
              maxDate={Date.now()}
            />
            )}

            {c.id === 'blogger' && (
            <InputElement
              value={row?.blogger?.name}
              style={{ width: '100%' }}
              className={classes.input}
              disabled
            />
            )}

            {c.id === 'format' && (
            <InputElement
              value={publication?.type?.name}
              style={{ width: '100%' }}
              className={classes.input}
              disabled
            />
            )}

            {c.id === 'link' && (
            <InputElement
              value={row?.link}
              style={{ width: '100%', paddingRight: 20 }}
              className={classes.input}
              disabled
              isLink={isValidURL(row?.link)}
            />

            )}

            {c.id === 'linkPublication' && (
            <InputElement
              value={publication?.link}
              style={{ width: '100%', paddingRight: 20, marginBottom: 0 }}
              className={classes.input}
              isLink={isValidURL(publication?.link)}
              onChange={(event) => updateFunc(publication?.id, 'link', event.target.value)}
            />
            )}

            {c.id === 'token' && (
            <>
              <div>
                <InputElement
                  value={publication?.token}
                  style={{ width: '100%', paddingRight: 20 }}
                  className={classes.input}
                  disabled
                />
              </div>

              <NewAddBtn
                onClick={() => addChildren(row?.id, publication?.id, publication)}
                type="white"
                isPopoverText="Добавить публикацию"
              />

            </>
            )}

          </>
          )}
          {type === 'projectSubPublications' && (
          <>
            {c.id === 'deadline' && (
            <MyDateInput
              type="date"
              value={subpublication?.deadline}
              className={classes.date}
              onChange={(event) => updateFunc(row.id, publication.id, subpublication.id, 'deadline', event)}
            />
            )}

            {c.id === 'blogger' && (
            <InputElement
              value={row?.blogger?.name}
              style={{ width: '100%' }}
              className={classes.input}
              disabled
            />
            )}

            {c.id === 'format' && (
            <InputElement
              value={subpublication.type.name}
              style={{ width: '100%' }}
              className={classes.input}
              disabled
            />
            )}

            {c.id === 'link' && (
            <InputElement
              value={row?.link}
              style={{ width: '100%', paddingRight: 20 }}
              className={classes.input}
              disabled
              isLink={isValidURL(row?.link)}
            />
            )}

            {c.id === 'linkPublication' && (
            <InputElement
              value={subpublication?.link}
              style={{ width: '100%', paddingRight: 20, marginBottom: 0 }}
              className={classes.input}
              onChange={(event) => updateFunc(row.id, publication.id, subpublication.id, 'link', event.target.value)}
              isLink={isValidURL(subpublication?.link)}
            />
            )}

            {c.id === 'token' && (
            <>
              <div>
                <InputElement
                  value={subpublication?.token}
                  style={{ width: '100%', paddingRight: 20 }}
                  className={classes.input}
                  disabled
                />
              </div>

              <div style={{ marginRight: 9 }}>
                <CancelBtn
                  onClick={() => deleteChildren(row?.id, publication?.id, subpublication?.id)}
                />
              </div>

            </>
            )}

          </>
          )}

          {type === 'incomeProjectPage' && (
          <>
            {c.id === 'deadline' && (
            <>
              {row.payment_dates.map((payment) => (
                <div key={payment.id} className={classes.payment}>
                  <MyDateInput
                    type="date"
                    value={payment.date}
                    className={classes.date}
                    disabled
                  />
                </div>
              ))}
            </>
            )}

            {c.id === 'type'
                  && (
                  <InputElement
                    value={row?.type}
                    style={{ width: '100%' }}
                    className={classes.input}
                    disabled
                  />
                  )}

            {c.id === 'payment_type' && (
            <InputElement
              value={row?.payment_method}
              style={{ width: '100%' }}
              className={classes.input}
              disabled
            />
            )}

            {c.id === 'sum' && (
            <>
              {row.payment_dates.map((payment) => (
                <div key={payment.id} className={classes.payment}>
                  <InputElement
                    value={numberToSum(payment.sum)}
                    style={{ width: '100%' }}
                    className={classes.newInput}
                    disabled
                  />
                </div>
              ))}
            </>
            )}

            {c.id === 'total_sum' && (
            <div>
              <InputElement
                value={numberToSum(row.paid_sum, true)}
                style={{ width: '100%' }}
                className={classes.newInput}
                disabled
              />
            </div>
            )}
          </>
          )}

          {type === 'expenseProjectPage' && (
          <>
            {c.id === 'deadline' && (
            <>
              {row.payment_dates.map((payment) => (
                <div key={payment.id} className={classes.payment}>
                  <MyDateInput
                    value={payment.date}
                    onChange={(event) => updateFunc(row.id, 'payment_dates', [
                      {
                        ...row.payment_dates[0],
                        date: event,
                      },
                    ])}
                    className={classes.date}
                    disabled={row.type.id !== 2}
                  />
                </div>
              ))}
            </>
            )}

            {c.id === 'type'
                && (row?.type?.id === 2 ? (
                  <InputElement
                    style={{ width: '100%' }}
                    className={classes.input}
                    value={row?.comment}
                    onChange={(event) => updateFunc(row.id, 'comment', event.target.value)}
                  />
                ) : (
                  <InputElement
                    value={row?.type}
                    style={{ width: '100%' }}
                    className={classes.input}
                    disabled
                  />
                ))}

            {c.id === 'sum' && (
            <>
              {row.payment_dates.map((payment) => (
                <div key={payment.id} className={classes.payment}>
                  <InputElement
                    type="sum"
                    value={payment.sum}
                    onChange={(event) => updateFunc(row.id, 'payment_dates', [
                      {
                        ...row.payment_dates[0],
                        sum: getSumRuble(event.target.value),
                      },
                    ])}
                    style={{ width: '100%' }}
                    className={classes.newInput}
                    disabled={row.type.id !== 2}
                  />
                </div>
              ))}
            </>
            )}

            {c.id === 'payment_type' && (
            <InputElement
              value={row?.payment_method}
              style={{ width: '100%' }}
              className={classes.input}
              disabled
            />
            )}

            {c.id === 'paid_sum' && (
            <InputElement
              value={numberToSum(row?.paid_sum, true)}
              style={{ width: '100%' }}
              className={classes.input}
              disabled
            />
            )}
          </>
          )}

          {/* ТАБЛИЦА МАРЖИ */}
          {type === 'projectMargin' && (
          <>
            {c.id === 'department' && (
            <div style={{ position: 'absolute' }}>
              <InputElement
                textStyle={{ minWidth: 230 }}
                dropdown={departamentList}
                value={row?.department}
                style={{ border: 'none', background: 'none', marginTop: '2px' }}
                setValue={(element) => updateFunc(row.id, 'department', element)}
                noArrow
              />
            </div>
            )}
            {c.id === 'team' && (
            <div style={{ position: 'absolute' }}>
              <InputElement
                textStyle={{ minWidth: 230 }}
                value={row?.team}
                setValue={(element) => updateFunc(row.id, 'team', element)}
                dropdown={teams}
                style={{ border: 'none', background: 'none', marginTop: '2px' }}
                noArrow
              />
            </div>
            )}
            {c.id === 'staff' && (
            <div style={{ position: 'absolute' }}>
              {responsible.map((element) => (
                <SearchBar
                  inputStyle={{ border: 'none', background: 'none', marginTop: '4px' }}
                  setResponsibleMargin={setResponsibleMargin}
                  key={element.fieldId}
                  list={responsibleList}
                  text={element}
                  setText={changeResponsible}
                  checkResponsible={checkResponsible}
                  type="staff"
                />
              ))}

            </div>
            )}
            {c.id === 'sum' && (
            <div>
              <InputElement
                value={numberToSum(row[c.id])}
                style={{ width: '100%' }}
                className={classes.newInput}
                onChange={(event) => updateFunc(row.id, 'sum', event.target.value)}
              />
            </div>
            )}
            {c.id === 'percent' && (
            <div>
              <InputElement
                value={numberToSum(row[c.id])}
                style={{ width: '100%' }}
                className={classes.newInput}
                onChange={(event) => updateFunc(row.id, 'percent', event.target.value)}
              />
            </div>
            )}
          </>
          )}

          {/* ТАБЛИЦА МАРЖИ */}

          {/* ТАБЛИЦА СМЕТЫ */}
          {type === 'projectEstimate' && (
            row?.payments_data?.length > 0 && (
            <>
              {c.id === 'contractor'
                && (
                <InputElement
                  value={row?.contractor}
                  style={{ width: '100%', height: '100%' }}
                  className={classes.newInput}
                  disabled
                />
                )}
              {c.id === 'payment_method' && (
              <InputElement
                value={row?.payment_method}
                style={{ width: '100%', height: '100%' }}
                className={classes.newInput}
                disabled
              />
              )}
              {c.id === 'payment_status' && (

                row?.payments_data?.length > 0 && row?.payments_data.map((el, index) => (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: '0px',
                    height: '39px',
                    gap: '7px',
                    width: '100%',
                    borderBottom: index === row.payments_data.length - 1 ? 'none' : '1px solid #f2f2f2',
                  }}
                  >
                    {
                     (el.payment_status_id === 4 || user.department === 2 || user.department === 3) ? (
                       <StatusDropdown
                         style={{ padding: '5px 14px' }}
                         id={el?.payment_id}
                         financeCost
                         status={user.department === 2 ? el?.financial_payment_status_id : el?.payment_status_id}
                       />
                     ) : (
                       <StatusBtn
                         style={{ padding: '5px 14px' }}
                         financeCost
                         status={el?.payment_status_id}
                       />
                     )
}

                  </div>
                ))

              )}

              {c.id === 'total_sum'
                && (
                  <div>
                    {row?.payments_data?.length > 0 && row.payments_data.map((payment, index) => (
                      payment.payment_dates?.length > 0 ? (
                        payment.payment_dates?.map((el) => (
                          <InputElement
                            key={index}
                            value={numberToSum(el?.sum, true)}
                            style={{
                              width: '100%',
                              margin: 0,
                              height: '28px',
                            }}
                            className={classes.newInput}
                            disabled
                          />

                        ))

                      ) : (
                        <InputElement
                          key={index}
                          value={numberToSum(payment?.expense, true)}
                          style={{
                            width: '100%',
                            margin: 0,
                            borderBottom: index === row.payments_data.length - 1 ? 'none' : '1px solid #f2f2f2',
                          }}
                          className={classes.newInput}
                          disabled
                        />
                      )
                    ))}
                  </div>

                )}
              {c.id === 'sale_price'
                && (
                <InputElement
                  value={numberToSum(row?.sale_price, true)}
                  style={{ width: '100%', height: '100%' }}
                  className={classes.newInput}
                  onChange={(event) => updateFunc(row.id, 'sale_price', event.target.value)}
                  type="sum"
                />
                )}
              {c.id === 'margin'
                && (
                <InputElement
                  value={numberToSum(row?.margin, true)}
                  style={{ width: '100%', height: '100%' }}
                  className={classes.newInput__projectEstimate}
                  disabled
                />
                )}
            </>
            )

          )}

          {/* ТАБЛИЦА СМЕТЫ */}
        </div>
      ))}
    </>
  );
}

export default TableRow;
