export const LEGAL_ENTITIES_FIELD = [
  { id: 'legal_name', label: 'Юридическое лицо', delete: true },
  { id: 'inn', label: 'Реквизиты ЮЛ (ИНН)' },
];

export const BANK_DETAILS_FIELD = [
  { id: 'bank', label: 'Банк', delete: true },
  { id: 'number', label: '№ карты/счета' },
];

export const ADDITIONAL_FIELD = [
  { id: 'category', label: 'Категория' },
  { id: 'clothing_size', label: 'Размер одежды' },
  { id: 'params', label: 'Параметры (ОГ, ОТ, ОБ)' },
  { id: 'children', label: 'Дети' },
  { id: 'animals', label: 'Животные' },
  { id: 'exclusive', label: 'Эксклюзив' },
  { id: 'prohibited', label: 'Запретные категории' },
];
