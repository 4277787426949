import React from 'react';

import classes from '../RegistryTable.module.scss';

function RegistryTableHeader(props) {
  const {
    columns, headClassName, cellClassName, style,
  } = props;
  const classNames = [classes.head, classes.row, headClassName].join(' ');

  return (
    <div className={classNames} style={style}>
      <div className={[classes.cell, cellClassName].join(' ')}>№</div>
      {Array.isArray(columns)
        && columns.map((c) => (
          <div className={[classes.cell, cellClassName].join(' ')} key={c.id}>
            {c.label}
          </div>
        ))}
    </div>
  );
}

export default RegistryTableHeader;
