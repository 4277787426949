import React from 'react';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import ClientString from 'components/UI/ClientString/ClientString';

import classes from '../BloggersPage.module.css';

function AllBloggersWindow(props) {
  const { openHandler, clientStats, contractorsStats } = props;

  return (
    <div className={classes.wrapper}>
      <ClientString text="Всего блогеров" count={clientStats} all />
      <ClientString text="Всего подрядчиков" count={contractorsStats} all />

      <SuccessBtn
        text="Добавить"
        style={{ marginTop: 10, padding: '3px 15px', fontSize: 13 }}
        onClick={() => openHandler()}
      />
    </div>
  );
}

export default AllBloggersWindow;
