import React from 'react';

import MyDropdown from 'components/UI/MyDropdown/MyDropdown';
import LawyersInput from 'components/UI/MyInput/LawyersInput';

import { checkPhone, checkSum } from 'utils/checkInputField';

import CancelBtn from '../Btns/CancelBtn';
import CheckboxItem from '../CheckboxItem/CheckboxItem';
import MyDateTimeInput from '../MyDateTimeInput/MyDateTimeInput';
import NewTextArea from '../MyTextArea/NewTextArea';

import classes from './InputElements.module.css';

function InputElementDateTime(props) {
  const {
    type,
    text,
    checkboxText,
    placeholder,
    dropdown,
    checkbox,
    textarea,
    value,
    setValue,
    disabled,
    required,
    onChange,
    onKeyPress,
    onClick,
    onInput,
    date,
    updateDate,
    id,
    style,
    textStyle,
    textareaStyle,
    inputStyle,
    className,
    classNameDropdownMenu,
    textClassName,
    multiple,
    multipleSelect,
    deleteBtn,
    deleteHandler,
    cancelBtn,
    left,
    defaultText,
  } = props;

  const isNumber = (event) => {
    if (type === 'number' || type === 'sum') {
      checkSum(event);
    }

    if (type === 'phone') {
      checkPhone(event);
    }
  };

  let inputType = 'input';

  if (dropdown) {
    inputType = 'dropdown';
  } else if (date) {
    inputType = 'date';
  } else if (checkbox) {
    inputType = 'checkbox';
  } else if (textarea) {
    inputType = 'textarea';
  }

  const setDate = (changeValue) => {
    if (updateDate) {
      updateDate(changeValue, 'date', id);
    }
  };

  return (
    <div className={classes.contract__element} style={style}>
      {text && (
        <div
          className={[classes.element__text, textClassName].join(' ')}
          style={textStyle}
        >
          {text}
        </div>
      )}
      {inputType === 'input' && (
        <LawyersInput
          type={type}
          className={className}
          placeholder={placeholder}
          value={value}
          setValue={setValue}
          onInput={onInput}
          onKeyPress={onKeyPress || ((event) => isNumber(event))}
          disabled={disabled}
          onChange={onChange}
          onClick={onClick}
          required={required}
          style={inputStyle}
        />
      )}

      {inputType === 'dropdown' && (
        <MyDropdown
          selected={value}
          setSelected={setValue}
          className={className}
          classNameMenu={classNameDropdownMenu}
          inputStyle={inputStyle}
          list={dropdown}
          onClick={onClick}
          multiple={multiple}
          multipleSelect={multipleSelect}
          defaultText={defaultText}
          disabled={disabled}
        />
      )}

      {inputType === 'date' && (
        <MyDateTimeInput
          value={value}
          setValue={setValue || setDate}
          className={classes.date__input}
          style={inputStyle || { width: '100%' }}
          onClick={onClick}
          onChange={onChange}
          disabled={disabled}
        />
      )}

      {inputType === 'checkbox' && (
        <CheckboxItem
          status={value}
          setStatus={setValue}
          text={checkboxText}
          style={{ width: '100%' }}
          onClick={onClick}
        />
      )}

      {inputType === 'textarea' && (
        <NewTextArea
          value={value}
          setValue={setValue}
          onChange={onChange}
          onClick={onClick}
          style={textareaStyle}
          placeholder={placeholder}
          disabled={disabled}
        />
      )}

      {deleteBtn && (
        <div className={classes.delete} onClick={() => deleteHandler()} />
      )}

      {cancelBtn && (
        <CancelBtn
          onClick={deleteHandler}
          style={{
            padding: '1px 8px',
            left: left || 412,
            position: 'absolute',
          }}
        />
      )}
    </div>
  );
}

export default InputElementDateTime;
