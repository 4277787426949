import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import './ProgressBarGeneral.css';

function ProgressBarGeneral(props) {
  const { totalProgressFromAll } = props;

  return (
    <Box sx={{ width: '404px', height: '50px' }}>
      <LinearProgress
        style={{
          backgroundColor: '#303042', height: '50px', borderRadius: '4px 4px 0 0', width: '100%',
        }}
        variant="determinate"
        value={totalProgressFromAll || 0}
      />
    </Box>
  );
}

export default ProgressBarGeneral;
