import { useState } from 'react';

export const useStateNew2 = (initualValue) => {
  const [state, setState] = useState(initualValue || []);

  const change = (id, fieldName, value) => {
    setState(
      state.map((element) => (element.id === id
        ? {
          ...element,
          [fieldName]: value,
        }
        : element)),
    );
  };

  const add = (value, noId) => {
    if (noId) {
      setState([
        ...state,
        value,
      ]);
    } else if (value) {
      setState([
        ...state,
        {
          ...value,
          id: Date.now(),
        },
      ]);
    } else {
      setState([
        ...state,
        {
          ...initualValue[0],
          id: Date.now(),
        },
      ]);
    }
  };

  const deleteFunc = (id, firstDelete) => {
    if (state.length > 1 || firstDelete) {
      setState(state.filter((element) => element.id !== id));
    }
  };

  return [state, setState, change, add, deleteFunc];
};
