import React from 'react';
import { Helmet } from 'react-helmet';

import NotificationList from 'components/General/NotificationList/NotificationList';

function NotificationPage() {
  return (
    <section className="Mysection">
      <Helmet>
        <title>Уведомления | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <NotificationList />
    </section>
  );
}

export default NotificationPage;
