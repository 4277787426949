import React, {
  memo,
  useCallback,
  useContext,
  useEffect, useRef, useState,
} from 'react';
import { Dropdown } from 'react-bootstrap';
import { Context } from 'index';

import { patchOneCostBid } from 'API/FinanceAPI/CostsAPI';
import { patchClosingInfoProjectAccounting, patchMainRowProjectAccounting } from 'API/FinanceAPI/ProjectAccounting';
import { patchOneContract } from 'API/LawyersAPI/ContractAPI';
import { patchOneProject } from 'API/ManagerAPI/ProjectAPI';
import { check } from 'API/UserAPI';

import StatusBtn from 'components/UI/StatusBtns/StatusBtn/StatusBtn';

import { useLogout } from '../../../hooks/useLogout';

import {
  STATE_STATUSES,
  STATUS_FINANCE,
  STATUS_LAWYER,
  STATUS_LAWYER_COSTS,
  STATUS_PROJECT_ACCOUNTING,
  STATUS_TASKS,
} from 'utils/consts';
import { getCurrentQuarter } from 'utils/getCurrentQuarter';

import classes from './StatusDropdown.module.css';

function StatusDropdown(props) {
  const {
    status,
    id,
    fetchHistory,
    style,
    btnStyle,
    manager,
    lawyer,
    financeCost,
    marketingTasks,
    ProjectAccounting,
    additionalRow,
    mainRow,
    setQuarter,
    setStatus,
    row,
    closingInfo,
    updateInfo,
    setComments,
    setIndicateRed,
    setAdditionalRowComments,
    setAdditionalRowIndicateRed,
  } = props;

  const {
    modal, user, toast, projectsFinance,
  } = useContext(Context);
  const [logout] = useLogout();
  const rootEl = useRef(null);

  const [isActive, setIsActive] = useState(false);
  const [curStatus, setCurStatus] = useState(7);

  useEffect(() => {
    const handleEnterPress = (e) => {
      if (e.key === 'Enter' && modal._modalConfirmProps.currentId === id) {
        check()
          .then(() => {
            const { request } = modal._modalConfirmProps;
            patchOneCostBid(id, request)
              .then(() => setCurStatus(request?.status?.id || request?.financial_status?.id))
              .catch((error) => {
                toast.setToastInfo(error?.response?.data?.non_field_errors[0]);
                toast.setShowError(true);
              });
          })
          .catch((error) => logout(error))
          .finally(() => modal.setModalConfirmVisible(false));
      }
    };

    document.addEventListener('keydown', handleEnterPress);

    return () => {
      document.removeEventListener('keydown', handleEnterPress);
    };
  }, [modal]);

  useEffect(() => {
    setCurStatus(status);
  }, [status]);

  useEffect(() => {
    const onClick = (e) => {
      if (rootEl.current) {
        rootEl.current.contains(e.target) || setIsActive(false);
      }
    };
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  // Учет проектов
  const handleUpdate = (value, patchId, patchPayload, additionalRow) => {
    setCurStatus(value);
    const isValueTwo = value === 2;
    const quarterValue = isValueTwo ? getCurrentQuarter() : '-';
    const closingInfoId = closingInfo?.id;

    if (additionalRow && value !== undefined && closingInfoId !== undefined) {
      patchClosingInfoProjectAccounting(closingInfoId, { quarter: isValueTwo ? quarterValue : '', accounting_type_id: value }).then((data) => {
        setAdditionalRowComments(data?.comment || []);
        setAdditionalRowIndicateRed(data?.indicate_red);
        setQuarter(quarterValue);
      });

      // Патч запрос для получения обновленной строки
      patchMainRowProjectAccounting(row?.id, {}).then((response) => {
        projectsFinance.setProjects(projectsFinance.projects.map((project) => (project.id === patchId
          ? {
            ...response.data,
          } : project)), 'update');
      });
    } else {
      patchMainRowProjectAccounting(patchId, patchPayload)
        .then((response) => {
          setComments(response?.data?.closing_info[0]?.comment || []);
          setIndicateRed(response?.data?.closing_info[0]?.indicate_red);
          if (isValueTwo && value !== undefined && closingInfoId !== undefined) {
            patchClosingInfoProjectAccounting(closingInfoId, { accounting_type_id: value, quarter: quarterValue }).then(() => {
              setQuarter(quarterValue);
            });
          }

          projectsFinance.setProjects(projectsFinance.projects.map((project) => (project.id === patchId
            ? {
              ...response.data,
            } : project)), 'update');

          setStatus(value);
          // setIndicateRed(true);
          // const commentsUpdater = mainRow ? setComments : setAdditionalRowComments;
          // commentsUpdater((prevComments) => [...prevComments, 'Изменение поля Статус учета']);

          if (fetchHistory) {
            fetchHistory();
          }
        })
        .catch((error) => {
          setCurStatus(status);
          toast.setToastInfo(error?.response?.data?.non_field_errors[0]);
          toast.setShowError(true);
        });
    }
  };
  // Учет проектов

  const statusHandler = (event, value) => {
    event.stopPropagation();
    event.preventDefault();
    setIsActive(false);

    check()
      .then(() => {
        if (manager) {
          setCurStatus(value);
          patchOneProject(id, { status: { id: value } }).then(() => {
            if (fetchHistory) {
              fetchHistory();
            }
          });
          return;
        }

        if (lawyer) {
          setCurStatus(value);
          patchOneContract(id, {
            lawyer_status: { id: value },
          }).then(() => {
            if (fetchHistory) {
              fetchHistory();
            }
          });
        }

        if (financeCost) {
          const isNotDepartmentTwo = user.department !== 2;
          const statusArray = isNotDepartmentTwo ? STATUS_LAWYER_COSTS : STATUS_FINANCE;
          const requestField = isNotDepartmentTwo ? 'status' : 'financial_status';

          const onClickAction = () => {
            check()
              .then(() => {
                const requestData = { [requestField]: { id: value } };
                patchOneCostBid(id, requestData)
                  .then(() => {
                    setCurStatus(value);
                    updateInfo();
                  })
                  .catch((error) => {
                    if (error.response?.data?.non_field_errors) {
                      toast.setToastInfo(error?.response?.data?.non_field_errors[0]);
                      toast.setShowError(true);
                    } else if (error?.response?.data) {
                      toast.setToastInfo(error?.response?.data?.[0]);
                      toast.setShowError(true);
                    }
                  });
              })
              .catch((error) => logout(error));
          };

          modal.setModalConfirmProps({
            currentId: id,
            request: { [requestField]: { id: value } },
            save: true,
            text: `Вы изменили статус заявки с “${statusArray[status]}” на “${statusArray[value]}”`,
            click: onClickAction,
          });

          modal.setModalConfirmVisible(true);
        }

        // Учет проектов
        if (mainRow) {
          handleUpdate(value, id, { accounting_status: { id: value }, accounting_type_id: value });
        }

        if (additionalRow) {
          handleUpdate(value, id, { accounting_type_id: value }, additionalRow);
        }
        // Учет проектов
      })
      .catch((error) => logout(error));
  };

  const activeHandler = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsActive(!isActive);
  }, [isActive]);

  const createStatusArray = (statusList, indices) => indices.map((id) => ({ id, label: statusList[id] }));

  let statusArray = [];

  if (manager) {
    statusArray = STATE_STATUSES.LIST;
  } else if (lawyer) {
    statusArray = STATUS_LAWYER.LIST;
  } else if (financeCost) {
    const list = user.department !== 2 ? STATUS_LAWYER_COSTS : STATUS_FINANCE;
    // Полный доступ
    if (user.department === 3) {
      statusArray = createStatusArray(list, [1, 2, 5, 6]);
      // Финансы
    } else if (user.department === 2) {
      statusArray = createStatusArray(list, [1, 2, 3, 4, 5]);
      // Список статусов для остальных
    } else {
      statusArray = createStatusArray(list, [1, 4]);
    }
  } else if (marketingTasks) {
    statusArray = createStatusArray(STATUS_TASKS, [0, 1, 2]);
  } else if (ProjectAccounting) {
    const list = STATUS_PROJECT_ACCOUNTING;

    if (user.user.role.id !== 2 || user.user.department.id === 2 || user.user.department.id === 3) {
      if (mainRow) {
        statusArray = createStatusArray(list, [0, 1, 2, 4, 5]);
      }
      if (additionalRow) {
        statusArray = createStatusArray(list, [0, 1, 2, 4, 5]);
      }
    } else {
      if (mainRow) {
        statusArray = createStatusArray(list, [0, 1, 3]);
      }
      if (additionalRow) {
        statusArray = createStatusArray(list, [0, 1]);
      }
    }
  }

  return (
    <div ref={rootEl} style={style}>
      <StatusBtn
        status={curStatus}
        onClick={(event) => activeHandler(event)}
        style={btnStyle || { padding: '5px 14px' }}
        marketing={manager}
        lawyer={lawyer}
        financeCost={financeCost}
        marketingTasks={marketingTasks}
        ProjectAccounting={ProjectAccounting}
      />

      <Dropdown.Menu
        className={[
          'my__dropdown__inner',
          isActive && classes.newDropdown,
        ].join(' ')}
        show={isActive}
        style={{ width: 200, maxHeight: 250 }}
        align="start"
      >
        {statusArray.map((statusElement, index) => (
          <StatusBtn
            key={index}
            status={statusElement.id}
            onClick={(event) => statusHandler(event, statusElement.id)}
            style={{ padding: '5px 10px' }}
            marketing={manager}
            lawyer={lawyer}
            financeCost={financeCost}
            marketingTasks={marketingTasks}
            ProjectAccounting={ProjectAccounting}
          />
        ))}
      </Dropdown.Menu>
    </div>
  );
}

export default memo(StatusDropdown);
