// @ts-nocheck
import React, {
  useContext, useEffect, useLayoutEffect, useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

// Components
import MultipleBtn from 'components/UI/MultipleBtn/MultipleBtn';
import MyDropdown from 'components/UI/MyDropdown/MyDropdown';
import SearchBar from 'components/UI/SearchBar/SearchBar';

import { useMultiple } from 'hooks/useMultiple';

import classes from '../ReportPage.module.scss';

const FilterStaffBlock = observer((props) => {
  const {
    directorsList, departments, type, allLegalEntity, shortFields,
  } = props;
  const { user, filters } = useContext(Context);

  const [selectedDepartments, setSelectedDepartments] = useState([]);
  // Состояние для руководителей из выбранного отдела
  const [selectedDepartmentDirectors, setSelectedDepartmentDirectors] = useState([]);
  const [selectedDirectorStaffList, setSelectedDirectorStaffList] = useState([]);

  // Состояние для списка сотрудников по выбранной работе
  const [selectedJobStaffList, setSelectedJobStaffList] = useState([]);

  const [
    department,
    changeDepartment,
    selectedDepartmentList,
    addDepartment,
    deleteDepartment,
    setSelectedDepartmentList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    director,
    changeDirector,
    selectedDirectorList,
    addDirector,
    deleteDirector,
    setSelectedDirectorList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    responsible,
    changeResponsible,
    selectedResponsibleList,
    addResponsible,
    deleteResponsible,
    setSelectedResponsibleList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    contractorLegalEntity,
    changeContractorLegalEntity,
    selectedContractorLegalEntityList,
    addContractorLegalEntity,
    deleteContractorLegalEntity,
    setSelectedContractorLegalEntityList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  useEffect(() => {
    filters.setReportSelectedDepartmentList(selectedDepartmentList);
  }, [selectedDepartmentList]);

  useEffect(() => {
    filters.setReportSelectedDirectorList(selectedDirectorList);
  }, [selectedDirectorList]);

  useEffect(() => {
    filters.setReportSelectedResponsibleList(selectedResponsibleList);
  }, [selectedResponsibleList]);

  useEffect(() => {
    filters.setReportContractorLegalEntityList(selectedContractorLegalEntityList);
  }, [selectedContractorLegalEntityList]);

  useLayoutEffect(() => {
    setSelectedDepartmentList(filters.reportSelectedDepartmentList);
    setSelectedDirectorList(filters.reportSelectedDirectorList);
    setSelectedResponsibleList(filters.reportselectedResponsibleList);
    setSelectedContractorLegalEntityList(filters.reportContractorLegalEntityList);
  }, []);

  useEffect(() => {
    if (user.user.department.id !== 3 && user.user.department.id !== 2) {
      filters.setReportFilter({ selectedDepartment: user.user.department });
    }
  }, []);

  useEffect(() => {
    setSelectedDepartmentDirectors(directorsList);
    setSelectedDepartments(departments);
  }, [directorsList, departments]);

  // Смена списка руководителей при смене отдела/работы
  useEffect(() => {
    if (selectedDepartmentList && selectedDepartmentList.length) {
      const selectedDepartmentIds = selectedDepartmentList.map((dep) => dep.id);

      const filteredDirectors = directorsList.filter((person) => person?.grouphead?.department && selectedDepartmentIds.includes(person?.grouphead?.department?.id));

      setSelectedDepartmentDirectors(filteredDirectors);
    } else {
      setSelectedDepartmentDirectors(directorsList); // здесь мы обновляем список руководителей

      if (Number.isInteger(filters.reportFilter.selectedJob.id)) {
        // Массив итоговых сотрудников отфильтрованных по выбранной работе
        const staffList = [];

        // Массив руководителей отфильтрованных по выбранной работе
        const tempArray = directorsList
          .filter((person) => person?.grouphead?.job?.id === filters.reportFilter.selectedJob.id);

        tempArray.forEach((director) => {
          staffList.push(...director.team);
        });

        setSelectedJobStaffList(staffList);
      }
    }
  }, [selectedDepartmentList, filters.reportFilter.selectedJob]);

  // Смена списка сотрудников при добавлении/удалении руководителя в фильтр
  useEffect(() => {
    const tempArray = [];

    directorsList.forEach((director) => {
      tempArray.push(...director.team);
    });

    setSelectedDirectorStaffList(tempArray);
  }, [directorsList]);

  useEffect(() => {
    // Если выбран руководитель
    if (selectedDirectorList && selectedDirectorList.length) {
      const selectedDirectorIds = selectedDirectorList.map((dir) => dir.id);
      // Фильтрование списка сотрудников по выбранному руководителю
      const filteredStaff = directorsList
        .filter((director) => selectedDirectorIds.includes(director.id))
        .reduce((acc, currentDirector) => acc.concat(currentDirector.team), []);
      setSelectedDirectorStaffList(filteredStaff);
    } else {
      // Если руководитель не выбран, отображаем всех сотрудников
      const allStaff = directorsList.reduce((acc, currentDirector) => acc.concat(currentDirector.team), []);
      setSelectedDirectorStaffList(allStaff);
    }
  }, [selectedDirectorList, directorsList]);

  return (
    <div className={classes.filter__block}>
      {type !== 'lawyerTasks' && (
      <>
        {!shortFields && (
        <>
          <div className={classes.filter__staff}>
            <span className={classes.filter__text}>Отдел</span>
            <SearchBar
              text={department[0]}
              setText={changeDepartment}
              list={selectedDepartments}
              type="staffWithTeams"
              multiple={addDepartment}
            />
          </div>

          <div className={selectedDepartmentList.length ? classes.selected : classes.noSelected}>
            {selectedDepartmentList.map((element) => (
              <MultipleBtn
                key={element.id}
                element={element}
                deleteEl={deleteDepartment}
              />
            ))}
          </div>

          <div className={classes.filter__staff}>
            <span className={classes.filter__text}>Руководитель</span>
            <SearchBar
              text={director[0]}
              setText={changeDirector}
              list={selectedDepartmentDirectors}
              type="staffWithTeams"
              multiple={addDirector}
            />
          </div>

          <div className={selectedDirectorList.length ? classes.selected : classes.noSelected}>
            {selectedDirectorList.map((element) => (
              <MultipleBtn
                key={element.id}
                element={element}
                deleteEl={deleteDirector}
              />
            ))}
          </div>
        </>
        )}

        <div className={classes.filter__staff}>
          <span className={classes.filter__text}>Сотрудник</span>
          <SearchBar
            text={responsible[0]}
            setText={changeResponsible}
            list={selectedDirectorStaffList}
            type="staff"
            multiple={addResponsible}
          />
        </div>

        <div className={selectedResponsibleList.length ? classes.selected : classes.noSelected}>
          {selectedResponsibleList.map((element) => (
            <MultipleBtn
              key={element.id}
              element={element}
              deleteEl={deleteResponsible}
            />
          ))}
        </div>
      </>
      )}

      {type === 'lawyerTasks' && (
        <>
          <div className={classes.filter__staff}>
            <span className={classes.filter__text}>Направление</span>
            <MyDropdown
              selected={filters.reportFilter.selectedJob}
              setSelected={(element) => filters.setReportFilter({ selectedJob: element })}
              list={[{ id: 1, label: 'Юрист' }, { id: 2, label: 'ДОК' }]}
            />
          </div>

          <div className={classes.filter__staff}>
            <span className={classes.filter__text}>Сотрудник</span>
            <SearchBar
              text={responsible[0]}
              setText={changeResponsible}
              list={selectedJobStaffList}
              type="staff"
              multiple={addResponsible}
            />
          </div>

          <div className={selectedResponsibleList.length ? classes.selected : classes.noSelected}>
            {selectedResponsibleList.map((element) => (
              <MultipleBtn
                key={element.id}
                element={element}
                deleteEl={deleteResponsible}
              />
            ))}
          </div>

          <div className={classes.filter__staff}>
            <span className={classes.filter__text}>ЮЛ исполнителя</span>
            <SearchBar
              text={contractorLegalEntity[0]}
              setText={changeContractorLegalEntity}
              list={allLegalEntity}
              multiple={addContractorLegalEntity}
              type="legal_details"
            />
          </div>

          <div className={classes.selected__contractor}>
            {selectedContractorLegalEntityList.map((element) => (
              <MultipleBtn
                key={element.id}
                element={element}
                deleteEl={deleteContractorLegalEntity}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
});

export default FilterStaffBlock;
