// @ts-nocheck

import React, {
  useContext, useEffect, useLayoutEffect,
  useState,
} from 'react';
import {
  addWeeks, endOfISOWeek, endOfMonth, startOfISOWeek, startOfMonth,
} from 'date-fns';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import FilterBtn from 'components/UI/FilterBtn/FilterBtn';
import FiltersPanel from 'components/UI/FiltersPanel/FiltersPanel';
import StatusBtn from 'components/UI/StatusBtns/StatusBtn/StatusBtn';

import { useMultiple } from 'hooks/useMultiple';

import { FIELDS, SHORT_FIELDS, STATUS_QUANTITY } from './consts';

import classes from './MainLawyers.module.css';

const Filters = observer((props) => {
  const {
    style,
    short,
    projectList,
    lawyersList,
    documentList,
    managerList,
    allLegalEntity,
    staffCard,
    fetchContracts,
    controller,
  } = props;

  const { contracts, filters } = useContext(Context);

  const [filtersPanelOpen, setFiltersPanelOpen] = useState(false);

  const [
    project,
    changeProject,
    selectedProjectList,
    addSelectedProject,
    deleteSelectedProject,
    setSelectedProjectList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    ourLegalEntity,
    changeOurLegalEntity,
    selectedOurLegalEntityList,
    addOurLegalEntity,
    deleteOurLegalEntity,
    setSelectedOurLegalEntityList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    clientLegalEntity,
    changeClientLegalEntity,
    selectedClientLegalEntityList,
    addClientLegalEntity,
    deleteClientLegalEntity,
    setSelectedClientLegalEntityList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    manager,
    changeManager,
    selectedManagerList,
    addManager,
    deleteManager,
    setSelectedManagerList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    lawyer,
    changeLawyer,
    selectedLawyerList,
    addLawyer,
    deleteLawyer,
    setSelectedLawyerList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    document,
    changeDocument,
    selectedDocumentList,
    addDocument,
    deleteDocument,
    setSelectedDocumentList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  // Состояние фильтра из стора

  useEffect(() => {
    filters.setLawyersProjectFilter(selectedProjectList);
  }, [selectedProjectList]);

  useEffect(() => {
    filters.setLawyersClientLegalEntityFilter(selectedClientLegalEntityList);
  }, [selectedClientLegalEntityList]);

  useEffect(() => {
    filters.setLawyersContractorLegalEntityFilter(selectedOurLegalEntityList);
  }, [selectedOurLegalEntityList]);

  useEffect(() => {
    filters.setLawyersManagerFilter(selectedManagerList);
  }, [selectedManagerList]);

  useEffect(() => {
    filters.setLawyersLawyerFilter(selectedLawyerList);
  }, [selectedLawyerList]);

  useEffect(() => {
    filters.setLawyersDocFilter(selectedDocumentList);
  }, [selectedDocumentList]);

  useLayoutEffect(() => {
    setSelectedProjectList(filters.lawyersProjectFilter);

    setSelectedOurLegalEntityList(filters.lawyersContractorLegalEntityFilter);

    setSelectedClientLegalEntityList(filters.lawyersClientLegalEntityFilter);

    setSelectedManagerList(filters.lawyersManagerFilter);

    setSelectedLawyerList(filters.lawyersLawyerFilter);

    setSelectedDocumentList(filters.lawyersDocFilter);
  }, []);

  const updateFieldHandler = (payload) => {
    filters.setLawyersFilter({
      ...filters.lawyersFilter,
      ...payload,
    });
  };

  useEffect(() => {
    if (filters?.lawyersFilter?.bid_sent_date_status?.id === 0) {
      filters.setLawyersFilter({
        ...filters.lawyersFilter,
        bid_sent_date_start: addWeeks(startOfISOWeek(Date.now()), -1),
        bid_sent_date_end: addWeeks(endOfISOWeek(Date.now()), -1),
      });
    } else if (filters?.lawyersFilter?.bid_sent_date_status?.id === 1) {
      filters.setLawyersFilter({
        ...filters.lawyersFilter,
        bid_sent_date_start: startOfISOWeek(Date.now()),
        bid_sent_date_end: endOfISOWeek(Date.now()),
      });
    } else if (filters?.lawyersFilter?.bid_sent_date_status?.id === 2) {
      filters.setLawyersFilter({
        ...filters.lawyersFilter,
        bid_sent_date_start: addWeeks(startOfISOWeek(Date.now()), 1),
        bid_sent_date_end: addWeeks(endOfISOWeek(Date.now()), 1),
      });
    } else if (filters?.lawyersFilter?.bid_sent_date_status?.id === 3) {
      filters.setLawyersFilter({
        ...filters.lawyersFilter,
        bid_sent_date_start: startOfMonth(Date.now()),
        bid_sent_date_end: endOfMonth(Date.now()),
      });
    }
  }, [filters?.lawyersFilter?.bid_sent_date_status]);

  useEffect(() => {
    if (filters?.lawyersFilter?.contract_sent_date_status?.id === 0) {
      filters.setLawyersFilter({
        ...filters.lawyersFilter,
        contract_sent_date_start: addWeeks(startOfISOWeek(Date.now()), -1),
        contract_sent_date_end: addWeeks(endOfISOWeek(Date.now()), -1),
      });
    } else if (filters?.lawyersFilter?.contract_sent_date_status?.id === 1) {
      filters.setLawyersFilter({
        ...filters.lawyersFilter,
        contract_sent_date_start: startOfISOWeek(Date.now()),
        contract_sent_date_end: endOfISOWeek(Date.now()),
      });
    } else if (filters?.lawyersFilter?.contract_sent_date_status?.id === 2) {
      filters.setLawyersFilter({
        ...filters.lawyersFilter,
        contract_sent_date_start: addWeeks(startOfISOWeek(Date.now()), 1),
        contract_sent_date_end: addWeeks(endOfISOWeek(Date.now()), 1),
      });
    } else if (filters?.lawyersFilter?.contract_sent_date_status?.id === 3) {
      filters.setLawyersFilter({
        ...filters.lawyersFilter,
        contract_sent_date_start: startOfMonth(Date.now()),
        contract_sent_date_end: startOfMonth(Date.now()),
      });
    }
  }, [filters?.lawyersFilter?.contract_sent_date_status]);

  useEffect(() => {
    if (filters?.lawyersFilter?.payment_date_status?.id === 0) {
      filters.setLawyersFilter({
        ...filters.lawyersFilter,
        payment_date_start: addWeeks(startOfISOWeek(Date.now()), -1),
        payment_date_end: addWeeks(endOfISOWeek(Date.now()), -1),
      });
    } else if (filters?.lawyersFilter?.payment_date_status?.id === 1) {
      filters.setLawyersFilter({
        ...filters.lawyersFilter,
        payment_date_start: startOfISOWeek(Date.now()),
        payment_date_end: endOfISOWeek(Date.now()),
      });
    } else if (filters?.lawyersFilter?.payment_date_status?.id === 2) {
      filters.setLawyersFilter({
        ...filters.lawyersFilter,
        payment_date_start: addWeeks(startOfISOWeek(Date.now()), 1),
        payment_date_end: addWeeks(endOfISOWeek(Date.now()), 1),
      });
    } else if (filters?.lawyersFilter?.payment_date_status?.id === 3) {
      filters.setLawyersFilter({
        ...filters.lawyersFilter,
        payment_date_start: startOfMonth(Date.now()),
        payment_date_end: endOfMonth(Date.now()),
      });
    }
  }, [filters?.lawyersFilter?.payment_date_status]);

  const statusHandler = (value) => {
    const tempArray = Array.from(filters.lawyersFilter.status);

    if (tempArray.includes(value)) {
      const index = tempArray.findIndex((item) => item === value);
      tempArray.splice(index, 1);
    } else {
      tempArray.push(value);
    }

    filters.setLawyersFilter({
      ...filters.lawyersFilter,
      status: tempArray,
    });
  };

  const applyFiltersHandler = (event) => {
    event.preventDefault();

    setFiltersPanelOpen(false);

    if (fetchContracts) {
      contracts.setContractsFilter({
        ...filters.lawyersFilter,
        project: filters.lawyersProjectFilter,
        our_legal_entity: filters.lawyersContractorLegalEntityFilter,
        client_legal_entity: filters.lawyersClientLegalEntityFilter,
        responsible: filters.lawyersManagerFilter,
        lawyer: filters.lawyersLawyerFilter,
        doc_manager: filters.lawyersDocFilter,
      });

      contracts.setContractsPage(1);

      fetchContracts(
        contracts.contractsPage,
        contracts.contractsLimit,
        controller.signal,
        contracts.contractsFilter,
        'update',
      );
    }
  };

  const CancelAllFiltersHandler = () => {
    filters.setLawyersFilter({
      ...filters.lawyersFilter,
      status: [],
      id: '',
      bid_sent_date_start: '',
      bid_sent_date_end: '',
      contract_sent_date_start: '',
      contract_sent_date_end: '',
      contract_sent_date_status: {},
      marking_data: {},
      contract_date: '',
      branch_name: '',
      contract_id: '',
      total_sum: '',
      payment_date_start: '',
      payment_date_end: '',
      payment_date_status: {},
      service_start: '',
      service_end: '',
      service_end_start: '',
      service_end_end: '',
      service_date_status: {},
      contract_type: [],
      contract_status: [],
      closing_document_status: [],
      receipt_status: [],
      indicator: [],
    });

    filters.setLawyersProjectFilter([]);
    filters.setLawyersClientLegalEntityFilter([]);
    filters.setLawyersContractorLegalEntityFilter([]);
    filters.setLawyersManagerFilter([]);
    filters.setLawyersLawyerFilter([]);
    filters.setLawyersDocFilter([]);

    setSelectedProjectList([]);
    setSelectedOurLegalEntityList([]);
    setSelectedClientLegalEntityList([]);
    setSelectedManagerList([]);
    setSelectedLawyerList([]);
    setSelectedDocumentList([]);

    // Под вопросом
    contracts.setContractsFilter({
      ...filters.lawyersFilter,
      status: [],
      id: '',
      bid_sent_date_start: '',
      bid_sent_date_end: '',
      contract_sent_date_start: '',
      contract_sent_date_end: '',
      contract_sent_date_status: {},
      contract_date: '',
      branch_name: '',
      contract_id: '',
      total_sum: '',
      payment_date_start: '',
      payment_date_end: '',
      payment_date_status: {},
      service_start: '',
      service_end: '',
      service_end_start: '',
      service_end_end: '',
      contract_type: [],
      contract_status: [],
      closing_document_status: [],
      receipt_status: [],
      indicator: [],
      id_status: { id: '', label: '' },
      marking_data: { id: '', label: '' },
      project: '',
      our_legal_entity: '',
      client_legal_entity: '',
      responsible: '',
      lawyer: '',
      doc_manager: '',
      closed_status: '',
      bill_status: '',
      project_manager_status: '',
      quarter_closing: '',
      quarter_start: '',
      quarter_end: '',
    });

    contracts.setContractsPage(1);

    fetchContracts(
      contracts.contractsPage,
      contracts.contractsLimit,
      controller.signal,
      contracts.contractsFilter,
      'update',
    );
  };

  useEffect(() => {
    if (contracts.contractsPage > 1) {
      if (fetchContracts) {
        setTimeout(() => {
          contracts.setContractsFilter({
            ...filters.lawyersFilter,
            project: filters.lawyersProjectFilter,
            our_legal_entity: filters.lawyersContractorLegalEntityFilter,
            client_legal_entity: filters.lawyersClientLegalEntityFilter,
            responsible: filters.lawyersManagerFilter,
            lawyer: filters.lawyersLawyerFilter,
            doc_manager: filters.lawyersDocFilter,
          });

          fetchContracts(
            1,
            contracts.contractsLimit,
            controller.signal,
            contracts.contractsFilter,
            'update',
          ).then(() => contracts.setContractsPage(2));
        }, 300);
      }
    }
  }, [filters.lawyersFilter.status.length]);

  const propsForFiltersPanel = {
    isPanelOpen: filtersPanelOpen,
    fields: staffCard ? SHORT_FIELDS : FIELDS,
    values: filters.lawyersFilter,
    onUpdateField: updateFieldHandler,
    onApplyFilters: applyFiltersHandler,
    projectList,
    managerList,
    lawyersList,
    documentList,
    allLegalEntity,
    ourLegalEntity,
    changeOurLegalEntity,
    clientLegalEntity,
    changeClientLegalEntity,
    addSelectedProject,
    addOurLegalEntity,
    addClientLegalEntity,
    addManager,
    addLawyer,
    addDocument,
    changeProject,
    changeManager,
    changeResponsibleLawyer: changeLawyer,
    changeResponsibleDocument: changeDocument,
    project,
    manager,
    responsibleLawyer: lawyer,
    responsibleDocument: document,
    selectedProjectList,
    selectedOurLegalEntityList,
    selectedClientLegalEntityList,
    selectedManagerList,
    selectedLawyerList,
    selectedDocumentList,
    deleteSelectedProject,
    deleteOurLegalEntity,
    deleteClientLegalEntity,
    deleteManager,
    deleteLawyer,
    deleteDocument,
    mainLawyers: 'mainLawyers',
    CancelAllFiltersHandler,
  };

  return (
    <>
      <div className={classes.filters__container} style={style}>
        {!short && (
          <div className={classes.filters__status__container}>
            {STATUS_QUANTITY.map((statusElement) => (
              <StatusBtn
                key={statusElement}
                status={statusElement}
                onClick={() => statusHandler(statusElement)}
                isActive={filters.lawyersFilter.status.includes(statusElement)}
                style={{ padding: '5px 15px' }}
                lawyer
              />
            ))}
          </div>
        )}

        <FilterBtn isOpen={filtersPanelOpen} setIsOpen={setFiltersPanelOpen} />
      </div>

      <FiltersPanel {...propsForFiltersPanel} />
    </>
  );
});

export default Filters;
