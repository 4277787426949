// @ts-nocheck
import React, { useContext, useEffect } from 'react';
import {
  addWeeks, endOfISOWeek, endOfMonth, startOfISOWeek, startOfMonth,
} from 'date-fns';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import SecondDoubleDate from 'components/UI/DoubleDate/SecondDoubleDate';
import InputElement from 'components/UI/InputElements/InputElement';

import {
  DATE_DROPDOWN,
} from 'utils/consts';

import FilterStaffBlock from './FilterStaffBlock';

import classes from '../ReportPage.module.scss';

const NewsFilter = observer((props) => {
  const { reportNews, filters, user } = useContext(Context);
  const { departments, directorsList, fetchingReportNews } = props;
  const controllerNews = new AbortController();

  useEffect(() => {
    if (filters?.reportFilter?.news_date_status?.id === 0) {
      filters.setReportFilter({
        ...filters.reportFilter,
        news_period_start: addWeeks(startOfISOWeek(Date.now()), -1),
        news_period_end: addWeeks(endOfISOWeek(Date.now()), -1),
      });
    } else if (filters?.reportFilter?.news_date_status?.id === 1) {
      filters.setReportFilter({
        ...filters.reportFilter,
        news_period_start: startOfISOWeek(Date.now()),
        news_period_end: endOfISOWeek(Date.now()),
      });
    } else if (filters?.reportFilter?.news_date_status?.id === 2) {
      filters.setReportFilter({
        ...filters.reportFilter,
        news_period_start: addWeeks(startOfISOWeek(Date.now()), 1),
        news_period_end: addWeeks(endOfISOWeek(Date.now()), 1),
      });
    } else if (filters?.reportFilter?.news_date_status?.id === 3) {
      filters.setReportFilter({
        ...filters.reportFilter,
        news_period_start: startOfMonth(Date.now()),
        news_period_end: endOfMonth(Date.now()),
      });
    }
  }, [filters?.reportFilter?.news_date_status]);

  const apllyFilterHandler = () => {
    reportNews.setReportNewsFilter({
      ...filters.reportFilter,
      selectedDirectorList: filters.reportSelectedDirectorList,
      selectedResponsibleList: filters.reportselectedResponsibleList,
      selectedDepartmentList: filters.reportSelectedDepartmentList,
    });

    reportNews.setReportNewsPage(1);

    fetchingReportNews(
      reportNews.reportNewsPage,
      reportNews.reportNewsLimit,
      controllerNews.signal,
      reportNews.reportNewsFilter,
    );
  };

  return (
    <>
      <div className={classes.filter__container__inner}>

        <div className={classes.filter__block}>
          <div className={classes.filter__item}>
            <span className={classes.filter__text}>Период</span>
            <div className={classes.doubleDate__container}>
              <SecondDoubleDate
                value1={filters.reportFilter.news_period_start}
                value2={filters.reportFilter.news_period_end}
                setValue1={(event) => filters.setReportFilter({ news_period_start: event })}
                setValue2={(event) => filters.setReportFilter({ news_period_end: event })}
              />
            </div>
          </div>

          <div className={classes.filter__item}>
            <span />
            <InputElement
              value={filters.reportFilter.news_date_status}
              setValue={(status) => filters.setReportFilter({ news_date_status: status })}
              dropdown={DATE_DROPDOWN.LIST}
              inputStyle={{ fontSize: 13 }}
              className={classes.dropdown}
            />
          </div>

        </div>

        <FilterStaffBlock
          departments={departments}
          directorsList={directorsList}
          shortFields={user.user.role.id !== 0}
        />
      </div>

      <div className={classes.btns}>
        <SuccessBtn
          text="Применить фильтр"
          style={{ padding: '4px 14px', fontSize: 11 }}
          onClick={() => apllyFilterHandler()}
        />
      </div>
    </>
  );
});

export default NewsFilter;
