export const columns = [
  { id: 'title', label: 'Название проекта' },
  { id: 'contractor_legal_entity', label: 'ЮЛ заказчика' },
  { id: 'client_legal_entity', label: 'ЮЛ исполнителя' },
  { id: 'manager', label: 'Отправитель' },
  { id: 'doc', label: 'ДОК' },
  { id: 'id', label: 'ID' },
  { id: 'purpose', label: 'Назначение платежа' },
  { id: 'is_pp', label: 'Нужно ПП' },
  { id: 'status', label: 'Статус' },
  { id: 'date', label: 'Дата оплаты' },
  { id: 'sum', label: 'Сумма' },
  { id: 'contract_status', label: 'Статус договора' },
  { id: 'type', label: 'Тип платежа' },
  { id: 'comment', label: 'Комментарий' },
];

export const STATUS_QUANTITY = [0, 4, 2, 3, 1, 5, 6];
export const STATUS_QUANTITY_FINANCE = [0, 1, 2, 6, 4, 5, 3];

export const HEADERS_GOOGLE_EXPORT_COSTS = [
  { id: 'project_id', label: '№' },
  { id: 'project_name', label: 'Название проекта' },
  { id: 'client_legal_name', label: 'ЮЛ заказчика' },
  { id: 'contractor_legal_name', label: 'ЮЛ исполнителя' },
  { id: 'inn', label: 'ИНН(ЮЛ исполнителя)' },
  { id: 'ogrnip', label: 'ОГРН(ИП)' },
  { id: 'kpp', label: 'КПП' },
  { id: 'address', label: 'Юридический адрес' },
  { id: 'passport_details', label: 'Паспорт' },
  { id: 'rc', label: 'Р/С' },
  { id: 'bank', label: 'Банк исполнителя' },
  { id: 'bik', label: 'БИК' },
  { id: 'kc', label: 'К/С' },
  { id: 'id', label: '№ платежа(ID)' },
  { id: 'purpose', label: 'Назначение платежа' },
  { id: 'is_pp', label: 'Нужно ПП' },
  { id: 'status', label: 'Статус заявки' },
  { id: 'type', label: 'Тип платежа' },
  { id: 'responsible', label: 'Отправитель' },
  { id: 'doc_manager', label: 'ДОК' },
  { id: 'sent_date', label: 'Дата отправки заявки (дата)' },
  { id: 'sent_time', label: 'Дата отправки договора (время)' },
  { id: 'transfer_into_payment_date', label: 'Дата передачи в оплату (дата)' },
  { id: 'transfer_into_payment_time', label: 'Дата передачи в оплату (время)' },
  { id: 'contract_number', label: '№ договора' },
  { id: 'file_links', label: 'Ссылка на документы' },
  { id: 'contract_status', label: 'Статус договора' },
  { id: 'closed_status', label: 'Статус закрывающих документов' },
  { id: 'date', label: 'Порядок оплаты (дата)' },
  { id: 'sum', label: 'Порядок оплаты (сумма)' },
  { id: 'payment_dates', label: 'Фактическая оплата' },
  { id: 'is_nds', label: 'НДС' },
  { id: 'nds', label: 'Сумма НДС' },
  { id: 'pp_file_link', label: 'Ссылка на ПП' },
  { id: 'payment_method', label: 'Способ оплаты' },
  { id: 'recipient_phone', label: 'Номер телефона получателя' },
  { id: 'recipient_card', label: 'Номер карты получателя' },
  { id: 'recipient_name', label: 'ФИО получателя' },
];
