export const stylesDropDownMenu = {
  minWidth: '123px',
  height: '166px',
  background: '#FFFFFF',
  boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.2)',
  borderRadius: '5px',
  inset: '0px 0px 0px -50px',
};

export const userStatuses = {
  online: 'Онлайн',
  offline: 'Оффлайн',
  vacation: 'Отпуск',
  sickLeave: 'Больничный',
};
