// @ts-nocheck
// userStatus.jsx

import React, { memo } from 'react';

import classes from './UserStatusIcon.module.scss';

function UserStatusIcon(props) {
  const {
    element,
  } = props;

  let dropDownToggleStyles;

  switch (element?.status?.name) {
    case 'Оффлайн':
      dropDownToggleStyles = classes.offline;
      break;
    case 'Онлайн':
      dropDownToggleStyles = classes.online;
      break;
    case 'Отпуск':
      dropDownToggleStyles = classes.vacation;
      break;
    case 'Больничный':
      dropDownToggleStyles = classes.sickLeave;
      break;
    default:
      dropDownToggleStyles = undefined;
  }

  return (
    <div className={classes.user__status}>
      <div className={`${classes.status__button} ${dropDownToggleStyles}`}>
        {element?.status?.name}
      </div>
    </div>
  );
}

export default memo(UserStatusIcon);
