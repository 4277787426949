// @ts-nocheck
import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../../index';

import InputElement from 'components/UI/InputElements/InputElement';
import Section from 'components/UI/Section/Section';

import classes from '../OneCostPage.module.scss';

const COSTS_TYPES = {
  CONTRACOTOR_PAYMENT: 'Оплата исполнителю',
  SK_EXPENSE_PAYMENT: 'СК расход',
  ADDITIONAL_PAYMENT: 'Дополнительный расход',
  UNDEFINED_PAYMENT: 'Не указан',
};

const CommonInfo = observer((props) => {
  const { commonInfo, id } = props;

  const { user } = useContext(Context);

  return (
    <Section title="Общая информация">
      <div className={classes.wrapper}>
        <InputElement
          text="№ проекта"
          value={commonInfo?.project?.project_id || ''}
          textClassName="textClassName2"
          disabled
          style={{ color: '#212529' }}
        />

        <InputElement
          style={{ color: '#212529' }}
          text="Название проекта"
          value={commonInfo?.project?.name}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="Отправитель"
          value={commonInfo?.responsible?.fullname}
          textClassName="textClassName2"
          disabled
        />

        {commonInfo?.type?.name === COSTS_TYPES.CONTRACOTOR_PAYMENT && (
        <InputElement
          style={{ color: '#212529' }}
          text="ДОК"
          value={commonInfo?.contract?.doc_manager[0]?.fullname || ''}
          textClassName="textClassName2"
          disabled
        />
        )}

        <InputElement
          style={{ color: '#212529' }}
          text="Назначение платежа"
          value={commonInfo?.purpose}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="Тип платежа"
          value={commonInfo?.is_contract ? 'По договору' : 'Без договора'}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="Дата отправки заявки"
          type="date"
          value={commonInfo?.sent_datetime}
          textClassName="textClassName2"
          date
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="Дата передачи в оплату"
          type="date"
          value={commonInfo?.transfer_into_payment_datetime}
          textClassName="textClassName2"
          date
          disabled
        />

      </div>
    </Section>
  );
});

export default CommonInfo;
