// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { getLegalEntities } from 'API/LegalEntityAPI';
import { check } from 'API/UserAPI';

import ModalCreateEntity from 'components/UI/Modals/ModalCreateEntity/ModalCreateEntity';
import PageTitle from 'components/UI/PageTitle/PageTitle';

import NameFilter from '../../UI/NameFilter/NameFilter';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from 'hooks/useLogout';

import AllLegalEntitiesWindow from './components/AllLegalEntitiesWindow';
import LegalElement from './components/LegalEntitiesList';

// import AllBloggersWindow from './components/AllBloggersWindow';
import classes from './LegalEntityPage.module.scss';

const LegalEntityPage = observer(() => {
  const { modal } = useContext(Context);
  const [logout] = useLogout();
  const [allLegalEntities, setAllLegalEntities] = useState([]);

  const [update, setUpdate] = useState(false);

  const [counter, setCounter] = useState('');

  const [fetchLegalEntities] = useFetching(async (filter) => {
    const data = await getLegalEntities({ filter });
    setAllLegalEntities(data);
    setCounter(data.length);
  });

  useEffect(() => {
    check()
      .then(() => {
        fetchLegalEntities();
      })
      .catch((error) => logout(error));
  }, []);

  const click = (event, legal_name) => {
    if (event.key === 'Enter' || event.type === 'click') {
      check()
        .then(() => {
          fetchLegalEntities(legal_name).then(() => {
            if (legal_name) {
              setUpdate(true);
            }
          });
        })
        .catch((error) => logout(error));
    }
  };

  const openHandler = () => {
    modal.setModalCreateEntityVisible(true);
    // modal.setModalCreateEntityProps({ fetchBloggers });
  };

  return (
    <>
      <PageTitle>Юридические лица</PageTitle>
      <div className={classes.container}>

        <div style={{ width: '100%' }}>
          <NameFilter
            list={allLegalEntities}
            click={click}
            type="legal_details"
          />

          <LegalElement
            array={allLegalEntities}
            update={update}
            setUpdate={setUpdate}
            legalEntity
          />

        </div>

        <AllLegalEntitiesWindow
          openHandler={openHandler}
          counter={counter}
        />
      </div>

      <ModalCreateEntity
        show={modal.modalCreateEntityVisible}
        onHide={() => modal.setModalCreateEntityVisible(false)}
        // props={modal.modalCreateEntityProps}
      />
    </>
  );
});

export default LegalEntityPage;
