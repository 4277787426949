// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { patchBid } from 'API/ApplicationAPI';

import NewAddBtn from 'components/UI/Btns/NewAddBtn';
import CheckboxElement from 'components/UI/CheckboxItem/CheckboxElement';
import CheckboxItem from 'components/UI/CheckboxItem/CheckboxItem';

import { generateIdFromNumber, generateUniqueID } from 'utils/generateUniqueID';

import { list, STATUS_QUANTITY } from '../consts';

import InputElement from '../../../InputElements/InputElement';

import ContractElement from './ContractElement';
import PublicationItem from './PublicationItem';

import classes from '../ModalCreateContract.module.css';

const PublicationElement = React.memo((props) => {
  const {
    publication,
    index,
    setPublicationElement,
    deletePlatform,
    dataProps,
    show,
    blogger,
    publicationList,
    saveDraftHandler,
    platformIndex,
  } = props;

  const [link, setLink] = useState('');
  const [types, setTypes] = useState([]);

  const [isAssignment, setIsAssignment] = useState(false);
  const [assignmentType, setAssignmentType] = useState({
    id: 0,
    name: 'Отчуждение',
  });

  const [assignmentComment, setAssignmentComment] = useState('');

  const [isRepost, setIsRepost] = useState(false);
  const [repostComment, setRepostComment] = useState('');

  const addType = (type) => {
    setTypes([
      ...types,
      {
        type: { id: 0 },
        amount: '',
        additional_amount: 0,
        timing: '',
        additional_timing: 0,
        manual_type: '',
        channel: '',
        non_removal_period: '',
        section: '',
        video_hosting: '',
        contract_item: '',
      },
    ]);
  };

  const deleteType = (index) => {
    if (index === 0) {
      return;
    }
    setTypes(types.filter((_, idx) => idx !== index));
  };

  const changeType = (
    amount,
    additional_amount,
    timing,
    additional_timing,
    channel,
    manual_type,
    non_removal_period,
    non_removal_period_units,
    section,
    video_hosting,
    contract_item,
    newType,
    index,
  ) => {
    setTypes(
      types.map((element, idx) => (idx === index
        ? {
          ...element,
          amount,
          additional_amount,
          timing,
          additional_timing,
          channel,
          manual_type,
          non_removal_period,
          non_removal_period_units,
          section,
          video_hosting,
          contract_item,
          type: newType,
        }
        : element)),
    );
  };

  // Проверка на наличие такого же типа публикации
  const isActive = (type) => {
    let active = false;
    types.forEach((element) => {
      if (element?.type?.id === type) {
        active = true;
      }
    });

    return active;
  };

  useEffect(() => {
    let id;
    publication.tempId ? (id = publication.tempId) : (id = publication.id);
    setPublicationElement(id, {
      link,
      publication: types,
      is_assignment: isAssignment,
      assignment_type: { id: assignmentType?.id, name: assignmentType?.name },
      assignment_comment: assignmentComment,
      is_repost: isRepost,
      repost_comment: repostComment,
    });
  }, [
    link,
    types,
    isAssignment,
    assignmentType,
    assignmentComment,
    isRepost,
    repostComment,
  ]);

  useEffect(() => {
    setLink(publication.link);
    setTypes([...types, ...publication.publication]);
    setIsAssignment(publication.is_assignment);
    setAssignmentType(publication.assignment_type.name
      ? publication.assignment_type
      : list.find((el) => el.id === publication.assignment_type.id));
    setAssignmentComment(publication.assignment_comment);
    setIsRepost(publication.is_repost);
    setRepostComment(publication.repost_comment);
  }, [dataProps, show]);

  const publicationNumbers = (index) => publicationList
    .filter((item) => (item.platformIndex === platformIndex) && (item.publicationIndex === index))
    .map((item) => item.publicationNumber);

  return (
    <div style={index > 0 ? { marginTop: 20 } : {}}>
      <InputElement
        text="Ссылка"
        placeholder="Площадка размещения"
        value={link}
        setValue={setLink}
        cancelBtn
        deleteHandler={() => deletePlatform(publication.tempId || publication.id)}
        left={672}
      />

      <div
        className={classes.contract__element}
        style={{ alignItems: 'start' }}
      >
        <div className={classes.element__text}>Формат публикаций</div>

        <div className={classes.publication__inner}>
          {types?.map((element, elementIndex) => (
            <PublicationItem
              key={element.id || elementIndex}
              publication={element}
              addType={addType}
              deleteHandler={deleteType}
              change={changeType}
              isActive={isActive(elementIndex)}
              types={types}
              type={elementIndex}
              blogger={blogger}
              link={link}
              platformCount={index + 1}
              publicationNumber={publicationNumbers(elementIndex)}
              index={elementIndex}
              cancelBtn
            />
          ))}
        </div>
      </div>
      <div className={classes.addPublication}>
        <NewAddBtn
          text="Добавить формат публикаций"
          onClick={() => addType()}
        />
      </div>

      <ContractElement text="Передача прав">
        <div className={classes.ourСontacts__container}>
          <CheckboxElement style={{ margin: 0 }}>
            <CheckboxItem
              text="Да"
              status={isAssignment}
              setStatus={() => setIsAssignment(true)}
            />

            <CheckboxItem
              text="Нет"
              status={!isAssignment}
              setStatus={() => setIsAssignment(false)}
            />
          </CheckboxElement>

          {isAssignment && (
            <>
              <InputElement
                value={assignmentType}
                setValue={setAssignmentType}
                dropdown={list}
                style={{ margin: 0 }}
              />

              <InputElement
                type="text"
                value={assignmentComment}
                setValue={setAssignmentComment}
                placeholder="Введите текст..."
              />
            </>
          )}
        </div>
      </ContractElement>

      <CheckboxElement text="Репост">
        <CheckboxItem
          text="Да"
          status={isRepost}
          setStatus={() => setIsRepost(true)}
        />

        <CheckboxItem
          text="Нет"
          status={!isRepost}
          setStatus={() => setIsRepost(false)}
        />
      </CheckboxElement>

      {isRepost && (
        <InputElement
          type="text"
          value={repostComment}
          setValue={setRepostComment}
          placeholder="Введите текст..."
          style={{ marginLeft: 260 }}
        />
      )}
    </div>
  );
});

export default PublicationElement;
