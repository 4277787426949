import React from 'react';
import { Helmet } from 'react-helmet';

import BloggersPage from 'components/General/BloggersPage/BloggersPage';

function Bloggers() {
  return (
    <section className="Mysection">
      <Helmet>
        <title>Блогеры | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <BloggersPage />
    </section>
  );
}

export default Bloggers;
