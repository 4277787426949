import classes from '../StatusBtn/StatusBtn.module.css';

export const STATUS_COLORS_LAWYERS = {
  0: classes.btn1,
  1: classes.btn2,
  2: classes.btn3,
  3: classes.btn4,
  4: classes.btn5,
  5: classes.btn6,
  6: classes.btn7,
  7: classes.btn8,
};

export const STATUS_COLORS_LAWYERS_ACTIVE = {
  0: classes.active1,
  1: classes.active2,
  2: classes.active3,
  3: classes.active4,
  4: classes.active5,
  5: classes.active6,
  6: classes.active7,
  7: classes.active8,
};

export const STATUS_COLORS_MARKETING = {
  0: classes.btn1m,
  4: classes.btn2m,
  3: classes.btn3m,
  1: classes.btn4m,
  2: classes.btn5m,
  5: classes.btn6m,
  6: classes.btn7m,
  7: classes.btn6,
};

export const STATUS_COLORS_MARKETING_ACTIVE = {
  0: classes.active1m,
  4: classes.active2m,
  3: classes.active3m,
  1: classes.active4m,
  2: classes.active5m,
  5: classes.active6m,
  6: classes.active7m,
  7: classes.active6,
};

export const STATUS_COLORS = {
  0: classes.btn1,
  1: classes.btn8m,
  2: classes.btn3,
  3: classes.btn2,
  4: classes.btn6m,
  5: classes.btn6,
  6: classes.btn7m,
};

export const STATUS_COLORS_ACTIVE = {
  0: classes.active1,
  1: classes.active8m,
  2: classes.active3,
  3: classes.active2,
  4: classes.active6m,
  5: classes.active6,
  6: classes.active7m,
};

export const STATUS_COLORS_FINANCE = {
  0: classes.btn1,
  1: classes.btn6m,
  2: classes.btn8m,
  3: classes.btn7m,
  4: classes.btn3,
  5: classes.btn6,
  6: classes.btn2,

};

export const STATUS_COLORS_FINANCE_ACTIVE = {
  0: classes.active1,
  1: classes.active6m,
  2: classes.active8m,
  3: classes.active7m,
  4: classes.active3,
  5: classes.active6,
  6: classes.active2,
};

export const STATUS_COLORS_PROJECT_ACCOUNTING = {
  0: classes.btn9m,
  1: classes.btn10m,
  2: classes.btn11m,
  3: classes.btn12m,
  4: classes.btn13m,
  5: classes.btn6,
};

export const STATUS_COLORS_PROJECT_ACCOUNTING_ACTIVE = {
  0: classes.active9m,
  1: classes.active10m,
  2: classes.active11m,
  3: classes.active12m,
  4: classes.active13m,
  5: classes.active6,
};

export const stylesDropDownMenu = {
  minWidth: '144px',
  height: '130px',
  background: '#FFFFFF',
  boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.2)',
  borderRadius: '5px',
  inset: '0px 0px 0px 0px',
  zIndex: '3',
};
