// @ts-nocheck
import React, {
  useContext, useEffect,
  useRef, useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { addCostFile, getCostFiles } from 'API/ApplicationAPI';
import {
  deleteOneCostBid,
  deleteOneCostFile,
  getOneCostBid,
} from 'API/FinanceAPI/CostsAPI';
import { fetchStaff } from 'API/ManagerAPI/AutocompleteAPI';
import { getCommentsCost, postCommentCost } from 'API/ManagerAPI/ProjectAPI';
import { check } from 'API/UserAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import Comment from 'components/UI/Comment/Comment';
import DeleteRow from 'components/UI/DeleteRow/DeleteRow';
import Files from 'components/UI/Files/Files';
import ModalConfirm from 'components/UI/Modals/ModalConfirm/ModalConfirm';
import ModalEditPayments from 'components/UI/Modals/ModalEditPayments/ModalEditPayments';
import MyInputFile from 'components/UI/MyInput/MyInputFile';
import TextAreaAutoSize from 'components/UI/MyTextArea/TextAreaAutoSize';
import PageTitle from 'components/UI/PageTitle/PageTitle';
import Section from 'components/UI/Section/Section';
import StatusBtn from 'components/UI/StatusBtns/StatusBtn/StatusBtn';
import StatusDropdown from 'components/UI/StatusDropdown/StatusDropdown';
import UploadFilesContainer from 'components/UI/UploadFilesContainer/UploadFilesContainer';

import { useLogout } from 'hooks/useLogout';
import { useProgress } from 'hooks/useProgress';
import { useFetching } from '../../../hooks/useFetching';

import CommonInfo from './components/CommonInfo';
import FilesInfo from './components/FilesInfo';
import PaymentInfo from './components/PaymentInfo';
import PaymentInfoAdditional from './components/PaymentInfoAdditional';
import PaymentInfoContract from './components/PaymentInfoContract';

import classes from './OneCostPage.module.scss';

const COSTS_TYPES = {
  CONTRACOTOR_PAYMENT: 'Оплата исполнителю',
  SK_EXPENSE_PAYMENT: 'СК расход',
  ADDITIONAL_PAYMENT: 'Дополнительный расход',
  UNDEFINED_PAYMENT: 'Не указан',
};

const OneCostPage = observer(() => {
  const {
    modal, toast, comments, user,
  } = useContext(Context);
  const navigate = useNavigate();
  const table = useRef();
  const { id } = useParams();
  const isFirstRender = useRef(true);
  const [logout] = useLogout();

  const controller = new AbortController();

  const [fetching, setFetching] = useState(true);

  const [staffList, setStaffList] = useState([]);

  const [isVisible, setIsVisible] = useState(false);

  const [commonInfo, setCommonInfo] = useState({});

  const [files, setFiles] = useState([]);

  const [fetchFiles, isLoadingFiles] = useFetching(async (update) => {
    const fileList = await getCostFiles(id);

    setFiles(fileList.data);
  });

  const [fetchCost, isLoading] = useFetching(async (update) => {
    const serverData = await getOneCostBid(id);

    if (update === 'commonUpdate') {
      setCommonInfo(serverData);
      return;
    }

    setCommonInfo(serverData);
  });

  const totalPages = Math.ceil(
    comments.commentsTotalCount / comments.commentsLimit,
  );

  const [sentComment, setSentComment] = useState({ text: '' });

  const [fetchComments, isCommentsLoading] = useFetching(
    async (page, limit, signal, filter, update) => {
      await check().catch((error) => logout(error));

      const data = await getCommentsCost(page, limit, signal, filter, id);

      comments.setCommentsTotalCount(data.count);

      if (update === 'update' || comments.commentsPage === 1) {
        comments.setCommentsPage(2);
        comments.setComments(data.results, 'update');
      } else {
        comments.setCommentsPage(comments.commentsPage + 1);
        comments.setComments(data.results);
      }

      setFetching(false);
    },
  );

  useEffect(() => {
    if (fetching) {
      if (comments.commentsFilter) {
        fetchComments(
          comments.commentsPage,
          comments.commentsLimit,
          controller.signal,
          comments.commentsFilter,
        );
      } else {
        fetchComments(
          comments.commentsPage,
          comments.commentsLimit,
          controller.signal,
        );
      }
    }

    return () => {
      controller.abort();
    };
  }, [fetching]);

  const deleteCostHandler = () => {
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить заявку?',
      click: () => {
        check()
          .then(() => {
            deleteOneCostBid(id)
              .then(() => {
                fetchCost('commonUpdate');
              })
              .catch((err) => {
                if (err?.response?.data?.detail) {
                  toast.setToastInfo(err?.response?.data?.detail);
                  toast.setShowError(true);
                } else {
                  toast.setToastInfo('Ошибка при удалении заявки. Обратитесь в поддержку.');
                  toast.setShowError(true);
                }
              });
          })
          .catch((error) => logout(error));
      },
    });
    modal.setModalConfirmVisible(true);
  };

  const [progressFiles, addFileFunc] = useProgress(
    setIsVisible,
    fetchFiles,
    id,
    logout,
    addCostFile,
  );

  const addFileHandler = (event) => {
    const files = [...event.target.files];
    files.forEach((file) => {
      addFileFunc(file);
    });
    fetchFiles();
  };

  const deleteFileHandler = (fileId) => {
    check()
      .then(() => {
        deleteOneCostFile(fileId).then(() => {
          fetchFiles();
        });
      })
      .catch((error) => logout(error));
  };

  const [commentsFiles, setCommentsFiles] = useState([]);

  const addFileHandlerComment = (event) => {
    const addedFiles = Array.from(event.target.files);
    setCommentsFiles((prev) => [...prev, ...addedFiles]);
  };
  const removeFileHandler = (index) => {
    setCommentsFiles((prev) => prev.filter((_, i) => i !== index));
  };
  const [showUsersList, setShowUsersList] = useState(false);
  const [selectedUsersId, setSelectedUsersId] = useState([]);

  const [currentTag, setCurrentTag] = useState('');

  const formatUserName = (fullname) => {
    const [firstName, lastName] = fullname.split(' ');
    return `@${firstName}_${lastName}`;
  };

  const leaveCommentOnChange = (e) => {
    setSentComment({ text: e.target.value });

    const lastIndex = e.target.value.lastIndexOf('@');

    if (lastIndex !== -1) {
      const substring = e.target.value.slice(lastIndex);
      const match = substring.match(/@([a-zA-Zа-яА-ЯёЁ0-9_]*)/);

      if (match) {
        setShowUsersList(true);
        setCurrentTag({
          value: match[1],
          index: lastIndex, // сохраняем индекс
        });
        return;
      }
    }

    setShowUsersList(false);
    setCurrentTag(null);
  };

  const leaveCommentHandler = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();

      let finalCommentText = sentComment.text;

      selectedUsersId.forEach((userObj) => {
        const user = staffList.find((u) => u.id === userObj.id);
        if (user) {
          const regex = new RegExp(formatUserName(user.fullname), 'g');
          finalCommentText = finalCommentText.replace(
            regex,
            `<b style="color:#99B3FF;">@${user.fullname}</b>`,
          );
        }
      });

      const data = new FormData();

      commentsFiles.forEach((file, index) => {
        data.append(file.name, file);
      });

      const body = { text: finalCommentText, tags: selectedUsersId };

      data.append('data', JSON.stringify(body));

      postCommentCost(id, data).then(() => {
        setSentComment({ text: '' });
        setSelectedUsersId([]);
        toast.setToastInfo('Комментарий отправлен');
        toast.setShowSuccess(true);
        setFetching(true);
        setCommentsFiles([]);
        comments.setCommentsPage(1);
      });
    }
  };

  const commentRef = useRef(null);

  const handleUserSelect = (user) => {
    if (!currentTag) return;

    const formattedName = formatUserName(user.fullname);

    // Обновляем текст, основываясь на индексе, где начался тег
    const newUserText = [
      sentComment.text.slice(0, currentTag.index), // текст до @
      formattedName,
      sentComment.text.slice(currentTag.index + currentTag.value.length + 1), // текст после @
    ].join('');

    setSentComment({ text: newUserText });
    setSelectedUsersId((prevUsers) => [...prevUsers, { id: user.id }]);
    setShowUsersList(false);
    setCurrentTag(null); // сбрасываем значение после использования
  };

  useEffect(() => {
    if (!showUsersList) {
      if (commentRef.current) {
        commentRef.current.focus();
      }
    }
  }, [commentRef, showUsersList]);

  const scrollHandler = (event) => {
    if (
      event.target.scrollHeight
        - (event.target.scrollTop + event.target.clientHeight)
        < 600
      && comments.commentsPage <= totalPages
    ) {
      setFetching(true);
    }
  };

  // Наблюдение за скролломы
  useEffect(() => {
    if (isCommentsLoading) return;
    if (table.current) {
      const element = table.current;
      element.addEventListener('scroll', scrollHandler);
      return function () {
        element.removeEventListener('scroll', scrollHandler);
      };
    }
  }, [isCommentsLoading]);

  useEffect(() => {
    check()
      .then(() => {
        fetchCost();
        fetchFiles();
        fetchStaff().then((staff) => setStaffList(staff));
      })
      .catch((error) => logout(error));
  }, []);

  if (isFirstRender.current) {
    if (isLoading) {
      return <Spinner animation="grow" />;
    }
    isFirstRender.current = false;
  }

  return (
    <>
      <UploadFilesContainer
        progressFiles={progressFiles}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />

      <div className={classes.header}>
        <PageTitle>
          <SuccessBtn
            text="Вернуться назад"
            onClick={() => navigate(-1)}
            type="white"
            back
            style={{
              padding: '4px 10px 4px 16px',
              fontSize: '12px',
            }}
          />
          <span className={classes.text}>
            № {id} Платежное поручение
          </span>

          {(commonInfo?.status?.id === 4 || user.department === 2 || user.department === 3) ? (
            <StatusDropdown
              updateInfo={fetchCost}
              status={user.department !== 2 ? commonInfo?.status?.id : commonInfo?.financial_status?.id}
              financeCost
              id={id}
            />
          ) : (
            <StatusBtn
              status={user.department !== 2 ? commonInfo?.status?.id : commonInfo?.financial_status?.id}
              className={classes.statusBtn}
              financeCost
            />

          )}

        </PageTitle>
      </div>

      <CommonInfo commonInfo={commonInfo} id={id} />

      {/* ДОПОЛНИТЕЛЬНАЯ ОПЛАТА БЕЗ ДОГОВОРА */}
      {commonInfo?.type?.name === COSTS_TYPES.ADDITIONAL_PAYMENT && <PaymentInfoAdditional fetchCost={fetchCost} commonInfo={commonInfo} setCommonInfo={setCommonInfo} id={id} /> }

      {/* ВСЕ ОСТАЛЬНОЕ ПО ДОГОВОРУ */}
      {(commonInfo?.type?.name !== COSTS_TYPES.ADDITIONAL_PAYMENT && commonInfo?.is_contract) && <PaymentInfoContract fetchCost={fetchCost} commonInfo={commonInfo} setCommonInfo={setCommonInfo} id={id} /> }

      {/* ВСЕ ОСТАЛЬНОЕ БЕЗ ДОГОВОРА */}
      {commonInfo?.type?.name !== COSTS_TYPES.ADDITIONAL_PAYMENT && !commonInfo?.is_contract && <PaymentInfo fetchCost={fetchCost} commonInfo={commonInfo} setCommonInfo={setCommonInfo} id={id} /> }

      <FilesInfo
        files={files}
        addFileHandler={addFileHandler}
        deleteFileHandler={deleteFileHandler}
      />

      <Section title="Комментарии">
        <TextAreaAutoSize
          ref={commentRef}
          placeholder="Введите текст..."
          value={sentComment.text}
          onChange={leaveCommentOnChange}
          onKeyDown={(e) => leaveCommentHandler(e)}
          style={{ marginBottom: '20px', maxHeight: '100px' }}
        />
        {commentsFiles?.length > 0 && (
        <div className={classes.files} style={{ marginBottom: '20px' }}>
          {commentsFiles.map((file, index) => (
            <Files
              fileNameStyle={{ width: '100px' }}
              key={index}
              file={file}
              deleteFileHandler={() => removeFileHandler(index)}
            />
          ))}
        </div>
        )}
        {showUsersList && (
        <div className={classes.usersList}>
          {staffList
            .filter((user) => {
              if (!currentTag) return true;
              return user.fullname.toLowerCase().includes(currentTag?.value?.toLowerCase());
            })
            .map((user) => (
              <div
                key={user.id}
                onClick={() => handleUserSelect(user)}
              >
                {user.fullname}
              </div>
            ))}
        </div>
        )}
        <MyInputFile
          htmlFor="comments"
          text="Добавить файл"
          onChange={(event) => addFileHandlerComment(event)}
          multiple
          accept=".ppt, .pptx, .xls, .xlsx, .doc, .docx, .jpeg, .jpg, .png, .pdf, .key, .heif"
        />

        <div className={classes.comments__container} ref={table}>
          {comments.comments?.map((comment, i) => (
            <Comment
                  // eslint-disable-next-line react/no-array-index-key
              key={i}
              data={comment}
            />
          ))}
        </div>
      </Section>

      {commonInfo?.type?.name === COSTS_TYPES.ADDITIONAL_PAYMENT && commonInfo?.status?.id !== 3 && (
        <DeleteRow
      // Делаем неактивной кнопку для статуса "Оплачено"
          disabled={commonInfo.financial_status.id === 4}
          title="Удаление заявки"
          deleteProjectHandler={deleteCostHandler}
        />
      )}

      <ModalConfirm
        show={modal.modalConfirmVisible}
        onHide={() => modal.setModalConfirmVisible(false)}
        props={modal.modalConfirmProps}
      />

      <ModalEditPayments
        fetchCost={fetchCost}
        show={modal.modalCreateProjectVisible}
        onHide={() => modal.setModalCreateProjectVisible(false)}
        props={modal.modalCreateProjectProps}
      />
    </>
  );
});

export default OneCostPage;
