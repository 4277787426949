// @ts-nocheck
import React, { useContext, useState } from 'react';
import { Context } from 'index';

import { postClient } from 'API/ClientAPI';
import { check } from 'API/UserAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import CLientInfo from 'components/UI/CLientInfo/CLientInfo';
import ContactInfo from 'components/UI/ContactInfo/ContactInfo';
import MyModal from 'components/UI/MyModal/MyModal';

import { useStateNew2 } from '../../../../hooks/useStateNew2';

import { CLIENT_TYPES } from 'utils/consts';
import { getDate } from 'utils/getDate';

import classes from './ModalCreateClient.module.css';

const defaultContacts = {
  id: Date.now(),
  fullname: '',
  email: '',
  phone: '',
  telegram: '',
  post: '',
  birthday: '',
};

function ModalCreateClient({ show, onHide, props }) {
  const {
    fetchBrands,
    fetchMediaAgency,
    fetchIndependentAgency,
    fetchClientsStat,
    fetchClientsList,
  } = props;

  const { toast } = useContext(Context);

  const [name, setName] = useState('');

  const [typeClient, setTypeClient] = useState({
    id: null,
    name: '',
  });

  const [contacts, setContacts, updateContacts, addContact, deleteContact] = useStateNew2([
    defaultContacts,
  ]);

  const closeModal = () => {
    setTimeout(() => {
      setContacts([defaultContacts]);
      setTypeClient({ id: null, name: '' });
      setName('');
    }, 140);
    onHide();
  };

  const create = () => {
    const contactsList = [];

    contacts.forEach((contact) => {
      contactsList.push({
        fullname: contact.fullname,
        email: contact.email,
        phone: contact.phone,
        telegram: contact.telegram,
        post: contact.post,
        birthday: contact.birthday ? getDate(contact.birthday) : '',
      });
    });

    if (!name) {
      toast.setShowError(true);
      toast.setToastInfo('Наименование не может быть пустым');
      return;
    }

    const data = {
      name,
      type: { id: typeClient.id },
      contacts: contactsList,
    };

    check().then(() => {
      postClient(data)
        .then(() => {
          fetchClientsStat();
          fetchClientsList();

          if (typeClient.id === 0) {
            fetchBrands();
          } else if (typeClient.id === 1) {
            fetchMediaAgency();
          } else if (typeClient.id === 2) {
            fetchIndependentAgency();
          }
          toast.setShowSuccess(true);
          toast.setToastInfo('Новый клиент создан');
          closeModal();
        })
        .catch((error) => {
          toast.setShowError(true);
          if (error.response.data.contacts) {
            toast.setToastInfo('Введите правильный email');
          } else if (error.response.data.legal_entity) {
            toast.setToastInfo('Выберите форму ЮЛ');
          } else if (error.response.data.name) {
            toast.setToastInfo('Клиент с таким наименованием уже создан');
          } else if (error.response.data.phone) {
            toast.setToastInfo('Номер телефона не может быть длиннее 20 символов');
          } else {
            toast.setToastInfo('Заполните все поля со звездочкой');
          }
        });
    });
  };

  return (
    <MyModal show={show} onHide={closeModal} style={{ minHeight: '60vh' }}>
      <h1 className={classes.new}>Новый клиент</h1>

      <div className={classes.wrapper}>
        <header className={classes.header}>Общая информация</header>

        <CLientInfo
          name={name}
          setName={setName}
          typeClient={typeClient}
          setTypeClient={setTypeClient}
          typeClientList={CLIENT_TYPES.LIST}
          noDelete
          noHeader
        />

        <ContactInfo
          contacts={contacts}
          updateContacts={updateContacts}
          addContact={addContact}
          removeContact={deleteContact}
          sectionClassName={classes.section_header}
          titleClassName={classes.section_title}
          contentClassName={classes.contacts}
          className={classes.contacts}
          inModal
          noClose
        />
      </div>

      <div className={classes.btn__container}>
        <SuccessBtn
          text="Добавить клиента"
          style={{ padding: '4px 15px', fontSize: 13 }}
          onClick={create}
        />
      </div>
    </MyModal>
  );
}

export default ModalCreateClient;
