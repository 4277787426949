import React from 'react';
import { Modal } from 'react-bootstrap';

import SuccessBtn from '../Btns/SuccessBtn';

import classes from './MyModal.module.css';

function MyModal(props) {
  const {
    show, onHide, children, style, type, noClose, createUser, buttonBack, backPage, buttonStyle,
    classNameDialogProps, classNameCloseProps,
  } = props;

  let classNameContent = '';
  let classNameBody = '';
  let classNameDialog = '';
  let classNameClose = '';

  if (type === 'confirm') {
    classNameContent = classes.modal_content2;
    classNameBody = classes.modal_body2;
    classNameDialog = classes.modal_dialog2;
    classNameClose = classes.close;
  } else if (type === 'projectAccounting') {
    classNameContent = classes.modal_content3;
    classNameBody = classes.modal_body3;
    classNameDialog = classes.modal_dialog3;
    classNameClose = classes.close3;
  } else {
    classNameContent = classes.modal_content1;
    classNameBody = classes.modal_body1;
    classNameDialog = classes.modal_dialog1;
    classNameClose = classes.close;
  }

  const onClickHandler = () => {
    if (!backPage) {
      onHide();
    } else {
      backPage();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      contentClassName={classNameContent}
      dialogClassName={[classNameDialog, classNameDialogProps].join(' ')}
    >
      {!buttonBack ? (!noClose
      && <div className={[classNameClose, classNameCloseProps].join(' ')} onClick={() => onHide()} />
      ) : (
        !noClose
        && (
        <SuccessBtn
          createUser
          text="Вернуться назад"
          onClick={() => onClickHandler()}
          type="white"
          back
          style={buttonStyle || {
            top: '38px',
            left: '60px',
            width: '150px',
            height: '27px',
            padding: '0px',
            fontSize: '12px',
            zIndex: '3',
          }}
        />
        )
      )}
      <Modal.Body style={style} className={classNameBody}>
        {children}
      </Modal.Body>
    </Modal>
  );
}

export default MyModal;
