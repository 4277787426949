// @ts-nocheck
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import {
  fetchNotifications,
  setAllAsSeenNotifications,
  setFewAsSeenNotifications,
  setFewAsUnseenNotifications,
} from 'API/LawyersAPI/NotificationAPI';
import { check } from 'API/UserAPI';

import MyImageDropdown from 'components/UI/MyImageDropdown/MyImageDropdown';
import NotificationContainer from 'components/UI/NotificationContainer/NotificationContainer';
import PageTitle from 'components/UI/PageTitle/PageTitle';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from 'hooks/useLogout';

import SeenNotifications from '../../../assets/icons/Btns/seen_notifications.svg';
import Settings from '../../../assets/icons/Btns/settings_icon.svg';
import UnseenNotifications from '../../../assets/icons/Btns/Unseen_notifications.svg';

import classes from './NotificationList.module.scss';

const dropdownList = [
  { id: 1, name: 'Выбрать' },
  { id: 2, name: 'Выбрать все' },
  { id: 3, name: 'Показать только прочитанные' },
  { id: 4, name: 'Показать только непрочитанные' },
  { id: 5, name: 'Показать все уведомления' },
];

const NotificationList = observer((props) => {
  const { notifications, toast } = useContext(Context);

  const [logout] = useLogout();

  const table = useRef();
  const isFirstRender = useRef(true);

  const controller = new AbortController();

  const [selectedSetting, setSelectedSetting] = useState('');
  const [selectedNotifications, setSelectedNotifications] = useState(new Set());
  const [fetching, setFetching] = useState(true);

  const [fetchingNotifications, isNotificationsLoading] = useFetching(
    async (page, limit, signal, filter, update) => {
      await check().catch((error) => logout(error));

      const data = await fetchNotifications(page, limit, signal, filter);

      notifications.setNotificationsTotalCount(data.count);

      if (update === 'update' || notifications.notificationsPage === 1) {
        notifications.setNotificationsPage(2);
        notifications.setNotifications(data.results, 'update');
      } else {
        notifications.setNotificationsPage(notifications.notificationsPage + 1);
        notifications.setNotifications(data.results);
      }

      setFetching(false);
    },
  );

  const totalPages = Math.ceil(
    notifications.notificationsTotalCount / notifications.notificationsLimit,
  );

  /* Запрос на сервер при скролле до конца таблицы */

  useEffect(() => {
    if (fetching) {
      if (notifications.notificationsFilter) {
        fetchingNotifications(
          notifications.notificationsPage,
          notifications.notificationsLimit,
          controller.signal,
          notifications.notificationsFilter,
        );
      } else {
        fetchingNotifications(
          notifications.notificationsPage,
          notifications.notificationsLimit,
          controller.signal,
          {},
        );
      }
    }

    return () => {
      controller.abort();
    };
  }, [fetching]);

  /* Когда доскролили до нижней части таблицы (первое условие)
  Проверяем текущую страницу с общим количеством страниц(второе условие)
  Далее делаем запрос
  */
  const scrollHandler = (event) => {
    if (
      event.target.scrollHeight
        - (event.target.scrollTop + event.target.clientHeight)
        < 600
      && notifications.notificationsPage <= totalPages
    ) {
      setFetching(true);
    }
  };

  // Наблюдение за скролломы
  useEffect(() => {
    if (isNotificationsLoading) return;
    if (table.current) {
      const element = table.current;
      element.addEventListener('scroll', scrollHandler);
      return function () {
        element.removeEventListener('scroll', scrollHandler);
      };
    }
  }, [isNotificationsLoading]);

  const setAllAsSeenHandler = () => {
    setAllAsSeenNotifications()
      .then(() => {
        notifications.setIsNewNotifications(0);
        notifications.markAllNotificationsAsRead();
        toast.setToastInfo('Все уведомления прочитаны');
        toast.setShowSuccess(true);
      });
  };

  if (isNotificationsLoading && isFirstRender.current) {
    isFirstRender.current = false;
    return <Spinner animation="grow" />;
  }

  // Обработчик для чекбоксов уведомлений
  const handleNotificationCheck = (notificationId, isChecked) => {
    setSelectedNotifications((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (isChecked) {
        newSelected.add(notificationId);
      } else {
        newSelected.delete(notificationId);
      }
      return newSelected;
    });
  };

  // Обработчик для кнопок отправки запроса
  const handleSendNotifications = async (type) => {
    if (type === 'seen') {
      await setFewAsSeenNotifications([...selectedNotifications]);
      notifications.markFewNotificationsAsRead([...selectedNotifications]);
      toast.setToastInfo('Уведомления отмечены как прочитанные');
      toast.setShowSuccess(true);
    } else if (type === 'unseen') {
      await setFewAsUnseenNotifications([...selectedNotifications]);
      notifications.markFewNotificationsAsUnread([...selectedNotifications]);
      toast.setToastInfo('Уведомления отмечены как непрочитанные');
      toast.setShowSuccess(true);
    }
    setSelectedSetting('');
    setSelectedNotifications(new Set());
  };

  return (
    <>
      <div className={classes.header}>
        <PageTitle style={{ marginBottom: '30px' }}>Уведомления</PageTitle>
        <div className={classes.buttons__container}>
          {(selectedSetting === 'Выбрать' || selectedSetting === 'Выбрать все') && (
            <>
              <div className={classes.buttons__container__unseen}>
                <img alt="unseen" src={UnseenNotifications} onClick={() => handleSendNotifications('unseen')} />
                <span className={classes.tooltip}>Отметить как непрочитанные уведомления</span>
              </div>
              <div className={classes.buttons__container__seen}>
                <img alt="seen" src={SeenNotifications} onClick={() => handleSendNotifications('seen')} />
                <span className={classes.tooltip}>Отметить как прочитанные уведомления</span>
              </div>
            </>

          )}
          <MyImageDropdown
            image={Settings}
            selected={selectedSetting}
            setSelected={setSelectedSetting}
            list={dropdownList}
            setAllAsSeenHandler={setAllAsSeenHandler}
            setSelectedNotifications={setSelectedNotifications}
            fetch={fetchingNotifications}
          />
        </div>
      </div>
      {!notifications.notifications.length
        ? <h2>Уведомлений нет</h2>
        : (
          <div className={classes.notifications__container} ref={table}>
            {notifications?.notifications.map((notification) => (
              <NotificationContainer
                key={notification.id}
                selectedSetting={selectedSetting}
                selectedNotifications={selectedNotifications}
                handleNotificationCheck={handleNotificationCheck}
                notification={notification}
                isNew={notification.is_new}
              />
            ))}
          </div>
        )}
    </>
  );
});

export default NotificationList;
