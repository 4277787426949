// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { patchOneContract } from 'API/LawyersAPI/ContractAPI';

import NewAddBtn from 'components/UI/Btns/NewAddBtn';
import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import InputElement from 'components/UI/InputElements/InputElement';
import LawyerSection from 'components/UI/Section/LawyerSection';

import ContractStatusBlock from '../../../UI/ContractStatusBlock/ContractStatusBlock';

import { useLogout } from 'hooks/useLogout';
import { useStateNew2 } from 'hooks/useStateNew2';

import { getDate } from 'utils/getDate';
import { getSum, getSumRuble, numberToSum } from 'utils/getSums';
import { isValidURL } from 'utils/isValidUrl';

import classes from '../ProjectPage.module.scss';

function PaymentInfo(props) {
  const {
    project, save, info, fetchContract,
  } = props;

  const [activeBtn, setActiveBtn] = useState(false);

  const [paid, setPaid] = useState('');

  const [completedPayments, setCompletedPayments] = useState([]);

  const [startQuarter, setStartQuarter] = useState('');
  const [endQuarter, setEndQuarter] = useState('');

  const [oneQuarter, setOneQuarter] = useState(true);
  const [isAdesk, setIsAdesk] = useState(project.payment.adesk);
  const [conditions, setConditions] = useState('');

  const [comment, setComment] = useState('');

  const [status, setStatus] = useState({});
  const [statusDate, setStatusDate] = useState('');
  const [statusSum, setStatusSum] = useState('');
  const [currentLink, setCurrentLink] = useState('');

  const onChangeLink = (event) => {
    setCurrentLink(event.target.value);
    const sentData = {
      file_links: event.target.value,
    };
    patchOneContract(info?.id, sentData)
      .then(() => {
        fetchContract().then(() => {
        });
      });
  };

  useEffect(() => {
    if (info.file_links) {
      setCurrentLink(info.file_links);
    }
  }, [info.file_links]);

  const updateContractStatusValue = (id, fieldName, value) => {
    if (fieldName === 'status') {
      setStatus(value);
      setStatusDate(getDate(Date.now()));
    } else if (fieldName === 'sum') {
      setStatusSum(value);
    } else if (fieldName === 'date') {
      setStatusDate(value);
    }
  };

  const [billStatus, setBillStatus, updateBillStatus, addBillStatus, deleteBillStatus] = useStateNew2([{
    fieldId: Date.now(), id: null, date: '', name: '', type_id: 2, sum: '',
  }]);

  const [closingStatus, setClosingStatus, updateClosingStatus, addClosingStatus, deleteClosingStatus] = useStateNew2([{
    fieldId: Date.now(),
    id: null,
    date: getDate(info.date_end),
    status_date: '',
    closing_type_name: '',
    closing_type_id: 8,
    sum: '',
    quarter: '',
  }]);

  const [closingBalance, setClosingBalance] = useState('');

  const automaticallysSetPaid = (value) => {
    setPaid(getSumRuble(value));
  };

  const clickHandler = () => {
    setActiveBtn(true);
  };

  const cancelHandler = () => {
    if (project.id) {
      setActiveBtn(false);

      setPaid(numberToSum(project?.payment?.paid_sum));
      setCompletedPayments(project?.payment?.completed_payments);

      setStartQuarter(project?.payment?.quarter_start);
      setEndQuarter(project?.payment?.quarter_end);

      setOneQuarter(project?.payment?.is_quarter_closing);
      setConditions(project?.payment?.closing_condition);

      setComment(project?.payment?.comment);

      setStatus(project?.status);
      setStatusDate(project?.status_date);
      setStatusSum(project?.status_sum);
      setBillStatus(project?.bill_status);
      setClosingStatus(project?.closing_info);

      setClosingBalance(project?.closing_balance);
    }
  };

  useEffect(() => {
    if (project.id) {
      setPaid(numberToSum(project?.payment?.paid_sum));
      setCompletedPayments(project?.payment?.completed_payments);

      setStartQuarter(project?.payment?.quarter_start);
      setEndQuarter(project?.payment?.quarter_end);

      setOneQuarter(project?.payment?.is_quarter_closing);
      setConditions(project?.payment?.closing_condition);

      setComment(project.payment.comment);

      setStatus(project?.status);
      setStatusDate(project?.status_date);
      setStatusSum(project?.status_sum);

      if (project?.bill_status.length > 0) {
        setBillStatus(project?.bill_status);
      }

      if (project?.closing_info.length > 0) {
        setClosingStatus(project?.closing_info);
      }

      setClosingBalance(project?.closing_balance);
    }
  }, [project]);

  const savePaymentHandler = () => {
    const data = {
      adesk: isAdesk,
      is_quarter_closing: oneQuarter,
      closing_condition: conditions,
      comment,
      status: { id: status.id },
      status_date: statusDate ? getDate(statusDate) : '',
      status_sum: typeof statusSum === 'string' ? getSum(statusSum) : statusSum,
    };

    billStatus.forEach((element) => {
      delete element.contract;
      element.date = element.date ? getDate(element.date) : '';
      element.sum = typeof element.sum === 'string' ? getSum(element.sum) : element.sum;
    });

    const billStatusWithoutId = billStatus.filter(({ id: id1 }) => !project?.bill_status.some(({ id: id2 }) => id2 === id1));
    const billStatusWithtId = billStatus.filter(({ id: id1 }) => project?.bill_status.some(({ id: id2 }) => id2 === id1));

    billStatusWithoutId.forEach((element) => delete element.id);

    closingStatus.forEach((element) => {
      delete element.contract;
      delete element.quarter;
      element.date = element.date ? getDate(element.date) : '';
      element.status_date = element.status_date ? getDate(element.status_date) : '';
      element.sum = typeof element.sum === 'string' ? getSum(element.sum) : element.sum;
    });

    const closingStatusWithoutId = closingStatus.filter(({ id: id1 }) => !project?.closing_info.some(({ id: id2 }) => id2 === id1));
    const closingStatusWithtId = closingStatus.filter(({ id: id1 }) => project?.closing_info.some(({ id: id2 }) => id2 === id1));

    closingStatusWithoutId.forEach((element) => delete element.id);

    data.bill_status = [...billStatusWithoutId, ...billStatusWithtId];
    data.closing_info = [...closingStatusWithoutId, ...closingStatusWithtId];

    if (project.type.id === 0) {
      data.paid_sum_date = completedPayments[0]?.date ? getDate(completedPayments[0]?.date, true) : '';
    }

    save(data, 'payment').then(() => {
      setActiveBtn(false);
    });
  };

  return (
    <LawyerSection title="Оплата и закрытие" defaultActive>
      <div className={classes.wrapper}>

        {project?.type?.id !== 0 ? (
          <div>
            {completedPayments?.length > 0 ? completedPayments.map((payment, index) => (
              payment?.payment_dates?.length > 0 ? (
                payment?.payment_dates?.map((p, pIndex) => (
                  <div key={p.id} className={classes.wrapper__paid__container}>
                    <InputElement
                      text={(index === 0 && pIndex === 0) ? 'Оплачено' : ' '}
                      value={numberToSum(p.sum)}
                      setValue={automaticallysSetPaid}
                      textStyle={{ minWidth: 230, width: 289 }}
                      style={{ marginBottom: 0 }}
                      disabled
                      placeholder="0,00 Р"
                    />
                    <InputElement
                      date
                      type="date"
                      value={getDate(p.date)}
                      setValue={automaticallysSetPaid}
                      textStyle={{ minWidth: 230 }}
                      style={{ width: 225 }}
                      disabled
                    />
                  </div>
                ))
              ) : (
                <div key={payment?.id} className={classes.wrapper__paid__container}>
                  <InputElement
                    text={index === 0 ? 'Оплачено' : ' '}
                    value={numberToSum(payment.sum)}
                    setValue={automaticallysSetPaid}
                    textStyle={{ minWidth: 230, width: 289 }}
                    style={{ marginBottom: 0 }}
                    disabled
                    placeholder="0,00 Р"
                  />
                  <InputElement
                    date
                    type="date"
                    value={getDate(payment.date)}
                    setValue={automaticallysSetPaid}
                    textStyle={{ minWidth: 230 }}
                    style={{ width: 225 }}
                    disabled
                  />
                </div>
              )
            )) : (
              <div className={classes.wrapper__paid__container}>
                <InputElement
                  text="Оплачено"
                  textStyle={{ minWidth: 230, width: 289 }}
                  style={{ marginBottom: 0 }}
                  setValue={automaticallysSetPaid}
                  disabled
                  placeholder="0,00 Р"
                />
                <InputElement
                  date
                  type="date"
                  setValue={automaticallysSetPaid}
                  textStyle={{ minWidth: 230 }}
                  style={{ width: 225 }}
                  disabled
                />
              </div>
            )}
          </div>
        ) : (
          <div className={classes.wrapper__paid__container}>
            <InputElement
              text="Оплачено"
              textStyle={{ minWidth: 230, width: 289 }}
              style={{ marginBottom: 0 }}
              value={numberToSum(project?.payment?.paid_sum)}
              disabled
              placeholder="0,00 Р"
            />
            <InputElement
              date
              type="date"
              value={completedPayments[0]?.date}
              setValue={(event) => setCompletedPayments([{ ...completedPayments[0], date: event }])}
              onClick={clickHandler}
              textStyle={{ minWidth: 230 }}
              style={{ width: 225 }}
            />
          </div>
        )}

        <InputElement
          text="Остаток"
          value={numberToSum(project.payment.credit, true)}
          textStyle={{ minWidth: 230 }}
          onClick={clickHandler}
          placeholder="0,00 Р"
          disabled
        />

        {/* <InputElement
          text="Квартал (начало)"
          placeholder="Введите текст"
          value={startQuarter}
          setValue={setStartQuarter}
          textStyle={{ minWidth: 230 }}
          disabled
        />

        <InputElement
          text="Квартал (конец)"
          placeholder="Введите текст"
          value={endQuarter}
          setValue={setEndQuarter}
          textStyle={{ minWidth: 230 }}
          disabled
        />

        <CheckboxElement
          text="Закрытие в рамках одного квартала"
          textStyle={{ minWidth: 230 }}
        >
          <CheckboxItem
            text="Да"
            status={oneQuarter}
            setStatus={() => setOneQuarter(true)}
            onClick={clickHandler}
            style={{ width: '100%' }}
          />

          <CheckboxItem
            text="Нет"
            status={!oneQuarter}
            setStatus={() => setOneQuarter(false)}
            onClick={clickHandler}
            style={{ width: '100%' }}
          />
        </CheckboxElement> */}

        <InputElement
          text="Ссылка на договор и акт"
          textStyle={{ minWidth: 230 }}
          value={currentLink}
          isLink={isValidURL(currentLink)}
          onChange={onChangeLink}
          className={classes.input__link__padding}
          classNameLink={classes.input__link}
        />

        {!oneQuarter && (
          <InputElement
            text="Условие закрытия"
            value={conditions}
            setValue={setConditions}
            textStyle={{ minWidth: 230 }}
            textarea
            onClick={clickHandler}
            style={{ alignItems: 'flex-start' }}
            placeholder="Введите текст"
          />
        )}

      </div>

      <ContractStatusBlock
        text="Статус договора"
        element={{
          id: null, sum: statusSum, date: statusDate, status,
        }}
        updateFunc={updateContractStatusValue}
        type="contractStatus"
        onClick={clickHandler}
        disabledSum
      />

      {billStatus.map((element, index) => (
        <ContractStatusBlock
          key={element.fieldId || element.id}
          index={index}
          text="Статус счета"
          element={element}
          state={billStatus}
          setState={setBillStatus}
          updateFunc={updateBillStatus}
          deleteFunc={deleteBillStatus}
          type="billStatus"
          onClick={clickHandler}
        />
      ))}

      <div className={classes.addBtn__container}>
        <NewAddBtn
          text="Добавить"
          onClick={() => addBillStatus()}
        />
      </div>

      {closingStatus.map((element, index) => (
        <ContractStatusBlock
          info={info}
          key={element.fieldId || element.id}
          index={index}
          text="Закрывающие документы"
          element={element}
          state={closingStatus}
          setState={setClosingStatus}
          updateFunc={updateClosingStatus}
          deleteFunc={deleteClosingStatus}
          type="closingStatus"
          onClick={clickHandler}
        />
      ))}

      <div className={classes.addBtn__container}>
        <NewAddBtn
          text="Добавить"
          onClick={() => addClosingStatus()}
        />
      </div>

      <div className={classes.wrapper}>

        <InputElement
          text="Остаток на закрытие"
          value={numberToSum(closingBalance, true)}
          textStyle={{ minWidth: 230 }}
          onClick={clickHandler}
          elementClassName={classes.margin_ten}
          invalid={closingBalance < 0}
          disabled
        />

        {/* {project?.payment?.quarter_payments?.map((payment, index) => (
          <div key={payment.name} className={classes.wrapper__paid__container}>
            <InputElement
              text={index === 0 ? 'Закрытие' : ' '}
              value={payment.name}
              setValue={automaticallysSetPaid}
              textStyle={{ minWidth: 230, width: 289 }}
              style={{ marginBottom: 0 }}
              disabled
              placeholder="Квартал из реестра"
            />
            <InputElement
              value={numberToSum(payment.sum) || 0}
              setValue={automaticallysSetPaid}
              textStyle={{ minWidth: 230 }}
              style={{ width: 225 }}
              disabled
              placeholder="Сумма из реестра"
            />
          </div>
        ))}

        {project?.payment?.closing?.length > 0 && (
          <ContractElement
            text="Закрытие"
            textStyle={{ minWidth: 230, width: 230, height: 32 }}
            style={{ marginBottom: 20, alignItems: 'start' }}
          >
            <div className={classes.payment__wrapper}>
              {project?.payment?.closing?.map((payment, index) => (
                <div className={classes.date__wrapper} key={index}>
                  <InputElement
                    value={payment.name}
                    placeholder="Введите текст"
                    onClick={clickHandler}
                    style={{ margin: 0 }}
                    disabled
                  />

                  <InputElement
                    value={numberToSum(payment.sum)}
                    onClick={clickHandler}
                    style={{ margin: 0 }}
                    placeholder="0,00 Р"
                    disabled
                  />
                </div>
              ))}
            </div>
          </ContractElement>
        )} */}

        {/* <CheckboxElement
          text="Адеск"
          textStyle={{ minWidth: 230 }}
        >
          <CheckboxItem
            text="Да"
            status={isAdesk}
            setStatus={() => setIsAdesk(true)}
            onClick={clickHandler}
            style={{ width: '100%' }}
          />

          <CheckboxItem
            text="Нет"
            status={!isAdesk}
            setStatus={() => setIsAdesk(false)}
            onClick={clickHandler}
            style={{ width: '100%' }}
          />
        </CheckboxElement>

        <InputElement
          text="Примечание"
          value={comment}
          setValue={setComment}
          textStyle={{ minWidth: 230 }}
          textarea
          onClick={clickHandler}
          style={{ alignItems: 'flex-start' }}
        /> */}

        {activeBtn && (
          <div className={classes.btn__wrapper}>
            <SuccessBtn
              text="Сохранить"
              style={{
                padding: '2px 20px',
                fontSize: 13,
              }}
              onClick={savePaymentHandler}
            />

            <SuccessBtn
              text="Отменить"
              type="white"
              style={{
                padding: '2px 20px',
                fontSize: 13,
              }}
              onClick={cancelHandler}
            />
          </div>
        )}
      </div>
    </LawyerSection>
  );
}

export default PaymentInfo;
