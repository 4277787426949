// @ts-nocheck
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import TasksPageFinance from 'components/Finance/TasksPage/TasksPageFinance';
import TasksPageDevelopment from 'components/Marketing/TasksPage/TasksPageDevelopment';

import TasksPageLawyer from '../components/Lawyers/TasksPage/TasksPageLawyer';
import TasksPageMarketing from '../components/Marketing/TasksPage/TasksPageMarketing';

const MyTasks = observer(() => {
  const { user } = useContext(Context);

  return (
    <div className="Mysection">
      <Helmet>
        <title>Мои задачи | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      {(user.department === 0 || user.department === 3 || user.department === 4 || user.department === 5)
      && (<TasksPageMarketing />)}
      {user.department === 1 && <TasksPageLawyer />}
      {user.department === 2 && <TasksPageFinance />}
      {user.department === 6 && <TasksPageDevelopment /> }
    </div>
  );
});

export default MyTasks;
