/* eslint-disable quote-props */
export const STATUS_QUANTITY = [0, 1, 2, 3, 4, 5];

export const list = [
  { id: 0, name: 'Отчуждение' },
  { id: 1, name: 'Лицензия' },
];

export const CHECKBOX_QUANTITY = [0, 1];

export const HEADERS = [
  { id: 'contractor', label: 'Исполнитель', special: true },
  { id: 'dates', label: 'Срок оказания услуг', special: true },
  {
    id: 'link', label: 'Соц сеть', special: true, className: 'link_td',
  },
  {
    id: 'format', label: 'Формат публикации', special: true, className: 'link_td',
  },
  {
    id: 'delete_period', label: 'Период неудаления', special: true, popover: 'non_removal_period',
  },
  {
    id: 'rights', label: 'Передача прав', special: true, className: 'link_td',
  },
  {
    id: 'repost', label: 'Репост', special: true, className: 'link_td',
  },
  {
    id: 'kpi', label: 'KPI', special: true, popover: 'kpi',
  },
  { id: 'period', label: 'Период размещения', special: true },
  { id: 'exclusive', label: 'Эксклюзив', special: true },
  { id: 'is_contract', label: 'Договор', special: true },
  {
    id: 'client_legal_entity', label: 'ЮЛ заказчика', special: true, popover: 'client_legal_entity', popover2: ['name'],
  },
  {
    id: 'contractor_legal_entity', label: 'ЮЛ исполнителя', special: true, popover: 'contractor_legal_entity', popover2: ['name'],
  },
  { id: 'sum', label: 'Сумма договора', special: true },
  { id: 'nds', label: 'НДС', special: true },
  {
    id: 'payment_date', label: 'Дата оплаты', special: true, className: 'link_td',
  },
  {
    id: 'payment_sum', label: 'Сумма оплаты', special: true, className: 'link_td',
  },
  { id: 'sum_without_nds', label: 'Цена продажи (до НДС)', special: true },
];
export const FORMAT_TEXT = {
  0: 'Фотопост',
  1: 'Видеопост',
  2: 'Сторис',
  3: 'Прямой эфир',
  4: 'Интеграция',
  5: 'Другое',
};

export const FORMAT_OPTIONS = [
  { id: 0, label: 'Instagram. Фотопост' },
  { id: 1, label: 'Instagram. Видеопост' },
  { id: 2, label: 'Instagram. Сторис' },
  { id: 3, label: 'Instagram. Reels' },
  { id: 4, label: 'Отдельные виды интеграций. Онлайн-вебинар' },
  { id: 5, label: 'Другое' },
  { id: 6, label: 'Telegram. Рекламный пост (фото)' },
  { id: 7, label: 'Telegram. Рекламный пост (видео)' },
  { id: 8, label: 'Telegram. Рекламный пост (голосовое сообщение)' },
  { id: 9, label: 'Telegram. Кружочки' },
  { id: 10, label: 'ВК. Фотопост' },
  { id: 11, label: 'ВК. Видеопост' },
  { id: 12, label: 'ВК. История' },
  { id: 13, label: 'ВК. ВК-клип' },
  { id: 14, label: 'ВК. Интеграция' },
  { id: 15, label: 'ВК. Pre-roll' },
  { id: 16, label: 'ВК. Mid-roll' },
  { id: 17, label: 'Twitch. Размещение в стриме баннера' },
  { id: 18, label: 'Twitch. Интеграция видео в стрим' },
  { id: 19, label: 'Отдельные виды интеграций. Продакт-плейсмент' },
  { id: 20, label: 'Youtube. Mid-roll' },
  { id: 21, label: 'Youtube. Интеграция' },
  { id: 22, label: 'Youtube. Pre-roll' },
  { id: 23, label: 'Youtube. Эксклюзивный ролик' },
  { id: 24, label: 'Youtube. Shorts' },
  { id: 25, label: 'Telegram. Рекламный пост (текст)' },
];

export const FORMAT_COMMENT_TEXT_VARIANTS = {
  'Youtube. Интеграция': 'Услуги по организации создания и размещения +++amountNumberFemale+++ (+++amountTextFemale+++) +++marketingFemale+++ +++integration+++ хронометражем до +++timingNumber+++ (+++timingText+++) +++second+++ (далее – «Публикация - +++publicationNumber+++») в содержании одного из видеовыпусков (видеороликов) в шоу «+++section+++» на канале «+++bloggerName+++» на видеохостинге «YouTube» по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»).',
  'Youtube. Pre-roll': 'Услуги по организации размещения +++amountNumberFemale+++ (+++amountTextFemale+++) +++marketingFemale+++ +++integration+++ общим хронометражем до +++timingNumber+++ (+++timingText+++) +++second+++, +++presented+++ Заказчиком, в формате «Pre-roll» (далее – «Публикация - +++publicationNumber+++») в содержании одного из видеовыпусков (видеороликов) в шоу «+++section+++» на канале «+++bloggerName+++» на видеохостинге «YouTube» по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»).',
  'Youtube. Mid-roll': 'Услуги по организации размещения +++amountNumberFemale+++ (+++amountTextFemale+++) +++marketingFemale+++ +++integration+++ общим хронометражем до +++timingNumber+++ (+++timingText+++) +++second+++, +++presented+++ Заказчиком, в формате «Mid-roll» (далее – «Публикация - +++publicationNumber+++») в содержании одного из видеовыпусков (видеороликов) в шоу «+++section+++» на канале «+++bloggerName+++» на видеохостинге «YouTube» по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»).',
  'Youtube. Эксклюзивный ролик': 'Услуги по организации создания и размещения +++amountNumberMale+++ (+++amountTextMale+++) +++marketingMale+++ +++audioVisualAmount+++ +++works+++ (далее – «Публикация - +++publicationNumber+++») хронометражем до +++timingNumber+++ (+++timingText+++) +++minute+++ на канале «+++bloggerName+++» на видеохостинге «YouTube» по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»).',
  'Youtube. Shorts': 'Услуги по организации создания и размещения +++amountNumberFemale+++ (+++amountTextFemale+++) +++marketingFemale+++ +++integration+++ общим хронометражем до +++timingNumber+++ (+++timingText+++) +++second+++ в формате «YouTube Shorts» (далее – «Публикация - +++publicationNumber+++») на канале «+++bloggerName+++» видеохостинга «YouTube» по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»).',
  'Telegram. Рекламный пост (текст)': 'Услуги по организации создания и размещения +++amountNumberMale+++ (+++amountTextMale+++) +++marketingMale+++ +++post+++ в формате текста в Telegram-канале «+++bloggerName+++» (далее – «Публикация - +++publicationNumber+++») с информацией о Бренде по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»).',
  'Telegram. Рекламный пост (фото)': 'Услуги по организации создания и размещения +++amountNumberMale+++ (+++amountTextMale+++) +++marketingMale+++ +++post+++ в формате фотографии в Telegram-канале «+++bloggerName+++» (далее – «Публикация - +++publicationNumber+++») с информацией о Бренде по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»).',
  'Telegram. Рекламный пост (видео)': 'Услуги по организации создания и размещения +++amountNumberMale+++ (+++amountTextMale+++) +++marketingMale+++ +++post+++ в формате +++audioVisualAmount+++ +++works+++ хронометражем до +++timingNumber+++ (+++timingText+++) +++second+++ в Telegram-канале «+++bloggerName+++» (далее – «Публикация - +++publicationNumber+++») с информацией о Бренде по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»).',
  'Telegram. Рекламный пост (голосовое сообщение)': 'Услуги по организации создания и размещения +++amountNumberMale+++ (+++amountTextMale+++) +++marketingMale+++ +++post+++ в формате голосового сообщения длительностью до +++timingNumber+++ (+++timingText+++) +++second+++ в Telegram-канале «+++bloggerName+++» (далее – «Публикация - +++publicationNumber+++») с информацией о Бренде по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»).',
  'Telegram. Кружочки': 'Услуги по организации создания и размещения +++amountNumberMale+++ (+++amountTextMale+++) +++marketingMale+++ +++post+++ в формате круглого видеосообщения хронометражем до +++timingNumber+++ (+++timingText+++) +++second+++ в Telegram-канале «+++bloggerName+++» (далее – «Публикация - +++publicationNumber+++») с информацией о Бренде по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»).',
  'ВК. Фотопост': 'Услуги по организации создания и размещения блогером, известным как +++bloggerName+++, (далее – «Блогер») +++amountNumberMale+++ (+++amountTextMale+++) +++marketingMale+++ +++post+++ в формате фото+++post+++ в социальной сети «ВКонтакте» по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»)',
  'ВК. Видеопост': 'Услуги по организации создания и размещения блогером, известным как +++bloggerName+++, (далее – «Блогер») +++amountNumberMale+++ (+++amountTextMale+++) +++marketingMale+++ +++post+++ в формате +++audioVisualAmount+++ +++works+++ хронометражем до +++timingNumber+++ (+++timingText+++) +++second+++ в социальной сети «ВКонтакте» по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»)*Аудиовизуальные произведения далее по тексту именуются «Публикации».',
  'ВК. История': 'Услуги по организации создания и размещения блогером, известным как +++bloggerName+++, (далее – «Блогер») +++amountNumberMale+++ (+++amountTextMale+++) +++marketingMale+++ +++post+++ в формате «История» хронометражем до +++timingNumber+++ (+++timingText+++) +++second+++ в социальной сети «ВКонтакте» по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»)',
  'ВК. ВК-клип': 'Услуги по организации создания и размещения блогером, известным как +++bloggerName+++, (далее – «Блогер») +++amountNumberMale+++ (+++amountTextMale+++) +++marketingMale+++ +++post+++ в формате «VK клип» хронометражем до +++timingNumber+++ (+++timingText+++) +++second+++ в социальной сети «ВКонтакте» по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»)',
  'ВК. Интеграция': 'Услуги по организации создания и размещения +++amountNumberFemale+++ (+++amountTextFemale+++) +++marketingFemale+++ +++integration+++ хронометражем до +++timingNumber+++ (+++timingText+++) +++second+++ (далее – «Публикация - +++publicationNumber+++») в содержании одного из видеовыпусков (видеороликов) в шоу «+++section+++» на странице «+++bloggerName+++» в социальной сети «ВКонтакте по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»).',
  'ВК. Pre-roll': 'Услуги по организации размещения +++amountNumberFemale+++ (+++amountTextFemale+++) +++marketingFemale+++ +++integration+++ общим хронометражем до +++timingNumber+++ (+++timingText+++) +++second+++, +++presented+++ Заказчиком, в формате «Pre-roll» (далее – «Публикация - +++publicationNumber+++») в содержании одного из видеовыпусков (видеороликов) в шоу «+++section+++» на странице «+++bloggerName+++» в социальной сети «ВКонтакте по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»).',
  'ВК. Mid-roll': 'Услуги по организации размещения +++amountNumberFemale+++ (+++amountTextFemale+++) +++marketingFemale+++ +++integration+++ общим хронометражем до +++timingNumber+++ (+++timingText+++) +++second+++, +++presented+++ Заказчиком, в формате «Mid-roll» (далее – «Публикация - +++publicationNumber+++») в содержании одного из видеовыпусков (видеороликов) в шоу «+++section+++» на странице «+++bloggerName+++» в социальной сети «ВКонтакте по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»).',
  'Twitch. Размещение в стриме баннера': 'Услуги по организации проведения +++amountNumberMale+++ (+++amountTextMale+++) +++live+++ хронометражем до +++timingNumber+++ (+++timingText+++) +++minute+++ с размещением во время трансляции баннера с информацией о Бренде, на видеостриминговом сервисе «Twitch» на канале «+++bloggerName+++» по следующему адресу: +++link+++ (далее - «Канал - +++channelNumber+++»)',
  'Twitch. Интеграция видео в стрим': 'Услуги по организации проведения +++amountNumberMale+++ (+++amountTextMale+++) +++live+++ хронометражем до +++timingNumber+++ (+++timingText+++) +++minute+++ с размещением во время трансляции +++additionalAmountNumber+++ (+++additionalAmountText+++) +++audioVisualAdditionalAmount+++ +++additionalWorks+++ хронометражем до +++additionalTimingNumber+++ (+++additionalTimingText+++) +++additionalSecond+++ в формате +++additionalIntegration+++ в стрим», предоставляемого Заказчиком, на видеостриминговом сервисе «Twitch» на канале «+++bloggerName+++» по следующему адресу: +++link+++ (далее - «Канал - +++channelNumber+++»)*Аудиовизуальные произведения далее по тексту именуются «Публикации».',
  'Отдельные виды интеграций. Продакт-плейсмент': 'Услуги по созданию и размещению +++amountNumberFemale+++ (+++amountTextFemale+++) +++marketingFemale+++ интеграций в формате органичного включения демонстрации продукта, логотипа или его устного упоминания (продакт-плейсмент) в содержании одного из видеовыпусков (видеороликов) на канале «+++bloggerName+++» на видеохостинге «YouTube» по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»).',
  'Instagram. Сторис': 'Услуги по организации создания и размещения блогером, известным как +++bloggerName+++ (далее – «Блогер»), +++amountNumberFemale+++ (+++amountTextMale+++) +++audioVisualAmount+++ +++works+++ хронометражем до +++timingNumber+++ (+++timingText+++) +++second+++ в аккаунте Блогера-1 в социальной сети, размещенной в информационно - коммуникационной сети «Интернет» (далее – «Канал - +++channelNumber+++»).*Аудиовизуальные произведения далее по тексту именуются «Публикации».',
  'Instagram. Фотопост': 'Услуги по организации создания и размещения блогером, известным как +++bloggerName+++ (далее – «Блогер»), +++amountNumberMale+++ (+++amountTextMale+++) фото+++post+++ в аккаунте Блогера-1 в социальной сети, размещенной в информационно - коммуникационной сети «Интернет» (далее – «Канал - +++channelNumber+++»).',
  'Instagram. Видеопост': 'Услуги по организации создания и размещения блогером, известным как +++bloggerName+++ (далее – «Блогер»), +++amountNumberFemale+++ (+++amountTextMale+++) +++audioVisualAmount+++ +++works+++ хронометражем до +++timingNumber+++ (+++timingText+++) +++second+++ в аккаунте Блогера-1 в социальной сети, размещенной в информационно - коммуникационной сети «Интернет» (далее – «Канал - +++channelNumber+++»).*Аудиовизуальные произведения далее по тексту именуются «Публикации».',
  'Instagram. Reels': 'Услуги по организации создания и размещения блогером, известным как +++bloggerName+++ (далее – «Блогер»), +++amountNumberFemale+++ (+++amountTextMale+++) +++audioVisualAmount+++ +++works+++ хронометражем до +++timingNumber+++ (+++timingText+++) +++second+++ в аккаунте Блогера-1 в социальной сети, размещенной в информационно - коммуникационной сети «Интернет» (далее – «Канал - +++channelNumber+++»).*Аудиовизуальные произведения далее по тексту именуются «Публикации».',
  'Отдельные виды интеграций. Онлайн-вебинар': 'Услуги по организации создания и проведения онлайн-вебинара блогером, известным как +++bloggerName+++, (далее – «Блогер») в формате видеоконференцсвязи продолжительностью до +++timingNumber+++ (+++timingText+++) +++minute+++ (далее – «Публикация»- +++publicationNumber+++) на канале «+++channel+++» на видеохостинге « +++videoHosting+++ » по следующему адресу: +++link+++ (далее – «Канал - +++channelNumber+++»).',
  'Другое': '',
};
