// @ts-nocheck
import React, {
  useContext, useEffect, useLayoutEffect,
} from 'react';
import {
  addWeeks, endOfISOWeek, endOfMonth, startOfISOWeek, startOfMonth,
} from 'date-fns';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import SecondDoubleDate from 'components/UI/DoubleDate/SecondDoubleDate';
import InputElement from 'components/UI/InputElements/InputElement';
import MultipleBtn from 'components/UI/MultipleBtn/MultipleBtn';
import MyDropdown from 'components/UI/MyDropdown/MyDropdown';
import SearchBar from 'components/UI/SearchBar/SearchBar';

import { useMultiple } from 'hooks/useMultiple';

import {
  DATE_DROPDOWN,
  STATUS_TASKS,
} from 'utils/consts';

import FilterStaffBlock from './FilterStaffBlock';

import classes from '../ReportPage.module.scss';

const TasksFilter = observer((props) => {
  const { user, reportTasks, filters } = useContext(Context);
  const {
    departments, directorsList, bloggerList, allLegalEntity, fetchingReportTasks,
  } = props;
  const controllerTasks = new AbortController();

  const [
    blogger,
    changeBlogger,
    selectedBloggerList,
    addBlogger,
    deleteBlogger,
    setSelectedBloggerList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    clientLegalEntity,
    changeClientLegalEntity,
    selectedClientLegalEntityList,
    addClientLegalEntity,
    deleteClientLegalEntity,
    setSelectedClientLegalEntityList,
  ] = useMultiple([{ fieldId: Date.now(), id: null, name: '' }]);

  // Состояние фильтра из стора

  useEffect(() => {
    filters.setReportSelectedBloggerList(selectedBloggerList);
  }, [selectedBloggerList]);

  useEffect(() => {
    filters.setReportClientLegalEntityList(selectedClientLegalEntityList);
  }, [selectedClientLegalEntityList]);

  useLayoutEffect(() => {
    setSelectedBloggerList(filters.reportSelectedBloggerList);

    setSelectedClientLegalEntityList(filters.reportClientLegalEntityList);
  }, []);

  useEffect(() => {
    if (filters?.reportFilter?.deadline_date_status?.id === 0) {
      filters.setReportFilter({
        ...filters.reportFilter,
        deadline_start: addWeeks(startOfISOWeek(Date.now()), -1),
        deadline_end: addWeeks(endOfISOWeek(Date.now()), -1),
      });
    } else if (filters?.reportFilter?.deadline_date_status?.id === 1) {
      filters.setReportFilter({
        ...filters.reportFilter,
        deadline_start: startOfISOWeek(Date.now()),
        deadline_end: endOfISOWeek(Date.now()),
      });
    } else if (filters?.reportFilter?.deadline_date_status?.id === 2) {
      filters.setReportFilter({
        ...filters.reportFilter,
        deadline_start: addWeeks(startOfISOWeek(Date.now()), 1),
        deadline_end: addWeeks(endOfISOWeek(Date.now()), 1),
      });
    } else if (filters?.reportFilter?.deadline_date_status?.id === 3) {
      filters.setReportFilter({
        ...filters.reportFilter,
        deadline_start: startOfMonth(Date.now()),
        deadline_end: endOfMonth(Date.now()),
      });
    }
  }, [filters?.reportFilter?.deadline_date_status]);

  const apllyFilterHandler = () => {
    reportTasks.setReportTasksFilter({
      ...filters.reportFilter,
      selectedDirectorList: filters.reportSelectedDirectorList,
      selectedResponsibleList: filters.reportselectedResponsibleList,
      selectedReportLegalEntityList: filters._reportContractorLegalEntityList,
      selectedBloggerList,
      selectedClientLegalEntityList,
    });

    reportTasks.setReportTasksPage(1);

    fetchingReportTasks(
      reportTasks.reportTasksPage,
      reportTasks.reportTasksLimit,
      controllerTasks.signal,
      reportTasks.reportTasksFilter,
    );
  };

  return (
    <>
      <div className={classes.filter__container__inner}>
        <div className={classes.filter__block}>
          <div className={classes.filter__item}>
            <span className={classes.filter__text}>Дедлайн</span>
            <div className={classes.doubleDate__container}>
              <SecondDoubleDate
                value1={filters.reportFilter.deadline_start}
                value2={filters.reportFilter.deadline_end}
                setValue1={(event) => filters.setReportFilter({ deadline_start: event })}
                setValue2={(event) => filters.setReportFilter({ deadline_end: event })}
              />
            </div>

          </div>

          <div className={classes.filter__item}>
            <span />
            <InputElement
              value={filters.reportFilter.deadline_date_status}
              setValue={(status) => filters.setReportFilter({ deadline_date_status: status })}
              dropdown={DATE_DROPDOWN.LIST}
              inputStyle={{ fontSize: 13 }}
              className={classes.dropdown}
            />
          </div>

          <div className={classes.filter__item}>
            <span className={classes.filter__text}>Статус задачи</span>
            <MyDropdown
              selected={filters.reportFilter.taskStatus}
              setSelected={(element) => filters.setReportFilter({ taskStatus: element })}
              list={STATUS_TASKS.LIST}
              className={classes.dropdown}
            />
          </div>

          {user.department === 6
          && (
            <>
              <div className={classes.filter__item}>
                <span className={classes.filter__text}>Блогер</span>
                <SearchBar
                  text={blogger[0]}
                  setText={changeBlogger}
                  list={bloggerList}
                  multiple={addBlogger}
                  type="blogger"
                  containerClassName={classes.dropdown}
                />
              </div>

              <div className={classes.selected__blogger}>
                {selectedBloggerList.map((element) => (
                  <MultipleBtn
                    key={element.id}
                    element={element}
                    deleteEl={deleteBlogger}
                  />
                ))}
              </div>
            </>

          )}

          {user.department === 1
          && (
            <>
              <div className={classes.filter__item}>
                <span className={classes.filter__text}>ЮЛ заказчика</span>
                <SearchBar
                  text={clientLegalEntity[0]}
                  setText={changeClientLegalEntity}
                  list={allLegalEntity}
                  multiple={addClientLegalEntity}
                  type="legal_details"
                  containerClassName={classes.dropdown}
                />
              </div>

              <div className={classes.selected__blogger}>
                {selectedClientLegalEntityList.map((element) => (
                  <MultipleBtn
                    key={element.id}
                    element={element}
                    deleteEl={deleteClientLegalEntity}
                  />
                ))}
              </div>
            </>

          )}
        </div>

        <FilterStaffBlock
          departments={departments}
          directorsList={directorsList}
          type={user.department === 1 && 'lawyerTasks'}
          allLegalEntity={allLegalEntity}
          shortFields={user.user.role.id === 1}
        />
      </div>

      <div className={classes.btns}>
        <SuccessBtn
          text="Применить фильтр"
          style={{ padding: '4px 14px', fontSize: 11 }}
          onClick={() => apllyFilterHandler()}
        />
      </div>
    </>
  );
});

export default TasksFilter;
