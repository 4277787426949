import React, { memo, useState } from 'react';
import { Popover } from '@mui/material';

import Warning from '../../../assets/icons/Fields/Warning.svg';
import ModalEditLink from '../Modals/ModalEditLink/ModalEditLink';
import Tooltip from '../Tooltip/Tooltip';

import { ReactComponent as EditIcon } from './assets/edit.svg';

import classes from './NewInput.module.scss';

function LawyersInput(props) {
  const {
    type,
    disabled,
    value,
    setValue,
    placeholder,
    style,
    required,
    maxLength,
    onKeyDown,
    onKeyPress,
    pattern,
    onInput,
    onChange,
    onClick,
    className,
    classNameLink,
    tooltipText,
    tooltipPosition,
    isPopover,
    isLink,
    invalid,
    onlyWord,
  } = props;

  let tempType = '';

  const [isShow, setIsShow] = useState(false);

  if (type === 'password') {
    tempType = 'password';
  } else if (type === 'email') {
    tempType = 'email';
  } else {
    tempType = 'text';
  }

  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ

  const handleEditLink = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsShow(true);
  };

  const handleBlockClick = (event) => {
    if (event.target.tagName === 'A') {
      return;
    }

    handleEditLink(event);
  };

  return (
    <>
      {isLink ? (
        <div className={[classes.info__input, classes.linkContainer, className].join(' ')}>
          <a
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            className={[classes.link, classNameLink].join(' ')}
          >
            {value}
          </a>
          {!disabled
           && (
           <div>
             <EditIcon className={classes.editIcon} onClick={handleBlockClick} />
           </div>
           )}

        </div>

      ) : (
        <input
          type={tempType}
          className={[classes.info__input, className].join(' ')}
          style={style}
          value={value}
          disabled={disabled}
          onChange={onChange || ((event) => setValue(onlyWord ? event.target.value.replace(/[^A-Za-zА-Яа-яЁё]/g, '') : event.target.value))}
          onInput={
          onInput ? ({ currentTarget: { value } }) => onInput(value) : null
        }
          placeholder={placeholder}
          required={required}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
          pattern={pattern}
          onKeyPress={onKeyPress}
          onClick={onClick}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      )}

      {/* Всплывающая подсказка */}
      {tooltipText && <Tooltip tooltipPosition={tooltipPosition} text={tooltipText} icon={Warning} />}
      {/* Всплывающая подсказка */}
      {isPopover && !isLink ? (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={value?.length >= 45 ? open : false}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableAutoFocus
          disableEnforceFocus
        >
          <div className={classes.popoverText}>{value}</div>
        </Popover>
      ) : null}

      <ModalEditLink
        show={isShow}
        closeModal={setIsShow}
        onChange={onChange || ((event) => setValue(event.target.value))}
        value={value}
        setValue={setValue}
      />
    </>
  );
}

export default memo(LawyersInput);
