// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { BrowserRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
// import Snowfall from 'react-snowfall';
import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { check } from 'API/UserAPI';

import AppRouter from 'components/AppRouter';
import ReactNotification from 'components/Notifications/ReactNotification';
import MyToast from 'components/UI/MyToast/MyToast';
import Navbar from 'components/UI/Navbar/Navbar';

import { useLogout } from './hooks/useLogout';

import { getTokenFunc, onMessageListener } from 'utils/firebaseInit';

// import logo from './assets/images/snow.png';
import './styles/App.css';

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

if (process.env.NODE_ENV === 'production' && sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
// СНЕГ
// const snowflake = document.createElement('img');
// snowflake.src = logo;
// const images = [snowflake];
// СНЕГ

const App = observer(() => {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState([]);
  const [logout] = useLogout();

  // // СНЕГ
  // const [isSnowing, setIsSnowing] = useState(false);
  // const [snowfallSetting, setSnowfallSetting] = useState('В режиме ожидания');
  // const [activityTimeout, setActivityTimeout] = useState(null);

  // // Чтение настроек снегопада из localStorage
  // useEffect(() => {
  //   const savedSetting = localStorage.getItem('snowfallSetting') || 'В режиме ожидания';
  //   setSnowfallSetting(savedSetting);

  //   if (savedSetting === 'Всегда') {
  //     setIsSnowing(true);
  //   } else if (savedSetting === 'Отключить') {
  //     setIsSnowing(false);
  //   }
  // }, []);

  // // Установка таймаута для режима ожидания
  // useEffect(() => {
  //   if (snowfallSetting === 'В режиме ожидания') {
  //     const handleUserActivity = () => {
  //       setIsSnowing(false);
  //       clearTimeout(activityTimeout);
  //       setActivityTimeout(setTimeout(() => setIsSnowing(true), 20000));
  //     };

  //     window.addEventListener('mousemove', handleUserActivity);
  //     window.addEventListener('keydown', handleUserActivity);

  //     return () => {
  //       window.removeEventListener('mousemove', handleUserActivity);
  //       window.removeEventListener('keydown', handleUserActivity);
  //       clearTimeout(activityTimeout);
  //     };
  //   }
  // }, [activityTimeout, snowfallSetting]);

  // СНЕГ

  // Проверка токена при первичной загрузке страницы
  useEffect(() => {
    check()
      .then((data) => {
        if (data) {
          user.setUser(data);
          user.setIsAuth(true);
          user.setDepartment(data.department.id);
        }
      })
      .catch((err) => {
        logout(err);
      })

      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (user.isAuth) {
      getTokenFunc();
    }
  }, [user.isAuth]);

  if (loading) {
    return <Spinner animation="grow" />;
  }

  onMessageListener().then((data) => {
    setNotification((notification) => [...notification, data]);
  });

  const deleteNotification = (id) => {
    setNotification(notification.filter((item) => item.messageId !== id));
  };

  const classNames = ['personal__lawyers__container'].join(' ');

  return (
    <Sentry.ErrorBoundary fallback="Произошла ошибка">
      <BrowserRouter>
        <MyToast />
        <div
          className={
          classNames
        }
        >

          <div className="toast__container">
            {notification.map((element) => (
              <ReactNotification
                key={element.messageId}
                notification={element}
                deleteNotification={deleteNotification}
              />
            ))}
          </div>

          <Navbar />
          <AppRouter />
          {/* СНЕГ
          {isSnowing && (
          <Snowfall
            radius={[5.0, 16.5]}
            speed={[0.5, 4.0]}
            wind={[0, 2.0]}
            snowflakeCount={300}
            images={images}
          />
          ) }
          СНЕГ */}
        </div>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
});

export default App;
