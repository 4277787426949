// @ts-nocheck
import { makeAutoObservable } from 'mobx';

export default class ContractsStore {
  constructor() {
    // Проекты менедежров/договоры юристов
    this._contracts = [];
    this._contractsTotalCount = 0;
    this._contractsPage = 1;
    this._contractsLimit = 15;
    this._contractsFilter = undefined;

    this._modalContracts = [];
    this._modalContractsTotalCount = 0;
    this._modalContractsPage = 1;
    this._modalContractsLimit = 15;
    this._modalContractsFilter = undefined;

    // Договоры в статусе оплат(менеджеры/финансы)
    this._paymentStatusContracts = [];
    this._paymentStatusContractsTotalCount = 0;
    this._paymentStatusContractsPage = 1;
    this._paymentStatusContractsLimit = 15;
    this._paymentStatusContractsFilter = undefined;
    makeAutoObservable(this);
  }

  setContracts(contracts, update) {
    if (update) {
      return (this._contracts = contracts);
    }

    if (this._contractsPage === 1) {
      return (this._contracts = contracts);
    }

    contracts.forEach((contract) => this._contracts.push(contract));
  }

  setContractsTotalCount(count) {
    this._contractsTotalCount = count;
  }

  setContractsPage(page) {
    this._contractsPage = page;
  }

  setContractsFilter(filter) {
    this._contractsFilter = filter;
  }

  setModalContracts(contracts, update) {
    if (update) {
      return (this._modalContracts = contracts);
    }

    if (this._modalContractsPage === 1) {
      return (this._modalContracts = contracts);
    }

    contracts.forEach((contract) => this._modalContracts.push(contract));
  }

  setModalContractsTotalCount(count) {
    this._modalContractsTotalCount = count;
  }

  setModalContractsPage(page) {
    this._modalContractsPage = page;
  }

  setModalContractsFilter(filter) {
    this._modalContractsFilter = filter;
  }

  get contracts() {
    return this._contracts;
  }

  get contractsTotalCount() {
    return this._contractsTotalCount;
  }

  get contractsPage() {
    return this._contractsPage;
  }

  get contractsLimit() {
    return this._contractsLimit;
  }

  get contractsFilter() {
    return this._contractsFilter;
  }

  get modalContracts() {
    return this._modalContracts;
  }

  get modalContractsTotalCount() {
    return this._modalContractsTotalCount;
  }

  get modalContractsPage() {
    return this._modalContractsPage;
  }

  get modalContractsLimit() {
    return this._modalContractsLimit;
  }

  get modalContractsFilter() {
    return this._modalContractsFilter;
  }

  // Payment status contracts

  setPaymentStatusContracts(contracts, update) {
    if (update) {
      return (this._paymentStatusContracts = contracts);
    }

    if (this._paymentStatusContractsPage === 1) {
      return (this._paymentStatusContracts = contracts);
    }

    contracts.forEach((contract) => this._paymentStatusContracts.push(contract));
  }

  setPaymentStatusContractsTotalCount(count) {
    this._paymentStatusContractsTotalCount = count;
  }

  setPaymentStatusContractsPage(page) {
    this._paymentStatusContractsPage = page;
  }

  setPaymentStatusContractsFilter(filter) {
    this._paymentStatusContractsFilter = filter;
  }

  get paymentStatusContracts() {
    return this._paymentStatusContracts;
  }

  get paymentStatusContractsTotalCount() {
    return this._paymentStatusContractsTotalCount;
  }

  get paymentStatusContractsPage() {
    return this._paymentStatusContractsPage;
  }

  get paymentStatusContractsLimit() {
    return this._paymentStatusContractsLimit;
  }

  get paymentStatusContractsFilter() {
    return this._paymentStatusContractsFilter;
  }
}
