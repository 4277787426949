export const styleForModal = { padding: '60px 60px 37px 60px' };

export const stylesForButtonAddNewEmployee = {
  padding: '5px 35px', width: '332.38px', background: 'transparent', color: 'black', border: '1px solid black',
};
export const stylesForButtonLoading = { padding: '5px 35px', backgroundColor: '#a3a2a2' };

export const styleForButtonSend = { padding: '5px 35px', width: '332.38px' };

export const STATUS_QUANTITY = [0, 1, 2, 3, 4, 5, 6, 7];
