import React from 'react';

import FilesProgressBar from '../FilesProgressBar/FilesProgressBar';
import ProgressBar from '../ProgressBar/ProgressBar';
import ProgressBarGeneral from '../ProgressBarGeneral/ProgressBarGeneral';

import classes from './UploadFilesContainer.module.scss';

function UploadFilesContainer(props) {
  const { progressFiles, isVisible, setIsVisible } = props;
  const maxFileSize = 209715200;
  const convertedMaxFileSize = Math.floor(maxFileSize / 1000000);
  let totalProgressFromAll;

  // Подсчет для общего индикатора загрузки
  if (progressFiles.files.length) {
    const getProgressFromAll = progressFiles.files
      .filter((file) => file.size < maxFileSize)
      .map((file) => Object.values(file));
    totalProgressFromAll = getProgressFromAll
      .reduce((total, [, progress]) => total + progress, 0) / getProgressFromAll.length;
  }

  const hideHandler = () => {
    setIsVisible(false);
  };

  return (
    <div>
      {isVisible && (
        <div className={classes.progressBar__container}>
          <div className={classes.progressBar__container__common}>
            {totalProgressFromAll < 100 ? (
              <>
                <div className={classes.progressBar__container__general__text}>
                  Загрузка
                  {' '}
                  {Math.round(totalProgressFromAll)}
                  %
                </div>
                {totalProgressFromAll === 100 && (
                  <div
                    className={classes.progressBar__container__general__hideButton}
                    onClick={hideHandler}
                  >
                    Свернуть
                  </div>
                )}
              </>
            ) : (
              <>
                <div className={classes.progressBar__container__general__text}>
                  Загрузка завершена
                </div>
                {totalProgressFromAll === 100 && (
                  <div
                    className={classes.progressBar__container__general__hideButton}
                    onClick={hideHandler}
                  >
                    Свернуть
                  </div>
                )}
              </>
            )}

            {isVisible && (
              <ProgressBarGeneral
                totalProgressFromAll={totalProgressFromAll}
                style={classes.progressBar__general}
              />
            )}
          </div>

          {progressFiles?.files?.map(
            (file) => (file.progress === 100 && (
            <div className={classes.one__file__container} key={file.id}>
              <div className={classes.one__file__container__filename}>
                <FilesProgressBar key={file.id} file={file} />
              </div>
              <div className={classes.one__file__container__loading__success}>
                Загрузка завершена
              </div>
            </div>
            )) || (
              file.progress < 100 && file.isAllow ? (
                <div className={classes.one__file__container} key={file.id}>
                  <div className={classes.one__file__container__filename}>
                    <FilesProgressBar key={file.id} file={file} />
                  </div>
                  <ProgressBar
                    progress={file.progress}
                    style={classes.progressBar}
                  />
                </div>
              ) : (
                <div className={classes.one__file__container} key={file.id}>
                  <div className={classes.one__file__container__filename}>
                    <FilesProgressBar key={file.id} file={file} />
                  </div>
                  <div className={classes.one__file__container__loading__error}>
                    Нельзя загрузить файлы больше
                    {' '}
                    {convertedMaxFileSize}
                    мб
                  </div>
                </div>
              )),
          )}
        </div>
      )}
    </div>
  );
}

export default UploadFilesContainer;
