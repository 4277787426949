import qs from 'qs';

import { $authHost } from 'API';

import { getDate } from '../../utils/getDate';

export const fetchReportTasks = async (page, limit, controller, filter) => {
  const params = { page, limit };

  if (filter) {
    if (filter.deadline_start) {
      params.deadline_after = getDate(filter.deadline_start);
    }

    if (filter.deadline_end) {
      params.deadline_before = getDate(filter.deadline_end);
    }

    if (Number.isInteger(filter.taskStatus.id)) {
      params.status = filter?.taskStatus?.id;
    }

    let tempArray = [];
    filter?.selectedBloggerList.forEach((blogger) => tempArray.push(blogger.id));
    if (tempArray.length > 0) {
      params.blogger = tempArray;
    }

    if (Number.isInteger(filter.selectedDepartment.id)) {
      params.department = filter.selectedDepartment.id;
    }

    tempArray = [];
    filter?.selectedDirectorList?.forEach((grouphead) => tempArray.push(grouphead.id));
    if (tempArray.length > 0) {
      params.grouphead = tempArray;
    }

    tempArray = [];
    filter?.selectedResponsibleList?.forEach((person) => tempArray.push(person.id));
    if (tempArray.length > 0) {
      params.staff = tempArray;
    }
  }

  const { data } = await $authHost.get('/report_page/tasks/', {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return data;
};
