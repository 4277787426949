export const LOGIN_ROUTE = '/login';
export const FORGOT_ROUTE = '/forgot';
export const RECOVER_ROUTE = '/recover';

export const MAIN_ROUTE = '/';
export const PROJECT_ROUTE = '/project';
export const CONTRACTS_MAIN_ROUTE = '/contracts';
export const ONE_CONTRACT_ROUTE = '/contract';
export const FINANCE_MAIN_ROUTE = '/finance';
export const PROJECT_ACCOUNTING_ROUTE = '/project-accounting';

export const TASKS_ROUTE = '/myTasks';
export const NOTIFICATION_ROUTE = '/notification';
export const CLIENTS_ROUTE = '/clients';
export const ONE_CLIENT_ROUTE = '/client';
export const TEAMS_ROUTE = '/teams';
export const LEGAL_ENTITY_ROUTE = '/legalEntities';
export const STAFF_CARD_ROUTE = '/card';
export const REPORT_ROUTE = '/report';
export const PAYMENT_STATUS_ROUTE = '/paymentsStatus';
export const ONE_PAYMENT_STATUS_ROUTE = '/paymentStatus';
export const SALARIES_ROUTE = '/salaries';
export const COST_ROUTE = '/costs';
export const ONE_COST_ROUTE = '/cost';
export const PAYMENT_ROUTE = '/payments';
export const BLOGGERS_ROUTE = '/bloggers';
export const ONE_BLOGGER_ROUTE = '/blogger';
export const SETTING_ROUTE = '/settings';
export const ONE_LEGAL_ENTITY_ROUTE = '/legalEntity';

export const STATE_STATUSES = {
  0: 'Бриф в работе',
  1: 'Проект в реализации',
  2: 'Подготовка отчета',
  3: 'Документы и оплата',
  4: 'Предложение отправлено',
  5: 'Проект на холде',
  6: 'Проект завершен',
  7: 'Отмена',
};

export const TASKS_STATUSES = {
  preparing: 0,
  processing: 1,
  finishing: 2,
};
export const TASKS_STATUSES_FILTER = {
  0: 'К выполнению',
  1: 'В работе',
  2: 'Готово',
};

TASKS_STATUSES_FILTER.LIST = [
  { id: 0, label: TASKS_STATUSES_FILTER[0] },
  { id: 1, label: TASKS_STATUSES_FILTER[1] },
  { id: 2, label: TASKS_STATUSES_FILTER[2] },
];

STATE_STATUSES.LIST = [
  { id: 0, label: STATE_STATUSES[0] },
  { id: 1, label: STATE_STATUSES[1] },
  { id: 2, label: STATE_STATUSES[2] },
  { id: 3, label: STATE_STATUSES[3] },
  { id: 4, label: STATE_STATUSES[4] },
  { id: 5, label: STATE_STATUSES[5] },
  { id: 6, label: STATE_STATUSES[6] },
  { id: 7, label: STATE_STATUSES[7] },
];

export const STATUS_LAWYER = {
  0: 'Заявка',
  1: 'Согласование',
  2: 'На закрытие',
  3: 'Завершенный проект',
  4: 'Холд',
  5: 'Отмена',
  6: 'На рассмотрении (Юр)',
  7: 'На рассмотрении (ДОК)',
};

STATUS_LAWYER.LIST = [
  { id: 0, label: STATUS_LAWYER[0] },
  { id: 1, label: STATUS_LAWYER[1] },
  { id: 2, label: STATUS_LAWYER[2] },
  { id: 3, label: STATUS_LAWYER[3] },
  { id: 4, label: STATUS_LAWYER[4] },
  { id: 5, label: STATUS_LAWYER[5] },
  { id: 6, label: STATUS_LAWYER[6] },
  { id: 7, label: STATUS_LAWYER[7] },
];

export const STATUS_TASKS = {
  0: 'К выполнению',
  1: 'В работе',
  2: 'Готово',
};

STATUS_TASKS.LIST = [
  { id: 0, label: STATUS_TASKS[0] },
  { id: 1, label: STATUS_TASKS[1] },
  { id: 2, label: STATUS_TASKS[2] },
];

export const STATUS_FINANCE = {
  0: 'Создано',
  1: 'Согласование',
  2: 'В оплату',
  3: 'Частичная оплата',
  4: 'Оплачено',
  5: 'Отказ',
  6: 'Удалено',
};

// СТАТУС ОПЛАТ ДЛЯ ФИНАНСИСТОВ
STATUS_FINANCE.LIST = [
  { id: 0, label: STATUS_FINANCE[0] },
  { id: 1, label: STATUS_FINANCE[1] },
  { id: 2, label: STATUS_FINANCE[2] },
  { id: 3, label: STATUS_FINANCE[3] },
  { id: 4, label: STATUS_FINANCE[4] },
  { id: 5, label: STATUS_FINANCE[5] },
  { id: 6, label: STATUS_FINANCE[6] },
];

export const STATUS_PROJECT_ACCOUNTING = {
  0: 'Не принято к учёту',
  1: 'Принять к учёту',
  2: 'Принято к учёту',
  3: 'Частичное закрытие',
  4: 'Частичная отмена',
  5: 'Отмена',
};

// СТАТУС ОПЛАТ ДЛЯ ВСЕХ
export const STATUS_LAWYER_COSTS = {
  0: 'Создано',
  1: 'В оплату',
  2: 'Оплачено',
  3: 'Удалено',
  4: 'В ожидании',
  5: 'Отказ',
  6: 'Частичная оплата',
};

STATUS_LAWYER_COSTS.LIST = [
  { id: 0, label: STATUS_LAWYER_COSTS[0] },
  { id: 1, label: STATUS_LAWYER_COSTS[1] },
  { id: 2, label: STATUS_LAWYER_COSTS[2] },
  { id: 3, label: STATUS_LAWYER_COSTS[3] },
  { id: 4, label: STATUS_LAWYER_COSTS[4] },
  { id: 5, label: STATUS_LAWYER_COSTS[5] },
  { id: 6, label: STATUS_LAWYER_COSTS[6] },
];

export const TASK_STATUSES = {
  0: 'Выполненные',
  1: 'Невыполненные',
};

TASK_STATUSES.LIST = [
  { id: 0, label: TASK_STATUSES[0] },
  { id: 1, label: TASK_STATUSES[1] },
];

export const ID_PROJECT_STATUSES = {
  0: 'По возрастанию',
  1: 'По убыванию',
};

ID_PROJECT_STATUSES.LIST = [
  { id: 0, label: ID_PROJECT_STATUSES[0] },
  { id: 1, label: ID_PROJECT_STATUSES[1] },
];

export const DEADLINE_STATUSES = {
  0: 'По возрастанию',
  1: 'По убыванию',
};

DEADLINE_STATUSES.LIST = [
  { id: 0, label: DEADLINE_STATUSES[0] },
  { id: 1, label: DEADLINE_STATUSES[1] },
];

export const IS_CONTRACT_CREATED = {
  0: 'Нет',
  1: 'Да',
};

IS_CONTRACT_CREATED.LIST = [
  { id: 0, label: IS_CONTRACT_CREATED[0] },
  { id: 1, label: IS_CONTRACT_CREATED[1] },
];

export const DATE_DROPDOWN = {
  0: 'Прошедшая неделя',
  1: 'Текущая неделя',
  2: 'Следующая неделя',
  3: 'Текущий месяц',
};

DATE_DROPDOWN.LIST = [
  { id: 0, label: DATE_DROPDOWN[0] },
  { id: 1, label: DATE_DROPDOWN[1] },
  { id: 2, label: DATE_DROPDOWN[2] },
  { id: 3, label: DATE_DROPDOWN[3] },
];

export const DATE_DROPDOWN_SHORT = {
  0: 'Сегодня',
  1: 'Текущая неделя',
  2: 'Текущий месяц',
};

DATE_DROPDOWN_SHORT.LIST = [
  { id: 0, label: DATE_DROPDOWN_SHORT[0] },
  { id: 1, label: DATE_DROPDOWN_SHORT[1] },
  { id: 2, label: DATE_DROPDOWN_SHORT[2] },
];

export const APPLICATION_TYPES = {
  0: 'Заявка на документы',
  1: 'Заявка на СК',
  2: 'Заявка на доп. расход',
};

APPLICATION_TYPES.LIST = [
  { id: 0, application: APPLICATION_TYPES[0] },
  { id: 1, application: APPLICATION_TYPES[1] },
  { id: 2, application: APPLICATION_TYPES[2] },
  { id: 3, application: APPLICATION_TYPES[3] },
];

export const CONTRACT_TYPES = {
  0: 'КЛ (у)',
  1: 'КЛ (а)',
  2: 'БЛ',
  3: 'ПР',
  4: 'СК',
};

CONTRACT_TYPES.LIST = [
  { id: 0, label: CONTRACT_TYPES[0] },
  { id: 1, label: CONTRACT_TYPES[1] },
  { id: 2, label: CONTRACT_TYPES[2] },
  { id: 3, label: CONTRACT_TYPES[3] },
  { id: 4, label: CONTRACT_TYPES[4] },
];

export const CONTRACT_TYPES_GL = {
  0: 'КЛ',
  1: 'БЛ',
  2: 'СК расход',
  3: 'СК доход',
  4: 'Доп.расход',
  5: 'БЛ (без договора)',
};

CONTRACT_TYPES_GL.LIST = [
  { id: 0, label: CONTRACT_TYPES_GL[0] },
  { id: 1, label: CONTRACT_TYPES_GL[1] },
  { id: 2, label: CONTRACT_TYPES_GL[2] },
  { id: 3, label: CONTRACT_TYPES_GL[3] },
  { id: 4, label: CONTRACT_TYPES_GL[4] },
  { id: 5, label: CONTRACT_TYPES_GL[5] },
];

export const CONTRACT_STATUS = {
  0: 'Согласование',
  1: 'Направлен в ЭДО',
  2: 'Подписан в ЭДО',
  3: 'Получены сканы',
  4: 'Направлены сканы',
  5: 'Направлены оригиналы',
  6: 'Обменялись оригиналами',
  7: 'Чек получен',
  8: '-',
  9: 'Отмена',
  10: 'Запрошено',
};

CONTRACT_STATUS.LIST = [
  { id: 0, label: CONTRACT_STATUS[0] },
  { id: 1, label: CONTRACT_STATUS[1] },
  { id: 2, label: CONTRACT_STATUS[2] },
  { id: 3, label: CONTRACT_STATUS[3] },
  { id: 4, label: CONTRACT_STATUS[4] },
  { id: 5, label: CONTRACT_STATUS[5] },
  { id: 6, label: CONTRACT_STATUS[6] },
  { id: 7, label: CONTRACT_STATUS[7] },
  { id: 8, label: CONTRACT_STATUS[8] },
  { id: 9, label: CONTRACT_STATUS[9] },
  { id: 10, label: CONTRACT_STATUS[10] },
];

export const STATUS_CLOSE_DOC = {
  0: 'Согласование',
  1: 'Направлен в ЭДО',
  2: 'Подписан в ЭДО',
  3: 'Направлены сканы',
  4: 'Получены сканы',
  5: 'Направлены оригиналы',
  6: 'Обменялись оригиналами',
  7: 'Чек получен',
  8: '-',
  9: 'Отмена',
  10: 'Запрошено',
};

STATUS_CLOSE_DOC.LIST = [
  { id: 0, label: STATUS_CLOSE_DOC[0] },
  { id: 1, label: STATUS_CLOSE_DOC[1] },
  { id: 2, label: STATUS_CLOSE_DOC[2] },
  { id: 3, label: STATUS_CLOSE_DOC[3] },
  { id: 4, label: STATUS_CLOSE_DOC[4] },
  { id: 5, label: STATUS_CLOSE_DOC[5] },
  { id: 6, label: STATUS_CLOSE_DOC[6] },
  { id: 7, label: STATUS_CLOSE_DOC[7] },
  { id: 8, label: STATUS_CLOSE_DOC[8] },
  { id: 9, label: STATUS_CLOSE_DOC[9] },
  { id: 10, label: STATUS_CLOSE_DOC[10] },
];

export const ACCOUNT_STATUS = {
  0: 'Счет направлен',
  1: 'Счет получен',
  2: '-',
};

ACCOUNT_STATUS.LIST = [
  { id: 0, label: ACCOUNT_STATUS[0] },
  { id: 1, label: ACCOUNT_STATUS[1] },
  { id: 2, label: ACCOUNT_STATUS[2] },
];

export const QUARTER_CLOSING = {
  0: '1кв22',
  1: '2кв22',
  2: '3кв22',
  3: '4кв22',
  4: '1кв23',
  5: '2кв23',
  6: '3кв23',
  7: '4кв23',
};

QUARTER_CLOSING.LIST = [
  { id: 0, label: QUARTER_CLOSING[0] },
  { id: 1, label: QUARTER_CLOSING[1] },
  { id: 2, label: QUARTER_CLOSING[2] },
  { id: 3, label: QUARTER_CLOSING[3] },
  { id: 4, label: QUARTER_CLOSING[4] },
  { id: 5, label: QUARTER_CLOSING[5] },
  { id: 6, label: QUARTER_CLOSING[6] },
  { id: 7, label: QUARTER_CLOSING[7] },
];

export const TRUE_FALSE_STATUS = {
  0: 'Нет',
  1: 'Да',
};

TRUE_FALSE_STATUS.LIST = [
  { id: 0, label: TRUE_FALSE_STATUS[0] },
  { id: 1, label: TRUE_FALSE_STATUS[1] },
];

export const INDICATORS_1_STATUS = {
  0: 'Красный',
  1: 'Желтый',
};

INDICATORS_1_STATUS.LIST = [
  { id: 0, label: INDICATORS_1_STATUS[0] },
  { id: 1, label: INDICATORS_1_STATUS[1] },
];

export const INDICATORS_2_STATUS = {
  0: 'Красный',

};

INDICATORS_2_STATUS.LIST = [
  { id: 0, label: INDICATORS_2_STATUS[0] },

];

export const PAYMENT_TYPES = {
  0: 'Оплата исполнителю',
  1: 'СК',
  2: 'Внутренний',
  3: 'Возмещение расходов сотрудника по проекту',
  4: 'Другое',
  5: 'По договору',
  6: 'Без договора',
};

PAYMENT_TYPES.LIST = [
  { id: 0, label: PAYMENT_TYPES[5] },
  { id: 1, label: PAYMENT_TYPES[6] },
];

PAYMENT_TYPES.LIST_LONG = [
  { id: 0, label: PAYMENT_TYPES[0] },
  { id: 1, label: PAYMENT_TYPES[1] },
  { id: 2, label: PAYMENT_TYPES[2] },
  { id: 3, label: PAYMENT_TYPES[3] },
  { id: 4, label: PAYMENT_TYPES[4] },
];

export const BANKS_NAMES = {
  0: 'Тинькофф',
  1: 'Сбербанк',
  2: 'Альфа банк',
  3: 'Наличные',
  4: 'Другое',
};

BANKS_NAMES.LIST = [
  { id: 0, label: BANKS_NAMES[0] },
  { id: 1, label: BANKS_NAMES[1] },
  { id: 2, label: BANKS_NAMES[2] },
  { id: 3, label: BANKS_NAMES[3] },
  { id: 4, label: BANKS_NAMES[4], comment: true },
];

export const defaultLegalEntitiesWithId = {
  id: Date.now(),
  legal_name: '',
  type: { id: null, name: '' },
  inn: '',
  passport_details: '',
  ogrn: '',
  ogrnip: '',
  kpp: '',
  address: '',
  file_links: '',
  bank_details: [
    {
      id: Date.now(),
      rc: '',
      bank: '',
      bik: '',
      kc: '',
    },
  ],
};

export const CLIENT_TYPES = {
  0: 'Бренд',
  1: 'Медийное агентство',
  2: 'Независимое агентство',
};

CLIENT_TYPES.LIST = [
  { id: 0, label: CLIENT_TYPES[0] },
  { id: 1, label: CLIENT_TYPES[1] },
  { id: 2, label: CLIENT_TYPES[2] },
];

export const BLOGGER_TYPES = {
  3: 'Блогер',
  4: 'Подрядчик',
  5: 'Шоу',
  6: 'Exclusive',
};

BLOGGER_TYPES.LIST = [
  { id: 3, label: BLOGGER_TYPES[3] },
  { id: 4, label: BLOGGER_TYPES[4] },
  { id: 5, label: BLOGGER_TYPES[5] },
  { id: 6, label: BLOGGER_TYPES[6] },
];

export const BID_PAYMENT_TYPES = {
  0: 'Исполнителю',
  1: 'Ск',
};

BID_PAYMENT_TYPES.LIST = [
  { id: 0, label: BID_PAYMENT_TYPES[0] },
  { id: 1, label: BID_PAYMENT_TYPES[1] },
];

export const SK_BID_TYPES = {
  0: 'Доход',
  1: 'Расход',
};

SK_BID_TYPES.LIST = [
  { id: 7, label: SK_BID_TYPES[0] },
  { id: 8, label: SK_BID_TYPES[1] },
];

export const SOCIAL_TYPES = {
  0: 'instagram',
  1: 'youtube',
  2: 'vk',
  3: 'telegram',
  4: 'twitch',
  5: 'tiktok',
};

SOCIAL_TYPES.LIST = [
  { id: 0, type: SOCIAL_TYPES[0], label: 'Instagram' },
  { id: 1, type: SOCIAL_TYPES[1], label: 'YouTube' },
  { id: 2, type: SOCIAL_TYPES[2], label: 'ВКонтакте' },
  { id: 3, type: SOCIAL_TYPES[3], label: 'Telegram канал' },
  { id: 4, type: SOCIAL_TYPES[4], label: 'Twitch' },
  { id: 5, type: SOCIAL_TYPES[5], label: 'TikTok' },
];

export const LEGAL_TYPES = {
  0: 'СЗ',
  1: 'ИП',
  2: 'ООО',
  3: 'АО',
  4: 'ОАО',
  5: 'СПАО',
  6: 'ПАО',
  7: 'АНО',
  8: '-',
  9: 'ТОО',
  10: 'Иностранное ЮЛ',
};

LEGAL_TYPES.LIST = [
  { id: 0, label: LEGAL_TYPES[0] },
  { id: 1, label: LEGAL_TYPES[1] },
  { id: 2, label: LEGAL_TYPES[2] },
  { id: 3, label: LEGAL_TYPES[3] },
  { id: 4, label: LEGAL_TYPES[4] },
  { id: 5, label: LEGAL_TYPES[5] },
  { id: 6, label: LEGAL_TYPES[6] },
  { id: 7, label: LEGAL_TYPES[7] },
  { id: 8, label: LEGAL_TYPES[8] },
  { id: 9, label: LEGAL_TYPES[9] },
  { id: 10, label: LEGAL_TYPES[10] },
];

export const TIME_FOR_DELETE_USER = 1500;

export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};
export const EMPTY_STRING = '';
export const EMPTY_VALUE = '-';

export const EXPORT_TYPES = {
  0: 'Заполнить договор',
  1: 'Заполнить приложение',
  2: 'Заполнить общий договор',
  3: 'Заполнить общее приложение',
};

export const RESPONSIBLE_TOKEN = {
  0: 'DIDENOK TEAM',
  1: 'Клиент/Блогер',
};

RESPONSIBLE_TOKEN.LIST = [
  { id: 0, label: RESPONSIBLE_TOKEN[0] },
  { id: 1, label: RESPONSIBLE_TOKEN[1] },
  { id: 2, label: RESPONSIBLE_TOKEN[2] },
];

export const RESPONSIBLE_TOKEN_CLIENT = {
  0: 'Прямой рекламодатель',
  1: 'Стороннее агентство',
};

RESPONSIBLE_TOKEN_CLIENT.LIST = [
  { id: 0, label: RESPONSIBLE_TOKEN_CLIENT[0] },
  { id: 1, label: RESPONSIBLE_TOKEN_CLIENT[1] },
];
