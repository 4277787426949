import React from 'react';

import classes from './CheckboxItem.module.css';

function CheckboxElement(props) {
  const {
    children, text, style, innerStyle, textStyle, className, disabled,
  } = props;

  const classNames = [classes.contract__element, className, disabled && classes.disabled].join(' ');
  return (
    <div className={classNames} style={style}>
      {text && (
        <div className={classes.element__text} style={textStyle}>
          {text}
        </div>
      )}

      <div className={classes.element__inner} style={innerStyle}>
        {children}
      </div>
    </div>
  );
}

export default CheckboxElement;
