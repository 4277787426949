import classes from '../Table.module.scss';

export const TABLE_PARAMS = {
  managersTasks: {
    className: classes.managersTasks,
  },
  projectTasks: {
    className: classes.projectTasks,
  },
  lawyersTasks: {
    className: classes.lawyersTasks,
  },
  managersTasksStaffCard: {
    className: classes.managersTasksStaffCard,
  },
  newsArchive: {
    className: classes.newsArchive,
  },
  publications: {
    className: classes.publications,
  },
  incomeSum: {
    className: classes.incomeSum,
  },
  expenseSum: {
    className: classes.expenseSum,
  },
  projectMargin: {
    className: classes.projectMargin,
  },
  projectEstimate: {
    className: classes.projectEstimate,
  },
  tasksDevelopment: {
    className: classes.tasksDevelopment,
  },
  reportPageTasks: {
    className: classes.reportPageTasks,
  },
  reportPageDevelopmentTasks: {
    className: classes.reportPageDevelopmentTasks,
  },
  reportPageLawyerTasks: {
    className: classes.reportPageLawyerTasks,
  },
  reportPageNews: {
    className: classes.reportPageNews,
  },
};
