import React from 'react';
import { Helmet } from 'react-helmet';

import ClientsLawyers from 'components/General/ClientsPage/ClientsPage';

function Clients() {
  return (
    <section className="Mysection">
      <Helmet>
        <title>Клиенты | DidenokTeam</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <ClientsLawyers />
    </section>
  );
}

export default Clients;
