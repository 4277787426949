// @ts-nocheck
import React from 'react';

import MyModal from 'components/UI/MyModal/MyModal';

import { stylesModalDeleteUserContainer } from './consts/consts';

import SuccessBtn from '../../Btns/SuccessBtn';

import classes from './ModalDeleteUser.module.scss';

function ModalDeleteUser({
  userName, show, onHide, props,
}) {
  const {
    click, closeClick, text, save,
  } = props;

  const send = () => {
    click();
    onHide();
  };

  const close = () => {
    if (closeClick) {
      closeClick();
    }

    onHide();
  };
  return (
    <MyModal
      noClose
      show={show}
      onHide={onHide}
      type="confirm"
      className={classes.MyModal_modal_body2}
      style={stylesModalDeleteUserContainer}
    >
      <div className={classes.text}>{text}</div>
      <div className={classes.text__userName}>{userName}</div>
      <div className={classes.btns}>
        <SuccessBtn
          text={save ? 'Да' : 'Удалить'}
          style={{
            padding: '5px 32px',
            fontSize: 13,
          }}
          onClick={() => send()}
        />

        <SuccessBtn
          text={save ? 'Нет' : 'Отменить'}
          type="white"
          style={{
            padding: '5px 32px',
            fontSize: 13,
          }}
          onClick={() => close()}
        />
      </div>
    </MyModal>
  );
}
export default ModalDeleteUser;
