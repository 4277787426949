// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import AccordionArrow from 'components/UI/AccordionArrows/AccordionArrow/AccordionArrow';

import {
  ONE_BLOGGER_ROUTE,
  ONE_CLIENT_ROUTE,
  ONE_LEGAL_ENTITY_ROUTE,
  STAFF_CARD_ROUTE,
} from 'utils/consts';

import image from '../../../../assets/icons/Lawyers/TeamsArrow.svg';

import TeamElementItem from './TeamElementItem';

import classes from '../styles/TeamsPage.module.css';

function TeamElement(props) {
  const {
    array, text, client, blogger, update, setUpdate, legalEntity,
  } = props;
  const [active, setActive] = useState(false);

  let route = STAFF_CARD_ROUTE;

  if (client) {
    route = ONE_CLIENT_ROUTE;
  } else if (blogger) {
    route = ONE_BLOGGER_ROUTE;
  } else if (legalEntity) {
    route = ONE_LEGAL_ENTITY_ROUTE;
  }

  useEffect(() => {
    if (array.length > 0) {
      if (update) {
        setActive(true);
        setUpdate(false);
      } else {
        setActive(false);
      }
    }
  }, [array]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.team__element} onClick={() => setActive(!active)}>
        <span>{text}</span>

        <AccordionArrow isActive={active && array.length > 0} />
      </div>

      <CSSTransition
        in={active && array.length > 0}
        timeout={400}
        classNames={legalEntity ? 'legal-entity' : 'lawyers-team'}
        appear
        unmountOnExit
      >
        <div>
          {array.map((listElement) => (
            <TeamElementItem
              key={listElement.id}
              element={listElement}
              image={image}
              to={route}
            />
          ))}
        </div>
      </CSSTransition>
    </div>
  );
}

export default TeamElement;
