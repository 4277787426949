// @ts-nocheck
import { makeAutoObservable } from 'mobx';

// Стор для каждого модального окна
export default class ModalStore {
  constructor() {
    this._modalCreateProjectVisible = false;
    this._modalCreateProjectProps = {};

    this._modalApplicationContractVisible = false;
    this._modalApplicationContractProps = {};

    this._modalCreateStaffVisible = false;
    this._modalCreateStaffProps = {};

    this._modalCreateBloggerVisible = false;
    this._modalCreateBloggerProps = {};

    this._modalCreateEntityVisible = false;
    this._modalCreateEntityProps = {};

    this._modalCreateClientVisible = false;
    this._modalCreateClientProps = {};

    this._modalConfirmVisible = false;
    this._modalConfirmProps = {};

    this._modalDeleteUserVisible = false;
    this._modalDeleteUserProps = {};

    this._modalApplicationSKVisible = false;
    this._modalApplicationSKProps = {};

    this._modalApplicationCostVisible = false;
    this._modalApplicationCostProps = {};

    this._modalExportGoogleVisible = false;
    this._modalExportGoogleProps = {};

    this._modalTransferProjectsVisible = false;
    this._modalTransferProjectsProps = {};

    this._modalEditPaymentsVisible = false;
    this._modalEditPaymentsProps = {};

    this._modalEditRowVisible = false;
    this._modalEditRowProps = {};

    makeAutoObservable(this);
  }

  setModalCreateProjectVisible(bool) {
    this._modalCreateProjectVisible = bool;
  }

  setModalCreateProjectProps(props) {
    this._modalCreateProjectProps = props;
  }

  setModalEditPaymentsVisible(bool) {
    this._modalCreateProjectVisible = bool;
  }

  setModalEditPaymentsProps(props) {
    this._modalCreateProjectProps = props;
  }

  setModalApplicationContractVisible(bool) {
    this._modalApplicationContractVisible = bool;
  }

  setModalApplicationContractProps(props) {
    this._modalApplicationContractProps = props;
  }

  setModalCreateStaffVisible(bool) {
    this._modalCreateStaffVisible = bool;
  }

  setModalCreateStaffProps(props) {
    this._modalCreateStaffProps = props;
  }

  setModalCreateBloggerVisible(bool) {
    this._modalCreateBloggerVisible = bool;
  }

  setModalCreateEntityVisible(bool) {
    this._modalCreateEntityVisible = bool;
  }

  setModalCreateBloggerProps(props) {
    this._modalCreateBloggerProps = props;
  }

  setModalCreateClientVisible(bool) {
    this._modalCreateClientVisible = bool;
  }

  setModalCreateClientProps(props) {
    this._modalCreateClientProps = props;
  }

  setModalConfirmVisible(bool) {
    this._modalConfirmVisible = bool;
  }

  setModalConfirmProps(props) {
    this._modalConfirmProps = props;
  }

  //

  setModalDeleteUserVisible(bool) {
    this._modalDeleteUserVisible = bool;
  }

  setModalDeleteUserProps(props) {
    this._modalDeleteUserProps = props;
  }

  setModalApplicationSKVisible(bool) {
    this._modalApplicationSKVisible = bool;
  }

  setModalApplicationSKProps(props) {
    this._modalApplicationSKProps = props;
  }

  setModalApplicationCostVisible(bool) {
    this._modalApplicationCostVisible = bool;
  }

  setModalApplicationCostProps(props) {
    this._modalApplicationCostProps = props;
  }

  setModalTransferProjectsVisible(bool) {
    this._modalTransferProjectsVisible = bool;
  }

  setModalTransferProjectsProps(props) {
    this._modalApplicationPaymentProps = props;
  }

  setModalExportGoogleVisible(bool) {
    this._modalExportGoogleVisible = bool;
  }

  setModalExportGoogleProps(props) {
    this._modalExportGoogleProps = props;
  }

  setModalEditRowVisible(bool) {
    this._modalEditRowVisible = bool;
  }

  setModalEditRowProps(props) {
    this._modalEditRowProps = props;
  }

  get modalCreateProjectVisible() {
    return this._modalCreateProjectVisible;
  }

  get modalCreateProjectProps() {
    return this._modalCreateProjectProps;
  }

  get modalApplicationContractVisible() {
    return this._modalApplicationContractVisible;
  }

  get modalApplicationContractProps() {
    return this._modalApplicationContractProps;
  }

  get modalCreateStaffVisible() {
    return this._modalCreateStaffVisible;
  }

  get modalCreateStaffProps() {
    return this._modalCreateStaffProps;
  }

  get modalCreateBloggerVisible() {
    return this._modalCreateBloggerVisible;
  }

  get modalCreateBloggerProps() {
    return this._modalCreateBloggerProps;
  }

  get modalCreateEntityVisible() {
    return this._modalCreateEntityVisible;
  }

  get modalCreateEntityProps() {
    return this._modalCreateEntityProps;
  }

  get modalCreateClientVisible() {
    return this._modalCreateClientVisible;
  }

  get modalCreateClientProps() {
    return this._modalCreateClientProps;
  }

  get modalConfirmVisible() {
    return this._modalConfirmVisible;
  }

  get modalConfirmProps() {
    return this._modalConfirmProps;
  }

  //

  get modalDeleteUserVisible() {
    return this._modalDeleteUserVisible;
  }

  get modalDeleteUserProps() {
    return this._modalDeleteUserProps;
  }

  get modalApplicationSKVisible() {
    return this._modalApplicationSKVisible;
  }

  get modalApplicationSKProps() {
    return this._modalApplicationSKProps;
  }

  get modalApplicationCostVisible() {
    return this._modalApplicationCostVisible;
  }

  get modalApplicationCostProps() {
    return this._modalApplicationCostProps;
  }

  get modalTransferProjectsVisible() {
    return this._modalTransferProjectsVisible;
  }

  get modalTransferProjectsProps() {
    return this._modalTransferProjectsProps;
  }

  get modalExportGoogleVisible() {
    return this._modalExportGoogleVisible;
  }

  get modalExportGoogleProps() {
    return this._modalExportGoogleProps;
  }

  get modalEditPaymentsVisible() {
    return this._modalEditPaymentsVisible;
  }

  get modalEditPaymentsProps() {
    return this._modalEditPaymentsProps;
  }

  get modalEditRowVisible() {
    return this._modalEditRowVisible;
  }

  get modalEditRowProps() {
    return this._modalEditRowProps;
  }
}
