// @ts-nocheck

import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { exportGoogleRegistryTableProjectAccounting, getProjectsAccountingTable } from 'API/FinanceAPI/ProjectAccounting';
import {
  autocompleteAllLegalEntities,
} from 'API/LawyersAPI/AutocompleteAPI';
import { fetchAllProjects, fetchStaff } from 'API/ManagerAPI/AutocompleteAPI';
import { check } from 'API/UserAPI';

import { HEADERS_GOOGLE_EXPORT_PROJECT_ACCOUNTING } from 'components/Finance/FinanceMainOld/consts';
import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import ModalEditRow from 'components/UI/Modals/ModalEditRow/ModalEditRow';
import ModalExportGoogle from 'components/UI/Modals/ModalExportGoogle/ModalExportGoogle';
import RegistryTableProjectAccounting from 'components/UI/RegistryTable/RegistryTableProjectAccounting';

import OneTableRow from '../../UI/OneTableRow/OneTableRow';
import PageTitle from '../../UI/PageTitle/PageTitle';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from '../../../hooks/useLogout';

import FilterProjectAccounting from './FilterProjectAccounting';

import classes from './ProjectAccounting.module.css';

const ProjectAccounting = observer(() => {
  const { modal, projectsFinance } = useContext(Context);
  const table = useRef();
  const isFirstRender = useRef(true);
  const controller = new AbortController();
  const [logout] = useLogout();

  const [fetching, setFetching] = useState(true);
  const [url, setUrl] = useState('');
  const [totalSum, setTotalSum] = useState(0);

  const [columnsList, setColumnsList] = useState([]);

  const [projects, setProjects] = useState([]);
  const [managers, setManagers] = useState([]);
  const [responsibleList, setResponsibleList] = useState([]);
  const [directorList, setDirectorList] = useState([]);
  const [allLegalEntity, setAllLegalEntity] = useState([]);

  useEffect(() => {
    check()
      .then(() => {
        fetchAllProjects().then((projectsData) => setProjects(projectsData));
        autocompleteAllLegalEntities()
          .then((allLegalEntityData) => setAllLegalEntity(allLegalEntityData));

        fetchStaff({ role: 1 }).then((data) => setDirectorList(data));

        fetchStaff({ job: 0 }).then((managersData) => setManagers(managersData));

        fetchStaff({ job: [4, 5, 6] }).then((data) => setResponsibleList(data));
      })
      .catch((error) => logout(error));
  }, []);

  const [fetchProjects, isProjectsLoading] = useFetching(
    async (page, limit, signal, filter, update) => {
      await check().catch((error) => logout(error));

      const data = await getProjectsAccountingTable(page, limit, signal, filter);
      projectsFinance.setProjectsTotalCount(data.count);

      if (update || projectsFinance.projectsPage === 1) {
        setTotalSum(data.total_sum);
        projectsFinance.setProjectsPage(2);
        projectsFinance.setProjects(data.results, 'update');
      } else {
        projectsFinance.setProjectsPage(projectsFinance.projectsPage + 1);
        projectsFinance.setProjects(data.results);
      }

      setFetching(false);
    },
  );

  const totalPages = Math.ceil(
    projectsFinance.projectsTotalCount / projectsFinance.projectsLimit,
  );
  /* Запрос на сервер при скролле до конца таблицы */

  useEffect(() => {
    if (fetching) {
      if (projectsFinance.projectsFilter) {
        fetchProjects(
          projectsFinance.projectsPage,
          projectsFinance.projectsLimit,
          controller.signal,
          projectsFinance.projectsFilter,
        );
      } else {
        fetchProjects(
          projectsFinance.projectsPage,
          projectsFinance.projectsLimit,
          controller.signal,
          projectsFinance.projectsFilter,
        );
      }
    }

    return () => {
      controller.abort();
    };
  }, [fetching]);

  // Когда доскролили до нижней части таблицы (первое условие)
  // Проверяем текущую страницу с общим количеством страниц(второе условие)
  // Далее делаем запрос

  const scrollHandler = debounce((event) => {
    if (
      event.target.scrollHeight
        - (event.target.scrollTop + event.target.clientHeight)
        < 600
      && projectsFinance.projectsPage <= totalPages
    ) {
      setFetching(true);
    }
  }, 100);

  // Наблюдение за скроллом

  useEffect(() => {
    if (isProjectsLoading) return;

    const element = table.current;
    element.addEventListener('scroll', scrollHandler);
    return function () {
      element.removeEventListener('scroll', scrollHandler);
    };
  }, [isProjectsLoading]);

  const click = async () => {
    try {
      const res = await exportGoogleRegistryTableProjectAccounting(
        projectsFinance.projectsPage,
        projectsFinance.projectsLimit,
        controller.signal,
        projectsFinance.projectsFilter,
        columnsList,
      );
      setUrl(res.sheet_url);
    } catch (err) {
      console.error(err);
    }
  };

  const exportGoogleHandler = () => {
    modal.setModalExportGoogleProps({
      headers: HEADERS_GOOGLE_EXPORT_PROJECT_ACCOUNTING,
    });
    modal.setModalExportGoogleVisible(true);
  };

  if (isProjectsLoading && isFirstRender.current) {
    isFirstRender.current = false;
    return <Spinner animation="grow" />;
  }

  const columns = [
    { id: 'project', label: 'Проект' },
    { id: 'client_legal_entity', label: 'ЮЛ заказчика' },
    { id: 'contractor_legal_entity', label: 'Юл исполнителя' },
    { id: 'realization_period', label: 'Период реализации' },
    { id: 'status', label: 'Статус учета' },
    { id: 'g_l', label: 'G/L' },
    { id: 'sum', label: 'Сумма' },
    { id: 'tax', label: 'НДС' },
    { id: 'payment_date', label: 'Порядок оплаты' },
    { id: 'adesk', label: 'Адеск' },
    { id: 'paid_sum', label: 'Оплачено' },
    { id: 'balance', label: 'Остаток' },
    { id: 'month_and_accounting_amount', label: 'Месяц и сумма учета' },
    { id: 'quarter', label: 'Квартал' },
    { id: 'comment', label: 'Комментарий' },
  ];

  const propsForFilters = {
    fetchProjects,
    allLegalEntity,
    projectList: projects,
    managerList: managers,
    directorList,
    responsibleList,
    controller,
  };

  const propsForRegistryTable = {
    fetching,
    projectAccounting: true,
    columns,
    noDeadline: true,
    data: projectsFinance.projects,
    tableName: 'projectAccounting',
    tableRef: table,
  };

  return (
    <>
      <div className={classes.margin__container}>
        <PageTitle
          style={{ justifyContent: 'space-between' }}
          button={(
            <SuccessBtn
              text="Экспорт в Google"
              style={{ padding: '4px 18px', fontSize: 11 }}
              onClick={() => exportGoogleHandler()}
            />
        )}
        >
          Учёт проектов
        </PageTitle>

        <div className={classes.lawyers__status}>Статус учёта</div>

        <FilterProjectAccounting {...propsForFilters} />
      </div>

      <RegistryTableProjectAccounting {...propsForRegistryTable} />

      <div className={classes.margin__container}>
        <OneTableRow
          style={{ paddingRight: 0 }}
          rowName="mainLawyer"
          columns={[
            { id: 'text', label: 'НЕ ПРИНЯТЫ К УЧЕТУ' },
            { id: 'sum', label: projectsFinance.projectsTotalCount },
          ]}
        />
      </div>

      <ModalExportGoogle
        show={modal.modalExportGoogleVisible}
        onHide={() => modal.setModalExportGoogleVisible(false)}
        props={modal.modalExportGoogleProps}
        click={click}
        columnsList={columnsList}
        setColumnsList={setColumnsList}
        url={url}
        setUrl={setUrl}
      />
      <ModalEditRow
        show={modal.modalEditRowVisible}
        onHide={() => modal.setModalEditRowVisible(false)}
        props={modal.modalEditRowProps}
      />
    </>
  );
});

export default ProjectAccounting;
