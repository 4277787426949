import React from 'react';

import { numberToSum } from '../../../utils/getSums';

import css from './ProjectMarginBreakdown.module.scss';

function ProjectMarginBreakdown({
  planned_expense,
  actual_expense,
  margin,
}) {
  return (
    <div className={css.breakdown}>
      <ul className={css.list}>
        <li className={css.item}>
          <span>Сумма расходов (план)</span>
          <span>{numberToSum(planned_expense, true)}</span>
        </li>
        <li className={css.item}>
          <span>Сумма расходов (факт)</span>
          <span>{numberToSum(actual_expense, true)}</span>
        </li>
        <li className={css.item}>
          <span>Маржа по проекту</span>
          <span>{numberToSum(margin, true)}</span>
        </li>
      </ul>
    </div>
  );
}

export default ProjectMarginBreakdown;
