// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { Context } from 'index';

import { fetchTeamsCreateStaff } from 'API/ManagerAPI/AutocompleteAPI';
import { check, createUser } from 'API/UserAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import InputElement from 'components/UI/InputElements/InputElement';
import MyModal from 'components/UI/MyModal/MyModal';

import classes from './ModalCreateStaff.module.css';

function ModalCreateStaff({ show, onHide, props }) {
  const { toast, modal } = useContext(Context);
  const {
    departments,
    roles,
    fetchAllUsersFunc,
    fetchManager,
    fetchLawyers,
    fetchDocManagers,
    fetchFinance,
    fetchDevelopment,
    fetchCreative,
    fetchProduction,
    fetchDeletedUsers,
  } = props;

  const [teams, setTeams] = useState([]);

  const [info, setInfo] = useState({
    fullname: '',
    work_email: '',
    telegram: '',
    birthday: '',
    onboarding_date: '',
    contract_type: '',
    phone: '',
    adress: '',
    salary: '',
    citizenship: '',
    post: '',
  });

  const [selectedDirection, setSelectedDirection] = useState({
    id: null,
    name: undefined,
    jobs: [],
  });

  const [selectedPost, setSelectedPost] = useState({
    id: null,
    name: undefined,
  });

  const [selectedRole, setSelectedRole] = useState({
    id: null,
    name: undefined,
  });
  const [selectedTeam, setSelectedTeam] = useState({
    id: null,
    name: undefined,
  });

  const updateInfoHandler = (fieldName, value) => {
    setInfo({ ...info, [fieldName]: value });
  };

  const close = () => {
    setTimeout(() => {
      setInfo((prevState) => ({
        ...prevState,
        fullname: '',
        work_email: '',
        telegram: '',
        birthday: '',
        onboarding_date: '',
        contract_type: '',
        phone: '',
        adress: '',
        salary: '',
        citizenship: '',
        post: '',
      }));
      setSelectedDirection({
        id: null,
        name: undefined,
        jobs: [],
      });
      setSelectedPost({ id: null, name: undefined });
      setSelectedRole({ id: null, name: undefined });
      setSelectedTeam({ id: null, name: undefined });
    }, 120);

    onHide();
  };

  const send = () => {
    const password = info.work_email.split('@').shift();
    const name = info.fullname.replace(/ +/g, ' ').trim().split(' ')[0];
    const surname = info.fullname.replace(/ +/g, ' ').trim().split(' ')[1];

    if (!name) {
      toast.setToastInfo('Введите имя');
      toast.setShowError(true);
      return;
    }

    if (!surname) {
      toast.setToastInfo('Введите фамилию');
      toast.setShowError(true);
      return;
    }

    if (!info.work_email) {
      toast.setToastInfo('Введите почту');
      toast.setShowError(true);
      return;
    }

    if (!selectedDirection.name) {
      toast.setToastInfo('Выберите отдел');
      toast.setShowError(true);
      return;
    }

    if (!selectedRole.id) {
      toast.setToastInfo('Выберите уровень доступа');
      toast.setShowError(true);
      return;
    }

    if (selectedRole.id === 2) {
      if (!selectedTeam.id) {
        toast.setToastInfo('Выберите команду');
        toast.setShowError(true);
        return;
      }

      if (!info.post) {
        toast.setToastInfo('Введите должность');
        toast.setShowError(true);
        return;
      }

      if (selectedDirection.id === 1) {
        if (!selectedPost.id) {
          toast.setToastInfo('Выберите направление');
          toast.setShowError(true);
          return;
        }
      }
    }

    check().then(() => {
      const data = {
        name,
        surname,
        email: info.work_email,
        password,
        department: { id: selectedDirection.id },
        role: { id: selectedRole.id },
        username: info.work_email,
        info: {
          birthday: info.birthday,
          citizenship: info.citizenship,
          address: info.adress,
          phone: info.phone,
          telegram: info.telegram,
          onboarding_date: info.onboarding_date,
          salary: info.salary,
          contract_type: info.contract_type,
          comment: '',
          post: info.post,
        },
      };

      if (selectedTeam.id) {
        data.team = { id: selectedTeam.id };
      }

      if (selectedRole.id === 2) {
        if (selectedDirection.id === 0) {
          data.job = { id: 0 };
        }
        if (selectedDirection.id === 2) {
          data.job = { id: 3 };
        }
      }

      if (selectedDirection.id === 1) {
        data.job = { id: selectedPost.id };
      }

      createUser(data)
        .then(() => {
          fetchAllUsersFunc();
          fetchManager();
          fetchLawyers();
          fetchDocManagers();
          fetchFinance();
          fetchDevelopment();
          fetchCreative();
          fetchProduction();
          fetchDeletedUsers();

          toast.setToastInfo('Новый пользователь создан');
          toast.setShowSuccess(true);
          close();
        })
        .catch((error) => {
          toast.setToastInfo('Пользователь с такой почтой уже существует');
          toast.setShowError(true);
          console.log(error);
        });
    });
  };

  useEffect(() => {
    if (selectedDirection.id !== null) {
      fetchTeamsCreateStaff({ department: selectedDirection.id })
        .then((res) => setTeams(res));
    }
  }, [selectedDirection]);

  const closeModal = () => {
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите закрыть форму?',
      click: () => {
        onHide();
      },
    });
    modal.setModalConfirmVisible(true);
  };

  return (
    <MyModal createUser={createUser} show={show} onHide={closeModal} style={{ height: '791px', padding: '60px 60px 100px 60px' }}>
      <h1>Новый сотрудник</h1>
      <header className={classes.header}>Общая информация</header>

      <div className={classes.wrapper}>
        <InputElement
          text="Сотрудник"
          textStyle={{ minWidth: 230 }}
          value={info.fullname}
          onChange={(event) => updateInfoHandler('fullname', event.target.value)}
          placeholder="Имя Фамилия"
        />
        <InputElement
          text="Дата рождения"
          type="date"
          textStyle={{ minWidth: 230 }}
          value={info.birthday}
          onChange={(event) => updateInfoHandler('birthday', event)}
          date
        />
        <InputElement
          text="Отдел"
          textStyle={{ minWidth: 230 }}
          value={selectedDirection}
          setValue={setSelectedDirection}
          dropdown={departments}
        />
        <InputElement
          text="Уровень доступа"
          textStyle={{ minWidth: 230 }}
          value={selectedRole}
          setValue={setSelectedRole}
          dropdown={roles}
        />
        {selectedRole.id === 2 && (
        <InputElement
          text="Команда"
          textStyle={{ minWidth: 230 }}
          value={selectedTeam}
          setValue={setSelectedTeam}
          dropdown={teams}
        />
        )}
        <InputElement
          text="Почта (рабочая)"
          textStyle={{ minWidth: 230 }}
          value={info.work_email}
          onChange={(event) => updateInfoHandler('work_email', event.target.value)}
        />
        {selectedDirection.id === 1 && (
        <InputElement
          text="Направление"
          textStyle={{ minWidth: 230 }}
          value={selectedPost}
          setValue={setSelectedPost}
          dropdown={selectedDirection.jobs}
        />
        )}
        <InputElement
          text="Должность"
          textStyle={{ minWidth: 230 }}
          value={info.post}
          onChange={(event) => updateInfoHandler('post', event.target.value)}
        />
        <InputElement
          text="Дата приема на работу"
          type="date"
          textStyle={{ minWidth: 230 }}
          value={info.onboarding_date}
          onChange={(event) => updateInfoHandler('onboarding_date', event)}
          date
        />
        <InputElement
          text="Оформление"
          textStyle={{ minWidth: 230 }}
          value={info.contract_type}
          onChange={(event) => updateInfoHandler('contract_type', event.target.value)}
        />
        <InputElement
          text="Номер телефона"
          textStyle={{ minWidth: 230 }}
          value={info.phone}
          onChange={(event) => updateInfoHandler('phone', event.target.value)}
        />
        <InputElement
          text="Телеграм"
          textStyle={{ minWidth: 230 }}
          value={info.telegram}
          onChange={(event) => updateInfoHandler('telegram', event.target.value)}
        />
        <InputElement
          text="Адрес проживания"
          textStyle={{ minWidth: 230 }}
          value={info.adress}
          onChange={(event) => updateInfoHandler('adress', event.target.value)}
        />
        <InputElement
          text="Гражданство"
          textStyle={{ minWidth: 230 }}
          value={info.citizenship}
          onChange={(event) => updateInfoHandler('citizenship', event.target.value)}
        />
      </div>

      <div className={classes.btn__container}>
        <SuccessBtn
          text="Добавить нового сотрудника"
          style={{ padding: '3px 15px', fontSize: 13 }}
          onClick={() => send()}
        />
      </div>
    </MyModal>
  );
}

export default ModalCreateStaff;
