// @ts-nocheck
import { makeAutoObservable } from 'mobx';

export default class ReportNewsStore {
  constructor() {
    this._reportNews = [];
    this._reportNewsTotalCount = 0;
    this._reportNewsPage = 1;
    this._reportNewsLimit = 15;
    this._reportNewsFilter = undefined;

    makeAutoObservable(this);
  }

  setReportNews(reportNews, update) {
    if (update) {
      return (this._reportNews = reportNews);
    }

    if (this._reportNewsPage === 1) {
      return (this._reportNews = reportNews);
    }

    reportNews.forEach((reportNewsOne) => this._reportNews.push(reportNewsOne));
  }

  setReportNewsTotalCount(count) {
    this._reportNewsTotalCount = count;
  }

  setReportNewsPage(page) {
    this._reportNewsPage = page;
  }

  setReportNewsFilter(filter) {
    this._reportNewsFilter = filter;
  }

  get reportNews() {
    return this._reportNews;
  }

  get reportNewsTotalCount() {
    return this._reportNewsTotalCount;
  }

  get reportNewsPage() {
    return this._reportNewsPage;
  }

  get reportNewsLimit() {
    return this._reportNewsLimit;
  }

  get reportNewsFilter() {
    return this._reportNewsFilter;
  }
}
