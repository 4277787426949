import { formatISO } from 'date-fns';

export const getDate = (time, hours) => {
  if (time) {
    const date = new Date(time);

    date.setHours(date.getHours() + 3);

    if (hours) {
      return date.toISOString().slice(0, 16);
    }

    return formatISO(date).slice(0, 10);
  }

  const date = new Date();
  return formatISO(date).slice(0, 10);
};

export const getFormattedDate = (time) => {
  const addLeadingZero = (data) => (data < 10 ? `0${data}` : data);

  const date = new Date(time);

  const year = date.getUTCFullYear();
  const month = addLeadingZero(date.getUTCMonth() + 1);
  const day = addLeadingZero(date.getDate());
  const hour = addLeadingZero(date.getHours());
  const minutes = addLeadingZero(date.getMinutes());
  const formattedDate = `${year}-${month}-${day}T${hour}:${minutes}`;

  return formattedDate;
};

export const getTime = (time) => {
  if (time) {
    return time.slice(11, 16);
  }
};

// Переводит из формата 2000-01-01 в 01.01.2000
export const reversDate = (date) => {
  if (date) {
    return new Date(date).toLocaleDateString();
  }
  return '';
};

// Переводит из формата 01.01.2000  в 2000-01-01
export const formatedDate = (date) => {
  // Разделяем день, месяц и год на отдельные части
  if (date) {
    const dateParts = date.split('.');
    const day = dateParts[0];
    const month = dateParts[1];
    const year = dateParts[2];

    // Форматируем дату в формат "гггг-мм-дд"
    const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

    return formattedDate;
  }
  return '';
};
