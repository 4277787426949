// @ts-nocheck
import React from 'react';

import InputElement from 'components/UI/InputElements/InputElement';
import DateElement from 'components/UI/Modals/ModalCreateContract/components/DateElement';

const Step1 = React.memo((props) => {
  const {
    firstStepValues,
    updateFirstStepValues,
    id,
    name,
    adv_object,
    adv_object_comment,
  } = props;

  return (
    <>
      <InputElement text="№ проекта" value={id} disabled />
      <InputElement text="Название проекта" value={name} disabled />
      <InputElement text="Объект рекламирования" value={adv_object} disabled />
      <InputElement text="Комментарий" value={adv_object_comment} disabled />

      <DateElement
        value1={firstStepValues.date_start}
        value2={firstStepValues.date_end}
        onChange1={(event) => updateFirstStepValues('date_start', event)}
        onChange2={(event) => updateFirstStepValues('date_end', event)}
        type="date"
        text=" Общий срок оказания услуг:"
        style={{ margin: '10px 0' }}
        inner_style={{ width: 'auto' }}
      />
    </>
  );
});

export default Step1;
