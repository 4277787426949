// @ts-nocheck

import React, {
  useContext, useEffect, useRef,
  useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import { check } from 'API/UserAPI';
import {
  exportGoogleRegistryTableMain,
  getRegistryTableMain,
  patchRegistryTableMain,
} from '../../../API/FinanceAPI/RegistryTableAPI';
import {
  autocompleteAllLegalEntities,
} from '../../../API/LawyersAPI/AutocompleteAPI';
import {
  fetchAllProjects,
  fetchStaff,
} from '../../../API/ManagerAPI/AutocompleteAPI';

import ModalConfirm from 'components/UI/Modals/ModalConfirm/ModalConfirm';
import ModalExportGoogle from 'components/UI/Modals/ModalExportGoogle/ModalExportGoogle';
import PageTitle from 'components/UI/PageTitle/PageTitle';

import SuccessBtn from '../../UI/Btns/SuccessBtn';
import PaymentsTable from '../../UI/PaymentsTable/PaymentsTable';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from 'hooks/useLogout';

import { getSum, numberToSum } from 'utils/getSums';

import { HEADERS, HEADERS_GOOGLE_EXPORT_FINANCE_MAIN } from './consts';
import { prepareDataForTable, setLocalStorageValue } from './Helpers';

// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import FinanceFilter from './FinanceFilter';

const FinanceMain = observer(() => {
  const { projectsFinance, modal } = useContext(Context);
  const [logout] = useLogout();
  const table = useRef();
  const isFirstRender = useRef(true);
  const controller = new AbortController();

  const [fetching, setFetching] = useState(true);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [url, setUrl] = useState('');

  const [projects, setProjects] = useState([]);
  const [lawyers, setLawyers] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [managers, setManagers] = useState([]);
  const [allLegalEntity, setAllLegalEntity] = useState([]);

  const [columnsList, setColumnsList] = useState([]);

  const totalPages = Math.ceil(
    projectsFinance.projectsTotalCount / projectsFinance.projectsLimit,
  );

  useEffect(() => {
    check()
      .then(() => {
        autocompleteAllLegalEntities()
          .then((allLegalEntityData) => setAllLegalEntity(allLegalEntityData));
        fetchStaff({ job: 1 }).then((lawyersData) => setLawyers(lawyersData));
        fetchStaff({ job: 2 }).then((documentsData) => setDocuments(documentsData));

        fetchStaff({ job: 0 }).then((managersData) => setManagers(managersData));
        fetchAllProjects().then((projectsData) => setProjects(projectsData));
      })
      .catch((error) => logout(error));
  }, []);

  const [fetchContracts, isContractsLoading] = useFetching(
    async (page, limit, signal, filter, update) => {
      await check().catch((error) => logout(error));
      const data = await getRegistryTableMain(page, limit, signal, filter);
      const formattedData = prepareDataForTable(data?.results);

      projectsFinance.setProjectsTotalCount(data.count);

      if (update || projectsFinance.projectsPage === 1) {
        projectsFinance.setProjectsPage(2);
        projectsFinance.setProjects(formattedData, 'update');
      } else {
        projectsFinance.setProjectsPage(projectsFinance.projectsPage + 1);
        projectsFinance.setProjects(formattedData);
      }

      setFetching(false);
    },
  );

  const updateFieldHandler = (row, fieldName, value) => {
    check()
      .then(() => {
        if (fieldName === 'adesk') {
          const data = { adesk: false };
          if (value.id === 1) {
            data.adesk = true;
          }
          patchRegistryTableMain(row.id, data);
        } else if (fieldName === 'comment') {
          patchRegistryTableMain(row.id, {
            comment: value,
          });
        } else if (fieldName === 'paid_sum') {
          patchRegistryTableMain(row.id, {
            paid_sum: getSum(value),
          });
        } else {
          patchRegistryTableMain(row.id, {
            quarter_payments: value,
          });
        }

        if (fieldName === 'paid_sum') {
          projectsFinance.setProjects(
            projectsFinance.projects.map((item) => (item.id === row.id
              ? {
                ...item,
                [fieldName]: value,
                credit: numberToSum(getSum(item.total_sum) - getSum(value)),
              } : item)),
            'update',
          );
        } else {
          projectsFinance.setProjects(
            projectsFinance.projects.map((item) => (item.id === row.id
              ? { ...item, [fieldName]: value } : item)),
            'update',
          );
        }
      })
      .catch((error) => logout(error));
  };

  /* Запрос на сервер при скролле до конца таблицы */

  useEffect(() => {
    if (fetching) {
      fetchContracts(
        projectsFinance.projectsPage,
        projectsFinance.projectsLimit,
        controller.signal,
        projectsFinance.projectsFilter,
      );
    }
    return () => {
      controller.abort();
    };
  }, [fetching]);

  /* Когда доскролили до нижней части таблицы (первое условие)
    Проверяем текущую страницу с общим количеством страниц(второе условие)
    Далее делаем запрос
    */

  const scrollHandler = (event) => {
    if (
      event.target.scrollHeight
         - (event.target.scrollTop + event.target.clientHeight)
         < 600
       && projectsFinance.projectsPage <= totalPages
    ) {
      setFetching(true);
    }
  };

  // Наблюдение за скроллом

  useEffect(() => {
    if (isContractsLoading) return;

    const element = table.current;
    element.addEventListener('scroll', scrollHandler);
    return function () {
      element.removeEventListener('scroll', scrollHandler);
    };
  }, [isContractsLoading]);

  const click = async () => {
    try {
      const res = await exportGoogleRegistryTableMain(
        projectsFinance.projectsPage,
        projectsFinance.projectsLimit,
        controller.signal,
        projectsFinance.projectsFilter,
        columnsList,
      );
      setUrl(res.sheet_url);
    } catch (err) {
      console.error(err);
    }
  };

  const exportGoogleHandler = () => {
    modal.setModalExportGoogleProps({
      headers: HEADERS_GOOGLE_EXPORT_FINANCE_MAIN,
    });
    modal.setModalExportGoogleVisible(true);
  };

  if (isContractsLoading && isFirstRender.current) {
    isFirstRender.current = false;
    return <Spinner animation="grow" />;
  }

  const propsForPaymentsTable = {
    tableRef: table,
    headers: HEADERS,
    data: projectsFinance.projects,
    setLocalStorageValue,
    initialHiddenColumns: JSON.parse(localStorage.getItem('financeMainHiddenColumns')),
    updateFieldHandler,
    filterIsOpen,
    setFilterIsOpen,
  };

  const propsForFinanceFilter = {
    isOpen: filterIsOpen,
    setIsOpen: setFilterIsOpen,
    projectList: projects,
    responsibleList: managers,
    lawyersList: lawyers,
    documentList: documents,
    allLegalEntity,
    fetchProjects: fetchContracts,
  };

  return (
    <>
      <PageTitle
        style={{ justifyContent: 'space-between' }}
        button={(
          <SuccessBtn
            text="Экспорт в Google"
            style={{ padding: '4px 18px', fontSize: 11 }}
            onClick={() => exportGoogleHandler()}
          />
        )}
      >
        Реестр проектов
      </PageTitle>

      <PaymentsTable
        {...propsForPaymentsTable}
        filterWindow={<FinanceFilter {...propsForFinanceFilter} />}
      />

      <ModalConfirm
        show={modal.modalConfirmVisible}
        onHide={() => modal.setModalConfirmVisible(false)}
        props={modal.modalConfirmProps}
      />

      <ModalExportGoogle
        show={modal.modalExportGoogleVisible}
        onHide={() => modal.setModalExportGoogleVisible(false)}
        props={modal.modalExportGoogleProps}
        click={click}
        columnsList={columnsList}
        setColumnsList={setColumnsList}
        url={url}
        setUrl={setUrl}
      />

    </>
  );
});

export default FinanceMain;
