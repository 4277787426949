// @ts-nocheck
import React, { useContext, useState } from 'react';
import { Context } from 'index';

import { patchOneEntity } from 'API/LegalEntityAPI';
import { check } from 'API/UserAPI';

import NewAddBtn from 'components/UI/Btns/NewAddBtn';
import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import InputElement from 'components/UI/InputElements/InputElement';
import InputElementInn from 'components/UI/InputElements/InputElementInn';

import { useLogout } from 'hooks/useLogout';

import { LEGAL_TYPES } from 'utils/consts';
import { isValidURL } from 'utils/isValidUrl';

import {
  BANK_FIELDS, FIELDS,
  OGRN_FIELDS, OGRNIP_FIELDS,
} from '../Fields';

import classes from '../LegalEntityInfo.module.css';

function OneLegalEntityInfo(props) {
  const {
    element,
    legalEntities,
    setLegalEntities,
    updateLegalEntities,
    removeLegalEntity,
    addLegalEntities,
    save,
    cancel,
    noDelete,
    noClose,
    className,
    index,
    length,
    allDisabled,
    withoutBankDetails,
    noAddLegalEntity,
  } = props;
  const { modal } = useContext(Context);

  const logout = useLogout();

  const [activeBtn, setActiveBtn] = useState(false);
  const [currentLink, setCurrentLink] = useState('');

  const onChangeLink = (event) => {
    setCurrentLink(event.target.value);
    check()
      .then(() => {
        const sentData = {
          inn: element.inn,
          type: element.type,
          legal_name: element.legal_name,
          file_links: event.target.value,
        };
        setCurrentLink(event.target.value);
        patchOneEntity(element.id, sentData);
      })
      .catch((error) => logout(error));
  };

  const tooltipTextPasport = 'Серия номер паспорта, Кем выдан, Когда выдан, Код подразделения';

  const tooltipText = `<b>Для ИП:</b> ИНН, ОГРНИП, адрес регистрации и банковские реквизиты<br>
  <b>Для СЗ:</b> ИНН, паспортные данные (серия номер паспорта, кем выдан, когда выдан, код подразделения), адрес регистрации и банковские реквизиты<br>
  <b>Для ООО:</b> ИНН, ОГРН, КПП, юридический адрес и банковские реквизиты`;

  const saveHandler = (info) => {
    setActiveBtn(false);
    save(info);
  };

  const cancelHandler = () => {
    setActiveBtn(false);
    cancel('legal_entity', element);
  };

  const updateBankDetails = (fieldName, value, bankId) => {
    const tempArray = element.bank_details
      .map((bank) => (bank.id === bankId
        ? { ...bank, [fieldName]: value }
        : bank));

    updateLegalEntities(
      element.id,
      'bank_details',
      tempArray,
    );
  };

  const addBankDetails = () => {
    updateLegalEntities(
      element.id,
      'bank_details',
      [...element.bank_details, {
        id: Date.now(),
        rc: '',
        bank: '',
        bik: '',
        kc: '',
      }],
    );
  };

  const deleteBankDetail = (bankId) => {
    const newArray = element.bank_details.filter((bank) => bank.id !== bankId);

    updateLegalEntities(
      element.id,
      'bank_details',
      newArray,
    );
  };

  const updateType = (value) => {
    updateLegalEntities(element.id, 'type', value);
  };

  const openModalHandler = (id) => {
    setActiveBtn(false);
    modal.setModalConfirmVisible(true);
    modal.setModalConfirmProps({
      text: 'Вы хотите удалить данные юридического лица?',
      click: () => removeLegalEntity(id),
    });
  };

  return (
    <div
      className={[classes.oneElement__container, className].join(' ')}
      style={index === 0 ? { margin: 0 } : {}}
    >
      <div className={!noClose ? classes.wrapper : ''}>
        <InputElement
          text="Юридическое лицо"
          textStyle={{ minWidth: 230 }}
          value={element?.legal_name}
          onChange={(event) => updateLegalEntities(element.id, 'legal_name', event.target.value)}
          placeholder="Полное наименование ООО/ИП..."
          onClick={() => setActiveBtn(true)}
          deleteBtn={!noDelete && activeBtn}
          deleteHandler={() => openModalHandler(element.id)}
          disabled={allDisabled}
        />

        <InputElement
          text="Форма ЮЛ"
          textStyle={{ minWidth: 230 }}
          value={element?.type}
          setValue={updateType}
          dropdown={LEGAL_TYPES.LIST}
          onClick={() => setActiveBtn(true)}
          disabled={allDisabled}
        />

        <InputElementInn
          text="ИНН"
          textStyle={{ minWidth: 230 }}
          element={element}
          setLegalEntities={setLegalEntities}
          legalEntities={legalEntities}
          value={element?.inn}
          onChange={(value) => updateLegalEntities(element.id, 'inn', value)}
          onClick={() => setActiveBtn(true)}
          disabled={allDisabled}
        />

        {(element?.type?.id === 0
          ? FIELDS
          : element?.type?.id === 1
            ? OGRNIP_FIELDS
            : OGRN_FIELDS
        ).map((field) => (
          <InputElement
            key={field.id}
            text={field.label}
            textStyle={{ minWidth: 230 }}
            value={element ? element[field.id] : ''}
            onChange={(event) => updateLegalEntities(element.id, field.id, event.target.value)}
            onClick={() => setActiveBtn(true)}
            disabled={allDisabled}
            tooltipText={field.label === 'Паспорт' && tooltipTextPasport}
          />
        ))}

        {!withoutBankDetails
  && (
  <div className={classes.miniheader}>
    <div>Банковские реквизиты</div>

    <div className={classes.bank_details__container}>
      {element?.bank_details.map((bank) => (
        <div className={classes.bank_details} key={bank.id}>
          {BANK_FIELDS.map((field) => (
            <InputElement
              key={field.label}
              text={field.label}
              textStyle={{ minWidth: 230 }}
              value={bank[field.id]}
              onChange={(event) => updateBankDetails(field.id, event.target.value, bank.id)}
              onClick={() => setActiveBtn(true)}
              deleteBtn={field.delete}
              deleteHandler={() => deleteBankDetail(bank.id)}
              disabled={allDisabled}
            />
          ))}
        </div>
      ))}
    </div>

    {!allDisabled && (
    <div className={classes.addBtn__container}>
      <NewAddBtn
        text="Добавить банковские реквизиты"
        onClick={() => addBankDetails()}
      />
    </div>
    )}

  </div>
  )}

        <InputElement
          text="Ссылка на документы"
          textStyle={{ minWidth: 230 }}
          value={currentLink || element?.file_links}
          isLink={isValidURL(element?.file_links)}
          onChange={onChangeLink}
          onClick={() => setActiveBtn(true)}
          tooltipText={tooltipText}
          tooltipPosition="top"
          className={classes.input__link__padding}
          classNameLink={classes.input__link}
        />

        {index === length - 1 && !noAddLegalEntity && (
          <div className={classes.addBtn__container}>
            <NewAddBtn
              text="Добавить юридическое лицо"
              onClick={() => addLegalEntities()}
            />
          </div>
        )}

        {save && activeBtn && (
          <div className={classes.btn__wrapper}>
            <SuccessBtn
              text="Сохранить"
              style={{
                padding: '2px 20px',
                fontSize: 13,
              }}
              onClick={() => saveHandler(element)}
            />

            <SuccessBtn
              text="Отменить"
              type="white"
              style={{
                padding: '2px 20px',
                fontSize: 13,
              }}
              onClick={() => cancelHandler()}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default OneLegalEntityInfo;
