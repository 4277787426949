export const HEADERS = [
  { id: 'project_id', label: '№' },
  { id: 'project_name', label: 'Название проекта', special: true },
  { id: 'contract_type', label: 'Формат договора' },
  { id: 'client_legal_name', label: 'ЮЛ заказчика' },
  { id: 'contractor_legal_name', label: 'ЮЛ исполнителя' },
  { id: 'manager', label: 'Менеджер' },
  { id: 'lawyer', label: 'Юрист' },
  { id: 'doc', label: 'ДОК' },
  { id: 'bid_sent_date', label: 'Дата отправки заявки' },
  { id: 'is_sent', label: 'Отпр' },
  { id: 'sent_date', label: 'Дата отправки договора' },
  { id: 'post_branch', label: 'Название ветки' },
  { id: 'contract_number', label: '№ договора' },
  { id: 'total_sum', label: 'Сумма договора (вкл все налоги)' },
  { id: 'nds_sum', label: 'НДС' },
  { id: 'ak_sum', label: 'Сумма АК с налогом' },
  { id: 'payment_date', label: 'Порядок оплаты', special: true },
  { id: 'sum', label: 'Сумма', special: true },
  { id: 'date_start', label: 'Срок оказания услуг (начало)' },
  { id: 'date_end', label: 'Срок оказания услуг (конец)' },
  { id: 'status', label: 'Статус договора' },
  { id: 'status_date', label: 'Дата статуса договора' },
  { id: 'closed_status', label: 'Статус закрывающих документов' },
  { id: 'bill_status', label: 'Статус счета' },
  { id: 'bill_status_date', label: 'Дата статуса счета' },
  { id: 'file_links', label: 'Ссылка на документы' },
  { id: 'comment', label: 'Примечание', special: true },
  { id: 'adesk', label: 'Адеск', special: true },
  { id: 'is_case', label: 'Кейс' },
  { id: 'manager_status', label: 'Статус проекта' },
  { id: 'paid_sum', label: 'Оплачено', special: true },
  { id: 'credit', label: 'Долг' },
  { id: 'collection_date', label: 'Дата сбора док.' },
  { id: 'quarter_start', label: 'Квартал начало' },
  { id: 'quarter_end', label: 'Квартал конец' },
  { id: 'is_quarter_closing', label: 'Закрытие в одном кв' },
  { id: 'closing_condition', label: 'Условия закрытия' },
  { id: 'actual_month', label: 'Месяц фактического дохода/расхода' },
  { id: 'accounting_month', label: 'Месяц учета дохода/расхода' },
  { id: 'margin', label: 'Маржа' },
  { id: '1q22', label: '1кв22', special: true },
  { id: '2q22', label: '2кв22', special: true },
  { id: '3q22', label: '3кв22', special: true },
  { id: '4q22', label: '4кв22', special: true },
  { id: '1q23', label: '1кв23', special: true },
  { id: '2q23', label: '2кв23', special: true },
  { id: '3q23', label: '3кв23', special: true },
  { id: '4q23', label: '4кв23', special: true },
];

export const HEADERS_GOOGLE_EXPORT_FINANCE_MAIN = [
  { id: 'project_id', label: '№' },
  { id: 'project_name', label: 'Название проекта' },
  { id: 'type', label: 'Формат договора' },
  { id: 'client_legal_name', label: 'ЮЛ заказчика' },
  { id: 'contractor_legal_name', label: 'ЮЛ исполнителя' },
  { id: 'manager', label: 'Менеджер' },
  { id: 'lawyer', label: 'Юрист' },
  { id: 'doc_manager', label: 'ДОК' },
  { id: 'bid_sent_date', label: 'Дата отправки заявки' },
  { id: 'is_sent', label: 'Отпр' },
  { id: 'sent_date', label: 'Дата отправки договора' },
  { id: 'post_branch', label: 'Название ветки' },
  { id: 'number', label: '№ договора' },
  { id: 'total_sum', label: 'Сумма договора (вкл все налоги)' },
  { id: 'nds_sum', label: 'НДС' },
  { id: 'ak_sum', label: 'Сумма АК с налогом' },
  { id: 'payment_dates', label: 'Порядок оплаты', special: true },
  { id: 'date_start', label: 'Срок оказания услуг (начало)' },
  { id: 'date_end', label: 'Срок оказания услуг (конец)' },
  { id: 'status', label: 'Статус договора' },
  { id: 'status_date', label: 'Дата статуса договора' },
  { id: 'closed_status', label: 'Статус закрывающих документов' },
  { id: 'bill_status', label: 'Статус счета' },
  { id: 'bill_status_date', label: 'Дата статуса счета' },
  { id: 'file_links', label: 'Ссылка на документы' },
  { id: 'comment', label: 'Примечание', special: true },
  { id: 'adesk', label: 'Адеск', special: true },
  { id: 'is_case', label: 'Кейс' },
  { id: 'manager_status', label: 'Статус проекта' },
  { id: 'paid_sum', label: 'Оплачено', special: true },
  { id: 'credit', label: 'Долг' },
  { id: 'collection_date', label: 'Дата сбора док.' },
  { id: 'quarter_start', label: 'Квартал начало' },
  { id: 'quarter_end', label: 'Квартал конец' },
  { id: 'is_quarter_closing', label: 'Закрытие в одном кв' },
  { id: 'closing_condition', label: 'Условия закрытия' },
  { id: 'actual_month', label: 'Месяц фактического дохода/расхода' },
  { id: 'accounting_month', label: 'Месяц учета дохода/расхода' },
  { id: 'margin', label: 'Маржа' },
];

export const HEADERS_GOOGLE_EXPORT_LAWYERS_MAIN = [
  { id: 'lawyer_status', label: 'Статус документа' },
  { id: 'project_id', label: '№' },
  { id: 'project_name', label: 'Название проекта' },
  { id: 'type', label: 'Формат договора' },
  { id: 'client_legal_name', label: 'ЮЛ заказчика' },
  { id: 'contractor_legal_name', label: 'ЮЛ исполнителя' },
  { id: 'manager', label: 'Менеджер' },
  { id: 'lawyer', label: 'Юрист' },
  { id: 'doc_manager', label: 'ДОК' },
  { id: 'bid_sent_date', label: 'Дата отправки заявки' },
  { id: 'is_sent', label: 'Договор отправлен' },
  { id: 'sent_date', label: 'Дата отправки договора' },
  { id: 'number', label: '№ договора' },
  { id: 'post_branch', label: 'Название ветки' },
  { id: 'total_sum', label: 'Сумма договора' },
  { id: 'nds_sum', label: 'НДС' },
  { id: 'client_sum', label: 'Стоимость исполнителя для клиента (с налогом)' },
  { id: 'ak_sum', label: 'Сумма АК с налогом' },
  { id: 'payment_dates', label: 'Порядок оплаты', special: true },
  { id: 'date_start', label: 'Срок оказания услуг (начало)' },
  { id: 'date_end', label: 'Срок оказания услуг (конец)' },
  { id: 'is_case', label: 'Кейс' },
  { id: 'paid_sum', label: 'Оплачено(тотал)', special: true },
  { id: 'paid_sum_separate', label: 'Оплачено(разбивка)', special: true },
  { id: 'credit', label: 'Остаток' },
  { id: 'file_links', label: 'Ссылка на договор и акт' },
  { id: 'closing_condition', label: 'Условия закрытия' },
  { id: 'status', label: 'Статус договора' },
  { id: 'status_date', label: 'Дата статуса договора' },
  { id: 'bill_status', label: 'Статус счета' },
  { id: 'bill_status_date', label: 'Дата статуса счета' },
  { id: 'closing_docs', label: 'Закрывающие документы' },
  { id: 'closing_balance', label: 'Остаток на закрытие' },
  { id: 'marking_info', label: 'Маркировка' },
  { id: 'token', label: 'Токены' },
];

export const HEADERS_GOOGLE_EXPORT_PROJECT_ACCOUNTING = [
  { id: 'project_id', label: '№ проекта' },
  { id: 'project_name', label: 'Название проекта' },
  { id: 'client_legal_name', label: 'ЮЛ заказчика' },
  { id: 'contractor_legal_name', label: 'ЮЛ исполнителя' },
  { id: 'date_start', label: 'Срок оказания услуг (начало)' },
  { id: 'date_end', label: 'Срок оказания услуг (конец)' },
  { id: 'accounting_type', label: 'Статус учета' },
  { id: 'type', label: 'G/L' },
  { id: 'total_sum', label: 'Сумма' },
  { id: 'nds_sum', label: 'НДС' },
  { id: 'sale_price', label: 'Цена продажи (без НДС)' },
  { id: 'payment_dates', label: 'Порядок оплаты', special: true },
  { id: 'adesk', label: 'Адеск', special: true },
  { id: 'manager_status', label: 'Статус проекта' },
  { id: 'paid_sum', label: 'Оплачено', special: true },
  { id: 'credit', label: 'Остаток' },
  { id: 'accounting_date', label: 'Месяц учета' },
  { id: 'accounting_sum', label: 'Сумма учета' },
  { id: 'quarter', label: 'Квартал' },
  { id: 'director', label: 'Руководитель' },
  { id: 'manager', label: 'Менеджер' },
  { id: 'lawyer', label: 'Юрист' },
  { id: 'doc_manager', label: 'ДОК' },
  { id: 'bid_sent_date', label: 'Дата отправки заявки' },
  { id: 'is_sent', label: 'Договор отправлен' },
  { id: 'sent_date', label: 'Дата отправки договора' },
  { id: 'post_branch', label: 'Название ветки' },
  { id: 'number', label: '№ договора' },
  { id: 'status_date', label: 'Дата статуса договора' },
  { id: 'status', label: 'Статус договора' },
  { id: 'closing_sum', label: 'Сумма закрывающих документов' },
  { id: 'closing_date', label: 'Дата закрывающих документов' },
  { id: 'closed_status', label: 'Статус закрывающих документов' },
  { id: 'bill_status_date', label: 'Дата статуса счета' },
  { id: 'bill_status', label: 'Статус счета' },
  { id: 'closing_condition', label: 'Условия закрытия' },
  { id: 'ak_sum', label: 'Сумма АК' },
  { id: 'margin', label: 'Маржа' },
  { id: 'publication_format', label: 'Формат публикаций' },
];

export const FIELDS = [
  {
    id: 'id',
    label: '№ проекта',
    type: 'number',
    width: 3,
  },
  {
    id: 'id_status',
    label: '№ проекта',
    type: 'dropdown',
    list: 'project_sent_date_deadline',
    width: 3,
  },
  {
    id: 'project',
    label: 'Название проекта',
    type: 'autocomplete',
    list: 'project',
    multiple: true,
    width: 3,
  },
  {
    id: 'contract_format',
    label: 'Формат договора',
    type: 'dropdown',
    list: 'contract_format',
    multiple: true,
    width: 3,
  },
  {
    id: 'client_id',
    label: 'ЮЛ заказчика',
    type: 'autocompleteLegal',
    multiple: true,
    width: 3,
  },
  {
    id: 'contractor_id',
    label: 'ЮЛ исполнителя',
    type: 'autocompleteLegal',
    multiple: true,
    width: 3,
  },
  {
    id: 'manager',
    label: 'Менеджер',
    type: 'autocompleteStaff',
    list: 'managers',
    multiple: true,
    width: 3,
  },
  {
    id: 'lawyer',
    label: 'Юрист',
    type: 'autocompleteStaff',
    list: 'lawyers',
    multiple: true,
    width: 3,
  },
  {
    id: 'document',
    label: 'ДОК',
    type: 'autocompleteStaff',
    list: 'documents',
    multiple: true,
    width: 3,
  },
  {
    id: 'bid_sent_date',
    label: 'Дата отправки заявки',
    type: 'doubleDate',
    value_id_1: 'bid_sent_date_start',
    value_id_2: 'bid_sent_date_end',
    width: 5,
  },
  {
    id: 'deadline_status',
    label: 'Дата отправки заявки',
    type: 'dropdown',
    list: 'contract_sent_date_deadline',
    width: 3,
  },
  {
    id: 'is_contract_sent',
    label: 'Договор отправлен',
    type: 'dropdown',
    list: 'true_false',
    width: 3,
  },
  {
    id: 'contract_sent_date',
    label: 'Дата отправки договора',
    type: 'doubleDate',
    value_id_1: 'contract_sent_date_start',
    value_id_2: 'contract_sent_date_end',
    width: 5,
  },
  {
    id: 'contract_sent_date_deadline',
    label: 'Дата отправки договора',
    type: 'dropdown',
    list: 'contract_sent_date_deadline',
    width: 3,
  },
  {
    id: 'branch_name',
    label: 'Название ветки',
    type: 'text',
    width: 3,
  },
  {
    id: 'contract_number',
    label: '№ договора',
    type: 'text',
    width: 3,
  },
  {
    id: 'contract_sum',
    label: 'Сумма договора',
    type: 'sum',
    width: 3,
  },
  {
    id: 'ak_sum',
    label: 'Сумма АК',
    type: 'sum',
    width: 3,
  },
  {
    id: 'payment_date',
    label: 'Порядок оплаты',
    type: 'doubleDate',
    value_id_1: 'payment_date_start',
    value_id_2: 'payment_date_end',
    width: 5,
  },
  {
    id: 'service_start',
    label: 'Срок оказания услуг (н)',
    type: 'doubleDate',
    value_id_1: 'service_start',
    value_id_2: 'service_end',
    width: 5,
  },
  {
    id: 'service_end',
    label: 'Срок оказания услуг (к)',
    type: 'doubleDate',
    value_id_1: 'service_end_start',
    value_id_2: 'service_end_end',
    width: 5,
  },
  {
    id: 'contract_status',
    label: 'Статус договора',
    type: 'dropdown',
    list: 'contract_status',
    multiple: true,
    width: 3,
  },
  {
    id: 'contract_closed_status',
    label: 'Статус закр.документов',
    type: 'dropdown',
    list: 'contract_closed_status',
    multiple: true,
    width: 3,
  },
  {
    id: 'status_score',
    label: 'Статус счета',
    type: 'dropdown',
    list: 'status_score',
    multiple: true,
    width: 3,
  },
  {
    id: 'is_adesk',
    label: 'Адеск',
    type: 'dropdown',
    list: 'true_false',
    width: 3,
  },
  {
    id: 'is_case',
    label: 'Кейс',
    type: 'dropdown',
    list: 'true_false',
    width: 3,
  },
  {
    id: 'project_status',
    label: 'Статус проекта',
    type: 'dropdown',
    list: 'project_status',
    multiple: true,
    width: 3,
  },
  {
    id: 'date_take_documents',
    label: 'Дата сбора документов',
    type: 'doubleDate',
    value_id_1: 'date_take_documents_start',
    value_id_2: 'date_take_documents_end',
    width: 5,
  },
  {
    id: 'quarter_start',
    label: 'Квартал начало',
    type: 'dropdown',
    list: 'quarter_closing',
    multiple: true,
    width: 3,
  },
  {
    id: 'quarter_end',
    label: 'Квартал конец',
    type: 'dropdown',
    list: 'quarter_closing',
    multiple: true,
    width: 3,
  },

  {
    id: 'quarter_closing',
    label: 'Квартал закрытия',
    type: 'dropdown',
    list: 'quarter_closing',
    multiple: true,
    width: 3,
  },
  {
    id: 'actual_month',
    label: 'Месяц учета факт. дохода/расхода',
    type: 'doubleDate',
    value_id_1: 'actual_month_start',
    value_id_2: 'actual_month_end',
    width: 5,
  },
  {
    id: 'accounting_month',
    label: 'Месяц учета дохода/расхода',
    type: 'doubleDate',
    value_id_1: 'accounting_month_start',
    value_id_2: 'accounting_month_end',
    width: 5,
  },
];
