import qs from 'qs';

import { $authHost } from 'API';

import { getDate } from 'utils/getDate';

// Страница сотрудников
export const fetchStaffCard = async (id) => {
  const { data } = await $authHost.get(`/staff_page/staff/${id}/`);
  return data;
};

export const fetchDeleteStaffCard = async (id) => {
  const { data } = await $authHost.delete(`/staff_page/staff/${id}/`);
  return data;
};

export const updateStaff = async (id, data) => {
  await $authHost.patch(`/staff_page/staff/${id}/`, data);
};

export const fetchProjectStaff = async (page, limit, controller, filter, id) => {
  const params = { page, limit };

  if (filter) {
    if (filter.status.length > 0) {
      params.status = filter.status;
    }
  }

  const { data } = await $authHost.get(`/staff_page/staff/${id}/projects/`, {
    params,
    signal: controller,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return data;
};

export const fetchTasksStaff = async (id, filter) => {
  const params = {};

  if (filter) {
    if (Number.isInteger(filter.project[0].id)) {
      params.project = filter.project[0].id;
    }

    if (filter.deadlineStart) {
      params.deadline_after = getDate(filter.deadlineStart);
    }
    if (filter.deadlineEnd) {
      params.deadline_before = getDate(filter.deadlineEnd);
    }

    if (filter.status.id) {
      params.status = filter.status.id;
    }

    if (filter.project_status.length > 0) {
      params.project_status = filter.project_status;
    }
  }

  const { data } = await $authHost.get(`/staff_page/staff/${id}/tasks/`, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return data;
};

// Страница настроек
export const changePhoto = async (id, photo, options) => {
  const response = await $authHost.post(`/staff_page/staff/${id}/set_photo/`, photo, options);
  return response;
};

export const fetchOneUser = async () => {
  const { data } = await $authHost.get('/staff_page/staff/');
  return data;
};

export const updateNotificationStatus = async (id, status, name, type) => {
  const data = {};

  if (type) {
    data[name] = { [type]: status };
  } else {
    data[name] = { status };
  }

  await $authHost.patch(`/staff_page/staff/${id}/`, data);
};
