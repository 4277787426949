// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { Context } from 'index';

import {
  addAdditionalExpenseBIDFile,
  addCostFile,
  getBloggersWithoutContract,
  postAdditionalExpenseBid,
  postCostBid,
} from 'API/ApplicationAPI';
import { fetchStaff } from 'API/ManagerAPI/AutocompleteAPI';
import { check } from 'API/UserAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import CheckboxItem from 'components/UI/CheckboxItem/CheckboxItem';
import InputElement from 'components/UI/InputElements/InputElement';
import MyModal from 'components/UI/MyModal/MyModal';
import SearchBar from 'components/UI/SearchBar/SearchBar';
import UploadFilesContainer from 'components/UI/UploadFilesContainer/UploadFilesContainer';

import { useLogout } from 'hooks/useLogout';
import { useProgress } from 'hooks/useProgress';
import { useStateNew } from 'hooks/useStateNew';

import { getDate } from 'utils/getDate';
import { formatNumberCard, getSum, getSumRuble } from 'utils/getSums';
import { numberToSum } from '../../../../utils/getSums';

import CheckboxElement from '../../CheckboxItem/CheckboxElement';
import Files from '../../Files/Files';
import MyInputFile from '../../MyInput/MyInputFile';
import ContractElement from '../ModalCreateContract/components/ContractElement';

import classes from './ModalCreateCost.module.scss';

function ModalCreateCost({ show, onHide, props }) {
  const {
    id, fetchMargin, fetchEstimate, fetchProject,
  } = props;
  const [logout] = useLogout();
  const { user, toast } = useContext(Context);

  const [isVisible, setIsVisible] = useState(false);

  const [managerList, setManagerList] = useState([]);

  const [responsible, , changeResponsible, , , checkResponsible] = useStateNew([
    {
      fieldId: Date.now(),
      id: user.user.staff.id,
      name: user.user.staff.fullname,
    },
  ]);

  const [paymentPurpose, setPaymentPurpose] = useState('');
  const [sum, setSum] = useState('');
  const [isNds, setIsNds] = useState(true);
  const [date, setDate] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');

  const [numberPhone, setNumberPhone] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [fullname, setFullname] = useState('');

  const [files, setFiles] = useState([]);

  let idForModal;
  const [progressFiles, addFileFunc] = useProgress(
    setIsVisible,
    null,
    null,
    logout,
    addCostFile,
    addAdditionalExpenseBIDFile,
  );

  const addFileHandler = (event) => {
    setFiles([...files, ...Array.from(event.target.files)]);
  };

  const deleteFileHandler = (fileIndex) => {
    setFiles(files.filter((file, index) => index !== fileIndex));
  };

  useEffect(() => {
    if (show) {
      check()
        .then(() => {
          fetchStaff({ job: 0, is_active: true }).then((data) => setManagerList(data));
        })
        .catch(() => logout());
    }
  }, [show]);

  const closeModal = () => {
    setTimeout(() => {
      setPaymentPurpose('');
      setSum('');
      setDate(null);
      setPaymentMethod('');
      setNumberPhone('');
      setCardNumber('');
      setFullname('');
      setFiles([]);
    }, 140);
    onHide();
  };

  const send = () => {
    check()
      .then(() => {
        const data = {
          project: { id },
          responsible: { id: responsible[0].id },
          payment_method: paymentMethod,
          sum: getSum(sum),
          is_nds: isNds,
          date: date ? getDate(date) : '',
          payment_purpose: paymentPurpose,
          bank_details: {
            phone_number: numberPhone,
            card_number: cardNumber,
            recipient_name: fullname,
          },
        };

        if (getDate(date) < getDate()) {
          toast.setToastInfo('Дата оплаты не может быть раньше сегодняшнего дня');
          toast.setShowError(true);
          return;
        }

        if (getSum(sum) <= 0) {
          toast.setToastInfo('Введите сумму');
          toast.setShowError(true);
          return;
        }

        if (!date) {
          toast.setToastInfo('Поле "Дата оплаты ДО" не может быть пустым');
          toast.setShowError(true);
          return;
        }

        if (!paymentMethod) {
          toast.setToastInfo('Поле "Способ оплаты" не может быть пустым');
          toast.setShowError(true);
          return;
        }

        postAdditionalExpenseBid(data).then((response) => {
          idForModal = response.data.id;
          closeModal();
          toast.setToastInfo('Заявка отправлена');
          toast.setShowSuccess(true);
          fetchMargin();
          fetchEstimate();
          fetchProject('application');
          files.forEach((file) => addFileFunc(file, idForModal));
        }).catch(() => {
          toast.setToastInfo('Обратитесь в тех.поддержку');
          toast.setShowError(true);
        });
      })
      .catch(() => logout());
  };

  return (
    <>
      <UploadFilesContainer
        progressFiles={progressFiles}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />

      <MyModal show={show} onHide={closeModal} style={{ minHeight: 500 }}>
        <div className={classes.wrapper}>
          <div>
            <h1>Заявка на дополнительный расход</h1>

            <div className={classes.newContract__container}>
              <ContractElement
                text="Отправитель"
                style={{ alignItems: 'start', marginBottom: 0 }}
                textStyle={{ height: 32 }}
              >
                <div className={classes.ourСontacts__container}>
                  {responsible.map((element, index) => (
                    <SearchBar
                      key={element.fieldId}
                      list={managerList}
                      text={element}
                      setText={changeResponsible}
                      placeholder="Введите ответственного"
                      checkResponsible={checkResponsible}
                      required
                      type="staff"
                      disabled={index === 0}
                    />
                  ))}
                </div>
              </ContractElement>

              <InputElement
                text="Назначение платежа"
                placeholder="Введите текст..."
                value={paymentPurpose}
                onChange={(event) => setPaymentPurpose(event.target.value)}
                textareaStyle={{ maxHeight: '80px', height: '80px' }}
                elementClassName={classes.textarea__container}
                textarea
              />

              <InputElement
                value={sum}
                onChange={(event) => setSum(getSumRuble(event.target.value))}
                text="Сумма"
                placeholder="0, 00 Р"
                elementClassName={classes.margin__zero}
              />

              <CheckboxElement
                text="НДС"
                className={classes.margin_zero}
              >
                <CheckboxItem
                  text="Да"
                  status={isNds}
                  setStatus={() => setIsNds(true)}
                  className={classes.checkbox__mini}
                />

                <CheckboxItem
                  text="Нет"
                  status={!isNds}
                  setStatus={() => setIsNds(false)}
                  className={classes.checkbox__mini}
                />
              </CheckboxElement>

              {isNds && (
              <>
                <InputElement
                  text="Сумма НДС(20%)"
                  type="number"
                  placeholder="0,00 Р"
                  value={numberToSum((getSum(sum) * 20) / 120)}
                  elementClassName={classes.margin_zero}
                  disabled
                />

                <InputElement
                  text="Сумма расхода без НДС"
                  type="number"
                  placeholder="0,00 Р"
                  value={numberToSum(getSum(sum) / 1.2)}
                  elementClassName={classes.margin_zero}
                  disabled
                />
              </>
              )}

              <InputElement
                type="date"
                value={date}
                setValue={setDate}
                text="Дата оплаты ДО"
                placeholder="0, 00 Р"
                date
                style={{ width: 430, margin: 0 }}
              />

              <InputElement
                text="Способ оплаты"
                placeholder="Введите текст..."
                value={paymentMethod}
                onChange={(event) => setPaymentMethod(event.target.value)}
                textareaStyle={{ maxHeight: '80px', height: '80px' }}
                elementClassName={classes.textarea__container}
                textarea
              />

              <InputElement
                value={numberPhone}
                setValue={setNumberPhone}
                text="Номер телефона получателя"
                type="phone"
                elementClassName={classes.margin__zero}
              />
              <InputElement
                text="Номер карты получателя"
                type="number"
                value={formatNumberCard(cardNumber)}
                setValue={setCardNumber}
                elementClassName={classes.margin__zero}
                placeholder="____ ____ ____ ____"
              />
              <InputElement
                value={fullname}
                setValue={setFullname}
                text="ФИО получателя"
                elementClassName={classes.margin__zero}
                placeholder="ФИО"
              />

              <ContractElement>
                <div className={classes.files__container}>
                  {files.length > 0 && (
                    <div className={classes.files}>
                      {files.map((file, index) => (
                        <Files
                          key={index}
                          file={file}
                          deleteFileHandler={() => deleteFileHandler(index)}
                        />
                      ))}
                    </div>
                  )}

                  <div>
                    <MyInputFile
                      onChange={(event) => addFileHandler(event)}
                      multiple
                      accept=".ppt, .pptx, .xls, .xlsx, .doc, .docx, .jpeg, .jpg, .png, .pdf, .key, .heif"
                      text="Добавить файл (подтверждение расхода)"
                    />
                  </div>
                </div>
              </ContractElement>
            </div>
          </div>

          <div className={classes.btn__container}>
            <SuccessBtn
              text="Отправить заявку"
              onClick={send}
              style={{ padding: '3px 15px', fontSize: 13, width: 220 }}
            />
          </div>
        </div>
      </MyModal>
    </>
  );
}

export default ModalCreateCost;
