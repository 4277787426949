import React, { useContext } from 'react';
import { Context } from 'index';

import { changePhoto } from 'API/StaffAPI';
import { check } from 'API/UserAPI';

import photo from '../../../assets/icons/photo.svg';
import CancelBtn from '../Btns/CancelBtn';

import addBtnClass from '../Btns/Btns.module.scss';
import classes from './ProfileAvatar.module.css';

function ProfileAvatar(props) {
  const { user } = useContext(Context);
  const { info, fetch } = props;

  const addFile = (event) => {
    const formData = new FormData();
    formData.append(event.target.files[0].name, event.target.files[0]);

    check().then(() => {
      changePhoto(info.id, formData).then(() => {
        fetch(info.id);
      });
    });
  };

  const logout = () => {
    user.setIsAuth(false);
    localStorage.clear();
  };

  return (
    <div className={classes.profile__avatar__container}>
      <img
        className={classes.profile__avatar}
        src={info.photo.file ? info.photo.file : photo}
        alt=""
      />

      {fetch && (
        <div className={classes.profile__btns}>
          <label
            htmlFor="change"
            className={addBtnClass.btn__success}
            style={{ fontSize: 14 }}
          >
            <input
              className={classes.input__file}
              onChange={(event) => addFile(event)}
              type="file"
              accept=".jpg, .jpeg"
              id="change"
            />
            <span>Изменить</span>
          </label>

          <CancelBtn text="Выйти" style={{ fontSize: 13 }} onClick={logout} />
        </div>
      )}
    </div>
  );
}

export default ProfileAvatar;
