import React from 'react';

import classes from './MultipleBtn.module.css';

function MultipleBtn(props) {
  const { element, deleteEl } = props;

  return (
    <div className={classes.btn} onClick={() => deleteEl(element.id)}>
      <span className={classes.btn__text}>
        {element.fullname || element.name || element.label}
      </span>
      <svg
        width="6"
        height="6"
        viewBox="0 0 6 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.38882 3.00268L5.92067 0.479407C6.02644 0.372635 6.02644 0.199665 5.92067 0.0928935C5.81676 -0.0157865 5.64517 -0.0190505 5.53727 0.0856008L3.00543 2.60888L0.506131 0.0856008C0.454891 0.0309604 0.383595 0 0.309008 0C0.234422 0 0.163126 0.0309604 0.111886 0.0856008C0.0181002 0.188759 0.0181002 0.347078 0.111886 0.450236L2.61118 2.96987L0.0793336 5.4895C-0.0264446 5.59627 -0.0264446 5.76924 0.0793336 5.87601C0.129691 5.92801 0.198948 5.95699 0.271031 5.95623C0.344502 5.96224 0.417339 5.93863 0.473578 5.89059L3.00543 3.36732L5.53727 5.91977C5.58763 5.97177 5.65689 6.00075 5.72897 5.99999C5.80097 6.00033 5.87007 5.97141 5.92067 5.91977C6.02644 5.81299 6.02644 5.64002 5.92067 5.53325L3.38882 3.00268Z"
          fill="#D13589"
        />
      </svg>
    </div>
  );
}

export default MultipleBtn;
